import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form'
import {
  MenuItem,
  Box,
  Select,
  Typography,
  Button,
  CircularProgress,
  FormHelperText,
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { FC } from 'react'
import ReactQuill from 'react-quill'

import {
  ControlledDatePicker,
  FormFieldContainer,
} from 'src/features/shared/presentation'
import { reactQuillDefaultModules } from 'src/config'
import {
  useCreateEnablingTherapy,
  useGetPatientIntakeFlowsQuery,
} from 'src/features/msk/presentation'
import { mapToCreateEnablingTherapyPayload } from 'src/features/msk/adapters'
import { CreateEnablingTherapyArgs } from 'src/features/msk/domain'
import { toast } from 'react-toastify'
import { ErrorMessage } from '@hookform/error-message'

export type CreateEnablingTherapyFormFields = {
  type: string[]
  dateOfFirstAppointment?: string
  therapyNote: string
}

const createEnablingTherapyFormSchema: yup.Schema<CreateEnablingTherapyFormFields> =
  yup.object().shape({
    type: yup.array().of(yup.string().required()).required('Required'),
    dateOfFirstAppointment: yup.string(),
    therapyNote: yup.string().required('Required'),
  })

type CreateEnablingTherapyFormProps = {
  intakeId: string
  patientId: string
  assignedPathwayId: string
  onCancel: () => void
  onSubmitSuccess?: () => void
}

export const CreateEnablingTherapyForm: FC<CreateEnablingTherapyFormProps> = ({
  intakeId,
  patientId,
  assignedPathwayId,
  onCancel,
  onSubmitSuccess,
}) => {
  const { enablingTherapies } = useGetPatientIntakeFlowsQuery()
  const formMethods = useForm<CreateEnablingTherapyFormFields>({
    resolver: yupResolver(createEnablingTherapyFormSchema),
    defaultValues: {
      type: [],
      therapyNote: '',
    },
    mode: 'onBlur',
  })
  const { createEnablingTherapy, createEnablingTherapyIsLoading } =
    useCreateEnablingTherapy()

  const submitHandler: SubmitHandler<CreateEnablingTherapyFormFields> = (
    values
  ) => {
    const createEnablingTherapyArgs: CreateEnablingTherapyArgs = {
      intakeId,
      payload: mapToCreateEnablingTherapyPayload(
        patientId,
        assignedPathwayId,
        values
      ),
    }
    createEnablingTherapy(createEnablingTherapyArgs, {
      onSuccess: () => {
        toast.success('Enabling Therapy created successfully')
        formMethods.reset()
        if (onSubmitSuccess) onSubmitSuccess()
      },
      onError: () => {
        toast.error('Failed to create Enabling Therapy')
      },
    })
  }

  return (
    <FormProvider {...formMethods}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '16px',
        }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            color: '#aaaaaa',
          }}
        >
          Enabling Therapy...
        </Typography>
        <form onSubmit={formMethods.handleSubmit(submitHandler)}>
          <Box display={'flex'} flexDirection={'column'} rowGap={'16px'}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '16px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  columnGap: '16px',
                }}
              >
                <Controller
                  name="type"
                  control={formMethods.control}
                  render={({ field, fieldState }) => (
                    <FormFieldContainer
                      label={'Type'}
                      labelComponent={'inputLabel'}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                      required
                      formControlProps={{
                        size: 'small',
                      }}
                      formControlSx={{
                        flex: '0 1 227px',
                      }}
                    >
                      <Select
                        label={'Type'}
                        error={fieldState.invalid}
                        multiple
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                        renderValue={(selected) =>
                          Array.isArray(selected)
                            ? selected.join(', ')
                            : selected
                        }
                        sx={{ width: '100%' }}
                      >
                        {enablingTherapies.map((therapy) => (
                          <MenuItem key={therapy} value={therapy}>
                            {therapy}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormFieldContainer>
                  )}
                />
                <ControlledDatePicker
                  name="dateOfFirstAppointment"
                  datePickerProps={{
                    label: 'Date of first appt.',
                  }}
                />
              </Box>
              <Box width={'603px'}>
                <Controller
                  name="therapyNote"
                  control={formMethods.control}
                  render={({ field }) => (
                    <ReactQuill
                      modules={reactQuillDefaultModules}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      placeholder="Enabling Therapy note..."
                    />
                  )}
                />
                <ErrorMessage
                  name={'therapyNote'}
                  render={({ message }) => (
                    <FormHelperText error>{message}</FormHelperText>
                  )}
                />
              </Box>
            </Box>

            <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
              <Button
                type="submit"
                variant="text"
                color="secondary"
                onClick={onCancel}
                disabled={createEnablingTherapyIsLoading}
              >
                CANCEL
              </Button>
              <Button
                type="submit"
                variant="text"
                color="secondary"
                disabled={createEnablingTherapyIsLoading}
              >
                {createEnablingTherapyIsLoading ? <CircularProgress /> : 'SAVE'}
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </FormProvider>
  )
}
