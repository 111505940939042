import { TablePagination, TablePaginationProps } from '@mui/material'
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions'
import { FC, ChangeEvent, MouseEvent } from 'react'

import {
  EligiblePatientsQueryParams,
  useEligiblePatientsQueryParams,
} from 'src/features/eligibility/presentation'

type EligiblePatientsTablePaginationProps = {
  totalCount: number
} & Omit<
  TablePaginationProps,
  | 'rowsPerPageOptions'
  | 'colSpan'
  | 'count'
  | 'rowsPerPage'
  | 'page'
  | 'onPageChange'
  | 'onRowsPerPageChange'
  | 'ActionComponent'
>

export const EligiblePatientsTablePagination: FC<
  EligiblePatientsTablePaginationProps
> = ({ totalCount, sx, SelectProps, ...props }) => {
  const { getLimit, getPage, updateEligiblePatientsQueryParams } =
    useEligiblePatientsQueryParams()

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    const newParams: Partial<EligiblePatientsQueryParams> = {
      limit: `${getLimit()}`,
      page: `${newPage + 1}`,
    }
    updateEligiblePatientsQueryParams(newParams)
  }

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newParams: Partial<EligiblePatientsQueryParams> = {
      limit: event.target.value,
      page: '1',
    }
    updateEligiblePatientsQueryParams(newParams)
  }

  return (
    <TablePagination
      rowsPerPageOptions={[25, 50, 100]}
      colSpan={7}
      count={totalCount || 0}
      rowsPerPage={Number(getLimit())}
      page={!totalCount || totalCount <= 0 ? 0 : Number(getPage()) - 1}
      SelectProps={{
        inputProps: {
          'aria-label': 'rows per page',
        },
        native: true,
        ...SelectProps,
      }}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      ActionsComponent={TablePaginationActions}
      sx={{ width: '100%', ...sx }}
      {...props}
    />
  )
}
