import axios from 'axios'

import { CompassUsersUseCases } from 'src/features/shared/domain'
import {
  useCompassUsersService,
  useNavigatorsService,
} from 'src/features/shared/infrastructure'
import {
  mapToGetNavigatorsReturns,
  mapToGetNavigatorsServiceParams,
  mapToGetMyCompassUserReturns,
  mapToGetMyCompassUserError,
  mapToGetNavigatorsError,
} from 'src/features/shared/adapters'
import { useAxiosErrorHandling } from 'src/features/shared/infrastructure'

export const useCompassUsersServiceAdapter = () => {
  const compassUsersService = useCompassUsersService()
  const navigatorsService = useNavigatorsService()
  const { handleAxiosError } = useAxiosErrorHandling()

  const getNavigators: CompassUsersUseCases['getNavigators'] = async (
    params
  ) => {
    try {
      const serviceParams = mapToGetNavigatorsServiceParams(params)

      const handler = params?.withTasks
        ? navigatorsService.getNavigatorsWithTasks
        : navigatorsService.getNavigators
      const response = await handler(serviceParams)

      return mapToGetNavigatorsReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToGetNavigatorsError)
      }
      throw error
    }
  }

  const getMyCompassUser: CompassUsersUseCases['getMyCompassUser'] = async (
    idToken
  ) => {
    try {
      const response = await compassUsersService.getMyCompassUser(idToken)

      return mapToGetMyCompassUserReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToGetMyCompassUserError)
      }
      throw error
    }
  }

  return {
    getNavigators,
    getMyCompassUser,
  }
}
