import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from 'src/features/shared/infrastructure'

export type PatientPageState = {
  openMSKFormModal: boolean
  openTasksCalendarModal: boolean
  painFunctionGraphsSelectedCondition?: string
  careTeamSelectedCondition?: string
}

const initialState: PatientPageState = {
  openMSKFormModal: false,
  openTasksCalendarModal: false,
  painFunctionGraphsSelectedCondition: undefined,
  careTeamSelectedCondition: undefined,
}

const patientPageSlice = createSlice({
  name: 'patientPage',
  initialState,
  reducers: {
    setOpenMSKFormModalAction(
      state,
      action: PayloadAction<PatientPageState['openMSKFormModal']>
    ) {
      state.openMSKFormModal = action.payload
    },
    setOpenTasksCalendarSModalAction(
      state,
      action: PayloadAction<PatientPageState['openTasksCalendarModal']>
    ) {
      state.openTasksCalendarModal = action.payload
    },
    setPainFunctionGraphsSelectedConditionAction(
      state,
      action: PayloadAction<
        PatientPageState['painFunctionGraphsSelectedCondition']
      >
    ) {
      state.painFunctionGraphsSelectedCondition = action.payload
    },
    setCareTeamSelectedConditionAction(
      state,
      action: PayloadAction<PatientPageState['careTeamSelectedCondition']>
    ) {
      state.careTeamSelectedCondition = action.payload
    },
  },
})

export const {
  setOpenMSKFormModalAction,
  setOpenTasksCalendarSModalAction,
  setPainFunctionGraphsSelectedConditionAction,
  setCareTeamSelectedConditionAction,
} = patientPageSlice.actions

export const openMSKFormModalSelector = (state: RootState) =>
  state.patientPage.openMSKFormModal

export const openTasksCalendarModalSelector = (state: RootState) =>
  state.patientPage.openTasksCalendarModal

export const painFunctionGraphsSelectedConditionSelector = (state: RootState) =>
  state.patientPage.painFunctionGraphsSelectedCondition

export const careTeamSelectedConditionSelector = (state: RootState) =>
  state.patientPage.careTeamSelectedCondition

export default patientPageSlice.reducer
