import {
  LineOfBusiness,
  ReferralSource,
  Stage,
  Market,
} from 'src/features/patients/domain'

export const REMOVED_REFERRAL_SOURCES: ReferralSource[] = [
  'Orthopedic Surgeon',
  'Plan - Humana',
  'PCP',
  'Analytic Target',
]

export const REFERRAL_SOURCES: ReferralSource[] = [
  'PCP',
  'Orthopedic Surgeon',
  'Inbound - Phone',
  'Inbound - Web',
  'Outbound Outreach - UM',
  'Outbound Outreach - Predictive Model',
]

export const MARKETS: Market[] = [
  'Hyannis',
  'Seattle',
  'Charlotte',
  'Triad',
  'Phoenix',
  'Denver',
  'Houston',
  'Atlanta',
  'None',
]

export const LINE_OF_BUSINESS_OPTIONS: LineOfBusiness[] = ['MA', 'Commercial']

export const STAGES: Stage[] = [
  'Do Not Contact',
  'Engaged',
  'Closed',
  'Pathway Rec. Only',
  'Targeted',
]

export type RecommendedActionPathwayType = Record<string, string[]>

export const RECOMMENDED_ACTION_PATHWAYS_TYPES: RecommendedActionPathwayType = {
  '002a': ['Surgeon', 'APP (PA or NP)'],
  '003a': ['McKenzie'],
  '004a': ['Physiatry'],
  '005a': ['Surgeon', 'Physiatry'],
  '006a': ['Self-Care'],
  '008a': ['Traditional'],
  '009a': ['Surgeon', 'APP (PA or NP)'],
  '010a': ['CHT'],
}

export type Language = string

type Languages = Language[]

export const MAIN_LANGUAGES: Languages = [
  'English',
  'Spanish',
  'Korean',
  'Chinese - Mandarin',
  'Chinese - Cantonese',
  'French',
  'German',
  'ASL',
]

export const OTHER_LANGUAGES: Languages = [
  'Abkhazian',
  'Afar',
  'Afrikaans',
  'Akan',
  'Albanian',
  'Amharic',
  'Arabic',
  'Aragonese',
  'Armenian',
  'Assamese',
  'Avaric',
  'Avestan',
  'Aymara',
  'Azerbaijani',
  'Bambara',
  'Bashkir',
  'Basque',
  'Belarusian',
  'Bengali',
  'Bihari languages',
  'Bislama',
  'Bokm\u00e5l, Norwegian; Norwegian Bokm\u00e5l',
  'Bosnian',
  'Breton',
  'Bulgarian',
  'Burmese',
  'Catalan; Valencian',
  'Central Khmer',
  'Chamorro',
  'Chechen',
  'Chichewa; Chewa; Nyanja',
  'Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic',
  'Chuvash',
  'Cornish',
  'Corsican',
  'Cree',
  'Croatian',
  'Czech',
  'Danish',
  'Divehi; Dhivehi; Maldivian',
  'Dutch; Flemish',
  'Dzongkha',
  'Esperanto',
  'Estonian',
  'Ewe',
  'Faroese',
  'Fijian',
  'Finnish',
  'Fulah',
  'Gaelic; Scottish Gaelic',
  'Galician',
  'Ganda',
  'Georgian',
  'German',
  'Greek, Modern (1453-)',
  'Guarani',
  'Gujarati',
  'Haitian; Haitian Creole',
  'Hausa',
  'Hebrew',
  'Herero',
  'Hindi',
  'Hiri Motu',
  'Hungarian',
  'Icelandic',
  'Ido',
  'Igbo',
  'Indonesian',
  'Interlingua (International Auxiliary Language Association)',
  'Interlingue; Occidental',
  'Inuktitut',
  'Inupiaq',
  'Irish',
  'Italian',
  'Japanese',
  'Javanese',
  'Kalaallisut; Greenlandic',
  'Kannada',
  'Kanuri',
  'Kashmiri',
  'Kazakh',
  'Kikuyu; Gikuyu',
  'Kinyarwanda',
  'Kirghiz; Kyrgyz',
  'Komi',
  'Kongo',
  'Kuanyama; Kwanyama',
  'Kurdish',
  'Lao',
  'Latin',
  'Latvian',
  'Limburgan; Limburger; Limburgish',
  'Lingala',
  'Lithuanian',
  'Luba-Katanga',
  'Luxembourgish; Letzeburgesch',
  'Macedonian',
  'Malagasy',
  'Malay',
  'Malayalam',
  'Maltese',
  'Manx',
  'Maori',
  'Marathi',
  'Marshallese',
  'Mongolian',
  'Nauru',
  'Navajo; Navaho',
  'Ndebele, North; North Ndebele',
  'Ndebele, South; South Ndebele',
  'Ndonga',
  'Nepali',
  'Northern Sami',
  'Norwegian Nynorsk; Nynorsk, Norwegian',
  'Norwegian',
  'Occitan (post 1500)',
  'Ojibwa',
  'Oriya',
  'Oromo',
  'Ossetian; Ossetic',
  'Pali',
  'Panjabi; Punjabi',
  'Persian',
  'Polish',
  'Portuguese',
  'Pushto; Pashto',
  'Quechua',
  'Romanian; Moldavian; Moldovan',
  'Romansh',
  'Rundi',
  'Russian',
  'Samoan',
  'Sango',
  'Sanskrit',
  'Sardinian',
  'Serbian',
  'Shona',
  'Sichuan Yi; Nuosu',
  'Sindhi',
  'Sinhala; Sinhalese',
  'Slovak',
  'Slovenian',
  'Somali',
  'Sotho, Southern',
  'Sundanese',
  'Swahili',
  'Swati',
  'Swedish',
  'Tagalog',
  'Tahitian',
  'Tajik',
  'Tamil',
  'Tatar',
  'Telugu',
  'Thai',
  'Tibetan',
  'Tigrinya',
  'Tonga (Tonga Islands)',
  'Tsonga',
  'Tswana',
  'Turkish',
  'Turkmen',
  'Twi',
  'Uighur; Uyghur',
  'Ukrainian',
  'Urdu',
  'Uzbek',
  'Venda',
  'Vietnamese',
  'Volap\u00fck',
  'Walloon',
  'Welsh',
  'Western Frisian',
  'Wolof',
  'Xhosa',
  'Yiddish',
  'Yoruba',
  'Zhuang; Chuang',
  'Zulu',
]

export type StateCode = string

type StateCodes = StateCode[]

export const STATE_CODES: StateCodes = [
  'AA',
  'AE',
  'AK',
  'AL',
  'AP',
  'AR',
  'AS',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'FM',
  'GA',
  'GU',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MH',
  'MI',
  'MN',
  'MO',
  'MP',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VI',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
]

export const DNC_TOOLTIP_TEXT = 'Do not call as indicated by contracted partner'
