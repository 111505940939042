import { useCallback } from 'react'

import {
  GetPartnersService,
  GetPartnersServiceResponse,
} from 'src/features/providers/infrastructure'

import { useProvidersApiClient } from 'src/features/shared/infrastructure'
import * as settings from 'src/config/settings'

export const usePartnersService = () => {
  const { getProvidersApiClient } = useProvidersApiClient()

  const getPartners: GetPartnersService = useCallback(async () => {
    const providersClient = await getProvidersApiClient()

    return await providersClient.get<GetPartnersServiceResponse>(
      settings.PROVIDERS_API_BASE_URL + '/partners'
    )
  }, [getProvidersApiClient])

  return {
    getPartners,
  }
}
