import { rest } from 'msw'

import { SearchPatientsServiceResponse } from 'src/features/patients/infrastructure'

import * as settings from 'src/config/settings'
import { searchPatientsServiceResponseMock } from 'src/__mocks__/responses/search-patients-service-response'

const url = `${settings.COMPASS_API_BASE_URL}/search/suggest`

export const searchPatientsServiceResponseHandler =
  rest.get<SearchPatientsServiceResponse>(url, async (req, res, ctx) => {
    // const type = req.url.searchParams.get('type')
    // const q = req.url.searchParams.get('q')

    return res(ctx.status(200), ctx.json(searchPatientsServiceResponseMock))
  })
