import {
  PatientPageState,
  openMSKFormModalSelector,
  openTasksCalendarModalSelector,
  painFunctionGraphsSelectedConditionSelector,
  careTeamSelectedConditionSelector,
  setOpenMSKFormModalAction,
  setOpenTasksCalendarSModalAction,
  setPainFunctionGraphsSelectedConditionAction,
  setCareTeamSelectedConditionAction,
  useAppDispatch,
  useAppSelector,
} from 'src/features/shared/infrastructure'
import { addBodyScroll, removeBodyScroll } from 'src/features/shared/utils'

export const usePatientPageStore = () => {
  const dispatch = useAppDispatch()

  const openMSKFormModal = useAppSelector(openMSKFormModalSelector)
  const openTasksCalendarModal = useAppSelector(openTasksCalendarModalSelector)
  const painFunctionGraphsSelectedCondition = useAppSelector(
    painFunctionGraphsSelectedConditionSelector
  )
  const careTeamSelectedCondition = useAppSelector(
    careTeamSelectedConditionSelector
  )

  const setOpenMSKFormModal = (openMSKFormModal: boolean) => {
    dispatch(setOpenMSKFormModalAction(openMSKFormModal))
    if (openMSKFormModal) {
      removeBodyScroll()
    } else {
      addBodyScroll()
    }
  }

  const setOpenTasksCalendarModal = (openTasksCalendarModal: boolean) => {
    dispatch(setOpenTasksCalendarSModalAction(openTasksCalendarModal))
  }

  const setPainFunctionGraphsSelectedCondition = (
    selectedCondition: PatientPageState['painFunctionGraphsSelectedCondition']
  ) => {
    dispatch(setPainFunctionGraphsSelectedConditionAction(selectedCondition))
  }

  const setCareTeamSelectedCondition = (
    selectedCondition: PatientPageState['careTeamSelectedCondition']
  ) => {
    dispatch(setCareTeamSelectedConditionAction(selectedCondition))
  }

  return {
    openMSKFormModal,
    setOpenMSKFormModal,
    openTasksCalendarModal,
    setOpenTasksCalendarModal,
    painFunctionGraphsSelectedCondition,
    setPainFunctionGraphsSelectedCondition,
    careTeamSelectedCondition,
    setCareTeamSelectedCondition,
  }
}
