import { useQuery, useQueryClient } from '@tanstack/react-query'
import { usePatientsUseCases } from 'src/features/patients/patients.service-locator'
import {
  GetPatientV2Error,
  GetPatientV2Returns,
} from 'src/features/patients/domain'
import { usePatientId } from 'src/features/shared/presentation'
import { GET_PATIENT_DRAFT_NOTE } from 'src/features/notes/presentation/hooks/use-get-draft-note'

export const GET_PATIENT_QUERY_KEY_V2 = 'getPatientV2'

type UseGetPatientQueryV2Props = {
  patientId?: string
  throwGetPatientIdError?: boolean
}

export const useGetPatientQueryV2 = ({
  patientId,
  throwGetPatientIdError = true,
}: UseGetPatientQueryV2Props = {}) => {
  const patientsUseCases = usePatientsUseCases()
  const queryClient = useQueryClient()

  // TODO: find a better way to get the patient id
  const _patientId = usePatientId({ patientId, throwGetPatientIdError })

  const { data, isLoading, isError } = useQuery<
    GetPatientV2Returns | null,
    GetPatientV2Error
  >(
    [GET_PATIENT_QUERY_KEY_V2, _patientId],
    () => {
      return _patientId ? patientsUseCases.getPatientV2(_patientId) : null
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [GET_PATIENT_DRAFT_NOTE, _patientId],
        })
      },
    }
  )

  return {
    patient: data || null,
    getPatientIsLoading: isLoading,
    getPatientIsError: isError,
  }
}
