import { useQuery } from '@tanstack/react-query'
import { usePatientId } from 'src/features/shared/presentation'
import {
  GetAllTasksByPatientIdError,
  GetTasksByPatientIdParams,
  GetAllTasksByPatientIdReturns,
} from 'src/features/tasks/domain'
import { useTasksUseCases } from 'src/features/tasks/tasks.service-locator'

export const GET_ALL_TASKS_BY_PATIENT_ID_QUERY_KEY = 'getAllTasksByPatientId'

type UseGetAllTasksByPatientIdArgs = {
  patientId?: string
  throwGetPatientIdError?: boolean
  params?: GetTasksByPatientIdParams
}

export const useGetAllTasksByPatientIdQuery = ({
  patientId,
  params,
  throwGetPatientIdError = true,
}: UseGetAllTasksByPatientIdArgs = {}) => {
  const taskUseCase = useTasksUseCases()

  // TODO: find a better way to get the patient id
  const _patientId = usePatientId({ patientId, throwGetPatientIdError })

  const { data, refetch, isLoading, isError, isFetching, isRefetching } =
    useQuery<GetAllTasksByPatientIdReturns, GetAllTasksByPatientIdError>(
      [GET_ALL_TASKS_BY_PATIENT_ID_QUERY_KEY, _patientId],
      () => {
        return _patientId
          ? taskUseCase.getAllTasksByPatientId({
              patientId: _patientId,
              params,
            })
          : Promise.reject(
              new Error(
                'Patient ID is required as a parameter or in the url as path param.'
              )
            )
      }
    )

  return {
    patientAllTasksData: data,
    patientAllTasks: data?.tasks ? data.tasks : [],
    refetchPatientAllTasks: refetch,
    patientAllTasksIsLoading: isLoading,
    patientAllTasksIsError: isError,
    patientAllTasksIsFetching: isFetching,
    patientAllTasksIsRefetching: isRefetching,
  }
}
