import { ErrorMessage } from '@hookform/error-message'
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { FC } from 'react'
import {
  FieldPath,
  FieldValues,
  useController,
  useFormContext,
} from 'react-hook-form'
import { BODY_REGION_TO_NOTE_MULTI_SELECT_OPTIONS } from 'src/features/msk/presentation'

type BodyRegionFieldProps = {
  name?: FieldPath<FieldValues>
  required?: boolean
}

export const BodyRegionField: FC<BodyRegionFieldProps> = ({
  required,
  name = 'bodyRegionToNote',
}) => {
  const { control } = useFormContext()
  const { field, fieldState } = useController({ name, control })
  const selectedBodyRegions: string[] = field.value
    ? field.value.split(', ')
    : []

  const handleChange = (
    event: SelectChangeEvent<typeof selectedBodyRegions>
  ) => {
    const value = event.target.value
    if (Array.isArray(value)) {
      field.onChange(value.join(', '))
    } else {
      field.onChange(value)
    }
  }

  return (
    <FormControl size={'small'} sx={{ width: '100%' }}>
      <InputLabel error={fieldState.invalid} required={required}>
        Any specific body region to note?
      </InputLabel>
      <Select
        label={'Any specific body region to note?'}
        multiple
        value={selectedBodyRegions}
        onBlur={field.onBlur}
        onChange={handleChange}
        displayEmpty
        error={fieldState.invalid}
        required={required}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
      >
        {BODY_REGION_TO_NOTE_MULTI_SELECT_OPTIONS.map((bodyRegion) => (
          <MenuItem key={bodyRegion.id} value={bodyRegion.display}>
            <Checkbox
              checked={selectedBodyRegions.includes(bodyRegion.display)}
            />
            <ListItemText primary={bodyRegion.display} />
          </MenuItem>
        ))}
      </Select>
      <ErrorMessage
        name={name}
        render={({ message }) => (
          <FormHelperText error>{message}</FormHelperText>
        )}
      />
    </FormControl>
  )
}
