import {
  NotesState,
  currentNotesPatientIdSelector,
  setCurrentNotesPatientIdAction,
  noteTextSelector,
  selectedNoteTypeSelector,
  setNoteTextAction,
  setSelectedNoteTypeAction,
  resetNotesStateAction,
  setNotesStateAction,
  useAppDispatch,
  useAppSelector,
} from 'src/features/shared/infrastructure'

export const useNotesStore = () => {
  const dispatch = useAppDispatch()

  const currentNotesPatientId = useAppSelector(currentNotesPatientIdSelector)
  const selectedNoteType = useAppSelector(selectedNoteTypeSelector)
  const noteText = useAppSelector(noteTextSelector)

  const setCurrentNotesPatientId = (
    currentNotesPatientId: NotesState['currentNotesPatientId']
  ) => dispatch(setCurrentNotesPatientIdAction(currentNotesPatientId))

  const setSelectedNoteType = (
    selectedNoteType: NotesState['selectedNoteType']
  ) => dispatch(setSelectedNoteTypeAction(selectedNoteType))

  const setNoteText = (noteText: NotesState['noteText']) =>
    dispatch(setNoteTextAction(noteText))

  const resetNotesState = (patientId: NotesState['currentNotesPatientId']) =>
    dispatch(resetNotesStateAction(patientId))

  const setNotesState = (newState: Partial<NotesState> = {}) =>
    dispatch(setNotesStateAction(newState))

  return {
    currentNotesPatientId,
    setCurrentNotesPatientId,
    selectedNoteType,
    setSelectedNoteType,
    noteText,
    setNoteText,
    resetNotesState,
    setNotesState,
  }
}
