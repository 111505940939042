import {
  UseMutateFunction,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

import {
  CreatedPatient,
  CreatePatientArgs,
  CreatePatientError,
  CreatePatientReturns,
} from 'src/features/patients/domain'
import { GET_QUEUE_PATIENTS_QUERY_KEY } from 'src/features/queues/presentation'
import { GET_ELIGIBLE_PATIENTS_QUERY_KEY } from 'src/features/eligibility/presentation'
import { usePatientsUseCases } from 'src/features/patients/patients.service-locator'

type useCreatePatientProps = Omit<
  UseMutationOptions<
    CreatePatientReturns,
    CreatePatientError,
    CreatePatientArgs,
    unknown
  >,
  'mutationFn'
>

type UseCreatePatient = (props?: useCreatePatientProps) => {
  createPatient: UseMutateFunction<
    CreatePatientReturns,
    CreatePatientError,
    CreatePatientArgs,
    unknown
  >
  createPatientIsLoading: boolean
  createPatientIsError: boolean
  createPatientStatus: string
}

export const useCreatePatient: UseCreatePatient = (props = {}) => {
  const queryClient = useQueryClient()
  const patientsUseCases = usePatientsUseCases()

  const { onSuccess } = props

  const { mutate, isLoading, isError, status } = useMutation<
    CreatedPatient,
    CreatePatientError,
    CreatePatientArgs,
    unknown
  >({
    mutationFn: (args: CreatePatientArgs) => {
      return patientsUseCases.createPatient(args)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [GET_QUEUE_PATIENTS_QUERY_KEY],
      })
      queryClient.invalidateQueries({
        queryKey: [GET_ELIGIBLE_PATIENTS_QUERY_KEY],
      })
      onSuccess?.(data, variables, context)
    },
  })
  return {
    createPatient: mutate,
    createPatientIsLoading: isLoading,
    createPatientIsError: isError,
    createPatientStatus: status,
  }
}
