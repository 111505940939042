import {
  CreatePayorServicePayload,
  CreatePayorServiceArgs,
  CreatePayorServiceResponse,
  SPayor,
  CreatePayorServiceErrorResponse,
  GetPayorsServiceResponse,
  GetPayorsServiceErrorResponse,
} from 'src/features/providers/infrastructure'
import {
  Payor,
  CreatePayorArgs,
  CreatePayorError,
  CreatePayorPayload,
  CreatePayorReturns,
  GetPayorsError,
  GetPayorsReturns,
} from 'src/features/providers/domain'

type MapToPayor = (payor: SPayor) => Payor

export const mapToPayor: MapToPayor = (payor) => {
  return {
    name: payor.payor,
    id: payor.id,
  }
}

// -----------
// GET PAYORS /
// -----------

type MapToGetPayorsReturns = (
  response: GetPayorsServiceResponse
) => GetPayorsReturns

export const mapToGetPayorsReturns: MapToGetPayorsReturns = (response) => {
  return response.payors.map((payor) => {
    return { name: payor.payor, id: payor.id }
  })
}

export type MapToGetPayorsError = (
  error: GetPayorsServiceErrorResponse
) => GetPayorsError

export const mapToGetPayorsError: MapToGetPayorsError = (error) => ({
  ...error,
})

type MapToMultiSelectOptionPayor = (payor: Payor) => {
  label: string
  value: string
  key: string
}

export const mapPayorToMultiSelectOption: MapToMultiSelectOptionPayor = (
  payor
) => {
  return {
    label: payor.name,
    value: payor.id,
    key: payor.id,
  }
}

// -------------
// CREATE PAYOR /
// -------------

type MapToCreatePayorServicePayload = (
  payload: CreatePayorPayload
) => CreatePayorServicePayload

export const mapToCreatePayorPayload: MapToCreatePayorServicePayload = (
  payload
) => ({
  payor: payload.name,
})

type MapToCreatePayorServiceArgs = (
  args: CreatePayorArgs
) => CreatePayorServiceArgs

export const mapToCreatePayorServiceArgs: MapToCreatePayorServiceArgs = (
  args
) => ({
  payload: mapToCreatePayorPayload(args.payload),
})

type MapToCreatePayorReturns = (
  response: CreatePayorServiceResponse
) => CreatePayorReturns

export const mapToCreatePayorReturns: MapToCreatePayorReturns = (response) => {
  return mapToPayor(response)
}

type MapToCreatePayorError = (
  error: CreatePayorServiceErrorResponse
) => CreatePayorError

export const mapToCreatePayorError: MapToCreatePayorError = (error) => ({
  ...error,
})
