import { useEffect } from 'react'
import { UseFormReturn, FieldValues, Path } from 'react-hook-form'

import { DraftNote, NoteType } from 'src/features/notes/domain'
import { useNotesStore } from 'src/features/shared/infrastructure'
import { mapToNoteTypeStorageKey } from 'src/features/notes/adapters'

const NOTE_DATA_LOCAL_STORAGE_KEY_PREFIX = 'note-data'

type DraftNoteDataKey = keyof DraftNote['data']

type DraftNoteKey = keyof Omit<DraftNote, 'data'>

type UseNoteSessionArgs<T extends FieldValues> = {
  patientId: string
  noteType: NoteType
  formMethods: UseFormReturn<T>
  draftNote: DraftNote
}

type FormPersistConfig<T extends FieldValues> = {
  formMethods: UseFormReturn<T>
  draftNote: DraftNote
}

// Reference: https://www.npmjs.com/package/react-hook-form-persist
const useNoteFormDataPersist = <T extends FieldValues>(
  key: string,
  { formMethods, draftNote }: FormPersistConfig<T>
) => {
  const exclude = ['note']
  const validate = false
  const dirty = false
  const touch = false
  const { watch, setValue, getValues } = formMethods
  const fieldNames = Object.keys(getValues())
  const watchedValues = watch()

  const getStorage = () => window.localStorage

  const restoreFromLocalStorage = (storageData: string) => {
    const storageObject = JSON.parse(storageData)
    const dataRestored: { [key: string]: any } = {}
    Object.keys(storageObject).forEach((key) => {
      const shouldSet = !exclude.includes(key) && fieldNames.includes(key)
      if (shouldSet) {
        const fieldName = key as Path<T>
        dataRestored[fieldName] = storageObject[fieldName]
        setValue(fieldName, storageObject[fieldName], {
          shouldValidate: validate,
          shouldDirty: dirty,
          shouldTouch: touch,
        })
      }
    })
  }

  const restoreFromDraftNote = (draftNote: DraftNote) => {
    const dataRestored: { [key: string]: any } = {}
    const { data: draftNoteData, ...draftNoteWithoutData } = draftNote

    Object.keys(draftNoteWithoutData).forEach((key) => {
      const draftNoteFieldName = key as DraftNoteKey
      let fieldName: string = draftNoteFieldName
      if (draftNoteFieldName === 'referralSourceChangedTo') {
        fieldName = 'referralSource'
      }
      const shouldSet =
        !exclude.includes(fieldName) && fieldNames.includes(fieldName)
      if (shouldSet && draftNoteWithoutData[draftNoteFieldName] !== undefined) {
        dataRestored[fieldName] = draftNoteWithoutData[draftNoteFieldName]
        const _fieldName = fieldName as Path<T>
        const newValue = draftNoteWithoutData[draftNoteFieldName] as any
        setValue(_fieldName, newValue, {
          shouldValidate: validate,
          shouldDirty: dirty,
          shouldTouch: touch,
        })
      }
    })

    if (draftNoteData) {
      Object.keys(draftNoteData).forEach((key) => {
        const draftNoteDataFieldName = key as DraftNoteDataKey
        const fieldName: string = draftNoteDataFieldName
        const shouldSet =
          !exclude.includes(fieldName) && fieldNames.includes(fieldName)
        if (shouldSet && draftNoteData[draftNoteDataFieldName] !== undefined) {
          dataRestored[fieldName] = draftNoteData[draftNoteDataFieldName]
          const _fieldName = fieldName as Path<T>
          const newValue = draftNoteData[draftNoteDataFieldName] as any
          setValue(_fieldName, newValue, {
            shouldValidate: validate,
            shouldDirty: dirty,
            shouldTouch: touch,
          })
        }
      })
    }
  }

  useEffect(() => {
    const storageData = getStorage().getItem(key)

    if (storageData) {
      restoreFromLocalStorage(storageData)
      return
    }

    restoreFromDraftNote(draftNote)
  }, [])

  useEffect(() => {
    const values = exclude.length
      ? Object.entries(watchedValues)
          .filter(([key]) => !exclude.includes(key))
          .reduce((obj, [key, val]) => Object.assign(obj, { [key]: val }), {})
      : Object.assign({}, watchedValues)

    if (Object.entries(values).length) {
      getStorage().setItem(key, JSON.stringify(values))
    }
  }, [watchedValues])

  return {
    clear: () => getStorage().removeItem(key),
  }
}

export const useNoteSession = <T extends FieldValues>({
  patientId,
  noteType,
  formMethods,
  draftNote,
}: UseNoteSessionArgs<T>) => {
  const { setSelectedNoteType, setNoteText } = useNotesStore()

  const noteKeyPreffix = mapToNoteTypeStorageKey(noteType)

  const storageKey = `${NOTE_DATA_LOCAL_STORAGE_KEY_PREFIX}-${noteKeyPreffix}-${patientId}`

  const noteDataStorage = useNoteFormDataPersist(storageKey, {
    formMethods,
    draftNote,
  })

  return {
    clear: () => {
      noteDataStorage.clear()
      setSelectedNoteType('')
      setNoteText('')
    },
  }
}
