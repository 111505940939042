import {
  UseMutateFunction,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { useProvidersUseCases } from 'src/features/providers/providers.service-locator'
import {
  CreateProviderError,
  CreateProviderArgs,
  CreateProviderReturns,
} from 'src/features/providers/domain'
import { GET_PROVIDERS_QUERY_KEY } from 'src/features/providers/presentation'

type useCreateProviderProps = Omit<
  UseMutationOptions<
    CreateProviderReturns,
    CreateProviderError,
    CreateProviderArgs,
    unknown
  >,
  'mutationFn'
>

type UseCreateProvider = (props?: useCreateProviderProps) => {
  createProvider: UseMutateFunction<
    CreateProviderReturns,
    CreateProviderError,
    CreateProviderArgs,
    unknown
  >
  createProviderIsLoading: boolean
  createProviderIsError: boolean
  createProviderStatus: string
}

export const useCreateProviderQuery: UseCreateProvider = (props = {}) => {
  const providersUseCases = useProvidersUseCases()
  const queryClient = useQueryClient()

  const { onSuccess } = props

  const { mutate, isLoading, isError, status } = useMutation<
    CreateProviderReturns,
    CreateProviderError,
    CreateProviderArgs,
    unknown
  >({
    mutationFn: (args: CreateProviderArgs) => {
      return providersUseCases.createProvider(args)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [GET_PROVIDERS_QUERY_KEY],
      })
      onSuccess?.(data, variables, context)
    },
  })

  return {
    createProvider: mutate,
    createProviderIsLoading: isLoading,
    createProviderIsError: isError,
    createProviderStatus: status,
  }
}
