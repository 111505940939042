import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, Stack } from '@mui/material'
import { DevTool } from '@hookform/devtools'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'

import {
  FormLayoutWithCancelSave,
  ControlledNoteField,
  SDMField,
  SDMPatientUnderstandsField,
  DISCHARGE_POSITIONS,
  useStatusDefinitions,
  PROCEDURE_TYPES,
  SURGERY_SITES,
  ControlledSelectField,
  SPOKE_WITH_OPTIONS,
  YES_NO_OPTIONS,
  PatientGoalsSelect,
  PainAndFunctionField,
  StatusField,
} from 'src/features/shared/presentation/'
import {
  NoteFormProps,
  useCreatePatientPostOpTouchpointNote,
  noteFieldLabelDefaultSx,
  NOTE_FIELD_LABEL,
  PostOpTouchpointQuestionsFormV2Values,
  PostOpTouchpointAdditionalQuestionsFormV2,
  usePainAndFunctionField,
  HealthEquityQuestionsForm,
} from 'src/features/notes/presentation/'
import {
  filterNoneValueOptions,
  mapToCreatePatientPostOpTouchpointNoteV2Args,
  mapToUpdateDraftNoteArgs,
} from 'src/features/notes/adapters'
import {
  useCreatePatientPainAndFunction,
  useUpdatePatientStatus,
} from 'src/features/patients/presentation'
import { useEffect } from 'react'
import { useNotesStore } from 'src/features/shared/infrastructure'
import { mapToPatientUpdateStatus } from 'src/features/patients/adapters'
import { mapToControlledSelectItem } from 'src/features/shared/adapters'
import { useGetPatientIntakeFlowsQuery } from 'src/features/msk/presentation'
import { useUpdateDraftNote } from 'src/features/notes/presentation'

const isSdmTextRequired = (sdm?: boolean) => !!sdm

const isSdmPatientUnderstandsConditionRequired = (sdm?: boolean) => !!sdm

const isSdmClinicalBelievesPatientUnderstandsConditionRequired = (
  sdm: boolean
) => !!sdm

const isPainControlledRequired = (painLevels: number[]) =>
  painLevels.some((painLevel) => painLevel > 7)
const isAccessToMedicineToControlPainRequired = (isPainControlled?: string) =>
  isPainControlled === 'No'

const isPhysicalTherapyLocationRequired = (participatingInPT?: string) =>
  participatingInPT === 'Yes'

const isAgreeYouUnderstandConditionRequired = (
  healthEquityRequired: boolean,
  spokeWith?: string
) => {
  return spokeWith
    ? ['Patient', 'Caregiver'].includes(spokeWith) && healthEquityRequired
    : false
}
const isAgreeYouUnderstandTreatmentOptionsRequired = (
  healthEquityRequired: boolean,
  spokeWith?: string
) => {
  return spokeWith
    ? ['Patient', 'Caregiver'].includes(spokeWith) && healthEquityRequired
    : false
}

const isReasonForRefusalRequired = (status?: string) => status === 'Refused'

export type PostOpTouchpointNoteFormV2Fields =
  PostOpTouchpointQuestionsFormV2Values & {
    note: string
    status: string
    spokeWith?: string
    sdm: boolean
    sdmText?: string
    procedure?: string
    surgerySite?: string
    dischargePosition?: string
    communication: string
  }

type PostOpTouchpointNoteFormV2Props = NoteFormProps

export const PostOpTouchpointNoteFormV2 = ({
  patient,
  setShowLoader,
  draftNote,
}: PostOpTouchpointNoteFormV2Props) => {
  const { getAllStatusDefinitions } = useStatusDefinitions(patient)

  const { updatePatientStatusAsync } = useUpdatePatientStatus()

  const { createPatientPostOpTouchpointNoteAsync } =
    useCreatePatientPostOpTouchpointNote()
  const { setSelectedNoteType } = useNotesStore()

  const { intakes } = useGetPatientIntakeFlowsQuery()
  const { getPainValues, ...painAndFunctionField } = usePainAndFunctionField({
    intakes,
    patientId: patient.patientId,
    noteType: 'Post-Op Touchpoint',
    draftNotePainAndFunction: draftNote.painAndFunction,
  })
  const { createMultiplePatientPainAndFunction } =
    useCreatePatientPainAndFunction()
  const { updateDraftNoteAsync } = useUpdateDraftNote()

  const createPostOpTouchpointNoteFormSchema: yup.Schema<PostOpTouchpointNoteFormV2Fields> =
    yup.object().shape({
      note: yup.string().required('Required'),
      sdm: yup.boolean().required('Required'),
      sdmText: yup.string().when('sdm', {
        is: isSdmTextRequired,
        then: (schema) => schema.required('Required'),
      }),
      spokeWith: yup.string().required('Required'),
      status: yup.string().required('Required'),
      reasonForRefusal: yup.string().when('status', {
        is: isReasonForRefusalRequired,
        then: (schema) => schema.required('Required'),
      }),
      procedure: yup.string().required('Required'),
      surgerySite: yup.string().required('Required'),
      dischargePosition: yup.string().required('Required'),
      communication: yup.string().required('Required'),

      isPainControlled: yup.string().when('$painLevels', {
        is: isPainControlledRequired,
        then: (schema) => schema.required('Required'),
      }),
      accessToMedicineToControlPain: yup.string().when('isPainControlled', {
        is: isAccessToMedicineToControlPainRequired,
        then: (schema) => schema.required('Required'),
      }),

      participatingInPT: yup.string(),
      physicalTherapyLocation: yup.string().when('participatingInPT', {
        is: isPhysicalTherapyLocationRequired,
        then: (schema) => schema.required('Required'),
      }),
      additionalQuestionsOrConcerns: yup.string(),
      sdmPatientUnderstandsCondition: yup.string().when('sdm', {
        is: isSdmPatientUnderstandsConditionRequired,
        then: (schema) => schema.required('Required'),
      }),
      sdmClinicalBelievesPatientUnderstandsCondition: yup.string().when('sdm', {
        is: isSdmClinicalBelievesPatientUnderstandsConditionRequired,
        then: (schema) => schema.required('Required'),
      }),
      agreeYouUnderstandCondition: yup
        .string()
        .when(['$healthEquityRequired', 'spokeWith'], {
          is: isAgreeYouUnderstandConditionRequired,
          then: (schema) => schema.required('Required'),
        }),
      agreeYouUnderstandTreatmentOptions: yup
        .string()
        .when(['$healthEquityRequired', 'spokeWith'], {
          is: isAgreeYouUnderstandTreatmentOptionsRequired,
          then: (schema) => schema.required('Required'),
        }),
      patientGoals: yup.string(),
    })

  const formMethods = useForm<PostOpTouchpointNoteFormV2Fields>({
    resolver: yupResolver(createPostOpTouchpointNoteFormSchema),
    context: {
      healthEquityRequired: patient.healthAndEquityQuestionsRoundTwoRequired,
      painLevels: getPainValues(),
    },
    defaultValues: {
      note: '',
      sdm: false,
      sdmText: '',
      spokeWith: '',
      status: '',
      reasonForRefusal: '',
      procedure: '',
      surgerySite: '',
      dischargePosition: '',
      communication: 'outbound',
      isPainControlled: '',
      accessToMedicineToControlPain: '',
      participatingInPT: '',
      physicalTherapyLocation: '',
      additionalQuestionsOrConcerns: '',
      patientGoals: '',
      sdmPatientUnderstandsCondition: '',
      sdmClinicalBelievesPatientUnderstandsCondition: '',
    },
    mode: 'onBlur',
  })

  const participatingInPTWatcher = formMethods.watch('participatingInPT')
  const sdmWatcher = formMethods.watch('sdm')
  const statusWatcher = formMethods.watch('status')
  const isPainControlledWatcher = formMethods.watch('isPainControlled')
  const spokeWithWatcher = formMethods.watch('spokeWith')

  const submitHandler: SubmitHandler<PostOpTouchpointNoteFormV2Fields> = async (
    data
  ) => {
    setShowLoader?.(true)
    const painAndFunctionFieldHasAtLeastOneActive =
      painAndFunctionField.hasAtLeastOneActive
    const painAndFunctionFieldState = structuredClone(
      painAndFunctionField.state
    )
    painAndFunctionField.reset()

    const createPatientPostOpTouchpointNoteArgs =
      mapToCreatePatientPostOpTouchpointNoteV2Args(patient, data, draftNote.id)

    try {
      await createPatientPostOpTouchpointNoteAsync(
        createPatientPostOpTouchpointNoteArgs
      )

      toast.success('Patient Note successfully created!')
      painAndFunctionField.reset()
    } catch (error) {
      toast.error('Failed creating patient note!', { autoClose: false })
    }

    if (patient.stageAndStatus && patient.stageAndStatus.stage && data.status) {
      const createPatientUpdateStatusArgs = mapToPatientUpdateStatus(
        patient,
        data.status
      )
      try {
        await updatePatientStatusAsync(createPatientUpdateStatusArgs)
        toast.success('Patient Status successfully updated!')
      } catch (error) {
        toast.error('Patient Status update failed!', { autoClose: false })
      }
    }

    if (painAndFunctionFieldHasAtLeastOneActive) {
      await createMultiplePatientPainAndFunction(
        patient,
        intakes,
        painAndFunctionFieldState
      )
    }

    setSelectedNoteType('')
    setShowLoader?.(false)
  }

  const handleSubmit = (data: PostOpTouchpointNoteFormV2Fields) => {
    if (
      painAndFunctionField.hasAtLeastOneActive &&
      painAndFunctionField.hasErrors
    ) {
      formMethods.trigger()
      return
    }
    submitHandler(data)
  }

  const saveDraftHandler: SubmitHandler<
    PostOpTouchpointNoteFormV2Fields
  > = async (data) => {
    setShowLoader?.(true)

    const painAndFunctionFieldState = structuredClone(
      painAndFunctionField.state
    )

    const updatePatientCustomNoteArgs = mapToUpdateDraftNoteArgs(
      patient,
      draftNote.id,
      {
        ...data,
        type: 'Post-Op Touchpoint',
        painAndFunction: painAndFunctionFieldState.values,
      }
    )

    try {
      await updateDraftNoteAsync(updatePatientCustomNoteArgs)
      toast.success('Patient Note Draft successfully Saved!')
    } catch (error) {
      toast.error('Failed Saving Patient Note Draft!', { autoClose: false })
    }

    setShowLoader?.(false)
  }

  const handleSaveDraft = (data: PostOpTouchpointNoteFormV2Fields) => {
    saveDraftHandler(data)
  }

  useEffect(() => {
    const subscription = formMethods.watch((value, { name, type }) => {
      if (
        name === 'participatingInPT' &&
        type === 'change' &&
        value['physicalTherapyLocation']
      ) {
        formMethods.reset((formValues) => ({
          ...formValues,
          physicalTherapyLocation: '',
        }))
      }
    })

    return () => subscription.unsubscribe()
  }, [formMethods])

  useEffect(() => {
    const painLevels = getPainValues()

    if (painLevels.every((painLevel) => painLevel < 8)) {
      formMethods.reset((formValues) => ({
        ...formValues,
        isPainControlled: '',
        accessToMedicineToControlPain: '',
      }))
    }
  }, [formMethods, getPainValues])

  return (
    <FormLayoutWithCancelSave
      onSubmit={handleSubmit}
      onSaveDraftClick={handleSaveDraft}
      formMethods={formMethods}
      draftNote={draftNote}
    >
      <Grid container rowSpacing={2} pb={'16px'}>
        <Grid item sm={12} pb={'16px'}>
          <ControlledNoteField />
        </Grid>
        <Grid item container columnSpacing={2} sm={12}>
          <Grid item sm={6}>
            <StatusField
              statusRequired
              reasonForRefusalRequired={isReasonForRefusalRequired(
                statusWatcher
              )}
              statuses={getAllStatusDefinitions()}
            />
          </Grid>
        </Grid>
        <Grid item container columnSpacing={2} sm={12}>
          <Grid item sm={2}>
            <ControlledSelectField
              name={'spokeWith'}
              label={NOTE_FIELD_LABEL.spokeWith}
              labelComponent={'inputLabel'}
              items={SPOKE_WITH_OPTIONS.map(mapToControlledSelectItem)}
              formControlProps={{
                size: 'small',
              }}
              formControlSx={{
                width: '100%',
              }}
              required
            />
          </Grid>
        </Grid>

        <Grid container item sm={12} spacing={2}>
          <Grid item sm={4}>
            <ControlledSelectField
              name={'procedure'}
              label={NOTE_FIELD_LABEL.procedure}
              labelComponent={'inputLabel'}
              items={PROCEDURE_TYPES.map(mapToControlledSelectItem)}
              formControlSx={{
                width: '100%',
              }}
              formControlProps={{
                size: 'small',
              }}
              selectProps={{
                MenuProps: {
                  slotProps: {
                    paper: {
                      style: {
                        maxHeight: '200px',
                        marginLeft: '75px',
                      },
                    },
                  },
                },
              }}
              required
            />
          </Grid>
          <Grid item sm={2.5}>
            <ControlledSelectField
              name={'surgerySite'}
              label={NOTE_FIELD_LABEL.surgerySite}
              labelComponent={'inputLabel'}
              items={SURGERY_SITES.map(mapToControlledSelectItem)}
              required
              formControlProps={{
                size: 'small',
              }}
              formControlSx={{
                width: '100%',
              }}
            />
          </Grid>
          <Grid item sm={3}>
            <ControlledSelectField
              name={'dischargePosition'}
              label={NOTE_FIELD_LABEL.dischargePosition}
              labelComponent={'inputLabel'}
              items={DISCHARGE_POSITIONS.map(mapToControlledSelectItem)}
              formControlSx={{
                width: '100%',
              }}
              formControlProps={{
                size: 'small',
              }}
              required
            />
          </Grid>
        </Grid>
        <Grid item container columnGap={'16px'} sm={10}>
          <Box pt={1} width="100%">
            <SDMField />
          </Box>
          {sdmWatcher === true ? (
            <Stack direction={'column'} rowGap={'16px'} ml={'80px'} py={'16px'}>
              <PatientGoalsSelect />

              <ControlledSelectField
                name={'sdmPatientUnderstandsCondition'}
                label={NOTE_FIELD_LABEL.sdmPatientUnderstandsCondition}
                items={filterNoneValueOptions(YES_NO_OPTIONS).map(
                  mapToControlledSelectItem
                )}
                required={isSdmPatientUnderstandsConditionRequired(sdmWatcher)}
                selectSx={{ width: '88px' }}
                formLabelSx={noteFieldLabelDefaultSx}
              />
              <SDMPatientUnderstandsField
                required={isSdmClinicalBelievesPatientUnderstandsConditionRequired(
                  sdmWatcher
                )}
              />
            </Stack>
          ) : null}
        </Grid>

        {isAgreeYouUnderstandConditionRequired(
          patient.healthAndEquityQuestionsRoundTwoRequired,
          spokeWithWatcher
        ) &&
        isAgreeYouUnderstandTreatmentOptionsRequired(
          patient.healthAndEquityQuestionsRoundTwoRequired,
          spokeWithWatcher
        ) ? (
          <Grid item sm={12}>
            <HealthEquityQuestionsForm
              agreeYouUnderstandConditionRequired={true}
              agreeYouUnderstandTreatmentOptionsRequired={true}
              showTitle={sdmWatcher}
            />
          </Grid>
        ) : null}

        {intakes.length ? (
          <Grid item sm={12}>
            <PainAndFunctionField
              intakes={intakes}
              fieldMethods={{ getPainValues, ...painAndFunctionField }}
            />
          </Grid>
        ) : null}

        <Grid item sm={12}>
          <PostOpTouchpointAdditionalQuestionsFormV2
            isPainControlledRequired={isPainControlledRequired(getPainValues())}
            accessToMedicineToControlPainRequired={isAccessToMedicineToControlPainRequired(
              isPainControlledWatcher
            )}
            physicalTherapyLocationRequired={isPhysicalTherapyLocationRequired(
              participatingInPTWatcher
            )}
          />
        </Grid>
      </Grid>
      <DevTool control={formMethods.control} placement={'top-left'} />
    </FormLayoutWithCancelSave>
  )
}
