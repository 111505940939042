import { ChangeEvent, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { SelectChangeEvent } from '@mui/material'
import { DateRange } from '@mui/x-date-pickers-pro'

import {
  QueuePatientsStateSelectedFilters,
  useQueuePatientsStore,
} from 'src/features/shared/infrastructure'
import { useQueuePatientsQueryParams } from 'src/features/queues/presentation'
import {
  MultiSelectOption,
  useCurrentPage,
  useGetNavigatorsQuery,
} from 'src/features/shared/presentation'
import { formatDate } from 'src/features/shared/utils'

type QueuePatientsFilterErrors = Partial<
  Record<keyof QueuePatientsStateSelectedFilters, string>
>

export const useQueuePatientsFilters = () => {
  const navigate = useNavigate()
  const { getCurrentPage } = useCurrentPage()
  const isLaunchpadHomePage = getCurrentPage() === '/queues'
  const [lastDateOfAttemptDatePickerOpen, setLastDateOfAttemptDatePickerOpen] =
    useState(false)
  const [errors, setErrors] = useState<QueuePatientsFilterErrors>({})

  const {
    getReferralSource: getReferralSourceQueryParam,
    getStage: getStageQueryParam,
    getLinkedPlanId: getLinkedPlanIdQueryParam,
    getStatus: getStatusQueryParam,
    getMarket: getMarketQueryParam,
    getLastToContact: getLastToContactQueryParam,
    getAssignedTo: getAssignedToQueryParam,
    updateQueuePatientsQueryParams,
    getAttemptsQtyFrom: getAttemptsQtyFromQueryParam,
    getAttemptsQtyTo: getAttemptsQtyToQueryParam,
    getDateOfLastAttemptStart: getDateOfLastAttemptStartQueryParam,
    getDateOfLastAttemptEnd: getDateOfLastAttemptEndQueryParam,
  } = useQueuePatientsQueryParams()

  const {
    hasAtLeastOneQueuePatientsFilterSelected,
    openQueuePatientsFilterModal,
    queuePatientsSelectedAssignedToFilter,
    queuePatientsSelectedAttempsQtyFromFilter,
    queuePatientsSelectedAttempsQtyToFilter,
    queuePatientsSelectedDateOfLastAttemptEndFilter,
    queuePatientsSelectedDateOfLastAttemptStartFilter,
    queuePatientsSelectedLastToContactFilter,
    queuePatientsSelectedLinkedPlanIdFilter,
    queuePatientsSelectedStatusFilter,
    queuePatientsSelectedMarketFilter,
    queuePatientsSelectedReferralSourceFilter,
    queuePatientsSelectedStageFilter,
    setOpenQueuePatientsFilterModal,
    setQueuePatientsSelectedAssignedToFilter,
    setQueuePatientsSelectedAttemptsQtyFromFilter,
    setQueuePatientsSelectedAttemptsQtyToFilter,
    setQueuePatientsSelectedDateOfLastAttemptEndFilter,
    setQueuePatientsSelectedDateOfLastAttemptStartFilter,
    setQueuePatientsSelectedLastToContactFilter,
    setQueuePatientsSelectedLinkedPlanIdFilter,
    setQueuePatientsSelectedStatusFilter,
    setQueuePatientsSelectedMarketFilter,
    setQueuePatientsSelectedReferralSourceFilter,
    setQueuePatientsSelectedStageFilter,
  } = useQueuePatientsStore()

  const { navigators, getNavigatorsIsLoading } = useGetNavigatorsQuery()

  const getFormFieldSx = () => {
    return {
      width: '300px',
    }
  }
  const getMultiSelectFormControlSx = () => {
    return {
      ...getFormFieldSx(),
    }
  }

  const setInitialState = () => {
    setQueuePatientsSelectedReferralSourceFilter(
      getReferralSourceQueryParam().join(',')
    )

    setQueuePatientsSelectedStageFilter(getStageQueryParam().join(','))

    setQueuePatientsSelectedLinkedPlanIdFilter(
      getLinkedPlanIdQueryParam().join(',')
    )

    setQueuePatientsSelectedStatusFilter(getStatusQueryParam().join(','))

    setQueuePatientsSelectedMarketFilter(getMarketQueryParam().join(','))

    setQueuePatientsSelectedLastToContactFilter(
      getLastToContactQueryParam().join(',')
    )

    setQueuePatientsSelectedAssignedToFilter(
      getAssignedToQueryParam().join(',')
    )

    setQueuePatientsSelectedAttemptsQtyFromFilter(
      getAttemptsQtyFromQueryParam()
    )

    setQueuePatientsSelectedAttemptsQtyToFilter(getAttemptsQtyToQueryParam())

    setQueuePatientsSelectedDateOfLastAttemptStartFilter(
      getDateOfLastAttemptStartQueryParam()
    )

    setQueuePatientsSelectedDateOfLastAttemptEndFilter(
      getDateOfLastAttemptEndQueryParam()
    )
  }

  const clearReferralSource = () => {
    setQueuePatientsSelectedReferralSourceFilter('')
  }

  const clearStatus = () => {
    setQueuePatientsSelectedStatusFilter('')
  }

  const clearMarket = () => {
    setQueuePatientsSelectedMarketFilter('')
  }

  const clearStage = () => {
    setQueuePatientsSelectedStageFilter('')
  }

  const clearLinkedPlanId = () => {
    setQueuePatientsSelectedLinkedPlanIdFilter('')
  }

  const clearLastToContact = () => {
    setQueuePatientsSelectedLastToContactFilter('')
  }

  const clearAssignedTo = () => {
    setQueuePatientsSelectedAssignedToFilter('')
  }

  const clearDateOfLastAttempt = () => {
    setQueuePatientsSelectedDateOfLastAttemptStartFilter('')
    setQueuePatientsSelectedDateOfLastAttemptEndFilter('')
    deleteErrors(['dateOfLastAttemptEnd'])
  }

  const clearAttemptQty = () => {
    setQueuePatientsSelectedAttemptsQtyFromFilter('')
    setQueuePatientsSelectedAttemptsQtyToFilter('')
    deleteErrors(['attemptsQtyTo'])
  }

  const clearState = () => {
    clearReferralSource()
    clearStage()
    clearLinkedPlanId()
    clearStatus()
    clearLastToContact()
    clearAssignedTo()
    clearDateOfLastAttempt()
    clearAttemptQty()
  }

  const getAllSelectedFiltersFormatted = () => {
    return {
      referralSource: queuePatientsSelectedReferralSourceFilter.join(','),
      stage: queuePatientsSelectedStageFilter.join(','),
      linkedPlanId: queuePatientsSelectedLinkedPlanIdFilter.join(','),
      status: queuePatientsSelectedStatusFilter.join(','),
      market: queuePatientsSelectedMarketFilter.join(','),
      lastToContact: queuePatientsSelectedLastToContactFilter.join(','),
      assignedTo: queuePatientsSelectedAssignedToFilter.join(','),
      dateOfLastAttemptStart: queuePatientsSelectedDateOfLastAttemptStartFilter,
      dateOfLastAttemptEnd: queuePatientsSelectedDateOfLastAttemptEndFilter,
      attemptsQtyFrom: queuePatientsSelectedAttempsQtyFromFilter,
      attemptsQtyTo: queuePatientsSelectedAttempsQtyToFilter,
    }
  }

  const getCurrentSelectedFiltersAsURLSearchParams = () => {
    return new URLSearchParams(getAllSelectedFiltersFormatted())
  }

  const setQueryParams = () => {
    updateQueuePatientsQueryParams(getAllSelectedFiltersFormatted())
  }

  const handleModalClose = () => {
    setOpenQueuePatientsFilterModal(false)
  }

  const handleCancelClick = () => {
    setOpenQueuePatientsFilterModal(false)
    setInitialState()
  }

  const lastDateOfAttemptValues: DateRange<Date> = useMemo(() => {
    const start = queuePatientsSelectedDateOfLastAttemptStartFilter
      ? new Date(queuePatientsSelectedDateOfLastAttemptStartFilter)
      : null
    const end = queuePatientsSelectedDateOfLastAttemptEndFilter
      ? new Date(queuePatientsSelectedDateOfLastAttemptEndFilter)
      : null

    return [start, end]
  }, [
    queuePatientsSelectedDateOfLastAttemptEndFilter,
    queuePatientsSelectedDateOfLastAttemptStartFilter,
  ])

  const deleteErrors = (
    fields: (keyof QueuePatientsStateSelectedFilters)[]
  ) => {
    setErrors((prevState) => {
      const newState = { ...prevState }
      fields.forEach((field) => {
        delete newState[field]
      })
      return newState
    })
  }

  /**
   * Returns a change event handler for updating the filter value.
   *
   * @param {function} setter - The setter function to update the filter value.
   * @returns {function} The change event handler.
   */
  const getChangeFilterHandler =
    (setter: (value: string) => void) =>
    ({ target }: SelectChangeEvent<MultiSelectOption['value'][]>) => {
      const { value } = target
      let newValue = ''
      if (Array.isArray(value) && value.length > 0) {
        // Filter the selected values to get the user types
        const selectedUserTypes = value.filter((val) =>
          ['admin', 'clinical', 'non-clinical'].includes(val as string)
        )

        // Filter the selected values to get the individual users
        const selectedIndividualUsers = value.filter(
          (val) =>
            !['admin', 'clinical', 'non-clinical'].includes(val as string)
        )

        if (selectedUserTypes.length > 0) {
          // If user types are selected, find the corresponding navigators
          const selectedNavigators = navigators.filter((navigator) =>
            selectedUserTypes.includes(navigator.type!)
          )

          // Extract the IDs of the selected navigators and join them into a string
          newValue = selectedNavigators
            .map((navigator) => navigator.id)
            .join(',')
        } else {
          // If no user types are selected, join the selected individual users into a string
          newValue = selectedIndividualUsers.join(',')
        }
      }
      // Update the filter value using the setter function
      setter(newValue)
    }

  const getDateOfLastAttemptFilterHandler = (range: DateRange<Date>) => {
    if (range[0]) {
      setQueuePatientsSelectedDateOfLastAttemptStartFilter(formatDate(range[0]))
    }
    if (range[1]) {
      setQueuePatientsSelectedDateOfLastAttemptEndFilter(formatDate(range[1]))
      if (range[0] && range[0] > range[1]) {
        deleteErrors(['dateOfLastAttemptEnd'])
      }
    }
  }

  const getAttemptsQtyFromFilterHandler = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setQueuePatientsSelectedAttemptsQtyFromFilter(event.target.value)
  }

  const getAttemptsQtyToFilterHandler = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value
    setQueuePatientsSelectedAttemptsQtyToFilter(value)
    if (
      queuePatientsSelectedAttempsQtyFromFilter &&
      Number(queuePatientsSelectedAttempsQtyFromFilter) > Number(value)
    ) {
      deleteErrors(['attemptsQtyTo'])
    } else {
      deleteErrors(['attemptsQtyTo'])
    }
  }

  const isFormValid = () => {
    const newErrors: QueuePatientsFilterErrors = {}

    if (
      queuePatientsSelectedDateOfLastAttemptStartFilter &&
      queuePatientsSelectedDateOfLastAttemptEndFilter &&
      new Date(queuePatientsSelectedDateOfLastAttemptStartFilter) >
        new Date(queuePatientsSelectedDateOfLastAttemptEndFilter)
    ) {
      newErrors.dateOfLastAttemptEnd =
        'End date must be greater than start date.'
    }

    if (
      queuePatientsSelectedDateOfLastAttemptStartFilter &&
      queuePatientsSelectedDateOfLastAttemptEndFilter &&
      new Date(queuePatientsSelectedDateOfLastAttemptStartFilter) >
        new Date(queuePatientsSelectedDateOfLastAttemptEndFilter)
    ) {
      newErrors.dateOfLastAttemptEnd =
        'End date must be greater than start date.'
    }

    if (
      queuePatientsSelectedAttempsQtyFromFilter &&
      queuePatientsSelectedAttempsQtyToFilter &&
      Number(queuePatientsSelectedAttempsQtyFromFilter) >
        Number(queuePatientsSelectedAttempsQtyToFilter)
    ) {
      newErrors.attemptsQtyTo =
        "Attempts quantity 'To' must be greater than attempts quantity 'From'."
    }

    if (Object.keys(newErrors).length) {
      setErrors((prevState) => {
        return {
          ...prevState,
          ...newErrors,
        }
      })
      return false
    }

    return true
  }

  const handleApplyClick = () => {
    if (!isFormValid()) {
      return
    }

    if (isLaunchpadHomePage) {
      const urlSearchParams = getCurrentSelectedFiltersAsURLSearchParams()
      navigate(`/queues/patients?${urlSearchParams.toString()}`)
    } else {
      setQueryParams()
    }
    setOpenQueuePatientsFilterModal(false)
  }

  return {
    clearAssignedTo,
    clearLastToContact,
    clearLinkedPlanId,
    clearReferralSource,
    clearStage,
    clearState,
    clearStatus,
    clearMarket,
    errors,
    getAttemptsQtyFromFilterHandler,
    getAttemptsQtyToFilterHandler,
    getChangeFilterHandler,
    getDateOfLastAttemptFilterHandler,
    getFormFieldSx,
    getMultiSelectFormControlSx,
    getNavigatorsIsLoading,
    handleApplyClick,
    handleCancelClick,
    handleModalClose,
    hasAtLeastOneQueuePatientsFilterSelected,
    isFormValid,
    navigators,
    openQueuePatientsFilterModal,
    queuePatientsSelectedAssignedToFilter,
    queuePatientsSelectedLastToContactFilter,
    queuePatientsSelectedLinkedPlanIdFilter,
    queuePatientsSelectedStatusFilter,
    queuePatientsSelectedMarketFilter,
    queuePatientsSelectedReferralSourceFilter,
    queuePatientsSelectedStageFilter,
    queuePatientsSelectedDateOfLastAttemptStartFilter,
    queuePatientsSelectedDateOfLastAttemptEndFilter,
    queuePatientsSelectedAttempsQtyFromFilter,
    queuePatientsSelectedAttempsQtyToFilter,
    setErrors,
    setInitialState,
    setQueuePatientsSelectedAssignedToFilter,
    setQueuePatientsSelectedLastToContactFilter,
    setQueuePatientsSelectedLinkedPlanIdFilter,
    setQueuePatientsSelectedStatusFilter,
    setQueuePatientsSelectedMarketFilter,
    setQueuePatientsSelectedReferralSourceFilter,
    setQueuePatientsSelectedStageFilter,
    clearDateOfLastAttempt,
    clearAttemptQty,
    lastDateOfAttemptDatePickerOpen,
    setLastDateOfAttemptDatePickerOpen,
    lastDateOfAttemptValues,
  }
}
