import { Box, FormHelperText } from '@mui/material'
import { ErrorMessage } from '@hookform/error-message'
import { FC, useEffect, useRef } from 'react'
import {
  FieldPath,
  FieldValues,
  useController,
  useFormContext,
} from 'react-hook-form'
import ReactRouterPrompt from 'react-router-prompt'

import { isInViewport } from 'src/features/shared/utils'
import { WarningModal } from 'src/features/shared/presentation/components/modals'
import { NoteField } from 'src/features/shared/presentation'
import { useNotesStore } from 'src/features/shared/infrastructure'

type NoteFieldProps = {
  name?: FieldPath<FieldValues>
}

export const ControlledNoteField: FC<NoteFieldProps> = ({ name = 'note' }) => {
  const noteEditorContainer = useRef<HTMLDivElement>(null)
  const { noteText } = useNotesStore()
  const { control } = useFormContext()
  const { fieldState, field } = useController({ name, control })

  useEffect(() => {
    field.onChange(noteText)
  }, [noteText])

  return (
    <Box ref={noteEditorContainer} width={'100%'}>
      <NoteField onBlur={field.onBlur} />
      <ErrorMessage
        name={name}
        render={({ message }) => (
          <FormHelperText error>{message}</FormHelperText>
        )}
      />
      <ReactRouterPrompt when={fieldState.isDirty}>
        {({ isActive, onConfirm, onCancel }) => (
          <WarningModal
            title={'Are you sure you want to leave?'}
            message={'If you leave this page, unsaved notes may be lost.'}
            open={isActive}
            onConfirm={onConfirm}
            onCancel={() => {
              onCancel()
              setTimeout(() => {
                if (!isInViewport(noteEditorContainer?.current)) {
                  noteEditorContainer?.current?.scrollIntoView()
                }
              }, 0)
            }}
            confirmButtonText={'Yes'}
            cancelButtonText={'Cancel'}
          />
        )}
      </ReactRouterPrompt>
    </Box>
  )
}
