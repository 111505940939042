import axios from 'axios'

import { usePartnersService } from 'src/features/providers/infrastructure'

import { useAxiosErrorHandling } from 'src/features/shared/infrastructure'
import { PartnersUseCases } from 'src/features/providers/domain'
import {
  mapToGetPartnersError,
  mapToGetPartnersReturns,
} from 'src/features/providers/adapters'

export const usePartnersServiceAdapter = () => {
  const partnersService = usePartnersService()
  const { handleAxiosError } = useAxiosErrorHandling()

  const getPartners: PartnersUseCases['getPartners'] = async () => {
    try {
      const response = await partnersService.getPartners()

      return mapToGetPartnersReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToGetPartnersError)
      }
      throw error
    }
  }

  return {
    getPartners,
  }
}
