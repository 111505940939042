import { ReferralSource } from 'src/features/patients/domain'
import {
  REFERRAL_SOURCES,
  REMOVED_REFERRAL_SOURCES,
} from 'src/features/shared/constants'

/**
 * A wrapper for "JSON.parse()"" to support "undefined" value
 */
export function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '')
  } catch {
    return undefined
  }
}

export function readLocalStorage<TReturn>(key: string, initialValue?: TReturn) {
  try {
    const item = window.localStorage.getItem(key)
    return item ? (parseJSON(item) as TReturn) : initialValue
  } catch (error) {
    return initialValue
  }
}

export function saveInLocalStorage<TValue = any>(key: string, value: TValue) {
  window.localStorage.setItem(key, JSON.stringify(value))
}

export function removeFromLocalStorage(key: string) {
  window.localStorage.removeItem(key)
}

export function readSessionStorage<TReturn>(
  key: string,
  initialValue?: TReturn
) {
  try {
    const item = window.sessionStorage.getItem(key)
    return item ? (parseJSON(item) as TReturn) : initialValue
  } catch (error) {
    return initialValue
  }
}

export function saveInSessionStorage<TValue = any>(key: string, value: TValue) {
  window.sessionStorage.setItem(key, JSON.stringify(value))
}

export function removeFromSessionStorage(key: string) {
  window.sessionStorage.removeItem(key)
}

export const getReferralSources = (currentReferralSource?: string | null) => {
  const referralSources = [...REFERRAL_SOURCES]

  if (currentReferralSource) {
    const _currentReferralSource = currentReferralSource as ReferralSource
    if (REMOVED_REFERRAL_SOURCES.includes(_currentReferralSource)) {
      referralSources.unshift(_currentReferralSource)
    }
  }

  return referralSources
}

export function groupObjectBy<TObject, TKey extends string | number | symbol>(
  array: TObject[],
  propertyFunction: (obj: TObject) => TKey
) {
  type Result = Record<TKey, TObject[]>
  const acc: Result = {} as Result
  array.forEach((obj) => {
    const key = propertyFunction(obj)
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(obj)
  })
  return acc
}
