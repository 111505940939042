import { UsePatientsUseCases } from 'src/features/patients/domain'
import { usePatientsServiceAdapter } from 'src/features/patients/adapters'

type MakeUsePatientsUseCases = () => UsePatientsUseCases

const makeUsePatientsUseCases: MakeUsePatientsUseCases = () => () => {
  const patientsServiceAdapter = usePatientsServiceAdapter()

  return {
    createPatient: patientsServiceAdapter.createPatient,
    createPatientManually: patientsServiceAdapter.createPatientManually,
    getPatientV2: patientsServiceAdapter.getPatientV2,
    getPatientPainAndFunctionV2:
      patientsServiceAdapter.getPatientPainAndFunctionV2,
    createPatientPainAndFunction:
      patientsServiceAdapter.createPatientPainAndFunction,
    getPatientStatusDefinitions:
      patientsServiceAdapter.getPatientStatusDefinitions,
    searchPatients: patientsServiceAdapter.searchPatients,
    searchDuplicatePatients: patientsServiceAdapter.searchDuplicatePatients,
    updatePatient: patientsServiceAdapter.updatePatient,
    updatePatientStatus: patientsServiceAdapter.updatePatientStatus,
    getPatientGoals: patientsServiceAdapter.getPatientGoals,
  }
}

export const usePatientsUseCases: UsePatientsUseCases =
  makeUsePatientsUseCases()
