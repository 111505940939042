import { GetPatientIntakeFlowsServiceResponse } from 'src/features/msk/infrastructure'

export const getPatientIntakeFlowsServiceResponseMock: GetPatientIntakeFlowsServiceResponse =
  {
    intakeFlows: [
      {
        id: '2978015c-0d8b-4002-88c6-bcaacdb535bc',
        createdAt: '2023-09-20T16:28:49.969Z',
        patientVitals: {
          id: '7815b463-f6df-4ac4-9368-a0549bde637a',
          weight: null,
          height: null,
        },
        additionalQuestions: {
          questions: {},
          id: 'e9abb734-a469-4e1a-a376-c39af720293f',
        },
        intakes: [
          {
            botheredBodyPart: 'Back-Neck',
            chronicCondition: true,
            timeExperiencingSymptoms: '0-2 weeks',
            recommendedActionId: '002a',
            bodyRegionToNote: null,
            isTraumaPatient: true,
            id: 'c121164e-7d57-4db1-8b77-ab3135d9b364',
            intakeAssessment: {
              botheredBodyPart: '001',
              '047q': {
                id: '047q',
                answer: 'yes',
              },
              '062q': {
                id: '062q',
                answer: 'yes',
              },
            },
            createdAt: '2023-09-20T16:28:50.066014+00:00',
            recommendedAction: null,
            status: 'Inactive',
            currentPainLevel: null,
            currentFunctionLevel: null,
            hardStop: false,
            capturedBy: '5aef0dd5-1eb1-492f-928d-2e37c82facfb',
            associatedPathway: null,
            patientActions: null,
            enablingTherapies: null,
            skipped: false,
          },
          {
            botheredBodyPart: 'Left Hip',
            chronicCondition: true,
            timeExperiencingSymptoms: '0-2 weeks',
            recommendedActionId: '002a',
            bodyRegionToNote: null,
            isTraumaPatient: true,
            id: '84247372-2404-4e2b-a0f0-a3d07bb84e8f',
            intakeAssessment: {
              botheredBodyPart: '002',
              '047q': {
                id: '047q',
                answer: 'yes',
              },
              '062q': {
                id: '062q',
                answer: 'yes',
              },
            },
            createdAt: '2023-09-20T16:28:50.066014+00:00',
            recommendedAction: null,
            status: 'Inactive',
            currentPainLevel: null,
            currentFunctionLevel: null,
            hardStop: false,
            capturedBy: '5aef0dd5-1eb1-492f-928d-2e37c82facfb',
            associatedPathway: null,
            patientActions: null,
            enablingTherapies: null,
            skipped: false,
          },
          {
            botheredBodyPart: 'Left Knee',
            chronicCondition: true,
            timeExperiencingSymptoms: '0-2 weeks',
            recommendedActionId: '002a',
            bodyRegionToNote: null,
            isTraumaPatient: true,
            id: 'f30fe68e-eb21-46bd-8385-923f6a18f6f3',
            intakeAssessment: {
              botheredBodyPart: '003',
              '047q': {
                id: '047q',
                answer: 'yes',
              },
              '062q': {
                id: '062q',
                answer: 'yes',
              },
            },
            createdAt: '2023-09-20T16:28:50.066014+00:00',
            recommendedAction: null,
            status: 'Inactive',
            currentPainLevel: null,
            currentFunctionLevel: null,
            hardStop: false,
            capturedBy: '5aef0dd5-1eb1-492f-928d-2e37c82facfb',
            associatedPathway: null,
            patientActions: null,
            enablingTherapies: null,
            skipped: false,
          },
        ],
      },
      {
        id: 'ffe2e82f-5b3a-4100-9d5a-d10af38740f4',
        createdAt: '2023-09-20T16:27:18.000Z',
        patientVitals: {
          id: 'df2a4264-4c81-4b61-aa7e-978a4cee77b2',
          weight: null,
          height: null,
        },
        additionalQuestions: {
          questions: {},
          id: 'a90db118-8d09-446f-b9b6-25dce48a7def',
        },
        intakes: [
          {
            botheredBodyPart: 'Left Hip',
            chronicCondition: true,
            timeExperiencingSymptoms: '0-2 weeks',
            recommendedActionId: '002a',
            bodyRegionToNote: null,
            isTraumaPatient: true,
            id: 'dd5ebf14-d333-40cc-848d-730df99b613a',
            intakeAssessment: {
              botheredBodyPart: '002',
              '047q': {
                id: '047q',
                answer: 'yes',
              },
              '062q': {
                id: '062q',
                answer: 'yes',
              },
            },
            createdAt: '2023-09-20T16:27:18.08898+00:00',
            recommendedAction: null,
            status: 'Inactive',
            currentPainLevel: null,
            currentFunctionLevel: null,
            hardStop: false,
            capturedBy: '5aef0dd5-1eb1-492f-928d-2e37c82facfb',
            associatedPathway: null,
            patientActions: null,
            enablingTherapies: null,
            skipped: false,
          },
          {
            botheredBodyPart: 'Left Knee',
            chronicCondition: true,
            timeExperiencingSymptoms: '0-2 weeks',
            recommendedActionId: '002a',
            bodyRegionToNote: null,
            isTraumaPatient: true,
            id: '3770b16d-0a59-4b59-841d-fa38f69a79db',
            intakeAssessment: {
              botheredBodyPart: '003',
              '047q': {
                id: '047q',
                answer: 'yes',
              },
              '062q': {
                id: '062q',
                answer: 'yes',
              },
            },
            createdAt: '2023-09-20T16:27:18.08898+00:00',
            recommendedAction: null,
            status: 'Inactive',
            currentPainLevel: null,
            currentFunctionLevel: null,
            hardStop: false,
            capturedBy: '5aef0dd5-1eb1-492f-928d-2e37c82facfb',
            associatedPathway: null,
            patientActions: null,
            enablingTherapies: null,
            skipped: false,
          },
          {
            botheredBodyPart: 'Left Shoulder',
            chronicCondition: true,
            timeExperiencingSymptoms: '0-2 weeks',
            recommendedActionId: '002a',
            bodyRegionToNote: null,
            isTraumaPatient: true,
            id: '6755b95e-8735-48c5-8ecb-787093a67602',
            intakeAssessment: {
              botheredBodyPart: '004',
              '047q': {
                id: '047q',
                answer: 'yes',
              },
              '062q': {
                id: '062q',
                answer: 'yes',
              },
            },
            createdAt: '2023-09-20T16:27:18.08898+00:00',
            recommendedAction: null,
            status: 'Inactive',
            currentPainLevel: null,
            currentFunctionLevel: null,
            hardStop: false,
            capturedBy: '5aef0dd5-1eb1-492f-928d-2e37c82facfb',
            associatedPathway: null,
            patientActions: null,
            enablingTherapies: null,
            skipped: false,
          },
        ],
      },
      {
        id: 'fd12d8f8-c967-46f0-9d0d-1d37c127bc65',
        createdAt: '2023-09-20T16:26:24.561Z',
        patientVitals: {
          id: 'b890b7c8-c118-4eab-b772-b635b2adced3',
          weight: null,
          height: null,
        },
        additionalQuestions: {
          questions: {},
          id: '79759aa1-7aa8-4428-98b6-4306b11ea57e',
        },
        intakes: [
          {
            botheredBodyPart: 'Left Hip',
            chronicCondition: true,
            timeExperiencingSymptoms: '0-2 weeks',
            recommendedActionId: '002a',
            bodyRegionToNote: null,
            isTraumaPatient: true,
            id: '16007832-4337-410e-8434-47ad3ee21b0b',
            intakeAssessment: {
              botheredBodyPart: '002',
              '047q': {
                id: '047q',
                answer: 'yes',
              },
              '062q': {
                id: '062q',
                answer: 'yes',
              },
            },
            createdAt: '2023-09-20T16:26:24.664626+00:00',
            recommendedAction: null,
            status: 'Inactive',
            currentPainLevel: null,
            currentFunctionLevel: null,
            hardStop: false,
            capturedBy: '5aef0dd5-1eb1-492f-928d-2e37c82facfb',
            associatedPathway: null,
            patientActions: null,
            enablingTherapies: null,
            skipped: false,
          },
          {
            botheredBodyPart: 'Left Knee',
            chronicCondition: true,
            timeExperiencingSymptoms: '0-2 weeks',
            recommendedActionId: '002a',
            bodyRegionToNote: null,
            isTraumaPatient: true,
            id: '4ccf4c53-2909-42c0-a98b-e30970b33042',
            intakeAssessment: {
              botheredBodyPart: '003',
              '047q': {
                id: '047q',
                answer: 'yes',
              },
              '062q': {
                id: '062q',
                answer: 'yes',
              },
            },
            createdAt: '2023-09-20T16:26:24.664626+00:00',
            recommendedAction: null,
            status: 'Inactive',
            currentPainLevel: null,
            currentFunctionLevel: null,
            hardStop: false,
            capturedBy: '5aef0dd5-1eb1-492f-928d-2e37c82facfb',
            associatedPathway: null,
            patientActions: null,
            enablingTherapies: null,
            skipped: false,
          },
          {
            botheredBodyPart: 'Left Shoulder',
            chronicCondition: true,
            timeExperiencingSymptoms: '0-2 weeks',
            recommendedActionId: '002a',
            bodyRegionToNote: null,
            isTraumaPatient: true,
            id: 'd533a57b-afe3-436b-acca-500f70e71a0d',
            intakeAssessment: {
              botheredBodyPart: '004',
              '047q': {
                id: '047q',
                answer: 'yes',
              },
              '062q': {
                id: '062q',
                answer: 'yes',
              },
            },
            createdAt: '2023-09-20T16:26:24.664626+00:00',
            recommendedAction: null,
            status: 'Inactive',
            currentPainLevel: null,
            currentFunctionLevel: null,
            hardStop: false,
            capturedBy: '5aef0dd5-1eb1-492f-928d-2e37c82facfb',
            associatedPathway: null,
            patientActions: null,
            enablingTherapies: null,
            skipped: false,
          },
        ],
      },
      {
        id: 'a88e037d-4c3a-42c6-b6e9-798a7c069c1d',
        createdAt: '2023-09-20T16:21:34.355Z',
        patientVitals: {
          id: '4d93b4f2-4df5-4efe-8ca5-9a6570496870',
          weight: null,
          height: null,
        },
        additionalQuestions: {
          questions: {},
          id: '288e8a31-ceb9-4689-bf2b-1b2021429f48',
        },
        intakes: [
          {
            botheredBodyPart: 'Left Hip',
            chronicCondition: true,
            timeExperiencingSymptoms: '0-2 weeks',
            recommendedActionId: '002a',
            bodyRegionToNote: null,
            isTraumaPatient: true,
            id: 'f1b82091-bcb3-485c-a4ef-22d3c48398c6',
            intakeAssessment: {
              botheredBodyPart: '002',
              '047q': {
                id: '047q',
                answer: 'yes',
              },
              '062q': {
                id: '062q',
                answer: 'yes',
              },
            },
            createdAt: '2023-09-20T16:21:34.444885+00:00',
            recommendedAction: null,
            status: 'Inactive',
            currentPainLevel: null,
            currentFunctionLevel: null,
            hardStop: false,
            capturedBy: '5aef0dd5-1eb1-492f-928d-2e37c82facfb',
            associatedPathway: null,
            patientActions: null,
            enablingTherapies: null,
            skipped: false,
          },
          {
            botheredBodyPart: 'Left Knee',
            chronicCondition: true,
            timeExperiencingSymptoms: '0-2 weeks',
            recommendedActionId: '002a',
            bodyRegionToNote: null,
            isTraumaPatient: true,
            id: '0538e907-bc4b-4882-9505-b9f210e84454',
            intakeAssessment: {
              botheredBodyPart: '003',
              '047q': {
                id: '047q',
                answer: 'yes',
              },
              '062q': {
                id: '062q',
                answer: 'yes',
              },
            },
            createdAt: '2023-09-20T16:21:34.444885+00:00',
            recommendedAction: null,
            status: 'Inactive',
            currentPainLevel: null,
            currentFunctionLevel: null,
            hardStop: false,
            capturedBy: '5aef0dd5-1eb1-492f-928d-2e37c82facfb',
            associatedPathway: null,
            patientActions: null,
            enablingTherapies: null,
            skipped: false,
          },
          {
            botheredBodyPart: 'Left Shoulder',
            chronicCondition: true,
            timeExperiencingSymptoms: '0-2 weeks',
            recommendedActionId: '002a',
            bodyRegionToNote: null,
            isTraumaPatient: true,
            id: '5d77e1e3-c795-4b3b-a6d1-bfb0b833b3e7',
            intakeAssessment: {
              botheredBodyPart: '004',
              '047q': {
                id: '047q',
                answer: 'yes',
              },
              '062q': {
                id: '062q',
                answer: 'yes',
              },
            },
            createdAt: '2023-09-20T16:21:34.444885+00:00',
            recommendedAction: null,
            status: 'Inactive',
            currentPainLevel: null,
            currentFunctionLevel: null,
            hardStop: false,
            capturedBy: '5aef0dd5-1eb1-492f-928d-2e37c82facfb',
            associatedPathway: null,
            patientActions: null,
            enablingTherapies: null,
            skipped: false,
          },
        ],
      },
      {
        id: '6c408c0f-a1cb-46e2-ac6b-53092b3ba9c0',
        createdAt: '2023-09-20T16:13:19.278Z',
        patientVitals: {
          id: '6dd5e900-a80c-4e9d-898d-a172641060b1',
          weight: null,
          height: null,
        },
        additionalQuestions: {
          questions: {},
          id: 'd60ee7a2-676d-4413-9826-34526e31ba27',
        },
        intakes: [
          {
            botheredBodyPart: 'Left Knee',
            chronicCondition: true,
            timeExperiencingSymptoms: '0-2 weeks',
            recommendedActionId: '002a',
            bodyRegionToNote: null,
            isTraumaPatient: true,
            id: 'e2e942b3-0307-4336-8c4a-fb5627fcf4e8',
            intakeAssessment: {
              botheredBodyPart: '003',
              '047q': {
                id: '047q',
                answer: 'yes',
              },
              '062q': {
                id: '062q',
                answer: 'yes',
              },
            },
            createdAt: '2023-09-20T16:13:19.370943+00:00',
            recommendedAction: null,
            status: 'Inactive',
            currentPainLevel: null,
            currentFunctionLevel: null,
            hardStop: false,
            capturedBy: '5aef0dd5-1eb1-492f-928d-2e37c82facfb',
            associatedPathway: null,
            patientActions: null,
            enablingTherapies: null,
            skipped: false,
          },
          {
            botheredBodyPart: 'Left Shoulder',
            chronicCondition: true,
            timeExperiencingSymptoms: '0-2 weeks',
            recommendedActionId: '002a',
            bodyRegionToNote: null,
            isTraumaPatient: true,
            id: 'acf36eb3-d550-4488-946e-554a5fdd0bc5',
            intakeAssessment: {
              botheredBodyPart: '004',
              '047q': {
                id: '047q',
                answer: 'yes',
              },
              '062q': {
                id: '062q',
                answer: 'yes',
              },
            },
            createdAt: '2023-09-20T16:13:19.370943+00:00',
            recommendedAction: null,
            status: 'Inactive',
            currentPainLevel: null,
            currentFunctionLevel: null,
            hardStop: false,
            capturedBy: '5aef0dd5-1eb1-492f-928d-2e37c82facfb',
            associatedPathway: null,
            patientActions: null,
            enablingTherapies: null,
            skipped: false,
          },
        ],
      },
      {
        id: 'd42fe2f1-94a8-423a-a234-0e0c3977d44f',
        createdAt: '2023-09-20T16:12:49.462Z',
        patientVitals: null,
        additionalQuestions: {
          questions: {
            phAssessment: 'N/A',
            gad2Assessment: 'N/A',
            fallScreenResult: 'N/A',
          },
          id: '7bda59e2-9f8e-4156-b694-5ca3e87a49ed',
        },
        intakes: [
          {
            botheredBodyPart: 'Back-Neck',
            chronicCondition: true,
            timeExperiencingSymptoms: '0-2 weeks',
            recommendedActionId: '002a',
            bodyRegionToNote: null,
            isTraumaPatient: false,
            id: '0fe44474-ee95-403d-b4ab-f8869d5e0b68',
            intakeAssessment: {
              botheredBodyPart: '001',
              '001q': {
                id: '001q',
                answer: 'no',
              },
              '003q': {
                id: '003q',
                answer: 'yes',
              },
            },
            createdAt: '2023-09-20T16:12:49.551243+00:00',
            recommendedAction:
              'Ortho:Surgeon, APP if unavailable w/in 5-7 days',
            status: 'Inactive',
            currentPainLevel: 5,
            currentFunctionLevel: 5,
            hardStop: false,
            capturedBy: '5aef0dd5-1eb1-492f-928d-2e37c82facfb',
            associatedPathway: null,
            patientActions: [
              {
                id: 'ed74ebd5-d8f5-45d9-bd45-5f27dc9158bf',
                patientId: 'b993afa6-a730-468d-a715-b9f0087b6103',
                createdAt: null,
                openToTry: false,
                previouslyTried: true,
                rest: false,
                ice: false,
                elevation: false,
                compression: false,
                physicalTherapy: false,
                accupuncture: false,
                chiropracticCare: false,
                injections: false,
                surgery: false,
                opioids: false,
                otcMedication: false,
                currentlyUsingOpiods: null,
                intakeId: '0fe44474-ee95-403d-b4ab-f8869d5e0b68',
                other: false,
                otherText: null,
              },
              {
                id: '0839fbe2-b72f-49bc-9c5f-5b54b3cd0c37',
                patientId: 'b993afa6-a730-468d-a715-b9f0087b6103',
                createdAt: null,
                openToTry: true,
                previouslyTried: false,
                rest: false,
                ice: false,
                elevation: false,
                compression: false,
                physicalTherapy: false,
                accupuncture: false,
                chiropracticCare: false,
                injections: false,
                surgery: false,
                opioids: false,
                otcMedication: false,
                currentlyUsingOpiods: null,
                intakeId: '0fe44474-ee95-403d-b4ab-f8869d5e0b68',
                other: false,
                otherText: null,
              },
            ],
            enablingTherapies: null,
            skipped: false,
          },
        ],
      },
      {
        id: '5f99b366-596c-44c8-8dd1-891ae6f4c751',
        createdAt: '2023-09-20T16:11:22.991Z',
        patientVitals: {
          id: '7d86f236-2435-42ba-9338-4fe9785d1926',
          weight: null,
          height: null,
        },
        additionalQuestions: {
          questions: {},
          id: 'cee98f99-2fcc-4b73-bbe3-8438fcd7149b',
        },
        intakes: [
          {
            botheredBodyPart: 'Back-Neck',
            chronicCondition: true,
            timeExperiencingSymptoms: '0-2 weeks',
            recommendedActionId: '002a',
            bodyRegionToNote: null,
            isTraumaPatient: true,
            id: 'f91b1d91-cbf6-4397-aef4-4f87abf7f0df',
            intakeAssessment: {
              botheredBodyPart: '001',
              '047q': {
                id: '047q',
                answer: 'yes',
              },
              '062q': {
                id: '062q',
                answer: 'yes',
              },
            },
            createdAt: '2023-09-20T16:11:23.093945+00:00',
            recommendedAction: null,
            status: 'Inactive',
            currentPainLevel: null,
            currentFunctionLevel: null,
            hardStop: false,
            capturedBy: '5aef0dd5-1eb1-492f-928d-2e37c82facfb',
            associatedPathway: null,
            patientActions: null,
            enablingTherapies: null,
            skipped: false,
          },
          {
            botheredBodyPart: 'Left Hip',
            chronicCondition: true,
            timeExperiencingSymptoms: '0-2 weeks',
            recommendedActionId: '002a',
            bodyRegionToNote: null,
            isTraumaPatient: true,
            id: 'd3b17aea-d84c-4280-b2c1-9170a24b5b9b',
            intakeAssessment: {
              botheredBodyPart: '002',
              '047q': {
                id: '047q',
                answer: 'yes',
              },
              '062q': {
                id: '062q',
                answer: 'yes',
              },
            },
            createdAt: '2023-09-20T16:11:23.093945+00:00',
            recommendedAction: null,
            status: 'Inactive',
            currentPainLevel: null,
            currentFunctionLevel: null,
            hardStop: false,
            capturedBy: '5aef0dd5-1eb1-492f-928d-2e37c82facfb',
            associatedPathway: null,
            patientActions: null,
            enablingTherapies: null,
            skipped: false,
          },
          {
            botheredBodyPart: 'Left Knee',
            chronicCondition: true,
            timeExperiencingSymptoms: '0-2 weeks',
            recommendedActionId: '002a',
            bodyRegionToNote: null,
            isTraumaPatient: true,
            id: '42c62ebf-9304-44ad-8593-e2cb44a227d7',
            intakeAssessment: {
              botheredBodyPart: '003',
              '047q': {
                id: '047q',
                answer: 'yes',
              },
              '062q': {
                id: '062q',
                answer: 'yes',
              },
            },
            createdAt: '2023-09-20T16:11:23.093945+00:00',
            recommendedAction: null,
            status: 'Inactive',
            currentPainLevel: null,
            currentFunctionLevel: null,
            hardStop: false,
            capturedBy: '5aef0dd5-1eb1-492f-928d-2e37c82facfb',
            associatedPathway: null,
            patientActions: null,
            enablingTherapies: null,
            skipped: false,
          },
        ],
      },
      {
        id: 'ca073c34-9b4b-4498-82c0-4ee9a05e700c',
        createdAt: '2023-09-20T15:19:17.004Z',
        patientVitals: {
          id: 'ab1e84f3-d50e-430d-bdfa-27352e1f7272',
          weight: null,
          height: null,
        },
        additionalQuestions: {
          questions: {},
          id: '2fea4d6e-97f0-4466-b104-9d6987dcb31e',
        },
        intakes: [
          {
            botheredBodyPart: 'Right Knee',
            chronicCondition: true,
            timeExperiencingSymptoms: '0-2 weeks',
            recommendedActionId: '002a',
            bodyRegionToNote: null,
            isTraumaPatient: false,
            id: 'b98757a1-3927-4f9b-8b03-9fe76433583d',
            intakeAssessment: {
              botheredBodyPart: '009',
              '047q': {
                id: '047q',
                answer: 'no',
              },
              '062q': {
                id: '062q',
                answer: 'yes',
              },
            },
            createdAt: '2023-09-20T15:19:17.098078+00:00',
            recommendedAction: null,
            status: 'Inactive',
            currentPainLevel: null,
            currentFunctionLevel: null,
            hardStop: false,
            capturedBy: '5aef0dd5-1eb1-492f-928d-2e37c82facfb',
            associatedPathway: null,
            patientActions: null,
            enablingTherapies: null,
            skipped: false,
          },
        ],
      },
      {
        id: '8cd752af-7447-4465-a226-711a27c47cba',
        createdAt: '2023-09-20T13:52:26.181Z',
        patientVitals: null,
        additionalQuestions: null,
        intakes: null,
      },
      {
        id: '059a0b45-9296-4802-96a3-16e7510c5b03',
        createdAt: '2023-09-20T13:34:49.908Z',
        patientVitals: {
          id: '56f90ca7-7842-48d6-8c6e-c375bc1eaf66',
          weight: null,
          height: null,
        },
        additionalQuestions: {
          questions: {},
          id: '2ac6a8fa-a688-49dd-a330-06daec18f2ea',
        },
        intakes: [
          {
            botheredBodyPart: 'Right Knee',
            chronicCondition: true,
            timeExperiencingSymptoms: '0-2 weeks',
            recommendedActionId: '002a',
            bodyRegionToNote: null,
            isTraumaPatient: false,
            id: '3209920c-d3c7-4fff-b509-a3ef54a8fa42',
            intakeAssessment: {
              botheredBodyPart: '009',
              '047q': {
                id: '047q',
                answer: 'no',
              },
              '062q': {
                id: '062q',
                answer: 'no',
              },
            },
            createdAt: '2023-09-20T13:34:49.998896+00:00',
            recommendedAction: null,
            status: 'Inactive',
            currentPainLevel: null,
            currentFunctionLevel: null,
            hardStop: false,
            capturedBy: '5aef0dd5-1eb1-492f-928d-2e37c82facfb',
            associatedPathway: null,
            patientActions: null,
            enablingTherapies: null,
            skipped: false,
          },
        ],
      },
    ],
    questionsAndActions: {
      actions: {
        '001a': {
          id: '001a',
          type: 'action',
          action: 'Send to ED',
          hardstop: true,
        },
        '002a': {
          id: '002a',
          action: 'Ortho:Surgeon, APP if unavailable w/in 5-7 days',
          type: 'action',
          hardstop: false,
        },
        '003a': {
          id: '003a',
          action: 'PT:McKenzie',
          type: 'action',
          hardstop: false,
        },
        '004a': {
          id: '004a',
          action: 'Ortho:Physiatry',
          type: 'action',
          hardstop: false,
        },
        '005a': {
          id: '005a',
          action: 'Ortho:Surgeon or Physiatry',
          type: 'action',
          hardstop: false,
        },
        '006a': {
          id: '006a',
          action: 'Self Care',
          type: 'action',
          hardstop: false,
        },
        '007a': {
          id: '007a',
          action: 'Send to bariatric management and PT',
          type: 'action',
          hardstop: false,
        },
        '008a': {
          id: '008a',
          action: 'PT:Traditional',
          type: 'action',
          hardstop: false,
        },
        '009a': {
          id: '009a',
          action: 'Ortho: Surgeon or PA',
          type: 'action',
          hardstop: false,
        },
        '010a': {
          id: '010a',
          action: 'PT:CHT',
          type: 'action',
          hardstop: false,
        },
      },
      start: {
        '001': '001q',
        '002': '002q',
        '008': '002q',
        '003': '020q',
        '009': '020q',
        '004': '002q',
        '010': '002q',
        '005': '036q',
        '011': '036q',
        '006': '044q',
        '012': '044q',
        '007': '051q',
        '013': '051q',
      },
      questions: {
        '001q': {
          id: '001q',
          type: 'question',
          q: 'Do you have a loss of bowel or bladder function or numbness in the area between your rectum and scrotum/vagina?',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '001a',
                '002': '001a',
                '008': '001a',
                '003': '001a',
                '009': '001a',
                '004': '001a',
                '010': '001a',
                '005': '001a',
                '011': '001a',
                '006': '001a',
                '012': '001a',
                '007': '001a',
                '013': '001a',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '003q',
                '002': '003q',
                '008': '003q',
                '003': '003q',
                '009': '003q',
                '004': '003q',
                '010': '003q',
                '006': '003q',
                '012': '003q',
                '007': '003q',
                '013': '003q',
              },
            },
          },
        },
        '002q': {
          id: '002q',
          type: 'question',
          q: 'Was there a recent injury?',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '002': '009q',
                '008': '009q',
                '010': '010q',
                '004': '010q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '002': '015q',
                '008': '015q',
                '004': '014q',
                '010': '014q',
              },
            },
          },
        },
        '003q': {
          id: '003q',
          type: 'question',
          q: 'Do you have any weakness, trouble buttoning your shirt or constant numbness or tingling not related to movement?',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '002a',
                '002': '002a',
                '008': '002a',
                '003': '002a',
                '009': '002a',
                '004': '002a',
                '010': '002a',
                '005': '002a',
                '011': '002a',
                '006': '002a',
                '012': '002a',
                '007': '002a',
                '013': '002a',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '004q',
                '002': '004q',
                '008': '004q',
                '003': '004q',
                '009': '004q',
                '004': '004q',
                '010': '004q',
                '005': '004q',
                '011': '004q',
                '006': '004q',
                '012': '004q',
                '007': '004q',
                '013': '004q',
              },
            },
          },
        },
        '004q': {
          id: '004q',
          q: 'Have you had this pain for more than 3 months?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '005q',
                '002': '005q',
                '008': '005q',
                '003': '005q',
                '009': '005q',
                '004': '005q',
                '010': '005q',
                '005': '005q',
                '011': '005q',
                '006': '005q',
                '012': '005q',
                '007': '005q',
                '013': '005q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '007q',
                '002': '007q',
                '008': '007q',
                '003': '007q',
                '009': '007q',
                '004': '007q',
                '010': '007q',
                '005': '007q',
                '011': '007q',
                '006': '007q',
                '012': '007q',
                '007': '007q',
                '013': '007q',
              },
            },
          },
        },
        '005q': {
          id: '005q',
          q: 'Have you tried physical therapy in the past?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '006q',
                '002': '006q',
                '008': '006q',
                '003': '006q',
                '009': '006q',
                '004': '006q',
                '010': '006q',
                '005': '006q',
                '011': '006q',
                '006': '006q',
                '012': '006q',
                '007': '006q',
                '013': '006q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '003a',
                '002': '003a',
                '008': '003a',
                '003': '003a',
                '009': '003a',
                '004': '003a',
                '010': '003a',
                '005': '003a',
                '011': '003a',
                '006': '003a',
                '012': '003a',
                '007': '003a',
                '013': '003a',
              },
            },
          },
        },
        '006q': {
          id: '006q',
          q: 'We have another physical therapy option that has been shown to be more effective for your condition. Even if you have done PT in the past, would you be open to trying the McKenzie style of PT?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '003a',
                '002': '003a',
                '003': '003a',
                '004': '003a',
                '005': '003a',
                '006': '003a',
                '007': '003a',
                '008': '003a',
                '009': '003a',
                '010': '003a',
                '011': '003a',
                '012': '003a',
                '013': '003a',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '004a',
                '002': '004a',
                '003': '004a',
                '004': '004a',
                '005': '004a',
                '006': '004a',
                '007': '004a',
                '008': '004a',
                '009': '004a',
                '010': '004a',
                '011': '004a',
                '012': '004a',
                '013': '004a',
              },
            },
          },
        },
        '007q': {
          id: '007q',
          q: 'Have you had any recent trauma or injury?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '005a',
                '002': '005a',
                '003': '005a',
                '004': '005a',
                '005': '005a',
                '006': '005a',
                '007': '005a',
                '008': '005a',
                '009': '005a',
                '010': '005a',
                '011': '005a',
                '012': '005a',
                '013': '005a',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '008q',
                '002': '008q',
                '003': '008q',
                '004': '008q',
                '005': '008q',
                '006': '008q',
                '007': '008q',
                '008': '008q',
                '009': '008q',
                '010': '008q',
                '011': '008q',
                '012': '008q',
                '013': '008q',
              },
            },
          },
        },
        '008q': {
          id: '008q',
          q: 'Have you had the pain for less than 2 weeks?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '006a',
                '002': '006a',
                '008': '006a',
                '009': '006a',
                '010': '006a',
                '011': '006a',
                '012': '006a',
                '013': '006a',
                '003': '006a',
                '004': '006a',
                '005': '006a',
                '006': '006a',
                '007': '006a',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '005q',
                '002': '005q',
                '008': '005q',
                '003': '005q',
                '009': '005q',
                '004': '005q',
                '010': '005q',
                '005': '005q',
                '011': '005q',
                '006': '005q',
                '012': '005q',
                '007': '005q',
                '013': '005q',
              },
            },
          },
        },
        '009q': {
          id: '009q',
          type: 'question',
          q: 'Are you able to bear weight(stand)?',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '012q',
                '002': '012q',
                '003': '012q',
                '004': '012q',
                '005': '012q',
                '006': '012q',
                '007': '012q',
                '008': '012q',
                '009': '012q',
                '010': '012q',
                '011': '012q',
                '012': '012q',
                '013': '012q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '010q',
                '002': '010q',
                '003': '010q',
                '004': '010q',
                '005': '010q',
                '006': '010q',
                '007': '010q',
                '008': '010q',
                '009': '010q',
                '010': '010q',
                '011': '010q',
                '012': '010q',
                '013': '010q',
              },
            },
          },
        },
        '010q': {
          id: '010q',
          type: 'question',
          q: 'Have you had X-rays?',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '011q',
                '002': '011q',
                '008': '011q',
                '003': '011q',
                '009': '011q',
                '004': '011q',
                '010': '011q',
                '005': '011q',
                '011': '011q',
                '006': '011q',
                '012': '011q',
                '007': '011q',
                '013': '011q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '002a',
                '002': '002a',
                '008': '002a',
                '003': '002a',
                '009': '002a',
                '004': '002a',
                '010': '002a',
                '005': '002a',
                '011': '002a',
                '006': '002a',
                '012': '002a',
                '007': '002a',
                '013': '002a',
              },
            },
          },
        },
        '011q': {
          id: '011q',
          type: 'question',
          q: 'After your X-rays, were you told you had a fracture or dislocation?',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '002a',
                '002': '002a',
                '008': '002a',
                '003': '002a',
                '009': '002a',
                '004': '002a',
                '010': '002a',
                '005': '002a',
                '011': '002a',
                '006': '002a',
                '012': '002a',
                '007': '002a',
                '013': '002a',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '002': '012q',
                '008': '012q',
                '004': '026q',
                '010': '026q',
              },
            },
          },
        },
        '012q': {
          id: '012q',
          type: 'question',
          q: 'Is there a history of osteoarthritis?',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '014q',
                '002': '014q',
                '008': '014q',
                '003': '014q',
                '009': '014q',
                '004': '014q',
                '010': '014q',
                '005': '014q',
                '011': '014q',
                '006': '014q',
                '012': '014q',
                '007': '014q',
                '013': '014q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '014q',
                '002': '014q',
                '008': '014q',
                '003': '014q',
                '009': '014q',
                '004': '014q',
                '010': '014q',
                '005': '014q',
                '011': '014q',
                '006': '014q',
                '012': '014q',
                '007': '014q',
                '013': '014q',
              },
            },
          },
        },
        '013q': {
          id: '013q',
          type: 'question',
          q: 'Is the patient BMI over 45?',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '007a',
                '002': '007a',
                '003': '007a',
                '004': '007a',
                '005': '007a',
                '006': '007a',
                '007': '007a',
                '008': '007a',
                '009': '007a',
                '010': '007a',
                '011': '007a',
                '012': '007a',
                '013': '007a',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '014q',
                '002': '014q',
                '008': '014q',
                '003': '014q',
                '009': '014q',
                '004': '014q',
                '010': '014q',
                '005': '014q',
                '011': '014q',
                '006': '014q',
                '012': '014q',
                '007': '014q',
                '013': '014q',
              },
            },
          },
        },
        '014q': {
          id: '014q',
          type: 'question',
          q: 'Have you had treatment in the past 6 months? (Including injections, PT, pain management, etc.)',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '002a',
                '002': '002a',
                '008': '002a',
                '003': '002a',
                '009': '002a',
                '004': '002a',
                '010': '002a',
                '005': '002a',
                '011': '002a',
                '006': '002a',
                '012': '002a',
                '007': '002a',
                '013': '002a',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '002': '008a',
                '008': '008a',
                '003': '008a',
                '009': '008a',
                '004': '026q',
                '010': '026q',
              },
            },
          },
        },
        '015q': {
          id: '015q',
          type: 'question',
          q: 'Do you have sharp pain going from your buttock down the back of your leg?',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '001q',
                '002': '001q',
                '008': '001q',
                '009': '001q',
                '010': '001q',
                '011': '001q',
                '012': '001q',
                '013': '001q',
                '003': '001q',
                '004': '001q',
                '005': '001q',
                '006': '001q',
                '007': '001q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '016q',
                '002': '016q',
                '003': '016q',
                '004': '016q',
                '005': '016q',
                '006': '016q',
                '007': '016q',
                '008': '016q',
                '009': '016q',
                '010': '016q',
                '011': '016q',
                '012': '016q',
                '013': '016q',
              },
            },
          },
        },
        '016q': {
          id: '016q',
          tag: 'numbnessOrTingling',
          q: 'Do you have numbness or tingling in your foot?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '001q',
                '002': '001q',
                '008': '001q',
                '009': '001q',
                '010': '001q',
                '011': '001q',
                '012': '001q',
                '013': '001q',
                '003': '001q',
                '004': '001q',
                '005': '001q',
                '006': '001q',
                '007': '001q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '017q',
                '002': '017q',
                '003': '017q',
                '004': '017q',
                '005': '017q',
                '006': '017q',
                '007': '017q',
                '008': '017q',
                '009': '017q',
                '010': '017q',
                '011': '017q',
                '012': '017q',
                '013': '017q',
              },
            },
          },
        },
        '017q': {
          id: '017q',
          tag: 'weaknessInFoot',
          q: 'Do you have weakness in your leg or foot?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '001q',
                '002': '001q',
                '008': '001q',
                '009': '001q',
                '010': '001q',
                '011': '001q',
                '012': '001q',
                '013': '001q',
                '003': '001q',
                '004': '001q',
                '005': '001q',
                '006': '001q',
                '007': '001q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '018q',
                '002': '018q',
                '003': '018q',
                '004': '018q',
                '005': '018q',
                '006': '018q',
                '007': '018q',
                '008': '018q',
                '009': '018q',
                '010': '018q',
                '011': '018q',
                '012': '018q',
                '013': '018q',
              },
            },
          },
        },
        '018q': {
          id: '018q',
          tag: 'footSlapOrHit',
          q: 'Does your foot slap/hit the floor when you walk because you struggle to pick up the front of your foot?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '001q',
                '002': '001q',
                '008': '001q',
                '009': '001q',
                '010': '001q',
                '011': '001q',
                '012': '001q',
                '013': '001q',
                '003': '001q',
                '004': '001q',
                '005': '001q',
                '006': '001q',
                '007': '001q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '019q',
                '002': '019q',
                '003': '019q',
                '004': '019q',
                '005': '019q',
                '006': '019q',
                '007': '019q',
                '008': '019q',
                '009': '019q',
                '010': '019q',
                '011': '019q',
                '012': '019q',
                '013': '019q',
              },
            },
          },
        },
        '019q': {
          id: '019q',
          q: 'Do you have a burning pain in your buttock or back of your leg?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '001q',
                '002': '001q',
                '008': '001q',
                '009': '001q',
                '010': '001q',
                '011': '001q',
                '012': '001q',
                '013': '001q',
                '003': '001q',
                '004': '001q',
                '005': '001q',
                '006': '001q',
                '007': '001q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '009q',
                '002': '009q',
                '008': '009q',
                '009': '009q',
                '010': '009q',
                '011': '009q',
                '012': '009q',
                '013': '009q',
                '003': '009q',
                '004': '009q',
                '005': '009q',
                '006': '009q',
                '007': '009q',
              },
            },
          },
        },
        '020q': {
          id: '020q',
          q: 'Is your knee red, hot or swollen?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '002a',
                '002': '002a',
                '008': '002a',
                '003': '002a',
                '009': '002a',
                '004': '002a',
                '010': '002a',
                '005': '002a',
                '011': '002a',
                '006': '002a',
                '012': '002a',
                '007': '002a',
                '013': '002a',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '021q',
                '002': '021q',
                '003': '021q',
                '004': '021q',
                '005': '021q',
                '006': '021q',
                '007': '021q',
                '008': '021q',
                '009': '021q',
                '010': '021q',
                '011': '021q',
                '012': '021q',
                '013': '021q',
              },
            },
          },
        },
        '021q': {
          id: '021q',
          q: 'Are you able to bend and straighten your knee?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '022q',
                '002': '022q',
                '003': '022q',
                '009': '022q',
                '004': '022q',
                '005': '022q',
                '006': '022q',
                '007': '022q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '002a',
                '002': '002a',
                '008': '002a',
                '003': '002a',
                '009': '002a',
                '004': '002a',
                '010': '002a',
                '005': '002a',
                '011': '002a',
                '006': '002a',
                '012': '002a',
                '007': '002a',
                '013': '002a',
              },
            },
          },
        },
        '022q': {
          id: '022q',
          q: 'Are you able to bear weight (stand) on your leg?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '023q',
                '002': '023q',
                '003': '023q',
                '004': '023q',
                '005': '023q',
                '006': '023q',
                '007': '023q',
                '008': '023q',
                '009': '023q',
                '011': '023q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '002a',
                '002': '002a',
                '008': '002a',
                '003': '002a',
                '009': '002a',
                '004': '002a',
                '010': '002a',
                '005': '002a',
                '011': '002a',
                '006': '002a',
                '012': '002a',
                '007': '002a',
                '013': '002a',
              },
            },
          },
        },
        '023q': {
          id: '023q',
          q: 'Was there an acute injury (fall, etc.)?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '024q',
                '002': '024q',
                '003': '024q',
                '004': '024q',
                '005': '024q',
                '006': '024q',
                '007': '024q',
                '008': '024q',
                '009': '024q',
                '010': '024q',
                '011': '024q',
                '012': '024q',
                '013': '024q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '012q',
                '002': '012q',
                '003': '012q',
                '004': '012q',
                '005': '012q',
                '006': '012q',
                '007': '012q',
                '008': '012q',
                '009': '012q',
                '010': '012q',
                '011': '012q',
                '012': '012q',
                '013': '012q',
              },
            },
          },
        },
        '024q': {
          id: '024q',
          q: "Is there swelling, instability, or a feeling like you can't bend or straighten your knee?",
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '002a',
                '002': '002a',
                '008': '002a',
                '003': '002a',
                '009': '002a',
                '004': '002a',
                '010': '002a',
                '005': '002a',
                '011': '002a',
                '006': '002a',
                '012': '002a',
                '007': '002a',
                '013': '002a',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '025q',
                '002': '025q',
                '003': '025q',
                '004': '025q',
                '005': '025q',
                '006': '025q',
                '007': '025q',
                '008': '025q',
                '009': '025q',
                '010': '025q',
                '011': '025q',
                '012': '025q',
                '013': '025q',
              },
            },
          },
        },
        '025q': {
          id: '025q',
          q: 'Have you tried rest, ice, compression and elevation?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '008a',
                '002': '008a',
                '003': '008a',
                '004': '008a',
                '005': '008a',
                '006': '008a',
                '007': '008a',
                '008': '008a',
                '009': '008a',
                '010': '008a',
                '011': '008a',
                '012': '008a',
                '013': '008a',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '006a',
                '002': '006a',
                '008': '006a',
                '009': '006a',
                '010': '006a',
                '011': '006a',
                '012': '006a',
                '013': '006a',
                '003': '006a',
                '004': '006a',
                '005': '006a',
                '006': '006a',
                '007': '006a',
              },
            },
          },
        },
        '026q': {
          id: '026q',
          q: 'Do you have pain at rest (when not moving shoulder)?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '008a',
                '002': '008a',
                '003': '008a',
                '004': '027q',
                '005': '008a',
                '006': '008a',
                '007': '008a',
                '008': '008a',
                '009': '008a',
                '010': '027q',
                '011': '008a',
                '012': '008a',
                '013': '008a',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '031q',
                '002': '031q',
                '003': '031q',
                '004': '031q',
                '005': '031q',
                '006': '031q',
                '007': '031q',
                '008': '031q',
                '009': '031q',
                '010': '031q',
                '011': '031q',
                '012': '031q',
                '013': '031q',
              },
            },
          },
        },
        '027q': {
          id: '027q',
          q: 'Do you have pain in your neck?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '001q',
                '002': '001q',
                '008': '001q',
                '009': '001q',
                '010': '001q',
                '011': '001q',
                '012': '001q',
                '013': '001q',
                '003': '001q',
                '004': '001q',
                '005': '001q',
                '006': '001q',
                '007': '001q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '028q',
                '002': '028q',
                '003': '028q',
                '004': '028q',
                '005': '028q',
                '006': '028q',
                '007': '028q',
                '008': '028q',
                '009': '028q',
                '010': '028q',
                '011': '028q',
                '012': '028q',
                '013': '028q',
              },
            },
          },
        },
        '028q': {
          id: '028q',
          q: 'Is there tingling or feelings of pins and needles in your forearm/hand/fingers?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '001q',
                '002': '001q',
                '008': '001q',
                '009': '001q',
                '010': '001q',
                '011': '001q',
                '012': '001q',
                '013': '001q',
                '003': '001q',
                '004': '001q',
                '005': '001q',
                '006': '001q',
                '007': '001q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '029q',
                '002': '029q',
                '003': '029q',
                '004': '029q',
                '005': '029q',
                '006': '029q',
                '007': '029q',
                '008': '029q',
                '009': '029q',
                '010': '029q',
                '011': '029q',
                '012': '029q',
                '013': '029q',
              },
            },
          },
        },
        '029q': {
          id: '029q',
          q: 'Do you have weakness in the muscles of the arm, shoulder or hand?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '001q',
                '002': '001q',
                '008': '001q',
                '009': '001q',
                '010': '001q',
                '011': '001q',
                '012': '001q',
                '013': '001q',
                '003': '001q',
                '004': '001q',
                '005': '001q',
                '006': '001q',
                '007': '001q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '030q',
                '002': '030q',
                '003': '030q',
                '004': '030q',
                '005': '030q',
                '006': '030q',
                '007': '030q',
                '008': '030q',
                '009': '030q',
                '010': '030q',
                '011': '030q',
                '012': '030q',
                '013': '030q',
              },
            },
          },
        },
        '030q': {
          id: '030q',
          q: 'Do you have loss of sensation (feeling) in your hand or fingers?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '001q',
                '002': '001q',
                '008': '001q',
                '009': '001q',
                '010': '001q',
                '011': '001q',
                '012': '001q',
                '013': '001q',
                '003': '001q',
                '004': '001q',
                '005': '001q',
                '006': '001q',
                '007': '001q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '031q',
                '002': '031q',
                '003': '031q',
                '004': '031q',
                '005': '031q',
                '006': '031q',
                '007': '031q',
                '008': '031q',
                '009': '031q',
                '010': '031q',
                '011': '031q',
                '012': '031q',
                '013': '031q',
              },
            },
          },
        },
        '031q': {
          id: '031q',
          q: 'Are you able to perform your normal daily activities?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '032q',
                '002': '032q',
                '003': '032q',
                '004': '032q',
                '005': '032q',
                '006': '032q',
                '007': '032q',
                '008': '032q',
                '009': '032q',
                '010': '032q',
                '011': '032q',
                '012': '032q',
                '013': '032q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '002a',
                '002': '002a',
                '008': '002a',
                '003': '002a',
                '009': '002a',
                '004': '002a',
                '010': '002a',
                '005': '002a',
                '011': '002a',
                '006': '002a',
                '012': '002a',
                '007': '002a',
                '013': '002a',
              },
            },
          },
        },
        '032q': {
          id: '032q',
          q: 'Are you able to perform overhead lifting or previous hobbies?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '033q',
                '002': '033q',
                '003': '033q',
                '004': '033q',
                '005': '033q',
                '006': '033q',
                '007': '033q',
                '008': '033q',
                '009': '033q',
                '010': '033q',
                '011': '033q',
                '012': '033q',
                '013': '033q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '002a',
                '002': '002a',
                '003': '002a',
                '004': '002a',
                '005': '002a',
                '006': '002a',
                '007': '002a',
                '008': '002a',
                '009': '002a',
                '010': '002a',
                '011': '002a',
                '012': '002a',
                '013': '002a',
              },
            },
          },
        },
        '033q': {
          id: '033q',
          q: 'Are you able to lift your hand higher than shoulder level?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '034q',
                '002': '034q',
                '003': '034q',
                '004': '034q',
                '005': '034q',
                '006': '034q',
                '007': '034q',
                '008': '034q',
                '009': '034q',
                '010': '034q',
                '011': '034q',
                '012': '034q',
                '013': '034q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '002a',
                '002': '002a',
                '008': '002a',
                '003': '002a',
                '009': '002a',
                '004': '002a',
                '010': '002a',
                '005': '002a',
                '011': '002a',
                '006': '002a',
                '012': '002a',
                '007': '002a',
                '013': '002a',
              },
            },
          },
        },
        '034q': {
          id: '034q',
          q: 'Does the pain wake you up at night?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '035q',
                '002': '035q',
                '003': '035q',
                '004': '035q',
                '005': '035q',
                '006': '035q',
                '007': '035q',
                '008': '035q',
                '009': '035q',
                '010': '035q',
                '011': '035q',
                '012': '035q',
                '013': '035q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '008a',
                '002': '008a',
                '003': '008a',
                '004': '008a',
                '005': '008a',
                '006': '008a',
                '007': '008a',
                '008': '008a',
                '009': '008a',
                '010': '008a',
                '011': '008a',
                '012': '008a',
                '013': '008a',
              },
            },
          },
        },
        '035q': {
          id: '035q',
          q: 'Do you need to take medications to sleep due to the pain in your shoulder?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '002a',
                '002': '002a',
                '008': '002a',
                '003': '002a',
                '009': '002a',
                '004': '002a',
                '010': '002a',
                '005': '002a',
                '011': '002a',
                '006': '002a',
                '012': '002a',
                '007': '002a',
                '013': '002a',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '008a',
                '002': '008a',
                '003': '008a',
                '004': '008a',
                '005': '008a',
                '006': '008a',
                '007': '008a',
                '008': '008a',
                '009': '008a',
                '010': '008a',
                '011': '008a',
                '012': '008a',
                '013': '008a',
              },
            },
          },
        },
        '036q': {
          id: '036q',
          q: 'Is your ankle or foot red, hot or swollen?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '002a',
                '002': '002a',
                '008': '002a',
                '003': '002a',
                '009': '002a',
                '004': '002a',
                '010': '002a',
                '005': '002a',
                '011': '002a',
                '006': '002a',
                '012': '002a',
                '007': '002a',
                '013': '002a',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '037q',
                '002': '037q',
                '003': '037q',
                '004': '037q',
                '005': '037q',
                '006': '037q',
                '007': '037q',
                '008': '037q',
                '009': '037q',
                '010': '037q',
                '011': '037q',
                '012': '037q',
                '013': '037q',
              },
            },
          },
        },
        '037q': {
          id: '037q',
          q: 'Are you able to move your ankle and toes?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '038q',
                '002': '038q',
                '003': '038q',
                '004': '038q',
                '005': '038q',
                '006': '038q',
                '007': '038q',
                '008': '038q',
                '009': '038q',
                '010': '038q',
                '011': '038q',
                '012': '038q',
                '013': '038q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '002a',
                '002': '002a',
                '008': '002a',
                '003': '002a',
                '009': '002a',
                '004': '002a',
                '010': '002a',
                '005': '002a',
                '011': '002a',
                '006': '002a',
                '012': '002a',
                '007': '002a',
                '013': '002a',
              },
            },
          },
        },
        '038q': {
          id: '038q',
          q: 'Was there a recent injury?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '039q',
                '002': '039q',
                '003': '039q',
                '004': '039q',
                '005': '039q',
                '006': '039q',
                '007': '039q',
                '008': '039q',
                '009': '039q',
                '010': '039q',
                '011': '039q',
                '012': '039q',
                '013': '039q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '041q',
                '002': '041q',
                '003': '041q',
                '004': '041q',
                '005': '041q',
                '006': '041q',
                '007': '041q',
                '008': '041q',
                '009': '041q',
                '010': '041q',
                '011': '041q',
                '012': '041q',
                '013': '041q',
              },
            },
          },
        },
        '039q': {
          id: '039q',
          q: 'Are you able to walk at least 4 steps?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '040q',
                '002': '040q',
                '003': '040q',
                '004': '040q',
                '005': '040q',
                '006': '040q',
                '007': '040q',
                '008': '040q',
                '009': '040q',
                '010': '040q',
                '011': '040q',
                '012': '040q',
                '013': '040q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '041q',
                '002': '041q',
                '003': '041q',
                '004': '041q',
                '005': '041q',
                '006': '041q',
                '007': '041q',
                '008': '041q',
                '009': '041q',
                '010': '041q',
                '011': '041q',
                '012': '041q',
                '013': '041q',
              },
            },
          },
        },
        '040q': {
          id: '040q',
          q: 'Do you have any tenderness on the ankle or foot bones?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '002a',
                '002': '002a',
                '008': '002a',
                '003': '002a',
                '009': '002a',
                '004': '002a',
                '010': '002a',
                '005': '002a',
                '011': '002a',
                '006': '002a',
                '012': '002a',
                '007': '002a',
                '013': '002a',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '041q',
                '002': '041q',
                '003': '041q',
                '004': '041q',
                '005': '041q',
                '006': '041q',
                '007': '041q',
                '008': '041q',
                '009': '041q',
                '010': '041q',
                '011': '041q',
                '012': '041q',
                '013': '041q',
              },
            },
          },
        },
        '041q': {
          id: '041q',
          q: 'Have you tried rest, ice, compression and elevation?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '042q',
                '002': '042q',
                '003': '042q',
                '004': '042q',
                '005': '042q',
                '006': '042q',
                '007': '042q',
                '008': '042q',
                '009': '042q',
                '010': '042q',
                '011': '042q',
                '012': '042q',
                '013': '042q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '006a',
                '002': '006a',
                '008': '006a',
                '009': '006a',
                '010': '006a',
                '011': '006a',
                '012': '006a',
                '013': '006a',
                '003': '006a',
                '004': '006a',
                '005': '006a',
                '006': '006a',
                '007': '006a',
              },
            },
          },
        },
        '042q': {
          id: '042q',
          q: 'Is there a history of prior ankle or foot injury or surgery?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '043q',
                '002': '043q',
                '003': '043q',
                '004': '043q',
                '005': '043q',
                '006': '043q',
                '007': '043q',
                '008': '043q',
                '009': '043q',
                '010': '043q',
                '011': '043q',
                '012': '043q',
                '013': '043q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '008a',
                '002': '008a',
                '003': '008a',
                '004': '008a',
                '005': '008a',
                '006': '008a',
                '007': '008a',
                '008': '008a',
                '009': '008a',
                '010': '008a',
                '011': '008a',
                '012': '008a',
                '013': '008a',
              },
            },
          },
        },
        '043q': {
          id: '043q',
          q: 'Have you had treatment in the past 6 months? (Including injections, PT, pain management, etc.)',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '002a',
                '002': '002a',
                '008': '002a',
                '003': '002a',
                '009': '002a',
                '004': '002a',
                '010': '002a',
                '005': '002a',
                '011': '002a',
                '006': '002a',
                '012': '002a',
                '007': '002a',
                '013': '002a',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '008a',
                '002': '008a',
                '003': '008a',
                '004': '008a',
                '005': '008a',
                '006': '008a',
                '007': '008a',
                '008': '008a',
                '009': '008a',
                '010': '008a',
                '011': '008a',
                '012': '008a',
                '013': '008a',
              },
            },
          },
        },
        '044q': {
          id: '044q',
          q: 'Is your elbow red, hot or swollen?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '002a',
                '002': '002a',
                '008': '002a',
                '003': '002a',
                '009': '002a',
                '004': '002a',
                '010': '002a',
                '005': '002a',
                '011': '002a',
                '006': '002a',
                '012': '002a',
                '007': '002a',
                '013': '002a',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '045q',
                '002': '045q',
                '003': '045q',
                '004': '045q',
                '005': '045q',
                '006': '045q',
                '007': '045q',
                '008': '045q',
                '009': '045q',
                '010': '045q',
                '011': '045q',
                '012': '045q',
                '013': '045q',
              },
            },
          },
        },
        '045q': {
          id: '045q',
          q: 'Are you able to bend or straighten your elbow?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '046q',
                '002': '046q',
                '003': '046q',
                '004': '046q',
                '005': '046q',
                '006': '046q',
                '007': '046q',
                '008': '046q',
                '009': '046q',
                '010': '046q',
                '011': '046q',
                '012': '046q',
                '013': '046q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '002a',
                '002': '002a',
                '008': '002a',
                '003': '002a',
                '009': '002a',
                '004': '002a',
                '010': '002a',
                '005': '002a',
                '011': '002a',
                '006': '002a',
                '012': '002a',
                '007': '002a',
                '013': '002a',
              },
            },
          },
        },
        '046q': {
          id: '046q',
          q: 'Are you able to place your hand on a surface and bear weight through your elbow? (push up from seated)',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '047q',
                '002': '047q',
                '003': '047q',
                '004': '047q',
                '005': '047q',
                '006': '047q',
                '007': '047q',
                '008': '047q',
                '009': '047q',
                '010': '047q',
                '011': '047q',
                '012': '047q',
                '013': '047q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '009a',
                '002': '009a',
                '003': '009a',
                '004': '009a',
                '005': '009a',
                '006': '009a',
                '007': '009a',
                '008': '009a',
                '009': '009a',
                '010': '009a',
                '011': '009a',
                '012': '009a',
                '013': '009a',
              },
            },
          },
        },
        '047q': {
          id: '047q',
          q: 'Did you have a recent injury?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '048q',
                '002': '048q',
                '003': '048q',
                '004': '048q',
                '005': '048q',
                '006': '048q',
                '007': '048q',
                '008': '048q',
                '009': '048q',
                '010': '048q',
                '011': '048q',
                '012': '048q',
                '013': '048q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '050q',
                '002': '050q',
                '003': '050q',
                '004': '050q',
                '005': '050q',
                '006': '050q',
                '007': '050q',
                '008': '050q',
                '009': '050q',
                '010': '050q',
                '011': '050q',
                '012': '050q',
                '013': '050q',
              },
            },
          },
        },
        '048q': {
          id: '048q',
          q: 'Have you had X-rays?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '049q',
                '002': '049q',
                '003': '049q',
                '004': '049q',
                '005': '049q',
                '006': '049q',
                '007': '049q',
                '008': '049q',
                '009': '049q',
                '010': '049q',
                '011': '049q',
                '012': '049q',
                '013': '049q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '002a',
                '002': '002a',
                '008': '002a',
                '003': '002a',
                '009': '002a',
                '004': '002a',
                '010': '002a',
                '005': '002a',
                '011': '002a',
                '006': '002a',
                '012': '002a',
                '007': '002a',
                '013': '002a',
              },
            },
          },
        },
        '049q': {
          id: '049q',
          q: 'After your X-ray, were you told you had a fracture or dislocation?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '002a',
                '002': '002a',
                '008': '002a',
                '003': '002a',
                '009': '002a',
                '004': '002a',
                '010': '002a',
                '005': '002a',
                '011': '002a',
                '006': '002a',
                '012': '002a',
                '007': '002a',
                '013': '002a',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '008a',
                '002': '008a',
                '003': '008a',
                '004': '008a',
                '005': '008a',
                '006': '008a',
                '007': '008a',
                '008': '008a',
                '009': '008a',
                '010': '008a',
                '011': '008a',
                '012': '008a',
                '013': '008a',
              },
            },
          },
        },
        '050q': {
          id: '050q',
          q: 'Have you tried rest, ice, compression and elevation?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '008a',
                '002': '008a',
                '003': '008a',
                '004': '008a',
                '005': '008a',
                '006': '008a',
                '007': '008a',
                '008': '008a',
                '009': '008a',
                '010': '008a',
                '011': '008a',
                '012': '008a',
                '013': '008a',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '006a',
                '002': '006a',
                '008': '006a',
                '009': '006a',
                '010': '006a',
                '011': '006a',
                '012': '006a',
                '013': '006a',
                '003': '006a',
                '004': '006a',
                '005': '006a',
                '006': '006a',
                '007': '006a',
              },
            },
          },
        },
        '051q': {
          id: '051q',
          q: 'Is your wrist or finger red, hot or swollen?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '002a',
                '002': '002a',
                '008': '002a',
                '003': '002a',
                '009': '002a',
                '004': '002a',
                '010': '002a',
                '005': '002a',
                '011': '002a',
                '006': '002a',
                '012': '002a',
                '007': '002a',
                '013': '002a',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '052q',
                '002': '052q',
                '003': '052q',
                '004': '052q',
                '005': '052q',
                '006': '052q',
                '007': '052q',
                '008': '052q',
                '009': '052q',
                '010': '052q',
                '011': '052q',
                '012': '052q',
                '013': '052q',
              },
            },
          },
        },
        '052q': {
          id: '052q',
          q: 'Are you able to bend or straighten your finger and move your wrist?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '053q',
                '002': '053q',
                '003': '053q',
                '004': '053q',
                '005': '053q',
                '006': '053q',
                '007': '053q',
                '008': '053q',
                '009': '053q',
                '010': '053q',
                '011': '053q',
                '012': '053q',
                '013': '053q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '002a',
                '002': '002a',
                '008': '002a',
                '003': '002a',
                '009': '002a',
                '004': '002a',
                '010': '002a',
                '005': '002a',
                '011': '002a',
                '006': '002a',
                '012': '002a',
                '007': '002a',
                '013': '002a',
              },
            },
          },
        },
        '053q': {
          id: '053q',
          q: 'Do you have numbness?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '054q',
                '002': '054q',
                '003': '054q',
                '004': '054q',
                '005': '054q',
                '006': '054q',
                '007': '054q',
                '008': '054q',
                '009': '054q',
                '010': '054q',
                '011': '054q',
                '012': '054q',
                '013': '054q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '056q',
                '002': '056q',
                '003': '056q',
                '004': '056q',
                '005': '056q',
                '006': '056q',
                '007': '056q',
                '008': '056q',
                '009': '056q',
                '010': '056q',
                '011': '056q',
                '012': '056q',
                '013': '056q',
              },
            },
          },
        },
        '054q': {
          id: '054q',
          q: 'Is the numbness constant?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '002a',
                '002': '002a',
                '008': '002a',
                '003': '002a',
                '009': '002a',
                '004': '002a',
                '010': '002a',
                '005': '002a',
                '011': '002a',
                '006': '002a',
                '012': '002a',
                '007': '002a',
                '013': '002a',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '055q',
                '002': '055q',
                '003': '055q',
                '004': '055q',
                '005': '055q',
                '006': '055q',
                '007': '055q',
                '008': '055q',
                '009': '055q',
                '010': '055q',
                '011': '055q',
                '012': '055q',
                '013': '055q',
              },
            },
          },
        },
        '055q': {
          id: '055q',
          q: 'Do you have loss of the shape of your palm? (the palm no longer curls up under the thumb but is flat)',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '002a',
                '002': '002a',
                '008': '002a',
                '003': '002a',
                '009': '002a',
                '004': '002a',
                '010': '002a',
                '005': '002a',
                '011': '002a',
                '006': '002a',
                '012': '002a',
                '007': '002a',
                '013': '002a',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '056q',
                '002': '056q',
                '003': '056q',
                '004': '056q',
                '005': '056q',
                '006': '056q',
                '007': '056q',
                '008': '056q',
                '009': '056q',
                '010': '056q',
                '011': '056q',
                '012': '056q',
                '013': '056q',
              },
            },
          },
        },
        '056q': {
          id: '056q',
          q: 'Do you have pain?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '057q',
                '002': '057q',
                '003': '057q',
                '004': '057q',
                '005': '057q',
                '006': '057q',
                '007': '057q',
                '008': '057q',
                '009': '057q',
                '010': '057q',
                '011': '057q',
                '012': '057q',
                '013': '057q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '061q',
                '002': '061q',
                '003': '061q',
                '004': '061q',
                '005': '061q',
                '006': '061q',
                '007': '061q',
                '008': '061q',
                '009': '061q',
                '010': '061q',
                '011': '061q',
                '012': '061q',
                '013': '061q',
              },
            },
          },
        },
        '057q': {
          id: '057q',
          q: 'Did you experience a fall or other injury?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '058q',
                '002': '058q',
                '003': '058q',
                '004': '058q',
                '005': '058q',
                '006': '058q',
                '007': '058q',
                '008': '058q',
                '009': '058q',
                '010': '058q',
                '011': '058q',
                '012': '058q',
                '013': '058q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '060q',
                '002': '060q',
                '003': '060q',
                '004': '060q',
                '005': '060q',
                '006': '060q',
                '007': '060q',
                '008': '060q',
                '009': '060q',
                '010': '060q',
                '011': '060q',
                '012': '060q',
                '013': '060q',
              },
            },
          },
        },
        '058q': {
          id: '058q',
          q: 'Have you had X-rays?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '059q',
                '002': '059q',
                '003': '059q',
                '004': '059q',
                '005': '059q',
                '006': '059q',
                '007': '059q',
                '008': '059q',
                '009': '059q',
                '010': '059q',
                '011': '059q',
                '012': '059q',
                '013': '059q',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '002a',
                '002': '002a',
                '008': '002a',
                '003': '002a',
                '009': '002a',
                '004': '002a',
                '010': '002a',
                '005': '002a',
                '011': '002a',
                '006': '002a',
                '012': '002a',
                '007': '002a',
                '013': '002a',
              },
            },
          },
        },
        '059q': {
          id: '059q',
          q: 'After your X-ray, were you told you had a fracture or dislocation?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '002a',
                '002': '002a',
                '008': '002a',
                '003': '002a',
                '009': '002a',
                '004': '002a',
                '010': '002a',
                '005': '002a',
                '011': '002a',
                '006': '002a',
                '012': '002a',
                '007': '002a',
                '013': '002a',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '010a',
                '002': '010a',
                '003': '010a',
                '004': '010a',
                '005': '010a',
                '006': '010a',
                '007': '010a',
                '008': '010a',
                '009': '010a',
                '010': '010a',
                '011': '010a',
                '012': '010a',
                '013': '010a',
              },
            },
          },
        },
        '060q': {
          id: '060q',
          q: 'Have you had treatment in the past 6 months? (Including injections, PT, pain management, etc.)',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '009a',
                '002': '009a',
                '003': '009a',
                '004': '009a',
                '005': '009a',
                '006': '009a',
                '007': '009a',
                '008': '009a',
                '009': '009a',
                '010': '009a',
                '011': '009a',
                '012': '009a',
                '013': '009a',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '061q',
                '002': '061q',
                '003': '061q',
                '004': '061q',
                '005': '061q',
                '006': '061q',
                '007': '061q',
                '008': '061q',
                '009': '061q',
                '010': '061q',
                '011': '061q',
                '012': '061q',
                '013': '061q',
              },
            },
          },
        },
        '061q': {
          id: '061q',
          q: 'Have you tried rest, ice, compression and elevation?',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
              nextStep: {
                '001': '010a',
                '002': '010a',
                '003': '010a',
                '004': '010a',
                '005': '010a',
                '006': '010a',
                '007': '010a',
                '008': '010a',
                '009': '010a',
                '010': '010a',
                '011': '010a',
                '012': '010a',
                '013': '010a',
              },
            },
            n: {
              value: 'no',
              nextStep: {
                '001': '006a',
                '002': '006a',
                '008': '006a',
                '009': '006a',
                '010': '006a',
                '011': '006a',
                '012': '006a',
                '013': '006a',
                '003': '006a',
                '004': '006a',
                '005': '006a',
                '006': '006a',
                '007': '006a',
              },
            },
          },
        },
        '062q': {
          id: '062q',
          q: 'Did you see a physician who diagnosed you with a fracture',
          type: 'question',
          answers: {
            y: {
              value: 'yes',
            },
            n: {
              value: 'no',
            },
          },
        },
      },
    },
    previouslyTriedActions: [
      {
        id: 'rest',
        display: 'Rest',
      },
      {
        id: 'ice',
        display: 'Ice',
      },
      {
        id: 'elevation',
        display: 'Elevation',
      },
      {
        id: 'compression',
        display: 'Compression',
      },
      {
        id: 'physicalTherapy',
        display: 'Physical Therapy',
      },
      {
        id: 'accupuncture',
        display: 'Acupuncture',
      },
      {
        id: 'chiropracticCare',
        display: 'Chiropractic Care',
      },
      {
        id: 'injections',
        display: 'Injections',
      },
      {
        id: 'surgery',
        display: 'Surgery',
      },
      {
        id: 'opioids',
        display: 'Opioids',
      },
      {
        id: 'otcMedication',
        display:
          'Over the counter  medications such as: Advil, Aleve, Tylenol, Ibuprofen, Motrin, etc.',
      },
      {
        id: 'other',
        display: 'Other',
      },
    ],
    actionsOpenToTry: [
      {
        id: 'rest',
        display: 'Rest',
      },
      {
        id: 'ice',
        display: 'Ice',
      },
      {
        id: 'elevation',
        display: 'Elevation',
      },
      {
        id: 'compression',
        display: 'Compression',
      },
      {
        id: 'physicalTherapy',
        display: 'Physical Therapy',
      },
      {
        id: 'accupuncture',
        display: 'Acupuncture',
      },
      {
        id: 'chiropracticCare',
        display: 'Chiropractic Care',
      },
      {
        id: 'injections',
        display: 'Injections',
      },
      {
        id: 'surgery',
        display: 'Surgery',
      },
      {
        id: 'otcMedication',
        display:
          'Over the counter  medications such as: Advil, Aleve, Tylenol, Ibuprofen, Motrin, etc.',
      },
    ],
    enablingTherapies: [
      'Physical Therapy',
      'NSAIDs',
      'Opioids',
      'Other Pain Medication',
      'DME (e.g. walker, bracing etc.)',
      'Home Exercise Plan',
      'Reassurance',
      'Injections',
    ],
  }
