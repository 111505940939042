import { useState } from 'react'

export const useTooltipsState = (tooltips: string[]) => {
  const [tooltipState, setTooltipState] = useState<Record<string, boolean>>(
    tooltips.reduce((acc, tooltip) => ({ ...acc, [tooltip]: false }), {})
  )

  const getTooltipCopyClickHandler = (tooltip: string) => () =>
    setTooltipState((prevTooltipState) => ({
      ...prevTooltipState,
      [tooltip]: true,
    }))

  const getTooltipCopiedTooltipCloseHandler = (tooltip: string) => () =>
    setTooltipState((prevTooltipState) => ({
      ...prevTooltipState,
      [tooltip]: false,
    }))

  return {
    tooltipState,
    getTooltipCopyClickHandler,
    getTooltipCopiedTooltipCloseHandler,
  }
}
