import { ErrorMessage } from '@hookform/error-message'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormHelperText,
  SelectChangeEvent,
} from '@mui/material'
import { FC } from 'react'
import {
  FieldPath,
  FieldValues,
  useController,
  useFormContext,
} from 'react-hook-form'

import { useLoadMSKFormQuery } from 'src/features/msk/presentation'

type ReferredForFieldProps = {
  name?: FieldPath<FieldValues>
  required?: boolean
}

export const ReferredForField: FC<ReferredForFieldProps> = ({
  required,
  name = 'referredFor',
}: ReferredForFieldProps) => {
  const { loadedMSKForm } = useLoadMSKFormQuery()
  const { control } = useFormContext()
  const { field, fieldState } = useController({ name, control })
  const selectedBodyParts: string[] = field.value ? field.value.split(', ') : []

  const handleChange = (event: SelectChangeEvent<typeof selectedBodyParts>) => {
    const value = event.target.value
    if (Array.isArray(value)) {
      field.onChange(value.join(', '))
    } else {
      field.onChange(value)
    }
  }

  return (
    <FormControl size={'small'} sx={{ width: '100%' }}>
      <InputLabel error={fieldState.invalid} required={required}>
        Referred For
      </InputLabel>
      <Select
        label={'Referred For'}
        multiple
        value={selectedBodyParts}
        onBlur={field.onBlur}
        onChange={handleChange}
        renderValue={(selected) => selected.join(', ')}
        error={fieldState.invalid}
        required={required}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
      >
        {loadedMSKForm?.botheredBodyParts?.map((bodyRegion) => (
          <MenuItem key={bodyRegion.id} value={bodyRegion.display}>
            <Checkbox
              checked={selectedBodyParts.includes(bodyRegion.display)}
            />
            <ListItemText primary={bodyRegion.display} />
          </MenuItem>
        ))}
        <MenuItem value="Not Provided">
          <Checkbox checked={selectedBodyParts.includes('Not Provided')} />
          <ListItemText primary="Not Provided" />
        </MenuItem>
      </Select>
      <ErrorMessage
        name={name}
        render={({ message }) => (
          <FormHelperText error>{message}</FormHelperText>
        )}
      />
    </FormControl>
  )
}
