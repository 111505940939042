import { useCallback } from 'react'

import { useCompassApiClient } from 'src/features/shared/infrastructure'
import {
  CreatePathwayService,
  CreatePathwayServiceResponse,
  GetPathwaysService,
  GetPathwaysServiceResponse,
  ReassignPathwayService,
  ReassignPathwayServiceResponse,
  UpdatePathwayService,
  UpdatePathwayServiceResponse,
} from 'src/features/pathways/infrastructure'
import * as settings from 'src/config/settings'

export const usePathwaysService = () => {
  const { getCompassApiClient } = useCompassApiClient()

  const getPathways: GetPathwaysService = useCallback(async () => {
    const compassClient = await getCompassApiClient()
    return await compassClient.get<GetPathwaysServiceResponse>(
      settings.COMPASS_API_BASE_URL + '/pathways/definitions'
    )
  }, [getCompassApiClient])

  const createPathway: CreatePathwayService = useCallback(
    async (payload) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.post<CreatePathwayServiceResponse>(
        settings.COMPASS_API_BASE_URL + `/pathways/assign`,
        payload
      )
    },
    [getCompassApiClient]
  )

  const reassignPathway: ReassignPathwayService = useCallback(
    async (payload) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.post<ReassignPathwayServiceResponse>(
        settings.COMPASS_API_BASE_URL + `/pathways/reassign`,
        payload
      )
    },
    [getCompassApiClient]
  )

  const updatePathway: UpdatePathwayService = useCallback(
    async ({ pathwayId, payload }) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.put<UpdatePathwayServiceResponse>(
        settings.COMPASS_API_BASE_URL + `/pathways/${pathwayId}`,
        payload
      )
    },
    [getCompassApiClient]
  )

  return {
    getPathways,
    createPathway,
    reassignPathway,
    updatePathway,
  }
}
