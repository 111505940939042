import { useQuery } from '@tanstack/react-query'

import { usePatientsUseCases } from 'src/features/patients/patients.service-locator'
import {
  GetPatientGoalsError,
  GetPatientGoalsReturns,
} from 'src/features/patients/domain'

export const GET_PATIENT_GOALS_QUERY_KEY = 'getPatientGoals'

export const useGetPatientGoalsQuery = () => {
  const patientsUseCases = usePatientsUseCases()

  const { data, isLoading, isError } = useQuery<
    GetPatientGoalsReturns,
    GetPatientGoalsError
  >([GET_PATIENT_GOALS_QUERY_KEY], () => {
    return patientsUseCases.getPatientGoals()
  })
  return {
    patientGoals: data ? data : [],
    getPatientGoalsIsLoading: isLoading,
    getPatientGoalsIsError: isError,
  }
}
