import { rest } from 'msw'

import { GetPatientNotesServiceResponse } from 'src/features/notes/infrastructure'

import * as settings from 'src/config/settings'
import { getPatientNotesServiceResponseMock } from 'src/__mocks__/responses/get-patient-notes-service-response'

const url = `${settings.COMPASS_API_BASE_URL}/notes/:patientId`

export const getPatientNotesServiceResponseHandler =
  rest.get<GetPatientNotesServiceResponse>(url, async (req, res, ctx) => {
    const { patientId } = req.params
    return res(
      ctx.status(200),
      ctx.json({
        ...getPatientNotesServiceResponseMock,
        patientId: patientId,
      })
    )
  })
