import {
  FormControl,
  FormControlProps,
  FormHelperText,
  FormHelperTextProps,
  FormLabel,
  FormLabelProps,
  InputLabel,
  SxProps,
  Theme,
} from '@mui/material'
import { FC } from 'react'

import { WithChildren } from 'src/features/shared/types'

export type FormFieldContainerProps = {
  label?: string
  helperText?: string
  id?: string
  error?: boolean
  labelComponent?: 'formLabel' | 'inputLabel'
  formControlProps?: Omit<FormControlProps, 'sx'>
  formControlSx?: SxProps<Theme>
  formLabelProps?: Omit<FormLabelProps, 'sx'>
  formLabelSx?: SxProps<Theme>
  inputLabelProps?: Omit<FormLabelProps, 'sx'>
  inputLabelSx?: SxProps<Theme>
  formHelperTextProps?: Omit<FormHelperTextProps, 'sx'>
  formHelperTextSx?: SxProps<Theme>
  formHelperTextAbsolute?: boolean
  required?: boolean
}

export const FormFieldContainer: FC<WithChildren<FormFieldContainerProps>> = ({
  id,
  label,
  helperText,
  error,
  children,
  labelComponent = 'formLabel',
  formControlProps = {},
  formControlSx = {},
  formLabelProps = {},
  formLabelSx = {},
  inputLabelProps = {},
  inputLabelSx = {},
  formHelperTextProps = {},
  formHelperTextSx = {},
  formHelperTextAbsolute = false,
  required,
}) => {
  const formLabelId = id ? `${id}-label` : undefined
  return (
    <FormControl
      error={error}
      {...formControlProps}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        ...formControlSx,
      }}
    >
      {label ? (
        labelComponent === 'formLabel' ? (
          <FormLabel
            id={formLabelId}
            error={error}
            required={required}
            {...formLabelProps}
            sx={{
              ...formLabelSx,
            }}
          >
            {label}
          </FormLabel>
        ) : (
          <InputLabel
            error={error}
            required={required}
            {...inputLabelProps}
            sx={{ ...inputLabelSx }}
          >
            {label}
          </InputLabel>
        )
      ) : null}
      {children}
      {helperText ? (
        <FormHelperText
          error={error}
          {...formHelperTextProps}
          sx={{
            position: formHelperTextAbsolute ? 'absolute' : undefined,
            bottom: formHelperTextAbsolute ? -24 : undefined,
            ...formHelperTextSx,
          }}
        >
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  )
}
