import { Box, Divider, Stack, Typography } from '@mui/material'
import { FieldPath, FieldValues } from 'react-hook-form'
import { FC } from 'react'

import { filterNoneValueOptions } from 'src/features/notes/adapters'
import {
  ControlledSelectField,
  ControlledTextField,
  LEVEL_SCORES,
  YES_NO_OPTIONS,
  MISSED_REASONS,
  MISSED_HOME_PT_REASONS,
} from 'src/features/shared/presentation'
import {
  NOTE_FIELD_LABEL,
  noteFieldLabelDefaultSx,
} from 'src/features/notes/presentation'
import { mapToControlledSelectItem } from 'src/features/shared/adapters'
import { NoteData } from 'src/features/notes/domain'

export type PTTouchpointQuestionsFormV2Values = Pick<
  NoteData,
  | 'agreeYouUnderstandCondition'
  | 'agreeYouUnderstandTreatmentOptions'
  | 'completedAppointment'
  | 'reasonForMissingAppointment'
  | 'detailsOfMissedAppointment'
  | 'appointmentRescheduled'
  | 'completingPhysicalTherapyExercises'
  | 'reasonForNotCompletingPTExercises'
  | 'painDuringPTExercise'
  | 'additionalQuestionsOrConcerns'
  | 'sdmText'
  | 'sdmPatientUnderstandsCondition'
  | 'sdmClinicalBelievesPatientUnderstandsCondition'
  | 'patientGoals'
  | 'reasonForRefusal'
>

type PTTouchpointAdditionalQuestionsFormV2Props = {
  additionalQuestionsOrConcernsFieldName?: FieldPath<FieldValues>
  completedAppointmentFieldName?: FieldPath<FieldValues>
  completingPhysicalTherapyExercisesFieldName?: FieldPath<FieldValues>
  reasonForMissingAppointmentFieldName?: FieldPath<FieldValues>
  detailsOfMissedAppointmentFieldName?: FieldPath<FieldValues>
  appointmentRescheduledFieldName?: FieldPath<FieldValues>
  reasonForNotCompletingPTExercisesFieldName?: FieldPath<FieldValues>
  painDuringPTExerciseFieldName?: FieldPath<FieldValues>
  additionalQuestionsOrConcernsRequired?: boolean
  completedAppointmentRequired?: boolean
  completingPhysicalTherapyExercisesRequired?: boolean
  reasonForMissingAppointmentRequired?: boolean
  detailsOfMissedAppointmentRequired?: boolean
  appointmentRescheduledRequired?: boolean
  reasonForNotCompletingPTExercisesRequired?: boolean
  painDuringPTExerciseRequired?: boolean
}

export const PTTouchpointAdditionalQuestionsFormV2: FC<
  PTTouchpointAdditionalQuestionsFormV2Props
> = ({
  additionalQuestionsOrConcernsFieldName = 'additionalQuestionsOrConcerns',
  completedAppointmentFieldName = 'completedAppointment',
  completingPhysicalTherapyExercisesFieldName = 'completingPhysicalTherapyExercises',
  reasonForMissingAppointmentFieldName = 'reasonForMissingAppointment',
  detailsOfMissedAppointmentFieldName = 'detailsOfMissedAppointment',
  appointmentRescheduledFieldName = 'appointmentRescheduled',
  reasonForNotCompletingPTExercisesFieldName = 'reasonForNotCompletingPTExercises',
  painDuringPTExerciseFieldName = 'painDuringPTExercise',
  additionalQuestionsOrConcernsRequired,
  completedAppointmentRequired,
  completingPhysicalTherapyExercisesRequired,
  reasonForMissingAppointmentRequired,
  detailsOfMissedAppointmentRequired,
  appointmentRescheduledRequired,
  reasonForNotCompletingPTExercisesRequired,
  painDuringPTExerciseRequired,
}) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: '8px',
          width: 170,
          whiteSpace: 'nowrap',
          flexDirection: 'column',
        }}
      >
        <Divider />
        <Typography pb={'16px'} color={'grey'}>
          Assessment Questions
        </Typography>
      </Box>
      <Stack direction={'column'} rowGap={'16px'}>
        <ControlledSelectField
          name={completedAppointmentFieldName}
          label={NOTE_FIELD_LABEL.completedAppointment}
          items={filterNoneValueOptions(YES_NO_OPTIONS).map(
            mapToControlledSelectItem
          )}
          required={completedAppointmentRequired}
          selectSx={{ width: '88px' }}
          formLabelSx={noteFieldLabelDefaultSx}
        />

        {reasonForMissingAppointmentRequired ? (
          <ControlledSelectField
            name={reasonForMissingAppointmentFieldName}
            label={NOTE_FIELD_LABEL.reasonForMissingAppointment}
            labelComponent={'inputLabel'}
            items={filterNoneValueOptions(MISSED_REASONS).map(
              mapToControlledSelectItem
            )}
            required={reasonForMissingAppointmentRequired}
            formControlProps={{
              size: 'small',
            }}
            formControlSx={{
              width: '340px',
            }}
          />
        ) : null}
        {detailsOfMissedAppointmentRequired ? (
          <ControlledTextField
            name={detailsOfMissedAppointmentFieldName}
            required={detailsOfMissedAppointmentRequired}
            textFieldProps={{
              label: NOTE_FIELD_LABEL.detailsOfMissedAppointment,
            }}
            formControlSx={{ width: '668px' }}
          />
        ) : null}
        {appointmentRescheduledRequired ? (
          <ControlledSelectField
            name={appointmentRescheduledFieldName}
            label={NOTE_FIELD_LABEL.appointmentRescheduled}
            labelComponent={'inputLabel'}
            items={filterNoneValueOptions(YES_NO_OPTIONS).map(
              mapToControlledSelectItem
            )}
            required={appointmentRescheduledRequired}
            formControlProps={{
              size: 'small',
            }}
            formControlSx={{
              width: '340px',
            }}
          />
        ) : null}

        <ControlledSelectField
          name={completingPhysicalTherapyExercisesFieldName}
          label={'Have you been doing your physical therapy exercises at home?'}
          items={filterNoneValueOptions(YES_NO_OPTIONS).map(
            mapToControlledSelectItem
          )}
          required={completingPhysicalTherapyExercisesRequired}
          selectSx={{ width: '88px' }}
          formLabelSx={noteFieldLabelDefaultSx}
        />

        {reasonForNotCompletingPTExercisesRequired ? (
          <>
            <ControlledSelectField
              name={reasonForNotCompletingPTExercisesFieldName}
              required={reasonForNotCompletingPTExercisesRequired}
              formControlSx={{ width: '340px' }}
              formControlProps={{ size: 'small' }}
              items={filterNoneValueOptions(MISSED_HOME_PT_REASONS).map(
                mapToControlledSelectItem
              )}
              label="If No, why not?"
              labelComponent="inputLabel"
            />
            {painDuringPTExerciseRequired ? (
              <ControlledSelectField
                name={painDuringPTExerciseFieldName}
                label={'What is your pain level while performing exercises?'}
                labelComponent={'inputLabel'}
                items={LEVEL_SCORES.map(mapToControlledSelectItem)}
                required={painDuringPTExerciseRequired}
                formControlProps={{
                  size: 'small',
                }}
                formControlSx={{
                  width: '432px',
                }}
              />
            ) : null}
          </>
        ) : null}

        <ControlledTextField
          name={additionalQuestionsOrConcernsFieldName}
          label={NOTE_FIELD_LABEL.additionalQuestionsOrConcerns}
          required={additionalQuestionsOrConcernsRequired}
          formLabelSx={noteFieldLabelDefaultSx}
          textFieldSx={{ width: '100%' }}
        />
      </Stack>
    </>
  )
}
