import { UseCareTeamUseCases } from 'src/features/care-team/domain'
import { useCareTeamServiceAdapter } from 'src/features/care-team/adapters'

type MakeUseCareTeamUseCases = () => UseCareTeamUseCases

const makeUseCareTeamUseCases: MakeUseCareTeamUseCases = () => () => {
  const careTeamServiceAdapter = useCareTeamServiceAdapter()

  return {
    createCareTeam: careTeamServiceAdapter.createCareTeam,
    updateCareTeam: careTeamServiceAdapter.updateCareTeam,
  }
}

export const useCareTeamUseCases: UseCareTeamUseCases =
  makeUseCareTeamUseCases()
