import { FC, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'

import {
  usePatientPageStore,
  useTasksStore,
} from 'src/features/shared/infrastructure'
import { mapToTaskCalendarEvent } from 'src/features/tasks/adapters'
import { RefreshButton, TaskCalendar } from 'src/features/shared/presentation'

import 'react-big-calendar/lib/css/react-big-calendar.css'
import {
  PatientCalendarTasksEvent,
  useGetAllTasksByPatientIdQuery,
  useGetDoneTasksByPatientIdQuery,
  useGetOverdueTasksByPatientIdQuery,
  useGetTodayTasksByPatientIdQuery,
  useGetUpcomingTasksByPatientIdQuery,
  useGetUrgentTasksByPatientIdQuery,
  useRefreshPatientTasks,
} from 'src/features/tasks/presentation'
import { View } from 'react-big-calendar'

export const PatientTasksCalendarModal: FC = () => {
  const [timeFrame, setTimeFrame] = useState('todo')

  const { patientTasksCalendarView, setPatientTasksCalendarView } =
    useTasksStore()

  const { openTasksCalendarModal, setOpenTasksCalendarModal } =
    usePatientPageStore()
  const { patientAllTasks } = useGetAllTasksByPatientIdQuery({
    params: { paginated: false, withUrgent: true },
  })
  const { patientUpcomingTasks } = useGetUpcomingTasksByPatientIdQuery({
    params: { paginated: false, withUrgent: true },
  })
  const { patientUrgentTasks } = useGetUrgentTasksByPatientIdQuery({
    params: { paginated: false },
  })
  const { patientOverdueTasks } = useGetOverdueTasksByPatientIdQuery({
    params: { paginated: false, withUrgent: true },
  })
  const { patientTodayTasks } = useGetTodayTasksByPatientIdQuery({
    params: { paginated: false, withUrgent: true },
  })
  const { patientDoneTasks } = useGetDoneTasksByPatientIdQuery({
    params: { paginated: false, withUrgent: true },
  })
  const { refreshPatientTasks, isRefreshingPatientTasks } =
    useRefreshPatientTasks()

  const getCurrentEvents = (timeFrame?: string) => {
    switch (timeFrame) {
      case 'todo':
        return patientAllTasks
          .filter(
            (task) =>
              !patientDoneTasks.some((doneTask) => doneTask.id === task.id)
          )
          .map(mapToTaskCalendarEvent)
      case 'all':
        return patientAllTasks.map(mapToTaskCalendarEvent)
      case 'urgent':
        return patientUrgentTasks.map(mapToTaskCalendarEvent)
      case 'today':
        return patientTodayTasks.map(mapToTaskCalendarEvent)
      case 'overdue':
        return patientOverdueTasks.map(mapToTaskCalendarEvent)
      case 'upcoming':
        return patientUpcomingTasks.map(mapToTaskCalendarEvent)
      case 'done':
        return patientDoneTasks.map(mapToTaskCalendarEvent)
      default:
        return patientAllTasks.map(mapToTaskCalendarEvent)
    }
  }

  const handleClose = () => {
    setOpenTasksCalendarModal(false)
  }

  const handleSelectTaskChange = (event: SelectChangeEvent) => {
    setTimeFrame(event.target.value)
  }
  const handleRefreshTasksClick = () => {
    refreshPatientTasks()
  }

  const handleViewChange = (view: View) => {
    setPatientTasksCalendarView(view)
  }

  return (
    <Dialog
      onClose={handleClose}
      open={openTasksCalendarModal}
      sx={{
        zIndex: 2,
        '& .MuiPaper-root': {
          xs: {
            maxWidth: '1024px',
            minWidth: '768px',
          },
        },
      }}
    >
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '48px',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <Typography>Patient Touchpoints Calendar</Typography>
            <RefreshButton
              onClick={handleRefreshTasksClick}
              isRefreshing={isRefreshingPatientTasks}
            />
          </Box>
          <FormControl
            sx={{
              width: '300px',
              '& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
                color: 'secondary.main',
              },
            }}
          >
            <InputLabel>Filter By</InputLabel>
            <Select
              size={'small'}
              value={timeFrame}
              label="Filter By"
              onChange={handleSelectTaskChange}
              variant="outlined"
              color={'secondary'}
              slotProps={{
                input: {
                  color: 'secondary',
                },
              }}
              sx={{
                background: 'white',
              }}
            >
              <MenuItem value={'todo'}>To Do Tasks</MenuItem>
              <MenuItem value={'all'}>All Tasks</MenuItem>
              <MenuItem value={'urgent'}>Urgent Tasks</MenuItem>
              <MenuItem value={'today'}>Today Tasks</MenuItem>
              <MenuItem value={'overdue'}>Overdue Tasks</MenuItem>
              <MenuItem value={'upcoming'}>Upcoming Tasks</MenuItem>
              <MenuItem value={'done'}>Done Tasks</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <TaskCalendar
          key={`patient_${timeFrame}_tasks_calendar`}
          events={getCurrentEvents(timeFrame)}
          EventComponent={PatientCalendarTasksEvent}
          view={patientTasksCalendarView}
          onViewChange={handleViewChange}
        />
      </DialogContent>
      <Button color="secondary" onClick={handleClose}>
        Close
      </Button>
    </Dialog>
  )
}
