import { useQuery } from '@tanstack/react-query'

import {
  GetEligiblePatientsParams,
  GetEligiblePatientsFilters,
  GetEligiblePatientsReturns,
  GetEligiblePatientsError,
} from 'src/features/eligibility/domain'
import { useEligiblePatientsQueryParams } from 'src/features/eligibility/presentation'
import { useEligibilityUseCases } from 'src/features/eligibility/eligibility.service-locator'

export const GET_ELIGIBLE_PATIENTS_QUERY_KEY = 'getEligiblePatients'

export const DEFAULT_ELIGIBLE_PATIENTS_LIMIT_PARAM = '25'

export const useGetEligiblePatientsQuery = () => {
  const { getLimit, getPage, getQ, getMbi } = useEligiblePatientsQueryParams()
  const eligibilityPatientsUseCases = useEligibilityUseCases()

  const getEligiblePatientsParams: GetEligiblePatientsParams = {
    limit: getLimit() || DEFAULT_ELIGIBLE_PATIENTS_LIMIT_PARAM,
    page: getPage() || '1',
    q: getQ(),
  }

  const getEligiblePatientsFilters: GetEligiblePatientsFilters = {
    mbi: getMbi(),
  }

  const { data, isLoading, isError, refetch, isRefetching } = useQuery<
    GetEligiblePatientsReturns,
    GetEligiblePatientsError
  >(
    [
      GET_ELIGIBLE_PATIENTS_QUERY_KEY,
      { ...getEligiblePatientsParams, ...getEligiblePatientsFilters },
    ],
    () => {
      return eligibilityPatientsUseCases.getEligiblePatients({
        params: getEligiblePatientsParams,
        filters: getEligiblePatientsFilters,
      })
    }
  )
  return {
    totalCount: data ? data.totalCount : 0,
    eligibilityPatients: data ? data.patients : [],
    getEligiblePatientsIsLoading: isLoading,
    getEligiblePatientsIsError: isError,
    getEligiblePatientsIsRefetching: isRefetching,
    refetchEligiblePatients: refetch,
  }
}
