import {
  UseMutateAsyncFunction,
  UseMutateFunction,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import {
  UpdateIntakeArgs,
  UpdateIntakeError,
  UpdateIntakeReturns,
} from 'src/features/msk/domain'
import { useMSKUseCases } from 'src/features/msk/msk.service-locator'
import {
  GET_PATIENT_INTAKE_FLOWS_QUERY_KEY,
  GET_PATIENT_LAST_INTAKE_FLOW_QUERY_KEY,
} from 'src/features/msk/presentation'
import { GET_PATIENT_QUERY_KEY_V2 } from 'src/features/patients/presentation'
import { usePatientId } from 'src/features/shared/presentation'

type useUpdateIntakeProps = Omit<
  UseMutationOptions<
    UpdateIntakeReturns,
    UpdateIntakeError,
    UpdateIntakeArgs,
    unknown
  >,
  'mutationFn'
> & {
  patientId?: string
  throwGetPatientIdError?: boolean
}

type UseUpdateIntake = (props?: useUpdateIntakeProps) => {
  updateIntake: UseMutateFunction<
    UpdateIntakeReturns,
    UpdateIntakeError,
    UpdateIntakeArgs,
    unknown
  >
  updateIntakeAsync: UseMutateAsyncFunction<
    UpdateIntakeReturns,
    UpdateIntakeError,
    UpdateIntakeArgs,
    unknown
  >
  updateIntakeIsLoading: boolean
  updateIntakeStatus: string
}

export const useUpdateIntake: UseUpdateIntake = (props = {}) => {
  const queryClient = useQueryClient()
  const patientsUseCases = useMSKUseCases()

  const { onSuccess, patientId, throwGetPatientIdError = false } = props

  // TODO: find a better way to get the patient id
  const _patientId = usePatientId({ patientId, throwGetPatientIdError })

  const { mutate, mutateAsync, isLoading, status } = useMutation<
    UpdateIntakeReturns,
    UpdateIntakeError,
    UpdateIntakeArgs,
    unknown
  >({
    mutationFn: (args: UpdateIntakeArgs) => {
      return patientsUseCases.updateIntake(args)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [GET_PATIENT_LAST_INTAKE_FLOW_QUERY_KEY, _patientId],
      })
      queryClient.invalidateQueries({
        queryKey: [GET_PATIENT_INTAKE_FLOWS_QUERY_KEY, _patientId],
      })
      queryClient.invalidateQueries({
        queryKey: [GET_PATIENT_QUERY_KEY_V2, _patientId],
      })
      onSuccess?.(data, variables, context)
    },
  })
  return {
    updateIntake: mutate,
    updateIntakeAsync: mutateAsync,
    updateIntakeIsLoading: isLoading,
    updateIntakeStatus: status,
  }
}
