import axios from 'axios'

import { useMarketsService } from 'src/features/providers/infrastructure'

import { useAxiosErrorHandling } from 'src/features/shared/infrastructure'
import { MarketsUseCases } from 'src/features/providers/domain'
import {
  mapToGetMarketsError,
  mapToGetMarketsReturns,
} from 'src/features/providers/adapters'

export const useMarketsServiceAdapter = () => {
  const marketsService = useMarketsService()
  const { handleAxiosError } = useAxiosErrorHandling()

  const getMarkets: MarketsUseCases['getMarkets'] = async () => {
    try {
      const response = await marketsService.getMarkets()

      return mapToGetMarketsReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToGetMarketsError)
      }
      throw error
    }
  }

  return {
    getMarkets,
  }
}
