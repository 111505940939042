import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from 'src/features/shared/infrastructure'
import { NoteType } from 'src/features/notes/domain'

export type NotesState = {
  currentNotesPatientId: string
  selectedNoteType: NoteType
  noteText: string
}

const initialState: NotesState = {
  currentNotesPatientId: '',
  selectedNoteType: '',
  noteText: '',
}

const notesSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    resetNotesStateAction(
      state,
      action: PayloadAction<NotesState['currentNotesPatientId']>
    ) {
      const patientId = action.payload
      return { ...initialState, currentNotesPatientId: patientId }
    },
    setNotesStateAction(state, action: PayloadAction<Partial<NotesState>>) {
      return {
        ...initialState,
        ...action.payload,
      }
    },
    setCurrentNotesPatientIdAction(
      state,
      action: PayloadAction<NotesState['currentNotesPatientId']>
    ) {
      state.currentNotesPatientId = action.payload
    },
    setSelectedNoteTypeAction(
      state,
      action: PayloadAction<NotesState['selectedNoteType']>
    ) {
      state.selectedNoteType = action.payload
    },
    setNoteTextAction(state, action: PayloadAction<NotesState['noteText']>) {
      state.noteText = action.payload
    },
  },
})

export const {
  setCurrentNotesPatientIdAction,
  setSelectedNoteTypeAction,
  setNoteTextAction,
  resetNotesStateAction,
  setNotesStateAction,
} = notesSlice.actions

export const currentNotesPatientIdSelector = (state: RootState) =>
  state.notes.currentNotesPatientId

export const selectedNoteTypeSelector = (state: RootState) =>
  state.notes.selectedNoteType

export const noteTextSelector = (state: RootState) => state.notes.noteText

export default notesSlice.reducer
