import { useCallback } from 'react'

import {
  CreatePlanService,
  CreatePlanServiceResponse,
  GetPlansService,
  GetPlansServiceResponse,
} from 'src/features/providers/infrastructure'

import { useProvidersApiClient } from 'src/features/shared/infrastructure'
import * as settings from 'src/config/settings'

export const usePlansService = () => {
  const { getProvidersApiClient } = useProvidersApiClient()

  const getPlans: GetPlansService = useCallback(
    async ({ filters } = {}) => {
      const providersClient = await getProvidersApiClient()

      let filterOptions
      if (
        filters &&
        !Object.values(filters).every((filter) =>
          [undefined, ''].includes(filter)
        )
      ) {
        filterOptions = JSON.stringify(filters)
      }

      const params = {
        filterOptions,
      }

      return await providersClient.get<GetPlansServiceResponse>(
        settings.PROVIDERS_API_BASE_URL + '/plans',
        {
          params,
        }
      )
    },
    [getProvidersApiClient]
  )

  const createPlan: CreatePlanService = useCallback(
    async ({ payload }) => {
      const compassClient = await getProvidersApiClient()
      return await compassClient.post<CreatePlanServiceResponse>(
        settings.PROVIDERS_API_BASE_URL + '/plans',
        payload
      )
    },
    [getProvidersApiClient]
  )

  return {
    getPlans,
    createPlan,
  }
}
