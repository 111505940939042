import { Box } from '@mui/material'
import { ChangeEvent, FC } from 'react'

import {
  MSKFormStateIntakeAssessment,
  useMSKFormStore,
} from 'src/features/shared/infrastructure'
import {
  LoadMSKFormReturns,
  MSKAnswer,
  MSKQuestion,
} from 'src/features/msk/domain'
import { getNextId, isAnActionId, isQuestionId } from 'src/features/msk/utils'
import { YesNoRadioGroup } from 'src/features/shared/presentation'
import { mskFormLabelSx } from 'src/features/msk/presentation'

type IntakeAssessmentPathQuestionsProps = {
  loadedMSKForm: LoadMSKFormReturns
  currentIntakeAssessment: MSKFormStateIntakeAssessment
}

export const IntakeAssessmentPathQuestions: FC<
  IntakeAssessmentPathQuestionsProps
> = ({ loadedMSKForm, currentIntakeAssessment }) => {
  const {
    setCurrentIntakeAssessmentAnswer,
    currentPathQuestion,
    currentPathQuestionAnswer,
    setCurrentPathQuestionAnswer,
    setPathQuestionsNext,
    isCurrentPathQuestionValidated,
    setIsCurrentPathQuestionValidated,
  } = useMSKFormStore({
    loadedMSKForm,
  })
  const questions = loadedMSKForm.questionsAndActions.questions
  const actions = loadedMSKForm.questionsAndActions.actions

  const hasError = isCurrentPathQuestionValidated && !currentPathQuestionAnswer

  const getChangehandler =
    (currentPathQuestion: MSKQuestion) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value

      const answerValue: MSKAnswer['value'] = value === 'yes' ? 'yes' : 'no'

      const answer: MSKAnswer = {
        questionId: currentPathQuestion.id,
        value: answerValue,
      }

      // Update the component state
      setCurrentPathQuestionAnswer(answer)

      // Update the global state
      setCurrentIntakeAssessmentAnswer(currentPathQuestion, answer)

      const nextId = getNextId(
        answer,
        currentPathQuestion,
        currentIntakeAssessment
      )

      if (nextId) {
        if (isQuestionId(nextId)) {
          setPathQuestionsNext(questions[nextId])
        }
        if (isAnActionId(nextId)) {
          setPathQuestionsNext(actions[nextId])
        }
      }

      setIsCurrentPathQuestionValidated(false)
    }

  return (
    <Box>
      {currentPathQuestion ? (
        <>
          <YesNoRadioGroup
            dataTestIdPreffix={'step2_question_radio'}
            label={currentPathQuestion.q}
            id={currentPathQuestion.id}
            onChange={getChangehandler(currentPathQuestion)}
            error={hasError}
            helperText={hasError ? 'Please select an answer' : ''}
            value={currentPathQuestionAnswer?.value || ''}
            formFieldContainerProps={{
              formLabelSx: mskFormLabelSx,
            }}
          />
        </>
      ) : null}
    </Box>
  )
}
