import {
  UseMutateAsyncFunction,
  UseMutateFunction,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import {
  DeleteDraftNoteReturns,
  DeleteDraftNoteArgs,
  DeleteDraftNoteError,
} from 'src/features/notes/domain'
import { useNotesUseCases } from 'src/features/notes/notes.service-locator'
import { GET_PATIENT_DRAFT_NOTE } from 'src/features/notes/presentation/hooks/use-get-draft-note'

type UseDeleteDraftNoteProps = Omit<
  UseMutationOptions<
    DeleteDraftNoteReturns,
    DeleteDraftNoteError,
    DeleteDraftNoteArgs,
    unknown
  >,
  'mutationFn'
> & { patientId: string }

type UseDeleteDraftNote = (props: UseDeleteDraftNoteProps) => {
  deleteDraftNote: UseMutateFunction<
    DeleteDraftNoteReturns,
    DeleteDraftNoteError,
    DeleteDraftNoteArgs,
    unknown
  >
  deleteDraftNoteAsync: UseMutateAsyncFunction<
    DeleteDraftNoteReturns,
    DeleteDraftNoteError,
    DeleteDraftNoteArgs,
    unknown
  >
  deleteDraftNoteIsLoading: boolean
  deleteDraftNoteIsError: boolean
  deleteDraftNoteStatus: string
}

export const useDeleteDraftNote: UseDeleteDraftNote = ({
  patientId,
  ...props
}) => {
  const notesUseCases = useNotesUseCases()
  const queryClient = useQueryClient()
  const { onSuccess } = props

  const { mutate, mutateAsync, isLoading, isError, status } = useMutation<
    DeleteDraftNoteReturns,
    DeleteDraftNoteError,
    DeleteDraftNoteArgs,
    unknown
  >({
    mutationFn: (args: DeleteDraftNoteArgs) => {
      return notesUseCases.deleteDraftNote(args)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [GET_PATIENT_DRAFT_NOTE, patientId],
      })
      onSuccess?.(data, variables, context)
    },
  })
  return {
    deleteDraftNote: mutate,
    deleteDraftNoteAsync: mutateAsync,
    deleteDraftNoteIsLoading: isLoading,
    deleteDraftNoteIsError: isError,
    deleteDraftNoteStatus: status,
  }
}
