import {
  Box,
  InputAdornment,
  MenuItem,
  SelectChangeEvent,
  TextField,
  Select,
  Typography,
} from '@mui/material'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import {
  AgreeYouUnderstandCondition,
  AgreeYouUnderstandTreatmentOptions,
  MSKFormState,
  MSKFormStateIntakeAssessment,
  useMSKFormStore,
  useNotesStore,
  usePatientPageStore,
} from 'src/features/shared/infrastructure'
import {
  mskFormLabelSx,
  IntakeAssessmentPainAndFunction,
  MSKFormPagination,
  MSKFormStepContainer,
  getSelectDefaultSx,
  MSK_FORM_ADDITIONAL_QUESTIONS_FIELD_OPTIONS,
  TRAUMA_QUESTIONS,
  useProcessTraumaMSKForm,
  ErrorAlert,
  actionsPageNumber as step2ActionsPageNumber,
  chronicConditionPageNumber as step2ChronicConditionPageNumber,
} from 'src/features/msk/presentation'
import { LoadMSKFormReturns } from 'src/features/msk/domain'
import {
  mapBodyPartToMultiSelectOption,
  mapToProcessTraumaMSKFormArgs,
} from 'src/features/msk/adapters'
import {
  MultiSelect,
  MultiSelectOption,
  FormFieldContainer,
  YesNoRadioGroup,
} from 'src/features/shared/presentation'
import { HEALTH_EQUITY_LABELS } from 'src/features/notes/presentation'
import { PatientV2 } from 'src/features/patients/domain'

type MSKFormStepOneProps = {
  patient: PatientV2
  loadedMSKForm: LoadMSKFormReturns
}

export const MSKFormStepOne: FC<MSKFormStepOneProps> = ({
  patient,
  loadedMSKForm,
}) => {
  const bodyPartsOptions = loadedMSKForm.botheredBodyParts

  const [showTraumaPatientWarning, setShowTraumaPatientWarning] =
    useState(false)

  const mskFormStore = useMSKFormStore({
    loadedMSKForm,
  })

  const { processTraumaMSKForm, processTraumaMSKFormIsLoading } =
    useProcessTraumaMSKForm()

  const { setOpenMSKFormModal } = usePatientPageStore()
  const { setSelectedNoteType } = useNotesStore()

  const {
    addNewErrors,
    additionalQuestions,
    deleteError,
    didYouHaveARecentInjury,
    didYouSeeAPhysician,
    errors,
    intakeAssessments,
    page,
    profileHeightFt,
    profileHeightIn,
    profileWeight,
    resetMSKForm,
    selectedBotheredBodyParts,
    setAdditionalQuestions,
    setIntakeAssessments,
    setMSKFormDidYouHaveARecentInjury,
    setMSKFormDidYouSeeAPhysician,
    setModalTitle,
    setPage,
    setProfileHeightFt,
    setProfileHeightIn,
    setProfileWeight,
    setSelectedBotheredBodyParts,
    setStep,
  } = mskFormStore

  const showPrevious = page > 1

  const getMultiSelectFormControlSx = () => {
    return {
      m: 0,
      width: '100%',
    }
  }

  const handleBotheredBodyPartsChange = (
    event: SelectChangeEvent<MultiSelectOption['value'][]>
  ) => {
    const {
      target: { value },
    } = event
    // On autofill we get a stringified value.
    const selectedValueList =
      typeof value === 'string' ? value.split(',') : value
    setSelectedBotheredBodyParts(
      bodyPartsOptions.filter((bodyPartOption) =>
        selectedValueList.includes(bodyPartOption.id)
      )
    )

    if (selectedValueList.length) {
      deleteError('selectedBotheredBodyParts')
    }
  }

  const handleProfileHeightFtChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const regex = /^[1-7]$/
    const value = event.target.value
    if (value === '' || regex.test(value)) {
      setProfileHeightFt(value)
      if (value) {
        deleteError('profileHeightFt')
      }
    }
  }

  const handleProfileHeightInChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const regex = /^[0-9]{1,2}$/
    const value = event.target.value
    if (value === '' || regex.test(value)) {
      setProfileHeightIn(value)
      const numValue = parseInt(value, 10)
      if (numValue >= 0 && numValue <= 11) {
        deleteError('profileHeightIn')
      } else {
        addNewErrors({
          profileHeightIn: 'Inches must be between 0 and 11.',
        })
      }
    }
  }

  const handleProfileWeightChange = (event: ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9]{1,3}(\.)?(\d{1,3})?$/
    const value = event.target.value
    if (value === '' || regex.test(value)) {
      setProfileWeight(value)
      if (value) {
        deleteError('profileWeight')
      }
    }
  }

  const validateSelectedBotheredBodyParts = () => {
    if (!selectedBotheredBodyParts.length) {
      addNewErrors({
        selectedBotheredBodyParts: 'Required',
      })
      return false
    }
    return true
  }

  const validateProfileHeightFt = () => {
    if (!profileHeightFt.length) {
      addNewErrors({
        profileHeightFt: 'Required',
      })
      return false
    }
    return true
  }

  const validateProfileHeightIn = () => {
    if (!profileHeightIn.length) {
      addNewErrors({
        profileHeightIn: 'Required',
      })
      return false
    }
    return true
  }

  const validateProfileWeight = () => {
    if (!profileWeight.length) {
      addNewErrors({
        profileWeight: 'Required',
      })
      return false
    }
    return true
  }

  const isPage1FormValid = () => {
    const validFields: boolean[] = [
      validateSelectedBotheredBodyParts(),
      validateProfileHeightFt(),
      validateProfileHeightIn(),
      validateProfileWeight(),
    ]

    if (validFields.every((isValid) => isValid)) {
      return true
    }

    return false
  }

  const validateAgreeYouUnderstandCondition = () => {
    if (
      additionalQuestions.agreeYouUnderstandCondition === 'Select an option'
    ) {
      addNewErrors({
        agreeYouUnderstandCondition: 'Required',
      })
      return false
    }
    return true
  }

  const validateAgreeYouUnderstandTreatmentOptions = () => {
    if (
      additionalQuestions.agreeYouUnderstandTreatmentOptions ===
      'Select an option'
    ) {
      addNewErrors({
        agreeYouUnderstandTreatmentOptions: 'Required',
      })
      return false
    }
    return true
  }

  const validatePatientHadRecentSurgery = () => {
    if (didYouHaveARecentInjury === '') {
      addNewErrors({
        didYouHaveARecentInjury: 'Required',
      })
      return false
    }
    return true
  }

  const validatePatientSawPhysician = () => {
    if (didYouSeeAPhysician === '') {
      addNewErrors({
        didYouSeeAPhysician: 'Required',
      })
      return false
    }
    return true
  }

  const isPage2FormValid = () => {
    if (
      !validateAgreeYouUnderstandCondition() ||
      !validateAgreeYouUnderstandTreatmentOptions() ||
      !validatePatientHadRecentSurgery() ||
      !validatePatientSawPhysician()
    ) {
      return false
    }

    return true
  }

  const handleNext = () => {
    if (page < 2 && isPage1FormValid()) {
      setPage(2)
      return
    }
    if (page > 1 && isPage2FormValid()) {
      setStep(2)
      if (intakeAssessments.length && intakeAssessments[0].skipped) {
        setPage(step2ActionsPageNumber)
      } else {
        setPage(step2ChronicConditionPageNumber)
      }
      return
    }
  }

  const handlePrevious = () => {
    if (page > 1) {
      setPage(1)
      return
    }
  }

  const handleBotheredBodyPartsOnClose = () => {
    setIntakeAssessments()
  }

  const handleAgreeYouUnderstandConditionChange = (
    event: SelectChangeEvent
  ) => {
    const value = event.target.value as AgreeYouUnderstandCondition
    setAdditionalQuestions({
      agreeYouUnderstandCondition: value,
    })

    if (value !== 'Select an option') {
      deleteError('agreeYouUnderstandCondition')
    }
  }

  const handleAgreeYouUnderstandTreatmentOptionsChange = (
    event: SelectChangeEvent
  ) => {
    const value = event.target.value as AgreeYouUnderstandTreatmentOptions
    setAdditionalQuestions({
      agreeYouUnderstandTreatmentOptions: value,
    })

    if (value !== 'Select an option') {
      deleteError('agreeYouUnderstandTreatmentOptions')
    }
  }

  const handleDidYouHaveARecentInjuryChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value: MSKFormState['didYouHaveARecentInjury'] =
      event.target.value === 'yes' ? 'yes' : 'no'

    setMSKFormDidYouHaveARecentInjury(value)
    deleteError('didYouHaveARecentInjury')

    if (
      value &&
      didYouSeeAPhysician === 'yes' &&
      selectedBotheredBodyParts.length
    ) {
      setShowTraumaPatientWarning(true)
    }
  }

  const handleDidYouSeeAPhysicianChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value: MSKFormState['didYouSeeAPhysician'] =
      event.target.value === 'yes' ? 'yes' : 'no'

    setMSKFormDidYouSeeAPhysician(value)
    deleteError('didYouSeeAPhysician')

    if (
      value === 'yes' &&
      didYouHaveARecentInjury &&
      selectedBotheredBodyParts.length
    ) {
      setShowTraumaPatientWarning(true)
    }
  }

  const handleTraumaPatientWarningIgnore = () => {
    setShowTraumaPatientWarning(false)
  }

  const handleTraumaPatientWarningConfirm = () => {
    const processTraumaMSKFormArgs = mapToProcessTraumaMSKFormArgs(mskFormStore)

    processTraumaMSKForm(processTraumaMSKFormArgs, {
      onSuccess: () => {
        toast.success('Trauma Intake Assessment submitted successfully')
        setShowTraumaPatientWarning(false)
        setOpenMSKFormModal(false)
        setSelectedNoteType('Intake')
        resetMSKForm(patient.patientId)
      },
    })
  }

  useEffect(() => {
    setModalTitle('Intake Assessment')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <MSKFormStepContainer>
        <Box
          id="msk_form_step_one"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '32px',
          }}
        >
          {page < 2 ? (
            <>
              <Box
                sx={{
                  width: '380px',
                }}
              >
                <MultiSelect
                  displayOptionType="checkbox"
                  id="msk_form_bothered_body_parts"
                  data-testid="msk_form_bothered_body_parts"
                  label="What part of the body is bothering you?"
                  options={bodyPartsOptions.map(mapBodyPartToMultiSelectOption)}
                  selectedOptions={selectedBotheredBodyParts.map(
                    (selectedBodyBotheredPart) => selectedBodyBotheredPart.id
                  )}
                  onChange={handleBotheredBodyPartsChange}
                  onClose={handleBotheredBodyPartsOnClose}
                  formControlSx={getMultiSelectFormControlSx()}
                  helperText={
                    errors.selectedBotheredBodyParts
                      ? errors.selectedBotheredBodyParts
                      : undefined
                  }
                  error={!!errors.selectedBotheredBodyParts}
                  required
                />
              </Box>

              {intakeAssessments.map(
                (intakeAssessment: MSKFormStateIntakeAssessment) => (
                  <Box
                    id={`msk_form_step_two_pain_and_function-${intakeAssessment.botheredBodyPart.id}`}
                    key={`msk_form_step_two_pain_and_function-${intakeAssessment.botheredBodyPart.id}`}
                  >
                    <IntakeAssessmentPainAndFunction
                      loadedMSKForm={loadedMSKForm}
                      currentIntakeAssessment={intakeAssessment}
                    />
                  </Box>
                )
              )}

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '16px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    maxWidth: '266px',
                    columnGap: '16px',
                  }}
                >
                  <TextField
                    data-testid={'msk_form_profile_height_ft_text_field'}
                    name={'profileHeightFt'}
                    label={'Height'}
                    value={profileHeightFt}
                    onBlur={() => validateProfileHeightFt()}
                    onChange={handleProfileHeightFtChange}
                    error={!!errors.profileHeightFt}
                    helperText={
                      errors.profileHeightFt ? errors.profileHeightFt : ''
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position={'end'}>ft</InputAdornment>
                      ),
                    }}
                    required
                  />
                  <TextField
                    data-testid={'msk_form_profile_height_in_text_field'}
                    name={'profileHeightIn'}
                    label={'Height'}
                    value={profileHeightIn}
                    onBlur={() => validateProfileHeightIn()}
                    onChange={handleProfileHeightInChange}
                    error={!!errors.profileHeightIn}
                    helperText={
                      errors.profileHeightIn ? errors.profileHeightIn : ''
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position={'end'}>in</InputAdornment>
                      ),
                    }}
                    required
                  />
                </Box>
                <Box sx={{ maxWidth: '125px' }}>
                  <TextField
                    data-testid={'msk_form_profile_weight_text_field'}
                    name={'profileWeight'}
                    label={'Weight'}
                    value={profileWeight}
                    onBlur={() => validateProfileWeight()}
                    onChange={handleProfileWeightChange}
                    error={!!errors.profileWeight}
                    helperText={
                      errors.profileWeight ? errors.profileWeight : ''
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position={'end'}>lbs</InputAdornment>
                      ),
                    }}
                    required
                  />
                </Box>
              </Box>
            </>
          ) : page > 1 ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '16px',
              }}
            >
              <ErrorAlert
                open={showTraumaPatientWarning}
                title={'Potential Trauma Patient'}
                dataTestIdPreffix={'msk_form_trauma_patient_warning_'}
                confirmText={'YES, SKIP ASSESSMENT'}
                ignoreText={'NO, CONTINUE THE ASSESSMENT'}
                onConfirm={handleTraumaPatientWarningConfirm}
                onIgnore={handleTraumaPatientWarningIgnore}
                confirmDisabled={processTraumaMSKFormIsLoading}
                ignoreDisabled={processTraumaMSKFormIsLoading}
              >
                <Typography sx={{ mt: '16px' }}>
                  It sounds like this patient may already be scheduled,
                  <br />
                  Would you like to skip the Intake Assessment and Additional
                  Questions?
                  <br />
                </Typography>
              </ErrorAlert>
              <FormFieldContainer
                id={'agreeYouUnderstandCondition'}
                label={HEALTH_EQUITY_LABELS.agreeYouUnderstandCondition}
                error={!!errors.agreeYouUnderstandCondition}
                helperText={
                  errors.agreeYouUnderstandCondition
                    ? errors.agreeYouUnderstandCondition
                    : ''
                }
                formLabelSx={mskFormLabelSx}
                required
              >
                <Select
                  data-testid="msk_form_condition_understanding"
                  id={'agreeYouUnderstandCondition'}
                  size="small"
                  value={additionalQuestions.agreeYouUnderstandCondition}
                  onChange={handleAgreeYouUnderstandConditionChange}
                  error={!!errors.agreeYouUnderstandCondition}
                  sx={{
                    ...getSelectDefaultSx(),
                  }}
                  required
                >
                  {MSK_FORM_ADDITIONAL_QUESTIONS_FIELD_OPTIONS.agreeYouUnderstandCondition.map(
                    (option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        data-testid={`msk_form_condition_understanding_${option.value.replace(
                          ' ',
                          '_'
                        )}`}
                      >
                        {option.display}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormFieldContainer>
              <FormFieldContainer
                id={'agreeYouUnderstandTreatmentOptions'}
                label={HEALTH_EQUITY_LABELS.agreeYouUnderstandTreatmentOptions}
                error={!!errors.agreeYouUnderstandTreatmentOptions}
                helperText={
                  errors.agreeYouUnderstandTreatmentOptions
                    ? errors.agreeYouUnderstandTreatmentOptions
                    : ''
                }
                formLabelSx={mskFormLabelSx}
                required
              >
                <Select
                  data-testid="msk_form_treatment_options_understanding"
                  id={'agreeYouUnderstandTreatmentOptions'}
                  size="small"
                  value={additionalQuestions.agreeYouUnderstandTreatmentOptions}
                  onChange={handleAgreeYouUnderstandTreatmentOptionsChange}
                  error={!!errors.agreeYouUnderstandTreatmentOptions}
                  sx={{
                    ...getSelectDefaultSx(),
                  }}
                  required
                >
                  {MSK_FORM_ADDITIONAL_QUESTIONS_FIELD_OPTIONS.agreeYouUnderstandTreatmentOptions.map(
                    (option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        data-testid={`msk_form_treatment_options_understanding_${option.value.replace(
                          ' ',
                          '_'
                        )}`}
                      >
                        {option.display}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormFieldContainer>
              <YesNoRadioGroup
                dataTestIdPreffix={`step1_question_${TRAUMA_QUESTIONS.didYouHaveARecentInjury.id}`}
                label={TRAUMA_QUESTIONS.didYouHaveARecentInjury.question}
                id={TRAUMA_QUESTIONS.didYouHaveARecentInjury.id}
                onChange={handleDidYouHaveARecentInjuryChange}
                error={!!errors.didYouHaveARecentInjury}
                helperText={
                  errors.didYouHaveARecentInjury
                    ? errors.didYouHaveARecentInjury
                    : undefined
                }
                value={didYouHaveARecentInjury}
                formFieldContainerProps={{
                  formLabelSx: mskFormLabelSx,
                }}
                required
              />
              <YesNoRadioGroup
                dataTestIdPreffix={`step1_question_${TRAUMA_QUESTIONS.didYouSeeAPhysician.id}`}
                label={TRAUMA_QUESTIONS.didYouSeeAPhysician.question}
                id={TRAUMA_QUESTIONS.didYouSeeAPhysician.id}
                onChange={handleDidYouSeeAPhysicianChange}
                error={!!errors.didYouSeeAPhysician}
                helperText={
                  errors.didYouSeeAPhysician
                    ? errors.didYouSeeAPhysician
                    : undefined
                }
                value={didYouSeeAPhysician}
                formFieldContainerProps={{
                  formLabelSx: mskFormLabelSx,
                }}
                required
              />
            </Box>
          ) : null}
        </Box>
        <MSKFormPagination
          showNext={true}
          showPrevious={showPrevious}
          onNext={handleNext}
          onPrevious={handlePrevious}
        />
      </MSKFormStepContainer>
    </>
  )
}
