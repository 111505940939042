import { useQuery } from '@tanstack/react-query'

import { useMSKUseCases } from 'src/features/msk/msk.service-locator'
import { LoadMSKFormError, LoadMSKFormReturns } from 'src/features/msk/domain'

export const LOAD_MSK_FORM_QUERY_KEY = 'loadMSKForm'

export const useLoadMSKFormQuery = () => {
  const mskUseCases = useMSKUseCases()

  const { data, isLoading, isError } = useQuery<
    LoadMSKFormReturns,
    LoadMSKFormError
  >([LOAD_MSK_FORM_QUERY_KEY], () => {
    return mskUseCases.loadMSKForm()
  })
  return {
    loadedMSKForm: data,
    mskFormIsLoading: isLoading,
    mskFormIsError: isError,
  }
}
