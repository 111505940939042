import {
  readLocalStorage,
  saveInLocalStorage,
  removeFromLocalStorage,
} from 'src/features/shared/utils'
import { MSKFormState } from 'src/features/shared/infrastructure'

const MSK_FORM_SLICE_LOCAL_STORAGE_KEY_PREFIX = 'msk-form-slice'

export const loadMSKFormStateFromLocalStorage = (
  mskFormPatientId: MSKFormState['mskFormPatientId']
) => {
  const key = `${MSK_FORM_SLICE_LOCAL_STORAGE_KEY_PREFIX}-${mskFormPatientId}`
  return readLocalStorage<MSKFormState>(key)
}

export const saveMSKFormInLocalStorage = (state: MSKFormState) => {
  const key = `${MSK_FORM_SLICE_LOCAL_STORAGE_KEY_PREFIX}-${state.mskFormPatientId}`
  saveInLocalStorage(key, state)
}

export const removeMSKFormFromLocalStorage = (
  mskFormPatientId: MSKFormState['mskFormPatientId']
) => {
  const key = `${MSK_FORM_SLICE_LOCAL_STORAGE_KEY_PREFIX}-${mskFormPatientId}`
  removeFromLocalStorage(key)
}
