import { rest } from 'msw'

import { SSearchDuplicatePatientsServiceResponse } from 'src/features/patients/infrastructure'

import * as settings from 'src/config/settings'
import {
  searchDuplicatePatientsServiceResponseMock,
  searchDuplicatePatientsServiceResponseMockFactory,
} from 'src/__mocks__/responses/search-duplicate-patients-service-response'

const url = `${settings.COMPASS_API_BASE_URL}/search/existing-users`

export const searchDuplicatePatientsServiceResponseHandler =
  rest.get<SSearchDuplicatePatientsServiceResponse>(
    url,
    async (req, res, ctx) => {
      const firstName = req.url.searchParams.get('firstName')
      // const lastName = req.url.searchParams.get('lastName')
      // const dob = req.url.searchParams.get('dob')
      // const phone = req.url.searchParams.get('phone')
      if (firstName === 'Jhon') {
        return res(
          ctx.status(200),
          ctx.json(searchDuplicatePatientsServiceResponseMock)
        )
      }

      if (firstName === 'Jhonny') {
        return res(
          ctx.status(200),
          ctx.json(searchDuplicatePatientsServiceResponseMockFactory(3))
        )
      }

      return res(ctx.status(200), ctx.json([]))
    }
  )
