import axios from 'axios'

import { NotesUseCases } from 'src/features/notes/domain'
import { useNotesService } from 'src/features/notes/infrastructure'
import {
  mapToGetPatientNotesReturns,
  mapToGetPatientNotesError,
  mapToCreatePatientCustomNoteServicePayload,
  mapToCreatePatientCustomNoteError,
  mapToCreatePatientCustomNoteReturns,
  mapToCreatePatientIntakeNoteServicePayload,
  mapToCreatePatientIntakeNoteError,
  mapToCreatePatientIntakeNoteReturns,
  mapToCreatePatientOrthoChartCheckNoteServicePayload,
  mapToCreatePatientOrthoChartCheckNoteReturns,
  mapToCreatePatientOrthoChartCheckNoteError,
  mapToCreatePatientOrthoTouchpointNoteServicePayload,
  mapToCreatePatientOrthoTouchpointNoteReturns,
  mapToCreatePatientOrthoTouchpointNoteError,
  mapToCreatePatientOutreachNoteServicePayload,
  mapToCreatePatientOutreachNoteReturns,
  mapToCreatePatientOutreachNoteError,
  mapToCreatePatientSelfCareTouchpointNoteServicePayload,
  mapToCreatePatientSelfCareTouchpointNoteReturns,
  mapToCreatePatientSelfCareTouchpointNoteError,
  mapToCreatePatientReferralUploadNoteServicePayload,
  mapToCreatePatientReferralUploadNoteReturns,
  mapToCreatePatientReferralUploadNoteError,
  mapToCreatePatientPTTouchpointNoteServicePayload,
  mapToCreatePatientPTTouchpointNoteReturns,
  mapToCreatePatientPTTouchpointNoteError,
  mapToCreatePatientPostOpTouchpointNoteServicePayload,
  mapToCreatePatientPostOpTouchpointNoteReturns,
  mapToCreatePatientPostOpTouchpointNoteError,
  mapToCreatePatientPreOpTouchpointNoteServicePayload,
  mapToCreatePatientPreOpTouchpointNoteReturns,
  mapToCreatePatientPreOpTouchpointNoteError,
  mapToCreateDraftNoteServicePayload,
  mapToCreateDraftNoteReturns,
  mapToCreateDraftNoteError,
  mapToUpdateDraftNoteServicePayload,
  mapToUpdateDraftNoteReturns,
  mapToUpdateDraftNoteError,
  mapToGetDraftNoteReturns,
  mapToGetDraftNoteError,
  mapToDeleteDraftNoteReturns,
  mapToDeleteDraftNoteError,
} from 'src/features/notes/adapters'
import { useAxiosErrorHandling } from 'src/features/shared/infrastructure'

export const useNotesServiceAdapter = () => {
  const notesService = useNotesService()
  const { handleAxiosError } = useAxiosErrorHandling()

  const getPatientNotes: NotesUseCases['getPatientNotes'] = async (
    patientId
  ) => {
    try {
      const response = await notesService.getPatientNotes(patientId)

      return mapToGetPatientNotesReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToGetPatientNotesError)
      }
      throw error
    }
  }

  const createPatientOrthoChartCheckNote: NotesUseCases['createPatientOrthoChartCheckNote'] =
    async (args) => {
      try {
        const serviceArgs =
          mapToCreatePatientOrthoChartCheckNoteServicePayload(args)

        const response = await notesService.createPatientOrthoChartCheckNote(
          serviceArgs
        )

        return mapToCreatePatientOrthoChartCheckNoteReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToCreatePatientOrthoChartCheckNoteError)
        }
        throw error
      }
    }

  const createPatientOrthoTouchpointNote: NotesUseCases['createPatientOrthoTouchpointNote'] =
    async (args) => {
      try {
        const serviceArgs =
          mapToCreatePatientOrthoTouchpointNoteServicePayload(args)

        const response = await notesService.createPatientOrthoTouchpointNote(
          serviceArgs
        )

        return mapToCreatePatientOrthoTouchpointNoteReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToCreatePatientOrthoTouchpointNoteError)
        }
        throw error
      }
    }

  const createPatientOutreachNote: NotesUseCases['createPatientOutreachNote'] =
    async (args) => {
      try {
        const serviceArgs = mapToCreatePatientOutreachNoteServicePayload(args)

        const response = await notesService.createPatientOutreachNote(
          serviceArgs
        )

        return mapToCreatePatientOutreachNoteReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToCreatePatientOutreachNoteError)
        }
        throw error
      }
    }

  const createPatientSelfCareTouchpointNote: NotesUseCases['createPatientSelfCareTouchpointNote'] =
    async (args) => {
      try {
        const serviceArgs =
          mapToCreatePatientSelfCareTouchpointNoteServicePayload(args)

        const response = await notesService.createPatientSelfCareTouchpointNote(
          serviceArgs
        )

        return mapToCreatePatientSelfCareTouchpointNoteReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToCreatePatientSelfCareTouchpointNoteError)
        }
        throw error
      }
    }

  const createPatientReferralUploadNote: NotesUseCases['createPatientReferralUploadNote'] =
    async (args) => {
      try {
        const serviceArgs =
          mapToCreatePatientReferralUploadNoteServicePayload(args)

        const response = await notesService.createPatientReferralUploadNote(
          serviceArgs
        )

        return mapToCreatePatientReferralUploadNoteReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToCreatePatientReferralUploadNoteError)
        }
        throw error
      }
    }

  const createPatientPTTouchpointNote: NotesUseCases['createPatientPTTouchpointNote'] =
    async (args) => {
      try {
        const serviceArgs =
          mapToCreatePatientPTTouchpointNoteServicePayload(args)

        const response = await notesService.createPatientPTTouchpointNote(
          serviceArgs
        )

        return mapToCreatePatientPTTouchpointNoteReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToCreatePatientPTTouchpointNoteError)
        }
        throw error
      }
    }

  const createPatientPostOpTouchpointNote: NotesUseCases['createPatientPostOpTouchpointNote'] =
    async (args) => {
      try {
        const serviceArgs =
          mapToCreatePatientPostOpTouchpointNoteServicePayload(args)

        const response = await notesService.createPatientPostOpTouchpointNote(
          serviceArgs
        )

        return mapToCreatePatientPostOpTouchpointNoteReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToCreatePatientPostOpTouchpointNoteError)
        }
        throw error
      }
    }

  const createPatientPreOpTouchpointNote: NotesUseCases['createPatientPreOpTouchpointNote'] =
    async (args) => {
      try {
        const serviceArgs =
          mapToCreatePatientPreOpTouchpointNoteServicePayload(args)

        const response = await notesService.createPatientPreOpTouchpointNote(
          serviceArgs
        )

        return mapToCreatePatientPreOpTouchpointNoteReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToCreatePatientPreOpTouchpointNoteError)
        }
        throw error
      }
    }

  const createPatientCustomNote: NotesUseCases['createPatientCustomNote'] =
    async (args) => {
      try {
        const serviceArgs = mapToCreatePatientCustomNoteServicePayload(args)

        const response = await notesService.createPatientCustomNote(serviceArgs)

        return mapToCreatePatientCustomNoteReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToCreatePatientCustomNoteError)
        }
        throw error
      }
    }

  const createPatientIntakeNote: NotesUseCases['createPatientIntakeNote'] =
    async (args) => {
      try {
        const serviceArgs = mapToCreatePatientIntakeNoteServicePayload(args)

        const response = await notesService.createPatientIntakeNote(serviceArgs)

        return mapToCreatePatientIntakeNoteReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToCreatePatientIntakeNoteError)
        }
        throw error
      }
    }

  const createDraftNote: NotesUseCases['createDraftNote'] = async (args) => {
    try {
      const serviceArgs = mapToCreateDraftNoteServicePayload(args)

      const response = await notesService.createDraftNote(serviceArgs)
      return mapToCreateDraftNoteReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToCreateDraftNoteError)
      }
      throw error
    }
  }

  const updateDraftNote: NotesUseCases['updateDraftNote'] = async (args) => {
    try {
      const { draftNoteId, payload } = args
      const servicePayload = mapToUpdateDraftNoteServicePayload(payload)
      const response = await notesService.updateDraftNote({
        draftNoteId,
        payload: servicePayload,
      })
      return mapToUpdateDraftNoteReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToUpdateDraftNoteError)
      }
      throw error
    }
  }

  const getDraftNote: NotesUseCases['getDraftNote'] = async (params) => {
    const { patientId } = params
    try {
      const response = await notesService.getDraftNote({ patientId })
      return mapToGetDraftNoteReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          return null
        }
        handleAxiosError(error, mapToGetDraftNoteError)
      }
      throw error
    }
  }

  const deleteDraftNote: NotesUseCases['deleteDraftNote'] = async (args) => {
    try {
      const { draftNoteId } = args
      const response = await notesService.deleteDraftNote({ draftNoteId })
      return mapToDeleteDraftNoteReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToDeleteDraftNoteError)
      }
      throw error
    }
  }

  return {
    createDraftNote,
    createPatientCustomNote,
    createPatientIntakeNote,
    createPatientOrthoChartCheckNote,
    createPatientOrthoTouchpointNote,
    createPatientOutreachNote,
    createPatientSelfCareTouchpointNote,
    createPatientReferralUploadNote,
    createPatientPTTouchpointNote,
    createPatientPostOpTouchpointNote,
    createPatientPreOpTouchpointNote,
    deleteDraftNote,
    getDraftNote,
    getPatientNotes,
    updateDraftNote,
  }
}
