export const isInViewport = (el?: HTMLElement | null) => {
  if (!el) return false

  const rect = el.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export const removeBodyScroll = () => {
  setTimeout(() => {
    document.body.style.overflow = 'hidden'
    document.body.style.paddingRight = '15px'
  }, 0)
}

export const addBodyScroll = () => {
  setTimeout(() => {
    document.body.style.overflow = 'auto'
    document.body.style.paddingRight = '0px'
  }, 0)
}
