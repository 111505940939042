import { GetPatientNotesServiceResponse } from 'src/features/notes/infrastructure'

export const getPatientNotesServiceResponseMock: GetPatientNotesServiceResponse =
  {
    notes: [
      {
        id: 'c4555d5a-1dbd-479c-805b-8d4565b48c6d',
        createdAt: '2023-06-13T19:47:24.594Z',
        note: '<p><u>Test #2</u></p>',
        author: '5aef0dd5-1eb1-492f-928d-2e37c82facfb',
        authorName: 'David GrandadOs',
        sdm: false,
        spokeWith: 'PCP',
        type: 'Custom',
        // data: null,
      },
      {
        id: 'd9b8ae12-b86c-4421-b485-74faba8db403',
        createdAt: '2023-06-10T01:14:43.608Z',
        note: '<p>Left Hip</p>',
        author: '5aef0dd5-1eb1-492f-928d-2e37c82facfb',
        authorName: 'David Grandados',
        sdm: false,
        spokeWith: 'Caregiver',
        type: 'Custom',
        // data: null,
      },
      {
        id: '61631aef-7136-43cf-9d25-d7f493823692',
        createdAt: '2023-06-10T00:37:08.517Z',
        note: '<p>Test #1</p>',
        author: '5aef0dd5-1eb1-492f-928d-2e37c82facfb',
        authorName: 'David Grandados',
        sdm: false,
        spokeWith: 'Caregiver',
        type: 'Custom',
        // data: null,
      },
    ],
    // lastNoteId: '61631aef-7136-43cf-9d25-d7f493823692',
  }
