import { GetPlansServiceResponse } from 'src/features/providers/infrastructure'

export const getPlansServiceResponseMock: GetPlansServiceResponse = {
  plans: [
    {
      id: 'fde3cb3f-e50c-4e14-a9f3-403b71850bbe',
      createdAt: '2023-08-30T19:42:34.630Z',
      createdBy: '7a89b889-8d9a-40f1-b96e-5940bdb5942a',
      plan: 'This plan should be selected',
      payorId: '123abcd',
    },
    {
      id: '90d7fbbe-3374-460f-b311-753c286bd89a',
      createdAt: '2023-08-06T19:54:32.998Z',
      createdBy: '4196eaa9-c29f-41f0-9c94-596643bf73ed',
      plan: 'Test Plan',
      payorId: '123abcd',
    },
  ],
}
