import { createBrowserRouter } from 'react-router-dom'

import { ErrorPage } from 'src/features/shared/presentation'

import RouterWithBackBaseLayout from './layouts/with-back-base'
import RouterQueuesBaseLayout from './layouts/queues-base'
import HomePage from './routes/home'
import PatientPage from './routes/patients/[patient-id]'
import QueuesPage from './routes/queues'
import QueuePatientsPage from './routes/queues/patients'

export const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <ErrorPage />,
    element: <RouterQueuesBaseLayout />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
    ],
  },
  {
    path: '/queues',
    errorElement: <ErrorPage />,
    element: <RouterQueuesBaseLayout />,
    children: [
      {
        index: true,
        element: <QueuesPage />,
      },
      {
        path: 'patients',
        element: <QueuePatientsPage />,
      },
    ],
  },
  {
    path: '/patients/:patientId',
    errorElement: <ErrorPage />,
    element: <RouterWithBackBaseLayout />,
    children: [
      {
        index: true,
        element: <PatientPage />,
      },
    ],
  },
])
