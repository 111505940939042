import { rest } from 'msw'

import * as settings from 'src/config/settings'
import { createPlanServiceResponseMock } from 'src/__mocks__/responses/create-plan-service-response'
import { CreatePlanServiceResponse } from 'src/features/providers/infrastructure'

const url = `${settings.PROVIDERS_API_BASE_URL}/plans`

export const createPlanServiceResponseHandler =
  rest.post<CreatePlanServiceResponse>(url, async (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json(createPlanServiceResponseMock))
  })
