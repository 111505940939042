import { useQuery } from '@tanstack/react-query'

import {
  GetNavigatorsError,
  GetNavigatorsParams,
  GetNavigatorsReturns,
} from 'src/features/shared/domain'
import { useCompassUsersUseCases } from 'src/features/shared/service-locator/compass-users.service-locator'

export const GET_NAVIGATORS_QUERY_KEY = 'getNavigators'

export const useGetNavigatorsQuery = (params?: GetNavigatorsParams) => {
  const navigatorsUseCases = useCompassUsersUseCases()

  const { data, isLoading, isError } = useQuery<
    GetNavigatorsReturns,
    GetNavigatorsError
  >([GET_NAVIGATORS_QUERY_KEY, params], () => {
    return navigatorsUseCases.getNavigators(params)
  })
  return {
    navigators: data ? data.navigators : [],
    getNavigatorsIsLoading: isLoading,
    getNavigatorsIsError: isError,
  }
}
