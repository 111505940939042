import {
  SelectChangeEvent,
  Box,
  Select,
  MenuItem,
  TextField,
} from '@mui/material'
import { FC, ChangeEvent, useEffect } from 'react'
import InputMask from 'react-input-mask'

import { LoadMSKFormReturns } from 'src/features/msk/domain'
import { PatientV2 } from 'src/features/patients/domain'
import {
  useMSKFormStore,
  MSKFormStateAdditionalQuestionsFieldOptions,
  BarriersToBeAwareOfOptions,
} from 'src/features/shared/infrastructure'
import { FormFieldContainer } from 'src/features/shared/presentation'
import {
  addressRegex,
  cityRegex,
  cleanAddressFieldValue,
  cleanCityFieldValue,
  cleanNameFieldValue,
  cleanPhoneFieldValue,
  cleanStateFieldValue,
  cleanZipFieldValue,
  getMaskedPhone,
  getPatientCurrentContacts,
  nameRegex,
  phoneRegex,
  stateRegex,
  zipRegex,
} from 'src/features/shared/utils'
import {
  mskFormLabelSx,
  useValidateMSKFormStepThree,
} from 'src/features/msk/presentation'

import {
  QUESTIONS_LABELS,
  getSelectDefaultSx,
  MSK_FORM_ADDITIONAL_QUESTIONS_FIELD_OPTIONS,
  getTextFieldDefaultSx,
} from './helpers'

type MSKFormStepThreePage3Props = {
  patient: PatientV2
  loadedMSKForm: LoadMSKFormReturns
}

export const MSKFormStepThreePage3: FC<MSKFormStepThreePage3Props> = ({
  patient,
  loadedMSKForm,
}) => {
  const { phone1, phone2 } = getPatientCurrentContacts(patient)

  const { additionalQuestions, setAdditionalQuestions, errors, deleteError } =
    useMSKFormStore({
      loadedMSKForm,
    })

  const {
    validateAdditionalPhone,
    validateBarriersToBeAwareOfText,
    validatePeopleAllowedToReachOutToPhone,
    validatePeopleAllowedToReachOutToWho,
    validatePcpName,
    validatePcpPhone,
    validatePcpFax,
    validatePcpAddressLine1,
    validatePcpAddressLine2,
    validatePcpCity,
    validatePcpState,
    validatePcpZip,
  } = useValidateMSKFormStepThree({ loadedMSKForm })

  const getPcpLabel = () => {
    if (
      patient &&
      patient.medicalProfile &&
      patient.medicalProfile.primaryCarePhysician
    ) {
      return `I am showing your PCP is Dr. ${patient.medicalProfile.primaryCarePhysician}.
      Is that still correct?`
    }
    return 'PCP Information'
  }

  const handleBarriersToBeAwareOfChange = (event: SelectChangeEvent) => {
    const value = event.target.value as BarriersToBeAwareOfOptions
    setAdditionalQuestions({
      barriersToBeAwareOf: value,
    })
  }

  const handleBarriersToBeAwareOfTextChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value
    setAdditionalQuestions({
      barriersToBeAwareOfText: value,
    })

    if (value) {
      deleteError('barriersToBeAwareOfText')
    }
  }

  const handleTabletComputerOrPhoneChange = (event: SelectChangeEvent) => {
    const value = event.target
      .value as MSKFormStateAdditionalQuestionsFieldOptions['v2']
    setAdditionalQuestions({
      tabletComputerOrPhone: value,
    })
  }

  const handleAdditionalPhoneChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = cleanPhoneFieldValue(event.target.value)
    setAdditionalQuestions({
      additionalPhone: value,
    })

    if (!value || phoneRegex.test(value)) {
      deleteError('additionalPhone')
    }
  }

  const handlePeopleAllowedToReachOutToWhoChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = cleanNameFieldValue(event.target.value)
    setAdditionalQuestions({
      peopleAllowedToReachOutToWho: value,
    })
    deleteError('peopleAllowedToReachOutToWho')

    if (!value) {
      deleteError('peopleAllowedToReachOutToPhone')
    }
  }

  const handlePeopleAllowedToReachOutToPhoneChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = cleanPhoneFieldValue(event.target.value)

    if (!value || phoneRegex.test(value)) {
      deleteError('peopleAllowedToReachOutToPhone')
    }

    setAdditionalQuestions({
      peopleAllowedToReachOutToPhone: value,
    })
  }

  const handlePatientConsentForSmsChange = (event: SelectChangeEvent) => {
    const value = event.target
      .value as MSKFormStateAdditionalQuestionsFieldOptions['v2']
    setAdditionalQuestions({
      patientConsentForSms: value,
    })
  }

  const handlePatientConsentForSmsAdditionalPhoneChange = (
    event: SelectChangeEvent
  ) => {
    const value = event.target
      .value as MSKFormStateAdditionalQuestionsFieldOptions['v2']
    setAdditionalQuestions({
      patientConsentForSmsAdditionalPhone: value,
    })
  }

  const handlePcpNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = cleanNameFieldValue(event.target.value).slice(0, 255)
    setAdditionalQuestions({
      pcpName: value,
    })

    if (!value || nameRegex.test(value)) {
      deleteError('pcpName')
    }
  }

  const handlePcpPhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = cleanPhoneFieldValue(event.target.value)
    setAdditionalQuestions({
      pcpPhone: value,
    })

    if (!value || phoneRegex.test(value)) {
      deleteError('pcpPhone')
    }
  }

  const handlePcpFaxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = cleanPhoneFieldValue(event.target.value)
    setAdditionalQuestions({
      pcpFax: value,
    })

    if (!value || phoneRegex.test(value)) {
      deleteError('pcpFax')
    }
  }

  const handlePcpGroupNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAdditionalQuestions({
      pcpGroupName: event.target.value,
    })
  }

  const handlePcpAddressLine1Change = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = cleanAddressFieldValue(event.target.value)
    setAdditionalQuestions({
      pcpAddressLine1: value,
    })

    if (!value || addressRegex.test(value)) {
      deleteError('pcpAddressLine1')
    }
  }

  const handlePcpAddressLine2Change = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = cleanAddressFieldValue(event.target.value)
    setAdditionalQuestions({
      pcpAddressLine2: value,
    })

    if (!value || addressRegex.test(value)) {
      deleteError('pcpAddressLine2')
    }
  }

  const handlePcpCityChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = cleanCityFieldValue(event.target.value)
    setAdditionalQuestions({
      pcpCity: value,
    })

    if (!value || cityRegex.test(value)) {
      deleteError('pcpCity')
    }
  }

  const handlePcpStateChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = cleanStateFieldValue(event.target.value).slice(0, 2)
    setAdditionalQuestions({
      pcpState: value,
    })

    if (!value || stateRegex.test(value)) {
      deleteError('pcpState')
    }
  }

  const handlePcpZipChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = cleanZipFieldValue(event.target.value)
    setAdditionalQuestions({
      pcpZip: value,
    })

    if (!value || zipRegex.test(value)) {
      deleteError('pcpZip')
    }
  }

  useEffect(() => {
    if (patient.medicalProfile) {
      setAdditionalQuestions({
        pcpName:
          additionalQuestions.pcpName ||
          patient.medicalProfile.primaryCarePhysician ||
          '',
        pcpPhone:
          additionalQuestions.pcpPhone || patient.medicalProfile.phone || '',
        pcpFax: additionalQuestions.pcpFax || patient.medicalProfile.fax || '',
        pcpGroupName:
          additionalQuestions.pcpGroupName ||
          patient.medicalProfile.groupName ||
          '',
        pcpAddressLine1:
          additionalQuestions.pcpAddressLine1 ||
          patient.medicalProfile.addressLine1 ||
          '',
        pcpAddressLine2:
          additionalQuestions.pcpAddressLine2 ||
          patient.medicalProfile.addressLine2 ||
          '',
        pcpCity:
          additionalQuestions.pcpCity || patient.medicalProfile.city || '',
        pcpState:
          additionalQuestions.pcpState || patient.medicalProfile.state || '',
        pcpZip: additionalQuestions.pcpZip || patient.medicalProfile.zip || '',
      })
    }

    if (phone2?.phone && additionalQuestions.additionalPhone === '') {
      setAdditionalQuestions({
        additionalPhone: phone2.phone.trim(),
        patientConsentForSmsAdditionalPhone: phone2.consentForSms ? 'Y' : 'N',
      })
    }

    if (phone1?.phone) {
      setAdditionalQuestions({
        patientConsentForSms: phone1.consentForSms ? 'Y' : 'N',
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '32px',
      }}
    >
      <FormFieldContainer
        id={'tabletComputerOrPhone'}
        label={QUESTIONS_LABELS.tabletComputerOrPhone}
        error={!!errors.tabletComputerOrPhone}
        helperText={
          errors.tabletComputerOrPhone ? errors.tabletComputerOrPhone : ''
        }
        formLabelSx={mskFormLabelSx}
      >
        <Select
          id={'tabletComputerOrPhone'}
          size="small"
          value={additionalQuestions.tabletComputerOrPhone}
          onChange={handleTabletComputerOrPhoneChange}
          error={!!errors.tabletComputerOrPhone}
          sx={{
            ...getSelectDefaultSx(),
          }}
        >
          {MSK_FORM_ADDITIONAL_QUESTIONS_FIELD_OPTIONS.v2.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.display}
            </MenuItem>
          ))}
        </Select>
      </FormFieldContainer>

      <FormFieldContainer
        id={'barriersToBeAwareOf'}
        label={QUESTIONS_LABELS.barriersToBeAwareOf}
        error={!!errors.barriersToBeAwareOf || !!errors.barriersToBeAwareOfText}
        formLabelSx={mskFormLabelSx}
      >
        <Box
          sx={{
            display: 'flex',
            columnGap: '16px',
            alignContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <Select
            id={'barriersToBeAwareOf'}
            size="small"
            value={additionalQuestions.barriersToBeAwareOf}
            onChange={handleBarriersToBeAwareOfChange}
            error={!!errors.barriersToBeAwareOf}
            sx={{
              ...getSelectDefaultSx(),
            }}
          >
            {MSK_FORM_ADDITIONAL_QUESTIONS_FIELD_OPTIONS.barriersToBeAwareOf.map(
              (option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.display}
                </MenuItem>
              )
            )}
          </Select>

          {additionalQuestions.barriersToBeAwareOf === 'other' ? (
            <TextField
              label={'What is the barrier?'}
              size={'small'}
              error={!!errors.barriersToBeAwareOfText}
              value={additionalQuestions.barriersToBeAwareOfText}
              onBlur={() => validateBarriersToBeAwareOfText()}
              onChange={handleBarriersToBeAwareOfTextChange}
              required
              helperText={
                errors.barriersToBeAwareOfText
                  ? errors.barriersToBeAwareOfText
                  : ''
              }
              sx={{
                ...getTextFieldDefaultSx(),
              }}
            />
          ) : null}
        </Box>
      </FormFieldContainer>

      <FormFieldContainer
        id={'patientConsentForSms'}
        label={QUESTIONS_LABELS.patientConsentForSms.replace(
          '{{phoneNumber}}',
          getMaskedPhone(phone1?.phone) ?? ''
        )}
        error={!!errors.patientConsentForSms}
        helperText={
          errors.patientConsentForSms ? errors.patientConsentForSms : ''
        }
        formLabelSx={mskFormLabelSx}
      >
        <Select
          id={'patientConsentForSms'}
          size="small"
          value={additionalQuestions.patientConsentForSms}
          onChange={handlePatientConsentForSmsChange}
          error={!!errors.patientConsentForSms}
          sx={{
            ...getSelectDefaultSx(),
          }}
        >
          {MSK_FORM_ADDITIONAL_QUESTIONS_FIELD_OPTIONS.v2.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.display}
            </MenuItem>
          ))}
        </Select>
      </FormFieldContainer>

      <FormFieldContainer
        id={'additionalPhone'}
        label={QUESTIONS_LABELS.additionalPhone}
        error={!!errors.additionalPhone}
        formLabelSx={mskFormLabelSx}
      >
        <InputMask
          mask="(999) 999-9999"
          value={getMaskedPhone(additionalQuestions.additionalPhone)}
          onBlur={() => validateAdditionalPhone()}
          onChange={handleAdditionalPhoneChange}
        >
          <TextField
            error={!!errors.additionalPhone}
            helperText={errors.additionalPhone ? errors.additionalPhone : ''}
            size={'small'}
            sx={{
              ...getTextFieldDefaultSx(),
            }}
          />
        </InputMask>
      </FormFieldContainer>

      <FormFieldContainer
        id={'patientConsentForSmsAdditionalPhone'}
        label={QUESTIONS_LABELS.patientConsentForSmsAdditionalPhone}
        error={!!errors.patientConsentForSmsAdditionalPhone}
        helperText={
          errors.patientConsentForSmsAdditionalPhone
            ? errors.patientConsentForSmsAdditionalPhone
            : ''
        }
        formLabelSx={mskFormLabelSx}
      >
        <Select
          id={'patientConsentForSmsAdditionalPhone'}
          size="small"
          value={additionalQuestions.patientConsentForSmsAdditionalPhone}
          onChange={handlePatientConsentForSmsAdditionalPhoneChange}
          error={!!errors.patientConsentForSmsAdditionalPhone}
          sx={{
            ...getSelectDefaultSx(),
          }}
        >
          {MSK_FORM_ADDITIONAL_QUESTIONS_FIELD_OPTIONS.v2.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.display}
            </MenuItem>
          ))}
        </Select>
      </FormFieldContainer>

      <FormFieldContainer
        id={'peopleAllowedToReachOutTo'}
        label={QUESTIONS_LABELS.peopleAllowedToReachOutTo}
        error={
          !!errors.peopleAllowedToReachOutToWho ||
          !!errors.peopleAllowedToReachOutToPhone
        }
        formLabelSx={mskFormLabelSx}
      >
        <Box
          sx={{
            display: 'flex',
            columnGap: '16px',
          }}
        >
          <TextField
            label={'Who?'}
            size={'small'}
            error={!!errors.peopleAllowedToReachOutToWho}
            value={additionalQuestions.peopleAllowedToReachOutToWho}
            onBlur={() => validatePeopleAllowedToReachOutToWho()}
            onChange={handlePeopleAllowedToReachOutToWhoChange}
            helperText={
              errors.peopleAllowedToReachOutToWho
                ? errors.peopleAllowedToReachOutToWho
                : ''
            }
            required={!!additionalQuestions.peopleAllowedToReachOutToPhone}
            sx={{
              ...getTextFieldDefaultSx(),
            }}
          />
          <InputMask
            mask="(999) 999-9999"
            value={getMaskedPhone(
              additionalQuestions.peopleAllowedToReachOutToPhone
            )}
            onBlur={() => validatePeopleAllowedToReachOutToPhone()}
            onChange={handlePeopleAllowedToReachOutToPhoneChange}
          >
            <TextField
              label={'Phone Number'}
              size={'small'}
              error={!!errors.peopleAllowedToReachOutToPhone}
              helperText={
                errors.peopleAllowedToReachOutToPhone
                  ? errors.peopleAllowedToReachOutToPhone
                  : ''
              }
              required={!!additionalQuestions.peopleAllowedToReachOutToWho}
              sx={{
                ...getTextFieldDefaultSx(),
              }}
            />
          </InputMask>
        </Box>
      </FormFieldContainer>

      <FormFieldContainer label={getPcpLabel()} formLabelSx={mskFormLabelSx}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '16px',
          }}
        >
          <TextField
            label={QUESTIONS_LABELS.pcpName}
            size={'small'}
            error={!!errors.pcpName}
            value={additionalQuestions.pcpName}
            onBlur={() => validatePcpName()}
            onChange={handlePcpNameChange}
            helperText={errors.pcpName ? errors.pcpName : ''}
            sx={{
              ...getTextFieldDefaultSx(),
            }}
          />
          <InputMask
            mask="(999) 999-9999"
            value={getMaskedPhone(additionalQuestions.pcpPhone)}
            onBlur={() => validatePcpPhone()}
            onChange={handlePcpPhoneChange}
          >
            <TextField
              label={QUESTIONS_LABELS.pcpPhone}
              size={'small'}
              error={!!errors.pcpPhone}
              helperText={errors.pcpPhone ? errors.pcpPhone : ''}
              sx={{
                ...getTextFieldDefaultSx(),
              }}
            />
          </InputMask>
          <InputMask
            mask="(999) 999-9999"
            value={getMaskedPhone(additionalQuestions.pcpFax)}
            onBlur={() => validatePcpFax()}
            onChange={handlePcpFaxChange}
          >
            <TextField
              label={QUESTIONS_LABELS.pcpFax}
              size={'small'}
              error={!!errors.pcpFax}
              helperText={errors.pcpFax ? errors.pcpFax : ''}
              sx={{
                ...getTextFieldDefaultSx(),
              }}
            />
          </InputMask>
          <TextField
            label={QUESTIONS_LABELS.pcpGroupName}
            size={'small'}
            error={!!errors.pcpGroupName}
            value={additionalQuestions.pcpGroupName}
            onChange={handlePcpGroupNameChange}
            helperText={errors.pcpGroupName ? errors.pcpGroupName : ''}
            sx={{
              ...getTextFieldDefaultSx(),
            }}
          />
          <TextField
            label={QUESTIONS_LABELS.pcpAddressLine1}
            size={'small'}
            error={!!errors.pcpAddressLine1}
            value={additionalQuestions.pcpAddressLine1}
            onBlur={() => validatePcpAddressLine1()}
            onChange={handlePcpAddressLine1Change}
            helperText={errors.pcpAddressLine1 ? errors.pcpAddressLine1 : ''}
            sx={{
              ...getTextFieldDefaultSx(),
            }}
          />
          <TextField
            label={QUESTIONS_LABELS.pcpAddressLine2}
            size={'small'}
            error={!!errors.pcpAddressLine2}
            value={additionalQuestions.pcpAddressLine2}
            onBlur={() => validatePcpAddressLine2()}
            onChange={handlePcpAddressLine2Change}
            helperText={errors.pcpAddressLine2 ? errors.pcpAddressLine2 : ''}
            sx={{
              ...getTextFieldDefaultSx(),
            }}
          />
          <TextField
            label={QUESTIONS_LABELS.pcpCity}
            size={'small'}
            error={!!errors.pcpCity}
            value={additionalQuestions.pcpCity}
            onBlur={() => validatePcpCity()}
            onChange={handlePcpCityChange}
            helperText={errors.pcpCity ? errors.pcpCity : ''}
            sx={{
              ...getTextFieldDefaultSx(),
            }}
          />
          <TextField
            label={QUESTIONS_LABELS.pcpState}
            size={'small'}
            error={!!errors.pcpState}
            value={additionalQuestions.pcpState}
            onBlur={() => validatePcpState()}
            onChange={handlePcpStateChange}
            helperText={errors.pcpState ? errors.pcpState : ''}
            sx={{
              ...getTextFieldDefaultSx(),
            }}
          />
          <TextField
            label={QUESTIONS_LABELS.pcpZip}
            size={'small'}
            error={!!errors.pcpZip}
            value={additionalQuestions.pcpZip}
            onBlur={() => validatePcpZip()}
            onChange={handlePcpZipChange}
            helperText={errors.pcpZip ? errors.pcpZip : ''}
            sx={{
              ...getTextFieldDefaultSx(),
            }}
          />
        </Box>
      </FormFieldContainer>
    </Box>
  )
}
