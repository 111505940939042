import { UseTasksUseCases } from 'src/features/tasks/domain'
import { useTasksServiceAdapter } from 'src/features/tasks/adapters'

type MakeUseTasksUseCases = () => UseTasksUseCases

const makeUseTasksUseCases: MakeUseTasksUseCases = () => () => {
  const tasksServiceAdapter = useTasksServiceAdapter()

  return {
    createTask: tasksServiceAdapter.createTask,
    getAllTasksByUserId: tasksServiceAdapter.getAllTasksByUserId,
    getDoneTasksByUserId: tasksServiceAdapter.getDoneTasksByUserId,
    getTodayTasksByUserId: tasksServiceAdapter.getTodayTasksByUserId,
    getUrgentTasksByUserId: tasksServiceAdapter.getUrgentTasksByUserId,
    getOverdueTasksByUserId: tasksServiceAdapter.getOverdueTasksByUserId,
    getUpcomingTasksByUserId: tasksServiceAdapter.getUpcomingTasksByUserId,
    updateTask: tasksServiceAdapter.updateTask,
    updateTasks: tasksServiceAdapter.updateTasks,
    getAllTasksByPatientId: tasksServiceAdapter.getAllTasksByPatientId,
    getUpcomingTasksByPatientId:
      tasksServiceAdapter.getUpcomingTasksByPatientId,
    getUrgentTasksByPatientId: tasksServiceAdapter.getUrgentTasksByPatientId,
    getDoneTasksByPatientId: tasksServiceAdapter.getDoneTasksByPatientId,
    getOverdueTasksByPatientId: tasksServiceAdapter.getOverdueTasksByPatientId,
    getTodayTasksByPatientId: tasksServiceAdapter.getTodayTasksByPatientId,
  }
}

export const useTasksUseCases: UseTasksUseCases = makeUseTasksUseCases()
