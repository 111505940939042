import { TextField, Box, IconButton, Tooltip } from '@mui/material'
import { FC, useState } from 'react'
import { toast } from 'react-toastify'

import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import { useUpdatePatient } from 'src/features/patients/presentation'
import { BackdropLoader } from 'src/features/shared/presentation'

type MRNFieldProps = {
  patientId: string
  defaultValue: string
  onCancel: () => void
  onSuccess?: () => void
  onError?: () => void
}

export const MRNField: FC<MRNFieldProps> = ({
  patientId,
  defaultValue,
  onCancel,
  onSuccess,
  onError,
}) => {
  const [inputValue, setInputValue] = useState(defaultValue)
  const { updatePatient, updatePatientIsLoading } = useUpdatePatient()

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    const onlyNums = value.replace(/[^0-9]/g, '')
    setInputValue(onlyNums)
  }

  const handleSave = () => {
    updatePatient(
      {
        patientId: patientId,
        payload: { mrn: inputValue },
      },
      {
        onSuccess: () => {
          toast.success('Patient MRN updated!')
          if (onSuccess) onSuccess()
        },
        onError: () => {
          toast.error('Patient MRN update failed!')
          if (onError) onError()
        },
      }
    )
  }

  const ButtonsSaveCancel = () => {
    return (
      <Box display="flex">
        <Tooltip title="Save" arrow>
          <IconButton onClick={handleSave} color={'secondary'}>
            <CheckCircleIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Cancel" arrow>
          <IconButton onClick={onCancel}>
            <CancelRoundedIcon />
          </IconButton>
        </Tooltip>
      </Box>
    )
  }

  return (
    <div>
      <BackdropLoader open={updatePatientIsLoading} />
      <Box display="flex" gap="4px">
        <TextField
          variant="standard"
          value={inputValue}
          onChange={handleInputChange}
          style={{ width: '4rem' }}
        />
        <ButtonsSaveCancel />
      </Box>
    </div>
  )
}
