import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert, AlertTitle, Backdrop, Box, Typography } from '@mui/material'

import { useAuth } from 'src/features/shared/infrastructure'

import * as settings from 'src/config/settings'

export const ErrorPage: FC = () => {
  const navigate = useNavigate()
  const { logout } = useAuth()

  const handleHelpClick = () => {
    window.open(settings.HELP_URL, '_blank')
  }

  const handleGoBackClick = () => {
    navigate(-1)
  }

  const handleRefreshClick = () => {
    window.location.reload()
  }

  const handleLogoutClick = () => {
    logout()
  }

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <Alert severity="warning">
          <AlertTitle>There’s been a glitch with Compass...</AlertTitle>
          <Typography>We’re not quite sure what went wrong.</Typography>
          <br />
          <Typography>You can try to:</Typography>
          <ul>
            <li>
              <Typography
                component={'span'}
                onClick={handleGoBackClick}
                sx={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                Go back
              </Typography>
            </li>
            <li>
              <Typography
                component={'span'}
                onClick={handleRefreshClick}
                sx={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                Refresh the page
              </Typography>
            </li>
            <li>
              <Typography
                component={'span'}
                onClick={handleLogoutClick}
                sx={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                Logout and log back in
              </Typography>
            </li>
            <li>
              <Typography component={'span'}>
                Verify that your AWS Vpn is connected
              </Typography>
            </li>
          </ul>
          <Typography>
            Contact the team via the{' '}
            <Typography
              component={'span'}
              onClick={handleHelpClick}
              sx={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              Help Center
            </Typography>{' '}
            if none of the above work.
          </Typography>
        </Alert>
      </Box>
    </Backdrop>
  )
}
