import { FC, useEffect, useMemo, useState } from 'react'
import { Box, Paper } from '@mui/material'

import {
  BackdropLoader,
  FullSectionLoader,
  useGetNavigatorsQuery,
  usePatientId,
} from 'src/features/shared/presentation'
import {
  useGetPatientPainAndFunctionQueryV2,
  PatientPageHeaderView,
  PatientPageHeaderEditForm,
  PatientConditionPainFunctionGraphsSection,
  useGetPatientQueryV2,
  PatientGlanceEditForm,
  PatientGlanceView,
} from 'src/features/patients/presentation'
import { useGetPathwaysQuery } from 'src/features/pathways/presentation'
import {
  mapToPatientHeaderEditFormFields,
  mapToPatientHeaderData,
  mapToPatientGlanceEditFormFields,
} from 'src/features/patients/adapters'
import {
  ConditionsCareJourneys,
  MSKFormModal,
  useGetPatientIntakeFlowsQuery,
  useGetPatientLastIntakeFlowQuery,
  useLoadMSKFormQuery,
} from 'src/features/msk/presentation'
import {
  useMSKFormStore,
  usePatientPageStore,
} from 'src/features/shared/infrastructure'
import { PatientMultiplePathwayForm } from 'src/features/pathways/presentation/components'
import {
  PatientNotesSection,
  useGetDraftNote,
  useGetPatientNotesQuery,
} from 'src/features/notes/presentation'
import { ChildrenInProps } from 'src/features/shared/types'
import { IntakeFlow } from 'src/features/msk/domain'
import { mapToPatientIntakeFlowV2Data } from 'src/features/msk/adapters'
import { mapToConditionWithPathway } from 'src/features/pathways/adapters'
import {
  PatientTouchpoints,
  useGetAllTasksByPatientIdQuery,
} from 'src/features/tasks/presentation'
import { CareTeamForm } from 'src/features/care-team/presentation'
import { useGetPlansQuery } from 'src/features/providers/presentation'

export type PatientIntakeFlowWithCreatedInfoV2 = IntakeFlow & {
  createdInfo: string
}

const getPatientPageSectionFlex = () => {
  return {
    xs: '0 1 940px',
  }
}

const PatientSectionWrapper: FC<ChildrenInProps> = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      {children}
    </Box>
  )
}

export const PatientPagePaperSectionV2: FC<ChildrenInProps> = ({
  children,
}) => {
  return (
    <PatientSectionWrapper>
      <Paper
        sx={{
          flex: getPatientPageSectionFlex(),
        }}
      >
        {children}
      </Paper>
    </PatientSectionWrapper>
  )
}

export const PatientPage: FC = () => {
  const patientId = usePatientId()
  const [showEditForm, setShowEditForm] = useState(false)
  const [showPatientGlanceEditForm, setShowPatientGlanceEditForm] =
    useState(false)
  const {
    openMSKFormModal,
    setOpenMSKFormModal,
    setPainFunctionGraphsSelectedCondition,
    setCareTeamSelectedCondition,
    careTeamSelectedCondition: careTeamSelectedConditionId,
  } = usePatientPageStore()
  const { patient, getPatientIsLoading } = useGetPatientQueryV2()
  const { navigators, getNavigatorsIsLoading } = useGetNavigatorsQuery()
  const { getPathwaysIsLoading } = useGetPathwaysQuery()
  const { loadedMSKForm, mskFormIsLoading } = useLoadMSKFormQuery()
  const { mskFormPatientId, initMSKForm, resetMSKForm } = useMSKFormStore()
  const {
    intakeFlows,
    questionsAndActions,
    getPatientIntakeFlowsIsLoading,
    actionsOpenToTry,
    previouslyTriedActions,
  } = useGetPatientIntakeFlowsQuery()
  const {
    patientPainAndFunctionConditions,
    getPatientPainAndFunctionIsLoading,
  } = useGetPatientPainAndFunctionQueryV2()
  const { plans } = useGetPlansQuery()
  const { patientAllTasksIsLoading } = useGetAllTasksByPatientIdQuery({
    params: { paginated: false, withUrgent: true },
  })
  const { lastIntakeFlow } = useGetPatientLastIntakeFlowQuery()

  const { patientNotes, getPatientNotesIsLoading, getPatientNotesIsFetching } =
    useGetPatientNotesQuery()

  const { getDraftNoteIsLoading } = useGetDraftNote({
    patientId: patientId ?? undefined,
  })

  const conditions = useMemo(() => {
    return questionsAndActions
      ? mapToPatientIntakeFlowV2Data({
          intakeFlows,
          navigators,
          previouslyTriedActions,
          actionsOpenToTry,
          questionsAndActions,
        })
      : {
          allConditions: [],
          activeConditions: [],
          inactiveConditions: [],
          completedConditions: [],
        }
  }, [
    intakeFlows,
    navigators,
    previouslyTriedActions,
    actionsOpenToTry,
    questionsAndActions,
  ])

  const showGraphSection =
    getPatientPainAndFunctionIsLoading ||
    patientPainAndFunctionConditions.length

  const pathwayFormSectionIsLoading =
    getNavigatorsIsLoading ||
    getPathwaysIsLoading ||
    getPatientIntakeFlowsIsLoading

  const intakeFlowsSectionIsLoading =
    getNavigatorsIsLoading ||
    getPatientIntakeFlowsIsLoading ||
    getPatientIsLoading

  const careTeamSectionIsLoading =
    getPatientIntakeFlowsIsLoading || getPatientIsLoading

  const showBackDropLoader =
    getPatientIsLoading || (openMSKFormModal && mskFormIsLoading)

  const careTeamSelectedCondition = useMemo(() => {
    return conditions.allConditions.find(
      (condition) => condition.id === careTeamSelectedConditionId
    )
  }, [careTeamSelectedConditionId, conditions])

  const careTeamSelectedConditionKey = useMemo(() => {
    if (!careTeamSelectedCondition) return 'care-team-form'

    let key = `care-team-${careTeamSelectedCondition.id}`

    if (careTeamSelectedCondition.associatedPathway) {
      key += `-${careTeamSelectedCondition.associatedPathway.id}`
    }

    return key
  }, [careTeamSelectedCondition])

  const handleEditTaskClick = () => setShowEditForm(true)

  const handleEditFormCancelClick = () => setShowEditForm(false)

  const handleEditFormSave = () => setShowEditForm(false)

  const handleEditPatientGlanceTaskClick = () =>
    setShowPatientGlanceEditForm(true)

  const handleEditPatientGlanceFormCancelClick = () =>
    setShowPatientGlanceEditForm(false)

  const handleEditPatientGlanceFormSave = () =>
    setShowPatientGlanceEditForm(false)

  const handleIntakeAssessmentClick = () => {
    if (patientId !== mskFormPatientId) {
      initMSKForm(patientId ?? '')
    }
    setOpenMSKFormModal(true)
  }

  useEffect(() => {
    resetMSKForm()

    return () => {
      setPainFunctionGraphsSelectedCondition(undefined)
      setCareTeamSelectedCondition(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box
      id={'patient-page'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '56px',
        alignItems: 'center',
      }}
    >
      {patient ? (
        <>
          {/* Patient Page Header */}
          <PatientPagePaperSectionV2>
            {showEditForm ? (
              <PatientPageHeaderEditForm
                patient={patient}
                initialValues={mapToPatientHeaderEditFormFields(patient, plans)}
                onCancelClick={handleEditFormCancelClick}
                onSaveSuccess={handleEditFormSave}
              />
            ) : (
              <PatientPageHeaderView
                patient={mapToPatientHeaderData(patient, plans, lastIntakeFlow)}
                onEditClick={handleEditTaskClick}
              />
            )}
          </PatientPagePaperSectionV2>

          <PatientPagePaperSectionV2>
            {showPatientGlanceEditForm ? (
              <PatientGlanceEditForm
                patient={patient}
                initialValues={mapToPatientGlanceEditFormFields(patient)}
                onCancelClick={handleEditPatientGlanceFormCancelClick}
                onSaveSuccess={handleEditPatientGlanceFormSave}
              ></PatientGlanceEditForm>
            ) : (
              <PatientGlanceView
                patient={mapToPatientHeaderData(patient, plans, lastIntakeFlow)}
                onEditClick={handleEditPatientGlanceTaskClick}
              />
            )}
          </PatientPagePaperSectionV2>

          {/* Patient Page Pathway Form */}
          <PatientPagePaperSectionV2>
            {patientAllTasksIsLoading ? (
              <FullSectionLoader />
            ) : (
              <PatientTouchpoints />
            )}
          </PatientPagePaperSectionV2>

          {/* Patient Page Pathway Form */}
          <PatientPagePaperSectionV2>
            {pathwayFormSectionIsLoading ? (
              <FullSectionLoader />
            ) : (
              <PatientMultiplePathwayForm
                patient={patient}
                activeConditionsWithPathways={mapToConditionWithPathway(
                  conditions.activeConditions,
                  patient.pathways
                )}
                inactiveConditionsWithPathways={mapToConditionWithPathway(
                  conditions.inactiveConditions,
                  patient.pathways
                )}
              />
            )}
          </PatientPagePaperSectionV2>

          {/* Patient Care Team Form */}
          <PatientPagePaperSectionV2>
            {careTeamSectionIsLoading ? (
              <FullSectionLoader />
            ) : (
              <CareTeamForm
                key={careTeamSelectedConditionKey}
                patient={patient}
                condition={careTeamSelectedCondition}
              />
            )}
          </PatientPagePaperSectionV2>

          {/* Patient Page Pain Function Graphs */}
          {showGraphSection ? (
            <PatientPagePaperSectionV2>
              {getPatientPainAndFunctionIsLoading ? (
                <FullSectionLoader />
              ) : null}
              {patientPainAndFunctionConditions.length ? (
                <PatientConditionPainFunctionGraphsSection
                  conditions={patientPainAndFunctionConditions}
                />
              ) : null}
            </PatientPagePaperSectionV2>
          ) : null}

          {/* Patient Page Intake Results */}
          <PatientPagePaperSectionV2>
            {intakeFlowsSectionIsLoading ? (
              <FullSectionLoader />
            ) : patient ? (
              <ConditionsCareJourneys
                patient={patient}
                inactiveConditions={conditions.inactiveConditions}
                activeConditions={conditions.activeConditions}
                completedConditions={conditions.completedConditions}
                onIntakeAssessmentClick={handleIntakeAssessmentClick}
              />
            ) : null}
          </PatientPagePaperSectionV2>

          {/* Patient Page Notes */}
          <PatientPagePaperSectionV2>
            {getPatientNotesIsLoading ||
            getPatientNotesIsFetching ||
            getDraftNoteIsLoading ? (
              <FullSectionLoader />
            ) : (
              <PatientNotesSection
                patient={patient}
                patientNotes={patientNotes}
              />
            )}
          </PatientPagePaperSectionV2>

          {loadedMSKForm ? (
            <MSKFormModal patient={patient} loadedMSKForm={loadedMSKForm} />
          ) : null}
        </>
      ) : null}
      <BackdropLoader open={showBackDropLoader} />
    </Box>
  )
}
