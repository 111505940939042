import {
  UseMutateFunction,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { useCareTeamUseCases } from 'src/features/care-team/care-team.service-locator'
import {
  CreateCareTeamError,
  CreateCareTeamArgs,
  CreateCareTeamReturns,
} from 'src/features/care-team/domain'
import { GET_PATIENT_INTAKE_FLOWS_QUERY_KEY } from 'src/features/msk/presentation'

type useCreateCareTeamProps = Omit<
  UseMutationOptions<
    CreateCareTeamReturns,
    CreateCareTeamError,
    CreateCareTeamArgs,
    unknown
  >,
  'mutationFn'
>

type UseCreateCareTeam = (props?: useCreateCareTeamProps) => {
  createCareTeam: UseMutateFunction<
    CreateCareTeamReturns,
    CreateCareTeamError,
    CreateCareTeamArgs,
    unknown
  >
  createCareTeamIsLoading: boolean
  createCareTeamIsError: boolean
  createCareTeamStatus: string
}

export const useCreateCareTeam: UseCreateCareTeam = (props = {}) => {
  const careTeamUseCases = useCareTeamUseCases()
  const queryClient = useQueryClient()

  const { onSuccess } = props

  const { mutate, isLoading, isError, status } = useMutation<
    CreateCareTeamReturns,
    CreateCareTeamError,
    CreateCareTeamArgs,
    unknown
  >({
    mutationFn: (args: CreateCareTeamArgs) => {
      return careTeamUseCases.createCareTeam(args)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [GET_PATIENT_INTAKE_FLOWS_QUERY_KEY, variables.patientId],
      })
      onSuccess?.(data, variables, context)
    },
  })

  return {
    createCareTeam: mutate,
    createCareTeamIsLoading: isLoading,
    createCareTeamIsError: isError,
    createCareTeamStatus: status,
  }
}
