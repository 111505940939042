import {
  GetPatientV2ServiceResponse,
  SPatientV2,
} from 'src/features/patients/infrastructure'

const patientWithLockedPathway: SPatientV2 = {
  patientId: '3e512034-cb3d-49bc-a4e5-2c8aa93f73ba',
  firstName: 'Ashil',
  middleName: null,
  // mrn: null,
  lastName: 'Panons',
  dob: '06/09/2025',
  mbi: '2RG3RA6JD19',
  doNotCall: false,
  referralSource: 'Outbound Marketing',
  isThreadwell: true,
  eligible: true,
  dualCoverage: false,
  language: null,
  notes: [
    {
      id: '61eb3f8d-99ee-4bbc-a726-05d0f49c8c22',
      author: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      createdAt: '2023-10-05T18:02:17.157127+00:00',
      note: '<p>test</p>',
      spokeWith: 'Patient',
      sdm: false,
    },
    {
      id: 'b7b4c030-69df-4c7d-97e0-58afceddbbd0',
      author: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      createdAt: '2023-10-05T18:01:41.665541+00:00',
      note: '<p>test</p>',
      spokeWith: 'Patient',
      sdm: false,
    },
  ],
  contacts: [
    {
      id: '19ce8b0b-d7fd-4293-bfdc-3bd8146f477a',
      email: null,
      phone: '6990426534  ',
    },
    {
      id: '5b533cf9-b956-470e-9220-d146eed931c3',
      email: 'hvandaalen1z@plala.or.jp',
      phone: null,
    },
  ],
  addressLine1: '203 Sage Lane',
  addressLine2: null,
  city: 'Bakersfield',
  state: 'CA',
  zip: '23046',
  addressId: '2a221453-550c-4f2c-a29d-4195b6c14283',
  pathways: [
    {
      id: '39f7ed1a-5403-462a-a0e1-42d44a597b61',
      done: false,
      type: 'phone',
      track: 'Non-Resurgens - Non-Surgical',
      pathway: 'Ortho',
      intakeId: '240c3946-6995-40ba-89be-e493404c0710',
      isPaused: false,
      lockDate: '10/03/2023',
      firstAppointmentDate: '10/04/2023',
      createdBy: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      navigator: 'E2E Automation ',
      pathwayId: 'af9ca2c6-eb52-4831-a2f1-9951afd46cf9',
      patientId: '3e512034-cb3d-49bc-a4e5-2c8aa93f73ba',
      assignedTo: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      isCancelled: false,
      pathwayCode: 'OR06P',
      pathwayType: 'Physiatry',
      creationDate: '10/03/2023',
      lastUpdatedAt: '10/03/2023',
      eventsGenerated: true,
    },
    {
      id: '214a6c0f-3599-4d86-ad3b-d8bcb0642225',
      done: false,
      type: 'phone',
      track: 'In Person Appt',
      pathway: 'PT',
      intakeId: '9ca7749c-3cce-47c4-a4d7-da07003849c9',
      isPaused: false,
      lockDate: '10/03/2023',
      firstAppointmentDate: '10/04/2023',
      createdBy: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      navigator: 'E2E Automation ',
      pathwayId: '32e9b9f1-a317-496a-a78d-3fa1e3d7a8da',
      patientId: '3e512034-cb3d-49bc-a4e5-2c8aa93f73ba',
      assignedTo: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      isCancelled: false,
      pathwayCode: 'PT01P',
      pathwayType: 'McKenzie',
      creationDate: '10/03/2023',
      lastUpdatedAt: '10/03/2023',
      eventsGenerated: true,
    },
  ],
  stageAndStatus: {
    stage: 'Engaged',
    status: 'On Pathway',
  },
  medicalProfile: {
    id: '49340cc6-7e9b-48a2-90c6-61431f79c58a',
    fax: null,
    zip: '38172',
    city: 'Washington',
    plan: 'Humana',
    payor: 'Humana',
    phone: null,
    state: 'DC',
    county: null,
    planId: 'H08319-436-908',
    primary: true,
    planType: 'HMO',
    provider: null,
    createdAt: '2023-10-03T19:10:32.187797+00:00',
    groupName: 'PCP Group Name 95',
    patientId: '3e512034-cb3d-49bc-a4e5-2c8aa93f73ba',
    addressLine1: '3 Artisan Parkway',
    addressLine2: null,
    linkedPlanId: null,
    lastUpdatedAt: '2023-10-03T19:10:32.187797+00:00',
    linkedProviderId: null,
    primaryCarePhysician: 'Hamlin Van Daalen',
    marketId: '3e512034-cb3d-49bc-a4e5-2c8aa93f73ba',
    contractedWith: '3e512034-cb3d-49bc-a4e5-2c8aa93f73ba',
  },
  patientViewersHistory: [
    {
      id: '4e9cc2f7-259a-4819-bfd8-a97f13c693c8',
      viewedBy: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      viewedAt: '2023-11-09T14:19:53.537Z',
      name: 'E2E Automation ',
      email: 'e2e@tailorcare.com',
    },
    {
      id: 'ad7085db-b654-4ee2-85be-c860a358613c',
      viewedBy: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      viewedAt: '2023-11-09T14:19:30.798Z',
      name: 'E2E Automation ',
      email: 'e2e@tailorcare.com',
    },
    {
      id: '416f1117-ebde-4e61-9d8b-7c75cdc0934c',
      viewedBy: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      viewedAt: '2023-11-09T14:19:24.602Z',
      name: 'E2E Automation ',
      email: 'e2e@tailorcare.com',
    },
  ],
  healthAndEquityQuestionsRoundTwoRequired: false,
}

const patientWithoutLockedPathway: SPatientV2 = {
  patientId: 'fa62bcf5-2400-4fb1-a5a2-ddce9512c9ae',
  firstName: 'Laisha',
  middleName: null,
  lastName: 'Sanford',
  dob: '8/29/1976',
  mbi: '8JU4Rv8HX72',
  doNotCall: false,
  referralSource: 'PCP',
  isThreadwell: true,
  eligible: false,
  eligibilityStartDate: '11/01/2023',
  eligibilityEndDate: '11/01/2023',
  eligibilityLastUpdatedAt: '11/01/2023',
  dualCoverage: true,
  language: 'English',
  notes: [
    {
      id: '027806bb-b79f-4e2f-bfcc-fbccdfa355dd',
      author: '0b711404-6c8c-4f55-9889-ca7f5ee55422',
      createdAt: '2024-01-03T18:38:22.60964+00:00',
      note: '<p>test</p>',
      spokeWith: null,
      sdm: false,
    },
    {
      id: 'f9a6a048-e6c6-460c-b4be-97ff0fb675c9',
      author: '0b711404-6c8c-4f55-9889-ca7f5ee55422',
      createdAt: '2023-12-01T16:13:41.349894+00:00',
      note: '<p>Seeded Intake note</p>',
      spokeWith: 'Patient',
      sdm: true,
    },
  ],
  contacts: [
    {
      id: '72249389-2875-4073-b49a-ffee5422dbe3',
      email: 'hkunkel@tailorcare.com',
      phone: null,
    },
  ],
  addressLine1: '310 Dietrich Trace',
  addressLine2: null,
  city: 'Wilson',
  state: 'MD',
  zip: '10643',
  addressId: 'aef6b692-da07-42ba-9c2b-a670a572332d',
  pathways: [
    {
      id: '559a2aa3-49fb-40d6-826e-ca6ed6ba9a52',
      done: false,
      type: 'phone',
      track: 'Resurgens - Surgical',
      pathway: 'Ortho',
      intakeId: '49665e1a-936a-4e4e-a442-7e7a72d3141f',
      isPaused: false,
      lockDate: '12/1/2023',
      createdBy: '0b711404-6c8c-4f55-9889-ca7f5ee55422',
      navigator: 'Harrison Kunkel',
      pathwayId: '46d3f27e-23fa-4d69-b7ba-f430eab74f4f',
      patientId: 'fa62bcf5-2400-4fb1-a5a2-ddce9512c9ae',
      assignedTo: '0b711404-6c8c-4f55-9889-ca7f5ee55422',
      isCancelled: false,
      pathwayCode: 'OR08P',
      pathwayType: 'Physiatry',
      creationDate: '2023-12-01T00:00:00+00:00',
      lastUpdatedAt: '2023-12-01T00:00:00+00:00',
      eventsGenerated: true,
      firstAppointmentDate: '01/11/2024',
    },
  ],
  stageAndStatus: {
    stage: 'Engaged',
    status: 'On Pathway',
  },
  medicalProfile: {
    id: '1185520e-bfc8-4af2-91fe-39775a1a8a5c',
    fax: '2032862049',
    zip: null,
    city: null,
    plan: 'Humana',
    payor: 'Humana',
    phone: null,
    state: null,
    county: null,
    planId: null,
    primary: null,
    planType: null,
    createdAt: '2023-12-01T16:13:35.083048+00:00',
    groupName: 'Welch Inc',
    patientId: 'fa62bcf5-2400-4fb1-a5a2-ddce9512c9ae',
    addressLine1: null,
    addressLine2: null,
    linkedPlanId: null,
    lastUpdatedAt: '2023-12-01T16:13:35.083048+00:00',
    linkedProviderId: 'a45f4461-166a-4f70-81e7-eeb6dc8002a3',
    primaryCarePhysician: 'Brycen Hilll',
    marketId: '3e512034-cb3d-49bc-a4e5-2c8aa93f73ba',
    contractedWith: '3e512034-cb3d-49bc-a4e5-2c8aa93f73ba',
  },
  healthAndEquityQuestionsRoundTwoRequired: true,
  patientViewersHistory: [
    {
      id: 'a04f1a57-dd15-4b39-9185-ae5e5a927d08',
      viewedBy: '0b711404-6c8c-4f55-9889-ca7f5ee55422',
      viewedAt: '2024-01-12T08:34:04.137Z',
      name: 'Harrison Kunkel',
      email: 'hkunkel@tailorcare.com',
    },
  ],
}

export const getPatientWithLockedPathwayServiceResponseMock: GetPatientV2ServiceResponse =
  patientWithLockedPathway

export const getPatientWithoutLockedPathwayServiceResponseMockEmpty: GetPatientV2ServiceResponse =
  patientWithoutLockedPathway
