import {
  SxProps,
  Theme,
  TextField,
  TextFieldProps,
  FormControlProps,
} from '@mui/material'
import { FC } from 'react'
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form'

import {
  FormFieldContainer,
  FormFieldContainerProps,
} from 'src/features/shared/presentation'

type ControlledTextFieldProps = FormFieldContainerProps & {
  name: FieldPath<FieldValues>
  textFieldSx?: SxProps<Theme>
  textFieldProps?: TextFieldProps
}

export const ControlledTextField: FC<ControlledTextFieldProps> = ({
  name,
  required,
  helperText,
  label,
  labelComponent = 'formLabel',
  textFieldProps = {},
  textFieldSx = {},
  formControlProps = {},
  ...formFieldProps
}) => {
  const formMethods = useFormContext()
  const defaultFormControlProps: FormControlProps =
    labelComponent === 'inputLabel' ? { size: 'small' } : {}
  return (
    <Controller
      name={name}
      control={formMethods.control}
      render={({ field, fieldState }) => (
        <FormFieldContainer
          label={labelComponent === 'formLabel' && label ? label : undefined}
          labelComponent={labelComponent}
          required={required}
          helperText={fieldState.error ? fieldState.error.message : helperText}
          error={fieldState.invalid}
          formControlProps={{
            ...defaultFormControlProps,
            ...formControlProps,
          }}
          {...formFieldProps}
        >
          <TextField
            size="small"
            label={labelComponent === 'inputLabel' && label ? label : undefined}
            value={field.value}
            onBlur={field.onBlur}
            onChange={field.onChange}
            required={required}
            error={fieldState.invalid}
            {...textFieldProps}
            sx={textFieldSx}
          />
        </FormFieldContainer>
      )}
    />
  )
}
