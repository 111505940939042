import { useCallback } from 'react'

import {
  CreateProviderGroupService,
  CreateProviderGroupServiceResponse,
  CreateProviderService,
  CreateProviderServiceResponse,
  GetProviderGroupsService,
  GetProviderGroupsServiceResponse,
  GetProvidersService,
  GetProvidersServiceResponse,
  SearchNPPESService,
  SearchNPPESServiceResponse,
} from 'src/features/providers/infrastructure'
import { useProvidersApiClient } from 'src/features/shared/infrastructure'
import * as settings from 'src/config/settings'

export const useProvidersService = () => {
  const { getProvidersApiClient } = useProvidersApiClient()

  const getProviders: GetProvidersService = useCallback(
    async ({ filters } = {}) => {
      const providersClient = await getProvidersApiClient()

      let filterOptions
      if (
        filters &&
        !Object.values(filters).every((filter) =>
          [undefined, ''].includes(filter)
        )
      ) {
        filterOptions = JSON.stringify(filters)
      }

      const params = {
        filterOptions,
      }

      return await providersClient.get<GetProvidersServiceResponse>(
        settings.PROVIDERS_API_BASE_URL + '/providers',
        {
          params,
        }
      )
    },
    [getProvidersApiClient]
  )

  const createProvider: CreateProviderService = useCallback(
    async ({ payload }) => {
      const providersClient = await getProvidersApiClient()
      return await providersClient.post<CreateProviderServiceResponse>(
        settings.PROVIDERS_API_BASE_URL + '/providers',
        payload
      )
    },
    [getProvidersApiClient]
  )

  const getProviderGroups: GetProviderGroupsService = useCallback(async () => {
    const providersClient = await getProvidersApiClient()

    return await providersClient.get<GetProviderGroupsServiceResponse>(
      settings.PROVIDERS_API_BASE_URL + '/providers/groups'
    )
  }, [getProvidersApiClient])

  const createProviderGroup: CreateProviderGroupService = useCallback(
    async ({ payload }) => {
      const providersClient = await getProvidersApiClient()
      return await providersClient.post<CreateProviderGroupServiceResponse>(
        settings.PROVIDERS_API_BASE_URL + '/providers/groups',
        payload
      )
    },
    [getProvidersApiClient]
  )

  const searchNPPES: SearchNPPESService = useCallback(
    async ({ params }) => {
      const providersClient = await getProvidersApiClient()
      return await providersClient.get<SearchNPPESServiceResponse>(
        settings.PROVIDERS_API_BASE_URL + '/providers/nppes',
        { params }
      )
    },
    [getProvidersApiClient]
  )

  return {
    getProviders,
    createProvider,
    getProviderGroups,
    createProviderGroup,
    searchNPPES,
  }
}
