import {
  GetAllTasksByPatientIdServiceResponse,
  GetDoneTasksByPatientIdServiceResponse,
  GetOverdueTasksByPatientIdServiceResponse,
  GetTodayTasksByPatientIdServiceResponse,
  GetUpcomingTasksByPatientIdServiceResponse,
  GetUrgentTasksByPatientIdServiceResponse,
} from 'src/features/tasks/infrastructure'

export const getTasksByPatientIdResponseMock: GetAllTasksByPatientIdServiceResponse =
  {
    tasks: [
      {
        eventId: 'c0956a15-510f-4bb1-b1cf-bbf2a2b24fa9',
        comments: null,
        name: 'Jennifer Lipson',
        patientId: 'fb3479bc-b59a-4c45-9cd0-742bcb508820',
        status: null,
        urgent: false,
        dueDate: '2023-07-04T00:00:00.000Z',
        title: 'Call Reminder',
        description: 'Call Reminder',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
        assignedPathwayId: 'b280750e-4b0b-4cb1-968a-ae7f9bb5ad62',
        createdBy: 'aba1a8b8-f27b-40e8-b2d4-ab8bcccef5f4',
        lastUpdatedAt: '2023-06-20T00:00:00.000Z',
        source: 'system',
        linkedEventId: null,
        isDeleted: false,
        pathwayId: '19983631-218e-449c-b60d-4a19cacc9cf6',
        createdAt: '2023-06-20T13:39:34.958Z',
      },
      {
        eventId: 'b30dc1d0-64d9-4ceb-8721-13bff90e0da5',
        comments: null,
        name: 'Jennifer Lipson',
        patientId: 'fb3479bc-b59a-4c45-9cd0-742bcb508820',
        status: null,
        urgent: false,
        dueDate: '2023-07-31T00:00:00.000Z',
        title: 'Call Reminder',
        description: 'Call Reminder',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
        assignedPathwayId: '2e7c3c24-7f50-4a28-a7f7-7b43ee57ca45',
        createdBy: 'aba1a8b8-f27b-40e8-b2d4-ab8bcccef5f4',
        lastUpdatedAt: '2023-07-26T00:00:00.000Z',
        source: 'system',
        linkedEventId: 'a2932663-ba94-4539-a24e-d6f32e2ecd2d',
        isDeleted: false,
        pathwayId: '51ddf969-d588-4980-9458-cc949fd7485b',
        createdAt: '2023-07-07T01:02:58.182Z',
      },
      {
        eventId: '9c077b4f-9733-446b-9292-b38552fd3b28',
        comments: null,
        name: 'Jennifer Lipson',
        patientId: 'fb3479bc-b59a-4c45-9cd0-742bcb508820',
        status: 'complete',
        urgent: false,
        dueDate: '2023-08-18T00:00:00.000Z',
        title: 'Call Reminder',
        description: 'Call Reminder',
        assignedTCMember: 'E2E Automation ',
        completedAt: '2023-08-29T00:00:00.000Z',
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
        assignedPathwayId: '70dd7e46-c5d8-471d-9b0b-d780627367bb',
        createdBy: 'aba1a8b8-f27b-40e8-b2d4-ab8bcccef5f4',
        lastUpdatedAt: '2023-08-29T00:00:00.000Z',
        source: 'system',
        linkedEventId: null,
        isDeleted: false,
        pathwayId: '51ddf969-d588-4980-9458-cc949fd7485b',
        createdAt: '2023-08-29T13:49:32.539Z',
      },
      {
        eventId: 'bd2d7265-0e69-4ba1-affa-03c46e6440b6',
        comments: null,
        name: 'Jennifer Lipson',
        patientId: 'fb3479bc-b59a-4c45-9cd0-742bcb508820',
        status: 'complete',
        urgent: false,
        dueDate: '2023-09-01T00:00:00.000Z',
        title: 'Call Reminder',
        description: 'Call Reminder',
        assignedTCMember: 'E2E Automation ',
        completedAt: '2023-09-28T00:00:00.000Z',
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
        assignedPathwayId: '70dd7e46-c5d8-471d-9b0b-d780627367bb',
        createdBy: 'aba1a8b8-f27b-40e8-b2d4-ab8bcccef5f4',
        lastUpdatedAt: '2023-09-28T00:00:00.000Z',
        source: 'system',
        linkedEventId: '7eaf5929-0341-4253-ab20-0ea3af7b5c80',
        isDeleted: false,
        pathwayId: '51ddf969-d588-4980-9458-cc949fd7485b',
        createdAt: '2023-08-29T13:49:32.697Z',
      },
      {
        eventId: 'bc3a807f-fbbd-47a6-ac6c-1ecb4c26c111',
        comments: null,
        name: 'Jennifer Lipson',
        patientId: 'fb3479bc-b59a-4c45-9cd0-742bcb508820',
        status: null,
        urgent: false,
        dueDate: '2023-09-10T00:00:00.000Z',
        title: 'Call Reminder',
        description: 'Call Reminder',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
        assignedPathwayId: '2e7c3c24-7f50-4a28-a7f7-7b43ee57ca45',
        createdBy: 'aba1a8b8-f27b-40e8-b2d4-ab8bcccef5f4',
        lastUpdatedAt: '2023-07-31T00:00:00.000Z',
        source: 'system',
        linkedEventId: 'a2932663-ba94-4539-a24e-d6f32e2ecd2d',
        isDeleted: false,
        pathwayId: '51ddf969-d588-4980-9458-cc949fd7485b',
        createdAt: '2023-07-07T01:02:58.060Z',
      },
      {
        eventId: '2be0bab8-52a0-4530-8899-116a6d072244',
        comments: null,
        name: 'Jennifer Lipson',
        patientId: 'fb3479bc-b59a-4c45-9cd0-742bcb508820',
        status: 'new',
        urgent: false,
        dueDate: '2023-10-10T00:00:00.000Z',
        title: 'Call Reminder',
        description: 'Call Reminder',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
        assignedPathwayId: '75d47120-d76b-4c98-8e4d-b147f522ab36',
        createdBy: 'aba1a8b8-f27b-40e8-b2d4-ab8bcccef5f4',
        lastUpdatedAt: '2023-10-03T18:38:17.493Z',
        source: 'system',
        linkedEventId: null,
        isDeleted: false,
        pathwayId: '1415fb60-058d-42ff-bb0b-c87fbf484f74',
        createdAt: '2023-10-03T18:38:17.493Z',
      },
      {
        eventId: '61a2aa72-bb97-405b-85e7-246f41f94852',
        comments: null,
        name: 'Jennifer Lipson',
        patientId: 'fb3479bc-b59a-4c45-9cd0-742bcb508820',
        status: null,
        urgent: false,
        dueDate: '2023-10-22T00:00:00.000Z',
        title: 'Call Reminder',
        description: 'Call Reminder',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
        assignedPathwayId: '2e7c3c24-7f50-4a28-a7f7-7b43ee57ca45',
        createdBy: 'aba1a8b8-f27b-40e8-b2d4-ab8bcccef5f4',
        lastUpdatedAt: '2023-07-31T00:00:00.000Z',
        source: 'system',
        linkedEventId: 'a2932663-ba94-4539-a24e-d6f32e2ecd2d',
        isDeleted: false,
        pathwayId: '51ddf969-d588-4980-9458-cc949fd7485b',
        createdAt: '2023-07-07T01:02:58.068Z',
      },
      {
        eventId: '700e00aa-d189-40e3-ace9-1ca2edc166d9',
        comments: null,
        name: 'Jennifer Lipson',
        patientId: 'fb3479bc-b59a-4c45-9cd0-742bcb508820',
        status: null,
        urgent: false,
        dueDate: '2023-12-03T00:00:00.000Z',
        title: 'Call Reminder',
        description: 'Call Reminder',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
        assignedPathwayId: '2e7c3c24-7f50-4a28-a7f7-7b43ee57ca45',
        createdBy: 'aba1a8b8-f27b-40e8-b2d4-ab8bcccef5f4',
        lastUpdatedAt: '2023-07-31T00:00:00.000Z',
        source: 'system',
        linkedEventId: 'a2932663-ba94-4539-a24e-d6f32e2ecd2d',
        isDeleted: false,
        pathwayId: '51ddf969-d588-4980-9458-cc949fd7485b',
        createdAt: '2023-07-07T01:02:58.079Z',
      },
    ],
  }

export const getUpcomingTasksByPatientIdResponseMock: GetUpcomingTasksByPatientIdServiceResponse =
  {
    upcoming: [
      {
        eventId: '2be0bab8-52a0-4530-8899-116a6d072244',
        comments: null,
        name: 'Jennifer Lipson',
        patientId: 'fb3479bc-b59a-4c45-9cd0-742bcb508820',
        status: 'new',
        urgent: false,
        dueDate: '2023-10-10T00:00:00.000Z',
        title: 'Call Reminder',
        description: 'Call Reminder',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
        assignedPathwayId: '75d47120-d76b-4c98-8e4d-b147f522ab36',
        createdBy: 'aba1a8b8-f27b-40e8-b2d4-ab8bcccef5f4',
        lastUpdatedAt: '2023-10-03T18:38:17.493Z',
        source: 'system',
        linkedEventId: null,
        isDeleted: false,
        pathwayId: '1415fb60-058d-42ff-bb0b-c87fbf484f74',
        createdAt: '2023-10-03T18:38:17.493Z',
      },
    ],
  }

export const getUrgentTasksByPatientIdResponseMock: GetUrgentTasksByPatientIdServiceResponse =
  {
    urgent: [],
  }

export const getOverdueTasksByPatientIdResponseMock: GetOverdueTasksByPatientIdServiceResponse =
  {
    overdue: [],
  }

export const getDoneTasksByPatientIdResponseMock: GetDoneTasksByPatientIdServiceResponse =
  {
    done: [
      {
        eventId: 'bd2d7265-0e69-4ba1-affa-03c46e6440b6',
        comments: null,
        name: 'Jennifer Lipson',
        patientId: 'fb3479bc-b59a-4c45-9cd0-742bcb508820',
        status: 'complete',
        urgent: false,
        dueDate: '2023-09-01T00:00:00.000Z',
        title: 'Call Reminder',
        description: 'Call Reminder',
        assignedTCMember: 'E2E Automation ',
        completedAt: '2023-09-28T00:00:00.000Z',
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
        assignedPathwayId: '70dd7e46-c5d8-471d-9b0b-d780627367bb',
        createdBy: 'aba1a8b8-f27b-40e8-b2d4-ab8bcccef5f4',
        lastUpdatedAt: '2023-09-28T00:00:00.000Z',
        source: 'system',
        linkedEventId: '7eaf5929-0341-4253-ab20-0ea3af7b5c80',
        isDeleted: false,
        pathwayId: '51ddf969-d588-4980-9458-cc949fd7485b',
        createdAt: '2023-08-29T13:49:32.697Z',
      },
      {
        eventId: '9c077b4f-9733-446b-9292-b38552fd3b28',
        comments: null,
        name: 'Jennifer Lipson',
        patientId: 'fb3479bc-b59a-4c45-9cd0-742bcb508820',
        status: 'complete',
        urgent: false,
        dueDate: '2023-08-18T00:00:00.000Z',
        title: 'Call Reminder',
        description: 'Call Reminder',
        assignedTCMember: 'E2E Automation ',
        completedAt: '2023-08-29T00:00:00.000Z',
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
        assignedPathwayId: '70dd7e46-c5d8-471d-9b0b-d780627367bb',
        createdBy: 'aba1a8b8-f27b-40e8-b2d4-ab8bcccef5f4',
        lastUpdatedAt: '2023-08-29T00:00:00.000Z',
        source: 'system',
        linkedEventId: null,
        isDeleted: false,
        pathwayId: '51ddf969-d588-4980-9458-cc949fd7485b',
        createdAt: '2023-08-29T13:49:32.539Z',
      },
    ],
  }

export const getTodayTasksByPatientIdResponseMock: GetTodayTasksByPatientIdServiceResponse =
  {
    today: [],
  }
