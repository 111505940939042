import { useCallback } from 'react'

import {
  CreatePayorService,
  CreatePayorServiceResponse,
  GetPayorsService,
  GetPayorsServiceResponse,
} from 'src/features/providers/infrastructure'

import { useProvidersApiClient } from 'src/features/shared/infrastructure'
import * as settings from 'src/config/settings'

export const usePayorsService = () => {
  const { getProvidersApiClient } = useProvidersApiClient()

  const getPayors: GetPayorsService = useCallback(async () => {
    const providersClient = await getProvidersApiClient()

    return await providersClient.get<GetPayorsServiceResponse>(
      settings.PROVIDERS_API_BASE_URL + '/payors'
    )
  }, [getProvidersApiClient])

  const createPayor: CreatePayorService = useCallback(
    async ({ payload }) => {
      const compassClient = await getProvidersApiClient()
      return await compassClient.post<CreatePayorServiceResponse>(
        settings.PROVIDERS_API_BASE_URL + '/payors',
        payload
      )
    },
    [getProvidersApiClient]
  )

  return {
    getPayors,
    createPayor,
  }
}
