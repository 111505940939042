import { useQuery } from '@tanstack/react-query'

import {
  GetOverdueTasksByPatientIdReturns,
  GetOverdueTasksByPatientIdError,
  GetTasksByPatientIdParams,
} from 'src/features/tasks/domain'
import { useTasksUseCases } from 'src/features/tasks/tasks.service-locator'
import { usePatientId } from 'src/features/shared/presentation'

export const GET_OVERDUE_TASKS_BY_PATIENT_ID_QUERY_KEY =
  'getOverdueTasksByPatientId'

type UseGetOverdueTasksByPatientIdProps = {
  patientId?: string
  throwGetPatientIdError?: boolean
  params?: GetTasksByPatientIdParams
}

export const useGetOverdueTasksByPatientIdQuery = ({
  patientId,
  params,
  throwGetPatientIdError = true,
}: UseGetOverdueTasksByPatientIdProps = {}) => {
  const taskUseCase = useTasksUseCases()

  // TODO: find a better way to get the patient id
  const _patientId = usePatientId({ patientId, throwGetPatientIdError })

  const { data, refetch, isLoading, isError, isFetching, isRefetching } =
    useQuery<
      GetOverdueTasksByPatientIdReturns,
      GetOverdueTasksByPatientIdError
    >([GET_OVERDUE_TASKS_BY_PATIENT_ID_QUERY_KEY, _patientId], () => {
      return _patientId
        ? taskUseCase.getOverdueTasksByPatientId({
            patientId: _patientId,
            params,
          })
        : Promise.reject(
            new Error(
              'Patient ID is required as a parameter or in the url as path param.'
            )
          )
    })

  return {
    patientOverdueTasksData: data,
    patientOverdueTasks: data?.tasks ? data.tasks : [],
    refetchPatientOverdueTasks: refetch,
    patientOverdueTasksIsLoading: isLoading,
    patientOverdueTasksIsError: isError,
    patientOverdueTasksIsFetching: isFetching,
    patientOverdueTasksIsRefetching: isRefetching,
  }
}
