import {
  Backdrop,
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Typography,
} from '@mui/material'
import { FC, useEffect, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'

import {
  ModalDialogTitleContainer,
  WarningModal,
} from 'src/features/shared/presentation'
import { LoadMSKFormReturns } from 'src/features/msk/domain'
import {
  useMSKFormStore,
  useNotesStore,
  usePatientPageStore,
} from 'src/features/shared/infrastructure'
import { PatientV2 } from 'src/features/patients/domain'
import {
  MSKForm,
  MSKNoteField,
  chronicConditionPageNumber as stepTwoChronicConditionPageNumber,
  actionsPageNumber as stepTwoActionsPageNumber,
  ErrorAlert,
} from 'src/features/msk/presentation'
import {
  PatientPageHeaderView,
  PatientGlanceView,
} from 'src/features/patients/presentation'
import { mapToPatientHeaderData } from 'src/features/patients/adapters'
import { useGetPlansQuery } from 'src/features/providers/presentation'

type MSKFormModalProps = {
  patient: PatientV2
  loadedMSKForm: LoadMSKFormReturns
}

export const MSKFormModal: FC<MSKFormModalProps> = ({
  patient,
  loadedMSKForm,
}) => {
  const [showResetWarning, setShowResetWarning] = useState(false)
  const [showSkipWarning, setShowSkipWarning] = useState(false)
  const {
    modalTitle,
    resetMSKForm,
    setSkippedIntakeAssessment,
    currentIntakeAssessmentIdx,
    intakeAssessments,
    step,
    page,
    setPage,
  } = useMSKFormStore({
    loadedMSKForm,
  })
  const { openMSKFormModal, setOpenMSKFormModal } = usePatientPageStore()
  const { setSelectedNoteType, setNoteText } = useNotesStore()
  const { plans } = useGetPlansQuery()

  const currentIntakeAssessment = intakeAssessments.length
    ? intakeAssessments[currentIntakeAssessmentIdx]
    : null

  const showSkipButton =
    step === 2 &&
    page === stepTwoChronicConditionPageNumber &&
    currentIntakeAssessment &&
    !currentIntakeAssessment.skipped

  const handleModalClose = () => {
    setOpenMSKFormModal(false)
    setSelectedNoteType('Intake')
  }

  const handleResetWarningConfirm = () => {
    resetMSKForm(patient.patientId)
    setNoteText('')
    setShowResetWarning(false)
  }

  const handleSkipWarningConfirm = () => {
    setSkippedIntakeAssessment(true)
    setShowSkipWarning(false)
    setPage(stepTwoActionsPageNumber)
  }

  const handleSkipIntakeAssessmentClick = () => {
    setShowSkipWarning(true)
  }

  useEffect(() => {
    return () => {
      setOpenMSKFormModal(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Backdrop
      open={openMSKFormModal}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '32px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Paper
            sx={{
              flex: '0 1 940px',
              height: '150px',
              overflow: 'auto',
            }}
          >
            <PatientPageHeaderView
              patient={mapToPatientHeaderData(patient, plans)}
              disableEdit
            />
            <PatientGlanceView
              patient={mapToPatientHeaderData(patient, plans)}
              disableEdit
            />
          </Paper>
        </Box>

        <Paper
          sx={{
            maxWidth: '75vw',
            minWidth: '75vw',
            height: '75vh',
            overflow: 'auto',
          }}
        >
          <ModalDialogTitleContainer id="msk_form_modal_dialog_title">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant={'h2'}>{modalTitle}</Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '16px',
                }}
              >
                {showSkipButton ? (
                  <Button
                    variant={'contained'}
                    color={'error'}
                    endIcon={<ErrorOutlineOutlinedIcon />}
                    onClick={handleSkipIntakeAssessmentClick}
                  >
                    SKIP INTAKE
                  </Button>
                ) : null}
                <IconButton onClick={handleModalClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </ModalDialogTitleContainer>
          <Box
            id="msk_form_modal_dialog_content"
            sx={{
              padding: '32px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              rowGap: '32px',
            }}
          >
            <MSKForm patient={patient} loadedMSKForm={loadedMSKForm} />
            <Divider
              sx={{
                width: {
                  xs: '50%',
                },
                color: 'gray',
              }}
            />
            <MSKNoteField />
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                variant={'contained'}
                color={'secondary'}
                onClick={() => setShowResetWarning(true)}
              >
                Reset
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
      <WarningModal
        open={showResetWarning}
        message={'Are you sure you want to reset the form?'}
        onConfirm={handleResetWarningConfirm}
        onCancel={() => setShowResetWarning(false)}
        confirmButtonVariant={'outlined'}
        confirmButtonText={'Yes, Reset'}
        cancelButtonVariant={'contained'}
        actionsAlignment={'right'}
      />
      <ErrorAlert
        open={showSkipWarning}
        title={'Skipping the Intake Assessment'}
        dataTestIdPreffix={'msk_form_skip_assessment_warning_'}
        confirmText={'YES, EXIT BUT SAVE ASSESSMENT'}
        ignoreText={'NO, CONTINUE THE ASSESSMENT'}
        onConfirm={handleSkipWarningConfirm}
        onIgnore={() => setShowSkipWarning(false)}
      >
        <Typography sx={{ mt: '16px' }}>
          As a Navigator I feel it is necessary at this time to skip the
          assessment. But I need to save the current information that I have
          entered to come back to at a later date.
        </Typography>
      </ErrorAlert>
    </Backdrop>
  )
}
