import { rest } from 'msw'

import * as settings from 'src/config/settings'
import { getProviderGroupsServiceResponseMockExample } from 'src/__mocks__/responses/get-provider-groups-service-response'
import { GetProviderGroupsServiceResponse } from 'src/features/providers/infrastructure'

const url = `${settings.PROVIDERS_API_BASE_URL}/providers/groups`

export const getProviderGroupsServiceResponseHandler =
  rest.get<GetProviderGroupsServiceResponse>(url, async (_req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(getProviderGroupsServiceResponseMockExample)
    )
  })
