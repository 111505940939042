import {
  UseMutateAsyncFunction,
  UseMutateFunction,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import {
  CreatePatientPainAndFunctionArgs,
  CreatePatientPainAndFunctionError,
  CreatePatientPainAndFunctionReturns,
  PatientV2,
} from 'src/features/patients/domain'
import { usePatientsUseCases } from 'src/features/patients/patients.service-locator'
import {
  GET_PATIENT_QUERY_KEY_V2,
  GET_PATIENT_PAIN_AND_FUNCTION_QUERY_KEY_V2,
} from 'src/features/patients/presentation'
import {
  GET_PATIENT_NOTES_QUERY_KEY,
  PainAndFunctionFieldState,
} from 'src/features/notes/presentation'
import { toast } from 'react-toastify'
import { Intake } from 'src/features/msk/domain'
import { mapToCreatePatientPainAndFunctionArgs } from '../../adapters'

type UseCreatePatientPainAndFunctionProps = Omit<
  UseMutationOptions<
    CreatePatientPainAndFunctionReturns,
    CreatePatientPainAndFunctionError,
    CreatePatientPainAndFunctionArgs,
    unknown
  >,
  'mutationFn'
>

type UseCreatePatientPainAndFunction = (
  props?: UseCreatePatientPainAndFunctionProps
) => {
  createPatientPainAndFunction: UseMutateFunction<
    CreatePatientPainAndFunctionReturns,
    CreatePatientPainAndFunctionError,
    CreatePatientPainAndFunctionArgs,
    unknown
  >
  createPatientPainAndFunctionAsync: UseMutateAsyncFunction<
    CreatePatientPainAndFunctionReturns,
    CreatePatientPainAndFunctionError,
    CreatePatientPainAndFunctionArgs,
    unknown
  >
  createPatientPainAndFunctionIsLoading: boolean
  createPatientPainAndFunctionStatus: string
  createMultiplePatientPainAndFunction: (
    patient: PatientV2,
    intakes: Intake[],
    painAndFunctionFieldState: PainAndFunctionFieldState
  ) => Promise<void>
}

export const useCreatePatientPainAndFunction: UseCreatePatientPainAndFunction =
  (props = {}) => {
    const queryClient = useQueryClient()
    const patientsUseCases = usePatientsUseCases()

    const { onSuccess } = props

    const { mutate, isLoading, status, mutateAsync } = useMutation<
      CreatePatientPainAndFunctionReturns,
      CreatePatientPainAndFunctionError,
      CreatePatientPainAndFunctionArgs,
      unknown
    >({
      mutationFn: (args: CreatePatientPainAndFunctionArgs) => {
        return patientsUseCases.createPatientPainAndFunction(args)
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: [GET_PATIENT_QUERY_KEY_V2, variables.patientId],
        })
        queryClient.invalidateQueries({
          queryKey: [
            GET_PATIENT_PAIN_AND_FUNCTION_QUERY_KEY_V2,
            variables.patientId,
          ],
        })
        queryClient.invalidateQueries({
          queryKey: [GET_PATIENT_NOTES_QUERY_KEY, variables.patientId],
        })
        onSuccess?.(data, variables, context)
      },
    })

    const createMultiplePatientPainAndFunction = async (
      patient: PatientV2,
      intakes: Intake[],
      painAndFunctionFieldState: PainAndFunctionFieldState
    ) => {
      const createPatientPainAndFunctionArgsList =
        mapToCreatePatientPainAndFunctionArgs(
          patient,
          painAndFunctionFieldState
        )

      createPatientPainAndFunctionArgsList.forEach(async (args) => {
        const conditionName = intakes.find(
          (intake) => intake.id === args.payload.intakeId
        )?.botheredBodyPart
        try {
          await mutateAsync(args)
          toast.success(
            `Patient Pain and Function successfully updated for ${conditionName}!`
          )
        } catch (error) {
          toast.error(
            `Patient Pain and Function update for ${conditionName} failed!`,
            {
              autoClose: false,
            }
          )
        }
      })
    }

    return {
      createPatientPainAndFunction: mutate,
      createPatientPainAndFunctionAsync: mutateAsync,
      createPatientPainAndFunctionIsLoading: isLoading,
      createPatientPainAndFunctionStatus: status,
      createMultiplePatientPainAndFunction,
    }
  }
