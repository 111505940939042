import {
  FC,
  PropsWithChildren,
  SyntheticEvent,
  Fragment,
  useState,
  useEffect,
} from 'react'
import {
  Box,
  Stack,
  Tab,
  Tabs,
  Typography,
  IconButton,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material'
import { styled } from '@mui/material/styles'

import AddIcon from '@mui/icons-material/Add'
import RefreshIcon from '@mui/icons-material/Refresh'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import {
  FullSectionLoader,
  RefreshButton,
} from 'src/features/shared/presentation'
import { useGetPatientQueryV2 } from 'src/features/patients/presentation'
import {
  useUserSidebarTasks,
  TasksSidebarToggle,
  TaskComponent,
  TaskAddFormModal,
  TaskEditFormModal,
  AgendaMultipleTasksEditFormModal,
  DayMultipleTasksEditFormModal,
} from 'src/features/tasks/presentation'
import { getRotateIconSx } from 'src/features/shared/utils'
import { DoneTask, Task } from 'src/features/tasks/domain'
import {
  mapToGetDoneTaskGroupedByDate,
  mapToGetUpcomingTaskGroupedByDate,
} from 'src/features/tasks/adapters'
import { TasksState, useTasksStore } from 'src/features/shared/infrastructure'
import { UserTasksCalendarModal } from './user-tasks-calendar-modal'

export type TaskGroupedByDate = Record<string, Task[]>
export type DoneTaskGroupedByDate = Record<string, DoneTask[]>

export const TASKS_SIDEBAR_DESKTOP_WIDTH = 400
export const TASKS_SIDEBAR_HEADER_HEIGHT = '100px'
export const TASKS_SIDEBAR_TABS_HEIGHT = '48px'

const AlertAccordionSummary = styled(AccordionSummary)(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0px 11px',
  backgroundColor: '#D32F2F12',
  color: '#C62828',
}))

const AccordionHeader = styled(Box)(() => ({
  width: '100%',
  padding: '12px 10px 4px 10px',
  backgroundColor: 'rgba(0, 0, 0, 0.08)',
}))

const NoTasksMessage: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography color="rgba(0, 0, 0, 0.38)">{children}</Typography>
    </Box>
  )
}

const TaskSectionContainer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box
      sx={{
        width: '100%',
        marginTop: '40px',
        height: `calc(100vh - 40px - ${TASKS_SIDEBAR_HEADER_HEIGHT} - ${TASKS_SIDEBAR_TABS_HEIGHT})`,
        overflowY: 'scroll',
      }}
    >
      {children}
    </Box>
  )
}

type LoadMoreTasksButtonProps = {
  hasNextPage: boolean
  isFetchingNextPage: boolean
  text: string
  onClick: () => void
}

const LoadMoreTasksButton: FC<LoadMoreTasksButtonProps> = ({
  hasNextPage,
  isFetchingNextPage,
  text,
  onClick,
}) => {
  return hasNextPage || isFetchingNextPage ? (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Button onClick={onClick} disabled={isFetchingNextPage}>
        {isFetchingNextPage ? (
          <RefreshIcon
            sx={{
              ...getRotateIconSx(true),
              color: 'primary.main',
            }}
          />
        ) : (
          text
        )}
      </Button>
    </Box>
  ) : null
}

export const TasksSidebarTodayTasks: FC = () => {
  const {
    todaysTasksGroupedByPatient,
    todaysTasksGroupedByPatientSortedByPatientNamePatientsId,
    userTodayTasksIsLoading,
    infiniteUrgentTasksData,
    infiniteUrgentTasksIsLoading,
    infiniteUrgentTasksIsFetchingNextPage,
    fetchNextPageInfiniteUrgentTasks,
    hasNextPageInfiniteUrgentTasks,
    userOverdueTasksIsLoading,
    overdueTasksGroupedByPatient,
    overdueTasksGroupedByPatientSortedByPatientNamePatientsId,
  } = useUserSidebarTasks()

  const isLoading =
    infiniteUrgentTasksIsLoading ||
    userOverdueTasksIsLoading ||
    userTodayTasksIsLoading

  const hasNoTasksToday =
    !todaysTasksGroupedByPatientSortedByPatientNamePatientsId.length

  const hasUrgentTasks =
    infiniteUrgentTasksData?.pages.length &&
    infiniteUrgentTasksData.pages[0].tasks.length
  const hasOverdueTasksByPatientName = Object.values(
    overdueTasksGroupedByPatient
  ).some((tasks) => tasks.length)

  return (
    <TaskSectionContainer>
      {isLoading ? (
        <FullSectionLoader />
      ) : (
        <Box>
          {hasUrgentTasks ? (
            <Accordion disableGutters defaultExpanded>
              <AlertAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="urgent-tasks-content"
              >
                Urgent
              </AlertAccordionSummary>
              <AccordionDetails>
                <Stack>
                  {infiniteUrgentTasksData.pages.map((page, i) => (
                    <Fragment key={i}>
                      {page.tasks.map((task) => (
                        <TaskComponent key={task.id} task={task} />
                      ))}
                    </Fragment>
                  ))}
                </Stack>
                <LoadMoreTasksButton
                  hasNextPage={!!hasNextPageInfiniteUrgentTasks}
                  isFetchingNextPage={infiniteUrgentTasksIsFetchingNextPage}
                  text="Load More Urgent Tasks"
                  onClick={() => fetchNextPageInfiniteUrgentTasks()}
                />
              </AccordionDetails>
            </Accordion>
          ) : null}

          {hasOverdueTasksByPatientName ? (
            <Accordion disableGutters>
              <AlertAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="overdue-tasks-by-patient-content"
                id="overdue-tasks-by-patient-header"
              >
                Overdue By Patient
              </AlertAccordionSummary>
              <AccordionDetails>
                {overdueTasksGroupedByPatientSortedByPatientNamePatientsId.map(
                  (patientId) => (
                    <Accordion
                      key={`overdue-tasks-by-patient-${patientId}`}
                      disableGutters
                      sx={{
                        boxShadow: 'none',
                        borderBottom: '1px solid #EFEFF0',
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`${patientId}-content`}
                        id={`${patientId}-header`}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: '0px 11px',
                        }}
                      >
                        <>
                          {
                            overdueTasksGroupedByPatient[patientId][0]
                              .patientName
                          }{' '}
                          ({overdueTasksGroupedByPatient[patientId].length})
                          <br />
                          {
                            overdueTasksGroupedByPatient[patientId][0].dueDate
                          } -{' '}
                          {
                            overdueTasksGroupedByPatient[patientId][
                              overdueTasksGroupedByPatient[patientId].length - 1
                            ].dueDate
                          }
                        </>
                      </AccordionSummary>
                      <AccordionDetails>
                        {overdueTasksGroupedByPatient[patientId].map(
                          (task, i) => (
                            <TaskComponent
                              key={task.id}
                              task={task}
                              summaryProperties={['title', 'dueDate']}
                              showBottomBorder={
                                i <
                                overdueTasksGroupedByPatient[patientId].length -
                                  1
                              }
                            />
                          )
                        )}
                      </AccordionDetails>
                    </Accordion>
                  )
                )}
              </AccordionDetails>
            </Accordion>
          ) : null}

          <Accordion disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="tasks-by-patient-content"
              id="tasks-by-patient-header"
            >
              Today's Tasks By Patient
            </AccordionSummary>
            <AccordionDetails>
              {hasNoTasksToday ? (
                <NoTasksMessage>No tasks for today</NoTasksMessage>
              ) : (
                todaysTasksGroupedByPatientSortedByPatientNamePatientsId.map(
                  (patientId) => (
                    <Accordion
                      key={`tasks-by-patient-${patientId}`}
                      disableGutters
                      sx={{
                        boxShadow: 'none',
                        borderBottom: '1px solid #EFEFF0',
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`${patientId}-content`}
                        id={`${patientId}-header`}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: '0px 11px',
                        }}
                      >
                        <>
                          {
                            todaysTasksGroupedByPatient[patientId][0]
                              .patientName
                          }{' '}
                          ({todaysTasksGroupedByPatient[patientId].length})
                        </>
                      </AccordionSummary>
                      <AccordionDetails>
                        {todaysTasksGroupedByPatient[patientId].map(
                          (task, i) => (
                            <TaskComponent
                              key={task.id}
                              task={task}
                              summaryProperties={['title', 'dueDate']}
                              showBottomBorder={
                                i <
                                todaysTasksGroupedByPatient[patientId].length -
                                  1
                              }
                            />
                          )
                        )}
                      </AccordionDetails>
                    </Accordion>
                  )
                )
              )}
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
    </TaskSectionContainer>
  )
}

export const TasksSidebarDoneTasks: FC = () => {
  const {
    infiniteDoneTasksData,
    infiniteDoneTasksIsLoading,
    infiniteDoneTasksIsFetchingNextPage,
    fetchNextPageInfiniteDoneTasks,
    hasNextPageInfiniteDoneTasks,
  } = useUserSidebarTasks()
  const [groupedTasks, setGroupedTasks] = useState<DoneTaskGroupedByDate>({})

  const hasDoneTasks = Object.keys(groupedTasks).length

  useEffect(() => {
    if (infiniteDoneTasksData) {
      const groupedTasks = mapToGetDoneTaskGroupedByDate(infiniteDoneTasksData)
      setGroupedTasks(groupedTasks)
    }
  }, [infiniteDoneTasksData])
  return (
    <TaskSectionContainer>
      {infiniteDoneTasksIsLoading ? (
        <FullSectionLoader />
      ) : (
        <Box>
          {hasDoneTasks ? (
            Object.keys(groupedTasks).map((completedDate) => (
              <Box key={completedDate}>
                <AccordionHeader>{completedDate}</AccordionHeader>
                <Stack>
                  {groupedTasks[completedDate].map((task) => (
                    <TaskComponent key={task.id} task={task} edit={false} />
                  ))}
                </Stack>
              </Box>
            ))
          ) : (
            <NoTasksMessage>No tasks done</NoTasksMessage>
          )}
          <LoadMoreTasksButton
            hasNextPage={!!hasNextPageInfiniteDoneTasks}
            isFetchingNextPage={infiniteDoneTasksIsFetchingNextPage}
            text="Load More Done Tasks"
            onClick={() => fetchNextPageInfiniteDoneTasks()}
          />
        </Box>
      )}
    </TaskSectionContainer>
  )
}

export const TasksSidebarUpcomingTasks: FC = () => {
  const {
    infiniteUpcomingTasksData,
    infiniteUpcomingTasksIsLoading,
    infiniteUpcomingTasksIsFetchingNextPage,
    fetchNextPageInfiniteUpcomingTasks,
    hasNextPageInfiniteUpcomingTasks,
  } = useUserSidebarTasks()
  const [groupedTasks, setGroupedTasks] = useState<TaskGroupedByDate>({})

  const hasUpcomingTasks = Object.keys(groupedTasks).length

  useEffect(() => {
    if (infiniteUpcomingTasksData) {
      const groupedTasks = mapToGetUpcomingTaskGroupedByDate(
        infiniteUpcomingTasksData
      )
      setGroupedTasks(groupedTasks)
    }
  }, [infiniteUpcomingTasksData])
  return (
    <TaskSectionContainer>
      {infiniteUpcomingTasksIsLoading ? (
        <FullSectionLoader />
      ) : (
        <Box>
          {hasUpcomingTasks ? (
            Object.keys(groupedTasks).map((completedDate) => (
              <Box key={completedDate}>
                <AccordionHeader>{completedDate}</AccordionHeader>
                <Stack>
                  {groupedTasks[completedDate].map((task) => (
                    <TaskComponent key={task.id} task={task} edit={false} />
                  ))}
                </Stack>
              </Box>
            ))
          ) : (
            <NoTasksMessage>No upcoming tasks</NoTasksMessage>
          )}
          <LoadMoreTasksButton
            hasNextPage={!!hasNextPageInfiniteUpcomingTasks}
            isFetchingNextPage={infiniteUpcomingTasksIsFetchingNextPage}
            text="Load More Upcoming Tasks"
            onClick={() => fetchNextPageInfiniteUpcomingTasks()}
          />
        </Box>
      )}
    </TaskSectionContainer>
  )
}

export const TasksSidebar: FC = () => {
  const { patient } = useGetPatientQueryV2({ throwGetPatientIdError: false })
  const {
    sidebarOpen,
    selectedSidebarTab,
    setSelectedSidebarTab,
    setAddTaskFormModalOpen,
  } = useTasksStore()
  const { refreshUserSidebarTasks, isRefreshingUserTasks } =
    useUserSidebarTasks()
  const { setUserOpenTasksCalendarModal } = useTasksStore()

  const handleRefreshTasksClick = () => {
    if (!isRefreshingUserTasks) {
      refreshUserSidebarTasks()
    }
  }

  const handleChange = (
    _event: SyntheticEvent,
    newSelectedTab: TasksState['selectedSidebarTab']
  ) => {
    setSelectedSidebarTab(newSelectedTab)
  }

  const handleAddTaskClick = () => {
    setAddTaskFormModalOpen(true)
  }

  const handleCalendarButtonClick = () => {
    setUserOpenTasksCalendarModal(true)
  }

  return (
    <Box
      sx={{
        backgroundColor: 'common.white',
        border: '1px solid #ccc',
        height: '100vh',
        position: 'fixed',
        right: 0,
        top: 0,
        transform: {
          xs: sidebarOpen
            ? 'translateX(0)'
            : `translateX(calc(${TASKS_SIDEBAR_DESKTOP_WIDTH}px - 11px))`,
        },
        transitionDuration: '0.5s',
        transitionProperty: 'transform',
        width: { xs: TASKS_SIDEBAR_DESKTOP_WIDTH },
        zIndex: 1,
      }}
    >
      <TasksSidebarToggle />
      <Box
        sx={{
          width: '100%',
          height: '100px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '40px',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            component={'h3'}
            sx={{ fontSize: '21px', textAlign: 'center' }}
          >
            Tasks
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            {patient ? (
              <IconButton onClick={handleAddTaskClick}>
                <AddIcon />
              </IconButton>
            ) : null}

            <Box sx={{ position: 'relative' }}>
              <RefreshButton
                onClick={handleRefreshTasksClick}
                isRefreshing={isRefreshingUserTasks}
              />
            </Box>

            <Box>
              <IconButton onClick={handleCalendarButtonClick}>
                <CalendarMonthIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ width: '100%' }}>
        <Tabs
          value={selectedSidebarTab}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          centered
          sx={{ height: TASKS_SIDEBAR_TABS_HEIGHT }}
        >
          <Tab label="UPCOMING" value={'upcoming'} />
          <Tab label="TODAY" value={'today'} />
          <Tab label="DONE" value={'done'} />
        </Tabs>
        {selectedSidebarTab === 'upcoming' ? (
          <TasksSidebarUpcomingTasks />
        ) : null}
        {selectedSidebarTab === 'today' ? <TasksSidebarTodayTasks /> : null}
        {selectedSidebarTab === 'done' ? <TasksSidebarDoneTasks /> : null}
      </Box>
      <TaskEditFormModal />
      <AgendaMultipleTasksEditFormModal />
      <DayMultipleTasksEditFormModal />
      {patient ? <TaskAddFormModal /> : null}
      <UserTasksCalendarModal />
    </Box>
  )
}
