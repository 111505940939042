import axios from 'axios'

import { MSKUseCases } from 'src/features/msk/domain'
import { usePatientIntakeFlowsService } from 'src/features/msk/infrastructure'
import {
  mapToCreateEnablingTherapyReturns,
  mapToCreateEnablingTherapyServiceArgs,
  mapToGetPatientIntakeFlowsError,
  mapToGetPatientIntakeFlowsReturns,
  mapToGetPatientIntakeFlowsServiceParams,
  mapToUpdateIntakeError,
  mapToUpdateIntakeReturns,
  mapToUpdateIntakeServiceArgs,
} from 'src/features/msk/adapters'
import { useAxiosErrorHandling } from 'src/features/shared/infrastructure'

export const usePatientIntakeFlowsServiceAdapter = () => {
  const patientIntakeFlowsService = usePatientIntakeFlowsService()
  const { handleAxiosError } = useAxiosErrorHandling()

  const getPatientIntakeFlows: MSKUseCases['getPatientIntakeFlows'] = async (
    patientId,
    params
  ) => {
    try {
      const serviceParams = mapToGetPatientIntakeFlowsServiceParams(params)

      const response = await patientIntakeFlowsService.getPatientIntakeFlows(
        patientId,
        serviceParams
      )

      return mapToGetPatientIntakeFlowsReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToGetPatientIntakeFlowsError)
      }
      throw error
    }
  }

  const updateIntake: MSKUseCases['updateIntake'] = async (args) => {
    try {
      const serviceArgs = mapToUpdateIntakeServiceArgs(args)

      const response = await patientIntakeFlowsService.updateIntake(serviceArgs)

      return mapToUpdateIntakeReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToUpdateIntakeError)
      }
      throw error
    }
  }

  const createEnablingTherapy: MSKUseCases['createEnablingTherapy'] = async (
    args
  ) => {
    try {
      const serviceArgs = mapToCreateEnablingTherapyServiceArgs(args)

      const response = await patientIntakeFlowsService.createEnablingTherapy(
        serviceArgs
      )

      return mapToCreateEnablingTherapyReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToUpdateIntakeError)
      }
      throw error
    }
  }

  return {
    getPatientIntakeFlows,
    updateIntake,
    createEnablingTherapy,
  }
}
