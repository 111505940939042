import React from 'react'
import { TaskCalendarEvent } from './tc-calendar' // Adjust the import path as needed

interface AgendaDateHeaderProps {
  label?: string
  events: TaskCalendarEvent[]
}

export const AgendaDateHeader: React.FC<AgendaDateHeaderProps> = ({
  label,
  events,
}) => {
  const fullDateString = `${label} ${new Date().getFullYear()}`
  const fullDate: Date = new Date(fullDateString)

  const taskPerDay = events.reduce((acc, event) => {
    const date = event.start ? new Date(event.start) : new Date()
    if (date.toDateString() === fullDate.toDateString()) {
      acc++
    }
    return acc
  }, 0)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <span>{label}</span>
      <span
        style={{
          backgroundColor: '#f0f0f0',
          padding: '2px 6px',
          borderRadius: '10px',
        }}
      >
        tasks: {taskPerDay}
      </span>
    </div>
  )
}
