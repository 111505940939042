import { RootState } from 'src/features/shared/infrastructure'

export const mskFormPatientIdSelector = (state: RootState) =>
  state.mskForm.mskFormPatientId

export const mskFormModalTitleSelector = (state: RootState) =>
  state.mskForm.modalTitle

export const mskFormStepSelector = (state: RootState) => state.mskForm.step

export const mskFormPageSelector = (state: RootState) => state.mskForm.page

export const mskFormErrorsSelector = (state: RootState) => state.mskForm.errors

export const mskFormSelectedBotheredBodyPartsSelector = (state: RootState) =>
  state.mskForm.selectedBotheredBodyParts

export const mskFormSelectorDidYouHaveARecentInjurySelector = (
  state: RootState
) => state.mskForm.didYouHaveARecentInjury

export const mskFormSelectorDidYouSeeAPhysicianSelector = (state: RootState) =>
  state.mskForm.didYouSeeAPhysician

export const mskFormProfileHeightFtSelector = (state: RootState) =>
  state.mskForm.profileHeightFt

export const mskFormProfileHeightInSelector = (state: RootState) =>
  state.mskForm.profileHeightIn

export const mskFormProfileWeightSelector = (state: RootState) =>
  state.mskForm.profileWeight

export const mskFormIntakeAssessmentsSelector = (state: RootState) =>
  state.mskForm.intakeAssessments

export const mskFormCurrentConditionSelector = (state: RootState) =>
  state.mskForm.currentCondition

export const mskFormCurrentIntakeAssessmentIdxSelector = (state: RootState) =>
  state.mskForm.currentIntakeAssessmentIdx

export const mskFormShowStepTwoFullSummarySelector = (state: RootState) =>
  state.mskForm.showStepTwoFullSummary

export const mskFormShowStepThreeFullSummarySelector = (state: RootState) =>
  state.mskForm.showStepThreeFullSummary

export const mskFormShowFullSummarySelector = (state: RootState) =>
  state.mskForm.showFullSummary

export const mskFormShowHardstopWarningSelector = (state: RootState) =>
  state.mskForm.showHardstopWarning

export const mskFormCurrentPathQuestionSelector = (state: RootState) =>
  state.mskForm.currentPathQuestion

export const mskFormCurrentPathQuestionAnswerSelector = (state: RootState) =>
  state.mskForm.currentPathQuestionAnswer

export const mskFormPathQuestionsNextSelector = (state: RootState) =>
  state.mskForm.pathQuestionsNext

export const mskFormIsCurrentPathQuestionValidatedSelector = (
  state: RootState
) => state.mskForm.isCurrentPathQuestionValidated

export const mskFormAdditionalQuestionsSelector = (state: RootState) =>
  state.mskForm.additionalQuestions
