import { ReactQuillProps, Quill } from 'react-quill'

export const reactQuillLinkExternalClass = 'link--external'

export const reactQuillLinkExternalClickEventListener = (e: MouseEvent) => {
  const target = e.target as HTMLElement
  if (
    target.tagName === 'A' &&
    target.classList.contains(reactQuillLinkExternalClass)
  ) {
    e.preventDefault()
    const href = target.getAttribute('href')
    if (href && href !== '#') {
      window.open(href, '_blank')
    }
  }
}

export class ReactQuillExternalLink extends Quill.import('formats/link') {
  static create(value: string) {
    const node = super.create(value)

    node.className = reactQuillLinkExternalClass

    return node
  }
}

export const reactQuillDefaultModules: ReactQuillProps['modules'] = {
  toolbar: [
    'bold',
    'italic',
    'underline',
    'strike',
    'link',
    { list: 'ordered' },
    { list: 'bullet' },
    { indent: '-1' },
    { indent: '+1' },
    { color: [] },
    { background: [] },
  ],
}
