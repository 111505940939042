import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { DevTool } from '@hookform/devtools'
import { Grid } from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useEffect } from 'react'

import {
  FormLayoutWithCancelSave,
  ControlledNoteField,
} from 'src/features/shared/presentation/'
import {
  NoteFormProps,
  useCreatePatientOrthoChartCheckNote,
  OrthoChartCheckAdditionalQuestionsForm,
  OrthoChartCheckQuestionsFormValues,
} from 'src/features/notes/presentation'
import {
  mapToCreatePatientOrthoChartCheckNoteArgs,
  mapToUpdateDraftNoteArgs,
} from 'src/features/notes/adapters'
import { useNotesStore } from 'src/features/shared/infrastructure'
import { useUpdateDraftNote } from 'src/features/notes/presentation'

const isOutcomeOfVisitRequired = (completedAppointment?: string) =>
  completedAppointment === 'Yes'

const isReasonForMissingAppointmentRequired = (completedAppointment?: string) =>
  completedAppointment === 'No'

const isDetailsOfMissedAppointmentRequired = (completedAppointment?: string) =>
  completedAppointment === 'No'

const isAppointmentRescheduledRequired = (completedAppointment?: string) =>
  completedAppointment === 'No'

const isTypeOfInjectionRequired = (outcomeOfVisit?: string) =>
  outcomeOfVisit === 'Injection'

const isTypeOfInjectionTextRequired = (typeOfInjection?: string) =>
  typeOfInjection === 'Other'

export type OrthoChartCheckNoteFormFields =
  OrthoChartCheckQuestionsFormValues & {
    note: string
    communication?: string
  }

type OrthoChartCheckNoteFormProps = NoteFormProps

export const OrthoChartCheckNoteForm = ({
  patient,
  setShowLoader,
  draftNote,
}: OrthoChartCheckNoteFormProps) => {
  const { createPatientOrthoChartCheckNoteAsync } =
    useCreatePatientOrthoChartCheckNote()
  const { setSelectedNoteType } = useNotesStore()
  const { updateDraftNoteAsync } = useUpdateDraftNote()

  const createOrthoChartCheckNoteFormSchema: yup.Schema<OrthoChartCheckNoteFormFields> =
    yup.object().shape({
      note: yup.string().required('Required'),
      completedAppointment: yup.string().required('Required'),
      reasonForMissingAppointment: yup.string().when('completedAppointment', {
        is: isReasonForMissingAppointmentRequired,
        then: (schema) => schema.required('Required'),
      }),
      detailsOfMissedAppointment: yup.string().when('completedAppointment', {
        is: isDetailsOfMissedAppointmentRequired,
        then: (schema) => schema.required('Required'),
      }),
      appointmentRescheduled: yup.string().when('completedAppointment', {
        is: isAppointmentRescheduledRequired,
        then: (schema) => schema.required('Required'),
      }),
      outcomeOfVisit: yup.string().when('completedAppointment', {
        is: isOutcomeOfVisitRequired,
        then: (schema) => schema.required('Required'),
      }),
      typeOfInjection: yup.string().when('outcomeOfVisit', {
        is: isTypeOfInjectionRequired,
        then: (schema) => schema.required('Required'),
      }),
      typeOfInjectionText: yup.string().when('typeOfInjection', {
        is: isTypeOfInjectionTextRequired,
        then: (schema) => schema.required('Required'),
      }),
      outcomeNote: yup.string(),
      appointmentDate: yup.string().nullable(),
      surgeryDate: yup.string().nullable(),
    })

  const formMethods = useForm<OrthoChartCheckNoteFormFields>({
    resolver: yupResolver(createOrthoChartCheckNoteFormSchema),
    defaultValues: {
      note: '',
      completedAppointment: '',
      reasonForMissingAppointment: '',
      detailsOfMissedAppointment: '',
      appointmentRescheduled: '',
      outcomeOfVisit: '',
      typeOfInjection: '',
      typeOfInjectionText: '',
      outcomeNote: '',
      appointmentDate: null,
      surgeryDate: null,
    },
    mode: 'onBlur',
  })

  const completedAppointmentWatcher = formMethods.watch('completedAppointment')
  const outcomeOfVisitWatcher = formMethods.watch('outcomeOfVisit')
  const typeOfInjectionWatcher = formMethods.watch('typeOfInjection')

  const submitHandler: SubmitHandler<OrthoChartCheckNoteFormFields> = async (
    data
  ) => {
    setShowLoader?.(true)
    const createPatientOrthoChartCheckNoteArgs =
      mapToCreatePatientOrthoChartCheckNoteArgs(patient, data, draftNote.id)

    try {
      await createPatientOrthoChartCheckNoteAsync(
        createPatientOrthoChartCheckNoteArgs
      )
      setSelectedNoteType('')
      setShowLoader?.(false)
      toast.success('Patient Note successfully created!')
    } catch (error) {
      toast.error('Failed creating patient note!')
      toast.error('Patient Status update failed!')
    }
  }

  const saveDraftHandler: SubmitHandler<OrthoChartCheckNoteFormFields> = async (
    data
  ) => {
    setShowLoader?.(true)

    const updateDraftNoteArgs = mapToUpdateDraftNoteArgs(
      patient,
      draftNote.id,
      {
        ...data,
        type: 'Ortho Chart Check',
      }
    )

    try {
      await updateDraftNoteAsync(updateDraftNoteArgs)
      toast.success('Patient Note Draft successfully Saved!')
    } catch (error) {
      toast.error('Failed Saving Patient Note Draft!', { autoClose: false })
    }

    setShowLoader?.(false)
  }

  const handleSaveDraft = (data: OrthoChartCheckNoteFormFields) => {
    saveDraftHandler(data)
  }

  useEffect(() => {
    const subscription = formMethods.watch((value, { name, type }) => {
      if (
        name === 'outcomeOfVisit' &&
        type === 'change' &&
        value['outcomeNote']
      ) {
        formMethods.reset((formValues) => ({
          ...formValues,
          outcomeNote: '',
        }))
      }

      if (
        name === 'outcomeOfVisit' &&
        type === 'change' &&
        value['typeOfInjection']
      ) {
        formMethods.reset((formValues) => ({
          ...formValues,
          typeOfInjection: '',
        }))
      }

      if (
        name === 'typeOfInjection' &&
        type === 'change' &&
        value['typeOfInjectionText']
      ) {
        formMethods.reset((formValues) => ({
          ...formValues,
          typeOfInjectionText: '',
        }))
      }

      if (
        name === 'completedAppointment' &&
        type === 'change' &&
        (value['reasonForMissingAppointment'] ||
          value['detailsOfMissedAppointment'] ||
          value['appointmentRescheduled'])
      ) {
        formMethods.reset((formValues) => ({
          ...formValues,
          reasonForMissingAppointment: '',
          detailsOfMissedAppointment: '',
          appointmentRescheduled: '',
        }))
      }
    })

    return () => subscription.unsubscribe()
  }, [formMethods])

  return (
    <FormLayoutWithCancelSave
      onSubmit={submitHandler}
      onSaveDraftClick={handleSaveDraft}
      formMethods={formMethods}
      renderCommunicationField={false}
      draftNote={draftNote}
    >
      <Grid item container sm={12} pb={'16px'}>
        <ControlledNoteField />
      </Grid>

      <OrthoChartCheckAdditionalQuestionsForm
        outcomeOfVisitRequired={isOutcomeOfVisitRequired(
          completedAppointmentWatcher
        )}
        reasonForMissingAppointmentRequired={isReasonForMissingAppointmentRequired(
          completedAppointmentWatcher
        )}
        detailsOfMissedAppointmentRequired={isDetailsOfMissedAppointmentRequired(
          completedAppointmentWatcher
        )}
        appointmentRescheduledRequired={isAppointmentRescheduledRequired(
          completedAppointmentWatcher
        )}
        typeOfInjectionRequired={isTypeOfInjectionRequired(
          outcomeOfVisitWatcher
        )}
        typeOfInjectionTextRequired={isTypeOfInjectionTextRequired(
          typeOfInjectionWatcher
        )}
        completedAppointmentRequired
      />
      <DevTool control={formMethods.control} placement={'top-left'} />
    </FormLayoutWithCancelSave>
  )
}
