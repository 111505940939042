import { UseCaseDefaultError } from 'src/features/shared/domain'
import { DetailedServiceErrorResponse } from 'src/features/shared/infrastructure'

export const mapToUseCaseDefaultError = <
  TDetailedServiceErrorResponse extends DetailedServiceErrorResponse
>(
  error: TDetailedServiceErrorResponse
): UseCaseDefaultError => {
  return {
    message: error.message.details.map((detail) => detail.message).join(', '),
  }
}
