import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import { DevTool } from '@hookform/devtools'
import { toast } from 'react-toastify'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'

import {
  FormLayoutWithCancelSave,
  ControlledNoteField,
  ControlledSelectField,
  useStatusDefinitions,
  PainAndFunctionField,
  StatusField,
} from 'src/features/shared/presentation'

import {
  getPatientHasStage,
  getReferralSources,
} from 'src/features/shared/utils'
import {
  NOTE_FIELD_LABEL,
  NoteFormProps,
  useCreatePatientCustomNote,
  usePainAndFunctionField,
} from 'src/features/notes/presentation'
import {
  mapToCreatePatientCustomNoteV2Args,
  mapToUpdateDraftNoteArgs,
} from 'src/features/notes/adapters'
import { mapToControlledSelectItem } from 'src/features/shared/adapters'
import {
  useCreatePatientPainAndFunction,
  useUpdatePatient,
  useUpdatePatientStatus,
} from 'src/features/patients/presentation'
import { mapToPatientUpdateStatus } from 'src/features/patients/adapters'
import { useNotesStore } from 'src/features/shared/infrastructure'
import { useGetPatientIntakeFlowsQuery } from 'src/features/msk/presentation'
import { NoteData } from 'src/features/notes/domain'
import { useUpdateDraftNote } from 'src/features/notes/presentation'

const isStatusRequired = (patientHasStage: boolean) => patientHasStage

const isReasonForRefusalRequired = (status?: string) => status === 'Refused'

export type CustomAdditionalQuestionsFormV2Values = Pick<
  NoteData,
  'reasonForRefusal'
>

export type CreatePatientCustomNoteFormV2Fields =
  CustomAdditionalQuestionsFormV2Values & {
    note: string
    status?: string
    referralSource?: string
    communication?: string
  }

type CustomNoteFormV2Props = NoteFormProps

export const CustomNoteFormV2 = ({
  patient,
  setShowLoader,
  draftNote,
}: CustomNoteFormV2Props) => {
  const { updatePatientAsync } = useUpdatePatient()
  const patientHasStage = getPatientHasStage(patient)
  const { createPatientCustomNoteAsync } = useCreatePatientCustomNote()
  const { updatePatientStatusAsync } = useUpdatePatientStatus()
  const { updateDraftNoteAsync } = useUpdateDraftNote()

  const { intakes } = useGetPatientIntakeFlowsQuery()
  const painAndFunctionField = usePainAndFunctionField({
    intakes,
    patientId: patient.patientId,
    noteType: 'Custom',
    draftNotePainAndFunction: draftNote.painAndFunction,
  })
  const { createMultiplePatientPainAndFunction } =
    useCreatePatientPainAndFunction()

  const { getStatusInitialValue, getAllStatusDefinitions } =
    useStatusDefinitions(patient)
  const { setSelectedNoteType } = useNotesStore()

  const createCustomNoteFormV2Schema: yup.Schema<CreatePatientCustomNoteFormV2Fields> =
    yup.object().shape({
      note: yup.string().required('Required'),
      status: yup.string().when('$patientHasStage', {
        is: isStatusRequired,
        then: (schema) => schema.required('Required'),
      }),
      reasonForRefusal: yup.string().when('status', {
        is: isReasonForRefusalRequired,
        then: (schema) => schema.required('Required'),
      }),
      referralSource: yup.string(),
      communication: yup.string(),
    })

  const formMethods = useForm<CreatePatientCustomNoteFormV2Fields>({
    resolver: yupResolver(createCustomNoteFormV2Schema),
    context: { patientHasStage },
    defaultValues: {
      note: '',
      status: getStatusInitialValue(),
      reasonForRefusal: '',
      referralSource: patient.referralSource || '',
      communication: '',
    },
    mode: 'onBlur',
  })

  const referralSourceWatcher = formMethods.watch().referralSource
  const statusWatcher = formMethods.watch('status')

  // TODO: This submission should be in a single request and the backend should use a transaction
  const submitHandler: SubmitHandler<
    CreatePatientCustomNoteFormV2Fields
  > = async (data) => {
    setShowLoader?.(true)
    const painAndFunctionFieldHasAtLeastOneActive =
      painAndFunctionField.hasAtLeastOneActive
    const painAndFunctionFieldState = structuredClone(
      painAndFunctionField.state
    )

    const createPatientCustomNoteArgs = mapToCreatePatientCustomNoteV2Args(
      patient,
      data,
      draftNote.id
    )
    painAndFunctionField.reset()
    try {
      await createPatientCustomNoteAsync(createPatientCustomNoteArgs)
      toast.success('Patient Note successfully created!')

      if (
        patient.stageAndStatus &&
        patient.stageAndStatus.stage &&
        data.status
      ) {
        const createPatientUpdateStatusArgs = mapToPatientUpdateStatus(
          patient,
          data.status
        )
        try {
          await updatePatientStatusAsync(createPatientUpdateStatusArgs)
          toast.success('Patient Status successfully updated!')
        } catch (error) {
          toast.error('Patient Status update failed!', { autoClose: false })
        }
      }

      if (data.referralSource) {
        try {
          await updatePatientAsync({
            patientId: patient.patientId,
            payload: {
              referralSource: data.referralSource,
            },
          })
          toast.success('Patient referral source successfully updated!')
        } catch (error) {
          toast.error('Patient referral source update failed!')
        }
      }

      if (painAndFunctionFieldHasAtLeastOneActive) {
        await createMultiplePatientPainAndFunction(
          patient,
          intakes,
          painAndFunctionFieldState
        )
      }
    } catch (error) {
      toast.error('Failed creating patient note!', { autoClose: false })
    }

    setSelectedNoteType('')
    setShowLoader?.(false)
  }

  const saveDraftHandler: SubmitHandler<
    CreatePatientCustomNoteFormV2Fields
  > = async (data) => {
    setShowLoader?.(true)

    const painAndFunctionFieldState = structuredClone(
      painAndFunctionField.state
    )

    const updatePatientCustomNoteArgs = mapToUpdateDraftNoteArgs(
      patient,
      draftNote.id,
      {
        ...data,
        type: 'Custom',
        painAndFunction: painAndFunctionFieldState.values,
      }
    )

    try {
      await updateDraftNoteAsync(updatePatientCustomNoteArgs)
      toast.success('Patient Note Draft successfully Saved!')
    } catch (error) {
      toast.error('Failed Saving Patient Note Draft!', { autoClose: false })
    }

    setShowLoader?.(false)
  }

  const handleSubmit = async (data: CreatePatientCustomNoteFormV2Fields) => {
    if (
      painAndFunctionField.hasAtLeastOneActive &&
      painAndFunctionField.hasErrors
    ) {
      formMethods.trigger()
      return
    }
    await formMethods.trigger()

    submitHandler(data)
  }

  const handleSaveDraft = (data: CreatePatientCustomNoteFormV2Fields) => {
    saveDraftHandler(data)
  }

  return (
    <FormLayoutWithCancelSave
      onSubmit={handleSubmit}
      onSaveDraftClick={handleSaveDraft}
      formMethods={formMethods}
      inboundOutboundType="checkbox"
      draftNote={draftNote}
    >
      <Grid container rowSpacing={2} pb={'32px'}>
        <Grid item sm={12} pb={'16px'}>
          <ControlledNoteField />
        </Grid>

        {intakes.length ? (
          <Grid item sm={12}>
            <PainAndFunctionField
              intakes={intakes}
              fieldMethods={painAndFunctionField}
            />
          </Grid>
        ) : null}

        <Grid item container columnSpacing={2} sm={12}>
          <Grid item sm={6}>
            <StatusField
              statusRequired={isStatusRequired(patientHasStage)}
              reasonForRefusalRequired={isReasonForRefusalRequired(
                statusWatcher
              )}
              statuses={getAllStatusDefinitions(referralSourceWatcher)}
            />
          </Grid>
        </Grid>

        <Grid item container columnSpacing={2}>
          <Grid item sm={4.5}>
            <ControlledSelectField
              name={'referralSource'}
              label={NOTE_FIELD_LABEL.referralSource}
              labelComponent={'inputLabel'}
              items={getReferralSources(patient.referralSource).map(
                mapToControlledSelectItem
              )}
              formControlProps={{
                size: 'small',
              }}
              formControlSx={{
                width: '100%',
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <DevTool control={formMethods.control} placement={'top-left'} />
    </FormLayoutWithCancelSave>
  )
}
