import {
  Typography,
  Box,
  Stack,
  SxProps,
  Theme,
  TypographyProps,
  Tooltip,
  IconButton,
} from '@mui/material'
import { FC } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { PatientMedicalProfile } from 'src/features/patients/domain'
import { DNC_TOOLTIP_TEXT } from 'src/features/shared/constants'
import {
  GroupMedicareChip,
  PatientHeaderChip,
  TCCopyToClipboard,
  ThreadwellChip,
  useTooltipsState,
} from 'src/features/shared/presentation'
import { WithChildren } from 'src/features/shared/types'
import {
  useGetMarketsQuery,
  useGetPartnersQuery,
  useGetPayorsQuery,
  useGetPlansQuery,
} from 'src/features/providers/presentation'
import { useGetPatientIntakeFlowsQuery } from 'src/features/msk/presentation'

export type PatientGlanceData = {
  id: string
  name: string
  notEnglishSpeaker: boolean
  hasPhone: boolean
  hasPCPInfo: boolean
  hasPlanInfo: boolean
  isTraumaPatient: boolean
  language?: string | null
  mbi?: string | null
  dob?: string | null
  mrn?: string
  phone1?: string
  phone2?: string
  doNotCall?: boolean
  dualCoverage?: boolean
  planType: string | null
  referralSource?: string
  isThreadwell: boolean
  stageAndStatus?: string | null
  medicalProfile?: PatientMedicalProfile | null
  planName?: string
  eligible: boolean
  eligibilityStartDate?: string | null
  eligibilityEndDate?: string | null
  eligibilityLastUpdatedAt?: string | null
}

type PatientGlanceAttrKeyProps = Omit<TypographyProps, 'variant'> & {
  typographyVariant?: TypographyProps['variant']
  variant?: 'normal' | 'warning'
}

const PatientGlanceAttrKey: FC<WithChildren<PatientGlanceAttrKeyProps>> = ({
  sx,
  children,
  typographyVariant,
  variant = 'normal',
  ...restProps
}) => {
  const _sx: SxProps<Theme> = {
    color: variant === 'warning' ? 'warning.main' : 'rgba(0, 0, 0, 0.38)',
    fontSize: '14px',
    letterSpacing: '0.17px',
    ...sx,
  }
  return (
    <Typography variant={typographyVariant} sx={_sx} {...restProps}>
      {children}
    </Typography>
  )
}

type PatientGlanceViewProps = {
  patient: PatientGlanceData
  onEditClick?: () => void
  disableEdit?: boolean
}

export const PatientGlanceView: FC<PatientGlanceViewProps> = ({
  patient,
  onEditClick,
  disableEdit = false,
}) => {
  const {
    tooltipState,
    getTooltipCopyClickHandler,
    getTooltipCopiedTooltipCloseHandler,
  } = useTooltipsState([
    'referralSource',
    'payor',
    'plan',
    'memberId',
    'market',
    'contractedWith',
  ])
  const handleEditTaskClick = () => (onEditClick ? onEditClick() : undefined)
  const { getPayorsIsLoading, getPayorName } = useGetPayorsQuery()
  const { plans, getPlansIsLoading } = useGetPlansQuery()
  const { markets, getMarketsIsLoading } = useGetMarketsQuery()
  const { partners, getPartnersIsLoading } = useGetPartnersQuery()
  const { chronicCondtionBodyParts, hasChronicCondition } =
    useGetPatientIntakeFlowsQuery()

  const getPlanName = (planId: string) => {
    if (getPlansIsLoading) {
      throw new Error('trying to map plan name before done loading')
    }
    const plan = plans.find(({ id }) => id === planId)
    if (!plan) {
      throw new Error('invalid plan id, data integrity issue')
    }
    return plan.name
  }

  const getMarketName = (marketId: string) => {
    if (getMarketsIsLoading) {
      throw new Error('trying to map market name before done loading')
    }
    const market = markets.find(({ id }) => id === marketId)
    if (!market) {
      throw new Error('invalid market id, data integrity issue')
    }
    return market.name
  }

  const getPartnerName = (partnerId: string) => {
    if (getPartnersIsLoading) {
      throw new Error('trying to map partner name before done loading')
    }
    const partner = partners.find(({ id }) => id === partnerId)
    if (!partner) {
      throw new Error('invalid market id, data integrity issue')
    }
    return partner.name
  }

  return (
    <Box
      sx={{
        padding: '24px 16px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '5px',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', columnGap: '16px', alignItems: 'center' }}>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '24px',
            }}
          >
            Patient Glance
          </Typography>
        </Box>
        {!disableEdit ? (
          <Box sx={{ display: 'flex' }}>
            <IconButton
              onClick={handleEditTaskClick}
              data-testid="edit_patient_glance_btn"
            >
              <EditIcon />
            </IconButton>
          </Box>
        ) : null}
      </Box>

      <Box sx={{ display: 'flex', columnGap: '30px', alignItems: 'center' }}>
        <Stack direction={'row'} gap={'24px'}>
          <Stack gap={'8px'} alignItems={'left'} paddingTop={'12px'}>
            <PatientGlanceAttrKey>Ref Source</PatientGlanceAttrKey>
            <Stack direction={'row'} gap={'4px'}>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '16px',
                  color: 'rgba(0, 0, 0, 0.87)',
                }}
              >
                {patient.referralSource ? patient.referralSource : '---'}
              </Typography>
              {patient.referralSource ? (
                <TCCopyToClipboard
                  value={patient.referralSource}
                  tooltipOpen={tooltipState['referralSource']}
                  onCopy={getTooltipCopyClickHandler('referralSource')}
                  onTooltipClose={getTooltipCopiedTooltipCloseHandler(
                    'referralSource'
                  )}
                />
              ) : null}
            </Stack>
          </Stack>
          <Stack gap={'8px'} alignItems={'left'} paddingTop={'12px'}>
            <PatientGlanceAttrKey>Payor Name</PatientGlanceAttrKey>
            <Stack direction={'row'} gap={'4px'}>
              <Typography>
                {patient.medicalProfile &&
                patient.medicalProfile.linkedPayorId &&
                !getPayorsIsLoading
                  ? getPayorName(patient.medicalProfile.linkedPayorId, true)
                  : '---'}
              </Typography>
              {patient.medicalProfile &&
              patient.medicalProfile.linkedPayorId &&
              !getPayorsIsLoading ? (
                <TCCopyToClipboard
                  value={
                    getPayorName(patient.medicalProfile.linkedPayorId, true) ??
                    ''
                  }
                  tooltipOpen={tooltipState['payor']}
                  onCopy={getTooltipCopyClickHandler('payor')}
                  onTooltipClose={getTooltipCopiedTooltipCloseHandler('payor')}
                />
              ) : null}
            </Stack>
          </Stack>
          <Stack gap={'8px'} alignItems={'left'} paddingTop={'12px'}>
            <PatientGlanceAttrKey>Plan Name</PatientGlanceAttrKey>
            <Stack direction={'row'} gap={'4px'}>
              <Typography>
                {patient.medicalProfile &&
                patient.medicalProfile.linkedPlanId &&
                !getPlansIsLoading
                  ? getPlanName(patient.medicalProfile.linkedPlanId)
                  : '---'}
              </Typography>
              {patient.medicalProfile &&
              patient.medicalProfile.linkedPlanId &&
              !getPlansIsLoading ? (
                <TCCopyToClipboard
                  value={getPlanName(patient.medicalProfile.linkedPlanId)}
                  tooltipOpen={tooltipState['plan']}
                  onCopy={getTooltipCopyClickHandler('plan')}
                  onTooltipClose={getTooltipCopiedTooltipCloseHandler('plan')}
                />
              ) : null}
            </Stack>
          </Stack>
          <Stack gap={'8px'} alignItems={'left'} paddingTop={'12px'}>
            <PatientGlanceAttrKey>Member ID</PatientGlanceAttrKey>
            <Stack direction={'row'} gap={'4px'}>
              <Typography>
                {patient.medicalProfile && patient.medicalProfile.memberId
                  ? patient.medicalProfile.memberId
                  : '---'}
              </Typography>
              {patient.medicalProfile && patient.medicalProfile.memberId ? (
                <TCCopyToClipboard
                  value={patient.medicalProfile.memberId}
                  tooltipOpen={tooltipState['memberId']}
                  onCopy={getTooltipCopyClickHandler('memberId')}
                  onTooltipClose={getTooltipCopiedTooltipCloseHandler(
                    'memberId'
                  )}
                />
              ) : null}
            </Stack>
          </Stack>
          <Stack gap={'8px'} alignItems={'left'} paddingTop={'12px'}>
            <PatientGlanceAttrKey>Market</PatientGlanceAttrKey>
            <Stack direction={'row'} gap={'4px'}>
              <Typography>
                {patient.medicalProfile &&
                patient.medicalProfile.marketId &&
                !getMarketsIsLoading
                  ? getMarketName(patient.medicalProfile.marketId)
                  : '---'}
              </Typography>
              {patient.medicalProfile &&
              patient.medicalProfile.marketId &&
              !getMarketsIsLoading ? (
                <TCCopyToClipboard
                  value={getMarketName(patient.medicalProfile.marketId)}
                  tooltipOpen={tooltipState['market']}
                  onCopy={getTooltipCopyClickHandler('market')}
                  onTooltipClose={getTooltipCopiedTooltipCloseHandler('market')}
                />
              ) : null}
            </Stack>
          </Stack>
          <Stack gap={'8px'} alignItems={'left'} paddingTop={'12px'}>
            <PatientGlanceAttrKey>Contracted With</PatientGlanceAttrKey>
            <Stack direction={'row'} gap={'4px'}>
              <Typography>
                {patient.medicalProfile &&
                patient.medicalProfile.contractedWith &&
                !getPartnersIsLoading
                  ? getPartnerName(patient.medicalProfile.contractedWith)
                  : '---'}
              </Typography>
              {patient.medicalProfile &&
              patient.medicalProfile.contractedWith &&
              !getPartnersIsLoading ? (
                <TCCopyToClipboard
                  value={getPartnerName(patient.medicalProfile.contractedWith)}
                  tooltipOpen={tooltipState['contractedWith']}
                  onCopy={getTooltipCopyClickHandler('contractedWith')}
                  onTooltipClose={getTooltipCopiedTooltipCloseHandler(
                    'contractedWith'
                  )}
                />
              ) : null}
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <Box sx={{ display: 'flex', columnGap: '30px', alignItems: 'center' }}>
        <Stack direction={'row'} columnGap={'30px'} paddingTop={'12px'}>
          {patient.doNotCall ? (
            <Tooltip title={DNC_TOOLTIP_TEXT}>
              <PatientHeaderChip color="warning" label="DNC" />
            </Tooltip>
          ) : null}

          {patient.notEnglishSpeaker && patient.language ? (
            <PatientHeaderChip label={`${patient.language} Speaker`} />
          ) : null}

          {patient.dualCoverage ? (
            <PatientHeaderChip label="D-SNP" />
          ) : patient.planType ? (
            <PatientHeaderChip label={patient.planType} />
          ) : null}

          {patient.isTraumaPatient ? (
            <PatientHeaderChip label="Trauma Intake" />
          ) : null}

          {patient.isThreadwell ? <ThreadwellChip label="ThreadWell" /> : null}

          {patient.medicalProfile?.groupType === 'Group' ? (
            <GroupMedicareChip label="Group Medicare" />
          ) : null}

          {hasChronicCondition ? (
            <Tooltip
              arrow={true}
              title={
                <Box>
                  {chronicCondtionBodyParts.map((bodyPart) => (
                    <Typography>{bodyPart}</Typography>
                  ))}
                </Box>
              }
            >
              <PatientHeaderChip
                label="Chronic"
                sx={{
                  backgroundColor: '#FFFF00',
                }}
              />
            </Tooltip>
          ) : null}
        </Stack>
      </Box>
    </Box>
  )
}
