import {
  UseMutateAsyncFunction,
  UseMutateFunction,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import {
  UpdatePatientArgs,
  UpdatePatientError,
  UpdatePatientReturns,
} from 'src/features/patients/domain'
import { usePatientsUseCases } from 'src/features/patients/patients.service-locator'
import { GET_PATIENT_QUERY_KEY_V2 } from 'src/features/patients/presentation'

type UseUpdatePatientProps = Omit<
  UseMutationOptions<
    UpdatePatientReturns,
    UpdatePatientError,
    UpdatePatientArgs,
    unknown
  >,
  'mutationFn'
>

type UseUpdatePatient = (props?: UseUpdatePatientProps) => {
  updatePatient: UseMutateFunction<
    UpdatePatientReturns,
    UpdatePatientError,
    UpdatePatientArgs,
    unknown
  >
  updatePatientAsync: UseMutateAsyncFunction<
    UpdatePatientReturns,
    UpdatePatientError,
    UpdatePatientArgs,
    unknown
  >
  updatePatientIsLoading: boolean
  updatePatientStatus: string
}

export const useUpdatePatient: UseUpdatePatient = (props = {}) => {
  const queryClient = useQueryClient()
  const patientsUseCases = usePatientsUseCases()

  const { onSuccess } = props

  const { mutate, isLoading, status, mutateAsync } = useMutation<
    UpdatePatientReturns,
    UpdatePatientError,
    UpdatePatientArgs,
    unknown
  >({
    mutationFn: (args: UpdatePatientArgs) => {
      return patientsUseCases.updatePatient(args)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [GET_PATIENT_QUERY_KEY_V2, variables.patientId],
      })
      onSuccess?.(data, variables, context)
    },
  })
  return {
    updatePatient: mutate,
    updatePatientAsync: mutateAsync,
    updatePatientIsLoading: isLoading,
    updatePatientStatus: status,
  }
}
