import { rest } from 'msw'

import { GetPatientPainAndFunctionV2ServiceResponse } from 'src/features/patients/infrastructure'

import * as settings from 'src/config/settings'
import { getPatientPainAndFunctionV2ServiceResponseMock } from 'src/__mocks__/responses/get-patient-pain-and-function-v2-service-response'

const url = `${settings.COMPASS_API_BASE_URL}/patients/:patientId/painAndFunction`

export const getPatientPainAndFunctionServiceResponseHandler =
  rest.get<GetPatientPainAndFunctionV2ServiceResponse>(
    url,
    async (req, res, ctx) => {
      const { patientId } = req.params

      return res(
        ctx.status(200),
        ctx.json({
          ...getPatientPainAndFunctionV2ServiceResponseMock,
          patientId: patientId,
        })
      )
    }
  )
