import { GetPatientStatusDefinitionsServiceResponse } from 'src/features/patients/infrastructure'

export const getPatientsStatusDefinitionsServiceResponseMock: GetPatientStatusDefinitionsServiceResponse =
  {
    'Do Not Contact': [
      'Not Attempted',
      'Left Voicemail',
      'No Answer',
      'Invalid Number',
      'Call Back',
      'Refused',
      'On Pathway',
      'Completed',
      'Non-Responsive',
      'Opt Out',
      'Hospice/Deceased',
    ],
    'Not Attempted': [
      'Not Attempted',
      'Left Voicemail',
      'No Answer',
      'Invalid Number',
      'Call Back',
      'Refused',
      'On Pathway',
      'Do Not Contact',
    ],
    'Left Voicemail': [
      'Left Voicemail',
      'No Answer',
      'Invalid Number',
      'Call Back',
      'Refused',
      'Unable to Contact',
      'On Pathway',
      'Do Not Contact',
    ],
    'No Answer': [
      'Left Voicemail',
      'No Answer',
      'Invalid Number',
      'Call Back',
      'Refused',
      'Unable to Contact',
      'On Pathway',
      'Do Not Contact',
    ],
    'Invalid Number': [
      'Left Voicemail',
      'No Answer',
      'Invalid Number',
      'Call Back',
      'Refused',
      'Unable to Contact',
      'On Pathway',
      'Do Not Contact',
    ],
    'Call Back': [
      'Left Voicemail',
      'No Answer',
      'Invalid Number',
      'Call Back',
      'Refused',
      'Unable to Contact',
      'On Pathway',
      'Do Not Contact',
    ],
    Refused: [
      'Left Voicemail',
      'No Answer',
      'Invalid Number',
      'Call Back',
      'Refused',
      'Unable to Contact',
      'On Pathway',
      'Do Not Contact',
    ],
    'Unable to Contact': [
      'Left Voicemail',
      'No Answer',
      'Invalid Number',
      'Call Back',
      'Refused',
      'Unable to Contact',
      'On Pathway',
      'Do Not Contact',
    ],
    'On Pathway': [
      'On Pathway',
      'Attempted Touchpoint',
      'Unable to Complete Touchpoint',
      'Completed',
      'Non-Responsive',
      'Opt Out',
      'Hospice/Deceased',
      'Do Not Contact',
    ],
    'Attempted Touchpoint': [
      'On Pathway',
      'Attempted Touchpoint',
      'Unable to Complete Touchpoint',
      'Completed',
      'Non-Responsive',
      'Opt Out',
      'Hospice/Deceased',
      'Do Not Contact',
    ],
    'Unable to Complete Touchpoint': [
      'On Pathway',
      'Attempted Touchpoint',
      'Unable to Complete Touchpoint',
      'Completed',
      'Non-Responsive',
      'Opt Out',
      'Hospice/Deceased',
      'Do Not Contact',
    ],
    Maintenance: ['Completed'],
    Completed: ['Completed', 'On Pathway'],
    'Non-Responsive': ['On Pathway'],
    'Opt Out': ['On Pathway'],
    'Hospice/Deceased': ['On Pathway'],
  }
