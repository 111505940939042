import { EligiblePatientsTableRow } from 'src/features/eligibility/presentation'
import {
  EligibilityState,
  clearSelectedEligiblePatientAction,
  openEligiblePatientFormModalSelector,
  selectedEligiblePatientRowSelector,
  selectedEligiblePatientSelector,
  setOpenEligiblePatientFormModalAction,
  setSelectedEligiblePatientAction,
  setSelectedEligiblePatientRowAction,
  useAppDispatch,
  useAppSelector,
} from 'src/features/shared/infrastructure'

export const useEligibilityPatientStore = () => {
  const dispatch = useAppDispatch()

  const selectedEligiblePatient = useAppSelector(
    selectedEligiblePatientSelector
  )

  const selectedEligiblePatientRow = useAppSelector(
    selectedEligiblePatientRowSelector
  )

  const openEligiblePatientFormModal = useAppSelector(
    openEligiblePatientFormModalSelector
  )

  const setSelectedEligiblePatient = (
    selectedEligiblePatient: EligibilityState['selectedEligiblePatient']
  ) => dispatch(setSelectedEligiblePatientAction(selectedEligiblePatient))

  const setSelectedEligiblePatientRow = (
    selectedEligiblePatientRow: EligibilityState['selectedEligiblePatientRow']
  ) => dispatch(setSelectedEligiblePatientRowAction(selectedEligiblePatientRow))

  const setOpenEligiblePatientFormModal = (
    openEligiblePatientFormModal: EligibilityState['openEligiblePatientFormModal']
  ) =>
    dispatch(
      setOpenEligiblePatientFormModalAction(openEligiblePatientFormModal)
    )

  const clearSelectedEligiblePatient = () =>
    dispatch(clearSelectedEligiblePatientAction())

  const isEligiblePatientsTableRowSelected = (
    row: EligiblePatientsTableRow
  ) => {
    return (
      selectedEligiblePatient &&
      selectedEligiblePatientRow &&
      selectedEligiblePatientRow.mbi === row.mbi
    )
  }

  return {
    clearSelectedEligiblePatient,
    isEligiblePatientsTableRowSelected,
    openEligiblePatientFormModal,
    selectedEligiblePatient,
    selectedEligiblePatientRow,
    setOpenEligiblePatientFormModal,
    setSelectedEligiblePatient,
    setSelectedEligiblePatientRow,
  }
}
