import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NPPESResult } from 'src/features/providers/domain'
import { ProvidersTableRow } from 'src/features/providers/presentation/forms/search-providers-form'

import { RootState } from 'src/features/shared/infrastructure'

// essentiall 1:1 with https://npiregistry.cms.hhs.gov/search
export type SearchProvidersState = {
  firstName?: string
  lastName?: string
  providerGroupId?: string
  isLoading: boolean
  page: number
  rowsPerPage: number
  searchResults?: NPPESResult[]
  selectedProvider?: ProvidersTableRow
}

const initialState: SearchProvidersState = {
  firstName: undefined,
  lastName: undefined,
  providerGroupId: undefined,
  isLoading: false,
  page: 0,
  rowsPerPage: 100,
  searchResults: [],
  selectedProvider: undefined,
}

const searchProvidersSlice = createSlice({
  name: 'searchProviders',
  initialState,
  reducers: {
    setFirstNameAction(
      state,
      action: PayloadAction<SearchProvidersState['firstName']>
    ) {
      state.firstName = action.payload
    },
    setLastNameAction(
      state,
      action: PayloadAction<SearchProvidersState['lastName']>
    ) {
      state.lastName = action.payload
    },
    setProviderGroupIdAction(
      state,
      action: PayloadAction<SearchProvidersState['providerGroupId']>
    ) {
      state.providerGroupId = action.payload
    },
    setIsLoadingAction(
      state,
      action: PayloadAction<SearchProvidersState['isLoading']>
    ) {
      state.isLoading = action.payload
    },
    setPageAction(state, action: PayloadAction<SearchProvidersState['page']>) {
      state.page = action.payload
    },
    setRowsPerPageAction(
      state,
      action: PayloadAction<SearchProvidersState['rowsPerPage']>
    ) {
      state.rowsPerPage = action.payload
    },
    setSearchResultsAction(
      state,
      action: PayloadAction<SearchProvidersState['searchResults']>
    ) {
      state.searchResults = action.payload
    },
    setSelectedProviderAction(
      state,
      action: PayloadAction<SearchProvidersState['selectedProvider']>
    ) {
      state.selectedProvider = action.payload
    },
  },
})

export const {
  setFirstNameAction,
  setLastNameAction,
  setProviderGroupIdAction,
  setIsLoadingAction,
  setPageAction,
  setRowsPerPageAction,
  setSearchResultsAction,
  setSelectedProviderAction,
} = searchProvidersSlice.actions

export const firstNameSelector = (state: RootState) =>
  state.searchProviders.firstName

export const lastNameSelector = (state: RootState) =>
  state.searchProviders.lastName

export const providerGroupIdSelector = (state: RootState) =>
  state.searchProviders.providerGroupId

export const isLoadingSelector = (state: RootState) =>
  state.searchProviders.isLoading

export const pageSelector = (state: RootState) => state.searchProviders.page

export const rowsPerPageSelector = (state: RootState) =>
  state.searchProviders.rowsPerPage

export const searchResultsSelector = (state: RootState) =>
  state.searchProviders.searchResults

export const selectedProviderSelector = (state: RootState) =>
  state.searchProviders.selectedProvider

export default searchProvidersSlice.reducer
