import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, Stack } from '@mui/material'
import { DevTool } from '@hookform/devtools'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import * as yup from 'yup'

import {
  FormLayoutWithCancelSave,
  ControlledNoteField,
  SDMField,
  SDMPatientUnderstandsField,
  SPOKE_WITH_OPTIONS,
  ControlledSelectField,
  useStatusDefinitions,
  YES_NO_OPTIONS,
  PatientGoalsSelect,
  PainAndFunctionField,
  StatusField,
} from 'src/features/shared/presentation/'
import {
  NoteFormProps,
  useCreatePatientSelfCareTouchpointNote,
  noteFieldLabelDefaultSx,
  NOTE_FIELD_LABEL,
  SelfCareTouchpointAdditionalQuestionsFormV2,
  usePainAndFunctionField,
  SelfCareTouchpointAdditionalQuestionsFormV2Values,
  HealthEquityQuestionsForm,
} from 'src/features/notes/presentation'
import {
  filterNoneValueOptions,
  mapToCreatePatientSelfCareTouchpointNoteV2Args,
  mapToUpdateDraftNoteArgs,
} from 'src/features/notes/adapters'
import { useNotesStore } from 'src/features/shared/infrastructure'
import {
  useCreatePatientPainAndFunction,
  useUpdatePatientStatus,
} from 'src/features/patients/presentation'
import { mapToPatientUpdateStatus } from 'src/features/patients/adapters'
import { mapToControlledSelectItem } from 'src/features/shared/adapters'
import { useGetPatientIntakeFlowsQuery } from 'src/features/msk/presentation'
import { useUpdateDraftNote } from 'src/features/notes/presentation'

const isSdmTextRequired = (sdm?: boolean) => !!sdm

const isSdmPatientUnderstandsConditionRequired = (sdm?: boolean) => !!sdm

const isSdmClinicalBelievesPatientUnderstandsConditionRequired = (
  sdm: boolean
) => !!sdm

const isAgreeYouUnderstandConditionRequired = (
  healthEquityRequired: boolean,
  spokeWith?: string
) => {
  return spokeWith
    ? ['Patient', 'Caregiver'].includes(spokeWith) && healthEquityRequired
    : false
}
const isAgreeYouUnderstandTreatmentOptionsRequired = (
  healthEquityRequired: boolean,
  spokeWith?: string
) => {
  return spokeWith
    ? ['Patient', 'Caregiver'].includes(spokeWith) && healthEquityRequired
    : false
}

const isReasonForRefusalRequired = (status?: string) => status === 'Refused'

export type SelfCareTouchpointNoteFormV2Fields =
  SelfCareTouchpointAdditionalQuestionsFormV2Values & {
    note: string
    status: string
    spokeWith?: string
    sdm: boolean
    communication: string
  }

type SelfCareTouchpointNoteFormV2Props = NoteFormProps

export const SelfCareTPNoteFormV2 = ({
  patient,
  setShowLoader,
  draftNote,
}: SelfCareTouchpointNoteFormV2Props) => {
  const { getAllStatusDefinitions } = useStatusDefinitions(patient)

  const { createPatientSelfCareTouchpointNoteAsync } =
    useCreatePatientSelfCareTouchpointNote()
  const { updatePatientStatusAsync } = useUpdatePatientStatus()
  const { setSelectedNoteType } = useNotesStore()

  const { intakes } = useGetPatientIntakeFlowsQuery()
  const painAndFunctionField = usePainAndFunctionField({
    intakes,
    patientId: patient.patientId,
    noteType: 'Self-Care Touchpoint',
    draftNotePainAndFunction: draftNote.painAndFunction,
  })
  const { createMultiplePatientPainAndFunction } =
    useCreatePatientPainAndFunction()
  const { updateDraftNoteAsync } = useUpdateDraftNote()

  const createSelfCareTouchpointNoteFormV2Schema: yup.Schema<SelfCareTouchpointNoteFormV2Fields> =
    yup.object().shape({
      note: yup.string().required('Required'),
      sdm: yup.boolean().required('Required'),
      spokeWith: yup.string().required('Required'),
      status: yup.string().required('Required'),
      reasonForRefusal: yup.string().when('status', {
        is: isReasonForRefusalRequired,
        then: (schema) => schema.required('Required'),
      }),
      communication: yup.string().required('Required'),
      painLevelDecreased: yup.string(),
      newSymptoms: yup.string(),
      additionalQuestionsOrConcerns: yup.string(),
      sdmText: yup.string().when('sdm', {
        is: isSdmTextRequired,
        then: (schema) => schema.required('Required'),
      }),
      sdmPatientUnderstandsCondition: yup.string().when('sdm', {
        is: isSdmPatientUnderstandsConditionRequired,
        then: (schema) => schema.required('Required'),
      }),
      sdmClinicalBelievesPatientUnderstandsCondition: yup.string().when('sdm', {
        is: isSdmClinicalBelievesPatientUnderstandsConditionRequired,
        then: (schema) => schema.required('Required'),
      }),
      agreeYouUnderstandCondition: yup
        .string()
        .when(['$healthEquityRequired', 'spokeWith'], {
          is: isAgreeYouUnderstandConditionRequired,
          then: (schema) => schema.required('Required'),
        }),
      agreeYouUnderstandTreatmentOptions: yup
        .string()
        .when(['$healthEquityRequired', 'spokeWith'], {
          is: isAgreeYouUnderstandTreatmentOptionsRequired,
          then: (schema) => schema.required('Required'),
        }),
      patientGoals: yup.string(),
    })

  const formMethods = useForm<SelfCareTouchpointNoteFormV2Fields>({
    resolver: yupResolver(createSelfCareTouchpointNoteFormV2Schema),
    context: {
      healthEquityRequired: patient.healthAndEquityQuestionsRoundTwoRequired,
    },
    defaultValues: {
      note: '',
      sdm: false,
      spokeWith: '',
      status: '',
      reasonForRefusal: '',
      communication: 'outbound',
      painLevelDecreased: '',
      newSymptoms: '',
      additionalQuestionsOrConcerns: '',
      sdmText: '',
      sdmPatientUnderstandsCondition: '',
      sdmClinicalBelievesPatientUnderstandsCondition: '',
      patientGoals: '',
    },
    mode: 'onBlur',
  })

  const sdmWatcher = formMethods.watch('sdm')
  const statusWatcher = formMethods.watch('status')
  const spokeWithWatcher = formMethods.watch('spokeWith')

  const submitHandler: SubmitHandler<
    SelfCareTouchpointNoteFormV2Fields
  > = async (data) => {
    setShowLoader?.(true)
    const painAndFunctionFieldHasAtLeastOneActive =
      painAndFunctionField.hasAtLeastOneActive
    const painAndFunctionFieldState = structuredClone(
      painAndFunctionField.state
    )

    const createPatientSelfCareTouchpointNoteArgs =
      mapToCreatePatientSelfCareTouchpointNoteV2Args(
        patient,
        data,
        draftNote.id
      )
    try {
      await createPatientSelfCareTouchpointNoteAsync(
        createPatientSelfCareTouchpointNoteArgs
      )

      toast.success('Patient Note successfully created!')
      painAndFunctionField.reset()
      if (
        patient.stageAndStatus &&
        patient.stageAndStatus.stage &&
        data.status
      ) {
        const createPatientUpdateStatusArgs = mapToPatientUpdateStatus(
          patient,
          data.status
        )
        try {
          await updatePatientStatusAsync(createPatientUpdateStatusArgs)
          toast.success('Patient Status successfully updated!')
        } catch (error) {
          toast.error('Patient Status update failed!', { autoClose: false })
        }
      }

      if (painAndFunctionFieldHasAtLeastOneActive) {
        await createMultiplePatientPainAndFunction(
          patient,
          intakes,
          painAndFunctionFieldState
        )
      }
    } catch (error) {
      toast.error('Failed creating patient note!', { autoClose: false })
    }
    setSelectedNoteType('')
    setShowLoader?.(false)
  }

  const handleSubmit = (data: SelfCareTouchpointNoteFormV2Fields) => {
    if (
      painAndFunctionField.hasAtLeastOneActive &&
      painAndFunctionField.hasErrors
    ) {
      formMethods.trigger()
      return
    }
    submitHandler(data)
  }

  const saveDraftHandler: SubmitHandler<
    SelfCareTouchpointNoteFormV2Fields
  > = async (data) => {
    setShowLoader?.(true)

    const painAndFunctionFieldState = structuredClone(
      painAndFunctionField.state
    )

    const updatePatientCustomNoteArgs = mapToUpdateDraftNoteArgs(
      patient,
      draftNote.id,
      {
        ...data,
        type: 'Self-Care Touchpoint',
        painAndFunction: painAndFunctionFieldState.values,
      }
    )

    try {
      await updateDraftNoteAsync(updatePatientCustomNoteArgs)
      toast.success('Patient Note Draft successfully Saved!')
    } catch (error) {
      toast.error('Failed Saving Patient Note Draft!', { autoClose: false })
    }

    setShowLoader?.(false)
  }

  const handleSaveDraft = (data: SelfCareTouchpointNoteFormV2Fields) => {
    saveDraftHandler(data)
  }

  useEffect(() => {
    const subscription = formMethods.watch((value, { name, type }) => {
      if (
        name === 'sdm' &&
        type === 'change' &&
        (value['sdmPatientUnderstandsCondition'] ||
          value['sdmClinicalBelievesPatientUnderstandsCondition'])
      ) {
        formMethods.reset((formValues) => ({
          ...formValues,
          sdmPatientUnderstandsCondition: '',
          sdmClinicalBelievesPatientUnderstandsCondition: '',
        }))
      }
    })

    return () => subscription.unsubscribe()
  }, [formMethods])

  return (
    <FormLayoutWithCancelSave
      onSubmit={handleSubmit}
      onSaveDraftClick={handleSaveDraft}
      formMethods={formMethods}
      draftNote={draftNote}
    >
      <Grid container rowSpacing={2} pb={'16px'}>
        <Grid item sm={12} pb={'16px'}>
          <ControlledNoteField />
        </Grid>
        <Grid item container columnSpacing={2} sm={12}>
          <Grid item sm={6}>
            <StatusField
              statusRequired
              reasonForRefusalRequired={isReasonForRefusalRequired(
                statusWatcher
              )}
              statuses={getAllStatusDefinitions()}
            />
          </Grid>
        </Grid>
        <Grid item container columnSpacing={2} sm={12}>
          <Grid item sm={2}>
            <ControlledSelectField
              name={'spokeWith'}
              label={NOTE_FIELD_LABEL.spokeWith}
              labelComponent={'inputLabel'}
              items={SPOKE_WITH_OPTIONS.map(mapToControlledSelectItem)}
              formControlProps={{
                size: 'small',
              }}
              formControlSx={{
                width: '100%',
              }}
              required
            />
          </Grid>
        </Grid>
        <Grid item container columnGap={'16px'} sm={10}>
          <Box pt={1} width="100%">
            <SDMField />
          </Box>
          {sdmWatcher === true ? (
            <Stack direction={'column'} rowGap={'16px'} ml={'80px'} py={'16px'}>
              <PatientGoalsSelect />

              <ControlledSelectField
                name={'sdmPatientUnderstandsCondition'}
                label={NOTE_FIELD_LABEL.sdmPatientUnderstandsCondition}
                items={filterNoneValueOptions(YES_NO_OPTIONS).map(
                  mapToControlledSelectItem
                )}
                required={isSdmPatientUnderstandsConditionRequired(sdmWatcher)}
                selectSx={{ width: '88px' }}
                formLabelSx={noteFieldLabelDefaultSx}
              />
              <SDMPatientUnderstandsField
                required={isSdmClinicalBelievesPatientUnderstandsConditionRequired(
                  sdmWatcher
                )}
              />
            </Stack>
          ) : null}
        </Grid>

        {isAgreeYouUnderstandConditionRequired(
          patient.healthAndEquityQuestionsRoundTwoRequired,
          spokeWithWatcher
        ) &&
        isAgreeYouUnderstandTreatmentOptionsRequired(
          patient.healthAndEquityQuestionsRoundTwoRequired,
          spokeWithWatcher
        ) ? (
          <Grid item sm={12}>
            <HealthEquityQuestionsForm
              agreeYouUnderstandConditionRequired={true}
              agreeYouUnderstandTreatmentOptionsRequired={true}
              showTitle={sdmWatcher}
            />
          </Grid>
        ) : null}

        {intakes.length ? (
          <Grid item sm={12}>
            <PainAndFunctionField
              intakes={intakes}
              fieldMethods={painAndFunctionField}
            />
          </Grid>
        ) : null}

        <Grid item sm={12}>
          <SelfCareTouchpointAdditionalQuestionsFormV2 />
        </Grid>
      </Grid>
      <DevTool control={formMethods.control} placement={'top-left'} />
    </FormLayoutWithCancelSave>
  )
}
