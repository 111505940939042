import { usePlansServiceAdapter } from 'src/features/providers/adapters'
import { UsePlansUseCases } from 'src/features/providers/domain'

type MakeUsePlansUseCases = () => UsePlansUseCases

const makeUsePlansUseCases: MakeUsePlansUseCases = () => () => {
  const plansServiceAdapter = usePlansServiceAdapter()

  return {
    getPlans: plansServiceAdapter.getPlans,
    createPlan: plansServiceAdapter.createPlan,
  }
}

export const usePlansUseCases: UsePlansUseCases = makeUsePlansUseCases()
