import { ReassignPathwayServiceResponse } from 'src/features/pathways/infrastructure'

export const reassignPathwayServiceResponseMock: ReassignPathwayServiceResponse =
  {
    id: '8ec6f897-1fbb-4110-9dea-5673c82e8861',
    patientId: 'fb3479bc-b59a-4c45-9cd0-742bcb508820',
    assignedTo: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
    eventsGenerated: false,
    pathwayId: '1415fb60-058d-42ff-bb0b-c87fbf484f74',
    lockDate: '06/20/2023',
    creationDate: '06/21/2023',
    lastUpdatedAt: '06/21/2023',
    isCancelled: false,
    isPaused: false,
    createdBy: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
    pathwayType: 'Self-Care',
    done: false,
    intakeId: null,
    firstAppointmentDate: null,
    message:
      'reassinged to pathway 1415fb60-058d-42ff-bb0b-c87fbf484f74 from pathway b280750e-4b0b-4cb1-968a-ae7f9bb5ad62 for patient fb3479bc-b59a-4c45-9cd0-742bcb508820',
  }
