import { AppBar, IconButton, Toolbar, Stack, Box } from '@mui/material'

import tcLogo from 'src/assets/images/tc-logo-white.svg'

export const Navbar = () => {
  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <AppBar
        sx={{
          backgroundColor: '#004F54',
          borderRadius: '0 0 20px 20px',
          height: '80px',
          position: 'relative',
        }}
      >
        <Toolbar
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Stack>
            <IconButton>
              <img src={tcLogo} alt="tailorcare logo" height={32} />
            </IconButton>
          </Stack>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
