import {
  useMutation,
  useQueryClient,
  UseMutateFunction,
  UseMutationOptions,
} from '@tanstack/react-query'

import {
  ReassignPathwayReturns,
  ReassignPathwayArgs,
  ReassignPathwayError,
} from 'src/features/pathways/domain'
import { usePathwaysUseCases } from 'src/features/pathways/pathways.service-locator'
import { GET_PATIENT_QUERY_KEY_V2 } from 'src/features/patients/presentation'
import { GET_PATIENT_INTAKE_FLOWS_QUERY_KEY } from 'src/features/msk/presentation'

type UseReassignPathwayProps = Omit<
  UseMutationOptions<
    ReassignPathwayReturns,
    ReassignPathwayError,
    ReassignPathwayArgs,
    unknown
  >,
  'mutationFn'
>
type UseReassignPathway = (props?: UseReassignPathwayProps) => {
  reassignPathway: UseMutateFunction<
    ReassignPathwayReturns,
    ReassignPathwayError,
    ReassignPathwayArgs,
    unknown
  >
  reassignPathwayIsLoading: boolean
  reassignPathwayStatus: string
}

export const useReassignPathway: UseReassignPathway = (props = {}) => {
  const queryClient = useQueryClient()
  const pathwaysUseCases = usePathwaysUseCases()

  const { onSuccess } = props

  const { mutate, isLoading, isError, status } = useMutation<
    ReassignPathwayReturns,
    ReassignPathwayError,
    ReassignPathwayArgs,
    unknown
  >({
    mutationFn: (args: ReassignPathwayArgs) => {
      return pathwaysUseCases.reassignPathway(args)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [GET_PATIENT_QUERY_KEY_V2, variables.patientId],
      })
      queryClient.invalidateQueries({
        queryKey: [GET_PATIENT_INTAKE_FLOWS_QUERY_KEY, variables.patientId],
      })
      onSuccess?.(data, variables, context)
    },
  })
  return {
    reassignPathway: mutate,
    reassignPathwayIsLoading: isLoading,
    reassignPathwayIsError: isError,
    reassignPathwayStatus: status,
  }
}
