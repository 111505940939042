import {
  TasksState,
  openUserTasksCalendarModalSelector,
  setAddTaskFormModalOpenAction,
  setEditAgendaMultipleTasksFormModalOpenAction,
  setEditDayMultipleTasksFormModalOpenAction,
  setEditTaskFormModalOpenAction,
  setOpenUserTasksCalendarModalAction,
  setSelectedSidebarTabAction,
  setSelectedTaskAction,
  setShowTaskLoaderAction,
  setSidebarOpenAction,
  setTaskViewModalOpenAction,
  setUserTasksCalendarAgendaSelectedTasksAction,
  setUserTasksCalendarDateRangeAction,
  setUserTasksCalendarDaySelectedTasksAction,
  setPatientTasksCalendarViewAction,
  setUserTasksCalendarViewAction,
  taskViewModalOpenSelector,
  tasksSelectedTaskSelector,
  tasksSidebarOpenAddTaskFormModalSelector,
  tasksSidebarOpenEditAgendaMultipleTasksFormModalSelector,
  tasksSidebarOpenEditDayMultipleTasksFormModalSelector,
  tasksSidebarOpenEditTaskFormModalSelector,
  tasksSidebarOpenSelector,
  tasksSidebarSelectedTabSelector,
  tasksSidebarShowLoaderSelector,
  useAppDispatch,
  useAppSelector,
  userTasksCalendarAgendaSelectedTasksSelector,
  userTasksCalendarDateRangeSelector,
  userTasksCalendarDaySelectedTasksSelector,
  patientTasksCalendarViewSelector,
  userTasksCalendarViewSelector,
} from 'src/features/shared/infrastructure'

export const useTasksStore = () => {
  const dispatch = useAppDispatch()

  const sidebarOpen = useAppSelector(tasksSidebarOpenSelector)

  const taskViewModalOpen = useAppSelector(taskViewModalOpenSelector)

  const selectedSidebarTab = useAppSelector(tasksSidebarSelectedTabSelector)

  const addTaskFormModalOpen = useAppSelector(
    tasksSidebarOpenAddTaskFormModalSelector
  )

  const editTaskFormModalOpen = useAppSelector(
    tasksSidebarOpenEditTaskFormModalSelector
  )

  const editAgendaMultipleTasksFormModalOpen = useAppSelector(
    tasksSidebarOpenEditAgendaMultipleTasksFormModalSelector
  )

  const editDayMultipleTasksFormModalOpen = useAppSelector(
    tasksSidebarOpenEditDayMultipleTasksFormModalSelector
  )

  const selectedTask = useAppSelector(tasksSelectedTaskSelector)

  const showLoader = useAppSelector(tasksSidebarShowLoaderSelector)

  const openUserTasksCalendarModal = useAppSelector(
    openUserTasksCalendarModalSelector
  )

  const userTasksCalendarDateRange = useAppSelector(
    userTasksCalendarDateRangeSelector
  )

  const patientTasksCalendarView = useAppSelector(
    patientTasksCalendarViewSelector
  )

  const userTasksCalendarView = useAppSelector(userTasksCalendarViewSelector)

  const userTasksCalendarAgendaSelectedTasks = useAppSelector(
    userTasksCalendarAgendaSelectedTasksSelector
  )

  const userTasksCalendarDaySelectedTasks = useAppSelector(
    userTasksCalendarDaySelectedTasksSelector
  )

  const setSidebarOpen = (open: boolean) => dispatch(setSidebarOpenAction(open))

  const setTaskViewModalOpen = (open: boolean) =>
    dispatch(setTaskViewModalOpenAction(open))

  const setSelectedSidebarTab = (
    selectedSidebarTab: TasksState['selectedSidebarTab']
  ) => dispatch(setSelectedSidebarTabAction(selectedSidebarTab))

  const setAddTaskFormModalOpen = (
    open: boolean,
    defaultTitle = '',
    defaultUrgent = false
  ) =>
    dispatch(
      setAddTaskFormModalOpenAction({ open, defaultTitle, defaultUrgent })
    )

  const setEditTaskFormModalOpen = (open: boolean) =>
    dispatch(setEditTaskFormModalOpenAction(open))

  const setEditAgendaMultipleTasksFormModalOpen = (open: boolean) =>
    dispatch(setEditAgendaMultipleTasksFormModalOpenAction(open))

  const setEditDayMultipleTasksFormModalOpen = (open: boolean) =>
    dispatch(setEditDayMultipleTasksFormModalOpenAction(open))

  const setSelectedTask = (task: TasksState['selectedTask']) =>
    dispatch(setSelectedTaskAction(task))

  const setShowLoader = (showLoader: boolean) =>
    dispatch(setShowTaskLoaderAction(showLoader))

  const setUserOpenTasksCalendarModal = (
    openUserTasksCalendarModal: boolean
  ) => {
    dispatch(setOpenUserTasksCalendarModalAction(openUserTasksCalendarModal))
  }

  const setUserTasksCalendarDateRange = (
    userTasksCalendarDateRange: TasksState['userTasksCalendarDateRange']
  ) => {
    dispatch(setUserTasksCalendarDateRangeAction(userTasksCalendarDateRange))
  }

  const setPatientTasksCalendarView = (
    patientTasksCalendarView: TasksState['patientTasksCalendarView']
  ) => {
    dispatch(setPatientTasksCalendarViewAction(patientTasksCalendarView))
  }

  const setUserTasksCalendarView = (
    userTasksCalendarView: TasksState['userTasksCalendarView']
  ) => {
    dispatch(setUserTasksCalendarViewAction(userTasksCalendarView))
  }

  const setUserTasksCalendarAgendaSelectedTasks = (
    userTasksCalendarAgendaSelectedTasks: TasksState['userTasksCalendarAgendaSelectedTasks']
  ) => {
    dispatch(
      setUserTasksCalendarAgendaSelectedTasksAction(
        userTasksCalendarAgendaSelectedTasks
      )
    )
  }

  const setUserTasksCalendarDaySelectedTasks = (
    userTasksCalendarDaySelectedTasks: TasksState['userTasksCalendarDaySelectedTasks']
  ) => {
    dispatch(
      setUserTasksCalendarDaySelectedTasksAction(
        userTasksCalendarDaySelectedTasks
      )
    )
  }

  return {
    addTaskFormModalOpen,
    editAgendaMultipleTasksFormModalOpen,
    editDayMultipleTasksFormModalOpen,
    editTaskFormModalOpen,
    openUserTasksCalendarModal,
    patientTasksCalendarView,
    selectedSidebarTab,
    selectedTask,
    setAddTaskFormModalOpen,
    setEditAgendaMultipleTasksFormModalOpen,
    setEditDayMultipleTasksFormModalOpen,
    setEditTaskFormModalOpen,
    setPatientTasksCalendarView,
    setSelectedSidebarTab,
    setSelectedTask,
    setShowLoader,
    setSidebarOpen,
    setTaskViewModalOpen,
    setUserOpenTasksCalendarModal,
    setUserTasksCalendarAgendaSelectedTasks,
    setUserTasksCalendarDateRange,
    setUserTasksCalendarDaySelectedTasks,
    setUserTasksCalendarView,
    showLoader,
    sidebarOpen,
    taskViewModalOpen,
    userTasksCalendarAgendaSelectedTasks,
    userTasksCalendarDateRange,
    userTasksCalendarDaySelectedTasks,
    userTasksCalendarView,
  }
}
