import { usePartnersServiceAdapter } from 'src/features/providers/adapters'
import { UsePartnersUseCases } from 'src/features/providers/domain'

type MakeUsePartnersUseCases = () => UsePartnersUseCases

const makeUsePartnersUseCases: MakeUsePartnersUseCases = () => () => {
  const partnersServiceAdapter = usePartnersServiceAdapter()

  return {
    getPartners: partnersServiceAdapter.getPartners,
  }
}

export const usePartnersUseCases: UsePartnersUseCases =
  makeUsePartnersUseCases()
