import { useQuery } from '@tanstack/react-query'

import { usePayorsUseCases } from 'src/features/providers/payors.service-locator'
import { GetPayorsError, GetPayorsReturns } from 'src/features/providers/domain'

export const GET_PAYORS_QUERY_KEY = 'getPayors'

export const useGetPayorsQuery = () => {
  const payorsUseCases = usePayorsUseCases()

  const { data, isLoading, isError, isSuccess } = useQuery<
    GetPayorsReturns,
    GetPayorsError
  >([GET_PAYORS_QUERY_KEY], () => {
    return payorsUseCases.getPayors()
  })

  const getPayorName = (payorId: string, raiseError = false) => {
    if (isLoading && raiseError) {
      throw new Error('trying to map payor name before done loading')
    }
    const payors = data ?? []
    const payor = payors.find(({ id }) => id === payorId)
    if (!payor && raiseError) {
      throw new Error('invalid payor id, data integrity issue')
    }
    return payor ? payor.name : undefined
  }

  return {
    payors: data ?? [],
    getPayorsIsLoading: isLoading,
    getPayorsIsError: isError,
    getPayorsIsSuccess: isSuccess,
    getPayorName,
  }
}
