import { CreatePatientServiceResponse } from 'src/features/patients/infrastructure'

export const createPatientServiceResponseMock: CreatePatientServiceResponse = {
  patientId: '3c2343eb-bc60-45bf-9d48-41d33c1437f7',
  firstName: 'Trix',
  lastName: 'Bartoloma',
  middleName: null,
  dob: '06/25/2035',
  mbi: '7f42dn4qn77',
  // eligible: true,
  doNotCall: false,
  addressId: 'b2936815-db79-4909-b0e4-9d05eb5e6513',
  addressLine1: '81209 Homewood Pass',
  addressLine2: '11th Floor',
  city: 'Albuquerque',
  state: 'NM',
  zip: '32244',
  contacts: [
    {
      id: '23d9b926-c674-40b1-8b44-0f7d9b1888c1',
      phone: '5538636177  ',
      email: null,
    },
    {
      id: '2725e36f-5ca5-43a6-bdd9-e64cc0a1c7f7',
      phone: null,
      email: 'ekennetnt@princeton.edu',
    },
  ],
  // medicalProfile: {
  //   id: '426e932f-5a6f-4242-a954-e736a914cf1f',
  //   patientId: '3c2343eb-bc60-45bf-9d48-41d33c1437f7',
  //   planId: 'H15277-254-534',
  //   primaryCarePhysician: 'Englebert Kennet',
  //   primary: true,
  //   createdAt: '2023-06-20T17:57:47.137Z',
  //   lastUpdatedAt: '2023-06-20T17:57:47.137Z',
  //   payor: 'Humana',
  //   groupName: 'PCP Group Name 72',
  //   phone: null,
  //   fax: null,
  //   addressLine1: '40 Mallard Avenue',
  //   addressLine2: 'Apt 1721',
  //   city: 'Washington',
  //   state: 'DC',
  //   zip: '20465',
  //   county: null,
  // },
}
