import { useCallback } from 'react'
import {
  SSearchDuplicatePatientsService,
  SSearchDuplicatePatientsServiceParams,
  SSearchDuplicatePatientsServiceResponse,
} from 'src/features/patients/infrastructure'
import { useCompassApiClient } from 'src/features/shared/infrastructure'
import * as settings from 'src/config/settings'

export const useSearchDuplicatePatientsService = () => {
  const { getCompassApiClient } = useCompassApiClient()

  const duplicateUserSearch: SSearchDuplicatePatientsService = useCallback(
    async (params: SSearchDuplicatePatientsServiceParams) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.get<SSearchDuplicatePatientsServiceResponse>(
        settings.COMPASS_API_BASE_URL + '/search/existing-users',
        {
          params,
        }
      )
    },
    [getCompassApiClient]
  )

  return {
    duplicateUserSearch,
  }
}
