import { useCallback, useMemo } from 'react'

import { formatDate } from 'src/features/shared/utils'
import {
  useGetAllTasksByUserIdQuery,
  useGetDoneTasksByUserIdQuery,
  useGetTodayTasksByUserIdQuery,
  useGetUrgentTasksByUserIdQuery,
  useGetUpcomingTasksByUserIdQuery,
  useGetOverdueTasksByUserIdQuery,
} from 'src/features/tasks/presentation'
import { Task } from 'src/features/tasks/domain'
import { useTasksStore } from 'src/features/shared/infrastructure'

type RefreshTasksProps = {
  all?: boolean
  upcoming?: boolean
  today?: boolean
  urgent?: boolean
  overdue?: boolean
  done?: boolean
}

type UseUserCalendarTasks = () => {
  refreshUserCalendarTasks: (props?: RefreshTasksProps) => void
  isLoadingUserCalendarTasks: boolean
  setUserTasksCalendarDateRange: (dateRange: Date[]) => void
  userAllTasks: Task[]
  userUpcomingTasks: Task[]
  userUrgentTasks: Task[]
  userTodayTasks: Task[]
  userOverdueTasks: Task[]
  userDoneTasks: Task[]
}

export const useUserCalendarTasks: UseUserCalendarTasks = () => {
  const {
    userTasksCalendarDateRange,
    setUserTasksCalendarDateRange: _setUserTasksCalendarDateRange,
  } = useTasksStore()

  const setUserTasksCalendarDateRange = useCallback(
    (dateRange: Date[]) => {
      _setUserTasksCalendarDateRange(dateRange.map((date) => formatDate(date)))
    },
    [_setUserTasksCalendarDateRange]
  )

  const {
    userAllTasks,
    refetchUserAllTasks,
    userAllTasksIsFetching,
    userAllTasksIsLoading,
  } = useGetAllTasksByUserIdQuery({
    params: {
      paginated: false,
      withUrgent: true,
      fromDate: formatDate(userTasksCalendarDateRange[0]),
      toDate: formatDate(userTasksCalendarDateRange[1]),
    },
  })
  const {
    userUpcomingTasks,
    refetchUserUpcomingTasks,
    userAllUpcomingTasksIsFetching,
    userUpcomingTasksIsLoading,
  } = useGetUpcomingTasksByUserIdQuery({
    params: {
      paginated: false,
      withUrgent: true,
      fromDate: formatDate(userTasksCalendarDateRange[0]),
      toDate: formatDate(userTasksCalendarDateRange[1]),
    },
  })
  const {
    userUrgentTasks,
    refetchUserUrgentTasks,
    userUrgentTasksIsFetching,
    userUrgentTasksIsLoading,
  } = useGetUrgentTasksByUserIdQuery({
    params: {
      paginated: false,
      fromDate: formatDate(userTasksCalendarDateRange[0]),
      toDate: formatDate(userTasksCalendarDateRange[1]),
    },
  })
  const {
    userTodayTasks,
    refetchUserTodayTasks,
    userTodayTasksIsFetching,
    userTodayTasksIsLoading,
  } = useGetTodayTasksByUserIdQuery({
    params: {
      paginated: false,
      withUrgent: true,
      fromDate: formatDate(userTasksCalendarDateRange[0]),
      toDate: formatDate(userTasksCalendarDateRange[1]),
    },
  })
  const {
    userOverdueTasks,
    refetchUserOverdueTasks,
    userOverdueTasksIsFetching,
    userOverdueTasksIsLoading,
  } = useGetOverdueTasksByUserIdQuery({
    params: {
      paginated: false,
      withUrgent: true,
      fromDate: formatDate(userTasksCalendarDateRange[0]),
      toDate: formatDate(userTasksCalendarDateRange[1]),
    },
  })
  const {
    userDoneTasks,
    refetchUserDoneTasks,
    userDoneTasksIsFetching,
    userDoneTasksIsLoading,
  } = useGetDoneTasksByUserIdQuery({
    params: {
      paginated: false,
      withUrgent: true,
      fromDate: formatDate(userTasksCalendarDateRange[0]),
      toDate: formatDate(userTasksCalendarDateRange[1]),
    },
  })

  const refreshUserCalendarTasks = useCallback(
    (
      { all, upcoming, today, urgent, overdue, done }: RefreshTasksProps = {
        all: true,
        upcoming: true,
        today: true,
        urgent: true,
        overdue: true,
        done: true,
      }
    ) => {
      if (all) {
        refetchUserAllTasks()
      }
      if (today) {
        refetchUserTodayTasks()
      }
      if (urgent) {
        refetchUserUrgentTasks()
      }
      if (overdue) {
        refetchUserOverdueTasks()
      }
      if (done) {
        refetchUserDoneTasks()
      }
      if (upcoming) {
        refetchUserUpcomingTasks()
      }
    },
    [
      refetchUserAllTasks,
      refetchUserDoneTasks,
      refetchUserTodayTasks,
      refetchUserUrgentTasks,
      refetchUserUpcomingTasks,
      refetchUserOverdueTasks,
    ]
  )

  const isLoadingUserCalendarTasks = useMemo(() => {
    return (
      userAllTasksIsFetching ||
      userAllTasksIsLoading ||
      userDoneTasksIsFetching ||
      userDoneTasksIsLoading ||
      userTodayTasksIsFetching ||
      userTodayTasksIsLoading ||
      userUrgentTasksIsFetching ||
      userUrgentTasksIsLoading ||
      userAllUpcomingTasksIsFetching ||
      userUpcomingTasksIsLoading ||
      userOverdueTasksIsFetching ||
      userOverdueTasksIsLoading
    )
  }, [
    userAllTasksIsFetching,
    userAllTasksIsLoading,
    userDoneTasksIsFetching,
    userDoneTasksIsLoading,
    userTodayTasksIsFetching,
    userTodayTasksIsLoading,
    userUrgentTasksIsFetching,
    userUrgentTasksIsLoading,
    userAllUpcomingTasksIsFetching,
    userUpcomingTasksIsLoading,
    userOverdueTasksIsFetching,
    userOverdueTasksIsLoading,
  ])

  return {
    setUserTasksCalendarDateRange,
    userAllTasks,
    userUpcomingTasks,
    userUrgentTasks,
    userTodayTasks,
    userOverdueTasks,
    userDoneTasks,
    refreshUserCalendarTasks,
    isLoadingUserCalendarTasks,
  }
}
