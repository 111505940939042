import {
  UseMutateAsyncFunction,
  UseMutateFunction,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import {
  CreatePatientOrthoTouchpointNoteReturns,
  CreatePatientOrthoTouchpointNoteArgs,
  CreatePatientOrthoTouchpointNoteError,
} from 'src/features/notes/domain'
import { GET_PATIENT_QUERY_KEY_V2 } from 'src/features/patients/presentation'
import {
  GET_PATIENT_DRAFT_NOTE,
  GET_PATIENT_NOTES_QUERY_KEY,
} from 'src/features/notes/presentation'
import { useNotesUseCases } from 'src/features/notes/notes.service-locator'

type UseCreatePatientOrthoTouchpointNoteProps = Omit<
  UseMutationOptions<
    CreatePatientOrthoTouchpointNoteReturns,
    CreatePatientOrthoTouchpointNoteError,
    CreatePatientOrthoTouchpointNoteArgs,
    unknown
  >,
  'mutationFn'
>

type UseCreatePatientOrthoTouchpointNote = (
  props?: UseCreatePatientOrthoTouchpointNoteProps
) => {
  createPatientOrthoTouchpointNote: UseMutateFunction<
    CreatePatientOrthoTouchpointNoteReturns,
    CreatePatientOrthoTouchpointNoteError,
    CreatePatientOrthoTouchpointNoteArgs,
    unknown
  >
  createPatientOrthoTouchpointNoteAsync: UseMutateAsyncFunction<
    CreatePatientOrthoTouchpointNoteReturns,
    CreatePatientOrthoTouchpointNoteError,
    CreatePatientOrthoTouchpointNoteArgs,
    unknown
  >
  createPatientOrthoTouchpointNoteIsLoading: boolean
  createPatientOrthoTouchpointNoteIsError: boolean
  createPatientOrthoTouchpointNoteStatus: string
}

export const useCreatePatientOrthoTouchpointNote: UseCreatePatientOrthoTouchpointNote =
  (props = {}) => {
    const queryClient = useQueryClient()
    const notesUseCases = useNotesUseCases()

    const { onSuccess } = props

    const { mutate, mutateAsync, isLoading, isError, status } = useMutation<
      CreatePatientOrthoTouchpointNoteReturns,
      CreatePatientOrthoTouchpointNoteError,
      CreatePatientOrthoTouchpointNoteArgs,
      unknown
    >({
      mutationFn: (args: CreatePatientOrthoTouchpointNoteArgs) => {
        return notesUseCases.createPatientOrthoTouchpointNote(args)
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: [GET_PATIENT_QUERY_KEY_V2, variables.patientId],
        })
        queryClient.invalidateQueries({
          queryKey: [GET_PATIENT_NOTES_QUERY_KEY, variables.patientId],
        })
        queryClient.invalidateQueries({
          queryKey: [GET_PATIENT_DRAFT_NOTE, variables.patientId],
        })
        onSuccess?.(data, variables, context)
      },
    })
    return {
      createPatientOrthoTouchpointNote: mutate,
      createPatientOrthoTouchpointNoteAsync: mutateAsync,
      createPatientOrthoTouchpointNoteIsLoading: isLoading,
      createPatientOrthoTouchpointNoteIsError: isError,
      createPatientOrthoTouchpointNoteStatus: status,
    }
  }
