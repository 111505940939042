import axios from 'axios'

import { EligibilityUseCases } from 'src/features/eligibility/domain'
import { useEligibilityService } from 'src/features/eligibility/infrastructure'
import {
  mapToGetEligiblePatientsReturns,
  mapToGetEligiblePatientsServiceParams,
  mapToGetEligiblePatientsServiceFilters,
  mapToGetEligiblePatientsError,
} from 'src/features/eligibility/adapters'
import { useAxiosErrorHandling } from 'src/features/shared/infrastructure'

export const useEligibilityServiceAdapter = () => {
  const eligibilityService = useEligibilityService()
  const { handleAxiosError } = useAxiosErrorHandling()

  const getEligiblePatients: EligibilityUseCases['getEligiblePatients'] =
    async ({ params, filters } = {}) => {
      try {
        const serviceParams = mapToGetEligiblePatientsServiceParams(params)
        const serviceFilters = mapToGetEligiblePatientsServiceFilters(filters)

        const response = await eligibilityService.getEligiblePatients(
          serviceParams,
          serviceFilters
        )

        return mapToGetEligiblePatientsReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToGetEligiblePatientsError)
        }
        throw error
      }
    }

  return {
    getEligiblePatients,
  }
}
