import { GetEligiblePatientsServiceResponse } from 'src/features/eligibility/infrastructure'

export const getEligiblePatientsServiceResponseMock: GetEligiblePatientsServiceResponse =
  {
    count: '837',
    eligibility: [
      {
        firstName: 'Deeyn',
        middleName: null,
        lastName: 'Bowdery',
        dob: '3/16/38',
        primaryPhone: '8300272738',
        secondaryPhone: null,
        email: null,
        mbi: '6WV6W38YH63',
        addressLine1: '65020 Ryan Terrace',
        addressLine2: null,
        city: 'Oklahoma City',
        state: 'OK',
        zip: '19921',
        doNotCall: 'N',
        // primaryCarePhysician: 'Yolanda Cancutt',
        // planId: 'H88914-711-451',
        // eligible: 'Y',
        count: '837',
      },
      {
        firstName: 'Ellary',
        middleName: null,
        lastName: 'Chezelle',
        dob: '5/2/24',
        primaryPhone: '6802463886',
        secondaryPhone: null,
        email: 'ksoftleyk7@addtoany.com',
        mbi: '7q86wa8fk27',
        addressLine1: '2 Grayhawk Circle',
        addressLine2: null,
        city: 'Miami',
        state: 'FL',
        zip: '26501',
        doNotCall: 'N',
        // primaryCarePhysician: 'Kelila Softley',
        // planId: 'H25036-703-121',
        // eligible: 'Y',
        count: '837',
      },
      {
        firstName: 'Sammy',
        middleName: null,
        lastName: 'Petett',
        dob: '7/5/46',
        primaryPhone: '7056483449',
        secondaryPhone: null,
        email: 'kbachelork@skyrock.com',
        mbi: '4TJ8DM8RC82',
        addressLine1: '4109 Mayer Hill',
        addressLine2: 'Suite 99',
        city: 'Portland',
        state: 'OR',
        zip: '31925',
        doNotCall: 'N',
        // primaryCarePhysician: 'Karine Bachelor',
        // planId: 'H35591-378-181',
        // eligible: 'Y',
        count: '837',
      },
      {
        firstName: 'Analiese',
        middleName: null,
        lastName: 'Brelsford',
        dob: '8/28/42',
        primaryPhone: null,
        secondaryPhone: null,
        email: 'fellcomeiv@theglobeandmail.com',
        mbi: '3QF5HC8YE89',
        addressLine1: '6 Maryland Pass',
        addressLine2: 'Apt 666',
        city: 'Raleigh',
        state: 'NC',
        zip: '67596',
        doNotCall: 'N',
        // primaryCarePhysician: 'Fredek Ellcome',
        // planId: 'H26570-694-947',
        // eligible: 'Y',
        count: '837',
      },
      {
        firstName: 'Luce',
        middleName: null,
        lastName: 'Antecki',
        dob: '3/10/29',
        primaryPhone: '9844620845',
        secondaryPhone: null,
        email: 'ubalnaveiu@storify.com',
        mbi: '6D79NX6RA21',
        addressLine1: '52543 Reindahl Parkway',
        addressLine2: null,
        city: 'Rochester',
        state: 'NY',
        zip: '43388',
        doNotCall: 'N',
        // primaryCarePhysician: 'Udale Balnave',
        // planId: 'H46687-256-889',
        // eligible: 'Y',
        count: '837',
      },
      {
        firstName: 'Christi',
        middleName: null,
        lastName: 'Orteu',
        dob: '5/4/48',
        primaryPhone: '3827947899',
        secondaryPhone: null,
        email: 'felesanderij@comcast.net',
        mbi: '3VG5TD7QA91',
        addressLine1: '84 Brickson Park Crossing',
        addressLine2: null,
        city: 'Chattanooga',
        state: 'TN',
        zip: '43615',
        doNotCall: 'N',
        // primaryCarePhysician: 'Fernande Elesander',
        // planId: 'H09892-036-433',
        // eligible: 'Y',
        count: '837',
      },
      {
        firstName: 'Michelina',
        middleName: null,
        lastName: 'Antoinet',
        dob: '10/26/34',
        primaryPhone: '3551240560',
        secondaryPhone: null,
        email: 'hgaskoini1@reverbnation.com',
        mbi: '4E19XJ3HT77',
        addressLine1: '0 Haas Point',
        addressLine2: 'Apt 552',
        city: 'Anaheim',
        state: 'CA',
        zip: '77956',
        doNotCall: 'N',
        // primaryCarePhysician: 'Hall Gaskoin',
        // planId: 'H60560-192-044',
        // eligible: 'Y',
        count: '837',
      },
      {
        firstName: 'Lindsey',
        middleName: null,
        lastName: 'Ranfield',
        dob: '4/21/22',
        primaryPhone: '8776242024',
        secondaryPhone: null,
        email: 'ssothcotth0@utexas.edu',
        mbi: '3KH3R54FR58',
        addressLine1: '59262 Manitowish Terrace',
        addressLine2: 'Apt 899',
        city: 'Dallas',
        state: 'TX',
        zip: '4943',
        doNotCall: 'N',
        // primaryCarePhysician: 'Starla Sothcott',
        // planId: 'H50932-191-813',
        // eligible: 'Y',
        count: '837',
      },
      {
        firstName: 'Hermie',
        middleName: null,
        lastName: 'Ayerst',
        dob: '4/23/31',
        primaryPhone: '5466477450',
        secondaryPhone: '818407626',
        email: 'gjosephspx@tripod.com',
        mbi: '5R74CT8QF85',
        addressLine1: '9766 Pennsylvania Trail',
        addressLine2: null,
        city: 'Cedar Rapids',
        state: 'IA',
        zip: '72754',
        doNotCall: 'N',
        // primaryCarePhysician: 'Gerardo Josephs',
        // planId: 'H82961-097-550',
        // eligible: 'Y',
        count: '837',
      },
      {
        firstName: 'Nickey',
        middleName: null,
        lastName: 'Keeling',
        dob: '7/6/36',
        primaryPhone: '9608971700',
        secondaryPhone: null,
        email: null,
        mbi: '4p94rj8jn40',
        addressLine1: '90767 Dayton Alley',
        addressLine2: null,
        city: 'Davenport',
        state: 'IA',
        zip: '84271',
        doNotCall: 'N',
        // primaryCarePhysician: 'Diana Richings',
        // planId: 'H55726-767-159',
        // eligible: 'Y',
        count: '837',
      },
      {
        firstName: 'Trudie',
        middleName: null,
        lastName: 'Whiston',
        dob: '11/29/42',
        primaryPhone: '8094517954',
        secondaryPhone: null,
        email: 'rfayerbrother38@zdnet.com',
        mbi: '4YA5DX4CQ41',
        addressLine1: '5 Marcy Terrace',
        addressLine2: null,
        city: 'Milwaukee',
        state: 'WI',
        zip: '83900',
        doNotCall: 'N',
        // primaryCarePhysician: 'Rahel Fayerbrother',
        // planId: 'H18545-848-667',
        // eligible: 'Y',
        count: '837',
      },
      {
        firstName: 'Lionello',
        middleName: null,
        lastName: 'Joselson',
        dob: '3/29/49',
        primaryPhone: '6394518650',
        secondaryPhone: null,
        email: 'swettonjf@uiuc.edu',
        mbi: '8h99cw0xd59',
        addressLine1: '28325 Mockingbird Pass',
        addressLine2: 'PO Box 62601',
        city: 'New York City',
        state: 'NY',
        zip: '61131',
        doNotCall: 'N',
        // primaryCarePhysician: 'Sutherlan Wetton',
        // planId: 'H96156-986-313',
        // eligible: 'Y',
        count: '837',
      },
      {
        firstName: 'Gran',
        middleName: null,
        lastName: 'Tewkesberrie',
        dob: '6/15/30',
        primaryPhone: '9785679035',
        secondaryPhone: null,
        email: 'jyousef5r@theatlantic.com',
        mbi: '2TV5X02AU95',
        addressLine1: '81957 Hanover Center',
        addressLine2: null,
        city: 'Brooklyn',
        state: 'NY',
        zip: '39632',
        doNotCall: 'N',
        // primaryCarePhysician: 'Joeann Yousef',
        // planId: 'H29770-642-047',
        // eligible: 'Y',
        count: '837',
      },
      {
        firstName: 'Benoit',
        middleName: null,
        lastName: 'Ibeson',
        dob: '7/26/41',
        primaryPhone: '3526422642',
        secondaryPhone: null,
        email: 'tizkoveskiq5@arizona.edu',
        mbi: '9UC4C07YR45',
        addressLine1: '7525 Darwin Park',
        addressLine2: null,
        city: 'Conroe',
        state: 'TX',
        zip: '3352',
        doNotCall: 'N',
        // primaryCarePhysician: 'Tammara Izkoveski',
        // planId: 'H74855-145-643',
        // eligible: 'Y',
        count: '837',
      },
      {
        firstName: 'Elwin',
        middleName: null,
        lastName: 'Gedling',
        dob: '4/16/31',
        primaryPhone: '1919558644',
        secondaryPhone: null,
        email: 'cjanousop@microsoft.com',
        mbi: '8d85pt9td71',
        addressLine1: '935 Summit Court',
        addressLine2: 'Apt 469',
        city: 'Santa Clara',
        state: 'CA',
        zip: '36617',
        doNotCall: 'N',
        // primaryCarePhysician: 'Cherey Janous',
        // planId: 'H13546-469-221',
        // eligible: 'Y',
        count: '837',
      },
      {
        firstName: 'Bar',
        middleName: null,
        lastName: 'Arnley',
        dob: '2/15/37',
        primaryPhone: '1399702676',
        secondaryPhone: null,
        email: 'acarwithanmx@list-manage.com',
        mbi: '7E12RE8FE88',
        addressLine1: '19 Sheridan Point',
        addressLine2: null,
        city: 'Sacramento',
        state: 'CA',
        zip: '12418',
        doNotCall: 'N',
        // primaryCarePhysician: 'Archie Carwithan',
        // planId: 'H96030-022-283',
        // eligible: 'Y',
        count: '837',
      },
      {
        firstName: 'Pepe',
        middleName: null,
        lastName: 'Bourgourd',
        dob: '9/6/26',
        primaryPhone: '9657685313',
        secondaryPhone: null,
        email: 'bgaukrodgej2@marketwatch.com',
        mbi: '3PA4M15AV81',
        addressLine1: '33966 Miller Trail',
        addressLine2: '2nd Floor',
        city: 'Scranton',
        state: 'PA',
        zip: '75004',
        doNotCall: 'Y',
        // primaryCarePhysician: 'Bambie Gaukrodge',
        // planId: 'H27986-125-059',
        // eligible: 'Y',
        count: '837',
      },
      {
        firstName: 'Kessiah',
        middleName: null,
        lastName: 'Nassy',
        dob: '9/18/40',
        primaryPhone: null,
        secondaryPhone: null,
        email: 'cpetkovicas@furl.net',
        mbi: '4Q14GA8AX67',
        addressLine1: '18897 Ryan Park',
        addressLine2: null,
        city: 'Washington',
        state: 'DC',
        zip: '32017',
        doNotCall: 'N',
        // primaryCarePhysician: 'Corney Petkovic',
        // planId: 'H88428-750-246',
        // eligible: 'Y',
        count: '837',
      },
      {
        firstName: 'Hobey',
        middleName: null,
        lastName: 'Conrart',
        dob: '3/19/40',
        primaryPhone: '2411290794',
        secondaryPhone: null,
        email: 'cstenett4i@time.com',
        mbi: '9E52JA7HV84',
        addressLine1: '1 Hoepker Plaza',
        addressLine2: null,
        city: 'Albany',
        state: 'NY',
        zip: '42142',
        doNotCall: 'N',
        // primaryCarePhysician: 'Celestyna Stenett',
        // planId: 'H27111-813-970',
        // eligible: 'Y',
        count: '837',
      },
      {
        firstName: 'Lilly',
        middleName: null,
        lastName: 'Fenning',
        dob: '1/9/39',
        primaryPhone: '1010805339',
        secondaryPhone: null,
        email: null,
        mbi: '4D21RN7KF38',
        addressLine1: '9 Washington Trail',
        addressLine2: null,
        city: 'Pittsburgh',
        state: 'PA',
        zip: '40547',
        doNotCall: 'N',
        // primaryCarePhysician: 'Gordy Ibel',
        // planId: 'H85507-406-864',
        // eligible: 'Y',
        count: '837',
      },
      {
        firstName: 'Derward',
        middleName: null,
        lastName: 'Goldwater',
        dob: '10/31/46',
        primaryPhone: '3750579560',
        secondaryPhone: null,
        email: 'fbyforthl2@technorati.com',
        mbi: '5h19dg1hf88',
        addressLine1: '54 Gina Park',
        addressLine2: null,
        city: 'Seattle',
        state: 'WA',
        zip: '27711',
        doNotCall: 'N',
        // primaryCarePhysician: 'Faye Byforth',
        // planId: 'H36881-150-665',
        // eligible: 'Y',
        count: '837',
      },
      {
        firstName: 'Moises',
        middleName: null,
        lastName: 'Poser',
        dob: '12/5/37',
        primaryPhone: '1179601300',
        secondaryPhone: null,
        email: null,
        mbi: '1u32rq3ku44',
        addressLine1: '0376 Bobwhite Road',
        addressLine2: null,
        city: 'Washington',
        state: 'DC',
        zip: '44292',
        doNotCall: 'N',
        // primaryCarePhysician: 'Merrily Coultar',
        // planId: 'H19261-278-908',
        // eligible: 'Y',
        count: '837',
      },
      {
        firstName: 'Gayla',
        middleName: null,
        lastName: 'Conaghy',
        dob: '3/30/42',
        primaryPhone: '4683524361',
        secondaryPhone: null,
        email: 'valyonovoq@prnewswire.com',
        mbi: '3m42dr3nd22',
        addressLine1: '3200 Kensington Terrace',
        addressLine2: null,
        city: 'Miami',
        state: 'FL',
        zip: '88349',
        doNotCall: 'N',
        // primaryCarePhysician: 'Viole Alyonov',
        // planId: 'H98163-668-017',
        // eligible: 'Y',
        count: '837',
      },
      {
        firstName: 'Frayda',
        middleName: null,
        lastName: 'Marzelle',
        dob: '5/29/27',
        primaryPhone: '3950810210',
        secondaryPhone: null,
        email: 'gbinnelll7@free.fr',
        mbi: '6YQ1RF5WW83',
        addressLine1: '4 Mockingbird Point',
        addressLine2: null,
        city: 'Bethesda',
        state: 'MD',
        zip: '30828',
        doNotCall: 'N',
        // primaryCarePhysician: 'Genny Binnell',
        // planId: 'H89060-996-441',
        // eligible: 'Y',
        count: '837',
      },
      {
        firstName: 'Merline',
        middleName: null,
        lastName: 'McKillop',
        dob: '5/4/32',
        primaryPhone: '5704238897',
        secondaryPhone: null,
        email: 'dmatteinimu@ca.gov',
        mbi: '3e70pv3nr23',
        addressLine1: '766 Burning Wood Circle',
        addressLine2: null,
        city: 'Jacksonville',
        state: 'FL',
        zip: '76876',
        doNotCall: 'N',
        // primaryCarePhysician: 'Del Matteini',
        // planId: 'H97056-698-087',
        // eligible: 'Y',
        count: '837',
      },
    ],
  }
