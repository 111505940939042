import { styled } from '@mui/material/styles'
import TableSortLabel, {
  tableSortLabelClasses,
} from '@mui/material/TableSortLabel'

export const TCTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  [`&.${tableSortLabelClasses.root}`]: {
    '&.Mui-active': {
      color: theme.palette.common.white,
    },
    '& .MuiTableSortLabel-icon': {
      color: theme.palette.common.white,
    },
  },
}))
