import { FC } from 'react'
import { Box, SxProps, Theme, Typography } from '@mui/material'

export const mskFormLabelSx: SxProps<Theme> = {
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '25px',
  color: 'common.black',
  pb: '16px',
}

type LeftAlignedItemProps = {
  label: string
  value: string
  'data-testid'?: string
}

export const LeftAlignedItem: FC<LeftAlignedItemProps> = ({
  label,
  value,
  'data-testid': dataTestId = 'left_aligned_item',
}) => (
  <Box sx={{ display: 'flex', columnGap: '8px' }}>
    <Typography data-testid={`${dataTestId}_label`}>{label}:</Typography>
    <Typography sx={{ fontWeight: 'bold' }} data-testid={`${dataTestId}_value`}>
      {value}
    </Typography>
  </Box>
)

type TwoColumnsItemProps = {
  left: string
  right: string
  'data-testid'?: string
}

export const TwoColumnsItem: FC<TwoColumnsItemProps> = ({
  left,
  right,
  'data-testid': dataTestId = 'two_columns_item',
}) => (
  <Box
    sx={{ display: 'flex', justifyContent: 'start', columnGap: '25px' }}
    data-testid={dataTestId}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'start',
        flex: '0 0 140px',
      }}
    >
      <Typography
        color={'rgba(0, 0, 0, 0.6)'}
        data-testid={`${dataTestId}_left`}
      >
        {left}
      </Typography>
    </Box>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'start',
        flex: '1 1 140px',
      }}
    >
      <Typography
        sx={{ fontWeight: 'bold' }}
        data-testid={`${dataTestId}_right`}
      >
        {right}
      </Typography>
    </Box>
  </Box>
)

type QuestionItemProps = {
  question: string
  answer: string
  'data-testid'?: string
}

export const QuestionItem: FC<QuestionItemProps> = ({
  question,
  answer,
  'data-testid': dataTestId = 'question_item',
}) => (
  <Box data-testid={dataTestId}>
    <Box>
      <Typography
        color={'rgba(0, 0, 0, 0.6)'}
        data-testid={`${dataTestId}_question`}
      >
        {question}
      </Typography>
    </Box>
    <Box>
      <Typography
        sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}
        data-testid={`${dataTestId}_answer`}
      >
        {answer}
      </Typography>
    </Box>
  </Box>
)

type PathQuestionItemProps = {
  index: number
  question: string
  answer: string
  'data-testid'?: string
}

export const PathQuestionItem: FC<PathQuestionItemProps> = ({
  index,
  question,
  answer,
  'data-testid': dataTestId = 'path_question_item',
}) => (
  <QuestionItem
    question={`${index}. ${question}`}
    answer={answer}
    data-testid={dataTestId}
  />
)
