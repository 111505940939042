import { IconButton } from '@mui/material'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import HomeIcon from '@mui/icons-material/Home'

export const HomeButton: FC = () => {
  const navigate = useNavigate()

  const handleHomeClick = () => {
    navigate('/')
  }

  return (
    <IconButton onClick={handleHomeClick}>
      <HomeIcon />
    </IconButton>
  )
}
