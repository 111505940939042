import { useCallback } from 'react'
import {
  RolesAndPermissionsState,
  userRolesSelector,
  userTypeSelector,
  setUserRolesAction,
  setUserTypeAction,
  useAppDispatch,
  useAppSelector,
} from 'src/features/shared/infrastructure'

export const useRolesAndPermissionsStore = () => {
  const dispatch = useAppDispatch()

  const userRoles = useAppSelector(userRolesSelector)

  const userType = useAppSelector(userTypeSelector)

  const setUserRoles = useCallback(
    (roles: RolesAndPermissionsState['userRoles']) =>
      dispatch(setUserRolesAction(roles)),
    [dispatch]
  )

  const setUserType = useCallback(
    (userType: RolesAndPermissionsState['userType']) =>
      dispatch(setUserTypeAction(userType)),
    [dispatch]
  )

  return {
    userRoles,
    userType,
    setUserRoles,
    setUserType,
  }
}
