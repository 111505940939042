import {
  LoadMSKFormReturns,
  MSKAnswer,
  MSKQuestion,
} from 'src/features/msk/domain'
import {
  MSKFormState,
  MSKFormStateFieldName,
  MSKFormStateIntakeAssessment,
  MSKFormStateSelectedAction,
  MSKIntakeAssessmentDifficultActions,
  addMSKFormIntakeAssessmentAction,
  addMSKFormOpenToTryActionAction,
  addMSKFormPreviouslyTriedActionAction,
  addNewMSKFormErrorsAction,
  deleteMSKFormErrorsAction,
  deleteMSKFormIntakeAssessmentAction,
  deleteMSKFormIntakeAssessmentAnswerAction,
  deleteMSKFormOpenToTryActionAction,
  deleteMSKFormPreviouslyTriedActionAction,
  initMSKFormAction,
  mskFormAdditionalQuestionsSelector,
  mskFormCurrentConditionSelector,
  mskFormCurrentIntakeAssessmentIdxSelector,
  mskFormCurrentPathQuestionAnswerSelector,
  mskFormCurrentPathQuestionSelector,
  mskFormErrorsSelector,
  mskFormIntakeAssessmentsSelector,
  mskFormIsCurrentPathQuestionValidatedSelector,
  mskFormModalTitleSelector,
  mskFormPageSelector,
  mskFormPathQuestionsNextSelector,
  mskFormPatientIdSelector,
  mskFormProfileHeightFtSelector,
  mskFormProfileHeightInSelector,
  mskFormProfileWeightSelector,
  mskFormSelectedBotheredBodyPartsSelector,
  mskFormSelectorDidYouHaveARecentInjurySelector,
  mskFormSelectorDidYouSeeAPhysicianSelector,
  mskFormShowFullSummarySelector,
  mskFormShowHardstopWarningSelector,
  mskFormShowStepThreeFullSummarySelector,
  mskFormShowStepTwoFullSummarySelector,
  mskFormStepSelector,
  resetMSKFormAction,
  setMSKFormAdditionalQuestionsAction,
  setMSKFormBodyRegionToNoteAction,
  setMSKFormChronicConditionAction,
  setMSKFormCurrentConditionAction,
  setMSKFormCurrentFunctionLevelAction,
  setMSKFormCurrentIntakeAssessmentIdxAction,
  setMSKFormCurrentPainLevelAction,
  setMSKFormCurrentPathQuestionAction,
  setMSKFormCurrentPathQuestionAnswerAction,
  setMSKFormCurrentlyUsingOpioidsAction,
  setMSKFormDidYouHaveARecentInjuryAction,
  setMSKFormDidYouSeeAPhysicianAction,
  setMSKFormDifficultActionOtherTextAction,
  setMSKFormErrorsAction,
  setMSKFormIntakeAssessmentAction,
  setMSKFormIntakeAssessmentAnswerAction,
  setMSKFormIntakeAssessmentConfirmedAction,
  setMSKFormIntakeAssessmentHardstopAction,
  setMSKFormIntakeAssessmentRecommendedActionAction,
  setMSKFormIsCurrentPathQuestionValidatedAction,
  setMSKFormModalTitleAction,
  setMSKFormNextIntakeAssessmentIdxAction,
  setMSKFormOtherPreviouslyTriedActionTextAction,
  setMSKFormPageAction,
  setMSKFormPathQuestionsNextAction,
  setMSKFormPatientIdAction,
  setMSKFormPreviousIntakeAssessmentIdxAction,
  setMSKFormProfileHeightFtAction,
  setMSKFormProfileHeightInAction,
  setMSKFormProfileWeightAction,
  setMSKFormSelectedBotheredBodyPartsAction,
  setMSKFormSelectedDifficultActionsAction,
  setMSKFormShowFullSummaryAction,
  setMSKFormShowHardstopWarningAction,
  setMSKFormShowStepThreeFullSummaryAction,
  setMSKFormShowStepTwoFullSummaryAction,
  setMSKFormSkippedIntakeAssessmentAction,
  setMSKFormStepAction,
  setMSKFormTimeExperiencingSymptomsAction,
  useAppDispatch,
  useAppSelector,
} from 'src/features/shared/infrastructure'

type UseMSKFormStoreProps = {
  loadedMSKForm?: LoadMSKFormReturns
}

export const useMSKFormStore = ({
  loadedMSKForm,
}: UseMSKFormStoreProps = {}) => {
  const dispatch = useAppDispatch()

  const initMSKForm = (mskFormPatientId: MSKFormState['mskFormPatientId']) =>
    dispatch(initMSKFormAction(mskFormPatientId))

  const resetMSKForm = (mskFormPatientId?: MSKFormState['mskFormPatientId']) =>
    dispatch(resetMSKFormAction(mskFormPatientId || ''))

  const mskFormPatientId = useAppSelector(mskFormPatientIdSelector)

  const setMSKFormPatientId = (patientId: MSKFormState['mskFormPatientId']) =>
    dispatch(setMSKFormPatientIdAction(patientId))

  const modalTitle = useAppSelector(mskFormModalTitleSelector)

  const setModalTitle = (modalTitle: MSKFormState['modalTitle']) =>
    dispatch(setMSKFormModalTitleAction(modalTitle))

  const step = useAppSelector(mskFormStepSelector)

  const setStep = (step: MSKFormState['step']) =>
    dispatch(setMSKFormStepAction(step))

  const page = useAppSelector(mskFormPageSelector)

  const setPage = (page: MSKFormState['page']) =>
    dispatch(setMSKFormPageAction(page))

  const errors = useAppSelector(mskFormErrorsSelector)

  const setErrors = (errors: MSKFormState['errors']) =>
    dispatch(setMSKFormErrorsAction(errors))

  const deleteError = (
    errors: MSKFormStateFieldName | MSKFormStateFieldName[]
  ) => dispatch(deleteMSKFormErrorsAction(errors))

  const addNewErrors = (errors: MSKFormState['errors']) =>
    dispatch(addNewMSKFormErrorsAction(errors))

  const selectedBotheredBodyParts = useAppSelector(
    mskFormSelectedBotheredBodyPartsSelector
  )

  const setSelectedBotheredBodyParts = (
    selectedBotheredBodyParts: MSKFormState['selectedBotheredBodyParts']
  ) =>
    dispatch(
      setMSKFormSelectedBotheredBodyPartsAction(selectedBotheredBodyParts)
    )

  const setSelectedDifficultActions = (
    difficultAction: MSKIntakeAssessmentDifficultActions
  ) => dispatch(setMSKFormSelectedDifficultActionsAction(difficultAction))

  const setDifficultActionOtherText = (difficultActionOtherText: string) =>
    dispatch(setMSKFormDifficultActionOtherTextAction(difficultActionOtherText))

  const didYouHaveARecentInjury = useAppSelector(
    mskFormSelectorDidYouHaveARecentInjurySelector
  )

  const setMSKFormDidYouHaveARecentInjury = (
    didYouHaveARecentInjury: MSKFormState['didYouHaveARecentInjury']
  ) =>
    dispatch(setMSKFormDidYouHaveARecentInjuryAction(didYouHaveARecentInjury))

  const didYouSeeAPhysician = useAppSelector(
    mskFormSelectorDidYouSeeAPhysicianSelector
  )

  const setMSKFormDidYouSeeAPhysician = (
    didYouSeeAPhysician: MSKFormState['didYouSeeAPhysician']
  ) => dispatch(setMSKFormDidYouSeeAPhysicianAction(didYouSeeAPhysician))

  const setTimeExperiencingSymptoms = (
    timeExperiencingSymptoms: MSKFormStateIntakeAssessment['timeExperiencingSymptoms']
  ) =>
    dispatch(setMSKFormTimeExperiencingSymptomsAction(timeExperiencingSymptoms))

  const setChronicCondition = (
    chronicCondition: MSKFormStateIntakeAssessment['chronicCondition']
  ) => dispatch(setMSKFormChronicConditionAction(chronicCondition))

  const profileHeightFt = useAppSelector(mskFormProfileHeightFtSelector)

  const setProfileHeightFt = (
    profileHeightFt: MSKFormState['profileHeightFt']
  ) => dispatch(setMSKFormProfileHeightFtAction(profileHeightFt))

  const profileHeightIn = useAppSelector(mskFormProfileHeightInSelector)

  const setProfileHeightIn = (
    profileHeightIn: MSKFormState['profileHeightIn']
  ) => dispatch(setMSKFormProfileHeightInAction(profileHeightIn))

  const profileWeight = useAppSelector(mskFormProfileWeightSelector)

  const setProfileWeight = (profileWeight: MSKFormState['profileWeight']) =>
    dispatch(setMSKFormProfileWeightAction(profileWeight))

  const currentCondition = useAppSelector(mskFormCurrentConditionSelector)

  const setCurrentCondition = (
    currentCondition: MSKFormState['currentCondition']
  ) => dispatch(setMSKFormCurrentConditionAction(currentCondition))

  const intakeAssessments = useAppSelector(mskFormIntakeAssessmentsSelector)

  const setIntakeAssessments = () => {
    if (!loadedMSKForm) {
      throw new Error('loadedMSKForm is undefined')
    }
    // Delete intakeAssessments that are not in selectedBotheredBodyParts
    const selectedBotheredBodyPartsIdList = selectedBotheredBodyParts.map(
      (selectedBotheredBodyPart) => selectedBotheredBodyPart.id
    )
    intakeAssessments.forEach((intakeAssessment) => {
      const { botheredBodyPart } = intakeAssessment
      if (!selectedBotheredBodyPartsIdList.includes(botheredBodyPart.id)) {
        deleteIntakeAssessment(intakeAssessment)
      }
    })

    // Add intakeAssessments that are in selectedBotheredBodyParts but not in intakeAssessments
    selectedBotheredBodyParts.forEach((selectedBotheredBodyPart) => {
      const intakeAssessment = intakeAssessments.find(
        (intakeAssessment) =>
          intakeAssessment.botheredBodyPart.id === selectedBotheredBodyPart.id
      )
      if (!intakeAssessment) {
        addIntakeAssessment({
          actionsOpenToTry: [],
          answers: {},
          botheredBodyPart: selectedBotheredBodyPart,
          bodyRegionToNote: [],
          confirmed: false,
          currentFunctionLevel: 5,
          currentPainLevel: 5,
          difficultAction: [],
          difficultActionOtherText: '',
          hardstop: false,
          previouslyTriedActions: [],
          questionHistory: [],
          recommendedAction: null,
          startQuestionId:
            loadedMSKForm.questionsAndActions.start[
              selectedBotheredBodyPart.id
            ],
          timeExperiencingSymptoms: '0-2 weeks',
          chronicCondition: false,
          skipped: false,
        })
      }
    })
  }

  const addIntakeAssessment = (
    intakeAssessment: MSKFormStateIntakeAssessment
  ) => dispatch(addMSKFormIntakeAssessmentAction(intakeAssessment))

  const deleteIntakeAssessment = (
    intakeAssessment: MSKFormStateIntakeAssessment
  ) => dispatch(deleteMSKFormIntakeAssessmentAction(intakeAssessment))

  const setIntakeAssessment = (
    intakeAssessment: MSKFormStateIntakeAssessment
  ) => {
    dispatch(setMSKFormIntakeAssessmentAction(intakeAssessment))
  }

  const addPreviouslyTriedAction = (
    selectedAction: MSKFormStateSelectedAction
  ) => {
    dispatch(addMSKFormPreviouslyTriedActionAction(selectedAction))
  }

  const deletePreviouslyTriedAction = (
    selectedAction: MSKFormStateSelectedAction
  ) => {
    dispatch(deleteMSKFormPreviouslyTriedActionAction(selectedAction))
  }

  const setCurrentlyUsingOpioids = (
    currentlyUsingOpioids: MSKFormStateIntakeAssessment['currentlyUsingOpioids']
  ) => {
    dispatch(setMSKFormCurrentlyUsingOpioidsAction(currentlyUsingOpioids))
  }

  const setOtherPreviouslyTriedActionText = (
    otherPreviouslyTriedActionText: MSKFormStateIntakeAssessment['otherPreviouslyTriedActionText']
  ) => {
    dispatch(
      setMSKFormOtherPreviouslyTriedActionTextAction(
        otherPreviouslyTriedActionText
      )
    )
  }

  const addOpenToTryAction = (selectedAction: MSKFormStateSelectedAction) => {
    dispatch(addMSKFormOpenToTryActionAction(selectedAction))
  }

  const deleteOpenToTryAction = (
    selectedAction: MSKFormStateSelectedAction
  ) => {
    dispatch(deleteMSKFormOpenToTryActionAction(selectedAction))
  }

  const setCurrentPainLevel = (
    painLevel: MSKFormStateIntakeAssessment['currentPainLevel']
  ) => {
    dispatch(setMSKFormCurrentPainLevelAction(painLevel))
  }

  const setCurrentFunctionLevel = (
    functionLevel: MSKFormStateIntakeAssessment['currentFunctionLevel']
  ) => {
    dispatch(setMSKFormCurrentFunctionLevelAction(functionLevel))
  }

  const setBodyRegionToNote = (
    bodyRegionToNote: MSKFormStateIntakeAssessment['bodyRegionToNote']
  ) => {
    dispatch(setMSKFormBodyRegionToNoteAction(bodyRegionToNote))
  }

  const setCurrentIntakeAssessmentAnswer = (
    question: MSKQuestion,
    answer: MSKAnswer
  ) => {
    dispatch(
      setMSKFormIntakeAssessmentAnswerAction({
        question: question,
        answer: answer,
      })
    )
  }

  const deleteCurrentIntakeAssesmentAnswer = (question: MSKQuestion) => {
    dispatch(deleteMSKFormIntakeAssessmentAnswerAction(question))
  }

  const setCurrentIntakeAssessmentRecommendedAction = (
    action: MSKFormStateIntakeAssessment['recommendedAction']
  ) => {
    dispatch(setMSKFormIntakeAssessmentRecommendedActionAction(action))
  }

  const setCurrentIntakeAssessmentConfirmed = (
    confirmed: MSKFormStateIntakeAssessment['confirmed']
  ) => {
    dispatch(setMSKFormIntakeAssessmentConfirmedAction(confirmed))
  }

  const setCurrentIntakeAssessmentHardstop = (
    hardstop: MSKFormStateIntakeAssessment['hardstop']
  ) => {
    dispatch(setMSKFormIntakeAssessmentHardstopAction(hardstop))
  }

  const currentIntakeAssessmentIdx = useAppSelector(
    mskFormCurrentIntakeAssessmentIdxSelector
  )

  const setCurrentIntakeAssessmentIdx = (
    idx: MSKFormState['currentIntakeAssessmentIdx']
  ) => {
    dispatch(setMSKFormCurrentIntakeAssessmentIdxAction(idx))
  }

  const setNextIntakeAssessmentIdx = () => {
    dispatch(setMSKFormNextIntakeAssessmentIdxAction())
  }

  const setPreviousIntakeAssessmentIdx = () => {
    dispatch(setMSKFormPreviousIntakeAssessmentIdxAction())
  }

  const showStepTwoFullSummary = useAppSelector(
    mskFormShowStepTwoFullSummarySelector
  )

  const setShowStepTwoFullSummary = (
    show: MSKFormState['showStepTwoFullSummary']
  ) => {
    dispatch(setMSKFormShowStepTwoFullSummaryAction(show))
  }

  const showStepThreeFullSummary = useAppSelector(
    mskFormShowStepThreeFullSummarySelector
  )

  const setShowStepThreeFullSummary = (
    show: MSKFormState['showStepThreeFullSummary']
  ) => {
    dispatch(setMSKFormShowStepThreeFullSummaryAction(show))
  }

  const showFullSummary = useAppSelector(mskFormShowFullSummarySelector)

  const setShowFullSummary = (show: MSKFormState['showFullSummary']) => {
    dispatch(setMSKFormShowFullSummaryAction(show))
  }

  const showHardstopWarning = useAppSelector(mskFormShowHardstopWarningSelector)

  const setShowHardstopWarning = (
    show: MSKFormState['showHardstopWarning']
  ) => {
    dispatch(setMSKFormShowHardstopWarningAction(show))
  }

  const currentPathQuestion = useAppSelector(mskFormCurrentPathQuestionSelector)

  const setCurrentPathQuestion = (
    question: MSKFormState['currentPathQuestion']
  ) => {
    dispatch(setMSKFormCurrentPathQuestionAction(question))
  }

  const currentPathQuestionAnswer = useAppSelector(
    mskFormCurrentPathQuestionAnswerSelector
  )

  const setCurrentPathQuestionAnswer = (
    answer: MSKFormState['currentPathQuestionAnswer']
  ) => {
    dispatch(setMSKFormCurrentPathQuestionAnswerAction(answer))
  }

  const pathQuestionsNext = useAppSelector(mskFormPathQuestionsNextSelector)

  const setPathQuestionsNext = (next: MSKFormState['pathQuestionsNext']) => {
    dispatch(setMSKFormPathQuestionsNextAction(next))
  }

  const isCurrentPathQuestionValidated = useAppSelector(
    mskFormIsCurrentPathQuestionValidatedSelector
  )

  const setIsCurrentPathQuestionValidated = (
    validated: MSKFormState['isCurrentPathQuestionValidated']
  ) => {
    dispatch(setMSKFormIsCurrentPathQuestionValidatedAction(validated))
  }

  const additionalQuestions = useAppSelector(mskFormAdditionalQuestionsSelector)

  const setAdditionalQuestions = (
    questions: Partial<MSKFormState['additionalQuestions']>
  ) => {
    dispatch(setMSKFormAdditionalQuestionsAction(questions))
  }

  const confirmAdditionalQuestions = () => {
    dispatch(setMSKFormAdditionalQuestionsAction({ confirmed: true }))
  }

  const unconfirmAdditionalQuestions = () => {
    dispatch(setMSKFormAdditionalQuestionsAction({ confirmed: false }))
  }

  const setSkippedIntakeAssessment = (
    skipped: MSKFormStateIntakeAssessment['skipped']
  ) => {
    dispatch(setMSKFormSkippedIntakeAssessmentAction(skipped))
  }

  return {
    addNewErrors,
    addOpenToTryAction,
    addPreviouslyTriedAction,
    additionalQuestions,
    confirmAdditionalQuestions,
    currentCondition,
    currentIntakeAssessmentIdx,
    currentPathQuestion,
    currentPathQuestionAnswer,
    deleteCurrentIntakeAssesmentAnswer,
    deleteError,
    deleteOpenToTryAction,
    deletePreviouslyTriedAction,
    didYouHaveARecentInjury,
    didYouSeeAPhysician,
    errors,
    initMSKForm,
    intakeAssessments,
    isCurrentPathQuestionValidated,
    modalTitle,
    mskFormPatientId,
    page,
    pathQuestionsNext,
    profileHeightFt,
    profileHeightIn,
    profileWeight,
    resetMSKForm,
    selectedBotheredBodyParts,
    setAdditionalQuestions,
    setBodyRegionToNote,
    setChronicCondition,
    setCurrentCondition,
    setCurrentFunctionLevel,
    setCurrentIntakeAssessmentAnswer,
    setCurrentIntakeAssessmentConfirmed,
    setCurrentIntakeAssessmentHardstop,
    setCurrentIntakeAssessmentIdx,
    setCurrentIntakeAssessmentRecommendedAction,
    setCurrentPainLevel,
    setCurrentPathQuestion,
    setCurrentPathQuestionAnswer,
    setCurrentlyUsingOpioids,
    setDifficultActionOtherText,
    setErrors,
    setIntakeAssessment,
    setIntakeAssessments,
    setIsCurrentPathQuestionValidated,
    setMSKFormDidYouHaveARecentInjury,
    setMSKFormDidYouSeeAPhysician,
    setMSKFormPatientId,
    setModalTitle,
    setNextIntakeAssessmentIdx,
    setOtherPreviouslyTriedActionText,
    setPage,
    setPathQuestionsNext,
    setPreviousIntakeAssessmentIdx,
    setProfileHeightFt,
    setProfileHeightIn,
    setProfileWeight,
    setSelectedBotheredBodyParts,
    setSelectedDifficultActions,
    setShowFullSummary,
    setShowHardstopWarning,
    setShowStepThreeFullSummary,
    setShowStepTwoFullSummary,
    setSkippedIntakeAssessment,
    setStep,
    setTimeExperiencingSymptoms,
    showFullSummary,
    showHardstopWarning,
    showStepThreeFullSummary,
    showStepTwoFullSummary,
    step,
    unconfirmAdditionalQuestions,
  }
}
