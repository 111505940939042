import { useQuery } from '@tanstack/react-query'

import {
  GetTasksByPatientIdParams,
  GetTodayTasksByPatientIdError,
  GetTodayTasksByPatientIdReturns,
} from 'src/features/tasks/domain'
import { useTasksUseCases } from 'src/features/tasks/tasks.service-locator'
import { usePatientId } from 'src/features/shared/presentation'

export const GET_TODAY_TASKS_BY_PATIENT_ID_QUERY_KEY =
  'getTodayTasksByPatientId'

type UseGetTodayTasksByPatientIdProps = {
  patientId?: string
  throwGetPatientIdError?: boolean
  params?: GetTasksByPatientIdParams
}

export const useGetTodayTasksByPatientIdQuery = ({
  patientId,
  params,
  throwGetPatientIdError = true,
}: UseGetTodayTasksByPatientIdProps = {}) => {
  const taskUseCase = useTasksUseCases()

  // TODO: find a better way to get the patient id
  const _patientId = usePatientId({ patientId, throwGetPatientIdError })

  const { data, refetch, isLoading, isError, isFetching, isRefetching } =
    useQuery<GetTodayTasksByPatientIdReturns, GetTodayTasksByPatientIdError>(
      [GET_TODAY_TASKS_BY_PATIENT_ID_QUERY_KEY, _patientId],
      () => {
        return _patientId
          ? taskUseCase.getOverdueTasksByPatientId({
              patientId: _patientId,
              params,
            })
          : Promise.reject(
              new Error(
                'Patient ID is required as a parameter or in the url as path param.'
              )
            )
      }
    )

  return {
    patientTodayTasksData: data,
    patientTodayTasks: data?.tasks ? data.tasks : [],
    refetchPatientTodayTasks: refetch,
    patientTodayTasksIsLoading: isLoading,
    patientTodayTasksIsError: isError,
    patientTodayTasksIsFetching: isFetching,
    patientTodayTasksIsRefetching: isRefetching,
  }
}
