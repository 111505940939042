import { FC, useEffect } from 'react'
import { Box, TextField, Typography } from '@mui/material'
import { toast } from 'react-toastify'

import { PatientV2 } from 'src/features/patients/domain'
import { LoadMSKFormReturns } from 'src/features/msk/domain'
import {
  AgreeYouUnderstandCondition,
  AgreeYouUnderstandTreatmentOptions,
  MSKFormState,
  MSKFormStateAdditionalQuestionsFieldOptions,
  PhQ9ActionOptions,
  useMSKFormStore,
  useNotesStore,
  usePatientPageStore,
} from 'src/features/shared/infrastructure'
import {
  MSKFormPagination,
  MSKFormStepContainer,
  MSKFormStepTwoIntakeAssessmentSummary,
  QuestionItem,
  useProcessMSKForm,
} from 'src/features/msk/presentation'
import {
  mapMSKToUpdatePatientPayload,
  mapToProcessMSKFormArgs,
} from 'src/features/msk/adapters'
import { useUpdatePatient } from 'src/features/patients/presentation'
import {
  addressRegex,
  cityRegex,
  getMaskedPhone,
  nameRegex,
  phoneRegex,
  stateRegex,
  zipRegex,
} from 'src/features/shared/utils'

import { MSKFormStepThreePage1 } from './msk-form-step-three-page-1'
import { MSKFormStepThreePage2 } from './msk-form-step-three-page-2'
import { MSKFormStepThreePage3 } from './msk-form-step-three-page-3'
import {
  scrolToErrorElement,
  QUESTIONS_LABELS,
  isPhQ9ActionRequired,
} from './helpers'
import {
  HEALTH_EQUITY_LABELS,
  useCreateDraftNote,
  useGetDraftNote,
  useUpdateDraftNote,
} from 'src/features/notes/presentation'
import { useGetMarketsQuery } from 'src/features/providers/presentation/hooks/use-get-markets'
import { useGetPayorsQuery } from 'src/features/providers/presentation/hooks/use-get-payors'
import {
  mapToCreateDraftNoteArgs,
  mapToUpdateDraftNoteArgs,
} from 'src/features/notes/adapters'

export type AdditionalQuestionsSummaryProps = {
  agreeYouUnderstandConditionQuestion: string
  agreeYouUnderstandConditionAnswer: string
  agreeYouUnderstandTreatmentOptionsQuestion: string
  agreeYouUnderstandTreatmentOptionsAnswer: string
  phQ1Question: string
  phQ1Answer: string
  phQ2Question: string
  phQ2Answer: string
  phScore: string
  phAssessment: string
  phQ9ActionQuestion: string
  phQ9ActionAnswer: string
  gad2Q1Question: string
  gad2Q1Answer: string
  gad2Q2Question: string
  gad2Q2Answer: string
  gad2Score: string
  gad2Assessment: string
  bhAnswer: string
  fallScreenVersion?: string
  fallScreenQ1Question: string
  fallScreenQ1Answer: string
  fallScreenQ2Question: string
  fallScreenQ2Answer: string
  fallScreenResult: string
  fallScreenV2Q1Question: string
  fallScreenV2Q1Answer: string
  fallScreenV2Q2Question: string
  fallScreenV2Q2Answer: string
  fallScreenV2Q3Question: string
  fallScreenV2Q3Answer: string
  fallScreenV2Q4Question: string
  fallScreenV2Q4Answer: string
  fallScreenV2WhyNotQuestion: string
  fallScreenV2WhyNotAnswer: string
  fallScreenV2Result: string
  tabletComputerOrPhoneQuestion: string
  tabletComputerOrPhoneAnswer: string
  barriersToBeAwareOfQuestion: string
  barriersToBeAwareOfAnswer: string
  peopleAllowedToReachOutToQuestion: string
  peopleAllowedToReachOutToAnswer: string
  patientConsentForSmsQuestion: string
  patientConsentForSmsAnswer: string
  patientConsentForSmsAdditionalPhoneQuestion: string
  patientConsentForSmsAdditionalPhoneAnswer: string
  additionalPhoneQuestion: string
  additionalPhoneAnswer: string
}

export const AdditionalQuestionsSummary: FC<
  AdditionalQuestionsSummaryProps
> = ({
  agreeYouUnderstandConditionQuestion,
  agreeYouUnderstandConditionAnswer,
  agreeYouUnderstandTreatmentOptionsQuestion,
  agreeYouUnderstandTreatmentOptionsAnswer,
  phQ1Question,
  phQ1Answer,
  phQ2Question,
  phQ2Answer,
  phScore,
  phAssessment,
  phQ9ActionQuestion,
  phQ9ActionAnswer,
  gad2Q1Question,
  gad2Q1Answer,
  gad2Q2Question,
  gad2Q2Answer,
  gad2Score,
  gad2Assessment,
  bhAnswer,
  fallScreenQ1Question,
  fallScreenQ1Answer,
  fallScreenQ2Question,
  fallScreenQ2Answer,
  fallScreenResult,
  fallScreenV2Q1Answer,
  fallScreenV2Q1Question,
  fallScreenV2Q2Answer,
  fallScreenV2Q2Question,
  fallScreenV2Q3Answer,
  fallScreenV2Q3Question,
  fallScreenV2Q4Answer,
  fallScreenV2Q4Question,
  fallScreenV2WhyNotAnswer,
  fallScreenV2WhyNotQuestion,
  fallScreenV2Result,
  tabletComputerOrPhoneQuestion,
  tabletComputerOrPhoneAnswer,
  barriersToBeAwareOfQuestion,
  barriersToBeAwareOfAnswer,
  peopleAllowedToReachOutToQuestion,
  peopleAllowedToReachOutToAnswer,
  additionalPhoneAnswer,
  additionalPhoneQuestion,
  patientConsentForSmsAnswer,
  patientConsentForSmsQuestion,
  patientConsentForSmsAdditionalPhoneQuestion,
  patientConsentForSmsAdditionalPhoneAnswer,
  fallScreenVersion = 'v2',
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '32px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '8px' }}>
        <Box>
          <QuestionItem question={phQ1Question} answer={phQ1Answer} />

          <QuestionItem question={phQ2Question} answer={phQ2Answer} />

          <QuestionItem
            question={phQ9ActionQuestion}
            answer={phQ9ActionAnswer}
          />

          <Box
            sx={{
              display: 'flex',
              columnGap: '16px',
              justifyContent: 'flex-end',
            }}
          >
            <TextField
              label={'PHQ2 Score'}
              value={phScore}
              size={'small'}
              disabled={true}
              sx={{
                width: '124px',
              }}
            />
            <TextField
              label={'PHQ2 Assessment'}
              value={phAssessment}
              size={'small'}
              disabled={true}
              sx={{
                width: '132px',
              }}
            />
          </Box>
        </Box>

        <Box>
          <QuestionItem question={gad2Q1Question} answer={gad2Q1Answer} />

          <QuestionItem question={gad2Q2Question} answer={gad2Q2Answer} />

          <Box
            sx={{
              display: 'flex',
              columnGap: '16px',
              justifyContent: 'flex-end',
            }}
          >
            <TextField
              label={'GAD2 Score'}
              value={gad2Score}
              size={'small'}
              disabled={true}
              sx={{
                width: '124px',
              }}
            />
            <TextField
              label={'GAD2 Assessment'}
              value={gad2Assessment}
              size={'small'}
              disabled={true}
              sx={{
                width: '140px',
              }}
            />
          </Box>
        </Box>
      </Box>

      <Box>
        <QuestionItem
          question={'Behavioral Health Action:'}
          answer={bhAnswer}
        />
      </Box>

      <Box>
        {fallScreenVersion === 'v2' ? (
          <>
            <QuestionItem
              question={fallScreenV2Q1Question}
              answer={fallScreenV2Q1Answer}
            />

            <QuestionItem
              question={fallScreenV2Q2Question}
              answer={fallScreenV2Q2Answer}
            />

            <QuestionItem
              question={fallScreenV2Q3Question}
              answer={fallScreenV2Q3Answer}
            />

            <QuestionItem
              question={'Fall Risk Assessment'}
              answer={fallScreenV2Result}
            />

            <QuestionItem
              question={fallScreenV2Q4Question}
              answer={fallScreenV2Q4Answer}
            />

            <QuestionItem
              question={fallScreenV2WhyNotQuestion}
              answer={fallScreenV2WhyNotAnswer}
            />
          </>
        ) : (
          <>
            <QuestionItem
              question={fallScreenQ1Question}
              answer={fallScreenQ1Answer}
            />

            <QuestionItem
              question={fallScreenQ2Question}
              answer={fallScreenQ2Answer}
            />

            <Box
              sx={{
                display: 'flex',
                columnGap: '16px',
                justifyContent: 'flex-end',
              }}
            >
              <TextField
                label={'Fall Risk Assessment'}
                value={fallScreenResult}
                size={'small'}
                disabled={true}
                sx={{
                  width: '140px',
                }}
              />
            </Box>
          </>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '16px',
        }}
      >
        <Box>
          <Typography color={'rgba(0, 0, 0, 0.6)'}>
            Other Verifications:
          </Typography>
        </Box>
        <Box>
          <QuestionItem
            question={tabletComputerOrPhoneQuestion}
            answer={tabletComputerOrPhoneAnswer}
          />

          <QuestionItem
            question={barriersToBeAwareOfQuestion}
            answer={barriersToBeAwareOfAnswer}
          />

          <QuestionItem
            question={patientConsentForSmsQuestion}
            answer={patientConsentForSmsAnswer}
          />

          <QuestionItem
            question={additionalPhoneQuestion}
            answer={additionalPhoneAnswer}
          />

          <QuestionItem
            question={patientConsentForSmsAdditionalPhoneQuestion}
            answer={patientConsentForSmsAdditionalPhoneAnswer}
          />
          <QuestionItem
            question={peopleAllowedToReachOutToQuestion}
            answer={peopleAllowedToReachOutToAnswer}
          />
          <QuestionItem
            question={agreeYouUnderstandConditionQuestion}
            answer={agreeYouUnderstandConditionAnswer}
          />

          <QuestionItem
            question={agreeYouUnderstandTreatmentOptionsQuestion}
            answer={agreeYouUnderstandTreatmentOptionsAnswer}
          />
        </Box>
      </Box>
    </Box>
  )
}

const MSKFormStepThreeSummary: FC = () => {
  const { additionalQuestions } = useMSKFormStore()
  const getPhScore = () => {
    if (additionalQuestions.phScore === null) {
      return 'N/A'
    }
    return `${additionalQuestions.phScore}`
  }

  const getPhQ9ActionAnswer = (answer: PhQ9ActionOptions) => {
    if (answer === 'Select an option') {
      return 'Not answered'
    }
    return answer
  }

  const getGad2Score = () => {
    if (additionalQuestions.gad2Score === null) {
      return 'N/A'
    }
    return `${additionalQuestions.gad2Score}`
  }

  const getV1Answer = (
    answer: MSKFormStateAdditionalQuestionsFieldOptions['v1']
  ) => {
    if (answer === 'Select an option') {
      return 'Not answered'
    }
    return answer
  }

  const getV2Answer = (
    answer: MSKFormStateAdditionalQuestionsFieldOptions['v2']
  ) => {
    if (answer === 'Select an option') {
      return 'Not answered'
    }
    return answer === 'Y' ? 'Yes' : 'No'
  }

  const getBhAnswer = (
    bhAction1: MSKFormState['additionalQuestions']['bhAction1'],
    bhAction2: MSKFormState['additionalQuestions']['bhAction2']
  ) => {
    let result = bhAction1 === 'Y' ? 'Yes' : 'No'

    if (bhAction2 !== 'Select an option') {
      result += ` / ${bhAction2}`
    }

    return result
  }

  const getBarriersToBeAwareOfAnswer = (
    barriersToBeAwareOf: MSKFormState['additionalQuestions']['barriersToBeAwareOf'],
    barriersToBeAwareOfText: MSKFormState['additionalQuestions']['barriersToBeAwareOfText']
  ) => {
    if (barriersToBeAwareOf === 'Select an option') {
      return 'Not answered'
    }
    if (barriersToBeAwareOf !== 'other') {
      return barriersToBeAwareOf
    }
    return `${
      barriersToBeAwareOf.charAt(0).toUpperCase() + barriersToBeAwareOf.slice(1)
    }: ${barriersToBeAwareOfText}`
  }

  const getPeopleAllowedToReachOutToAnswer = (
    peopleAllowedToReachOutToWho: MSKFormState['additionalQuestions']['peopleAllowedToReachOutToWho'],
    peopleAllowedToReachOutToPhone: MSKFormState['additionalQuestions']['peopleAllowedToReachOutToPhone']
  ) => {
    const result = []

    if (peopleAllowedToReachOutToWho) {
      result.push(peopleAllowedToReachOutToWho)
    }

    if (peopleAllowedToReachOutToPhone) {
      result.push(getMaskedPhone(peopleAllowedToReachOutToPhone))
    }

    if (result.length === 0) {
      return 'Not answered'
    }

    return result.join(' - ')
  }

  const getPcpAnswer = (answer: string) => {
    if (!answer) {
      return 'Not answered'
    }
    return answer
  }

  const getAgreeYouUnderstandConditionAnswer = (
    answer: AgreeYouUnderstandCondition
  ) => {
    if (answer === 'Select an option') {
      return 'Not answered'
    }
    return answer
  }

  const getAgreeYouUnderstandTreatmentOptionsAnswer = (
    answer: AgreeYouUnderstandTreatmentOptions
  ) => {
    if (answer === 'Select an option') {
      return 'Not answered'
    }
    return answer
  }

  const getAdditionalPhoneAnswer = (answer: string) => {
    if (!answer) {
      return 'Not answered'
    }
    return getMaskedPhone(answer)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '32px',
      }}
    >
      <Typography
        variant={'h4'}
        sx={{
          fontSize: '24px',
          color: '#40787B',
        }}
      >
        Additional Intake Assessment Question Summary
      </Typography>
      <AdditionalQuestionsSummary
        agreeYouUnderstandConditionQuestion={
          HEALTH_EQUITY_LABELS.agreeYouUnderstandCondition
        }
        agreeYouUnderstandConditionAnswer={getAgreeYouUnderstandConditionAnswer(
          additionalQuestions.agreeYouUnderstandCondition
        )}
        agreeYouUnderstandTreatmentOptionsQuestion={
          HEALTH_EQUITY_LABELS.agreeYouUnderstandTreatmentOptions
        }
        agreeYouUnderstandTreatmentOptionsAnswer={getAgreeYouUnderstandTreatmentOptionsAnswer(
          additionalQuestions.agreeYouUnderstandTreatmentOptions
        )}
        phQ1Question={QUESTIONS_LABELS.phQ1}
        phQ1Answer={getV1Answer(additionalQuestions.phQ1)}
        phQ2Question={QUESTIONS_LABELS.phQ2}
        phQ2Answer={getV1Answer(additionalQuestions.phQ2)}
        phScore={getPhScore()}
        phQ9ActionQuestion={QUESTIONS_LABELS.phQ9Action}
        phQ9ActionAnswer={getPhQ9ActionAnswer(additionalQuestions.phQ9Action)}
        phAssessment={additionalQuestions.phAssessment}
        gad2Q1Question={QUESTIONS_LABELS.gad2Q1}
        gad2Q1Answer={getV1Answer(additionalQuestions.gad2Q1)}
        gad2Q2Question={QUESTIONS_LABELS.gad2Q2}
        gad2Q2Answer={getV1Answer(additionalQuestions.gad2Q2)}
        gad2Score={getGad2Score()}
        gad2Assessment={additionalQuestions.gad2Assessment}
        bhAnswer={getBhAnswer(
          additionalQuestions.bhAction1,
          additionalQuestions.bhAction2
        )}
        fallScreenVersion={additionalQuestions.fallScreenVersion}
        fallScreenQ1Question={QUESTIONS_LABELS.fallScreenQ1}
        fallScreenQ1Answer={getV2Answer(additionalQuestions.fallScreenQ1)}
        fallScreenQ2Question={QUESTIONS_LABELS.fallScreenQ2}
        fallScreenQ2Answer={getV2Answer(additionalQuestions.fallScreenQ2)}
        fallScreenResult={additionalQuestions.fallScreenResult}
        fallScreenV2Q1Question={QUESTIONS_LABELS.fallScreenV2Q1}
        fallScreenV2Q1Answer={getV2Answer(additionalQuestions.fallScreenV2Q1)}
        fallScreenV2Q2Question={QUESTIONS_LABELS.fallScreenV2Q2}
        fallScreenV2Q2Answer={getV2Answer(additionalQuestions.fallScreenV2Q2)}
        fallScreenV2Q3Question={QUESTIONS_LABELS.fallScreenV2Q3}
        fallScreenV2Q3Answer={getV2Answer(additionalQuestions.fallScreenV2Q3)}
        fallScreenV2Q4Question={QUESTIONS_LABELS.fallScreenV2Q4}
        fallScreenV2Q4Answer={getV2Answer(additionalQuestions.fallScreenV2Q4)}
        fallScreenV2WhyNotQuestion={QUESTIONS_LABELS.fallScreenV2WhyNot}
        fallScreenV2WhyNotAnswer={additionalQuestions.fallScreenV2WhyNot}
        fallScreenV2Result={additionalQuestions.fallScreenV2Result}
        tabletComputerOrPhoneQuestion={QUESTIONS_LABELS.tabletComputerOrPhone}
        tabletComputerOrPhoneAnswer={getV2Answer(
          additionalQuestions.tabletComputerOrPhone
        )}
        barriersToBeAwareOfQuestion={QUESTIONS_LABELS.barriersToBeAwareOf}
        barriersToBeAwareOfAnswer={getBarriersToBeAwareOfAnswer(
          additionalQuestions.barriersToBeAwareOf,
          additionalQuestions.barriersToBeAwareOfText
        )}
        peopleAllowedToReachOutToQuestion={
          QUESTIONS_LABELS.peopleAllowedToReachOutTo
        }
        peopleAllowedToReachOutToAnswer={getPeopleAllowedToReachOutToAnswer(
          additionalQuestions.peopleAllowedToReachOutToWho,
          additionalQuestions.peopleAllowedToReachOutToPhone
        )}
        patientConsentForSmsQuestion={'Patient Consent for SMS Main Phone'}
        patientConsentForSmsAnswer={getV2Answer(
          additionalQuestions.patientConsentForSms
        )}
        patientConsentForSmsAdditionalPhoneQuestion={
          'Patient Consent for SMS Additional Phone'
        }
        patientConsentForSmsAdditionalPhoneAnswer={getV2Answer(
          additionalQuestions.patientConsentForSmsAdditionalPhone
        )}
        additionalPhoneQuestion={QUESTIONS_LABELS.additionalPhone}
        additionalPhoneAnswer={getAdditionalPhoneAnswer(
          additionalQuestions.additionalPhone
        )}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '16px',
        }}
      >
        <Box>
          <Typography color={'rgba(0, 0, 0, 0.6)'}>PCP Information:</Typography>
        </Box>
        <Box>
          <QuestionItem
            question={QUESTIONS_LABELS.pcpName}
            answer={getPcpAnswer(additionalQuestions.pcpName)}
          />
          <QuestionItem
            question={QUESTIONS_LABELS.pcpPhone}
            answer={getPcpAnswer(additionalQuestions.pcpPhone)}
          />
          <QuestionItem
            question={QUESTIONS_LABELS.pcpFax}
            answer={getPcpAnswer(additionalQuestions.pcpFax)}
          />
          <QuestionItem
            question={QUESTIONS_LABELS.pcpGroupName}
            answer={getPcpAnswer(additionalQuestions.pcpGroupName)}
          />
          <QuestionItem
            question={QUESTIONS_LABELS.pcpAddressLine1}
            answer={getPcpAnswer(additionalQuestions.pcpAddressLine1)}
          />
          <QuestionItem
            question={QUESTIONS_LABELS.pcpAddressLine2}
            answer={getPcpAnswer(additionalQuestions.pcpAddressLine2)}
          />
          <QuestionItem
            question={QUESTIONS_LABELS.pcpCity}
            answer={getPcpAnswer(additionalQuestions.pcpCity)}
          />
          <QuestionItem
            question={QUESTIONS_LABELS.pcpState}
            answer={getPcpAnswer(additionalQuestions.pcpState)}
          />
          <QuestionItem
            question={QUESTIONS_LABELS.pcpZip}
            answer={getPcpAnswer(additionalQuestions.pcpZip)}
          />
        </Box>
      </Box>
    </Box>
  )
}

type UseValidateMSKFormStepThreeProps = {
  loadedMSKForm: LoadMSKFormReturns
}

export const useValidateMSKFormStepThree = ({
  loadedMSKForm,
}: UseValidateMSKFormStepThreeProps) => {
  const { additionalQuestions, addNewErrors } = useMSKFormStore({
    loadedMSKForm,
  })

  const validateAdditionalPhone = () => {
    if (
      additionalQuestions.additionalPhone.length &&
      !phoneRegex.test(additionalQuestions.additionalPhone)
    ) {
      addNewErrors({ additionalPhone: 'Invalid phone number' })
      return false
    }
    return true
  }

  const validateBarriersToBeAwareOfText = () => {
    if (
      additionalQuestions.barriersToBeAwareOf === 'other' &&
      !additionalQuestions.barriersToBeAwareOfText
    ) {
      addNewErrors({
        barriersToBeAwareOfText: 'Required',
      })
      return false
    }
    return true
  }

  const validatePeopleAllowedToReachOutToPhone = () => {
    if (
      additionalQuestions.peopleAllowedToReachOutToWho.length &&
      !additionalQuestions.peopleAllowedToReachOutToPhone.length
    ) {
      addNewErrors({
        peopleAllowedToReachOutToPhone: 'Required',
      })
      return false
    }
    if (
      additionalQuestions.peopleAllowedToReachOutToPhone.length &&
      !phoneRegex.test(additionalQuestions.peopleAllowedToReachOutToPhone)
    ) {
      addNewErrors({
        peopleAllowedToReachOutToPhone: 'Invalid phone number',
      })
      return false
    }
    return true
  }

  const validatePeopleAllowedToReachOutToWho = () => {
    if (
      additionalQuestions.peopleAllowedToReachOutToPhone.length &&
      !additionalQuestions.peopleAllowedToReachOutToWho.length
    ) {
      addNewErrors({
        peopleAllowedToReachOutToWho: 'Required',
      })
      return false
    }
    return true
  }

  const validatePcpName = () => {
    if (
      additionalQuestions.pcpName.length &&
      !nameRegex.test(additionalQuestions.pcpName)
    ) {
      addNewErrors({
        pcpName: 'Invalid name',
      })
    }
    return true
  }

  const validatePcpPhone = () => {
    if (
      additionalQuestions.pcpPhone.length &&
      !phoneRegex.test(additionalQuestions.pcpPhone)
    ) {
      addNewErrors({
        pcpPhone: 'Invalid phone number',
      })
      return false
    }
    return true
  }

  const validatePcpFax = () => {
    if (
      additionalQuestions.pcpFax.length &&
      !phoneRegex.test(additionalQuestions.pcpFax)
    ) {
      addNewErrors({
        pcpFax: 'Invalid fax number',
      })
      return false
    }
    return true
  }

  const validatePcpAddressLine1 = () => {
    if (
      additionalQuestions.pcpAddressLine1.length &&
      !addressRegex.test(additionalQuestions.pcpAddressLine1)
    ) {
      addNewErrors({
        pcpAddressLine1: 'Invalid address',
      })
      return false
    }
    return true
  }

  const validatePcpAddressLine2 = () => {
    if (
      additionalQuestions.pcpAddressLine2.length &&
      !addressRegex.test(additionalQuestions.pcpAddressLine2)
    ) {
      addNewErrors({
        pcpAddressLine2: 'Invalid address',
      })
      return false
    }
    return true
  }

  const validatePcpCity = () => {
    if (
      additionalQuestions.pcpCity.length &&
      !cityRegex.test(additionalQuestions.pcpCity)
    ) {
      addNewErrors({
        pcpCity: 'Invalid city',
      })
      return false
    }
    return true
  }

  const validatePcpState = () => {
    if (
      additionalQuestions.pcpState.length &&
      !stateRegex.test(additionalQuestions.pcpState)
    ) {
      addNewErrors({
        pcpState: 'Invalid state',
      })
      return false
    }
    return true
  }

  const validatePcpZip = () => {
    if (
      additionalQuestions.pcpZip.length &&
      !zipRegex.test(additionalQuestions.pcpZip)
    ) {
      addNewErrors({
        pcpZip: 'Invalid zip code',
      })
      return false
    }
    return true
  }

  return {
    validateAdditionalPhone,
    validateBarriersToBeAwareOfText,
    validatePeopleAllowedToReachOutToPhone,
    validatePeopleAllowedToReachOutToWho,
    validatePcpName,
    validatePcpPhone,
    validatePcpFax,
    validatePcpAddressLine1,
    validatePcpAddressLine2,
    validatePcpCity,
    validatePcpState,
    validatePcpZip,
  }
}

type MSKFormStepThreeProps = {
  patient: PatientV2
  loadedMSKForm: LoadMSKFormReturns
}

export const MSKFormStepThree: FC<MSKFormStepThreeProps> = ({
  patient,
  loadedMSKForm,
}) => {
  const { setSelectedNoteType, noteText } = useNotesStore()
  const { setOpenMSKFormModal } = usePatientPageStore()
  const mskFormStore = useMSKFormStore({
    loadedMSKForm,
  })
  const { processMSKForm, processMSKFormIsLoading } = useProcessMSKForm()
  const { updatePatient, updatePatientIsLoading } = useUpdatePatient()
  const { createDraftNote, createDraftNoteIsLoading } = useCreateDraftNote()
  const { updateDraftNote, updateDraftNoteIsLoading } = useUpdateDraftNote()
  const { draftNote } = useGetDraftNote({
    patientId: patient.patientId,
  })
  const {
    addNewErrors,
    additionalQuestions,
    confirmAdditionalQuestions,
    intakeAssessments,
    page,
    resetMSKForm,
    setModalTitle,
    setPage,
    setShowFullSummary,
    setShowStepThreeFullSummary,
    setStep,
    showFullSummary,
    showStepThreeFullSummary,
    unconfirmAdditionalQuestions,
  } = mskFormStore

  const {
    validateAdditionalPhone,
    validateBarriersToBeAwareOfText,
    validatePeopleAllowedToReachOutToPhone,
    validatePeopleAllowedToReachOutToWho,
    validatePcpName,
    validatePcpPhone,
    validatePcpFax,
    validatePcpAddressLine1,
    validatePcpAddressLine2,
    validatePcpCity,
    validatePcpState,
    validatePcpZip,
  } = useValidateMSKFormStepThree({ loadedMSKForm })

  const { getPayorName } = useGetPayorsQuery()

  const { markets } = useGetMarketsQuery()
  const getMarketName = () => {
    const market = markets.find(
      (market) => market.id === patient.medicalProfile?.marketId
    )
    return market ? market.name : undefined
  }

  const showEdit =
    !showFullSummary &&
    showStepThreeFullSummary &&
    additionalQuestions.confirmed

  const isSubmitting =
    processMSKFormIsLoading ||
    updatePatientIsLoading ||
    createDraftNoteIsLoading ||
    updateDraftNoteIsLoading

  const setAdditionalQuestionsTitle = () => {
    setModalTitle('Additional Intake Assessment Questions')
  }

  const setFullSummaryTitle = () => {
    setModalTitle('Intake Assessment Full Summary')
  }

  const getNextText = () => {
    if (showFullSummary) {
      return 'SUBMIT'
    }

    if (showStepThreeFullSummary && !additionalQuestions.confirmed) {
      return 'CONFIRM'
    }

    return 'NEXT'
  }

  const isPage1Valid = () => {
    const newErrors: MSKFormState['errors'] = {}

    if (
      additionalQuestions.bhAction1 === 'Y' &&
      additionalQuestions.bhAction2 === 'Select an option'
    ) {
      newErrors.bhAction2 = 'Required'
    }

    if (
      isPhQ9ActionRequired(
        additionalQuestions.phScore,
        patient.medicalProfile?.linkedPayorId
          ? getPayorName(patient.medicalProfile.linkedPayorId)
          : undefined,
        getMarketName()
      ) &&
      additionalQuestions.phQ9Action === 'Select an option'
    ) {
      newErrors.phQ9Action = 'Required'
    }

    if (Object.keys(newErrors).length) {
      addNewErrors(newErrors)
      return false
    }

    return true
  }

  const isPage3Valid = () => {
    const validFields: boolean[] = [
      validateAdditionalPhone(),
      validateBarriersToBeAwareOfText(),
      validatePeopleAllowedToReachOutToPhone(),
      validatePeopleAllowedToReachOutToWho(),
      validatePcpName(),
      validatePcpPhone(),
      validatePcpFax(),
      validatePcpAddressLine1(),
      validatePcpAddressLine2(),
      validatePcpCity(),
      validatePcpState(),
      validatePcpZip(),
    ]

    if (validFields.every((isValid) => isValid)) {
      return true
    }

    return false
  }

  const handleEdit = () => {
    setShowStepThreeFullSummary(false)
    unconfirmAdditionalQuestions()
    setPage(1)
  }

  const createOrUpdateDraftNote = (patient: PatientV2) => {
    if (draftNote) {
      updateDraftNote(
        mapToUpdateDraftNoteArgs(patient, draftNote.id, {
          type: 'Intake',
          note: noteText,
        })
      )
    } else {
      createDraftNote(
        mapToCreateDraftNoteArgs(patient, {
          type: 'Intake',
          note: noteText,
        })
      )
    }
  }

  const handleNext = () => {
    if (showFullSummary) {
      const processMSKFormArgs = mapToProcessMSKFormArgs(mskFormStore)
      const updatePatientPayload = mapMSKToUpdatePatientPayload(
        patient,
        mskFormStore
      )

      processMSKForm(processMSKFormArgs, {
        onSuccess: () => {
          if (Object.keys(updatePatientPayload).length) {
            updatePatient(
              {
                patientId: patient.patientId,
                payload: updatePatientPayload,
              },
              {
                onSuccess: () => {
                  toast.success('Patient PCP and phone updated successfully')
                },
                onError: () => {
                  toast.error('Patient PCP and phone update failed!')
                },
                onSettled: () => {
                  toast.success('Intake Assessment submitted successfully')
                  setOpenMSKFormModal(false)
                  setSelectedNoteType('Intake')
                  resetMSKForm(patient.patientId)
                  createOrUpdateDraftNote(patient)
                },
              }
            )
          } else {
            toast.success('Intake Assessment submitted successfully')
            setOpenMSKFormModal(false)
            setSelectedNoteType('Intake')
            resetMSKForm(patient.patientId)
            createOrUpdateDraftNote(patient)
          }
        },
        onError: () => {
          toast.error('Something went wrong. Please try again later')
        },
      })
      return
    }

    if (showStepThreeFullSummary) {
      if (additionalQuestions.confirmed) {
        setShowFullSummary(true)
        setShowStepThreeFullSummary(false)
        setFullSummaryTitle()
        return
      }
      confirmAdditionalQuestions()
      setShowFullSummary(true)
      setShowStepThreeFullSummary(false)
      setFullSummaryTitle()
      return
    }

    if (page === 3 && !isPage3Valid()) {
      scrolToErrorElement()
      return
    }

    if (page === 3 && isPage3Valid()) {
      setShowStepThreeFullSummary(true)
      return
    }

    if (page === 2) {
      setPage(3)
      return
    }

    if (page === 1 && !isPage1Valid()) {
      return
    }

    if (page === 1 && isPage1Valid()) {
      setPage(2)
      return
    }
  }

  const handlePrevious = () => {
    if (showFullSummary) {
      setShowFullSummary(false)
      setShowStepThreeFullSummary(true)
      setAdditionalQuestionsTitle()
      return
    }

    if (showStepThreeFullSummary) {
      if (additionalQuestions.confirmed) {
        setStep(2)
        return
      }
      setShowStepThreeFullSummary(false)
      return
    }

    if (page === 3) {
      setPage(2)
      return
    }

    if (page === 2) {
      setPage(1)
      return
    }

    if (page === 1) {
      setStep(2)
      return
    }
  }

  useEffect(() => {
    setAdditionalQuestionsTitle()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MSKFormStepContainer>
      <Box>
        {showFullSummary ? (
          <Box id="msk_form_full_summary">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '64px',
              }}
            >
              {intakeAssessments.map((intakeAssessment) => (
                <MSKFormStepTwoIntakeAssessmentSummary
                  key={intakeAssessment.botheredBodyPart.id}
                  intakeAssessment={intakeAssessment}
                  loadedMSKForm={loadedMSKForm}
                  intakeAssessments={intakeAssessments}
                  isInStepTwoFullSummary={true}
                />
              ))}

              <MSKFormStepThreeSummary />
            </Box>
          </Box>
        ) : showStepThreeFullSummary ? (
          <MSKFormStepThreeSummary />
        ) : page === 3 ? (
          <MSKFormStepThreePage3
            patient={patient}
            loadedMSKForm={loadedMSKForm}
          />
        ) : page === 2 ? (
          <MSKFormStepThreePage2 loadedMSKForm={loadedMSKForm} />
        ) : page === 1 ? (
          <MSKFormStepThreePage1
            patient={patient}
            loadedMSKForm={loadedMSKForm}
          />
        ) : null}
      </Box>
      <MSKFormPagination
        onNext={handleNext}
        onPrevious={handlePrevious}
        onEdit={handleEdit}
        showEdit={showEdit}
        disabledPrevious={isSubmitting}
        disabledNext={isSubmitting}
        nextText={getNextText()}
      />
    </MSKFormStepContainer>
  )
}
