import { FC, useState, useMemo, useEffect, useCallback } from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Auth, Hub } from 'aws-amplify'

import {
  BackdropLoader,
  useGetMyCompassUserQuery,
} from 'src/features/shared/presentation'
import {
  AuthContext,
  useRolesAndPermissionsStore,
} from 'src/features/shared/infrastructure'
import { ChildrenInProps } from 'src/features/shared/types'
import * as settings from 'src/config/settings'
import {
  loginDisabledAccessTokenMock,
  loginDisabledIdTokenMock,
} from 'src/__mocks__/tokens'

export const AuthProvider: FC<ChildrenInProps> = ({ children }) => {
  const [authIsLoading, setAuthIsLoading] = useState(false)
  const [authError, setAuthError] = useState<string | undefined>()
  const { user: amplifyUser, route } = useAuthenticator((context) => [
    context.user,
    context.route,
  ])
  const { userRoles, setUserRoles, setUserType } = useRolesAndPermissionsStore()

  const getIdToken = () => {
    if (settings.LOGIN_DISABLED) {
      return loginDisabledIdTokenMock
    }
    return amplifyUser?.getSignInUserSession()?.getIdToken().getJwtToken()
  }

  const { myCompassUser } = useGetMyCompassUserQuery({
    idToken: getIdToken(),
  })

  const logout = useCallback(() => {
    return Auth.signOut()
  }, [])

  /**
   * Get current session or logout if it's not valid
   */
  const getCurrentSession = useCallback(async () => {
    const session = await Auth.currentSession()
    if (!session.isValid()) {
      logout()
    }
    return session
  }, [logout])

  const getTokens = useCallback(async () => {
    if (settings.LOGIN_DISABLED) {
      return {
        idToken: loginDisabledIdTokenMock,
        accessToken: loginDisabledAccessTokenMock,
      }
    }
    const session = await getCurrentSession()
    const idToken = session.getIdToken().getJwtToken()
    const accessToken = session.getAccessToken().getJwtToken()
    return { idToken, accessToken }
  }, [getCurrentSession])

  const isAuthenticated = route === 'authenticated'

  const value = useMemo(
    () => ({
      amplifyUser: amplifyUser,
      myCompassUser,
      isAuthenticated,
      authIsLoading,
      setAuthIsLoading,
      authError,
      logout,
      getTokens,
    }),
    [
      amplifyUser,
      myCompassUser,
      isAuthenticated,
      authIsLoading,
      authError,
      logout,
      getTokens,
    ]
  )

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn_failure':
          setAuthError(data.message)
          setAuthIsLoading(false)
          break
      }
    })

    return unsubscribe
  }, [])

  useEffect(() => {
    if (myCompassUser && userRoles?.length) {
      setAuthIsLoading(false)
    } else if (myCompassUser) {
      setUserRoles(myCompassUser.roles)
      setUserType(myCompassUser.type)
    }
  }, [myCompassUser, userRoles, setUserRoles, setUserType])

  return (
    <AuthContext.Provider value={value}>
      <>
        {children}
        <BackdropLoader open={authIsLoading} />
      </>
    </AuthContext.Provider>
  )
}
