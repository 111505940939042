import { ChangeEvent } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import { SubmitHandler, useForm, Controller } from 'react-hook-form'
import { toast } from 'react-toastify'

import {
  FormLayoutWithCancelSave,
  ControlledNoteField,
  ControlledSelectField,
  SPOKE_WITH_OPTIONS,
  StatusField,
  useStatusDefinitions,
  CheckboxGroup,
} from 'src/features/shared/presentation/'
import { getReferralSources } from 'src/features/shared/utils'
import {
  NOTE_FIELD_LABEL,
  NoteFormProps,
  useCreatePatientOutreachNote,
} from 'src/features/notes/presentation'
import {
  mapToCreatePatientOutreachNoteArgs,
  mapToUpdateDraftNoteArgs,
} from 'src/features/notes/adapters'
import { useNotesStore } from 'src/features/shared/infrastructure'
import {
  useUpdatePatient,
  useUpdatePatientStatus,
} from 'src/features/patients/presentation'
import { DevTool } from '@hookform/devtools'
import { mapToPatientUpdateStatus } from 'src/features/patients/adapters'
import { mapToControlledSelectItem } from 'src/features/shared/adapters'
import { NoteData } from 'src/features/notes/domain'
import { useUpdateDraftNote } from 'src/features/notes/presentation'
import { mapActionToCheckboxGroupOption } from 'src/features/msk/adapters'

const isReasonForRefusalRequired = (status?: string) => status === 'Refused'

const MORE_INFORMATION_REQUEST_METHOD = [
  { id: 'Email', display: 'Email' },
  { id: 'Mailer', display: 'Mailer' },
]

export type OutreachAdditionalQuestionsFormValues = Pick<
  NoteData,
  'reasonForRefusal' | 'moreInformationRequested'
>

export type OutreachNoteFormFields = OutreachAdditionalQuestionsFormValues & {
  note: string
  status: string
  communication: string
  referralSource?: string
  spokeWith?: string
}

type OutreachNoteFormProps = NoteFormProps

export const OutreachNoteForm = ({
  patient,
  setShowLoader,
  draftNote,
}: OutreachNoteFormProps) => {
  const { updatePatientAsync } = useUpdatePatient()
  const { getAllStatusDefinitions } = useStatusDefinitions(patient)
  const { createPatientOutreachNoteAsync } = useCreatePatientOutreachNote()
  const { updatePatientStatusAsync } = useUpdatePatientStatus()
  const { setSelectedNoteType } = useNotesStore()
  const { updateDraftNoteAsync } = useUpdateDraftNote()

  const createOutreachNoteFormSchema: yup.Schema<OutreachNoteFormFields> = yup
    .object()
    .shape({
      note: yup.string().required('Required'),
      status: yup.string().required('Required'),
      reasonForRefusal: yup.string().when('status', {
        is: isReasonForRefusalRequired,
        then: (schema) => schema.required('Required'),
      }),
      referralSource: yup.string().required('Required'),
      spokeWith: yup.string().required('Required'),
      moreInformationRequested: yup.array(),
      communication: yup.string().required('Required'),
    })

  const formMethods = useForm<OutreachNoteFormFields>({
    resolver: yupResolver(createOutreachNoteFormSchema),
    defaultValues: {
      note: '',
      status: '',
      reasonForRefusal: '',
      referralSource: patient.referralSource || '',
      spokeWith: '',
      moreInformationRequested: [],
      communication: 'outbound',
    },
    mode: 'onBlur',
  })

  const referralSourceWatcher = formMethods.watch('referralSource')
  const statusWatcher = formMethods.watch('status')

  const submitHandler: SubmitHandler<OutreachNoteFormFields> = async (data) => {
    setShowLoader?.(true)
    const createPatientOutreachNoteArgs = mapToCreatePatientOutreachNoteArgs(
      patient,
      data,
      draftNote.id
    )

    // TODO: This submission should be in a single request and the backend should use a transaction
    try {
      await createPatientOutreachNoteAsync(createPatientOutreachNoteArgs)
      toast.success('Patient Note successfully created!')

      if (
        patient.stageAndStatus &&
        patient.stageAndStatus.stage &&
        data.status
      ) {
        try {
          const createPatientUpdateStatusArgs = mapToPatientUpdateStatus(
            patient,
            data.status
          )
          await updatePatientStatusAsync(createPatientUpdateStatusArgs)
          toast.success('Patient Status successfully updated!')
        } catch (error) {
          toast.error('Patient Status update failed!')
        }
      }

      if (data.referralSource) {
        try {
          await updatePatientAsync({
            patientId: patient.patientId,
            payload: {
              referralSource: data.referralSource,
            },
          })
          toast.success('Patient referral source successfully updated!')
        } catch (error) {
          toast.error('Patient referral source update failed!')
        }
      }
    } catch (error) {
      toast.error('Failed creating patient note!')
    }

    setSelectedNoteType('')
    setShowLoader?.(false)
  }

  const saveDraftHandler: SubmitHandler<OutreachNoteFormFields> = async (
    data
  ) => {
    setShowLoader?.(true)

    const updatePatientCustomNoteArgs = mapToUpdateDraftNoteArgs(
      patient,
      draftNote.id,
      {
        ...data,
        type: 'Outreach',
      }
    )

    try {
      await updateDraftNoteAsync(updatePatientCustomNoteArgs)
      toast.success('Patient Note Draft successfully Saved!')
    } catch (error) {
      toast.error('Failed Saving Patient Note Draft!', { autoClose: false })
    }

    setShowLoader?.(false)
  }

  const handleSaveDraft = (data: OutreachNoteFormFields) => {
    saveDraftHandler(data)
  }

  return (
    <FormLayoutWithCancelSave
      onSubmit={submitHandler}
      onSaveDraftClick={handleSaveDraft}
      formMethods={formMethods}
      draftNote={draftNote}
    >
      <Grid container rowSpacing={2}>
        <Grid item sm={12} pb={'16px'}>
          <ControlledNoteField />
        </Grid>
        <Grid item container columnSpacing={2} sm={12}>
          <Grid item sm={6}>
            <StatusField
              statusRequired
              reasonForRefusalRequired={isReasonForRefusalRequired(
                statusWatcher
              )}
              statuses={getAllStatusDefinitions(referralSourceWatcher)}
            />
          </Grid>
        </Grid>
        <Grid item container columnGap={'16px'} sm={12}>
          <Grid item sm={2}>
            <ControlledSelectField
              name={'spokeWith'}
              label={NOTE_FIELD_LABEL.spokeWith}
              labelComponent={'inputLabel'}
              items={SPOKE_WITH_OPTIONS.map(mapToControlledSelectItem)}
              formControlProps={{
                size: 'small',
              }}
              formControlSx={{
                width: '100%',
              }}
              required
            />
          </Grid>
          <Grid item sm={3}>
            <ControlledSelectField
              name={'referralSource'}
              label={NOTE_FIELD_LABEL.referralSource}
              labelComponent={'inputLabel'}
              items={getReferralSources(patient.referralSource).map(
                mapToControlledSelectItem
              )}
              formControlProps={{
                size: 'small',
              }}
              formControlSx={{
                width: '100%',
              }}
              required
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction={'column'}
          mt={'10px'}
          sm={12}
          sx={{
            '& .MuiFormLabel-root': {
              fontWeight: '400',
            },
            '& .MuiFormControlLabel-root': {
              minWidth: '0',
              marginRight: '40px',
            },
          }}
        >
          <Controller
            name={'moreInformationRequested'}
            render={({ field }) => (
              <CheckboxGroup
                maxRows={1}
                handleChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const checked = event.target.checked
                  const value = event.target.value
                  if (checked) {
                    field.onChange([...field.value, value])
                  } else {
                    field.onChange(
                      field.value.filter((item: string) => item !== value)
                    )
                  }
                }}
                name={'moreInformationRequested'}
                label={NOTE_FIELD_LABEL.moreInformationRequested}
                options={MORE_INFORMATION_REQUEST_METHOD.map(
                  mapActionToCheckboxGroupOption
                )}
                selectedOptions={field.value}
              />
            )}
          />
        </Grid>
      </Grid>
      <DevTool control={formMethods.control} placement={'top-left'} />
    </FormLayoutWithCancelSave>
  )
}
