import { FC } from 'react'
import { IconButton, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import BackIcon from '@mui/icons-material/ArrowBackIos'

export const BackButton: FC = () => {
  const navigate = useNavigate()

  const handleBackButtonClick = () => {
    navigate(-1)
  }

  return (
    <IconButton
      sx={{ color: 'magenta.300' }}
      onClick={handleBackButtonClick}
      disableRipple
      aria-label={'back button'}
    >
      <BackIcon fontSize="small" />
      <Typography fontWeight="bold">{'Back'}</Typography>
    </IconButton>
  )
}
