import { FC, ChangeEvent } from 'react'
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { EventProps } from 'react-big-calendar'

import { TaskCalendarEvent } from 'src/features/shared/presentation'
import { useTasksStore } from 'src/features/shared/infrastructure'
import { ArrowForward } from '@mui/icons-material'

export const PatientCalendarTasksEvent: FC<EventProps<TaskCalendarEvent>> = ({
  event,
}) => {
  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Typography variant="caption">
        {event.task?.title ?? ''} ({event.task?.assignedTCMemberName ?? ''})
      </Typography>
    </Box>
  )
}

export const UserCalendarTasksEvent: FC<EventProps<TaskCalendarEvent>> = ({
  event,
}) => {
  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Typography variant="caption">
        {event.task?.title ?? ''} ({event.task?.patientName ?? ''})
      </Typography>
    </Box>
  )
}

type UserCalendarCheckboxTasksEventProps = {
  label: string
  checked: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void
  patientId?: string
}

const UserCalendarCheckboxTasksEvent: FC<
  UserCalendarCheckboxTasksEventProps
> = ({ label, checked, onChange, patientId }) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <FormControlLabel
        control={
          <Checkbox onChange={onChange} checked={checked} color="primary" />
        }
        label={label}
      />
      {patientId ? (
        <IconButton
          color="primary"
          component={RouterLink}
          to={`/patients/${patientId}`}
        >
          <ArrowForward />
        </IconButton>
      ) : null}
    </Box>
  )
}

export const UserCalendarAgendaTasksEvent: FC<
  EventProps<TaskCalendarEvent>
> = ({ event }) => {
  const {
    userTasksCalendarAgendaSelectedTasks,
    setUserTasksCalendarAgendaSelectedTasks,
  } = useTasksStore()
  const checked = userTasksCalendarAgendaSelectedTasks.some(
    (task) => task.id === event.task?.id
  )
  const handleChange = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked && event.task) {
      setUserTasksCalendarAgendaSelectedTasks([
        ...userTasksCalendarAgendaSelectedTasks,
        event.task,
      ])
    } else {
      setUserTasksCalendarAgendaSelectedTasks(
        userTasksCalendarAgendaSelectedTasks.filter(
          (task) => task.id !== event.task?.id
        )
      )
    }
  }

  const title = event.task?.title ?? ''
  const patientName = event.task?.patientName ?? ''
  const status = event.task?.status ?? ''
  const urgentLabel = event.task?.urgent ? '**URGENT** ' : ''
  const label = `${urgentLabel}${title} (${patientName}) <${status}>`

  return (
    <UserCalendarCheckboxTasksEvent
      label={label}
      checked={checked}
      onChange={handleChange}
      patientId={event.task?.patientId}
    />
  )
}

export const UserCalendarDayTasksEvent: FC<EventProps<TaskCalendarEvent>> = ({
  event,
}) => {
  const {
    userTasksCalendarDaySelectedTasks,
    setUserTasksCalendarDaySelectedTasks,
  } = useTasksStore()
  const checked = userTasksCalendarDaySelectedTasks.some(
    (task) => task.id === event.task?.id
  )
  const handleChange = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked && event.task) {
      setUserTasksCalendarDaySelectedTasks([
        ...userTasksCalendarDaySelectedTasks,
        event.task,
      ])
    } else {
      setUserTasksCalendarDaySelectedTasks(
        userTasksCalendarDaySelectedTasks.filter(
          (task) => task.id !== event.task?.id
        )
      )
    }
  }

  const title = event.task?.title ?? ''
  const patientName = event.task?.patientName ?? ''
  const status = event.task?.status ?? ''
  const urgentLabel = event.task?.urgent ? '**URGENT** ' : ''
  const label = `${urgentLabel}${title} (${patientName}) <${status}>`

  return (
    <UserCalendarCheckboxTasksEvent
      label={label}
      checked={checked}
      onChange={handleChange}
      patientId={event.task?.patientId}
    />
  )
}
