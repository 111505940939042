import { Box } from '@mui/material'
import { FC, useState } from 'react'
import 'react-quill/dist/quill.snow.css'

import { PatientV2 } from 'src/features/patients/domain'
import { BackdropLoader } from 'src/features/shared/presentation'

import {
  CustomNoteFormV2,
  IntakeNoteForm,
  OrthoChartCheckNoteForm,
  OrthoTouchpointNoteFormV2,
  OutreachNoteForm,
  PTTouchpointNoteFormV2,
  PostOpTouchpointNoteFormV2,
  PreOpTouchpointNoteFormV2,
  ReferralUploadNoteForm,
  SelfCareTPNoteFormV2,
} from 'src/features/notes/presentation/components/forms'
import { useNotesStore } from 'src/features/shared/infrastructure/store/hooks/use-notes.store'
import { DraftNote } from '../../domain'

export type NoteFormProps = {
  patient: PatientV2
  setShowLoader?: (show: boolean) => void
  draftNote: DraftNote
}

type CreatePatientNoteFormProps = {
  patient: PatientV2
  draftNote: DraftNote
}

export const CreatePatientNoteForm: FC<CreatePatientNoteFormProps> = ({
  patient,
  draftNote,
}) => {
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const { selectedNoteType } = useNotesStore()

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '32px',
          padding: '24px 40px 12px 40px',
        }}
      >
        {selectedNoteType === 'Custom' ? (
          <CustomNoteFormV2
            patient={patient}
            setShowLoader={setShowLoader}
            draftNote={draftNote}
          />
        ) : null}
        {selectedNoteType === 'Outreach' ? (
          <OutreachNoteForm
            patient={patient}
            setShowLoader={setShowLoader}
            draftNote={draftNote}
          />
        ) : null}
        {selectedNoteType === 'Intake' ? (
          <IntakeNoteForm
            patient={patient}
            setShowLoader={setShowLoader}
            draftNote={draftNote}
          />
        ) : null}
        {selectedNoteType === 'Referral Upload' ? (
          <ReferralUploadNoteForm
            patient={patient}
            setShowLoader={setShowLoader}
            draftNote={draftNote}
          />
        ) : null}
        {selectedNoteType === 'Self-Care Touchpoint' ? (
          <SelfCareTPNoteFormV2
            patient={patient}
            setShowLoader={setShowLoader}
            draftNote={draftNote}
          />
        ) : null}
        {selectedNoteType === 'PT Touchpoint' ? (
          <PTTouchpointNoteFormV2
            patient={patient}
            setShowLoader={setShowLoader}
            draftNote={draftNote}
          />
        ) : null}
        {selectedNoteType === 'Ortho Touchpoint' ? (
          <OrthoTouchpointNoteFormV2
            patient={patient}
            setShowLoader={setShowLoader}
            draftNote={draftNote}
          />
        ) : null}
        {selectedNoteType === 'Ortho Chart Check' ? (
          <OrthoChartCheckNoteForm
            patient={patient}
            setShowLoader={setShowLoader}
            draftNote={draftNote}
          />
        ) : null}
        {selectedNoteType === 'Post-Op Touchpoint' ? (
          <PostOpTouchpointNoteFormV2
            patient={patient}
            setShowLoader={setShowLoader}
            draftNote={draftNote}
          />
        ) : null}
        {selectedNoteType === 'Pre-Op Touchpoint' ? (
          <PreOpTouchpointNoteFormV2
            patient={patient}
            setShowLoader={setShowLoader}
            draftNote={draftNote}
          />
        ) : null}
      </Box>
      <BackdropLoader open={showLoader} />
    </>
  )
}
