import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  styled,
} from '@mui/material'
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form'
import { FC, MouseEvent, ChangeEvent, useMemo } from 'react'
import { toast } from 'react-toastify'

import { ControlledTextField } from 'src/features/shared/presentation/components/form-fields'
import {
  useCreateProviderQuery,
  useSearchNPPESQuery,
} from 'src/features/providers/presentation'
import { useSearchProvidersStore } from 'src/features/shared/infrastructure'
import {
  FormFieldContainer,
  FullSectionLoader,
  TCBodyTableRow,
  TCCopyToClipboard,
  TCReactSelect,
  TCTableCell,
  useTooltipsState,
} from 'src/features/shared/presentation'
import { mapToProvidersTableRow } from 'src/features/providers/adapters'
import { STATE_CODES } from 'src/features/shared/constants'
import { mapToTCReactSelectOption } from 'src/features/shared/adapters'
import { CreateProviderError, Provider } from 'src/features/providers/domain'
import { emptyStringRegex, npiRegex, zipRegex } from 'src/features/shared/utils'

type SearchProvidersFormFields = {
  providerFirstName?: string
  providerLastName?: string
  city?: string
  state?: string
  postalCode?: string
  npi?: string
}

export type ProvidersTableRow = {
  npi: number
  name: string
  address: string
  secondaryAddress?: string
  phone: string
  secondaryPhone?: string
  fax?: string
  secondaryFax?: string
  taxonomy: string
}

const FormAttrKey = styled(Typography)(() => ({
  color: 'rgba(0, 0, 0, 0.38)',
  fontSize: '14px',
  letterSpacing: '0.17px',
}))

export type SearchProvidersFormProps = {
  onCreateProviderSuccess?: (provider: Provider) => void
  onCreateProviderError?: (
    npi: number,
    providerName: string,
    error: CreateProviderError
  ) => void
  onClose?: () => void
}

export const SearchProvidersForm: FC<SearchProvidersFormProps> = ({
  onClose,
  onCreateProviderSuccess,
  onCreateProviderError,
}) => {
  const {
    firstName,
    lastName,
    providerGroupId,
    isLoading,
    setIsLoading,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    searchResults,
    setSearchResults,
    selectedProvider,
    setSelectedProvider,
  } = useSearchProvidersStore()
  const { searchAllNPPES } = useSearchNPPESQuery()
  const { createProvider } = useCreateProviderQuery()
  const {
    tooltipState,
    getTooltipCopyClickHandler,
    getTooltipCopiedTooltipCloseHandler,
  } = useTooltipsState([
    'npi',
    'phone',
    'secondaryPhone',
    'fax',
    'secondaryFax',
    'address',
    'secondaryAddress',
  ])

  const createSearchProvidersFormSchema: yup.Schema<SearchProvidersFormFields> =
    yup.object().shape({
      state: yup.string(),
      providerFirstName: yup.string(),
      providerLastName: yup.string(),
      city: yup.string(),
      postalCode: yup
        .string()
        .matches(
          new RegExp(`(^${zipRegex.source}$|${emptyStringRegex.source})`),
          'invalid postal code'
        ),
      npi: yup
        .string()
        .matches(
          new RegExp(`(^${npiRegex.source}$|${emptyStringRegex.source})`),
          'must be a 10-digit number'
        ),
    })

  const formMethods = useForm<SearchProvidersFormFields>({
    resolver: yupResolver(createSearchProvidersFormSchema),
    defaultValues: {
      state: '',
      providerFirstName: firstName ? firstName : '',
      providerLastName: lastName ? lastName : '',
      city: '',
      postalCode: '',
      npi: '',
    },
    mode: 'onBlur',
  })

  const handleSubmit: SubmitHandler<SearchProvidersFormFields> = async (
    data: SearchProvidersFormFields
  ) => {
    setSearchResults(undefined)
    setSelectedProvider(undefined)
    setIsLoading(true)
    searchAllNPPES(
      {
        firstName:
          data.providerFirstName && data.providerFirstName.length > 0
            ? data.providerFirstName
            : undefined,
        lastName:
          data.providerLastName && data.providerLastName.length > 0
            ? data.providerLastName
            : undefined,
        city: data.city && data.city.length > 0 ? data.city : undefined,
        state: data.state && data.state.length > 0 ? data.state : undefined,
        postalCode:
          data.postalCode && data.postalCode.length > 0
            ? data.postalCode
            : undefined,
        npi: data.npi && data.npi.length > 0 ? data.npi : undefined,
      },
      setSearchResults,
      setIsLoading
    )
    setRowsPerPage(100)
    setPage(0)
  }

  const onRowClick = (row: any) => () => {
    setSelectedProvider(row)
  }

  const handleClose = () => {
    setSearchResults([])
    setSelectedProvider(undefined)
    if (onClose) {
      onClose()
    }
  }

  const createProviderSuccessHandler = (provider: Provider) => {
    if (onCreateProviderSuccess) {
      onCreateProviderSuccess(provider)
    } else {
      // default success handler
      toast.success(`Referring Provider ${provider.name} successfully created!`)
    }
    handleClose()
  }

  const createProviderErrorHandler = (
    npi: number,
    providerName: string,
    error: CreateProviderError
  ) => {
    if (onCreateProviderError) {
      onCreateProviderError(npi, providerName, error)
    } else {
      // default error handler
      toast.error(
        `Could not create provider ${providerName}. Reason: ${error.message}.`
      )
    }
    handleClose()
  }

  const onCreateProvider = async () => {
    if (selectedProvider) {
      const { name, npi } = selectedProvider
      createProvider(
        { payload: { name, providerGroupId, npi } },
        {
          onSuccess: createProviderSuccessHandler,
          onError: (error) => createProviderErrorHandler(npi, name, error),
        }
      )
    }
  }

  const onPageChange = (_: MouseEvent | null, page: number) => {
    setSelectedProvider(undefined)
    setPage(page)
  }

  const onRowsPerPageChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSelectedProvider(undefined)
    setRowsPerPage(Number(event.target.value))
    setPage(0)
  }

  const visibleRows = useMemo(() => {
    if (searchResults) {
      const start = page * rowsPerPage
      const end = Math.min(start + rowsPerPage, searchResults.length + 1)
      return searchResults.slice(start, end)
    }
  }, [searchResults, page, rowsPerPage])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: '10px',
        }}
      >
        <FormProvider {...formMethods}>
          <form
            onSubmit={formMethods.handleSubmit(handleSubmit)}
            onAbort={handleClose}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={2} pb={'16px'}>
                <Controller
                  name="state"
                  control={formMethods.control}
                  render={({ field }) => (
                    <FormFieldContainer label="State">
                      <TCReactSelect
                        placeholder="State"
                        options={STATE_CODES.map(mapToTCReactSelectOption)}
                        value={mapToTCReactSelectOption(
                          field.value ? field.value : ''
                        )}
                        onBlur={field.onBlur}
                        onChange={(option) => {
                          const value = !option ? '' : option.value
                          field.onChange(value)
                        }}
                      />
                    </FormFieldContainer>
                  )}
                />
              </Grid>
              <Grid item xs={2} pb={'16px'}>
                <ControlledTextField
                  name="npi"
                  label="Provider NPI"
                  formControlSx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={4} pb={'16px'}>
                <ControlledTextField
                  name="providerLastName"
                  label="Provider Last Name"
                  formControlSx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={4} pb={'16px'}>
                <ControlledTextField
                  name="providerFirstName"
                  label="Provider First Name"
                  formControlSx={{ width: '100%' }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4} pb={'16px'}>
                <ControlledTextField
                  name="city"
                  label="City"
                  formControlSx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={4} pb={'16px'}>
                <ControlledTextField
                  name="postalCode"
                  label="Postal Code"
                  formControlSx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={4} justifyContent={'flex-end'}>
                <Box
                  pt={'25px'}
                  sx={{ display: 'flex', justifyContent: 'right' }}
                >
                  <Button
                    variant={'text'}
                    color={'secondary'}
                    onClick={handleClose}
                  >
                    CANCEL
                  </Button>
                  <Button
                    type="submit"
                    variant={'contained'}
                    color={'secondary'}
                  >
                    SEARCH
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Box>
      {isLoading ? <FullSectionLoader /> : null}
      {!isLoading && searchResults && searchResults.length > 0 ? (
        <Box>
          <TableContainer
            sx={{
              height: '496px',
              overflowY: 'scroll',
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TCTableCell align="left">NPI</TCTableCell>
                  <TCTableCell align="left">Name</TCTableCell>
                  <TCTableCell align="left">Specialty</TCTableCell>
                  <TCTableCell align="left">Address</TCTableCell>
                  <TCTableCell align="left">Phone</TCTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleRows &&
                  visibleRows.map((result, index) => {
                    const row = mapToProvidersTableRow(result)
                    const { npi, name, address, phone, taxonomy } = row
                    return (
                      <TCBodyTableRow key={index} onClick={onRowClick(row)}>
                        <TCTableCell align="left">{npi}</TCTableCell>
                        <TCTableCell align="left">{name}</TCTableCell>
                        <TCTableCell align="left">{taxonomy}</TCTableCell>
                        <TCTableCell align="left">{address}</TCTableCell>
                        <TCTableCell align="left">{phone}</TCTableCell>
                      </TCBodyTableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Table>
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={searchResults.length}
                  onPageChange={onPageChange}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={onRowsPerPageChange}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Box>
      ) : null}
      {!isLoading && searchResults && searchResults.length === 0 ? (
        <Typography>No results found.</Typography>
      ) : null}
      {selectedProvider ? (
        <Paper sx={{ padding: '12px 16px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '16px',
                position: 'relative',
              }}
            >
              <Stack direction={'row'} gap={'24px'} alignItems={'center'}>
                <Typography sx={{ fontWeight: 500, fontSize: '24px' }}>
                  {selectedProvider.name}
                </Typography>
                <Stack direction={'row'} gap={'8px'}>
                  <FormAttrKey pt={'4px'}>NPI</FormAttrKey>
                  <Typography>{selectedProvider.npi}</Typography>
                  <TCCopyToClipboard
                    value={selectedProvider.npi.toString()}
                    tooltipOpen={tooltipState['npi']}
                    onCopy={getTooltipCopyClickHandler('npi')}
                    onTooltipClose={getTooltipCopiedTooltipCloseHandler('npi')}
                  />
                </Stack>
              </Stack>
              <Stack direction={'row'} gap={'24px'} alignItems={'center'}>
                <Stack direction={'row'} gap={'8px'}>
                  <FormAttrKey pt={'4px'}>Primary Address</FormAttrKey>
                  <Typography>{selectedProvider.address}</Typography>
                  <TCCopyToClipboard
                    value={selectedProvider.address}
                    tooltipOpen={tooltipState['address']}
                    onCopy={getTooltipCopyClickHandler('address')}
                    onTooltipClose={getTooltipCopiedTooltipCloseHandler(
                      'address'
                    )}
                  />
                </Stack>
              </Stack>
              <Stack direction={'row'} gap={'24px'} alignItems={'center'}>
                <Stack direction={'row'} gap={'8px'}>
                  <FormAttrKey pt={'4px'}>Primary Phone</FormAttrKey>
                  <Typography>{selectedProvider.phone}</Typography>
                  <TCCopyToClipboard
                    value={selectedProvider.phone}
                    tooltipOpen={tooltipState['phone']}
                    onCopy={getTooltipCopyClickHandler('phone')}
                    onTooltipClose={getTooltipCopiedTooltipCloseHandler(
                      'phone'
                    )}
                  />
                </Stack>
                {selectedProvider.fax ? (
                  <Stack direction={'row'} gap={'8px'}>
                    <FormAttrKey pt={'4px'}>Primary Fax</FormAttrKey>
                    <Typography>{selectedProvider.fax}</Typography>
                    <TCCopyToClipboard
                      value={selectedProvider.fax}
                      tooltipOpen={tooltipState['fax']}
                      onCopy={getTooltipCopyClickHandler('fax')}
                      onTooltipClose={getTooltipCopiedTooltipCloseHandler(
                        'fax'
                      )}
                    />
                  </Stack>
                ) : null}
              </Stack>
              {selectedProvider.secondaryAddress ? (
                <>
                  <Stack direction={'row'} gap={'24px'} alignItems={'center'}>
                    <Stack direction={'row'} gap={'8px'}>
                      <FormAttrKey pt={'4px'}>Secondary Address</FormAttrKey>
                      <Typography>
                        {selectedProvider.secondaryAddress}
                      </Typography>
                      <TCCopyToClipboard
                        value={selectedProvider.secondaryAddress}
                        tooltipOpen={tooltipState['secondaryAddress']}
                        onCopy={getTooltipCopyClickHandler('secondaryAddress')}
                        onTooltipClose={getTooltipCopiedTooltipCloseHandler(
                          'secondaryAddress'
                        )}
                      />
                    </Stack>
                  </Stack>
                  <Stack direction={'row'} gap={'24px'} alignItems={'center'}>
                    {selectedProvider.secondaryPhone ? (
                      <Stack direction={'row'} gap={'8px'}>
                        <FormAttrKey pt={'4px'}>Secondary Phone</FormAttrKey>
                        <Typography>
                          {selectedProvider.secondaryPhone}
                        </Typography>
                        <TCCopyToClipboard
                          value={selectedProvider.secondaryPhone}
                          tooltipOpen={tooltipState['secondaryPhone']}
                          onCopy={getTooltipCopyClickHandler('secondaryPhone')}
                          onTooltipClose={getTooltipCopiedTooltipCloseHandler(
                            'secondaryPhone'
                          )}
                        />
                      </Stack>
                    ) : null}
                    {selectedProvider.secondaryFax ? (
                      <Stack direction={'row'} gap={'8px'}>
                        <FormAttrKey pt={'4px'}>Secondary Fax</FormAttrKey>
                        <Typography>{selectedProvider.secondaryFax}</Typography>
                        <TCCopyToClipboard
                          value={selectedProvider.secondaryFax}
                          tooltipOpen={tooltipState['secondaryFax']}
                          onCopy={getTooltipCopyClickHandler('secondaryFax')}
                          onTooltipClose={getTooltipCopiedTooltipCloseHandler(
                            'secondaryFax'
                          )}
                        />
                      </Stack>
                    ) : null}
                  </Stack>
                </>
              ) : null}
            </Box>
            <Box
              sx={{
                display: 'flex',
                columnGap: '16px',
                alignItems: 'end',
              }}
            >
              <Button
                variant="outlined"
                color="secondary"
                size="medium"
                type="submit"
                onClick={onCreateProvider}
              >
                CREATE
              </Button>
            </Box>
          </Box>
        </Paper>
      ) : null}
    </>
  )
}
