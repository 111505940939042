import { Stack, Box, Icon, Typography } from '@mui/material'
import { FC } from 'react'

type PainLevelSliderFriendlyMarksProps = {
  setPainLevel: (value: number) => void
}

export const PainLevelSliderFriendlyMarks: FC<
  PainLevelSliderFriendlyMarksProps
> = ({ setPainLevel }) => {
  return (
    <Stack direction={'row'} justifyContent={'space-between'}>
      <Box
        sx={{
          width: { sm: '130px', xs: '70px' },
          display: 'flex',
          justifyContent: 'start',
        }}
      >
        <Stack direction={'column'}>
          <Icon
            sx={{ color: '#4CAF50', cursor: 'pointer' }}
            onClick={() => setPainLevel(0)}
          >
            sentiment_very_satisfied
          </Icon>
          <Typography
            sx={{ cursor: 'pointer' }}
            variant={'caption'}
            onClick={() => setPainLevel(0)}
          >
            No pain
          </Typography>
        </Stack>
      </Box>
      <Box
        sx={{
          width: { sm: '130px', xs: '90px' },
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Stack direction={'column'} alignItems={'center'}>
          <Icon
            sx={{ color: '#ED6C02', cursor: 'pointer' }}
            onClick={() => setPainLevel(5)}
          >
            sentiment_dissatisfied
          </Icon>
          <Typography
            sx={{ cursor: 'pointer' }}
            variant={'caption'}
            onClick={() => setPainLevel(5)}
          >
            Moderate Pain
          </Typography>
        </Stack>
      </Box>
      <Box
        sx={{
          width: { sm: '130px', xs: '70px' },
          display: 'flex',
          justifyContent: 'end',
          textAlign: 'end',
        }}
      >
        <Stack direction={'column'} alignItems={'end'}>
          <Icon
            sx={{ color: '#D32F2F', cursor: 'pointer' }}
            onClick={() => setPainLevel(10)}
          >
            mood_bad
          </Icon>
          <Typography
            sx={{ cursor: 'pointer' }}
            variant={'caption'}
            onClick={() => setPainLevel(10)}
          >
            Worst pain possible
          </Typography>
        </Stack>
      </Box>
    </Stack>
  )
}

type FunctionLevelSliderFriendlyMarksProps = {
  setFunctionLevel: (value: number) => void
}

export const FunctionLevelSliderFriendlyMarks: FC<
  FunctionLevelSliderFriendlyMarksProps
> = ({ setFunctionLevel }) => {
  return (
    <Stack direction={'row'} justifyContent={'space-between'}>
      <Box
        sx={{
          width: '180px',
          display: 'flex',
          justifyContent: 'start',
        }}
      >
        <Stack direction={'column'}>
          <Typography
            sx={{ cursor: 'pointer' }}
            variant={'caption'}
            onClick={() => setFunctionLevel(0)}
          >
            Completely unable
          </Typography>
        </Stack>
      </Box>
      <Box
        sx={{
          width: '160px',
          display: 'flex',
          justifyContent: 'end',
          textAlign: 'end',
        }}
      >
        <Stack direction={'column'} alignItems={'end'}>
          <Typography
            sx={{ cursor: 'pointer' }}
            variant={'caption'}
            onClick={() => setFunctionLevel(10)}
          >
            Able to perform as normal
          </Typography>
        </Stack>
      </Box>
    </Stack>
  )
}
