import { FC } from 'react'
import {
  Calendar,
  EventProps,
  dateFnsLocalizer,
  Event,
  View,
  NavigateAction,
} from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import enUS from 'date-fns/locale/en-US'

import { useTasksStore } from 'src/features/shared/infrastructure'
import { DoneTask, Task } from 'src/features/tasks/domain'

import 'react-big-calendar/lib/css/react-big-calendar.css'
import './tc-calendar.css'
import { AgendaDateHeader } from './tc-calendar-agenda-header-counter'

export interface TaskCalendarEvent extends Event {
  task?: Task | DoneTask
}

const locales = {
  'en-US': enUS,
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

type TaskCalendarProps = {
  events: TaskCalendarEvent[]
  EventComponent: FC<EventProps<TaskCalendarEvent>>
  view?: View
  AgendaEventComponent?: FC<EventProps<TaskCalendarEvent>>
  DayEventComponent?: FC<EventProps<TaskCalendarEvent>>
  onRangeChange?: (startDate: Date, endDate: Date) => void
  onNavigate?: (date: Date, view: View, action: NavigateAction) => void
  onViewChange?: (view: View) => void
  isLoading?: boolean
}

export const TaskCalendar: FC<TaskCalendarProps> = ({
  events,
  EventComponent,
  AgendaEventComponent,
  DayEventComponent,
  onRangeChange,
  onViewChange,
  onNavigate,
  isLoading,
  view = 'month',
}) => {
  const { setEditTaskFormModalOpen, setSelectedTask } = useTasksStore()
  const onDoubleClick = (event: TaskCalendarEvent) => {
    if (!event.task) return
    setSelectedTask(event.task)
    setEditTaskFormModalOpen(true)
  }

  const onSelect = (event: TaskCalendarEvent) => {
    if (event.task) {
      setSelectedTask(event.task)
    }
  }

  const handleRangeChange = (
    range:
      | Date[]
      | {
          start: Date
          end: Date
        }
  ) => {
    let start: Date | undefined = undefined
    let end: Date | undefined = undefined

    if (Array.isArray(range)) {
      start = range[0]
      end = range[range.length - 1]
    } else {
      start = range.start
      end = range.end
    }

    if (start && end && onRangeChange) {
      onRangeChange(start, end)
    }
  }

  const handleNavigate = (date: Date, view: View, action: NavigateAction) => {
    if (onNavigate) {
      onNavigate(date, view, action)
    }
  }

  return (
    <Calendar
      localizer={localizer}
      events={events}
      view={view}
      startAccessor="start"
      endAccessor="end"
      style={{ height: '50vh', width: '100%' }}
      components={{
        event: EventComponent,
        day: {
          event: DayEventComponent,
        },
        agenda: {
          event: AgendaEventComponent,
          date: (props) => <AgendaDateHeader {...props} events={events} />,
        },
      }}
      onDoubleClickEvent={onDoubleClick}
      onSelectEvent={onSelect}
      onRangeChange={handleRangeChange}
      onNavigate={handleNavigate}
      onView={onViewChange}
      messages={{
        event: 'Task',
        noEventsInRange: isLoading
          ? 'Loading tasks.'
          : 'There are no tasks in this range.',
      }}
    />
  )
}
