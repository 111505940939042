import { useCallback } from 'react'

import {
  GetQueuePatientsService,
  GetQueuePatientsServiceResponse,
  GetQueuesService,
  GetQueuesServiceResponse,
} from 'src/features/queues/infrastructure'
import { useCompassApiClient } from 'src/features/shared/infrastructure'
import * as settings from 'src/config/settings'

export const useQueuesService = () => {
  const { getCompassApiClient } = useCompassApiClient()

  const getQueues: GetQueuesService = useCallback(async () => {
    const compassClient = await getCompassApiClient()
    return await compassClient.get<GetQueuesServiceResponse>(
      settings.COMPASS_API_BASE_URL + '/queues/load'
    )
  }, [getCompassApiClient])

  const getQueuePatients: GetQueuePatientsService = useCallback(
    async ({ params, filters } = {}) => {
      const compassClient = await getCompassApiClient()
      let filterOptions
      if (
        filters &&
        !Object.values(filters).every((filter) => filter === undefined)
      ) {
        filterOptions = JSON.stringify(filters)
      }

      const allParams = {
        ...params,
        filterOptions,
      }
      return await compassClient.get<GetQueuePatientsServiceResponse>(
        settings.COMPASS_API_BASE_URL + '/queues',
        {
          params: allParams,
        }
      )
    },
    [getCompassApiClient]
  )

  return {
    getQueues,
    getQueuePatients,
  }
}
