import {
  OpenQueuePatientBusyModalActionPayload,
  OpenQueuePatientsFilterModalActionPayload,
  QueuePatientsSearchInputValueActionPayload,
  QueuePatientsSelectedFiltersActionPayload,
  QueuePatientsState,
  SelectedQueuePatientRowActionPayload,
  createPatientModalSelectedTabSelector,
  openCreatePatientModalSelector,
  openQueuePatientBusyModalSelector,
  openQueuePatientsFilterModalSelector,
  queuePatientsSearchInputValueSelector,
  queuePatientsSelectedFiltersSelector,
  selectedQueuePatientRowSelector,
  setCreatePatientModalSelectedTabAction,
  setOpenCreatePatientModalAction,
  setOpenQueuePatientBusyModalAction,
  setOpenQueuePatientsFilterModalAction,
  setQueuePatientsSearchInputValueAction,
  setQueuePatientsSelectedFiltersAction,
  setSelectedQueuePatientRowAction,
  useAppDispatch,
  useAppSelector,
} from 'src/features/shared/infrastructure'

export const useQueuePatientsStore = () => {
  const dispatch = useAppDispatch()

  const selectedQueuePatientRow = useAppSelector(
    selectedQueuePatientRowSelector
  )

  const openQueuePatientBusyModal = useAppSelector(
    openQueuePatientBusyModalSelector
  )

  const queuePatientsSearchInputValue = useAppSelector(
    queuePatientsSearchInputValueSelector
  )

  const openQueuePatientsFilterModal = useAppSelector(
    openQueuePatientsFilterModalSelector
  )

  const queuePatientsSelectedFilters = useAppSelector(
    queuePatientsSelectedFiltersSelector
  )

  const queuePatientsSelectedReferralSourceFilter =
    queuePatientsSelectedFilters.referralSource
      ? queuePatientsSelectedFilters.referralSource.split(',')
      : []

  const queuePatientsSelectedMarketFilter = queuePatientsSelectedFilters.market
    ? queuePatientsSelectedFilters.market.split(',')
    : []

  const queuePatientsSelectedStageFilter = queuePatientsSelectedFilters.stage
    ? queuePatientsSelectedFilters.stage.split(',')
    : []

  const queuePatientsSelectedLinkedPlanIdFilter =
    queuePatientsSelectedFilters.linkedPlanId
      ? queuePatientsSelectedFilters.linkedPlanId.split(',')
      : []

  const queuePatientsSelectedStatusFilter = queuePatientsSelectedFilters.status
    ? queuePatientsSelectedFilters.status.split(',')
    : []

  const queuePatientsSelectedLastToContactFilter =
    queuePatientsSelectedFilters.lastToContact
      ? queuePatientsSelectedFilters.lastToContact.split(',')
      : []

  const queuePatientsSelectedAssignedToFilter =
    queuePatientsSelectedFilters.assignedTo
      ? queuePatientsSelectedFilters.assignedTo.split(',')
      : []

  const queuePatientsReferralCoordinationFlagFilter =
    queuePatientsSelectedFilters.referralCoordinationFlag

  const queuePatientsSelectedAttempsQtyFromFilter =
    queuePatientsSelectedFilters.attemptsQtyFrom

  const queuePatientsSelectedAttempsQtyToFilter =
    queuePatientsSelectedFilters.attemptsQtyTo

  const queuePatientsSelectedDateOfLastAttemptStartFilter =
    queuePatientsSelectedFilters.dateOfLastAttemptStart

  const queuePatientsSelectedDateOfLastAttemptEndFilter =
    queuePatientsSelectedFilters.dateOfLastAttemptEnd

  const openCreatePatientModal = useAppSelector(openCreatePatientModalSelector)

  const createPatientModalSelectedTab = useAppSelector(
    createPatientModalSelectedTabSelector
  )

  const setSelectedQueuePatientRow = (
    selectedQueuePatientRow: SelectedQueuePatientRowActionPayload
  ) => dispatch(setSelectedQueuePatientRowAction(selectedQueuePatientRow))

  const setOpenQueuePatientBusyModal = (
    openQueuePatientBusyModal: OpenQueuePatientBusyModalActionPayload
  ) => dispatch(setOpenQueuePatientBusyModalAction(openQueuePatientBusyModal))

  const setQueuePatientsSearchInputValue = (
    queuePatientsSearchInputValue: QueuePatientsSearchInputValueActionPayload
  ) =>
    dispatch(
      setQueuePatientsSearchInputValueAction(queuePatientsSearchInputValue)
    )

  const setOpenQueuePatientsFilterModal = (
    openQueuePatientsFilterModal: OpenQueuePatientsFilterModalActionPayload
  ) =>
    dispatch(
      setOpenQueuePatientsFilterModalAction(openQueuePatientsFilterModal)
    )

  const setQueuePatientsSelectedFilters = (
    queuePatientsSelectedFilters: QueuePatientsSelectedFiltersActionPayload
  ) =>
    dispatch(
      setQueuePatientsSelectedFiltersAction(queuePatientsSelectedFilters)
    )

  const setQueuePatientsSelectedReferralSourceFilter = (value: string) =>
    setQueuePatientsSelectedFilters([{ filter: 'referralSource', value }])

  const setQueuePatientsSelectedStageFilter = (value: string) =>
    setQueuePatientsSelectedFilters([{ filter: 'stage', value }])

  const setQueuePatientsSelectedLinkedPlanIdFilter = (value: string) =>
    setQueuePatientsSelectedFilters([{ filter: 'linkedPlanId', value }])

  const setQueuePatientsSelectedStatusFilter = (value: string) =>
    setQueuePatientsSelectedFilters([{ filter: 'status', value }])

  const setQueuePatientsSelectedMarketFilter = (value: string) =>
    setQueuePatientsSelectedFilters([{ filter: 'market', value }])

  const setReferralCoordinationFlagFilter = (value: string) =>
    setQueuePatientsSelectedFilters([
      {
        filter: 'referralCoordinationFlag',
        value,
      },
    ])

  const setQueuePatientsSelectedLastToContactFilter = (value: string) =>
    setQueuePatientsSelectedFilters([{ filter: 'lastToContact', value }])

  const setQueuePatientsSelectedAssignedToFilter = (value: string) =>
    setQueuePatientsSelectedFilters([{ filter: 'assignedTo', value }])

  const setQueuePatientsSelectedDateOfLastAttemptStartFilter = (
    value: string
  ) =>
    setQueuePatientsSelectedFilters([
      { filter: 'dateOfLastAttemptStart', value },
    ])

  const setQueuePatientsSelectedDateOfLastAttemptEndFilter = (value: string) =>
    setQueuePatientsSelectedFilters([{ filter: 'dateOfLastAttemptEnd', value }])

  const setQueuePatientsSelectedAttemptsQtyFromFilter = (value: string) =>
    setQueuePatientsSelectedFilters([{ filter: 'attemptsQtyFrom', value }])

  const setQueuePatientsSelectedAttemptsQtyToFilter = (value: string) =>
    setQueuePatientsSelectedFilters([{ filter: 'attemptsQtyTo', value }])

  const clearQueuePatientsSelectedFilters = () =>
    setQueuePatientsSelectedFilters([
      { filter: 'referralSource', value: '' },
      { filter: 'stage', value: '' },
      { filter: 'status', value: '' },
      { filter: 'market', value: '' },
      { filter: 'linkedPlanId', value: '' },
      { filter: 'lastToContact', value: '' },
      { filter: 'assignedTo', value: '' },
      { filter: 'attemptsQtyFrom', value: '' },
      { filter: 'attemptsQtyTo', value: '' },
      { filter: 'dateOfLastAttemptStart', value: '' },
      { filter: 'dateOfLastAttemptEnd', value: '' },
    ])

  const hasAtLeastOneQueuePatientsFilterSelected = () => {
    return [
      queuePatientsSelectedAssignedToFilter,
      queuePatientsSelectedLastToContactFilter,
      queuePatientsSelectedLinkedPlanIdFilter,
      queuePatientsSelectedStageFilter,
      queuePatientsSelectedStatusFilter,
      queuePatientsSelectedMarketFilter,
      queuePatientsSelectedReferralSourceFilter,
      queuePatientsSelectedAttempsQtyFromFilter,
      queuePatientsSelectedAttempsQtyToFilter,
      queuePatientsSelectedDateOfLastAttemptStartFilter,
      queuePatientsSelectedDateOfLastAttemptEndFilter,
      queuePatientsReferralCoordinationFlagFilter,
    ].some((filter) => filter.length > 0)
  }

  const setOpenCreatePatientModal = (
    openCreatePatientModal: QueuePatientsState['openCreatePatientModal']
  ) => dispatch(setOpenCreatePatientModalAction(openCreatePatientModal))

  const setCreatePatientModalSelectedTab = (
    value: QueuePatientsState['createPatientModalSelectedTab']
  ) => dispatch(setCreatePatientModalSelectedTabAction(value))

  return {
    clearQueuePatientsSelectedFilters,
    createPatientModalSelectedTab,
    hasAtLeastOneQueuePatientsFilterSelected,
    openCreatePatientModal,
    openQueuePatientBusyModal,
    openQueuePatientsFilterModal,
    queuePatientsSearchInputValue,
    queuePatientsSelectedAssignedToFilter,
    queuePatientsSelectedFilters,
    queuePatientsSelectedLastToContactFilter,
    queuePatientsSelectedLinkedPlanIdFilter,
    queuePatientsSelectedStatusFilter,
    queuePatientsSelectedReferralSourceFilter,
    queuePatientsSelectedMarketFilter,
    queuePatientsSelectedStageFilter,
    queuePatientsSelectedAttempsQtyFromFilter,
    queuePatientsSelectedAttempsQtyToFilter,
    queuePatientsSelectedDateOfLastAttemptStartFilter,
    queuePatientsSelectedDateOfLastAttemptEndFilter,
    selectedQueuePatientRow,
    setCreatePatientModalSelectedTab,
    setOpenCreatePatientModal,
    setOpenQueuePatientBusyModal,
    setOpenQueuePatientsFilterModal,
    setQueuePatientsSearchInputValue,
    setQueuePatientsSelectedAssignedToFilter,
    setQueuePatientsSelectedFilters,
    setQueuePatientsSelectedLastToContactFilter,
    setQueuePatientsSelectedLinkedPlanIdFilter,
    setQueuePatientsSelectedStatusFilter,
    setQueuePatientsSelectedMarketFilter,
    setQueuePatientsSelectedReferralSourceFilter,
    setQueuePatientsSelectedStageFilter,
    setSelectedQueuePatientRow,
    setQueuePatientsSelectedAttemptsQtyFromFilter,
    setQueuePatientsSelectedAttemptsQtyToFilter,
    setQueuePatientsSelectedDateOfLastAttemptStartFilter,
    setQueuePatientsSelectedDateOfLastAttemptEndFilter,
    setReferralCoordinationFlagFilter,
  }
}
