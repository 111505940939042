import {
  SPartner,
  GetPartnersServiceResponse,
  GetPartnersServiceErrorResponse,
} from 'src/features/providers/infrastructure'
import {
  Partner,
  GetPartnersError,
  GetPartnersReturns,
} from 'src/features/providers/domain'

type MapToPartner = (partner: SPartner) => Partner

export const mapToPartner: MapToPartner = ({ id, name }) => ({
  id,
  name,
})

// -----------
// GET PARTNERS /
// -----------

type MapToGetPartnersReturns = (
  response: GetPartnersServiceResponse
) => GetPartnersReturns

export const mapToGetPartnersReturns: MapToGetPartnersReturns = (response) => {
  return response.partners.map((partner) => partner)
}

export type MapToGetPartnersError = (
  error: GetPartnersServiceErrorResponse
) => GetPartnersError

export const mapToGetPartnersError: MapToGetPartnersError = (error) => ({
  ...error,
})

type MapToMultiSelectOptionPartner = (partner: Partner) => {
  label: string
  value: string
  key: string
}

export const mapPartnerToMultiSelectOption: MapToMultiSelectOptionPartner = (
  partner
) => {
  return {
    label: partner.name,
    value: partner.id,
    key: partner.id,
  }
}
