import { FieldPath, FieldValues } from 'react-hook-form'
import { FC } from 'react'

import {
  YES_NO_OPTIONS,
  ControlledSelectField,
} from 'src/features/shared/presentation'
import { filterNoneValueOptions } from 'src/features/notes/adapters'
import { useAuth } from 'src/features/shared/infrastructure'
import { noteFieldLabelDefaultSx } from 'src/features/notes/presentation'
import { mapToControlledSelectItem } from 'src/features/shared/adapters'

type SDMPatientUnderstandsFieldProps = {
  name?: FieldPath<FieldValues>
  required?: boolean
}

export const SDMPatientUnderstandsField: FC<
  SDMPatientUnderstandsFieldProps
> = ({
  required,
  name = 'sdmClinicalBelievesPatientUnderstandsCondition',
}: SDMPatientUnderstandsFieldProps) => {
  const { myCompassUser } = useAuth()

  return (
    <ControlledSelectField
      name={name}
      required={required}
      label={`${myCompassUser?.firstName}, do you feel like this patient understands condition and treatment options?`}
      items={filterNoneValueOptions(YES_NO_OPTIONS).map(
        mapToControlledSelectItem
      )}
      selectSx={{ width: '88px' }}
      formLabelSx={noteFieldLabelDefaultSx}
    />
  )
}
