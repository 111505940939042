import { Auth } from 'aws-amplify'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { Box, Button, Typography } from '@mui/material'

import * as settings from 'src/config/settings'
import { useAuth } from 'src/features/shared/infrastructure'

type SignInButtonProps = {
  provider: CognitoHostedUIIdentityProvider
}

export const SignInButton = ({ provider }: SignInButtonProps) => {
  const { setAuthIsLoading } = useAuth()

  const handleClick = () => {
    setAuthIsLoading(true)
    Auth.federatedSignIn({
      provider,
    })
  }

  const getOKTAUrl = () => {
    const params = new URLSearchParams({
      identity_provider: 'OKTA',
      redirect_uri: settings.AWS_OAUTH_REDIRECT_SIGN_IN || '',
      response_type: 'CODE',
      client_id: settings.AWS_USER_POOLS_WEB_CLIENT_ID || '',
      scope: settings.AWS_OAUTH_SCOPE?.join(' ') || '',
    })

    return `https://${
      settings.AWS_OAUTH_DOMAIN
    }/oauth2/authorize?${params.toString()}`
  }

  switch (provider) {
    case CognitoHostedUIIdentityProvider.Google:
      return (
        <>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant={'body1'}>
              Please use your TailorCare provided google account
            </Typography>
          </Box>
          <button
            style={{
              background: '#4285F4',
              color: '#FFF',
              fontWeight: 500,
              padding: '1px',
              cursor: 'pointer',
              fontSize: '16px',
            }}
            onClick={handleClick}
          >
            <span>
              <svg
                style={{
                  width: '24px',
                  background: '#FFF',
                  borderRadius: '2px',
                  display: 'inline-block',
                  height: '100%',
                  marginRight: '12px',
                  padding: '2px',
                  textAlign: 'center',
                  verticalAlign: 'middle',
                }}
                viewBox="0 0 256 262"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMidYMid"
              >
                <path
                  d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                  fill="#4285F4"
                ></path>
                <path
                  d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                  fill="#34A853"
                ></path>
                <path
                  d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
                  fill="#FBBC05"
                ></path>
                <path
                  d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                  fill="#EA4335"
                ></path>
              </svg>
            </span>
            <span
              style={{
                padding: '12px',
              }}
            >
              Continue with Google
            </span>
          </button>
        </>
      )
    default:
      return (
        <>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant={'body1'} data-testid={'need_login_message'}>
              Please use your TailorCare provided credentials
            </Typography>
          </Box>
          {settings.OKTA_HOSTED_SIGNIN ? (
            <Button
              data-testid="login_button"
              variant="contained"
              color="secondary"
              sx={{ width: '100%' }}
              onClick={handleClick}
            >
              LOGIN
            </Button>
          ) : (
            <a href={getOKTAUrl()} style={{ textDecoration: 'none' }}>
              <Button
                data-testid="login_button"
                variant="contained"
                color="secondary"
                sx={{ width: '100%' }}
              >
                LOGIN
              </Button>
            </a>
          )}
        </>
      )
  }
}
