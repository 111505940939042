import { rest } from 'msw'

import * as settings from 'src/config/settings'
import { createProviderGroupServiceResponseMock } from 'src/__mocks__/responses/create-provider-group-service-response'
import { CreateProviderGroupServiceResponse } from 'src/features/providers/infrastructure'

const url = `${settings.PROVIDERS_API_BASE_URL}/providers/groups`

export const createProviderGroupServiceResponseHandler =
  rest.post<CreateProviderGroupServiceResponse>(url, async (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(createProviderGroupServiceResponseMock)
    )
  })
