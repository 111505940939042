import {
  UseMutateAsyncFunction,
  UseMutateFunction,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import {
  CreatePatientOrthoChartCheckNoteReturns,
  CreatePatientOrthoChartCheckNoteArgs,
  CreatePatientOrthoChartCheckNoteError,
} from 'src/features/notes/domain'
import { GET_PATIENT_QUERY_KEY_V2 } from 'src/features/patients/presentation'
import {
  GET_PATIENT_DRAFT_NOTE,
  GET_PATIENT_NOTES_QUERY_KEY,
} from 'src/features/notes/presentation'
import { useNotesUseCases } from 'src/features/notes/notes.service-locator'

type UseCreatePatientOrthoChartCheckNoteProps = Omit<
  UseMutationOptions<
    CreatePatientOrthoChartCheckNoteReturns,
    CreatePatientOrthoChartCheckNoteError,
    CreatePatientOrthoChartCheckNoteArgs,
    unknown
  >,
  'mutationFn'
>

type UseCreatePatientOrthoChartCheckNote = (
  props?: UseCreatePatientOrthoChartCheckNoteProps
) => {
  createPatientOrthoChartCheckNote: UseMutateFunction<
    CreatePatientOrthoChartCheckNoteReturns,
    CreatePatientOrthoChartCheckNoteError,
    CreatePatientOrthoChartCheckNoteArgs,
    unknown
  >
  createPatientOrthoChartCheckNoteAsync: UseMutateAsyncFunction<
    CreatePatientOrthoChartCheckNoteReturns,
    CreatePatientOrthoChartCheckNoteError,
    CreatePatientOrthoChartCheckNoteArgs,
    unknown
  >
  createPatientOrthoChartCheckNoteIsLoading: boolean
  createPatientOrthoChartCheckNoteIsError: boolean
  createPatientOrthoChartCheckNoteStatus: string
}

export const useCreatePatientOrthoChartCheckNote: UseCreatePatientOrthoChartCheckNote =
  (props = {}) => {
    const queryClient = useQueryClient()
    const notesUseCases = useNotesUseCases()

    const { onSuccess } = props

    const { mutate, mutateAsync, isLoading, isError, status } = useMutation<
      CreatePatientOrthoChartCheckNoteReturns,
      CreatePatientOrthoChartCheckNoteError,
      CreatePatientOrthoChartCheckNoteArgs,
      unknown
    >({
      mutationFn: (args: CreatePatientOrthoChartCheckNoteArgs) => {
        return notesUseCases.createPatientOrthoChartCheckNote(args)
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: [GET_PATIENT_QUERY_KEY_V2, variables.patientId],
        })
        queryClient.invalidateQueries({
          queryKey: [GET_PATIENT_NOTES_QUERY_KEY, variables.patientId],
        })
        queryClient.invalidateQueries({
          queryKey: [GET_PATIENT_DRAFT_NOTE, variables.patientId],
        })
        onSuccess?.(data, variables, context)
      },
    })
    return {
      createPatientOrthoChartCheckNote: mutate,
      createPatientOrthoChartCheckNoteAsync: mutateAsync,
      createPatientOrthoChartCheckNoteIsLoading: isLoading,
      createPatientOrthoChartCheckNoteIsError: isError,
      createPatientOrthoChartCheckNoteStatus: status,
    }
  }
