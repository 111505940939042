import { setupWorker } from 'msw'
import * as settings from 'src/config/settings'

import {
  getQueuesServiceHandler,
  getNavigatorsServiceResponseHandler,
  getTasksByUserIdServiceResponseHandler,
  searchPatientsServiceResponseHandler,
  searchDuplicatePatientsServiceResponseHandler,
  getQueuePatientsServiceResponseHandler,
  getPathwaysServiceResponseHandler,
  getPatientServiceResponseHandler,
  getPatientPainAndFunctionServiceResponseHandler,
  getMyCompassUserServiceResponseHandler,
  loadMSKFormServiceHandler,
  getPatientsStatusDefinitionsServiceResponseHanlder,
  getPatientNotesServiceResponseHandler,
  getEligiblePatientsServiceResponseHandler,
  getPatientIntakeFlowsServiceResponseHandler,
  createPatientServiceResponseHandler,
  createPatientNoteServiceResponseHandler,
  updatePatientStatusServiceResponseHandler,
  processMSKFormServiceResponseHandler,
  createPathwayServiceResponseHandler,
  reassignPathwayServiceResponseHandler,
  createTaskServiceResponseHandler,
  createEnablingTherapyServiceResponseHandler,
  getProvidersServiceResponseHandler,
  createProviderServiceResponseHandler,
  getProviderGroupsServiceResponseHandler,
  createProviderGroupServiceResponseHandler,
  getPlansServiceResponseHandler,
  createPlanServiceResponseHandler,
  getTasksByPatientIdServiceResponseHandler,
} from './handlers'

const handlers = []

if (settings.MOCK_GET_QUEUES_SERVICE) {
  handlers.push(getQueuesServiceHandler)
}

if (settings.MOCK_GET_NAVIGATORS_SERVICE) {
  handlers.push(getNavigatorsServiceResponseHandler)
}

if (settings.MOCK_GET_MY_COMPASS_USER_SERVICE) {
  handlers.push(getMyCompassUserServiceResponseHandler)
}

if (settings.MOCK_GET_TASKS_BY_USER_ID_SERVICE) {
  handlers.push(getTasksByUserIdServiceResponseHandler)
}

if (settings.MOCK_LOAD_MSK_FORM_SERVICE) {
  handlers.push(loadMSKFormServiceHandler)
}

if (settings.GET_PATIENTS_STATUS_DEFINITIONS_SERVICE_RESPONSE) {
  handlers.push(getPatientsStatusDefinitionsServiceResponseHanlder)
}

if (settings.MOCK_GET_PATIENT_SERVICE_RESPONSE) {
  handlers.push(getPatientServiceResponseHandler)
}

if (settings.MOCK_GET_PATIENT_PAIN_AND_FUNCTION_SERVICE_RESPONSE) {
  handlers.push(getPatientPainAndFunctionServiceResponseHandler)
}

if (settings.MOCK_GET_PATIENT_NOTES_SERVICE_RESPONSE) {
  handlers.push(getPatientNotesServiceResponseHandler)
}

if (settings.MOCK_GET_ELEGIBLE_PATIENTS_SERVICE_RESPONSE) {
  handlers.push(getEligiblePatientsServiceResponseHandler)
}

if (settings.MOCK_SEARCH_PATIENTS_SERVICE_RESPONSE) {
  handlers.push(searchPatientsServiceResponseHandler)
}

if (settings.MOCK_SEARCH_DUPLICATE_PATIENTS_SERVICE_RESPONSE) {
  handlers.push(searchDuplicatePatientsServiceResponseHandler)
}

if (settings.MOCK_GET_QUEUE_PATIENTS_SERVICE_RESPONSE) {
  handlers.push(getQueuePatientsServiceResponseHandler)
}

if (settings.MOCK_GET_PATIENT_INTAKE_FLOWS_SERVICE_RESPONSE) {
  handlers.push(getPatientIntakeFlowsServiceResponseHandler)
}

if (settings.MOCK_CREATE_PATIENT_SERVICE_RESPONSE) {
  handlers.push(createPatientServiceResponseHandler)
}

if (settings.MOCK_CREATE_PATIENT_NOTE_SERVICE_RESPONSE) {
  handlers.push(createPatientNoteServiceResponseHandler)
}

if (settings.MOCK_UPDATE_PATIENT_STATUS_SERVICE_RESPONSE) {
  handlers.push(updatePatientStatusServiceResponseHandler)
}

if (settings.MOCK_PROCESS_MSK_FORM_SERVICE_RESPONSE) {
  handlers.push(processMSKFormServiceResponseHandler)
}

if (settings.MOCK_CREATE_PATHWAY_SERVICE_RESPONSE) {
  handlers.push(createPathwayServiceResponseHandler)
}

if (settings.MOCK_REASSIGN_PATHWAY_SERVICE_RESPONSE) {
  handlers.push(reassignPathwayServiceResponseHandler)
}

if (settings.MOCK_CREATE_TASK_SERVICE_RESPONSE) {
  handlers.push(createTaskServiceResponseHandler)
}

if (settings.MOCK_GET_PATHWAYS_SERVICE_RESPONSE) {
  handlers.push(getPathwaysServiceResponseHandler)
}

if (settings.MOCK_CREATE_ENABLING_THERAPY_SERVICE_RESPONSE) {
  handlers.push(createEnablingTherapyServiceResponseHandler)
}

if (settings.MOCK_CREATE_PROVIDER_SERVICE_RESPONSE) {
  handlers.push(createProviderServiceResponseHandler)
}

if (settings.MOCK_GET_PROVIDER_GROUPS_SERVICE_RESPONSE) {
  handlers.push(getProviderGroupsServiceResponseHandler)
}

if (settings.MOCK_CREATE_PROVIDER_GROUP_SERVICE_RESPONSE) {
  handlers.push(createProviderGroupServiceResponseHandler)
}

if (settings.MOCK_GET_PROVIDERS_SERVICE_RESPONSE) {
  handlers.push(getProvidersServiceResponseHandler)
}

if (settings.MOCK_CREATE_PLAN_SERVICE_RESPONSE) {
  handlers.push(createPlanServiceResponseHandler)
}

if (settings.MOCK_GET_PLANS_SERVICE_RESPONSE) {
  handlers.push(getPlansServiceResponseHandler)
}

if (settings.MOCK_GET_TASKS_BY_PATIENT_ID_SERVICE_RESPONSE) {
  handlers.push(getTasksByPatientIdServiceResponseHandler)
}

export const worker = setupWorker(...handlers)
