import {
  CreateEnablingTherapyArgs,
  CreateEnablingTherapyError,
  CreateEnablingTherapyPayload,
  CreateEnablingTherapyReturns,
  CreatedEnablingTherapy,
  GetPatientIntakeFlowsError,
  GetPatientIntakeFlowsParams,
  GetPatientIntakeFlowsReturns,
  Intake,
  IntakeFlow,
  IntakeFlowActionOpenToTry,
  IntakeFlowAdditionalQuestions,
  IntakeFlowAnswer,
  IntakeFlowPreviouslyTriedAction,
  IntakeFlowQuestionsAndActions,
  IntakePatientActions,
  MSKAdditionalQuestions,
  MSKAdditionalQuestionsFieldOptions,
  MSKAdditionalQuestionsResultDisplay,
  UpdateIntakeArgs,
  UpdateIntakeError,
  UpdateIntakeReturns,
} from 'src/features/msk/domain'
import {
  CreateEnablingTherapyServiceArgs,
  CreateEnablingTherapyServiceErrorResponse,
  CreateEnablingTherapyServiceResponse,
  GetPatientIntakeFlowsServiceErrorResponse,
  GetPatientIntakeFlowsServiceParams,
  GetPatientIntakeFlowsServiceResponse,
  SCreatedEnablingTherapy,
  SIntake,
  SIntakeFlow,
  SIntakeFlowAnswer,
  SIntakeFlowQuestionsAndActions,
  SIntakePatientActions,
  UpdateIntakeServiceArgs,
  UpdateIntakeServiceErrorResponse,
  UpdateIntakeServiceResponse,
} from 'src/features/msk/infrastructure'
import { mapToMSKQuestionsAndActions } from 'src/features/msk/adapters'
import {
  CreateEnablingTherapyFormFields,
  IntakeWithAdditionalQuestions,
  PatientIntakeFlowAdditionalQuestions,
  PatientIntakeFlowCondition,
  PatientIntakeFlowConditionV2,
  QUESTIONS_LABELS,
  TRAUMA_QUESTIONS,
} from 'src/features/msk/presentation'
import { Navigator } from 'src/features/shared/domain'
import { RECOMMENDED_ACTION_PATHWAYS_TYPES } from 'src/features/shared/constants'
import { mapToUseCaseDefaultError } from 'src/features/shared/adapters'
import { formatDate } from 'src/features/shared/utils'
import { HEALTH_EQUITY_LABELS } from 'src/features/notes/presentation'

type MapToIntakeAssessment = (
  sIntakeAssessment: SIntake['intakeAssessment']
) => Intake['intakeAssessment']

export const mapToIntakeAssessment: MapToIntakeAssessment = (
  sIntakeAssessment
) => {
  const mappedIntakeAssessment: Intake['intakeAssessment'] = {}
  Object.entries(sIntakeAssessment).forEach(([key, value]) => {
    if (key !== 'botheredBodyPart') {
      const answerData = value as SIntakeFlowAnswer
      mappedIntakeAssessment[key] = {
        questionId: answerData.id,
        value: answerData.answer,
      }
    } else {
      mappedIntakeAssessment[key] = value as string
    }
  })

  return mappedIntakeAssessment
}

type MapToIntakePatientActions = (
  sIntakePatientActions: SIntakePatientActions
) => IntakePatientActions

export const mapToIntakePatientActions: MapToIntakePatientActions = (
  sIntakePatientActions
) => {
  let currentlyUsingOpioids = null
  if (sIntakePatientActions.currentlyUsingOpiods === 'yes') {
    currentlyUsingOpioids = true
  } else if (sIntakePatientActions.currentlyUsingOpiods === 'no') {
    currentlyUsingOpioids = false
  }
  return {
    accupuncture: sIntakePatientActions.accupuncture,
    chiropracticCare: sIntakePatientActions.chiropracticCare,
    compression: sIntakePatientActions.compression,
    createdAt: sIntakePatientActions.createdAt,
    elevation: sIntakePatientActions.elevation,
    ice: sIntakePatientActions.ice,
    id: sIntakePatientActions.id,
    injections: sIntakePatientActions.injections,
    intakeId: sIntakePatientActions.intakeId,
    openToTry: sIntakePatientActions.openToTry,
    opioids: sIntakePatientActions.opioids,
    otcMedication: sIntakePatientActions.otcMedication,
    patientId: sIntakePatientActions.patientId,
    physicalTherapy: sIntakePatientActions.physicalTherapy,
    previouslyTried: sIntakePatientActions.previouslyTried,
    rest: sIntakePatientActions.rest,
    surgery: sIntakePatientActions.surgery,
    other: sIntakePatientActions.other,
    currentlyUsingOpioids,
    otherPreviouslyTriedActionText: sIntakePatientActions.otherText,
  }
}

type MapToIntakeFlowQuestionsAndActions = (
  questionsAndActions: SIntakeFlowQuestionsAndActions
) => IntakeFlowQuestionsAndActions

export const mapToIntakeFlowQuestionsAndActions: MapToIntakeFlowQuestionsAndActions =
  (questionsAndActions) => {
    return mapToMSKQuestionsAndActions(questionsAndActions)
  }

type MapToEnablingTherapy = (
  sEnablingTherapy: SCreatedEnablingTherapy
) => CreatedEnablingTherapy

export const mapToCreatedEnablingTherapy: MapToEnablingTherapy = (
  sCreatedEnablingTherapy
) => {
  return {
    ...sCreatedEnablingTherapy,
    createdBy: sCreatedEnablingTherapy.name,
    createdAt: formatDate(sCreatedEnablingTherapy.createdAt, true),
    dateOfFirstAppointment: formatDate(
      sCreatedEnablingTherapy.dateOfFirstAppointment,
      true
    ),
  }
}

type MapToCareTeam = (careTeam: SIntake['careTeam']) => Intake['careTeam']

export const mapToCareTeam: MapToCareTeam = (careTeam) => {
  if (!careTeam) {
    return null
  }

  return {
    id: careTeam.id,
    careTeamPatientId: careTeam.careTeamPatientId,
    providerId: careTeam.providerId,
    providerGroupId: careTeam.providerGroupId,
    referringProviderGroupId: careTeam.referringProviderGroupId ?? null,
    locationProvider: careTeam.locationProvider,
    preferredProviderFlag: careTeam.preferredProviderFlag ?? false,
    garnerRecommendationFlag: careTeam.garnerRecommendationFlag ?? false,
    garnerUsed: careTeam.garnerUsed ?? false,
    garnerUsageExplanation: careTeam.garnerUsageExplanation ?? null,
    garnerUsageExplanationOther: careTeam.garnerUsageExplanationOther ?? null,
    referralCoordinationFlag: careTeam.referralCoordinationFlag ?? false,
    requiredTherapy: careTeam.requiredTherapy ?? null,
    requiredOtherTherapy: careTeam.requiredOtherTherapy ?? null,
    referringProviderId: careTeam.referringProviderId ?? null,
    limitations: careTeam.limitations ?? [],
    limitationsOther: careTeam.limitationsOther ?? null,
    pathwayId: careTeam.pathwayId,
    intakeId: careTeam.intakeId,
    scheduled: careTeam.scheduled,
    refRequestStatus: careTeam.refRequestStatus ?? null,
    referralUploadLink: careTeam.referralUploadLink ?? null,
    quickNote: careTeam.quickNote ?? null,
    lastModifiedReqStatusBy: careTeam.lastModifiedReqStatusBy ?? null,
    referralLastModified: careTeam.referralLastModified ?? null,
    lastReferralStatusUpdater: careTeam.lastReferralStatusUpdater ?? null,
  }
}

type MapToIntake = (sIntake: SIntake) => Intake

export const mapToIntake: MapToIntake = (sIntake) => {
  const bodyRegionToNote: Intake['bodyRegionToNote'] = []
  if (sIntake.bodyRegionToNote) {
    const bodyRegionsToNote = sIntake.bodyRegionToNote
      .split(',')
      .map((bodyRegion) => bodyRegion.trim()) as Intake['bodyRegionToNote']
    bodyRegionToNote.push(...bodyRegionsToNote)
  }
  const { isTraumaPatient, ...restSIntake } = sIntake
  return {
    ...restSIntake,
    status: sIntake.status === 'Pending' ? 'Inactive' : sIntake.status,
    enablingTherapies: sIntake.enablingTherapies
      ? sIntake.enablingTherapies.map(mapToCreatedEnablingTherapy)
      : [],
    bodyRegionToNote,
    hardstop: sIntake.hardStop,
    patientActions: sIntake.patientActions
      ? sIntake.patientActions.map(mapToIntakePatientActions)
      : [],
    intakeAssessment: mapToIntakeAssessment(sIntake.intakeAssessment),
    isTraumaIntake: isTraumaPatient,
    careTeam: mapToCareTeam(sIntake.careTeam),
  }
}

type MapToIntakeFlow = (sIntakeFlow: SIntakeFlow) => IntakeFlow

export const mapToIntakeFlow: MapToIntakeFlow = (sIntakeFlow) => {
  const intakes = sIntakeFlow.intakes?.map(mapToIntake) || []

  const recommendedActionIds = intakes.map(
    (intake) => intake.recommendedActionId
  )

  const recommendedActions: string[] = recommendedActionIds.reduce<string[]>(
    (prev, curr) => {
      if (curr && RECOMMENDED_ACTION_PATHWAYS_TYPES[curr]) {
        return Array.from(
          new Set([...prev, ...RECOMMENDED_ACTION_PATHWAYS_TYPES[curr]])
        )
      }
      return prev
    },
    []
  )

  return {
    ...sIntakeFlow,
    recommendedActions,
    intakes,
  }
}

type MapToGetPatientIntakeFlowsReturns = (
  response: GetPatientIntakeFlowsServiceResponse
) => GetPatientIntakeFlowsReturns

export const mapToGetPatientIntakeFlowsReturns: MapToGetPatientIntakeFlowsReturns =
  (response) => {
    return {
      intakeFlows: response.intakeFlows?.map(mapToIntakeFlow) || [],
      previouslyTriedActions: response.previouslyTriedActions,
      actionsOpenToTry: response.actionsOpenToTry,
      enablingTherapies: response.enablingTherapies,
      questionsAndActions: mapToIntakeFlowQuestionsAndActions(
        response.questionsAndActions
      ),
    }
  }

type MapToGetPatientIntakeFlowsError = (
  error: GetPatientIntakeFlowsServiceErrorResponse
) => GetPatientIntakeFlowsError

export const mapToGetPatientIntakeFlowsError: MapToGetPatientIntakeFlowsError =
  (error) => {
    return {
      message: error.message,
    }
  }

type MapToGetPatientIntakeFlowsServiceParams = (
  params?: GetPatientIntakeFlowsParams
) => GetPatientIntakeFlowsServiceParams | undefined

export const mapToGetPatientIntakeFlowsServiceParams: MapToGetPatientIntakeFlowsServiceParams =
  (params) => {
    if (!params) return undefined
    return {
      limit: params.limit,
      orderBy: params.orderBy,
      orderDirection: params.orderDirection,
    }
  }

// -------------------------
// CREATE ENABLING THERAPY /
// ------------------------

type MapToCreateEnablingTherapyServiceArgs = (
  args: CreateEnablingTherapyArgs
) => CreateEnablingTherapyServiceArgs

export const mapToCreateEnablingTherapyServiceArgs: MapToCreateEnablingTherapyServiceArgs =
  (args) => {
    return {
      ...args,
    }
  }

type MapToCreateEnablingTherapyReturns = (
  response: CreateEnablingTherapyServiceResponse
) => CreateEnablingTherapyReturns

export const mapToCreateEnablingTherapyReturns: MapToCreateEnablingTherapyReturns =
  (response) => {
    return {
      ...response,
    }
  }

type MapToCreateEnablingTherapyError = (
  error: CreateEnablingTherapyServiceErrorResponse
) => CreateEnablingTherapyError

export const mapToCreateEnablingTherapyError: MapToCreateEnablingTherapyError =
  (error) =>
    mapToUseCaseDefaultError<CreateEnablingTherapyServiceErrorResponse>(error)

type MapToCreateEnablingTherapyPayload = (
  patientId: string,
  assignedPathwayId: string,
  formValues: CreateEnablingTherapyFormFields
) => CreateEnablingTherapyPayload

export const mapToCreateEnablingTherapyPayload: MapToCreateEnablingTherapyPayload =
  (patientId, assignedPathwayId, formValues) => {
    const args: CreateEnablingTherapyPayload = {
      patientId: patientId,
      therapy: formValues.type,
      notes: formValues.therapyNote,
      assignedPathwayId: assignedPathwayId,
    }

    if (formValues.dateOfFirstAppointment) {
      args['dateOfFirstAppointment'] = formatDate(
        formValues.dateOfFirstAppointment
      )
    }

    return args
  }

// ---------------
// UPDATE INTAKE /
// ---------------

type MapToUpdateIntakeServiceArgs = (
  args: UpdateIntakeArgs
) => UpdateIntakeServiceArgs

export const mapToUpdateIntakeServiceArgs: MapToUpdateIntakeServiceArgs = (
  args
) => {
  return {
    ...args,
  }
}

type MapToUpdateIntakeReturns = (
  response: UpdateIntakeServiceResponse
) => UpdateIntakeReturns

export const mapToUpdateIntakeReturns: MapToUpdateIntakeReturns = (
  response
) => ({ ...response })

type MapToUpdateIntakeError = (
  response: UpdateIntakeServiceErrorResponse
) => UpdateIntakeError

export const mapToUpdateIntakeError: MapToUpdateIntakeError = (error) => ({
  ...error,
})

// ----------------------
// PRESENTATION MAPPERS /
// --------------------

type MapToPatientIntakeFlowAdditionalQuestions = (
  additionalQuestions: NonNullable<IntakeFlowAdditionalQuestions>
) => Omit<PatientIntakeFlowAdditionalQuestions, 'createdInfo'>

export const mapToPatientIntakeFlowAdditionalQuestions: MapToPatientIntakeFlowAdditionalQuestions =
  (additionalQuestions) => {
    const getAgreeYouUnderstandConditionAnswer = () => {
      if (!additionalQuestions.agreeYouUnderstandCondition) {
        return 'N/A'
      }
      return additionalQuestions.agreeYouUnderstandCondition
    }

    const getAgreeYouUnderstandTreatmentOptionsAnswer = () => {
      if (!additionalQuestions.agreeYouUnderstandTreatmentOptions) {
        return 'N/A'
      }
      return additionalQuestions.agreeYouUnderstandTreatmentOptions
    }

    const getPhScore = () => {
      if (!additionalQuestions.phScore) {
        return 'N/A'
      }
      return `${additionalQuestions.phScore}`
    }

    const getPhQ9Action = () => {
      if (!additionalQuestions.phQ9) {
        return 'N/A'
      }
      return additionalQuestions.phQ9
    }

    const getGad2Score = () => {
      if (!additionalQuestions.gad2Score) {
        return 'N/A'
      }
      return `${additionalQuestions.gad2Score}`
    }

    const getAssessment = (
      assessment?: MSKAdditionalQuestionsResultDisplay['v1']
    ) => {
      if (!assessment) {
        return 'N/A'
      }
      return assessment
    }

    const getV1Answer = (answer?: MSKAdditionalQuestionsFieldOptions['v1']) => {
      if (!answer) {
        return 'Not answered'
      }
      return answer
    }

    const getV2Answer = (answer?: MSKAdditionalQuestionsFieldOptions['v2']) => {
      if (!answer) {
        return 'Not answered'
      }
      return answer === 'Y' ? 'Yes' : 'No'
    }

    const getBhAnswer = (
      bhAction1: MSKAdditionalQuestions['bhAction1'],
      bhAction2: MSKAdditionalQuestions['bhAction2']
    ) => {
      if (!bhAction1) {
        return 'N/A'
      }

      let result = bhAction1 === 'Y' ? 'Yes' : 'No'

      if (bhAction2) {
        result += ` / ${bhAction2}`
      }

      return result
    }

    const getBarriersToBeAwareOfAnswer = (
      barriersToBeAwareOf: MSKAdditionalQuestions['barriersToBeAwareOf'],
      barriersToBeAwareOfText: MSKAdditionalQuestions['barriersToBeAwareOfText']
    ) => {
      if (!barriersToBeAwareOf) {
        return 'Not answered'
      }
      if (barriersToBeAwareOf !== 'other') {
        return barriersToBeAwareOf
      }
      return `${
        barriersToBeAwareOf.charAt(0).toUpperCase() +
        barriersToBeAwareOf.slice(1)
      }: ${barriersToBeAwareOfText}`
    }

    const getPeopleAllowedToReachOutToAnswer = (
      peopleAllowedToReachOutTo: MSKAdditionalQuestions['peopleAllowedToReachOutTo']
    ) => {
      if (!peopleAllowedToReachOutTo) {
        return 'Not answered'
      }
      return peopleAllowedToReachOutTo.replaceAll('|', '-')
    }

    const getAdditionalPhoneAnswer = (
      additionalPhone: MSKAdditionalQuestions['additionalPhone']
    ) => {
      if (!additionalPhone) {
        return 'Not answered'
      }
      return additionalPhone
    }

    const getFallScreenV2WhyNotAnswer = (
      fallScreenV2WhyNot: MSKAdditionalQuestions['fallScreenV2WhyNot']
    ) => {
      if (!fallScreenV2WhyNot) {
        return 'Not answered'
      }
      return fallScreenV2WhyNot
    }

    return {
      agreeYouUnderstandConditionQuestion:
        HEALTH_EQUITY_LABELS.agreeYouUnderstandCondition,
      agreeYouUnderstandConditionAnswer: getAgreeYouUnderstandConditionAnswer(),
      agreeYouUnderstandTreatmentOptionsQuestion:
        HEALTH_EQUITY_LABELS.agreeYouUnderstandTreatmentOptions,
      agreeYouUnderstandTreatmentOptionsAnswer:
        getAgreeYouUnderstandTreatmentOptionsAnswer(),
      phQ1Question: QUESTIONS_LABELS.phQ1,
      phQ1Answer: getV1Answer(additionalQuestions.phQ1),
      phQ2Question: QUESTIONS_LABELS.phQ2,
      phQ2Answer: getV1Answer(additionalQuestions.phQ2),
      phScore: getPhScore(),
      phAssessment: getAssessment(additionalQuestions.phAssessment),
      phQ9ActionQuestion: QUESTIONS_LABELS.phQ9Action,
      phQ9ActionAnswer: getPhQ9Action(),
      gad2Q1Question: QUESTIONS_LABELS.gad2Q1,
      gad2Q1Answer: getV1Answer(additionalQuestions.gad2Q1),
      gad2Q2Question: QUESTIONS_LABELS.gad2Q2,
      gad2Q2Answer: getV1Answer(additionalQuestions.gad2Q2),
      gad2Score: getGad2Score(),
      gad2Assessment: getAssessment(additionalQuestions.gad2Assessment),
      bhAnswer: getBhAnswer(
        additionalQuestions.bhAction1,
        additionalQuestions.bhAction2
      ),
      fallScreenVersion: additionalQuestions.fallScreenVersion ?? 'v1',
      fallScreenQ1Question: QUESTIONS_LABELS.fallScreenQ1,
      fallScreenQ1Answer: getV2Answer(additionalQuestions.fallScreenQ1),
      fallScreenQ2Question: QUESTIONS_LABELS.fallScreenQ2,
      fallScreenQ2Answer: getV2Answer(additionalQuestions.fallScreenQ2),
      fallScreenResult: getAssessment(additionalQuestions.fallScreenResult),
      fallScreenV2Q1Question: QUESTIONS_LABELS.fallScreenV2Q1,
      fallScreenV2Q1Answer: getV2Answer(additionalQuestions.fallScreenV2Q1),
      fallScreenV2Q2Question: QUESTIONS_LABELS.fallScreenV2Q2,
      fallScreenV2Q2Answer: getV2Answer(additionalQuestions.fallScreenV2Q2),
      fallScreenV2Q3Question: QUESTIONS_LABELS.fallScreenV2Q3,
      fallScreenV2Q3Answer: getV2Answer(additionalQuestions.fallScreenV2Q3),
      fallScreenV2Q4Question: QUESTIONS_LABELS.fallScreenV2Q4,
      fallScreenV2Q4Answer: getV2Answer(additionalQuestions.fallScreenV2Q4),
      fallScreenV2Result: getAssessment(additionalQuestions.fallScreenV2Result),
      fallScreenV2WhyNotQuestion: QUESTIONS_LABELS.fallScreenV2WhyNot,
      fallScreenV2WhyNotAnswer: getFallScreenV2WhyNotAnswer(
        additionalQuestions.fallScreenV2WhyNot
      ),
      bodyRegionToNoteQuestion: QUESTIONS_LABELS.bodyRegionToNote,
      tabletComputerOrPhoneQuestion: QUESTIONS_LABELS.tabletComputerOrPhone,
      tabletComputerOrPhoneAnswer: getV2Answer(
        additionalQuestions.tabletComputerOrPhone
      ),
      barriersToBeAwareOfQuestion: QUESTIONS_LABELS.barriersToBeAwareOf,
      barriersToBeAwareOfAnswer: getBarriersToBeAwareOfAnswer(
        additionalQuestions.barriersToBeAwareOf,
        additionalQuestions.barriersToBeAwareOfText
      ),
      peopleAllowedToReachOutToQuestion:
        QUESTIONS_LABELS.peopleAllowedToReachOutTo,

      peopleAllowedToReachOutToAnswer: getPeopleAllowedToReachOutToAnswer(
        additionalQuestions.peopleAllowedToReachOutTo
      ),
      additionalPhoneQuestion: QUESTIONS_LABELS.additionalPhone,
      additionalPhoneAnswer: getAdditionalPhoneAnswer(
        additionalQuestions.additionalPhone
      ),
      patientConsentForSmsQuestion: QUESTIONS_LABELS.patientConsentForSms,
      patientConsentForSmsAnswer: getV2Answer(
        additionalQuestions.patientConsentForSms
      ),
      patientConsentForSmsAdditionalPhoneQuestion:
        QUESTIONS_LABELS.patientConsentForSmsAdditionalPhone,
      patientConsentForSmsAdditionalPhoneAnswer: getV2Answer(
        additionalQuestions.patientConsentForSmsAdditionalPhone
      ),
    }
  }

type MapToPatientIntakeFlowDataConditionArgs = {
  intake: Intake
  previouslyTriedActions: IntakeFlowPreviouslyTriedAction[]
  actionsOpenToTry: IntakeFlowActionOpenToTry[]
  questionsAndActions: IntakeFlowQuestionsAndActions
  navigators: Navigator[]
}

type MapToPatientIntakeFlowDataCondition = (
  args: MapToPatientIntakeFlowDataConditionArgs
) => PatientIntakeFlowCondition

export const mapToPatientIntakeFlowDataCondition: MapToPatientIntakeFlowDataCondition =
  ({
    intake,
    previouslyTriedActions,
    actionsOpenToTry,
    questionsAndActions,
    navigators,
  }) => {
    const createdAt = formatDate(intake.createdAt, true)
    let createdInfo = `Added ${createdAt}`
    const createdBy = navigators.find(
      (navigator) => navigator.id === intake.capturedBy
    )?.name
    if (createdBy) {
      createdInfo += ` by ${createdBy}`
    }

    const bodyRegion = intake.botheredBodyPart
      ? intake.botheredBodyPart.toUpperCase()
      : 'Unknown'

    let recommendedCarePathway = '-'
    let recommendedType = '-'
    if (intake.recommendedAction) {
      const splittedRecommendedAction = intake.recommendedAction.split(':')
      recommendedCarePathway = splittedRecommendedAction[0]
      if (splittedRecommendedAction.length === 2) {
        recommendedType = splittedRecommendedAction[1]
      }
    }

    const carePathwayId = intake.recommendedActionId

    let currentlyUsingOpioids: PatientIntakeFlowCondition['currentlyUsingOpioids']
    let otherPreviouslyTriedActionText: PatientIntakeFlowCondition['otherPreviouslyTriedActionText']
    let previouslyTried = '-'
    const patientPreviouslyTriedActions = intake.patientActions.filter(
      (patientActions) => patientActions.previouslyTried
    )
    if (patientPreviouslyTriedActions.length) {
      const previouslyTriedActionsObject = patientPreviouslyTriedActions[0]

      if (previouslyTriedActionsObject.currentlyUsingOpioids === true) {
        currentlyUsingOpioids = 'Yes'
      } else if (previouslyTriedActionsObject.currentlyUsingOpioids === false) {
        currentlyUsingOpioids = 'No'
      }

      if (
        previouslyTriedActionsObject.other &&
        previouslyTriedActionsObject.otherPreviouslyTriedActionText
      ) {
        otherPreviouslyTriedActionText =
          previouslyTriedActionsObject.otherPreviouslyTriedActionText
      }

      const previouslyTriedActionsId = Object.entries(
        previouslyTriedActionsObject
      )
        .filter(([, selected]) => selected === true)
        .map(([key]) => key)

      previouslyTried = previouslyTriedActions
        .filter((previouslyTriedAction) =>
          previouslyTriedActionsId.includes(previouslyTriedAction.id)
        )
        .map(({ display }) => display)
        .join(', ')
    }

    let openToTrying = '-'
    const patientOpenToTryingActions = intake.patientActions.filter(
      (patientActions) => patientActions.openToTry
    )
    if (patientOpenToTryingActions.length) {
      const openToTryingActionsObject = patientOpenToTryingActions[0]
      const openToTryingActionsId = Object.entries(openToTryingActionsObject)
        .filter(([, selected]) => selected === true)
        .map(([key]) => key)
      openToTrying = actionsOpenToTry
        .filter((openToTryingAction) =>
          openToTryingActionsId.includes(openToTryingAction.id)
        )
        .map(({ display }) => display)
        .join(', ')
    }

    const painLevel = intake.currentPainLevel
      ? `${intake.currentPainLevel}`
      : '-'
    const functionLevel = intake.currentFunctionLevel
      ? `${intake.currentFunctionLevel}`
      : '-'

    const answers = Object.entries(intake.intakeAssessment)
      .filter(
        ([key]) =>
          (key !== 'botheredBodyPart' &&
            key in questionsAndActions.questions) ||
          key === TRAUMA_QUESTIONS.didYouSeeAPhysician.id
      )
      .map(([key, value]) => {
        let question: string
        if (key === TRAUMA_QUESTIONS.didYouSeeAPhysician.id) {
          question = TRAUMA_QUESTIONS.didYouSeeAPhysician.question
        } else {
          question = questionsAndActions.questions[key].q
        }
        const answerData = value as IntakeFlowAnswer

        return {
          question,
          value: answerData.value,
        }
      })

    const bodyRegionToNote = intake.bodyRegionToNote.length
      ? intake.bodyRegionToNote.join(', ')
      : 'Not answered'

    return {
      id: intake.id,
      createdInfo,
      bodyRegion,
      bodyRegionToNote,
      recommendedCarePathway,
      recommendedType,
      carePathwayId,
      previouslyTried: previouslyTried || '-',
      openToTrying: openToTrying || '-',
      painLevel,
      functionLevel,
      answers,
      currentlyUsingOpioids,
      otherPreviouslyTriedActionText,
      enablingTherapies: intake.enablingTherapies,
      isTraumaIntake: intake.isTraumaIntake,
      status: intake.status,
      associatedPathway: intake.associatedPathway,
      careTeam: intake.careTeam,
      skipped: intake.skipped,
    }
  }

type MapToPatientIntakeFlowV2DataArgs = {
  intakeFlows: IntakeFlow[]
  previouslyTriedActions: IntakeFlowPreviouslyTriedAction[]
  actionsOpenToTry: IntakeFlowActionOpenToTry[]
  questionsAndActions: IntakeFlowQuestionsAndActions
  navigators: Navigator[]
}

type PatientIntakeFlowV2Data = {
  allConditions: PatientIntakeFlowConditionV2[]
  inactiveConditions: PatientIntakeFlowConditionV2[]
  activeConditions: PatientIntakeFlowConditionV2[]
  completedConditions: PatientIntakeFlowConditionV2[]
}

type MapToPatientIntakeFlowV2Data = (
  args: MapToPatientIntakeFlowV2DataArgs
) => PatientIntakeFlowV2Data

export const mapToPatientIntakeFlowV2Data: MapToPatientIntakeFlowV2Data = ({
  intakeFlows,
  previouslyTriedActions,
  actionsOpenToTry,
  questionsAndActions,
  navigators,
}) => {
  const intakesWithAdditionalQuestions: IntakeWithAdditionalQuestions[] = []

  intakeFlows.forEach((intakeFlow) => {
    intakeFlow.intakes.forEach((intake) => {
      intakesWithAdditionalQuestions.push({
        intake,
        additionalQuestions: intakeFlow.additionalQuestions,
      })
    })
  })

  // sort intakes by createdAt
  intakesWithAdditionalQuestions.sort((a, b) => {
    const aDate = new Date(a.intake.createdAt)
    const bDate = new Date(b.intake.createdAt)
    return bDate.getTime() - aDate.getTime()
  })

  const allConditions = intakesWithAdditionalQuestions.map(
    (intakeWithAdditionalQuestions) => ({
      ...mapToPatientIntakeFlowDataCondition({
        intake: intakeWithAdditionalQuestions.intake,
        previouslyTriedActions,
        actionsOpenToTry,
        questionsAndActions,
        navigators,
      }),
      additionalQuestions: intakeWithAdditionalQuestions.additionalQuestions
        ?.questions
        ? mapToPatientIntakeFlowAdditionalQuestions(
            intakeWithAdditionalQuestions.additionalQuestions.questions
          )
        : null,
    })
  )

  const inactiveConditions = allConditions.filter((condition) =>
    ['Inactive', 'Pending'].includes(condition.status)
  )
  const activeConditions = allConditions.filter(
    (condition) => condition.status === 'Active'
  )
  const completedConditions = allConditions.filter(
    (condition) => condition.status === 'Completed'
  )

  return {
    allConditions,
    inactiveConditions,
    activeConditions,
    completedConditions,
  }
}
