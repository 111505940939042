import { rest } from 'msw'

import { UpdatePatientStatusServiceResponse } from 'src/features/patients/infrastructure'

import * as settings from 'src/config/settings'
import { updatePatientStatusServiceResponseMock } from 'src/__mocks__/responses/update-patient-status-service-response'

const url = `${settings.COMPASS_API_BASE_URL}/patients/:patientId/status`

export const updatePatientStatusServiceResponseHandler =
  rest.post<UpdatePatientStatusServiceResponse>(url, async (req, res, ctx) => {
    const { patientId } = req.params
    return res(
      ctx.status(200),
      ctx.json({
        ...updatePatientStatusServiceResponseMock,
        patientId: patientId,
      })
    )
  })
