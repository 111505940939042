import {
  GetAllTasksByUserIdServiceResponse,
  GetTodayTasksByUserIdServiceResponse,
  GetDoneTasksByUserIdServiceResponse,
  GetUpcomingTasksByUserIdServiceResponse,
  GetUrgentTasksByUserIdServiceResponse,
  GetOverdueTasksByUserIdServiceResponse,
} from 'src/features/tasks/infrastructure/'

// Mock response for today's tasks
export const getTodayTasksByUserIdServiceResponseMock: GetTodayTasksByUserIdServiceResponse =
  {
    today: [
      {
        eventId: '2be0bab8-52a0-4530-8899-116a6d072244',
        comments: null,
        name: 'Jennifer Lipson',
        patientId: 'fb3479bc-b59a-4c45-9cd0-742bcb508820',
        status: 'new',
        urgent: false,
        dueDate: '2023-10-10T00:00:00.000Z',
        title: 'Call Reminder',
        description: 'Call Reminder',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
        assignedPathwayId: '75d47120-d76b-4c98-8e4d-b147f522ab36',
        createdBy: 'aba1a8b8-f27b-40e8-b2d4-ab8bcccef5f4',
        lastUpdatedAt: '2023-10-03T18:38:17.493Z',
        source: 'system',
        linkedEventId: null,
        isDeleted: false,
        pathwayId: '1415fb60-058d-42ff-bb0b-c87fbf484f74',
        createdAt: '2023-10-03T18:38:17.493Z',
      },
      {
        eventId: '2be0bab8-52a0-4530-8899-116a6d072244',
        comments: null,
        name: 'Jennifer Lipson',
        patientId: 'fb3479bc-b59a-4c45-9cd0-742bcb508820',
        status: 'new',
        urgent: false,
        dueDate: '2023-10-10T00:00:00.000Z',
        title: 'Call Reminder',
        description: 'Call Reminder',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
        assignedPathwayId: '75d47120-d76b-4c98-8e4d-b147f522ab36',
        createdBy: 'aba1a8b8-f27b-40e8-b2d4-ab8bcccef5f4',
        lastUpdatedAt: '2023-10-03T18:38:17.493Z',
        source: 'system',
        linkedEventId: null,
        isDeleted: false,
        pathwayId: '1415fb60-058d-42ff-bb0b-c87fbf484f74',
        createdAt: '2023-10-03T18:38:17.493Z',
      },
    ],
  }

// Mock response for done tasks
export const getDoneTasksByUserIdServiceResponseMock: GetDoneTasksByUserIdServiceResponse =
  {
    done: [
      {
        eventId: 'bd2d7265-0e69-4ba1-affa-03c46e6440b6',
        comments: null,
        name: 'Jennifer Lipson',
        patientId: 'fb3479bc-b59a-4c45-9cd0-742bcb508820',
        status: 'complete',
        urgent: false,
        dueDate: '2023-09-01T00:00:00.000Z',
        title: 'Call Reminder',
        description: 'Call Reminder',
        assignedTCMember: 'E2E Automation ',
        completedAt: '2023-09-28T00:00:00.000Z',
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
        assignedPathwayId: '70dd7e46-c5d8-471d-9b0b-d780627367bb',
        createdBy: 'aba1a8b8-f27b-40e8-b2d4-ab8bcccef5f4',
        lastUpdatedAt: '2023-09-28T00:00:00.000Z',
        source: 'system',
        linkedEventId: '7eaf5929-0341-4253-ab20-0ea3af7b5c80',
        isDeleted: false,
        pathwayId: '51ddf969-d588-4980-9458-cc949fd7485b',
        createdAt: '2023-08-29T13:49:32.697Z',
      },
      {
        eventId: '9c077b4f-9733-446b-9292-b38552fd3b28',
        comments: null,
        name: 'Jennifer Lipson',
        patientId: 'fb3479bc-b59a-4c45-9cd0-742bcb508820',
        status: 'complete',
        urgent: false,
        dueDate: '2023-08-18T00:00:00.000Z',
        title: 'Call Reminder',
        description: 'Call Reminder',
        assignedTCMember: 'E2E Automation ',
        completedAt: '2023-08-29T00:00:00.000Z',
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
        assignedPathwayId: '70dd7e46-c5d8-471d-9b0b-d780627367bb',
        createdBy: 'aba1a8b8-f27b-40e8-b2d4-ab8bcccef5f4',
        lastUpdatedAt: '2023-08-29T00:00:00.000Z',
        source: 'system',
        linkedEventId: null,
        isDeleted: false,
        pathwayId: '51ddf969-d588-4980-9458-cc949fd7485b',
        createdAt: '2023-08-29T13:49:32.539Z',
      },
    ],
  }

// Mock response for upcoming tasks
export const getUpcomingTasksByUserIdServiceResponseMock: GetUpcomingTasksByUserIdServiceResponse =
  {
    upcoming: [
      {
        eventId: '2be0bab8-52a0-4530-8899-116a6d072244',
        comments: null,
        name: 'Jennifer Lipson',
        patientId: 'fb3479bc-b59a-4c45-9cd0-742bcb508820',
        status: 'new',
        urgent: false,
        dueDate: '2023-10-10T00:00:00.000Z',
        title: 'Call Reminder',
        description: 'Call Reminder',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
        assignedPathwayId: '75d47120-d76b-4c98-8e4d-b147f522ab36',
        createdBy: 'aba1a8b8-f27b-40e8-b2d4-ab8bcccef5f4',
        lastUpdatedAt: '2023-10-03T18:38:17.493Z',
        source: 'system',
        linkedEventId: null,
        isDeleted: false,
        pathwayId: '1415fb60-058d-42ff-bb0b-c87fbf484f74',
        createdAt: '2023-10-03T18:38:17.493Z',
      },
    ],
    lastTaskId: 'f682005b-f9f9-4048-935f-d46c6bb05130',
  }

// Mock response for urgent tasks
export const getUrgentTasksByUserIdServiceResponseMock: GetUrgentTasksByUserIdServiceResponse =
  {
    urgent: [],
    lastTaskId: '164a69fb-747c-4fe4-86d0-861745b162b5',
  }

// Mock response for overdue tasks
export const getOverdueTasksByUserIdServiceResponseMock: GetOverdueTasksByUserIdServiceResponse =
  {
    overdue: [],
  }

export const getAllTasksByUserIdServiceResponseMock: GetAllTasksByUserIdServiceResponse =
  {
    tasks: [
      {
        eventId: '2be0bab8-52a0-4530-8899-116a6d072244',
        comments: null,
        name: 'Jennifer Lipson',
        patientId: 'fb3479bc-b59a-4c45-9cd0-742bcb508820',
        status: 'new',
        urgent: false,
        dueDate: '2023-10-10T00:00:00.000Z',
        title: 'Call Reminder',
        description: 'Call Reminder',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
        assignedPathwayId: '75d47120-d76b-4c98-8e4d-b147f522ab36',
        createdBy: 'aba1a8b8-f27b-40e8-b2d4-ab8bcccef5f4',
        lastUpdatedAt: '2023-10-03T18:38:17.493Z',
        source: 'system',
        linkedEventId: null,
        isDeleted: false,
        pathwayId: '1415fb60-058d-42ff-bb0b-c87fbf484f74',
        createdAt: '2023-10-03T18:38:17.493Z',
      },
    ],
    lastTaskId: '164a69fb-747c-4fe4-86d0-861745b162b5',
  }
