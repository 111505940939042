import { CreateProviderServiceResponse } from 'src/features/providers/infrastructure'

export const createProviderServiceResponseMock: CreateProviderServiceResponse =
  {
    id: 'AddedProvider',
    groupId: 'c7f89a3c-d742-4111-9cf2-11377a50c20f',
    firstName: 'Harry',
    lastName: 'AddedProvided',
    npi: '1699963231',
    createdAt: '2023-08-31T00:30:28.415Z',
    createdBy: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
  }
