import { useMemo } from 'react'

import { CompassUserRole } from 'src/features/shared/domain'
import { useRolesAndPermissionsStore } from 'src/features/shared/infrastructure'

const PERMISSIONS: Record<CompassUserRole, string[]> = {
  admin: [],
  clinical: ['pathway:edit', 'intake:create'],
  non_clinical_staff: [],
  rn_navigator: [],
  pt_navigator: [],
  msk_assessment_partner: [],
}

PERMISSIONS['admin'] = [
  ...PERMISSIONS['admin'],
  ...PERMISSIONS['clinical'],
  ...PERMISSIONS['non_clinical_staff'],
  ...PERMISSIONS['rn_navigator'],
  ...PERMISSIONS['pt_navigator'],
  ...PERMISSIONS['msk_assessment_partner'],
]

export const useRolesAndPermissions = () => {
  const { userRoles, userType } = useRolesAndPermissionsStore()

  const compassRoles = useMemo(() => {
    return userRoles || []
  }, [userRoles])

  const hasRole = useMemo(
    () =>
      (
        role: CompassUserRole | CompassUserRole[],
        operator: 'or' | 'and' = 'or'
      ) => {
        if (typeof role === 'string') {
          return compassRoles.includes(role)
        }
        if (Array.isArray(role)) {
          if (operator === 'or') {
            return role.some((_role) => compassRoles.includes(_role))
          }
          if (operator === 'and') {
            return role.every((_role) => compassRoles.includes(_role))
          }
        }
      },
    [compassRoles]
  )

  const hasRoleOrIsAdmin = useMemo(
    () => (role: CompassUserRole) => {
      return hasRole([role, 'admin'])
    },
    [hasRole]
  )

  const isAdmin = useMemo(() => {
    return hasRole('admin')
  }, [hasRole])

  const isClinical = useMemo(
    (checkIsAdmin = true) => {
      return checkIsAdmin ? hasRoleOrIsAdmin('clinical') : hasRole('clinical')
    },
    [hasRole, hasRoleOrIsAdmin]
  )

  const isNonClinical = useMemo(
    (checkIsAdmin = true) => {
      return checkIsAdmin
        ? hasRoleOrIsAdmin('non_clinical_staff')
        : hasRole('non_clinical_staff')
    },
    [hasRole, hasRoleOrIsAdmin]
  )

  const isRnNavigator = useMemo(
    (checkIsAdmin = true) => {
      return checkIsAdmin
        ? hasRoleOrIsAdmin('rn_navigator')
        : hasRole('rn_navigator')
    },
    [hasRole, hasRoleOrIsAdmin]
  )

  const isPtNavigator = useMemo(
    (checkIsAdmin = true) => {
      return checkIsAdmin
        ? hasRoleOrIsAdmin('pt_navigator')
        : hasRole('pt_navigator')
    },
    [hasRole, hasRoleOrIsAdmin]
  )

  const isMskAssessmentPartner = useMemo(
    (checkIsAdmin = true) => {
      return checkIsAdmin
        ? hasRoleOrIsAdmin('msk_assessment_partner')
        : hasRole('msk_assessment_partner')
    },
    [hasRole, hasRoleOrIsAdmin]
  )

  const userTypeIs = useMemo(
    () => (typeCheck: string | null) => {
      return userType ? userType === typeCheck : false
    },
    [userType]
  )

  const hasPermissions = useMemo(
    () => (permissions: string | string[]) => {
      let _permissions: string[] = []
      if (typeof permissions === 'string') {
        _permissions = [permissions]
      } else {
        _permissions = permissions
      }

      // TODO: improve this
      for (const role of compassRoles) {
        for (const permission of _permissions) {
          if (PERMISSIONS[role].includes(permission)) {
            return true
          }
        }
      }
      return false
    },
    [compassRoles]
  )

  return {
    compassRoles,
    hasRole,
    userTypeIs,
    isAdmin,
    hasRoleOrIsAdmin,
    isClinical,
    isNonClinical,
    isRnNavigator,
    isPtNavigator,
    isMskAssessmentPartner,
    hasPermissions,
  }
}
