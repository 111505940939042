import { useEligibilityServiceAdapter } from 'src/features/eligibility/adapters'
import { UseEligibilityUseCases } from 'src/features/eligibility/domain'

type MakeUseEligibilityUseCases = () => UseEligibilityUseCases

const makeUseEligibilityUseCases: MakeUseEligibilityUseCases = () => () => {
  const eligibilityServiceAdapter = useEligibilityServiceAdapter()

  return {
    getEligiblePatients: eligibilityServiceAdapter.getEligiblePatients,
  }
}

export const useEligibilityUseCases: UseEligibilityUseCases =
  makeUseEligibilityUseCases()
