import { useAuth } from 'src/features/shared/infrastructure'
import { QueuePatientsTableRow } from 'src/features/queues/presentation'

export const useQueuePatientViewersHistory = () => {
  const { amplifyUser } = useAuth()

  const getOthersViewersHistory = (patient: QueuePatientsTableRow | null) => {
    if (
      patient &&
      patient.patientViewersHistory &&
      patient.patientViewersHistory.length > 0
    ) {
      return patient.patientViewersHistory.filter(
        (view) => view.viewedBy.email !== amplifyUser?.attributes?.email
      )
    }
    return null
  }

  const getLastViewerEmail = (patient: QueuePatientsTableRow | null) => {
    if (!patient) return null

    const othersViewersHistory = getOthersViewersHistory(patient)
    if (othersViewersHistory && othersViewersHistory.length > 0) {
      return othersViewersHistory[0].viewedBy.email
    }

    return null
  }

  const getLastViewersNames = (patient: QueuePatientsTableRow | null) => {
    if (!patient) return []

    const othersViewersHistory = getOthersViewersHistory(patient)
    if (othersViewersHistory && othersViewersHistory.length > 0) {
      return othersViewersHistory.map((view) => view.viewedBy.name)
    }

    return ['Someone']
  }

  const isBusy = (patient: QueuePatientsTableRow | null) => {
    const lastViewerEmail = getLastViewerEmail(patient)
    if (patient) {
      if (lastViewerEmail) {
        return (
          patient.isBusy && lastViewerEmail !== amplifyUser?.attributes?.email
        )
      }
      return false
    }
    return false
  }

  return {
    isBusy,
    getOthersViewersHistory,
    getLastViewerEmail,
    getLastViewersNames,
  }
}
