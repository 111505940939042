import { FC, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { View } from 'react-big-calendar'

import { useTasksStore } from 'src/features/shared/infrastructure'
import { mapToTaskCalendarEvent } from 'src/features/tasks/adapters'
import {
  RefreshButton,
  TaskCalendar,
  TaskCalendarEvent,
} from 'src/features/shared/presentation'

import {
  UserCalendarTasksEvent,
  useUserCalendarTasks,
  UserCalendarAgendaTasksEvent,
  UserCalendarDayTasksEvent,
} from 'src/features/tasks/presentation'

import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Task } from '../../domain'
import {
  getFirstAndLastDayOfMonth,
  getFirstAndLastDayOfWeek,
  getFirstAndLastDayOfAgenda,
} from 'src/features/shared/utils'

export const UserTasksCalendarModal: FC = () => {
  const {
    setUserTasksCalendarDateRange,
    userAllTasks,
    userUpcomingTasks,
    userUrgentTasks,
    userTodayTasks,
    userOverdueTasks,
    userDoneTasks,
    refreshUserCalendarTasks,
    isLoadingUserCalendarTasks,
  } = useUserCalendarTasks()

  const {
    openUserTasksCalendarModal,
    setEditAgendaMultipleTasksFormModalOpen,
    setUserOpenTasksCalendarModal,
    setUserTasksCalendarAgendaSelectedTasks,
    setUserTasksCalendarDaySelectedTasks,
    setUserTasksCalendarView,
    userTasksCalendarAgendaSelectedTasks,
    userTasksCalendarDaySelectedTasks,
    userTasksCalendarView,
    setEditDayMultipleTasksFormModalOpen,
  } = useTasksStore()

  const getSortName = (fullName: string): string => {
    const nameParts = fullName.split(' ')
    return nameParts[nameParts.length - 1]
  }

  const sortTasksByPatientLastName = (tasks: Task[]): Task[] => {
    return tasks.sort((a, b) => {
      const lastNameA = getSortName(a.patientName ?? '')
      const lastNameB = getSortName(b.patientName ?? '')
      return lastNameA.localeCompare(lastNameB)
    })
  }

  const getCurrentTasks = (timeFrame?: string): Task[] => {
    let tasks: Task[] = []

    switch (timeFrame) {
      case 'todo':
        tasks = userAllTasks.filter(
          (task) => !userDoneTasks.some((doneTask) => doneTask.id === task.id)
        )
        break
      case 'all':
        tasks = userAllTasks
        break
      case 'urgent':
        tasks = userUrgentTasks
        break
      case 'today':
        tasks = userTodayTasks
        break
      case 'overdue':
        tasks = userOverdueTasks
        break
      case 'upcoming':
        tasks = userUpcomingTasks
        break
      case 'done':
        tasks = userDoneTasks
        break
      default:
        tasks = userAllTasks
        break
    }
    return sortTasksByPatientLastName(tasks)
  }

  const getCurrentEvents = (timeFrame?: string): TaskCalendarEvent[] => {
    return getCurrentTasks(timeFrame).map(mapToTaskCalendarEvent)
  }

  const [timeFrame, setTimeFrame] = useState('todo')
  const currentEvents = getCurrentEvents(timeFrame)

  const clearTaskSelection = () => {
    setUserTasksCalendarAgendaSelectedTasks([])
    setUserTasksCalendarDaySelectedTasks([])
  }

  const handleClose = () => {
    clearTaskSelection()
    setUserOpenTasksCalendarModal(false)

    // Reset date range based on which view the user left on
    let viewDefaultDateRange: Date[] = []
    switch (userTasksCalendarView) {
      case 'month':
        viewDefaultDateRange = getFirstAndLastDayOfMonth()
        break
      case 'week':
        viewDefaultDateRange = getFirstAndLastDayOfWeek()
        break
      case 'day':
        viewDefaultDateRange = [new Date(), new Date()]
        break
      case 'agenda':
        viewDefaultDateRange = getFirstAndLastDayOfAgenda()
        break
    }
    setUserTasksCalendarDateRange(viewDefaultDateRange)
  }

  const handleSelectTaskChange = (event: SelectChangeEvent) => {
    clearTaskSelection()
    setTimeFrame(event.target.value)
  }

  const handleRefreshTasksClick = () => {
    clearTaskSelection()
    refreshUserCalendarTasks({ [timeFrame]: true })
  }

  const handleRangeChange = (startDate: Date, endDate: Date) => {
    clearTaskSelection()
    setUserTasksCalendarDateRange([startDate, endDate])
  }

  const handleViewChange = (view: View) => {
    clearTaskSelection()
    setUserTasksCalendarView(view)
  }

  const handleUpdateSelectedButtonClick = () => {
    if (userTasksCalendarView === 'agenda') {
      setEditAgendaMultipleTasksFormModalOpen(true)
    }
    if (userTasksCalendarView === 'day') {
      setEditDayMultipleTasksFormModalOpen(true)
    }
  }

  const handleSelectAllCheckboxChange = () => {
    if (userTasksCalendarView === 'agenda') {
      if (
        userTasksCalendarAgendaSelectedTasks.length ===
        getCurrentTasks(timeFrame).length
      ) {
        setUserTasksCalendarAgendaSelectedTasks([])
      } else {
        setUserTasksCalendarAgendaSelectedTasks(
          getCurrentTasks(timeFrame).map((task) => structuredClone(task))
        )
      }
    }
    if (userTasksCalendarView === 'day') {
      if (
        userTasksCalendarDaySelectedTasks.length ===
        getCurrentTasks(timeFrame).length
      ) {
        setUserTasksCalendarDaySelectedTasks([])
      } else {
        setUserTasksCalendarDaySelectedTasks(
          getCurrentTasks(timeFrame).map((task) => structuredClone(task))
        )
      }
    }
  }

  const handleNavigate = () => {
    clearTaskSelection()
  }

  const isUpdateSelectedDisabled = () => {
    if (userTasksCalendarView === 'agenda') {
      return (
        getCurrentTasks(timeFrame).length === 0 ||
        userTasksCalendarAgendaSelectedTasks.length === 0
      )
    }
    if (userTasksCalendarView === 'day') {
      return (
        getCurrentTasks(timeFrame).length === 0 ||
        userTasksCalendarDaySelectedTasks.length === 0
      )
    }

    return true
  }

  const isSelectAllCheckboxChecked = () => {
    if (userTasksCalendarView === 'agenda') {
      return (
        getCurrentTasks(timeFrame).length > 0 &&
        userTasksCalendarAgendaSelectedTasks.length ===
          getCurrentTasks(timeFrame).length
      )
    }
    if (userTasksCalendarView === 'day') {
      return (
        getCurrentTasks(timeFrame).length > 0 &&
        userTasksCalendarDaySelectedTasks.length ===
          getCurrentTasks(timeFrame).length
      )
    }
    return false
  }

  const isSelectAllCheckboxDisabled = () => {
    if (userTasksCalendarView === 'agenda') {
      return getCurrentTasks(timeFrame).length === 0
    }
    if (userTasksCalendarView === 'day') {
      return getCurrentTasks(timeFrame).length === 0
    }
    return true
  }

  return (
    <Dialog
      onClose={handleClose}
      open={openUserTasksCalendarModal}
      sx={{
        zIndex: 2,
        '& .MuiPaper-root': {
          xs: {
            maxWidth: '1024px',
            minWidth: '768px',
          },
        },
      }}
    >
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '48px',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <Typography>User Tasks Calendar</Typography>
            <RefreshButton
              onClick={handleRefreshTasksClick}
              isRefreshing={isLoadingUserCalendarTasks}
            />
          </Box>
          {['agenda', 'day'].includes(userTasksCalendarView) ? (
            <Box
              sx={{
                display: 'flex',
                gap: '16px',
                alignItems: 'center',
              }}
            >
              <FormControlLabel
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontSize: '18px',
                  },
                }}
                control={
                  <Checkbox
                    onChange={handleSelectAllCheckboxChange}
                    checked={isSelectAllCheckboxChecked()}
                    disabled={isSelectAllCheckboxDisabled()}
                    color="primary"
                  />
                }
                label={'Select All'}
              />
              <Button
                color="secondary"
                variant="contained"
                size="small"
                sx={{ height: '30px', lineHeight: 0, paddingTop: '6px' }}
                onClick={handleUpdateSelectedButtonClick}
                disabled={isUpdateSelectedDisabled()}
              >
                Update Selected
              </Button>
            </Box>
          ) : null}
          <FormControl
            sx={{
              width: '180px',
              '& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
                color: 'secondary.main',
              },
            }}
          >
            <InputLabel>Filter By</InputLabel>
            <Select
              size={'small'}
              value={timeFrame}
              label="Filter By"
              onChange={handleSelectTaskChange}
              variant="outlined"
              color={'secondary'}
              slotProps={{
                input: {
                  color: 'secondary',
                },
              }}
              sx={{
                background: 'white',
              }}
            >
              <MenuItem value={'todo'}>To Do Tasks</MenuItem>
              <MenuItem value={'all'}>All Tasks</MenuItem>
              <MenuItem value={'urgent'}>Urgent Tasks</MenuItem>
              <MenuItem value={'today'}>Today Tasks</MenuItem>
              <MenuItem value={'overdue'}>Overdue Tasks</MenuItem>
              <MenuItem value={'upcoming'}>Upcoming Tasks</MenuItem>
              <MenuItem value={'done'}>Done Tasks</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent className={`user-calendar-${userTasksCalendarView}-view`}>
        <TaskCalendar
          key={`user_${timeFrame}_tasks_calendar`}
          events={isLoadingUserCalendarTasks ? [] : currentEvents}
          onRangeChange={handleRangeChange}
          EventComponent={UserCalendarTasksEvent}
          AgendaEventComponent={UserCalendarAgendaTasksEvent}
          DayEventComponent={UserCalendarDayTasksEvent}
          onViewChange={handleViewChange}
          onNavigate={handleNavigate}
          isLoading={isLoadingUserCalendarTasks}
          view={userTasksCalendarView}
        />
      </DialogContent>
      <Button color="secondary" onClick={handleClose}>
        Close
      </Button>
    </Dialog>
  )
}
