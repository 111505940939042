import { useQuery } from '@tanstack/react-query'

import { useTasksUseCases } from 'src/features/tasks/tasks.service-locator'
import { GetTasksParams } from 'src/features/tasks/domain'

export const GET_URGENT_TASKS_BY_USER_ID_QUERY_KEY = 'getUrgentTasksByUserId'

type UseGetUrgentTasksByUserIdArgs = {
  params?: GetTasksParams
}

export const useGetUrgentTasksByUserIdQuery = ({
  params,
}: UseGetUrgentTasksByUserIdArgs = {}) => {
  const tasksUseCases = useTasksUseCases()

  const { data, isLoading, isError, refetch, isFetching } = useQuery({
    queryKey: [GET_URGENT_TASKS_BY_USER_ID_QUERY_KEY, params],
    queryFn: () => {
      return tasksUseCases.getUrgentTasksByUserId({
        params,
      })
    },
  })
  return {
    userUrgentTasksData: data,
    userUrgentTasks: data?.tasks ? data.tasks : [],
    refetchUserUrgentTasks: refetch,
    userUrgentTasksIsLoading: isLoading,
    userUrgentTasksIsError: isError,
    userUrgentTasksIsFetching: isFetching,
  }
}
