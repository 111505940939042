import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import {
  SearchDuplicatePatientParams,
  SearchDuplicatePatientsError,
  SearchDuplicatePatientsReturns,
} from 'src/features/patients/domain'
import { usePatientsUseCases } from 'src/features/patients/patients.service-locator'

export const SEARCH_DUPLICATE_PATIENTS_QUERY_KEY =
  'SearchDuplicatePatientsQueryKey'

type UseSearchDuplicatePatientsQueryProps = UseQueryOptions<
  SearchDuplicatePatientsReturns,
  unknown,
  SearchDuplicatePatientsReturns
> &
  SearchDuplicatePatientParams

type UseSearchDuplicatePatientsQuery = (
  props: UseSearchDuplicatePatientsQueryProps
) => {
  duplicatePatientsResults: SearchDuplicatePatientsReturns
  duplicatePatientsIsError: boolean
  duplicatePatientsIsLoading: boolean
}

export const useSearchDuplicatePatientsQuery: UseSearchDuplicatePatientsQuery =
  ({ phone, firstName, lastName, dob }) => {
    const patientsUseCases = usePatientsUseCases()
    const shouldExecuteQuery = !!phone && !!firstName && !!lastName && !!dob
    const { data, isLoading, isError } = useQuery<
      SearchDuplicatePatientsReturns,
      SearchDuplicatePatientsError
    >(
      [SEARCH_DUPLICATE_PATIENTS_QUERY_KEY, phone, firstName, lastName, dob],
      () =>
        patientsUseCases.searchDuplicatePatients({
          phone,
          firstName,
          lastName,
          dob,
        }),
      {
        retry: false,
        enabled: shouldExecuteQuery,
      }
    )

    return {
      duplicatePatientsResults: data ?? [],
      duplicatePatientsIsError: isError,
      duplicatePatientsIsLoading: isLoading,
    }
  }
