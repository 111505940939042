import { useQuery } from '@tanstack/react-query'

import { usePatientsUseCases } from 'src/features/patients/patients.service-locator'
import {
  GetPatientPainAndFunctionV2Error,
  GetPatientPainAndFunctionV2Returns,
} from 'src/features/patients/domain'
import { usePatientId } from 'src/features/shared/presentation'

export const GET_PATIENT_PAIN_AND_FUNCTION_QUERY_KEY_V2 =
  'getPatientPainAndFunctionV2'

type UseGetPatientPainAndFunctionQueryV2Props = {
  patientId?: string
  throwGetPatientIdError?: boolean
}

export const useGetPatientPainAndFunctionQueryV2 = ({
  patientId,
  throwGetPatientIdError = true,
}: UseGetPatientPainAndFunctionQueryV2Props = {}) => {
  const patientsUseCases = usePatientsUseCases()

  // TODO: find a better way to get the patient id
  const _patientId = usePatientId({ patientId, throwGetPatientIdError })

  const { data, isLoading, isError } = useQuery<
    GetPatientPainAndFunctionV2Returns | null,
    GetPatientPainAndFunctionV2Error
  >([GET_PATIENT_PAIN_AND_FUNCTION_QUERY_KEY_V2, _patientId], () => {
    return _patientId
      ? patientsUseCases.getPatientPainAndFunctionV2(_patientId)
      : null
  })

  return {
    patientPainAndFunctionConditions: data ? data.conditions : [],
    getPatientPainAndFunctionIsLoading: isLoading,
    getPatientPainAndFunctionIsError: isError,
  }
}
