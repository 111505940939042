import { GetMyCompassUserServiceResponse } from 'src/features/shared/infrastructure'

export const getMyCompassUserServiceResponseMock: GetMyCompassUserServiceResponse =
  {
    id: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
    email: 'e2e@tailorcare.com',
    description: 'End to End Automation Account',
    active: true,
    createdAt: '2023-05-30T13:41:12.614Z',
    lastUpdatedAt: '2023-05-30T13:41:12.614Z',
    name: 'E2E Automation ',
    role: null,
    type: 'non-clinical',
    roles: ['admin'],
  }
