import { UseProvidersUseCases } from 'src/features/providers/domain'
import { useProvidersServiceAdapter } from 'src/features/providers/adapters'

type MakeUseProvidersUseCases = () => UseProvidersUseCases

const makeUseProvidersUseCases: MakeUseProvidersUseCases = () => () => {
  const providersServiceAdapter = useProvidersServiceAdapter()

  return {
    getProviders: providersServiceAdapter.getProviders,
    createProvider: providersServiceAdapter.createProvider,
    getProviderGroups: providersServiceAdapter.getProviderGroups,
    createProviderGroup: providersServiceAdapter.createProviderGroup,
    searchNPPES: providersServiceAdapter.searchNPPES,
  }
}

export const useProvidersUseCases: UseProvidersUseCases =
  makeUseProvidersUseCases()
