import { rest } from 'msw'

import {
  CreatePatientCustomNoteServiceResponse,
  CreatePatientIntakeNoteServiceResponse,
  CreatePatientOrthoChartCheckNoteServiceResponse,
  CreatePatientOrthoTouchpointNoteServiceResponse,
  CreatePatientOutreachNoteServiceResponse,
  CreatePatientPTTouchpointNoteServiceResponse,
  CreatePatientPostOpTouchpointNoteServiceResponse,
  CreatePatientPreOpTouchpointNoteServiceResponse,
  CreatePatientReferralUploadNoteServiceResponse,
  CreatePatientSelfCareTouchpointNoteServiceResponse,
} from 'src/features/notes/infrastructure'
import {
  createPatientCustomNoteServiceResponseMock,
  createPatientIntakeNoteServiceResponseMock,
  createPatientOrthoChartCheckNoteServiceResponseMock,
  createPatientOrthoTouchpointNoteServiceResponseMock,
  createPatientOutreachNoteServiceResponseMock,
  createPatientPTTouchpointNoteServiceResponseMock,
  createPatientPostOpTouchpointNoteServiceResponseMock,
  createPatientPreOpTouchpointNoteServiceResponseMock,
  createPatientReferralUploadNoteServiceResponseMock,
  createPatientSelfCareTouchpointNoteServiceResponseMock,
} from 'src/__mocks__/responses/create-patient-note-service-response'

import * as settings from 'src/config/settings'

const url = `${settings.COMPASS_API_BASE_URL}/notes`

export const createPatientNoteServiceResponseHandler = rest.post<
  | CreatePatientCustomNoteServiceResponse
  | CreatePatientIntakeNoteServiceResponse
  | CreatePatientOrthoChartCheckNoteServiceResponse
  | CreatePatientOrthoTouchpointNoteServiceResponse
  | CreatePatientOutreachNoteServiceResponse
  | CreatePatientPTTouchpointNoteServiceResponse
  | CreatePatientPostOpTouchpointNoteServiceResponse
  | CreatePatientPreOpTouchpointNoteServiceResponse
  | CreatePatientReferralUploadNoteServiceResponse
  | CreatePatientSelfCareTouchpointNoteServiceResponse
>(url, async (req, res, ctx) => {
  const body = await req.json()
  const { type } = body

  if (type === 'Self-Care Touchpoint') {
    return res(
      ctx.status(200),
      ctx.json(createPatientSelfCareTouchpointNoteServiceResponseMock)
    )
  }
  if (type === 'Intake') {
    return res(
      ctx.status(200),
      ctx.json(createPatientIntakeNoteServiceResponseMock)
    )
  }
  if (type === 'Ortho Chart Check') {
    return res(
      ctx.status(200),
      ctx.json(createPatientOrthoChartCheckNoteServiceResponseMock)
    )
  }
  if (type === 'Ortho Touchpoint') {
    return res(
      ctx.status(200),
      ctx.json(createPatientOrthoTouchpointNoteServiceResponseMock)
    )
  }
  if (type === 'Outreach') {
    return res(
      ctx.status(200),
      ctx.json(createPatientOutreachNoteServiceResponseMock)
    )
  }
  if (type === 'PostOpTouchpoint') {
    return res(
      ctx.status(200),
      ctx.json(createPatientPTTouchpointNoteServiceResponseMock)
    )
  }
  if (type === 'Post-Op Touchpoint') {
    return res(
      ctx.status(200),
      ctx.json(createPatientPostOpTouchpointNoteServiceResponseMock)
    )
  }
  if (type === 'Pre-Op Touchpoint') {
    return res(
      ctx.status(200),
      ctx.json(createPatientPreOpTouchpointNoteServiceResponseMock)
    )
  }
  if (type === 'Referral Upload') {
    return res(
      ctx.status(200),
      ctx.json(createPatientReferralUploadNoteServiceResponseMock)
    )
  }

  return res(
    ctx.status(200),
    ctx.json(createPatientCustomNoteServiceResponseMock)
  )
})
