import { Box, Divider, Stack, Typography } from '@mui/material'
import { FieldPath, FieldValues } from 'react-hook-form'
import { FC } from 'react'

import {
  ControlledSelectField,
  ControlledTextField,
  PARTICIPATING_IN_PT_OPTIONS,
  PT_LOCATIONS,
  YES_NO_OPTIONS,
} from 'src/features/shared/presentation'
import {
  NOTE_FIELD_LABEL,
  noteFieldLabelDefaultSx,
} from 'src/features/notes/presentation'
import { mapToControlledSelectItem } from 'src/features/shared/adapters'
import { filterNoneValueOptions } from 'src/features/notes/adapters'
import { NoteData } from 'src/features/notes/domain'

export type PostOpTouchpointQuestionsFormV2Values = Pick<
  NoteData,
  | 'agreeYouUnderstandCondition'
  | 'agreeYouUnderstandTreatmentOptions'
  | 'isPainControlled'
  | 'accessToMedicineToControlPain'
  | 'participatingInPT'
  | 'physicalTherapyLocation'
  | 'additionalQuestionsOrConcerns'
  | 'sdmPatientUnderstandsCondition'
  | 'sdmClinicalBelievesPatientUnderstandsCondition'
  | 'sdmText'
  | 'procedure'
  | 'surgerySite'
  | 'dischargePosition'
  | 'patientGoals'
  | 'reasonForRefusal'
>

type PostOpTouchpointAdditionalQuestionsFormV2Props = {
  isPainControlledFieldName?: FieldPath<FieldValues>
  accessToMedicineToControlPainFieldName?: FieldPath<FieldValues>
  participatingInPTFieldName?: FieldPath<FieldValues>
  additionalQuestionsOrConcernsFieldName?: FieldPath<FieldValues>
  physicalTherapyLocationFieldName?: FieldPath<FieldValues>
  isPainControlledRequired?: boolean
  accessToMedicineToControlPainRequired?: boolean
  participatingInPTRequired?: boolean
  additionalQuestionsOrConcernsRequired?: boolean
  physicalTherapyLocationRequired?: boolean
}

export const PostOpTouchpointAdditionalQuestionsFormV2: FC<
  PostOpTouchpointAdditionalQuestionsFormV2Props
> = ({
  isPainControlledFieldName = 'isPainControlled',
  accessToMedicineToControlPainFieldName = 'accessToMedicineToControlPain',
  participatingInPTFieldName = 'participatingInPT',
  additionalQuestionsOrConcernsFieldName = 'additionalQuestionsOrConcerns',
  physicalTherapyLocationFieldName = 'physicalTherapyLocation',
  isPainControlledRequired,
  accessToMedicineToControlPainRequired,
  participatingInPTRequired,
  additionalQuestionsOrConcernsRequired,
  physicalTherapyLocationRequired,
}) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: '8px',
          width: '170px',
          whiteSpace: 'nowrap',
          flexDirection: 'column',
        }}
      >
        <Divider />
        <Typography pb={'16px'} color={'grey'}>
          Assessment Questions
        </Typography>
      </Box>
      <Stack direction={'column'} rowGap={'8px'}>
        {isPainControlledRequired ? (
          <>
            <ControlledSelectField
              name={isPainControlledFieldName}
              label={NOTE_FIELD_LABEL.isPainControlled}
              items={filterNoneValueOptions(YES_NO_OPTIONS).map(
                mapToControlledSelectItem
              )}
              required={isPainControlledRequired}
              selectSx={{ width: '88px' }}
              formLabelSx={noteFieldLabelDefaultSx}
            />
            {accessToMedicineToControlPainRequired ? (
              <ControlledSelectField
                name={accessToMedicineToControlPainFieldName}
                label={NOTE_FIELD_LABEL.accessToMedicineToControlPain}
                items={filterNoneValueOptions(YES_NO_OPTIONS).map(
                  mapToControlledSelectItem
                )}
                placeholderItem="Select Option"
                selectSx={{ width: 'min-content' }}
                helperText="If No, ensure the patient is connected to MD office"
                required={accessToMedicineToControlPainRequired}
                formLabelSx={{
                  ...noteFieldLabelDefaultSx,
                  whiteSpace: 'pre-wrap',
                  width: '90%',
                  height: 'min-height',
                }}
              />
            ) : null}
          </>
        ) : null}

        <ControlledSelectField
          name={participatingInPTFieldName}
          label={NOTE_FIELD_LABEL.participatingInPT}
          items={PARTICIPATING_IN_PT_OPTIONS.map(mapToControlledSelectItem)}
          required={participatingInPTRequired}
          selectSx={{ minWidth: '88px' }}
          formLabelSx={noteFieldLabelDefaultSx}
        />
        {physicalTherapyLocationRequired ? (
          <ControlledSelectField
            name={physicalTherapyLocationFieldName}
            label={NOTE_FIELD_LABEL.physicalTherapyLocation}
            labelComponent={'inputLabel'}
            items={PT_LOCATIONS.map(mapToControlledSelectItem)}
            required={physicalTherapyLocationRequired}
            formControlProps={{
              size: 'small',
            }}
            formControlSx={{
              width: '340px',
            }}
          />
        ) : null}
        <ControlledTextField
          name={additionalQuestionsOrConcernsFieldName}
          label={NOTE_FIELD_LABEL.additionalQuestionsOrConcerns}
          required={additionalQuestionsOrConcernsRequired}
          formLabelSx={noteFieldLabelDefaultSx}
          textFieldSx={{ width: '100%' }}
        />
      </Stack>
    </>
  )
}
