import {
  UseMutateFunction,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import {
  CreatePayorArgs,
  CreatePayorError,
  CreatePayorReturns,
} from 'src/features/providers/domain'
import { usePayorsUseCases } from 'src/features/providers/payors.service-locator'
import { GET_PAYORS_QUERY_KEY } from 'src/features/providers/presentation'

type useCreatePayorProps = Omit<
  UseMutationOptions<
    CreatePayorReturns,
    CreatePayorError,
    CreatePayorArgs,
    unknown
  >,
  'mutationFn'
>

type UseCreatePayor = (props?: useCreatePayorProps) => {
  createPayor: UseMutateFunction<
    CreatePayorReturns,
    CreatePayorError,
    CreatePayorArgs,
    unknown
  >
  createPayorIsLoading: boolean
  createPayorIsError: boolean
  createPayorStatus: string
}

export const useCreatePayorQuery: UseCreatePayor = (props = {}) => {
  const payorsUseCases = usePayorsUseCases()
  const queryClient = useQueryClient()

  const { onSuccess } = props

  const { mutate, isLoading, isError, status } = useMutation<
    CreatePayorReturns,
    CreatePayorError,
    CreatePayorArgs,
    unknown
  >({
    mutationFn: (args: CreatePayorArgs) => {
      return payorsUseCases.createPayor(args)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [GET_PAYORS_QUERY_KEY],
      })
      onSuccess?.(data, variables, context)
    },
  })

  return {
    createPayor: mutate,
    createPayorIsLoading: isLoading,
    createPayorIsError: isError,
    createPayorStatus: status,
  }
}
