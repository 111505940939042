import { Box } from '@mui/material'
import { FC } from 'react'

import { ChildrenInProps } from 'src/features/shared/types'

export const TCTextDivider: FC<ChildrenInProps> = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        '&::after': {
          content: '""',
          position: 'relative',
          borderTop: 'thin solid rgba(0, 0, 0, 0.12)',
          top: '50%',
          transform: 'translateY(50%)',
          flex: '1 1 auto',
        },
      }}
    >
      <Box
        sx={{
          mr: '16px',
        }}
      >
        {children}
      </Box>
    </Box>
  )
}
