import * as yup from 'yup'
import { DevTool } from '@hookform/devtools'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Divider, Stack, Tooltip } from '@mui/material'
import { FC, useState } from 'react'
import {
  useForm,
  SubmitHandler,
  FormProvider,
  Controller,
} from 'react-hook-form'
import { toast } from 'react-toastify'

import { mapToUpdatePatientGlancePayload } from 'src/features/patients/adapters'
import { PatientV2 } from 'src/features/patients/domain'
import {
  BackdropLoader,
  ControlledTextField,
  FormFieldContainer,
  TCReactSelect,
  useRolesAndPermissions,
} from 'src/features/shared/presentation'
import { useUpdatePatient } from 'src/features/patients/presentation'
import { mapToTCReactSelectOption } from 'src/features/shared/adapters'
import {
  useGetPayorsQuery,
  useGetMarketsQuery,
  useGetPartnersQuery,
  useGetPlansQuery,
  useCreatePayorQuery,
  useCreatePlanQuery,
} from 'src/features/providers/presentation'
import { REFERRAL_SOURCES } from 'src/features/shared/constants'

export type PatientGlanceEditFormFields = {
  referralSource?: string
  linkedPayorId?: string
  linkedPlanId?: string
  memberId?: string
  marketId?: string
  contractedWith?: string
}

const patientGlanceEditFormSchema: yup.Schema<PatientGlanceEditFormFields> = yup
  .object()
  .shape({
    referralSource: yup.string().required('Required'),
    linkedPayorId: yup.string(),
    linkedPlanId: yup.string(),
    memberId: yup.string(),
    marketId: yup.string(),
    contractedWith: yup.string(),
  })

type PatientGlanceEditFormProps = {
  patient: PatientV2
  onCancelClick: () => void
  initialValues: PatientGlanceEditFormFields
  onSaveSuccess?: () => void
}

export const PatientGlanceEditForm: FC<PatientGlanceEditFormProps> = ({
  patient,
  initialValues,
  onCancelClick,
  onSaveSuccess,
}) => {
  const formMethods = useForm<PatientGlanceEditFormFields>({
    resolver: yupResolver(patientGlanceEditFormSchema),
    defaultValues: initialValues,
    mode: 'onBlur',
  })
  const linkedPayorIdWatcher = formMethods.watch('linkedPayorId')

  const [showLoader, setShowLoader] = useState(false)
  const { updatePatient } = useUpdatePatient()
  const { createPayor, createPayorIsLoading } = useCreatePayorQuery()
  const { payors, getPayorsIsLoading } = useGetPayorsQuery()
  const { createPlan, createPlanIsLoading } = useCreatePlanQuery()
  const { plans, getPlansIsLoading } = useGetPlansQuery({
    filters: { payorId: linkedPayorIdWatcher },
  })
  const { markets, getMarketsIsLoading } = useGetMarketsQuery()
  const { partners, getPartnersIsLoading } = useGetPartnersQuery()
  const { isAdmin } = useRolesAndPermissions()

  const getCurrentReferralSourceOption = (
    referralSource: string | undefined
  ) => {
    if (!referralSource) {
      return undefined
    } else {
      return mapToTCReactSelectOption(referralSource)
    }
  }

  const getCurrentPayorOption = (payorId: string | undefined) => {
    if (!payorId) {
      return undefined
    }
    const payor = payors.find(({ id }) => id === payorId)
    if (payor) {
      return mapToTCReactSelectOption(payor)
    }
    return undefined
  }

  const getCurrentPlanOption = (planId: string | undefined) => {
    if (!planId) {
      return undefined
    }
    const plan = plans.find(({ id }) => id === planId)
    if (plan) {
      return mapToTCReactSelectOption(plan)
    }
    return undefined
  }

  const getCurrentMarketOption = (marketId: string | undefined) => {
    if (!marketId) {
      return undefined
    }
    const market = markets.find(({ id }) => id === marketId)
    if (market) {
      return mapToTCReactSelectOption(market)
    }
    return undefined
  }

  const getCurrentPartnerOption = (partnerId: string | undefined) => {
    if (!partnerId) {
      return undefined
    }
    const partner = partners.find(({ id }) => id === partnerId)
    if (partner) {
      return mapToTCReactSelectOption(partner)
    }
    return undefined
  }

  function createPayorHandler(name: string) {
    const createPayorArgs = {
      payload: {
        name,
      },
    }
    createPayor(createPayorArgs, {
      onSuccess: (data) => {
        formMethods.setValue('linkedPayorId', data.id)
        formMethods.setValue('linkedPlanId', undefined)
        toast.success(`Payor ${data.name} successfully created!`)
      },
      onError: () => {
        toast.error('Creating Payor failed!')
      },
    })
  }

  function createPlanHandler(name: string) {
    const createPlanArgs = {
      payload: {
        name,
        payorId: formMethods.getValues().linkedPayorId,
      },
    }
    createPlan(createPlanArgs, {
      onSuccess: (data) => {
        formMethods.setValue('linkedPlanId', data.id)
        toast.success(`Plan ${data.name} successfully created!`)
      },
      onError: () => {
        toast.error('Creating Plan failed!')
      },
    })
  }

  const referralSourceChangeHandler = (referralSource: string) => {
    if (referralSource && referralSource.length) {
      formMethods.setValue(
        'referralSource',
        referralSource ? referralSource : undefined,
        { shouldValidate: true }
      )
    }
  }

  const linkedPayorIdChangeHandler = (payorId: string) => {
    if (payorId && payorId.length) {
      const selectedPayor = payors.find(({ id }) => id === payorId)
      formMethods.setValue(
        'linkedPayorId',
        selectedPayor ? selectedPayor.id : undefined,
        { shouldValidate: true }
      )
    } else {
      formMethods.setValue('linkedPlanId', '')
    }
  }

  const linkedPlanIdChangeHandler = (planId: string) => {
    if (planId && planId.length) {
      const selectedPlan = plans.find(({ id }) => id === planId)
      formMethods.setValue(
        'linkedPlanId',
        selectedPlan ? selectedPlan.id : undefined,
        { shouldValidate: true }
      )
    }
  }

  const marketIdChangeHandler = (marketId: string) => {
    if (marketId && marketId.length) {
      const selectedMarket = markets.find(({ id }) => id === marketId)
      formMethods.setValue(
        'marketId',
        selectedMarket ? selectedMarket.id : undefined,
        {
          shouldValidate: true,
        }
      )
    }
  }

  const partnerIdChangeHandler = (partnerId: string) => {
    if (partnerId && partnerId.length) {
      const selectedPartner = partners.find(({ id }) => id === partnerId)
      formMethods.setValue(
        'contractedWith',
        selectedPartner ? selectedPartner.id : undefined,
        {
          shouldValidate: true,
        }
      )
    }
  }

  const submitHandler: SubmitHandler<PatientGlanceEditFormFields> = (data) => {
    setShowLoader(true)
    updatePatient(
      {
        patientId: patient.patientId,
        payload: mapToUpdatePatientGlancePayload(patient, data, payors),
      },
      {
        onSuccess: () => {
          toast.success('Patient succesfully updated!')
          onSaveSuccess?.()
        },
        onError: () => {
          toast.error('Patient update failed!')
        },
        onSettled: () => {
          setShowLoader(false)
        },
      }
    )
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(submitHandler)}>
        <Box
          sx={{
            padding: '48px 48px 24px 24px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '48px',
          }}
        >
          <Divider textAlign="left" sx={{ color: 'gray' }}>
            Patient Glance
          </Divider>
          <Box
            sx={{
              display: 'flex',
              columnGap: '30px',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <Stack
              gap={'8px'}
              alignItems={'left'}
              paddingTop={'12px'}
              width={'20%'}
            >
              <Controller
                name="referralSource"
                control={formMethods.control}
                render={({ field }) => (
                  <FormFieldContainer required={true} label="Referral Source">
                    <TCReactSelect
                      required={true}
                      isClearable
                      options={REFERRAL_SOURCES.map(mapToTCReactSelectOption)}
                      value={getCurrentReferralSourceOption(field.value)}
                      onBlur={field.onBlur}
                      onChange={(option) => {
                        const value = !option ? '' : option.value
                        field.onChange(value)
                        referralSourceChangeHandler(value)
                      }}
                      menuPlacement="auto"
                    />
                  </FormFieldContainer>
                )}
              />
            </Stack>
            <Stack
              gap={'8px'}
              alignItems={'left'}
              paddingTop={'12px'}
              width={'20%'}
            >
              <Box data-testid="edit_patient_glance_select_payor_btn">
                <Controller
                  name="linkedPayorId"
                  control={formMethods.control}
                  render={({ field }) => (
                    <FormFieldContainer label="Payor">
                      <TCReactSelect
                        isCreatable={isAdmin}
                        isClearable
                        isLoading={getPayorsIsLoading || createPayorIsLoading}
                        options={payors.map(mapToTCReactSelectOption)}
                        value={getCurrentPayorOption(field.value)}
                        onBlur={field.onBlur}
                        onChange={(option) => {
                          const value = !option ? '' : option.value
                          field.onChange(value)
                          linkedPayorIdChangeHandler(value)
                        }}
                        creatableProps={{
                          onCreateOption: createPayorHandler,
                        }}
                        menuPlacement="auto"
                      />
                    </FormFieldContainer>
                  )}
                />
              </Box>
            </Stack>
            <Stack
              gap={'8px'}
              alignItems={'left'}
              paddingTop={'12px'}
              width={'20%'}
            >
              <Box data-testid="edit_patient_glance_select_plan_btn">
                <Controller
                  name="linkedPlanId"
                  control={formMethods.control}
                  render={({ field }) => (
                    <FormFieldContainer label="Plan">
                      <TCReactSelect
                        isCreatable={
                          isAdmin &&
                          formMethods.getValues().linkedPayorId !== undefined &&
                          formMethods.getValues().linkedPayorId !== null
                        }
                        isDisabled={!formMethods.getValues().linkedPayorId}
                        isClearable
                        placeholder={'Plan'}
                        isLoading={getPlansIsLoading || createPlanIsLoading}
                        options={plans.map(mapToTCReactSelectOption)}
                        value={getCurrentPlanOption(field.value)}
                        onBlur={field.onBlur}
                        onChange={(option) => {
                          const value = !option ? '' : option.value
                          field.onChange(value)
                          linkedPlanIdChangeHandler(value)
                        }}
                        creatableProps={{
                          onCreateOption: createPlanHandler,
                        }}
                        menuPlacement="auto"
                      />
                    </FormFieldContainer>
                  )}
                />
              </Box>
            </Stack>
            <Tooltip
              title={isAdmin ? '' : 'You must be an admin to edit Member ID'}
            >
              <Stack
                gap={'8px'}
                alignItems={'left'}
                paddingTop={'12px'}
                width={'20%'}
              >
                <Box data-testid="edit_patient_glance_select_member_id_btn">
                  <ControlledTextField
                    name="memberId"
                    label="Member ID"
                    formLabelSx={{ cursor: isAdmin ? '' : 'not-allowed' }}
                    textFieldProps={{
                      disabled: !isAdmin,
                      inputProps: {
                        style: {
                          height: '1.3rem',
                          cursor: isAdmin ? '' : 'not-allowed',
                        },
                      },
                    }}
                  />
                </Box>
              </Stack>
            </Tooltip>
            {!getMarketsIsLoading ? (
              <Stack
                gap={'8px'}
                alignItems={'left'}
                paddingTop={'12px'}
                width={'20%'}
              >
                <Box data-testid="edit_patient_glance_select_market_btn">
                  <Controller
                    name="marketId"
                    control={formMethods.control}
                    render={({ field }) => (
                      <FormFieldContainer label="Market">
                        <TCReactSelect
                          isClearable
                          isLoading={getMarketsIsLoading}
                          options={markets.map(mapToTCReactSelectOption)}
                          value={getCurrentMarketOption(field.value)}
                          onBlur={field.onBlur}
                          onChange={(option) => {
                            const value = !option ? '' : option.value
                            field.onChange(value)
                            marketIdChangeHandler(value)
                          }}
                          menuPlacement="auto"
                        />
                      </FormFieldContainer>
                    )}
                  />
                </Box>
              </Stack>
            ) : null}
            {!getPartnersIsLoading ? (
              <Stack
                gap={'8px'}
                alignItems={'left'}
                paddingTop={'12px'}
                width={'20%'}
              >
                <Box data-testid="edit_patient_glance_select_contracted_with_btn">
                  <Controller
                    name="contractedWith"
                    control={formMethods.control}
                    render={({ field }) => (
                      <FormFieldContainer label="Contracted With">
                        <TCReactSelect
                          isClearable
                          isLoading={getPartnersIsLoading}
                          options={partners.map(mapToTCReactSelectOption)}
                          value={getCurrentPartnerOption(field.value)}
                          onBlur={field.onBlur}
                          onChange={(option) => {
                            const value = !option ? '' : option.value
                            field.onChange(value)
                            partnerIdChangeHandler(value)
                          }}
                          menuPlacement="auto"
                        />
                      </FormFieldContainer>
                    )}
                  />
                </Box>
              </Stack>
            ) : null}
          </Box>
          <Box
            sx={{
              pt: '24px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              columnGap: '16px',
            }}
          >
            <Button
              variant={'outlined'}
              color={'secondary'}
              onClick={onCancelClick}
            >
              CANCEL
            </Button>
            <Button
              type="submit"
              variant={'contained'}
              color={'secondary'}
              data-testid="edit_patient_glance_save_btn"
            >
              SAVE
            </Button>
          </Box>
        </Box>
      </form>
      <BackdropLoader open={showLoader} />
      <DevTool control={formMethods.control} placement={'bottom-left'} />
    </FormProvider>
  )
}
