import { PatientContact, PatientV2 } from 'src/features/patients/domain'
import { TCReactSelectOption } from 'src/features/shared/presentation'
import { MAIN_LANGUAGES } from 'src/features/shared/constants'
import { mapToTCReactSelectOption } from 'src/features/shared/adapters'
import {
  nameRegexReplace,
  addressRegexReplace,
  cityRegexReplace,
  phoneRegexReplace,
  stateRegexReplace,
  zipRegexReplace,
} from './regex.utils'

export const cleanPhoneFieldValue = (phone: string) => {
  return phone.replace(phoneRegexReplace, '').trim()
}

export const cleanNameFieldValue = (name: string) => {
  return name.replace(nameRegexReplace, '')
}

export const cleanAddressFieldValue = (address: string) => {
  return address.replace(addressRegexReplace, '')
}

export const cleanCityFieldValue = (city: string) => {
  return city.replace(cityRegexReplace, '')
}

export const limitStateFieldValue = (state: string) => {
  return state.substring(0, 2)
}

export const cleanStateFieldValue = (state: string) => {
  return limitStateFieldValue(state.replace(stateRegexReplace, ''))
}

export const cleanZipFieldValue = (zip: string) => {
  return zip.replace(zipRegexReplace, '')
}

export const getMaskedPhone = (phone?: string | null) => {
  if (!phone) return ''
  return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(
    6,
    phone.length
  )}`
}

export const getPatientMaskedPhones = (contacts: PatientV2['contacts']) => {
  if (!contacts) return { phone1: '', phone2: '' }
  const phones = contacts.filter((contact) => contact.phone)
  let phone1 = phones[0]?.phone || ''
  let phone2 = phones[1]?.phone || ''

  if (phone1.length) {
    phone1 = getMaskedPhone(phone1)
  }
  if (phone2.length) {
    phone2 = getMaskedPhone(phone2)
  }
  return { phone1, phone2 }
}

export const getPatientCurrentContacts = (patient: PatientV2) => {
  let phone1: PatientContact | undefined = undefined
  let phone2: PatientContact | undefined = undefined
  let email: PatientContact | undefined = undefined

  if (patient.contacts?.length) {
    const phones = patient.contacts.filter((contact) => contact.phone)
    if (phones.length > 0) {
      phone1 = { ...phones[0], phone: phones[0].phone?.trim() }
    }
    if (phones.length > 1) {
      phone2 = { ...phones[1], phone: phones[1].phone?.trim() }
    }
    const emails = patient.contacts.filter((contact) => contact.email)
    if (emails.length) {
      email = emails[0]
    }
  }

  return {
    phone1,
    phone2,
    email,
  }
}

export const cleanReactQuillText = (text: string) => {
  if (text === '<p><br></p>') return ''
  return text
}

export const getMainLanguageOptionsWithOther = () => {
  const otherOption: TCReactSelectOption = {
    label: 'Other',
    value: 'Other',
  }
  return [...MAIN_LANGUAGES.map(mapToTCReactSelectOption), otherOption]
}

export const getFormLanguage = (language?: string, otherLanguage?: string) => {
  if (language && language !== 'Other') {
    return language
  } else if (language && language === 'Other' && otherLanguage) {
    return otherLanguage
  }
}
