import { useCallback } from 'react'

import {
  SearchPatientsService,
  SearchPatientsServiceResponse,
} from 'src/features/patients/infrastructure'
import { useCompassApiClient } from 'src/features/shared/infrastructure'
import * as settings from 'src/config/settings'

export const useSearchPatientsService = () => {
  const { getCompassApiClient } = useCompassApiClient()

  const SearchPatients: SearchPatientsService = useCallback(
    async (params) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.get<SearchPatientsServiceResponse>(
        settings.COMPASS_API_BASE_URL + '/search/suggest',
        {
          params,
        }
      )
    },
    [getCompassApiClient]
  )

  return {
    SearchPatients,
  }
}
