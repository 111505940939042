import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material'
import { ChangeEvent, FC, useState } from 'react'

import { LoadMSKFormReturns } from 'src/features/msk/domain'
import {
  FallScreenV2WhyNotOptions,
  MSKFormState,
  MSKFormStateAdditionalQuestionsFieldOptions,
  useMSKFormStore,
} from 'src/features/shared/infrastructure'
import {
  FormFieldContainer,
  YesNoRadioGroup,
} from 'src/features/shared/presentation'
import { mskFormLabelSx } from 'src/features/msk/presentation'
import * as settings from 'src/config/settings'

import {
  QUESTIONS_LABELS,
  MSK_FORM_ADDITIONAL_QUESTIONS_FIELD_OPTIONS,
  getSelectDefaultSx,
  getTextFieldDefaultSx,
} from './helpers'

type NymblAlertProps = {
  open: boolean
  onDismiss: () => void
}

export const NymblAlert: FC<NymblAlertProps> = ({ open, onDismiss }) => {
  return (
    <Modal open={open}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          borderRadius: '4px',
          boxShadow: 24,
          display: 'flex',
        }}
      >
        <Alert
          severity={'info'}
          sx={{
            width: '100%',
            display: 'flex',
          }}
        >
          <AlertTitle>Eligible For Nymbl</AlertTitle>
          <Box
            sx={{
              width: '600px',
              display: 'flex',
              flexDirection: 'column',
              rowGap: '32px',
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '16px',
              }}
            >
              <Typography>
                Patient meets the criteria for fall prevention program.
              </Typography>
              {settings.NYMBL_ASSESSMENT_URL ? (
                <Typography>
                  Click here for Nymbl flow:{' '}
                  <a
                    href={settings.NYMBL_ASSESSMENT_URL}
                    target="_blank"
                    style={{ color: '#C40A73' }}
                  >
                    LINK HERE
                  </a>
                </Typography>
              ) : null}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant={'contained'}
                color={'secondary'}
                onClick={onDismiss}
              >
                DISMISS
              </Button>
            </Box>
          </Box>
        </Alert>
      </Box>
    </Modal>
  )
}

type FallScreenV1FormProps = {
  loadedMSKForm: LoadMSKFormReturns
}

const FallScreenV1Form: FC<FallScreenV1FormProps> = ({ loadedMSKForm }) => {
  const { additionalQuestions, setAdditionalQuestions, errors } =
    useMSKFormStore({
      loadedMSKForm,
    })

  const handleFallScreenQ1Change = (event: SelectChangeEvent) => {
    const value = event.target
      .value as MSKFormStateAdditionalQuestionsFieldOptions['v2']
    setAdditionalQuestions({
      fallScreenQ1: value,
    })
  }

  const handleFallScreenQ2Change = (event: SelectChangeEvent) => {
    const value = event.target
      .value as MSKFormStateAdditionalQuestionsFieldOptions['v2']
    setAdditionalQuestions({
      fallScreenQ2: value,
    })
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
        }}
      >
        <FormFieldContainer
          id={'fallScreenQ1'}
          label={QUESTIONS_LABELS.fallScreenQ1}
          error={!!errors.fallScreenQ1}
          helperText={errors.fallScreenQ1 ? errors.fallScreenQ1 : ''}
          formLabelSx={mskFormLabelSx}
        >
          <Select
            id={'fallScreenQ1'}
            size="small"
            value={additionalQuestions.fallScreenQ1}
            onChange={handleFallScreenQ1Change}
            error={!!errors.fallScreenQ1}
            sx={{
              ...getSelectDefaultSx(),
            }}
          >
            {MSK_FORM_ADDITIONAL_QUESTIONS_FIELD_OPTIONS.v2.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.display}
              </MenuItem>
            ))}
          </Select>
        </FormFieldContainer>

        <FormFieldContainer
          id={'fallScreenQ2'}
          label={QUESTIONS_LABELS.fallScreenQ2}
          error={!!errors.fallScreenQ2}
          helperText={errors.fallScreenQ2 ? errors.fallScreenQ2 : ''}
          formLabelSx={mskFormLabelSx}
        >
          <Select
            id={'fallScreenQ2'}
            size="small"
            value={additionalQuestions.fallScreenQ2}
            onChange={handleFallScreenQ2Change}
            error={!!errors.fallScreenQ2}
            sx={{
              ...getSelectDefaultSx(),
            }}
          >
            {MSK_FORM_ADDITIONAL_QUESTIONS_FIELD_OPTIONS.v2.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.display}
              </MenuItem>
            ))}
          </Select>
        </FormFieldContainer>
      </Box>

      <Divider />

      <FormFieldContainer
        label={'Does the patient screen positive as a fall risk?'}
        formLabelSx={mskFormLabelSx}
      >
        <TextField
          value={additionalQuestions.fallScreenResult}
          size={'small'}
          disabled={true}
          sx={{
            ...getTextFieldDefaultSx(),
          }}
        />
      </FormFieldContainer>
    </>
  )
}

type FallScreenV2FormProps = {
  loadedMSKForm: LoadMSKFormReturns
}

const FallScreenV2Form: FC<FallScreenV2FormProps> = ({ loadedMSKForm }) => {
  const [showNymblAlert, setShowNymblAlert] = useState(false)
  const [showNymblExtraQs, setShowNymblExtraQs] = useState(false)
  const { additionalQuestions, setAdditionalQuestions, errors } =
    useMSKFormStore({
      loadedMSKForm,
    })

  const mapToRadioYesNoValue = (
    state: MSKFormStateAdditionalQuestionsFieldOptions['v2']
  ) => {
    if (state === 'Y') {
      return 'yes'
    }
    if (state === 'N') {
      return 'no'
    }
    if (state === 'Select an option') {
      return ''
    }
    return ''
  }

  const mapToFormStateValue = (
    value: string
  ): MSKFormStateAdditionalQuestionsFieldOptions['v2'] => {
    if (value === 'yes') {
      return 'Y'
    }
    if (value === 'no') {
      return 'N'
    }
    return 'Select an option'
  }

  const handleNymblAlertDismiss = () => {
    setShowNymblAlert(false)
    setShowNymblExtraQs(true)
  }

  const onFallScreenMainQsChange = (
    value: MSKFormStateAdditionalQuestionsFieldOptions['v2'],
    otherValues: MSKFormStateAdditionalQuestionsFieldOptions['v2'][]
  ) => {
    if (value === 'Y' && !otherValues.includes('Y')) {
      setShowNymblAlert(true)
    }
  }

  const handleFallScreenV2Q1Change = (event: ChangeEvent<HTMLInputElement>) => {
    const value = mapToFormStateValue(event.target.value)

    setAdditionalQuestions({
      fallScreenV2Q1: value,
    })

    onFallScreenMainQsChange(value, [
      additionalQuestions.fallScreenV2Q2,
      additionalQuestions.fallScreenV2Q3,
    ])
  }

  const handleFallScreenV2Q2Change = (event: ChangeEvent<HTMLInputElement>) => {
    const value = mapToFormStateValue(event.target.value)

    setAdditionalQuestions({
      fallScreenV2Q2: value,
    })

    onFallScreenMainQsChange(value, [
      additionalQuestions.fallScreenV2Q1,
      additionalQuestions.fallScreenV2Q3,
    ])
  }

  const handleFallScreenV2Q3Change = (event: ChangeEvent<HTMLInputElement>) => {
    const value = mapToFormStateValue(event.target.value)

    setAdditionalQuestions({
      fallScreenV2Q3: value,
    })

    onFallScreenMainQsChange(value, [
      additionalQuestions.fallScreenV2Q1,
      additionalQuestions.fallScreenV2Q2,
    ])
  }

  const handleFallScreenV2Q4Change = (event: ChangeEvent<HTMLInputElement>) => {
    const value: MSKFormState['additionalQuestions']['fallScreenV2Q4'] =
      event.target.value === 'yes' ? 'Y' : 'N'

    setAdditionalQuestions({
      fallScreenV2Q4: value,
    })
  }

  const handleFallScreenV2WhyNotChange = (event: SelectChangeEvent) => {
    const value = event.target.value as FallScreenV2WhyNotOptions
    setAdditionalQuestions({
      fallScreenV2WhyNot: value,
    })
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
        }}
      >
        <YesNoRadioGroup
          dataTestIdPreffix={'step3_question_fall_screen_v2_q1'}
          label={QUESTIONS_LABELS.fallScreenV2Q1}
          id={'step3_question_fall_screen_v2_q1'}
          onChange={handleFallScreenV2Q1Change}
          error={!!errors.fallScreenV2Q1}
          helperText={errors.fallScreenV2Q1 ? errors.fallScreenV2Q1 : undefined}
          value={mapToRadioYesNoValue(additionalQuestions.fallScreenV2Q1)}
          formFieldContainerProps={{
            formLabelSx: mskFormLabelSx,
          }}
        />
        <YesNoRadioGroup
          dataTestIdPreffix={'step3_question_fall_screen_v2_q2'}
          label={QUESTIONS_LABELS.fallScreenV2Q2}
          id={'step3_question_fall_screen_v2_q2'}
          onChange={handleFallScreenV2Q2Change}
          error={!!errors.fallScreenV2Q2}
          helperText={errors.fallScreenV2Q2 ? errors.fallScreenV2Q2 : undefined}
          value={mapToRadioYesNoValue(additionalQuestions.fallScreenV2Q2)}
          formFieldContainerProps={{
            formLabelSx: mskFormLabelSx,
          }}
        />
        <YesNoRadioGroup
          dataTestIdPreffix={'step3_question_fall_screen_v2_q3'}
          label={QUESTIONS_LABELS.fallScreenV2Q3}
          id={'step3_question_fall_screen_v2_q3'}
          onChange={handleFallScreenV2Q3Change}
          error={!!errors.fallScreenV2Q3}
          helperText={errors.fallScreenV2Q3 ? errors.fallScreenV2Q3 : undefined}
          value={mapToRadioYesNoValue(additionalQuestions.fallScreenV2Q3)}
          formFieldContainerProps={{
            formLabelSx: mskFormLabelSx,
          }}
        />
      </Box>
      <Divider />

      <FormFieldContainer
        label={'Does the patient screen positive as a fall risk?'}
        formLabelSx={mskFormLabelSx}
      >
        <TextField
          value={additionalQuestions.fallScreenV2Result}
          size={'small'}
          disabled={true}
          sx={{
            ...getTextFieldDefaultSx(),
          }}
        />
      </FormFieldContainer>
      {additionalQuestions.fallScreenV2Result === 'Positive' &&
      showNymblExtraQs ? (
        <>
          <Divider />

          <YesNoRadioGroup
            dataTestIdPreffix={'step3_question_fall_screen_v2_q4'}
            label={QUESTIONS_LABELS.fallScreenV2Q4}
            id={'step3_question_fall_screen_v2_q3'}
            onChange={handleFallScreenV2Q4Change}
            error={!!errors.fallScreenV2Q4}
            helperText={
              errors.fallScreenV2Q4 ? errors.fallScreenV2Q4 : undefined
            }
            value={mapToRadioYesNoValue(additionalQuestions.fallScreenV2Q4)}
            formFieldContainerProps={{
              formLabelSx: mskFormLabelSx,
            }}
          />

          {additionalQuestions.fallScreenV2Q4 === 'N' ? (
            <FormFieldContainer
              id={'fallScreenV2WhyNot'}
              label={QUESTIONS_LABELS.fallScreenV2WhyNot}
              error={!!errors.fallScreenV2WhyNot}
              helperText={
                errors.fallScreenV2WhyNot ? errors.fallScreenV2WhyNot : ''
              }
              formLabelSx={mskFormLabelSx}
            >
              <Select
                id={'fallScreenV2WhyNot'}
                size="small"
                value={additionalQuestions.fallScreenV2WhyNot}
                onChange={handleFallScreenV2WhyNotChange}
                error={!!errors.fallScreenV2WhyNot}
                sx={{
                  width: '350px',
                }}
              >
                {MSK_FORM_ADDITIONAL_QUESTIONS_FIELD_OPTIONS.fallScreenV2WhyNot.map(
                  (option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.display}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormFieldContainer>
          ) : null}
        </>
      ) : null}

      <NymblAlert open={showNymblAlert} onDismiss={handleNymblAlertDismiss} />
    </>
  )
}

type MSKFormStepThreePage2Props = {
  loadedMSKForm: LoadMSKFormReturns
}

export const MSKFormStepThreePage2: FC<MSKFormStepThreePage2Props> = ({
  loadedMSKForm,
}) => {
  const { additionalQuestions } = useMSKFormStore({
    loadedMSKForm,
  })

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '32px',
      }}
    >
      {additionalQuestions.fallScreenVersion == 'v2' ? (
        <FallScreenV2Form loadedMSKForm={loadedMSKForm} />
      ) : (
        <FallScreenV1Form loadedMSKForm={loadedMSKForm} />
      )}
    </Box>
  )
}
