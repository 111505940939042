import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { Queue } from 'src/features/queues/domain'
import { useQueuePatientsStore } from 'src/features/shared/infrastructure'
import { ReferralSource, Stage } from 'src/features/patients/domain'

const getQueueCountColor = (queueName: string) => {
  switch (queueName.toLowerCase()) {
    case 'pcp referrals':
    case 'ortho referrals':
    case 'um/inbound inquiries':
    case 'referral requests':
      return 'teal.300'
    default:
      return '#8D929F'
  }
}

const getQueueDataTestId = (queueName: string) => {
  switch (queueName.toLowerCase()) {
    case 'pcp referrals':
      return 'launchpad_item_pcp_referrals'
    case 'ortho referrals':
      return 'launchpad_item_ortho_referrals'
    default:
      return `launchpad_item_${queueName.toLowerCase().replaceAll(' ', '_')}`
  }
}

type QueueCountProps = {
  queue: Queue
}

export const QueueCount: FC<QueueCountProps> = ({ queue }) => {
  return (
    <Typography
      sx={{
        fontSize: '96px',
        fontWeight: '600',
        lineHeight: '116px',
        color: getQueueCountColor(queue.name),
      }}
    >
      {queue.count}
    </Typography>
  )
}

type LaunchpadItemProps = {
  queue: Queue
  'data-testid'?: string
}

export const LaunchpadItem: FC<LaunchpadItemProps> = ({ queue }) => {
  const navigate = useNavigate()
  const {
    setQueuePatientsSearchInputValue,
    setQueuePatientsSelectedReferralSourceFilter,
    setQueuePatientsSelectedStageFilter,
    setQueuePatientsSelectedStatusFilter,
    setReferralCoordinationFlagFilter,
  } = useQueuePatientsStore()

  const pcpReferralsItemClickHandler = () => {
    setQueuePatientsSearchInputValue('')

    const referralSourceFilters: ReferralSource[] = ['PCP']

    const stageFilters: Stage[] = ['Targeted']
    setQueuePatientsSelectedReferralSourceFilter(
      referralSourceFilters.join(',')
    )
    setQueuePatientsSelectedStageFilter(stageFilters.join(','))

    const statusFilters: string[] = ['Not Attempted', 'Awaiting Intake']
    setQueuePatientsSelectedStatusFilter(statusFilters.join(','))

    const searchParamsFilters = new URLSearchParams({
      referralSource: referralSourceFilters.join(','),
      stage: stageFilters.join(','),
      status: statusFilters.join(','),
    })
    navigate(`/queues/patients?${searchParamsFilters}`)
  }

  const pcpReferralFollowUpsItemClickHandler = () => {
    setQueuePatientsSearchInputValue('')

    const referralSourceFilters: ReferralSource[] = ['PCP']

    const stageFilters: Stage[] = ['Targeted']
    setQueuePatientsSelectedReferralSourceFilter(
      referralSourceFilters.join(',')
    )
    setQueuePatientsSelectedStageFilter(stageFilters.join(','))

    const statusFilters: string[] = [
      'Call Back',
      'Invalid Number',
      'No Answer',
      'Left Voicemail',
    ]
    setQueuePatientsSelectedStatusFilter(statusFilters.join(','))

    const searchParamsFilters = new URLSearchParams({
      referralSource: referralSourceFilters.join(','),
      stage: stageFilters.join(','),
      status: statusFilters.join(','),
    })
    navigate(`/queues/patients?${searchParamsFilters}`)
  }

  const orthoReferralsItemClickHandler = () => {
    setQueuePatientsSearchInputValue('')
    const referralSourceFilters: ReferralSource[] = ['Orthopedic Surgeon']

    const stageFilters: Stage[] = ['Targeted']
    setQueuePatientsSelectedReferralSourceFilter(
      referralSourceFilters.join(',')
    )
    setQueuePatientsSelectedStageFilter(stageFilters.join(','))

    const statusFilters: string[] = ['Not Attempted', 'Awaiting Intake']
    setQueuePatientsSelectedStatusFilter(statusFilters.join(','))

    const searchParamsFilters = new URLSearchParams({
      referralSource: referralSourceFilters.join(','),
      stage: stageFilters.join(','),
      status: statusFilters.join(','),
    })
    navigate(`/queues/patients?${searchParamsFilters}`)
  }

  const orthoReferralFollowUpsItemClickHandler = () => {
    setQueuePatientsSearchInputValue('')
    const referralSourceFilters: ReferralSource[] = ['Orthopedic Surgeon']

    const stageFilters: Stage[] = ['Targeted']
    setQueuePatientsSelectedReferralSourceFilter(
      referralSourceFilters.join(',')
    )
    setQueuePatientsSelectedStageFilter(stageFilters.join(','))

    const statusFilters: string[] = [
      'Call Back',
      'Invalid Number',
      'No Answer',
      'Left Voicemail',
    ]
    setQueuePatientsSelectedStatusFilter(statusFilters.join(','))

    const searchParamsFilters = new URLSearchParams({
      referralSource: referralSourceFilters.join(','),
      stage: stageFilters.join(','),
      status: statusFilters.join(','),
    })
    navigate(`/queues/patients?${searchParamsFilters}`)
  }

  const umInboundInquiriesItemClickHandler = () => {
    setQueuePatientsSearchInputValue('')
    const referralSourceFilters: ReferralSource[] = [
      'Inbound - Phone',
      'Inbound - Web',
      'Outbound Outreach - UM',
      'Outbound Outreach - Predictive Model',
    ]

    const stageFilters: Stage[] = ['Targeted']
    setQueuePatientsSelectedReferralSourceFilter(
      referralSourceFilters.join(',')
    )
    setQueuePatientsSelectedStageFilter(stageFilters.join(','))

    const statusFilters: string[] = ['Not Attempted', 'Awaiting Intake']
    setQueuePatientsSelectedStatusFilter(statusFilters.join(','))

    const searchParamsFilters = new URLSearchParams({
      referralSource: referralSourceFilters.join(','),
      stage: stageFilters.join(','),
      status: statusFilters.join(','),
    })
    navigate(`/queues/patients?${searchParamsFilters}`)
  }

  const umInboundInquiryFollowUpsItemClickHandler = () => {
    setQueuePatientsSearchInputValue('')
    const referralSourceFilters: ReferralSource[] = [
      'Inbound - Phone',
      'Inbound - Web',
      'Outbound Outreach - UM',
      'Outbound Outreach - Predictive Model',
    ]

    const stageFilters: Stage[] = ['Targeted']
    setQueuePatientsSelectedReferralSourceFilter(
      referralSourceFilters.join(',')
    )
    setQueuePatientsSelectedStageFilter(stageFilters.join(','))

    const statusFilters: string[] = [
      'Call Back',
      'Invalid Number',
      'No Answer',
      'Left Voicemail',
    ]
    setQueuePatientsSelectedStatusFilter(statusFilters.join(','))

    const searchParamsFilters = new URLSearchParams({
      referralSource: referralSourceFilters.join(','),
      stage: stageFilters.join(','),
      status: statusFilters.join(','),
    })
    navigate(`/queues/patients?${searchParamsFilters}`)
  }

  const referRequiredItemClickHandler = () => {
    setQueuePatientsSearchInputValue('')
    setReferralCoordinationFlagFilter('true')

    const searchParamsFilters = new URLSearchParams({
      referralCoordinationFlag: 'true',
    })
    navigate(`/queues/patients?${searchParamsFilters}`)
  }

  const getLaunchpadItemClickHandler = (queueName: string) => () => {
    if (queueName.toLocaleLowerCase() === 'pcp referrals') {
      return pcpReferralsItemClickHandler()
    }
    if (queueName.toLocaleLowerCase() === 'ortho referrals') {
      return orthoReferralsItemClickHandler()
    }
    if (queueName.toLocaleLowerCase() === 'um/inbound inquiries') {
      return umInboundInquiriesItemClickHandler()
    }
    if (queueName.toLocaleLowerCase() === 'referral requests') {
      return referRequiredItemClickHandler()
    }
  }

  const getFollowUpItemClickHandler = (queueName: string) => () => {
    if (queueName.toLocaleLowerCase() === 'pcp referrals') {
      return pcpReferralFollowUpsItemClickHandler()
    }
    if (queueName.toLocaleLowerCase() === 'ortho referrals') {
      return orthoReferralFollowUpsItemClickHandler()
    }
    if (queueName.toLocaleLowerCase() === 'um/inbound inquiries') {
      return umInboundInquiryFollowUpsItemClickHandler()
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '5px',
        width: '15%',
      }}
    >
      <Box
        key={queue.position}
        sx={{
          p: '16px 16px 40px 16px',
          display: 'flex',
          flex: '0 1 258px',
          flexDirection: 'column',
          rowGap: '24px',
          alignItems: 'center',
          cursor: 'pointer',
          border: '1px solid rgba(0, 0, 0, 0.23)',
          backgroundColor: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#F5F7FA',
          },
        }}
        onClick={getLaunchpadItemClickHandler(queue.name)}
        data-testid={getQueueDataTestId(queue.name)}
      >
        <Typography
          sx={{
            fontFamily: 'Inter',
            fontSize: '20px',
            fontWeight: '600',
            letterSpacing: '1.5px',
            color: '#6A7682',
            textAlign: 'center',
          }}
        >
          {queue.name.toUpperCase()}
        </Typography>
        <QueueCount queue={queue} />
      </Box>
      {queue.followUpCount ? (
        <Box
          sx={{
            height: '15px',
            p: '16px 16px 16px 16px',
            display: 'flex',
            justifyContent: 'space-between',
            flex: '0 1 60px',
            alignItems: 'center',
            cursor: 'pointer',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            backgroundColor: '#FFFFFF',
            '&:hover': {
              backgroundColor: '#F5F7FA',
            },
          }}
          onClick={getFollowUpItemClickHandler(queue.name)}
        >
          <Box>
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: '600',
                letterSpacing: '1.5px',
                color: '#6A7682',
              }}
            >
              {'FOLLOW UP'}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontSize: '24px',
                fontWeight: '600',
                letterSpacing: '1.5px',
                color: '#00454A',
              }}
            >
              {queue.followUpCount}
            </Typography>
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}
