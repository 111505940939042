import {
  LocalizationProvider,
  DatePicker,
  DatePickerProps,
} from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { parseISO } from 'date-fns'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

type TCDatePickerProps = DatePickerProps<Date>

export const TCDatePicker: FC<TCDatePickerProps> = (props) => {
  const { slotProps, ...restProps } = props
  const { textField: textFieldSlotProps } = slotProps || {}
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        views={['year', 'month', 'day']}
        format={'MM/dd/yyyy'}
        slotProps={{
          textField: {
            size: 'small',
            ...textFieldSlotProps,
          },
        }}
        {...restProps}
      />
    </LocalizationProvider>
  )
}

type ControlledDatePickerProps = {
  name: string
  datePickerProps?: TCDatePickerProps
  onChange?: (value?: Date) => void
}

export const ControlledDatePicker: FC<ControlledDatePickerProps> = ({
  name,
  datePickerProps,
  onChange,
}) => {
  const formMethods = useFormContext()
  const { slotProps, ...restDatePickerProps } = datePickerProps || {}
  const { textField: textFieldSlotProps } = slotProps || {}
  return (
    <Controller
      name={name}
      control={formMethods.control}
      render={({ field, fieldState }) => {
        const getValue = () => {
          if (field.value) {
            if (typeof field.value === 'string') {
              return parseISO(field.value)
            }
            return field.value
          }
          return null
        }
        const handleChange = (value: Date | null) => {
          field.onChange(value)
          if (onChange) {
            // null doesn't play nice with upstream handlers, coalesce to undefined
            onChange(value ?? undefined)
          }
        }
        return (
          <TCDatePicker
            value={getValue()}
            onChange={handleChange}
            slotProps={{
              textField: {
                onBlur: field.onBlur,
                error: !!fieldState.error,
                helperText: fieldState.error ? fieldState.error.message : '',
                ...textFieldSlotProps,
              },
            }}
            {...restDatePickerProps}
          />
        )
      }}
    />
  )
}
