import { UseNotesUseCases } from 'src/features/notes/domain'
import { useNotesServiceAdapter } from 'src/features/notes/adapters'

type MakeUseNotesUseCases = () => UseNotesUseCases

const makeUseNotesUseCases: MakeUseNotesUseCases = () => () => {
  const notesServiceAdapter = useNotesServiceAdapter()

  return {
    createPatientCustomNote: notesServiceAdapter.createPatientCustomNote,
    createPatientIntakeNote: notesServiceAdapter.createPatientIntakeNote,
    createPatientOrthoChartCheckNote:
      notesServiceAdapter.createPatientOrthoChartCheckNote,
    createPatientOrthoTouchpointNote:
      notesServiceAdapter.createPatientOrthoTouchpointNote,
    createPatientOutreachNote: notesServiceAdapter.createPatientOutreachNote,
    createPatientSelfCareTouchpointNote:
      notesServiceAdapter.createPatientSelfCareTouchpointNote,
    createPatientReferralUploadNote:
      notesServiceAdapter.createPatientReferralUploadNote,
    createPatientPTTouchpointNote:
      notesServiceAdapter.createPatientPTTouchpointNote,
    createPatientPostOpTouchpointNote:
      notesServiceAdapter.createPatientPostOpTouchpointNote,
    createPatientPreOpTouchpointNote:
      notesServiceAdapter.createPatientPreOpTouchpointNote,
    getPatientNotes: notesServiceAdapter.getPatientNotes,
    getDraftNote: notesServiceAdapter.getDraftNote,
    createDraftNote: notesServiceAdapter.createDraftNote,
    deleteDraftNote: notesServiceAdapter.deleteDraftNote,
    updateDraftNote: notesServiceAdapter.updateDraftNote,
  }
}

export const useNotesUseCases: UseNotesUseCases = makeUseNotesUseCases()
