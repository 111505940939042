import { Image } from '@aws-amplify/ui-react'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import tcLogo from 'src/assets/images/tc-horizontal-logo.svg'

export const LogoHeader = () => {
  const navigate = useNavigate()

  const handleLogoClick = () => {
    navigate('/')
  }

  return (
    <Box
      sx={{
        cursor: 'pointer',
      }}
    >
      <Image
        alt="TailorCare logo"
        src={tcLogo}
        width={280}
        onClick={handleLogoClick}
      />
    </Box>
  )
}
