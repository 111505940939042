import {
  UseMutateAsyncFunction,
  UseMutateFunction,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import {
  UpdateDraftNoteReturns,
  UpdateDraftNoteArgs,
  UpdateDraftNoteError,
} from 'src/features/notes/domain'
import { useNotesUseCases } from 'src/features/notes/notes.service-locator'
import { GET_PATIENT_DRAFT_NOTE } from './use-get-draft-note'

type UseUpdateDraftNoteProps = Omit<
  UseMutationOptions<
    UpdateDraftNoteReturns,
    UpdateDraftNoteError,
    UpdateDraftNoteArgs,
    unknown
  >,
  'mutationFn'
>

type UseUpdateDraftNote = (props?: UseUpdateDraftNoteProps) => {
  updateDraftNote: UseMutateFunction<
    UpdateDraftNoteReturns,
    UpdateDraftNoteError,
    UpdateDraftNoteArgs,
    unknown
  >
  updateDraftNoteAsync: UseMutateAsyncFunction<
    UpdateDraftNoteReturns,
    UpdateDraftNoteError,
    UpdateDraftNoteArgs,
    unknown
  >
  updateDraftNoteIsLoading: boolean
  updateDraftNoteIsError: boolean
  updateDraftNoteStatus: string
}

export const useUpdateDraftNote: UseUpdateDraftNote = (props = {}) => {
  const notesUseCases = useNotesUseCases()
  const queryClient = useQueryClient()

  const { onSuccess, onError } = props

  const { mutate, mutateAsync, isLoading, isError, status } = useMutation<
    UpdateDraftNoteReturns,
    UpdateDraftNoteError,
    UpdateDraftNoteArgs,
    unknown
  >({
    mutationFn: (args: UpdateDraftNoteArgs) => {
      return notesUseCases.updateDraftNote(args)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [GET_PATIENT_DRAFT_NOTE, variables.payload.patientId],
      })
      onSuccess?.(data, variables, context)
    },
    onError: (error, variables, context) => {
      onError?.(error, variables, context)
    },
  })
  return {
    updateDraftNote: mutate,
    updateDraftNoteAsync: mutateAsync,
    updateDraftNoteIsLoading: isLoading,
    updateDraftNoteIsError: isError,
    updateDraftNoteStatus: status,
  }
}
