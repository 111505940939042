import {
  UseMutateAsyncFunction,
  UseMutateFunction,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import {
  UpdatePatientStatusArgs,
  UpdatePatientStatusError,
  UpdatePatientStatusReturns,
} from 'src/features/patients/domain'
import { usePatientsUseCases } from 'src/features/patients/patients.service-locator'
import { GET_PATIENT_QUERY_KEY_V2 } from 'src/features/patients/presentation'

type useUpdatePatientStatusProps = Omit<
  UseMutationOptions<
    UpdatePatientStatusReturns,
    UpdatePatientStatusError,
    UpdatePatientStatusArgs,
    unknown
  >,
  'mutationFn'
>

type UseUpdatePatientStatus = (props?: useUpdatePatientStatusProps) => {
  updatePatientStatus: UseMutateFunction<
    UpdatePatientStatusReturns,
    UpdatePatientStatusError,
    UpdatePatientStatusArgs,
    unknown
  >
  updatePatientStatusAsync: UseMutateAsyncFunction<
    UpdatePatientStatusReturns,
    UpdatePatientStatusError,
    UpdatePatientStatusArgs,
    unknown
  >
  updatePatientStatusIsLoading: boolean
  updatePatientStatusStatus: string
}

export const useUpdatePatientStatus: UseUpdatePatientStatus = (props = {}) => {
  const queryClient = useQueryClient()
  const patientsUseCases = usePatientsUseCases()

  const { onSuccess } = props

  const { mutate, mutateAsync, isLoading, status } = useMutation<
    UpdatePatientStatusReturns,
    UpdatePatientStatusError,
    UpdatePatientStatusArgs,
    unknown
  >({
    mutationFn: (args: UpdatePatientStatusArgs) => {
      return patientsUseCases.updatePatientStatus(args)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [GET_PATIENT_QUERY_KEY_V2, variables.patientId],
      })
      onSuccess?.(data, variables, context)
    },
  })
  return {
    updatePatientStatus: mutate,
    updatePatientStatusAsync: mutateAsync,
    updatePatientStatusIsLoading: isLoading,
    updatePatientStatusStatus: status,
  }
}
