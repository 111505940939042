import { useQuery } from '@tanstack/react-query'

import { useQueuesUseCases } from 'src/features/queues/queues.service-locator'
import { GetQueuesError, GetQueuesReturns } from 'src/features/queues/domain'

export const GET_QUEUES_QUERY_KEY = 'getQueues'

export const useGetQueuesQuery = () => {
  const queuesUseCases = useQueuesUseCases()

  const { data, isLoading, isError, refetch, isRefetching } = useQuery<
    GetQueuesReturns,
    GetQueuesError
  >([GET_QUEUES_QUERY_KEY], () => {
    return queuesUseCases.getQueues()
  })

  return {
    queues: data,
    getQueuesIsLoading: isLoading,
    getQueuesIsError: isError,
    getQueuesIsRefetching: isRefetching,
    refetchQueues: refetch,
  }
}
