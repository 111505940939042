import { Pathway } from 'src/features/pathways/domain'
import { PatientV2 } from 'src/features/patients/domain'

export const getPatientHasStage = (patient: PatientV2) =>
  patient.stageAndStatus && patient.stageAndStatus.stage ? true : false

export const isPatientV2Engaged = (
  patient: PatientV2,
  pathway?: 'self-care' | 'pt' | 'ortho'
) => {
  if (!patient.stageAndStatus?.stage) {
    return false
  }
  if (pathway) {
    const patientPathways = patient.pathways.map((pathway) =>
      pathway.pathway.toLowerCase()
    )
    return (
      patient.stageAndStatus?.stage.toLowerCase() == 'engaged' &&
      patientPathways.includes(pathway)
    )
  }
  return patient.stageAndStatus?.stage.toLowerCase() == 'engaged'
}

export const isPathwayDateFieldRequired = (track: Pathway['code']) => {
  return [
    'OR05P',
    'OR04P',
    'OR10P',
    'OR08P',
    'OR09P',
    'OR11P',
    'PT01P',
    'PT02P',
  ].includes(track)
}
