export const phoneRegex = /^[1-9]{1}[0-9]{9}$|^[1-9]{1}[0-9]{10}$/

export const phoneRegexReplace = /[^0-9]/g

export const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

export const nameRegex = /^[a-zA-Z0-9,.\- ']*$/

export const nameRegexReplace = /[^a-zA-Z0-9,.\- ']/g

export const addressRegex = /^[a-zA-Z0-9\s,'#-.]*$/

export const addressRegexReplace = /[^a-zA-Z0-9\s,''#-.]/g

export const cityRegex = /^[a-zA-Z ',-]*$/

export const cityRegexReplace = /[^a-zA-Z ',-]/g

export const stateRegex = /^[a-zA-Z]*$/

export const stateRegexReplace = /[^a-zA-Z]/g

export const zipRegex = /\d{5}(?:[-\s]\d{4})?/

export const zipRegexReplace = /[^0-9-]/

export const npiRegex = /\d{10}/

export const emptyStringRegex = /^$/
