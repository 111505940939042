import { useLocation } from 'react-router-dom'

export const useCurrentPage = () => {
  const location = useLocation()
  const getCurrentPage = () => {
    const { pathname } = location
    const currentPage = pathname.replace(/\/$/, '')
    return currentPage
  }

  return { getCurrentPage }
}
