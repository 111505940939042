import { Box, Checkbox, Grid, IconButton, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { ChangeEvent, FC, MouseEventHandler, useState } from 'react'
import { toast } from 'react-toastify'

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'

import {
  PatientTasksCalendarModal,
  useGetDoneTasksByPatientIdQuery,
  useGetTodayTasksByPatientIdQuery,
  useGetUpcomingTasksByPatientIdQuery,
  useUpdateTask,
} from 'src/features/tasks/presentation'
import {
  usePatientPageStore,
  useTasksStore,
} from 'src/features/shared/infrastructure'
import { useGetPatientIntakeFlowsQuery } from 'src/features/msk/presentation'
import { Intake, IntakeAssociatedPathway } from 'src/features/msk/domain'
import { ClickableText, RefreshButton } from 'src/features/shared/presentation'
import { useRefreshPatientTasks } from 'src/features/tasks/presentation'
import {
  groupTasksByPathway,
  mapToPatientTouchpointsPathwayInfo,
} from 'src/features/tasks/adapters'
import { Task } from 'src/features/tasks/domain'

const TaskCheckbox = styled(Checkbox)(() => ({
  padding: '0px',
}))

type TaskComponentProps = {
  task: Task
  bodyPart: string
}

const PatientTaskContent: FC<TaskComponentProps> = ({ task, bodyPart }) => {
  const { updateTask } = useUpdateTask()
  const [completedIsChecked, setCompletedIsChecked] = useState(true)
  const [pendingIsChecked, setPendingIsChecked] = useState(false)
  const { setSelectedTask, setEditTaskFormModalOpen } = useTasksStore()

  const handleCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    updateTask(
      { taskId: task.id, payload: { status: checked ? 'complete' : 'new' } },
      {
        onSuccess: () => {
          if (checked) {
            toast.success(`Task ${task.title} marked as done!`)
          } else {
            toast.success(`Task ${task.title} marked as pending!`)
          }
        },
        onError: () => {
          if (checked) {
            toast.error(`Error marking task ${task.title} as done!`)
            setPendingIsChecked(false)
          } else {
            toast.error(`Error marking task ${task.title} as pending!`)
            setCompletedIsChecked(true)
          }
        },
      }
    )
  }

  const handleCheckboxClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation()
    if (task.status === 'complete') {
      setCompletedIsChecked(!completedIsChecked)
    } else {
      setPendingIsChecked(!pendingIsChecked)
    }
  }

  const handleEditTaskClick: MouseEventHandler<HTMLButtonElement> = () => {
    if (task.status !== 'complete') {
      setCompletedIsChecked(!completedIsChecked)
      setSelectedTask(task)
      setEditTaskFormModalOpen(true)
    }
  }

  return (
    <>
      <TaskCheckbox
        checked={
          task.status === 'complete' ? completedIsChecked : pendingIsChecked
        }
        onChange={handleCheckboxChange}
        onClick={handleCheckboxClick}
      />
      <Typography>
        {task.status === 'complete' ? task.completedAt : task.dueDate}
      </Typography>
      {task.status === 'complete' ? (
        <Typography
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
            fontSize: '12px',
          }}
        >
          {bodyPart}
        </Typography>
      ) : (
        <Typography
          onClick={handleEditTaskClick}
          sx={{
            padding: '0px',
            width: '100%',
            whiteSpace: 'nowrap',
            fontSize: '12px',
            cursor: 'pointer',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textDecoration: 'underline',
            fontWeight: 500,
            color: 'secondary.main',
          }}
        >
          {bodyPart}
        </Typography>
      )}
    </>
  )
}

export type PatientTouchpointsPathwayInfo = Record<
  IntakeAssociatedPathway['id'],
  Intake['botheredBodyPart']
>

export const PatientTouchpoints: FC = () => {
  const { setOpenTasksCalendarModal } = usePatientPageStore()
  const { intakes } = useGetPatientIntakeFlowsQuery()
  const { patientDoneTasks } = useGetDoneTasksByPatientIdQuery()
  const { patientUpcomingTasks } = useGetUpcomingTasksByPatientIdQuery()
  const { patientTodayTasks } = useGetTodayTasksByPatientIdQuery()
  const { refreshPatientTasks, isRefreshingPatientTasks } =
    useRefreshPatientTasks()
  const { setAddTaskFormModalOpen } = useTasksStore()

  const patientTouchpointsPathwayInfo =
    mapToPatientTouchpointsPathwayInfo(intakes)

  const intakesPathwaysIds = Object.keys(patientTouchpointsPathwayInfo)

  const patientDoneTasksGroupedByPathway = groupTasksByPathway(
    patientDoneTasks,
    intakesPathwaysIds
  )

  const patientTodayAndUpcomingTasksGroupedByPathway = groupTasksByPathway(
    [...patientTodayTasks, ...patientUpcomingTasks],
    intakesPathwaysIds
  )

  const handleCalendarButtonClick = () => {
    setOpenTasksCalendarModal(true)
  }

  const handleRefreshTasksClick = () => {
    refreshPatientTasks()
  }

  const handleAddTaskClick = () => {
    setAddTaskFormModalOpen(true)
  }

  return (
    <Box
      sx={{
        p: '24px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '16px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            columnGap: '16px',
            alignItems: 'center',
          }}
        >
          <Typography
            fontWeight={500}
            fontSize={20}
            color={'rgba(0, 0, 0, 0.87)'}
          >
            Touchpoints
          </Typography>

          <IconButton onClick={handleCalendarButtonClick}>
            <CalendarMonthIcon />
          </IconButton>

          <RefreshButton
            onClick={handleRefreshTasksClick}
            isRefreshing={isRefreshingPatientTasks}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            columnGap: '16px',
            alignItems: 'center',
          }}
        >
          <ClickableText onClick={handleAddTaskClick}>Add Task</ClickableText>
        </Box>
      </Box>
      <Grid container>
        <Grid
          item
          sm={3}
          display={'flex'}
          flexDirection={'column'}
          rowGap={'16px'}
        >
          <Typography fontSize={'14px'} fontWeight={500}>
            Done
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '8px',
              color: 'rgba(0, 0, 0, 0.38)',
            }}
          >
            {Object.keys(patientDoneTasksGroupedByPathway).length ? (
              intakesPathwaysIds.map((pathwayId) => {
                return patientDoneTasksGroupedByPathway[pathwayId] ? (
                  <Box
                    key={pathwayId}
                    sx={{
                      display: 'flex',
                      columnGap: '8px',
                      alignItems: 'center',
                    }}
                  >
                    <PatientTaskContent
                      task={patientDoneTasksGroupedByPathway[pathwayId][0]}
                      bodyPart={patientTouchpointsPathwayInfo[pathwayId]}
                    />
                  </Box>
                ) : (
                  <Box key={pathwayId}>
                    ------------------------------------
                  </Box>
                )
              })
            ) : (
              <Typography>No done tasks</Typography>
            )}
          </Box>
        </Grid>
        <Grid
          item
          sm={9}
          display={'flex'}
          flexDirection={'column'}
          rowGap={'16px'}
        >
          <Typography fontSize={'14px'} fontWeight={500}>
            Upcoming & Today
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '8px',
            }}
          >
            {Object.keys(patientTodayAndUpcomingTasksGroupedByPathway)
              .length ? (
              intakesPathwaysIds.map((pathwayId) => {
                return patientTodayAndUpcomingTasksGroupedByPathway[
                  pathwayId
                ] ? (
                  <Grid container key={pathwayId} columnSpacing={'8px'}>
                    {patientTodayAndUpcomingTasksGroupedByPathway[pathwayId]
                      .slice(0, 3)
                      .map((task) => (
                        <Grid
                          item
                          key={task.id}
                          sm={4}
                          display={'flex'}
                          columnGap={'8px'}
                          alignItems={'center'}
                        >
                          <PatientTaskContent
                            task={task}
                            bodyPart={patientTouchpointsPathwayInfo[pathwayId]}
                          />
                        </Grid>
                      ))}
                  </Grid>
                ) : (
                  <Grid container key={pathwayId}>
                    <Grid item>&nbsp;</Grid>
                  </Grid>
                )
              })
            ) : (
              <Typography>No upcoming tasks</Typography>
            )}
          </Box>
        </Grid>
      </Grid>
      <PatientTasksCalendarModal />
    </Box>
  )
}
