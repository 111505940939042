import { usePathwaysServiceAdapter } from 'src/features/pathways/adapters'
import { UsePathwaysUseCases } from 'src/features/pathways/domain'

type MakeUsePathwaysUseCases = () => UsePathwaysUseCases

const makeUsePathwaysUseCases: MakeUsePathwaysUseCases = () => () => {
  const pathwaysServiceAdapter = usePathwaysServiceAdapter()

  return {
    getPathways: pathwaysServiceAdapter.getPathways,
    createPathway: pathwaysServiceAdapter.createPathway,
    updatePathway: pathwaysServiceAdapter.updatePathway,
    reassignPathway: pathwaysServiceAdapter.reassignPathway,
  }
}

export const usePathwaysUseCases: UsePathwaysUseCases =
  makeUsePathwaysUseCases()
