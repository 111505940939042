import { AxiosError } from 'axios'

import { APIError } from 'src/features/shared/adapters'
import { UseCaseDefaultError } from 'src/features/shared/domain'

export const useAxiosErrorHandling = () => {
  const handleAxiosError = <
    TServiceErrorResponse,
    TError extends UseCaseDefaultError = UseCaseDefaultError
  >(
    error: AxiosError<any, any>,
    mapToError: (response: TServiceErrorResponse) => TError
  ) => {
    const errorResponse = error.response
    if (errorResponse) {
      let message = 'Something went wrong'
      if (errorResponse.data) {
        if (errorResponse.status === 404) {
          message = errorResponse.data.error
        } else {
          const error = mapToError(errorResponse.data as TServiceErrorResponse)
          message = error.message
        }
      }
      throw new APIError(message)
    }
    // TODO: handle network error
  }

  return {
    handleAxiosError,
  }
}
