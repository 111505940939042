import { FC, useEffect } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  TextField,
  Tooltip,
  FormHelperText,
  FormLabel,
  FormControl,
  IconButton,
} from '@mui/material'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import {
  DateRangePicker,
  SingleInputDateRangeField,
} from '@mui/x-date-pickers-pro'

import HighlightOffIcon from '@mui/icons-material/HighlightOff'

import {
  FullSectionLoader,
  ModalDialogTitle,
  MultiSelect,
} from 'src/features/shared/presentation'
import {
  MARKETS,
  REFERRAL_SOURCES,
  REMOVED_REFERRAL_SOURCES,
  STAGES,
} from 'src/features/shared/constants'
import {
  mapNavigatorToMultiSelectOption,
  mapToMultiSelectOption,
} from 'src/features/shared/adapters'
import { WithChildren } from 'src/features/shared/types'
import { useQueuePatientsFilters } from 'src/features/queues/presentation'
import { useGetPlansQuery } from 'src/features/providers/presentation'
import { mapPlanToMultiSelectOption } from 'src/features/providers/adapters'
import { useGetPatientStatusDefinitionsQuery } from 'src/features/patients/presentation'
import { mapStatusToMultiSelectOption } from 'src/features/patients/adapters'

type FilterFieldWrapperProps = {
  clearButtonTooltipTitle: string
  onClearButtonClick: () => void
  showClearButton?: boolean
}

const FilterFieldWrapper: FC<WithChildren<FilterFieldWrapperProps>> = ({
  clearButtonTooltipTitle,
  onClearButtonClick,
  showClearButton,
  children,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      {children}
      {showClearButton ? (
        <Tooltip title={clearButtonTooltipTitle} arrow>
          <IconButton
            onClick={onClearButtonClick}
            sx={{
              position: 'absolute',
              right: '-40px',
              top: 'calc(50% - 20px)',
            }}
          >
            <HighlightOffIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </Box>
  )
}

export const QueuePatientsFilterModal: FC = () => {
  const {
    clearAssignedTo,
    clearLastToContact,
    clearLinkedPlanId,
    clearReferralSource,
    clearStage,
    clearState,
    clearStatus,
    clearMarket,
    errors,
    getAttemptsQtyFromFilterHandler,
    getAttemptsQtyToFilterHandler,
    getChangeFilterHandler,
    getDateOfLastAttemptFilterHandler,
    getFormFieldSx,
    getMultiSelectFormControlSx,
    getNavigatorsIsLoading,
    handleApplyClick,
    handleCancelClick,
    handleModalClose,
    hasAtLeastOneQueuePatientsFilterSelected,
    navigators,
    openQueuePatientsFilterModal,
    queuePatientsSelectedAssignedToFilter,
    queuePatientsSelectedLastToContactFilter,
    queuePatientsSelectedLinkedPlanIdFilter,
    queuePatientsSelectedReferralSourceFilter,
    queuePatientsSelectedMarketFilter,
    queuePatientsSelectedStatusFilter,
    queuePatientsSelectedStageFilter,
    clearAttemptQty,
    clearDateOfLastAttempt,
    setInitialState,
    setQueuePatientsSelectedAssignedToFilter,
    setQueuePatientsSelectedLastToContactFilter,
    setQueuePatientsSelectedLinkedPlanIdFilter,
    setQueuePatientsSelectedStatusFilter,
    setQueuePatientsSelectedMarketFilter,
    setQueuePatientsSelectedReferralSourceFilter,
    setQueuePatientsSelectedStageFilter,
    queuePatientsSelectedDateOfLastAttemptStartFilter,
    queuePatientsSelectedDateOfLastAttemptEndFilter,
    queuePatientsSelectedAttempsQtyFromFilter,
    queuePatientsSelectedAttempsQtyToFilter,
    lastDateOfAttemptDatePickerOpen,
    setLastDateOfAttemptDatePickerOpen,
    lastDateOfAttemptValues,
  } = useQueuePatientsFilters()

  const { plans } = useGetPlansQuery()
  const { mainStatuses } = useGetPatientStatusDefinitionsQuery()

  const sortedStatuses = mainStatuses.sort((a, b) => {
    if (a < b) {
      return -1
    }
    if (a > b) {
      return 1
    }
    return 0
  })

  const getReferralSourceOptions = () => {
    return [
      ...REFERRAL_SOURCES.map((referralSource) => ({
        label: referralSource,
        key: referralSource,
        value: referralSource,
      })),
      ...REMOVED_REFERRAL_SOURCES.map((referralSource) => ({
        label: `${referralSource} (Deprecated)`,
        key: referralSource,
        value: referralSource,
      })),
    ]
  }
  const getMarketOptions = () => {
    return [
      ...MARKETS.map((market) => ({
        label: market,
        key: market,
        value: market,
      })),
    ]
  }

  useEffect(() => {
    setInitialState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog
      onClose={handleModalClose}
      open={openQueuePatientsFilterModal}
      sx={{
        zIndex: 2,
        '& .MuiPaper-root': {
          xs: {
            maxWidth: '1024px',
            minWidth: '768px',
          },
        },
      }}
    >
      <ModalDialogTitle>Filter By</ModalDialogTitle>
      <DialogContent>
        {getNavigatorsIsLoading ? (
          <FullSectionLoader />
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                columnGap: '64px',
                padding: ' 8px 24px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '16px',
                }}
              >
                <FilterFieldWrapper
                  clearButtonTooltipTitle="Clear all Stage filters"
                  onClearButtonClick={clearStage}
                  showClearButton={queuePatientsSelectedStageFilter.length > 0}
                >
                  <MultiSelect
                    formControlSx={getMultiSelectFormControlSx()}
                    displayOptionType="checkbox"
                    displayValueType="chip"
                    id="filter-stages"
                    label="Stage"
                    options={STAGES.map(mapToMultiSelectOption)}
                    selectedOptions={queuePatientsSelectedStageFilter}
                    onChange={getChangeFilterHandler(
                      setQueuePatientsSelectedStageFilter
                    )}
                  />
                </FilterFieldWrapper>
                <FilterFieldWrapper
                  clearButtonTooltipTitle="Clear all Plan / Client filters"
                  onClearButtonClick={clearLinkedPlanId}
                  showClearButton={
                    queuePatientsSelectedLinkedPlanIdFilter.length > 0
                  }
                >
                  <MultiSelect
                    formControlSx={getMultiSelectFormControlSx()}
                    displayOptionType="checkbox"
                    displayValueType="chip"
                    id="filter-linked-plan-id"
                    label="Plan Name"
                    options={plans.map(mapPlanToMultiSelectOption)}
                    selectedOptions={queuePatientsSelectedLinkedPlanIdFilter}
                    onChange={getChangeFilterHandler(
                      setQueuePatientsSelectedLinkedPlanIdFilter
                    )}
                  />
                </FilterFieldWrapper>
                <FilterFieldWrapper
                  clearButtonTooltipTitle="Clear all Assigned To filters"
                  onClearButtonClick={clearAssignedTo}
                  showClearButton={
                    queuePatientsSelectedAssignedToFilter.length > 0
                  }
                >
                  <MultiSelect
                    formControlSx={getMultiSelectFormControlSx()}
                    displayOptionType="checkbox"
                    displayValueType="chip"
                    id="filter-assigned-to"
                    label="Assigned To"
                    options={navigators.map(mapNavigatorToMultiSelectOption)}
                    selectedOptions={queuePatientsSelectedAssignedToFilter}
                    onChange={getChangeFilterHandler(
                      setQueuePatientsSelectedAssignedToFilter
                    )}
                  />
                </FilterFieldWrapper>
                <FilterFieldWrapper
                  clearButtonTooltipTitle="Clear all Source / Tags filters"
                  onClearButtonClick={clearReferralSource}
                  showClearButton={
                    queuePatientsSelectedReferralSourceFilter.length > 0
                  }
                >
                  <MultiSelect
                    formControlSx={getMultiSelectFormControlSx()}
                    displayOptionType="checkbox"
                    displayValueType="chip"
                    id="filter-referral-sources"
                    label="Source / Tags"
                    options={getReferralSourceOptions()}
                    selectedOptions={queuePatientsSelectedReferralSourceFilter}
                    onChange={getChangeFilterHandler(
                      setQueuePatientsSelectedReferralSourceFilter
                    )}
                  />
                </FilterFieldWrapper>
                <FilterFieldWrapper
                  clearButtonTooltipTitle="Clear all Market filters"
                  onClearButtonClick={clearMarket}
                  showClearButton={queuePatientsSelectedMarketFilter.length > 0}
                >
                  <MultiSelect
                    formControlSx={getMultiSelectFormControlSx()}
                    displayOptionType="checkbox"
                    displayValueType="chip"
                    id="filter-markets"
                    label="Market"
                    options={[
                      {
                        value: (() => {
                          const markets = MARKETS.filter((market) =>
                            ['Atlanta', 'None'].includes(market)
                          )
                          return markets.join(',')
                        })(),
                        key: 'Care folk - Atlanta',
                        label: 'Care folk - Atlanta',
                      },
                      {
                        value: (() => {
                          const markets = MARKETS.filter(
                            (market) => !['Atlanta', 'None'].includes(market)
                          )
                          return markets.join(',')
                        })(),
                        key: 'Care folk - Non-Atlanta',
                        label: `Care folk - Non-Atlanta`,
                      },
                      ...getMarketOptions(),
                    ]}
                    selectedOptions={queuePatientsSelectedMarketFilter}
                    onChange={getChangeFilterHandler(
                      setQueuePatientsSelectedMarketFilter
                    )}
                  />
                </FilterFieldWrapper>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '16px',
                }}
              >
                <FilterFieldWrapper
                  clearButtonTooltipTitle="Clear all Last to Outreach filters"
                  onClearButtonClick={clearLastToContact}
                  showClearButton={
                    queuePatientsSelectedLastToContactFilter.length > 0
                  }
                >
                  <MultiSelect
                    formControlSx={getMultiSelectFormControlSx()}
                    displayOptionType="checkbox"
                    displayValueType="chip"
                    id="filter-last-to-contact"
                    label="Last to Outreach"
                    options={[
                      {
                        value: 'admin',
                        key: 'admin',
                        label: `Admin (${
                          navigators.filter((nav) => nav.type === 'admin')
                            .length
                        })`,
                      },
                      {
                        value: 'clinical',
                        key: 'clinical',
                        label: `Clinical (${
                          navigators.filter((nav) => nav.type === 'clinical')
                            .length
                        })`,
                      },
                      {
                        value: 'non-clinical',
                        key: 'non-clinical',
                        label: `Non-Clinical (${
                          navigators.filter(
                            (nav) => nav.type === 'non-clinical'
                          ).length
                        })`,
                      },
                      {
                        value: 'divider',
                        key: 'divider',
                        label: '---------',
                        disabled: true,
                      },
                      ...navigators.map(mapNavigatorToMultiSelectOption),
                    ]}
                    selectedOptions={queuePatientsSelectedLastToContactFilter}
                    onChange={getChangeFilterHandler(
                      setQueuePatientsSelectedLastToContactFilter
                    )}
                  />
                </FilterFieldWrapper>
                <FilterFieldWrapper
                  clearButtonTooltipTitle="Clear all Status filters"
                  onClearButtonClick={clearStatus}
                  showClearButton={queuePatientsSelectedStatusFilter.length > 0}
                >
                  <MultiSelect
                    formControlSx={getMultiSelectFormControlSx()}
                    displayOptionType="checkbox"
                    displayValueType="chip"
                    id="filter-status"
                    label="Status"
                    options={sortedStatuses.map(mapStatusToMultiSelectOption)}
                    selectedOptions={queuePatientsSelectedStatusFilter}
                    onChange={getChangeFilterHandler(
                      setQueuePatientsSelectedStatusFilter
                    )}
                  />
                </FilterFieldWrapper>
                <FormControl
                  sx={{
                    ...getFormFieldSx(),
                  }}
                >
                  <FilterFieldWrapper
                    clearButtonTooltipTitle="Clear Date Range"
                    onClearButtonClick={clearDateOfLastAttempt}
                    showClearButton={
                      !!queuePatientsSelectedDateOfLastAttemptStartFilter ||
                      !!queuePatientsSelectedDateOfLastAttemptEndFilter
                    }
                  >
                    <Box
                      sx={{
                        position: 'relative',
                        width: '100%',
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateRangePicker
                          calendars={1}
                          open={lastDateOfAttemptDatePickerOpen}
                          slots={{
                            field: SingleInputDateRangeField,
                          }}
                          label="Last Attempt Date Range"
                          onClose={() => {
                            if (lastDateOfAttemptDatePickerOpen) {
                              setLastDateOfAttemptDatePickerOpen(false)
                            }
                          }}
                          onOpen={() => {
                            if (!lastDateOfAttemptDatePickerOpen) {
                              setLastDateOfAttemptDatePickerOpen(true)
                            }
                          }}
                          onChange={getDateOfLastAttemptFilterHandler}
                          value={lastDateOfAttemptValues}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                            },
                          }}
                        />
                      </LocalizationProvider>
                      <IconButton
                        sx={{
                          position: 'absolute',
                          right: '0',
                          top: 'calc(50% - 20px)',
                        }}
                        onMouseDown={() => {
                          setLastDateOfAttemptDatePickerOpen(
                            (prevState) => !prevState
                          )
                        }}
                      >
                        <CalendarMonthIcon
                          sx={{
                            overflow: 'hidden',
                          }}
                        />
                      </IconButton>
                    </Box>
                  </FilterFieldWrapper>
                  {errors.dateOfLastAttemptEnd ? (
                    <FormHelperText error>
                      {errors.dateOfLastAttemptEnd}
                    </FormHelperText>
                  ) : null}
                </FormControl>
                <FormControl
                  sx={{
                    ...getFormFieldSx(),
                  }}
                >
                  <Box>
                    <FormLabel error={!!errors.attemptsQtyTo}>
                      Attempts Quantity
                    </FormLabel>
                    <FilterFieldWrapper
                      clearButtonTooltipTitle="Clear Attempts Range"
                      onClearButtonClick={clearAttemptQty}
                      showClearButton={
                        !!queuePatientsSelectedAttempsQtyFromFilter ||
                        !!queuePatientsSelectedAttempsQtyToFilter
                      }
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          columnGap: '32px',
                          pr: '16px',
                        }}
                      >
                        <TextField
                          label="From"
                          type="number"
                          variant="standard"
                          onChange={getAttemptsQtyFromFilterHandler}
                          value={queuePatientsSelectedAttempsQtyFromFilter}
                          inputProps={{
                            min: 0,
                          }}
                        />
                        <TextField
                          label="To"
                          type="number"
                          variant="standard"
                          onChange={getAttemptsQtyToFilterHandler}
                          value={queuePatientsSelectedAttempsQtyToFilter}
                          inputProps={{
                            min: 1,
                          }}
                        />
                      </Box>
                    </FilterFieldWrapper>
                  </Box>
                  {errors.attemptsQtyTo ? (
                    <FormHelperText error>
                      {errors.attemptsQtyTo}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Box>
            </Box>
          </>
        )}
      </DialogContent>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '12px 24px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {hasAtLeastOneQueuePatientsFilterSelected() ? (
            <Button
              onClick={clearState}
              sx={{
                color: 'rgba(0, 0, 0, 0.38)',
                fontSize: '10px',
                fontWeight: '700',
              }}
            >
              CLEAR ALL
            </Button>
          ) : null}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Button color="secondary" onClick={handleCancelClick}>
            CANCEL
          </Button>
          <Button type="submit" color="secondary" onClick={handleApplyClick}>
            APPLY
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
