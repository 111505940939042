import { FC, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'

import { ChildrenInProps } from 'src/features/shared/types'
import { useAuth } from 'src/features/shared/infrastructure'
import {
  WarningModal,
  useInterval,
  useSessionStorage,
} from 'src/features/shared/presentation'
import * as settings from 'src/config/settings'

const IDLE_TIMEOUT = 1000 * 60 * settings.IDLE_TIMEOUT_MINUTES
const PROMPT_BEFORE_IDLE = 1000 * 60 // 1 minute

export const IdleProvider: FC<ChildrenInProps> = ({ children }) => {
  const [openStillThereModal, setOpenStillThereModal] = useState(false)
  const [remaining, setRemaining] = useState(IDLE_TIMEOUT)
  const { isAuthenticated, logout } = useAuth()
  const [, setLoggedOut] = useSessionStorage('loggedOut', false)

  const onIdle = () => {
    if (isAuthenticated) {
      setLoggedOut(true)
      logout()
    }
  }
  const onPrompt = () => {
    if (isAuthenticated) {
      setOpenStillThereModal(true)
    }
  }
  const onActive = () => {
    setOpenStillThereModal(false)
  }

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout: IDLE_TIMEOUT,
    promptBeforeIdle: PROMPT_BEFORE_IDLE,
    throttle: 500,
  })

  const handleStillHere = () => {
    activate()
  }

  useInterval(() => {
    setRemaining(Math.ceil(getRemainingTime() / 1000))
  }, 500)

  return (
    <>
      {children}
      <WarningModal
        open={openStillThereModal}
        title={'Are you still there?'}
        message={`You will be logged out in ${remaining} seconds.`}
        onConfirm={handleStillHere}
        confirmButtonVariant={'contained'}
        confirmButtonText={"Yes, I'm still here"}
        actionsAlignment={'center'}
      />
    </>
  )
}
