import { useMemo } from 'react'

import { useSearchParams } from 'react-router-dom'

export const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const queryParams = useMemo(
    () => Object.fromEntries(new URLSearchParams(searchParams).entries()),
    [searchParams]
  )

  const getQueryParam = (key: string, defaultValue?: string) => {
    const queryParam = searchParams.get(key)
    if (queryParam) {
      return queryParam
    }
    return defaultValue
  }

  const setQueryParam = (key: string, value: string) => {
    setSearchParams((prevParams) => {
      return { ...Object.fromEntries(prevParams.entries()), [key]: value }
    })
  }

  const removeQueryParam = (key: string) => {
    setSearchParams((prevParams) => {
      const params = Object.fromEntries(prevParams.entries())
      delete params[key]
      return params
    })
  }

  const updateQueryParams = (params: Record<string, string>) => {
    setSearchParams((prevParams) => {
      return { ...Object.fromEntries(prevParams.entries()), ...params }
    })
  }

  return {
    queryParams,
    getQueryParam,
    setQueryParam,
    removeQueryParam,
    updateQueryParams,
    searchParams,
    setSearchParams,
  }
}
