import {
  UseMutateAsyncFunction,
  UseMutateFunction,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import {
  CreatePatientCustomNoteReturns,
  CreatePatientCustomNoteArgs,
  CreatePatientCustomNoteError,
} from 'src/features/notes/domain'
import { GET_PATIENT_QUERY_KEY_V2 } from 'src/features/patients/presentation'
import {
  GET_PATIENT_DRAFT_NOTE,
  GET_PATIENT_NOTES_QUERY_KEY,
} from 'src/features/notes/presentation'
import { useNotesUseCases } from 'src/features/notes/notes.service-locator'

type UseCreatePatientCustomNoteProps = Omit<
  UseMutationOptions<
    CreatePatientCustomNoteReturns,
    CreatePatientCustomNoteError,
    CreatePatientCustomNoteArgs,
    unknown
  >,
  'mutationFn'
>

type UseCreatePatientCustomNote = (props?: UseCreatePatientCustomNoteProps) => {
  createPatientCustomNote: UseMutateFunction<
    CreatePatientCustomNoteReturns,
    CreatePatientCustomNoteError,
    CreatePatientCustomNoteArgs,
    unknown
  >
  createPatientCustomNoteAsync: UseMutateAsyncFunction<
    CreatePatientCustomNoteReturns,
    CreatePatientCustomNoteError,
    CreatePatientCustomNoteArgs,
    unknown
  >
  createPatientCustomNoteIsLoading: boolean
  createPatientCustomNoteIsError: boolean
  createPatientCustomNoteStatus: string
}

export const useCreatePatientCustomNote: UseCreatePatientCustomNote = (
  props = {}
) => {
  const queryClient = useQueryClient()
  const notesUseCases = useNotesUseCases()

  const { onSuccess } = props

  const { mutate, mutateAsync, isLoading, isError, status } = useMutation<
    CreatePatientCustomNoteReturns,
    CreatePatientCustomNoteError,
    CreatePatientCustomNoteArgs,
    unknown
  >({
    mutationFn: (args: CreatePatientCustomNoteArgs) => {
      return notesUseCases.createPatientCustomNote(args)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [GET_PATIENT_QUERY_KEY_V2, variables.patientId],
      })
      queryClient.invalidateQueries({
        queryKey: [GET_PATIENT_NOTES_QUERY_KEY, variables.patientId],
      })
      queryClient.invalidateQueries({
        queryKey: [GET_PATIENT_DRAFT_NOTE, variables.patientId],
      })
      onSuccess?.(data, variables, context)
    },
  })
  return {
    createPatientCustomNote: mutate,
    createPatientCustomNoteAsync: mutateAsync,
    createPatientCustomNoteIsLoading: isLoading,
    createPatientCustomNoteIsError: isError,
    createPatientCustomNoteStatus: status,
  }
}
