import axios from 'axios'

import { useCareTeamService } from 'src/features/care-team/infrastructure'
import { CareTeamUseCases } from 'src/features/care-team/domain'
import {
  mapToCreateCareTeamError,
  mapToCreateCareTeamReturns,
  mapToCreateCareTeamServicePayload,
  mapToUpdateCareTeamError,
  mapToUpdateCareTeamReturns,
  mapToUpdateCareTeamServiceArgs,
} from 'src/features/care-team/adapters'
import { useAxiosErrorHandling } from 'src/features/shared/infrastructure'

export const useCareTeamServiceAdapter = () => {
  const careTeamService = useCareTeamService()
  const { handleAxiosError } = useAxiosErrorHandling()

  const createCareTeam: CareTeamUseCases['createCareTeam'] = async (args) => {
    try {
      const servicePayload = mapToCreateCareTeamServicePayload(args)
      const response = await careTeamService.createCareTeam(servicePayload)
      return mapToCreateCareTeamReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToCreateCareTeamError)
      }
      throw error
    }
  }

  const updateCareTeam: CareTeamUseCases['updateCareTeam'] = async (args) => {
    try {
      const serviceArgs = mapToUpdateCareTeamServiceArgs(args)
      const response = await careTeamService.updateCareTeam(serviceArgs)
      return mapToUpdateCareTeamReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToUpdateCareTeamError)
      }
      throw error
    }
  }

  return {
    createCareTeam,
    updateCareTeam,
  }
}
