import {
  useAppDispatch,
  useAppSelector,
} from 'src/features/shared/infrastructure'
import {
  SearchProvidersState,
  firstNameSelector,
  setFirstNameAction,
  lastNameSelector,
  setLastNameAction,
  providerGroupIdSelector,
  setProviderGroupIdAction,
  isLoadingSelector,
  setIsLoadingAction,
  pageSelector,
  setPageAction,
  rowsPerPageSelector,
  setRowsPerPageAction,
  searchResultsSelector,
  setSearchResultsAction,
  selectedProviderSelector,
  setSelectedProviderAction,
} from 'src/features/shared/infrastructure'

export const useSearchProvidersStore = () => {
  const dispatch = useAppDispatch()

  const firstName = useAppSelector(firstNameSelector)
  const lastName = useAppSelector(lastNameSelector)
  const providerGroupId = useAppSelector(providerGroupIdSelector)
  const isLoading = useAppSelector(isLoadingSelector)
  const page = useAppSelector(pageSelector)
  const rowsPerPage = useAppSelector(rowsPerPageSelector)
  const searchResults = useAppSelector(searchResultsSelector)
  const selectedProvider = useAppSelector(selectedProviderSelector)

  const setFirstName = (firstName: SearchProvidersState['firstName']) =>
    dispatch(setFirstNameAction(firstName))

  const setLastName = (lastName: SearchProvidersState['lastName']) =>
    dispatch(setLastNameAction(lastName))

  const setProviderGroupId = (
    providerGroupId: SearchProvidersState['providerGroupId']
  ) => dispatch(setProviderGroupIdAction(providerGroupId))

  const setIsLoading = (isLoading: SearchProvidersState['isLoading']) =>
    dispatch(setIsLoadingAction(isLoading))

  const setPage = (page: SearchProvidersState['page']) =>
    dispatch(setPageAction(page))

  const setRowsPerPage = (rowsPerPage: SearchProvidersState['rowsPerPage']) =>
    dispatch(setRowsPerPageAction(rowsPerPage))

  const setSearchResults = (
    searchResults: SearchProvidersState['searchResults']
  ) => dispatch(setSearchResultsAction(searchResults))

  const setSelectedProvider = (
    selectedProvider: SearchProvidersState['selectedProvider']
  ) => dispatch(setSelectedProviderAction(selectedProvider))

  return {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    providerGroupId,
    setProviderGroupId,
    isLoading,
    setIsLoading,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    searchResults,
    setSearchResults,
    selectedProvider,
    setSelectedProvider,
  }
}
