import { IconButton } from '@mui/material'
import { FC } from 'react'

import LogoutIcon from '@mui/icons-material/Logout'

import { useAuth } from 'src/features/shared/infrastructure'

export const LogoutButton: FC = () => {
  const { logout } = useAuth()
  const handleLogOutClick = () => {
    logout()
  }
  return (
    <>
      <IconButton onClick={handleLogOutClick}>
        <LogoutIcon />
      </IconButton>
    </>
  )
}
