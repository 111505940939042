import axios from 'axios'

import { usePayorsService } from 'src/features/providers/infrastructure'

import { useAxiosErrorHandling } from 'src/features/shared/infrastructure'
import { PayorsUseCases } from 'src/features/providers/domain'
import {
  mapToCreatePayorError,
  mapToCreatePayorReturns,
  mapToCreatePayorServiceArgs,
  mapToGetPayorsError,
  mapToGetPayorsReturns,
} from 'src/features/providers/adapters'

export const usePayorsServiceAdapter = () => {
  const payorsService = usePayorsService()
  const { handleAxiosError } = useAxiosErrorHandling()

  const getPayors: PayorsUseCases['getPayors'] = async () => {
    try {
      const response = await payorsService.getPayors()

      return mapToGetPayorsReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToGetPayorsError)
      }
      throw error
    }
  }

  const createPayor: PayorsUseCases['createPayor'] = async (args) => {
    try {
      const serviceArgs = mapToCreatePayorServiceArgs(args)

      const response = await payorsService.createPayor(serviceArgs)

      return mapToCreatePayorReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToCreatePayorError)
      }
      throw error
    }
  }

  return {
    getPayors,
    createPayor,
  }
}
