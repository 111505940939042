import { Box, SelectChangeEvent, TextField } from '@mui/material'
import { ChangeEvent, FC } from 'react'

import { LoadMSKFormReturns } from 'src/features/msk/domain'
import {
  MSKFormStateIntakeAssessment,
  MSKIntakeAssessmentDifficultActions,
  useMSKFormStore,
} from 'src/features/shared/infrastructure'
import {
  MultiSelect,
  MultiSelectOption,
  SliderField,
} from 'src/features/shared/presentation'

import {
  FunctionLevelSliderFriendlyMarks,
  PainLevelSliderFriendlyMarks,
} from 'src/features/msk/presentation'

import { mapBodyPartToMultiSelectOption } from 'src/features/msk/adapters'

type IntakeAssessmentPainAndFunctionProps = {
  loadedMSKForm: LoadMSKFormReturns
  currentIntakeAssessment: MSKFormStateIntakeAssessment
}

const marks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 1,
  },
  {
    value: 2,
  },
  {
    value: 3,
  },
  {
    value: 4,
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
  },
  {
    value: 7,
  },
  {
    value: 8,
  },
  {
    value: 9,
  },
  {
    value: 10,
    label: '10',
  },
]

const activityDifficulties = [
  { id: 'caregiving', display: 'caregiving' },
  { id: 'dressing', display: 'dressing' },
  {
    id: 'getting out of a chair/car',
    display: 'getting out of a chair/car',
  },
  { id: 'housework', display: 'housework' },
  { id: 'kneeling', display: 'kneeling' },
  {
    id: 'lifting/carrying objects',
    display: 'lifting/carrying objects',
  },
  { id: 'sleep', display: 'sleep' },
  { id: 'stairs', display: 'stairs' },
  { id: 'walking', display: 'walking' },
  { id: 'other', display: 'other' },
]

export const IntakeAssessmentPainAndFunction: FC<
  IntakeAssessmentPainAndFunctionProps
> = ({ loadedMSKForm, currentIntakeAssessment }) => {
  const {
    setCurrentCondition,
    setCurrentPainLevel,
    setCurrentFunctionLevel,
    setSelectedDifficultActions,
    setDifficultActionOtherText,
  } = useMSKFormStore({
    loadedMSKForm,
  })

  const isDifficultActionOtherSelected =
    currentIntakeAssessment.difficultAction.includes('other')

  const handlePainLevelChange = (event: Event, value: number | number[]) => {
    setCurrentCondition(currentIntakeAssessment.botheredBodyPart)
    setCurrentPainLevel(value as number)
  }

  const handleFunctionLevelChange = (
    event: Event,
    value: number | number[]
  ) => {
    setCurrentCondition(currentIntakeAssessment.botheredBodyPart)
    setCurrentFunctionLevel(value as number)
  }

  const handleDifficultActionChange = (
    event: SelectChangeEvent<MultiSelectOption['value'][]>
  ) => {
    const {
      target: { value },
    } = event
    setCurrentCondition(currentIntakeAssessment.botheredBodyPart)
    // On autofill we get a stringified value.
    const selectedValueList =
      typeof value === 'string' ? value.split(',') : value

    const difficultActions = activityDifficulties
      .filter((difficultAction) =>
        selectedValueList.includes(difficultAction.id)
      )
      .map(
        (difficultAction) => difficultAction.id
      ) as MSKIntakeAssessmentDifficultActions
    setSelectedDifficultActions(difficultActions)
  }

  const handleDifficultActionOtherTextChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setCurrentCondition(currentIntakeAssessment.botheredBodyPart)
    const value = event.target.value
    setDifficultActionOtherText(value)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '80px',
      }}
    >
      <Box
        sx={{
          width: '380px',
        }}
      >
        <MultiSelect
          displayOptionType="checkbox"
          id={'msk_form_most_difficult_activity'}
          data-testid={'msk_form_most_difficult_activity'}
          label={`What is the activity you are having most difficulty with because
          of your ${currentIntakeAssessment.botheredBodyPart.display}
          issue?`}
          labelComponent="formLabel"
          options={activityDifficulties.map(mapBodyPartToMultiSelectOption)}
          selectedOptions={currentIntakeAssessment.difficultAction}
          onChange={handleDifficultActionChange}
          formControlSx={{
            m: 0,
            width: '100%',
          }}
        />

        {isDifficultActionOtherSelected && (
          <TextField
            label={'Difficult Action Other Text'}
            value={currentIntakeAssessment.difficultActionOtherText}
            size={'small'}
            sx={{
              width: '100%',
              '& .MuiInputBase-input': {
                background: 'white',
              },
              m: '15px 0px 0px 0px',
            }}
            onChange={handleDifficultActionOtherTextChange}
            required={isDifficultActionOtherSelected}
          />
        )}
      </Box>
      <SliderField
        label={`How would you rate your ability to do your normal daily activities in relationship to your ${currentIntakeAssessment.botheredBodyPart.display}? (such as brushing your teeth, walking your dog, etc.)`}
        sliderProps={{
          value: currentIntakeAssessment.currentFunctionLevel,
          onChange: handleFunctionLevelChange,
          marks: marks,
          track: false,
          step: 1,
          min: 0,
          max: 10,
          valueLabelDisplay: 'auto',
        }}
        friendlyMarks={
          <FunctionLevelSliderFriendlyMarks
            setFunctionLevel={setCurrentFunctionLevel}
          />
        }
      />
      <SliderField
        label={`What is the pain level of your ${currentIntakeAssessment.botheredBodyPart.display}?`}
        sliderProps={{
          value: currentIntakeAssessment.currentPainLevel,
          onChange: handlePainLevelChange,
          marks: marks,
          track: false,
          step: 1,
          min: 0,
          max: 10,
          valueLabelDisplay: 'auto',
        }}
        friendlyMarks={
          <PainLevelSliderFriendlyMarks setPainLevel={setCurrentPainLevel} />
        }
      />
    </Box>
  )
}
