import {
  CreatePlanServicePayload,
  CreatePlanServiceArgs,
  CreatePlanServiceResponse,
  SPlan,
  CreatePlanServiceErrorResponse,
  GetPlansServiceResponse,
  GetPlansServiceErrorResponse,
  GetPlansServiceFilters,
  GetPlansServiceArgs,
} from 'src/features/providers/infrastructure'
import {
  Plan,
  CreatePlanArgs,
  CreatePlanError,
  CreatePlanPayload,
  CreatePlanReturns,
  GetPlansError,
  GetPlansReturns,
  GetPlansFilters,
  GetPlansArgs,
} from 'src/features/providers/domain'

type MapToPlan = (plan: SPlan) => Plan

export const mapToPlan: MapToPlan = (plan) => {
  return {
    name: plan.plan,
    id: plan.id,
  }
}

// -----------
// GET PLANS /
// -----------
type MapToGetPlansServiceArgs = (
  args?: GetPlansArgs
) => GetPlansServiceArgs | undefined

export const mapToGetPlansServiceArgs: MapToGetPlansServiceArgs = (args) => {
  if (!args) return undefined

  return {
    filters: args.filters
      ? mapToGetPlansServiceFilters(args.filters)
      : undefined,
  }
}

type MapToGetPlansServiceFilters = (
  filters: GetPlansFilters
) => GetPlansServiceFilters

export const mapToGetPlansServiceFilters: MapToGetPlansServiceFilters = (
  filters
) => ({ payorId: filters.payorId })

type MapToGetPlansReturns = (
  response: GetPlansServiceResponse
) => GetPlansReturns

export const mapToGetPlansReturns: MapToGetPlansReturns = (response) => {
  return response.plans.map((plan) => {
    return { name: plan.plan, id: plan.id, payorId: plan.payorId }
  })
}

export type MapToGetPlansError = (
  error: GetPlansServiceErrorResponse
) => GetPlansError

export const mapToGetPlansError: MapToGetPlansError = (error) => ({
  ...error,
})

type MapToMultiSelectOptionPlan = (plan: Plan) => {
  label: string
  value: string
  key: string
}

export const mapPlanToMultiSelectOption: MapToMultiSelectOptionPlan = (
  plan
) => {
  return {
    label: plan.name,
    value: plan.id,
    key: plan.id,
  }
}

// -------------
// CREATE PLAN /
// -------------

type MapToCreatePlanServicePayload = (
  payload: CreatePlanPayload
) => CreatePlanServicePayload

export const mapToCreatePlanPayload: MapToCreatePlanServicePayload = (
  payload
) => ({
  plan: payload.name,
  payorId: payload.payorId,
})

type MapToCreatePlanServiceArgs = (
  args: CreatePlanArgs
) => CreatePlanServiceArgs

export const mapToCreatePlanServiceArgs: MapToCreatePlanServiceArgs = (
  args
) => ({
  payload: mapToCreatePlanPayload(args.payload),
})

type MapToCreatePlanReturns = (
  response: CreatePlanServiceResponse
) => CreatePlanReturns

export const mapToCreatePlanReturns: MapToCreatePlanReturns = (response) => {
  return mapToPlan(response)
}

type MapToCreatePlanError = (
  error: CreatePlanServiceErrorResponse
) => CreatePlanError

export const mapToCreatePlanError: MapToCreatePlanError = (error) => ({
  ...error,
})
