import { useQuery } from '@tanstack/react-query'

import { useTasksUseCases } from 'src/features/tasks/tasks.service-locator'
import { GetTasksParams } from 'src/features/tasks/domain'

export const GET_DONE_TASKS_BY_USER_ID_QUERY_KEY = 'getAllDoneTasksByUserId'

type UseGetDoneTasksByUserIdArgs = {
  params?: GetTasksParams
}

export const useGetDoneTasksByUserIdQuery = ({
  params,
}: UseGetDoneTasksByUserIdArgs = {}) => {
  const tasksUseCases = useTasksUseCases()

  const { data, isLoading, isError, refetch, isFetching } = useQuery({
    queryKey: [GET_DONE_TASKS_BY_USER_ID_QUERY_KEY, params],
    queryFn: () => {
      return tasksUseCases.getDoneTasksByUserId({
        params,
      })
    },
  })
  return {
    userDoneTasksData: data,
    userDoneTasks: data?.tasks ? data.tasks : [],
    refetchUserDoneTasks: refetch,
    userDoneTasksIsLoading: isLoading,
    userDoneTasksIsError: isError,
    userDoneTasksIsFetching: isFetching,
  }
}
