import { GetPatientPainAndFunctionV2ServiceResponse } from 'src/features/patients/infrastructure'

export const getPatientPainAndFunctionV2ServiceResponseMock: GetPatientPainAndFunctionV2ServiceResponse =
  {
    min: 0,
    max: 10,
    pain: [],
    function: [],
    conditions: [
      {
        intakeId: 'intakeIdMock1',
        botheredBodyPartDisplay: 'Right Knee',
        function: [
          {
            function: 5,
            createdAt: '2023-09-12T16:19:53.219+00:00',
          },
          {
            function: 6,
            createdAt: '2023-09-12T16:19:53.219+00:00',
          },
          {
            function: 7,
            createdAt: '2023-09-12T16:19:53.219+00:00',
          },
        ],
        pain: [
          {
            pain: 5,
            createdAt: '2023-09-12T16:19:53.219+00:00',
          },
          {
            pain: 4,
            createdAt: '2023-09-12T16:19:53.219+00:00',
          },
          {
            pain: 3,
            createdAt: '2023-09-12T16:19:53.219+00:00',
          },
        ],
      },
      {
        intakeId: 'intakeIdMock2',
        botheredBodyPartDisplay: 'Back-Neck',
        function: [
          {
            function: 5,
            createdAt: '2023-09-12T16:20:10.934+00:00',
          },
          {
            function: 4,
            createdAt: '2023-09-12T16:20:10.934+00:00',
          },
          {
            function: 3,
            createdAt: '2023-09-12T16:20:10.934+00:00',
          },
        ],
        pain: [
          {
            pain: 5,
            createdAt: '2023-09-12T16:20:10.934+00:00',
          },
          {
            pain: 6,
            createdAt: '2023-09-12T16:20:10.934+00:00',
          },
          {
            pain: 7,
            createdAt: '2023-09-12T16:20:10.934+00:00',
          },
        ],
      },
      {
        intakeId: 'intakeIdMock3',
        botheredBodyPartDisplay: 'Test',
        function: [
          {
            function: 5,
            createdAt: '2023-09-12T16:20:10.934+00:00',
          },
          {
            function: 4,
            createdAt: '2023-09-12T16:20:10.934+00:00',
          },
          {
            function: 3,
            createdAt: '2023-09-12T16:20:10.934+00:00',
          },
        ],
        pain: [
          {
            pain: 5,
            createdAt: '2023-09-12T16:20:10.934+00:00',
          },
          {
            pain: 6,
            createdAt: '2023-09-12T16:20:10.934+00:00',
          },
          {
            pain: 7,
            createdAt: '2023-09-12T16:20:10.934+00:00',
          },
        ],
      },
    ],
  }
