import { useLocation } from 'react-router-dom'

import { useQueryParams } from 'src/features/shared/presentation'

export type QueuePatientsQueryParams = {
  limit: string
  page: string
  q: string
  assignedToMe: string
  referralSource: string
  stage: string
  market: string
  linkedPlanId: string
  status: string
  lastToContact: string
  assignedTo: string
  attemptsQtyFrom: string
  attemptsQtyTo: string
  dateOfLastAttemptStart: string
  dateOfLastAttemptEnd: string
  referralCoordinationFlag: string
}

export type QueuePatientsQueryParam = keyof QueuePatientsQueryParams

const DEFAULT_ROWS_PER_PAGE = '25'
const DEFAULT_PAGE = '1'

const PARAMS: QueuePatientsQueryParam[] = ['q', 'page', 'limit', 'assignedToMe']
const FILTERS: QueuePatientsQueryParam[] = [
  'referralSource',
  'stage',
  'market',
  'linkedPlanId',
  'status',
  'lastToContact',
  'assignedTo',
  'attemptsQtyFrom',
  'attemptsQtyTo',
  'dateOfLastAttemptStart',
  'dateOfLastAttemptEnd',
]

export const useQueuePatientsQueryParams = () => {
  const location = useLocation()

  const {
    searchParams,
    getQueryParam,
    setQueryParam,
    updateQueryParams,
    setSearchParams,
  } = useQueryParams()

  const getInitialURLSearchParams = () => {
    return new URLSearchParams(location.search)
  }

  const getInitialURLSearchParamValue = (param: QueuePatientsQueryParam) => {
    return getInitialURLSearchParams().get(param)
  }

  const getLimit = () => {
    const queryParamValue =
      getQueryParam('limit') || getInitialURLSearchParamValue('limit')
    return queryParamValue || DEFAULT_ROWS_PER_PAGE
  }

  const getPage = () => {
    const queryParamValue =
      getQueryParam('page') || getInitialURLSearchParamValue('page')
    return queryParamValue || DEFAULT_PAGE
  }

  const getQ = () => {
    return getQueryParam('q') || getInitialURLSearchParamValue('q')
  }

  const getAssignedToMe = () => {
    return (
      getQueryParam('assignedToMe') ||
      getInitialURLSearchParamValue('assignedToMe')
    )
  }

  const getReferralSource = () => {
    const queryParamValue =
      getQueryParam('referralSource') ||
      getInitialURLSearchParamValue('referralSource')
    return queryParamValue ? queryParamValue.split(',') : []
  }

  const getStage = () => {
    const queryParamValue =
      getQueryParam('stage') || getInitialURLSearchParamValue('stage')
    return queryParamValue ? queryParamValue.split(',') : []
  }

  const getMarket = () => {
    const queryParamValue =
      getQueryParam('market') || getInitialURLSearchParamValue('market')
    return queryParamValue ? queryParamValue.split(',') : []
  }

  const getReferralCoordinationFlag = () => {
    const queryParamValue =
      getQueryParam('referralCoordinationFlag') ||
      getInitialURLSearchParamValue('referralCoordinationFlag')
    return queryParamValue || ''
  }

  const getLinkedPlanId = () => {
    const queryParamValue =
      getQueryParam('linkedPlanId') ||
      getInitialURLSearchParamValue('linkedPlanId')
    return queryParamValue ? queryParamValue.split(',') : []
  }

  const getStatus = () => {
    const queryParamValue =
      getQueryParam('status') || getInitialURLSearchParamValue('status')
    return queryParamValue ? queryParamValue.split(',') : []
  }

  const getLastToContact = () => {
    const queryParamValue =
      getQueryParam('lastToContact') ||
      getInitialURLSearchParamValue('lastToContact')
    return queryParamValue ? queryParamValue.split(',') : []
  }

  const getAssignedTo = () => {
    const queryParamValue =
      getQueryParam('assignedTo') || getInitialURLSearchParamValue('assignedTo')
    return queryParamValue ? queryParamValue.split(',') : []
  }

  const getAttemptsQtyFrom = () => {
    return (
      getQueryParam('attemptsQtyFrom') ||
      getInitialURLSearchParamValue('attemptsQtyFrom') ||
      ''
    )
  }

  const getAttemptsQtyTo = () => {
    return (
      getQueryParam('attemptsQtyTo') ||
      getInitialURLSearchParamValue('attemptsQtyTo') ||
      ''
    )
  }

  const getDateOfLastAttemptStart = () => {
    return (
      getQueryParam('dateOfLastAttemptStart') ||
      getInitialURLSearchParamValue('dateOfLastAttemptStart') ||
      ''
    )
  }

  const getDateOfLastAttemptEnd = () => {
    return (
      getQueryParam('dateOfLastAttemptEnd') ||
      getInitialURLSearchParamValue('dateOfLastAttemptEnd') ||
      ''
    )
  }

  const getAttemptsQtyRange = () => {
    const from = getAttemptsQtyFrom() ? Number(getAttemptsQtyFrom()) : null

    const to = getAttemptsQtyTo() ? Number(getAttemptsQtyTo()) : null
    return [from, to]
  }

  const getDateOfLastAttemptRange = () => {
    const start = getDateOfLastAttemptStart() || null

    const end = getDateOfLastAttemptEnd() || null

    return [start, end]
  }

  const hasAtLeastOneFilter = () => {
    return FILTERS.some((filter) => {
      return getQueryParam(filter) || getInitialURLSearchParamValue(filter)
    })
  }

  const setQ = (q: string) => {
    setSearchParams((prevParams) => {
      prevParams.set('q', q)
      prevParams.delete('page')
      prevParams.delete('limit')
      return prevParams
    })
  }

  const deleteQueuePatientsQueryParams = (
    params: QueuePatientsQueryParam[]
  ) => {
    setSearchParams((prevParams) => {
      params.forEach((param) => prevParams.delete(param))
      return prevParams
    })
  }

  const deleteAllQueuePatientsQueryParams = (
    type: 'params' | 'filters' | 'all' = 'all'
  ) => {
    let paramsToDelete: QueuePatientsQueryParam[] = []
    if (type === 'params') {
      paramsToDelete = PARAMS
    } else if (type === 'filters') {
      paramsToDelete = FILTERS
    } else {
      paramsToDelete = [...PARAMS, ...FILTERS]
    }
    deleteQueuePatientsQueryParams(paramsToDelete)
  }

  const updateQueuePatientsQueryParams = (
    params: Partial<QueuePatientsQueryParams>
  ) => {
    const queryParams = Object.entries(params).reduce((acc, [key, value]) => {
      acc[key as QueuePatientsQueryParam] = value
      return acc
    }, {} as Record<string, string>)

    updateQueryParams(queryParams)
  }

  return {
    deleteAllQueuePatientsQueryParams,
    deleteQueuePatientsQueryParams,
    getAssignedTo,
    getAssignedToMe,
    getAttemptsQtyFrom,
    getAttemptsQtyRange,
    getAttemptsQtyTo,
    getDateOfLastAttemptEnd,
    getDateOfLastAttemptRange,
    getDateOfLastAttemptStart,
    getLastToContact,
    getLimit,
    getPage,
    getLinkedPlanId,
    getStatus,
    getMarket,
    getQ,
    getReferralSource,
    getStage,
    getReferralCoordinationFlag,
    hasAtLeastOneFilter,
    searchParams,
    setQ,
    setQueryParam,
    setSearchParams,
    updateQueuePatientsQueryParams,
  }
}
