import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Stack,
  Tooltip,
} from '@mui/material'
import { ErrorMessage } from '@hookform/error-message'
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form'
import ReactQuill from 'react-quill'
import { FC } from 'react'

import { reactQuillDefaultModules } from 'src/config'
import { cleanReactQuillText } from 'src/features/shared/utils'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

type SDMFieldProps = {
  sdmFieldName?: FieldPath<FieldValues>
  sdmTextFieldName?: FieldPath<FieldValues>
  sdmRequired?: boolean
}

export const SDMField: FC<SDMFieldProps> = ({
  sdmRequired,
  sdmFieldName = 'sdm',
  sdmTextFieldName = 'sdmText',
}) => {
  const { control } = useFormContext()
  const listItems = [
    'Invite patient to participate',
    'Present treatment options',
    'Describe risks and benefits of options',
    'Elicit or gather patient goals, preferences, history, and biases',
    'Educate patient on their diagnosis, prognosis, options, questions, and/or concerns',
    'Ensure patient understanding of the discussion and action plan',
  ]

  const renderTooltipText = () => (
    <>
      <p>SDM occurs when;</p>
      <ul>
        {listItems.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </>
  )

  return (
    <Controller
      name={sdmFieldName}
      control={control}
      render={({ field }) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            width: '100%',
          }}
        >
          <FormControlLabel
            componentsProps={{
              typography: {
                variant: 'body1',
              },
            }}
            control={
              <Checkbox {...field} color={'primary'} checked={field.value} />
            }
            label={'SDM'}
            required={sdmRequired}
          />
          {!!field.value && (
            <Box width={'100%'}>
              <Controller
                name={sdmTextFieldName}
                control={control}
                render={({ field }) => (
                  <Stack direction={'row'} pb={6} position={'relative'}>
                    <ReactQuill
                      style={{ width: '100%' }}
                      theme="snow"
                      modules={reactQuillDefaultModules}
                      value={field.value}
                      onBlur={field.onBlur}
                      onChange={(value) => {
                        const cleanValue = cleanReactQuillText(value)
                        field.onChange(cleanValue)
                      }}
                      placeholder="Document SDM conversation specifics here. See the tool-tip for guidance, if needed."
                    />
                    <Tooltip
                      title={renderTooltipText()}
                      placement="bottom-start"
                    >
                      <InfoOutlinedIcon
                        sx={{
                          position: 'absolute',
                          right: -30,
                          top: 0,
                          color: 'gray',
                        }}
                      />
                    </Tooltip>
                  </Stack>
                )}
              />
              <ErrorMessage
                name={sdmTextFieldName}
                render={({ message }) => (
                  <FormHelperText error>{message}</FormHelperText>
                )}
              />
            </Box>
          )}
        </Box>
      )}
    />
  )
}
