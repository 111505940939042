import axios from 'axios'

import { PatientsUseCases } from 'src/features/patients/domain'
import {
  usePatientsService,
  useSearchPatientsService,
  useSearchDuplicatePatientsService,
} from 'src/features/patients/infrastructure'
import {
  mapToSearchPatientsServiceParams,
  mapToSearchPatientsReturns,
  mapToUpdatePatientServiceArgs,
  mapToUpdatePatientReturns,
  mapToUpdatePatientError,
  mapArgsToCreatePatientServicePayload,
  mapToCreatePatientReturns,
  mapToCreatePatientError,
  mapToGetPatientStatusDefinitionsReturns,
  mapToUpdatePatientStatusServiceArgs,
  mapToUpdatePatientStatusReturns,
  mapToUpdatePatientStatusError,
  mapToSearchPatientsError,
  mapToGetPatientStatusDefinitionsError,
  mapArgsToCreatePatientManuallyServicePayload,
  mapToCreatePatientManuallyError,
  mapToCreatePatientManuallyReturns,
  mapToSearchDuplicatePatientsServiceParams,
  mapToSearchDuplicatePatientsReturns,
  mapToSearchDuplicatePatientsError,
  mapToGetPatientGoalsReturns,
  mapToGetPatientGoalsError,
  mapToGetPatientPainAndFunctionV2Returns,
  mapToGetPatientPainAndFunctionV2Error,
  mapToGetPatientV2Error,
  mapToGetPatientV2Returns,
  mapToCreatePatientPainAndFunctionError,
  mapToCreatePatientPainAndFunctionReturns,
  mapToCreatePatientPainAndFunctionServiceArgs,
} from 'src/features/patients/adapters'
import { useAxiosErrorHandling } from 'src/features/shared/infrastructure'

export const usePatientsServiceAdapter = () => {
  const patientsService = usePatientsService()
  const searchPatientsService = useSearchPatientsService()
  const searchDuplicatePatientsService = useSearchDuplicatePatientsService()
  const { handleAxiosError } = useAxiosErrorHandling()

  const getPatientV2: PatientsUseCases['getPatientV2'] = async (patientId) => {
    try {
      const response = await patientsService.getPatientV2(patientId)

      return mapToGetPatientV2Returns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToGetPatientV2Error)
      }
      throw error
    }
  }

  const getPatientPainAndFunctionV2: PatientsUseCases['getPatientPainAndFunctionV2'] =
    async (patientId) => {
      try {
        const response = await patientsService.getPatientPainAndFunctionV2(
          patientId
        )

        return mapToGetPatientPainAndFunctionV2Returns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToGetPatientPainAndFunctionV2Error)
        }
        throw error
      }
    }

  const createPatientPainAndFunction: PatientsUseCases['createPatientPainAndFunction'] =
    async (args) => {
      try {
        const serviceArgs = mapToCreatePatientPainAndFunctionServiceArgs(args)

        const response = await patientsService.createPatientPainAndFunction(
          serviceArgs
        )

        return mapToCreatePatientPainAndFunctionReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToCreatePatientPainAndFunctionError)
        }
        throw error
      }
    }

  const updatePatient: PatientsUseCases['updatePatient'] = async (args) => {
    try {
      const serviceArgs = mapToUpdatePatientServiceArgs(args)

      const response = await patientsService.updatePatient(serviceArgs)

      return mapToUpdatePatientReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToUpdatePatientError)
      }
      throw error
    }
  }

  const updatePatientStatus: PatientsUseCases['updatePatientStatus'] = async (
    args
  ) => {
    try {
      const serviceArgs = mapToUpdatePatientStatusServiceArgs(args)

      const response = await patientsService.updatePatientStatus(serviceArgs)

      return mapToUpdatePatientStatusReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToUpdatePatientStatusError)
      }
      throw error
    }
  }

  const createPatient: PatientsUseCases['createPatient'] = async (args) => {
    try {
      const servicePayload = mapArgsToCreatePatientServicePayload(args)

      const response = await patientsService.createPatient(servicePayload)

      return mapToCreatePatientReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToCreatePatientError)
      }
      throw error
    }
  }

  const createPatientManually: PatientsUseCases['createPatientManually'] =
    async (args) => {
      try {
        const servicePayload =
          mapArgsToCreatePatientManuallyServicePayload(args)

        const response = await patientsService.createPatientManually(
          servicePayload
        )

        return mapToCreatePatientManuallyReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToCreatePatientManuallyError)
        }
        throw error
      }
    }

  const searchPatients: PatientsUseCases['searchPatients'] = async (params) => {
    try {
      const serviceParams = mapToSearchPatientsServiceParams(params)

      const response = await searchPatientsService.SearchPatients(serviceParams)

      return mapToSearchPatientsReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToSearchPatientsError)
      }
      throw error
    }
  }
  const searchDuplicatePatients: PatientsUseCases['searchDuplicatePatients'] =
    async (params) => {
      try {
        const searchDuplicatePatientsParams =
          mapToSearchDuplicatePatientsServiceParams(params)

        const response =
          await searchDuplicatePatientsService.duplicateUserSearch(
            searchDuplicatePatientsParams
          )

        return mapToSearchDuplicatePatientsReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToSearchDuplicatePatientsError)
        }
        throw error
      }
    }

  const getPatientStatusDefinitions: PatientsUseCases['getPatientStatusDefinitions'] =
    async () => {
      try {
        const response = await patientsService.getPatientStatusDefinitions()

        return mapToGetPatientStatusDefinitionsReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToGetPatientStatusDefinitionsError)
        }
        throw error
      }
    }

  const getPatientGoals: PatientsUseCases['getPatientGoals'] = async () => {
    try {
      const response = await patientsService.getPatientGoals()

      return mapToGetPatientGoalsReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToGetPatientGoalsError)
      }
      throw error
    }
  }

  return {
    getPatientV2,
    updatePatient,
    updatePatientStatus,
    searchPatients,
    searchDuplicatePatients,
    createPatient,
    createPatientManually,
    getPatientStatusDefinitions,
    getPatientGoals,
    getPatientPainAndFunctionV2,
    createPatientPainAndFunction,
  }
}
