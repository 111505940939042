import { FallScreenV2WhyNot } from 'src/features/msk/domain'

export type AgreeLevel =
  | 'Extremely well'
  | 'Very well'
  | 'Moderately well'
  | 'Slightly well'
  | 'Not well at all'

export const AGREE_LEVEL_OPTIONS: AgreeLevel[] = [
  'Extremely well',
  'Very well',
  'Moderately well',
  'Slightly well',
  'Not well at all',
]

export type YesNo = 'Yes' | 'No' | 'N/A'

export const YES_NO_OPTIONS: YesNo[] = ['N/A', 'Yes', 'No']

export type Scheduled =
  | YesNo
  | 'Pending Partner Confirmation'
  | 'Warm Transferred'
  | 'Provided List of Options'

export const SCHEDULED_TYPES: Scheduled[] = [
  'Yes',
  'Pending Partner Confirmation',
  'Warm Transferred',
  'Provided List of Options',
  'No',
  'N/A',
]

export type ScheduledWithGarnerProvider =
  | 'Yes'
  | 'No, Garner results were not helpful'
  | 'No, could not find preferred specialist within geography'
  | 'No, scheduling issues'
  | 'No, some other reason'

export const SCHEDULED_WITH_GARNER_PROVIDER_TYPES: ScheduledWithGarnerProvider[] =
  [
    'Yes',
    'No, Garner results were not helpful',
    'No, could not find preferred specialist within geography',
    'No, scheduling issues',
    'No, some other reason',
  ]

export type ReasonsForNotUsingGarner =
  | 'Patient has a preferred specialist'
  | 'PCP group has preferred specialist'
  | 'Technical issues'
  | 'Some other reason'

export const REASONS_FOR_NOT_USING_GARNER_TYPES: ReasonsForNotUsingGarner[] = [
  'Patient has a preferred specialist',
  'PCP group has preferred specialist',
  'Technical issues',
  'Some other reason',
]

export type Therapy = 'PT' | 'OT' | 'CHT' | 'Aquatic' | 'Other'

export const THERAPY: Therapy[] = ['PT', 'OT', 'CHT', 'Aquatic', 'Other']

export type ReferralRequestStatusTypes =
  | 'Open'
  | 'In Progress'
  | 'Requested'
  | 'Canceled'
  | 'Completed'
  | 'Cannot complete'
export const REFERRAL_REQUESTS_STATUS_TYPES: ReferralRequestStatusTypes[] = [
  'Open',
  'In Progress',
  'Requested',
  'Canceled',
  'Completed',
  'Cannot complete',
]

export type Limitation = 'Distance' | 'Transport' | 'Language' | 'Other'

export const LIMITATION: Limitation[] = [
  'Distance',
  'Transport',
  'Language',
  'Other',
]

export type SurgerySite = 'Unknown' | 'ASC' | 'HOPD' | 'IP'

export const SURGERY_SITES: SurgerySite[] = ['Unknown', 'ASC', 'HOPD', 'IP']

export type DischargePosition =
  | 'Unknown'
  | 'Home'
  | 'Home with HH'
  | 'SNF'
  | 'IRF'

export const DISCHARGE_POSITIONS: DischargePosition[] = [
  'Unknown',
  'Home',
  'Home with HH',
  'SNF',
  'IRF',
]

export type Improvement =
  | 'Significant Improvement'
  | 'Some Improvement'
  | 'No Improvement'
  | 'Increased Pain'

export const INJECTION_IMPROVEMENTS: Improvement[] = [
  'Significant Improvement',
  'Some Improvement',
  'No Improvement',
  'Increased Pain',
]

export type InjectionType =
  | 'In-Office Cortisone Injection'
  | 'Hyaluronic Acid Injection'
  | 'ESI - ASC Injection'
  | 'Other'

export const INJECTION_TYPES: InjectionType[] = [
  'In-Office Cortisone Injection',
  'Hyaluronic Acid Injection',
  'ESI - ASC Injection',
  'Other',
]

export type PTLocation =
  | 'Outpatient PT'
  | 'In-home PT'
  | 'Digital PT'
  | 'Acute Rehab'
  | 'SNF'

export const PT_LOCATIONS: PTLocation[] = [
  'Outpatient PT',
  'In-home PT',
  'Digital PT',
  'Acute Rehab',
  'SNF',
]
export type VisitOutcome =
  | 'None'
  | 'Continued Ortho Follow Up Care'
  | 'Finished Ortho Care'
  | 'Injection'
  | 'Pathway Change - PT'
  | 'Pathway Change - Surgery'

export const VISIT_OUTCOMES: VisitOutcome[] = [
  'Continued Ortho Follow Up Care',
  'Finished Ortho Care',
]

export type LevelScore =
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | 'N/A'

export const LEVEL_SCORES: LevelScore[] = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
]

export type SpokeWith =
  | 'N/A'
  | 'Patient'
  | 'Caregiver'
  | 'PCP'
  | 'Ortho'
  | 'PT'
  | 'Other'

export const SPOKE_WITH_OPTIONS: SpokeWith[] = [
  'N/A',
  'Patient',
  'Caregiver',
  'PCP',
  'Ortho',
  'PT',
  'Other',
]

export type ReferralType = 'Other' | 'Ortho' | 'PT' | 'Both'

export const REFERRAL_TYPES: ReferralType[] = ['Ortho', 'PT', 'Both', 'Other']

export type MissedReason =
  | 'Unknown'
  | 'No Show'
  | 'Cancelled'
  | 'Rescheduled'
  | 'Co-pay'
  | 'Other'

export const MISSED_REASONS: MissedReason[] = [
  'Unknown',
  'No Show',
  'Cancelled',
  'Rescheduled',
  'Co-pay',
  'Other',
]

export type MissedHomePTReason =
  | 'Pain'
  | 'I don’t know how'
  | 'Takes too much time'
  | 'Difficult to perform'
  | 'Just have not started'
  | 'New health concern'
  | 'New Plan of Care'

export const MISSED_HOME_PT_REASONS: MissedHomePTReason[] = [
  'Pain',
  'I don’t know how',
  'Takes too much time',
  'Difficult to perform',
  'Just have not started',
  'New health concern',
  'New Plan of Care',
]

export type ProcedureType =
  | 'Spine - Fusion'
  | 'Spine - Injection'
  | 'Spine - Laminectomy / Discectomy / Disc Replacement'
  | 'Knee - UKA'
  | 'Knee - TKA'
  | 'Knee - Arthroscopy'
  | 'Hip - Hip Arthroscopy'
  | 'Hip - THA'
  | 'Hand - Carpel Tunnel Release'
  | 'Hand - Trigger Finger Release'
  | 'Hand - Wrist Arthroscopy'
  | 'Foot - TAA'
  | 'Shoulder - Shoulder Arthroscopy'
  | 'Shoulder - TSA'
  | 'Shoulder - Rotator Cuff Repair'
  | 'Elbow - Elbow Arthroscopy'
  | 'Other'

export const PROCEDURE_TYPES: ProcedureType[] = [
  'Spine - Fusion',
  'Spine - Injection',
  'Spine - Laminectomy / Discectomy / Disc Replacement',
  'Knee - UKA',
  'Knee - TKA',
  'Knee - Arthroscopy',
  'Hip - Hip Arthroscopy',
  'Hip - THA',
  'Hand - Carpel Tunnel Release',
  'Hand - Trigger Finger Release',
  'Hand - Wrist Arthroscopy',
  'Foot - TAA',
  'Shoulder - Shoulder Arthroscopy',
  'Shoulder - TSA',
  'Shoulder - Rotator Cuff Repair',
  'Elbow - Elbow Arthroscopy',
  'Other',
]

export type ParticipatingInPTOption =
  | 'Yes'
  | 'No - Not Prescribed'
  | 'No - Needed Scheduling Support'
  | 'No - Opted Out'

export const PARTICIPATING_IN_PT_OPTIONS: ParticipatingInPTOption[] = [
  'Yes',
  'No - Not Prescribed',
  'No - Needed Scheduling Support',
  'No - Opted Out',
]

export type ReasonForRefusal =
  | 'Does not want care through TailorCare, still seeking care elsewhere'
  | 'Does not want to schedule anything at this time '
  | 'No longer seeking care'
  | 'Incorrect transfer from Resurgens'
  | 'Language or Comprehension Barrier'
  | 'Other (Document reason in note)'

export const REASON_FOR_REFUSAL: ReasonForRefusal[] = [
  'Does not want care through TailorCare, still seeking care elsewhere',
  'Does not want to schedule anything at this time ',
  'No longer seeking care',
  'Incorrect transfer from Resurgens',
  'Language or Comprehension Barrier',
  'Other (Document reason in note)',
]

export const FALL_SCREEN_V2_WHY_NOT: FallScreenV2WhyNot[] = [
  'Not interested in program',
  'Not interested in digital option',
  'Not enough time',
  'Already doing balance exercise program',
  'Unable to due to health reasons',
  'Other',
]
