import { FC, useEffect } from 'react'
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'

import {
  CreatePatientNoteForm,
  PatientNotesList,
  useCreateDraftNote,
  useDeleteDraftNote,
  useGetDraftNote,
  useUpdateDraftNote,
} from 'src/features/notes/presentation'
import {
  mapToCreateDraftNoteArgs,
  mapToPatientV2NoteTypes,
  mapToUpdateDraftNoteArgs,
} from 'src/features/notes/adapters'
import { Note, NoteType } from 'src/features/notes/domain'
import { PatientV2 } from 'src/features/patients/domain'
import { useNotesStore } from 'src/features/shared/infrastructure/store/hooks/use-notes.store'
import { FullSectionLoader } from 'src/features/shared/presentation/components'
import { useStatusDefinitions } from 'src/features/shared/presentation'
import { toast } from 'react-toastify'
import {
  NotesState,
  loadNotesStateFromLocalStorage,
} from 'src/features/shared/infrastructure'

type PatientNotesSectionProps = {
  patient: PatientV2
  patientNotes: Note[]
}

export const PatientNotesSection: FC<PatientNotesSectionProps> = ({
  patient,
  patientNotes,
}) => {
  const { draftNote, getDraftNoteIsLoading } = useGetDraftNote({
    patientId: patient.patientId,
  })
  const { deleteDraftNote } = useDeleteDraftNote({
    patientId: patient?.patientId,
  })
  const {
    selectedNoteType,
    setSelectedNoteType,
    currentNotesPatientId,
    setNotesState,
  } = useNotesStore()
  const { createDraftNote, createDraftNoteIsLoading } = useCreateDraftNote({
    onSuccess: () => {},
    onError: () => {
      toast.error('Something when wrong, please try again!')
    },
  })
  const { updateDraftNote, updateDraftNoteIsLoading } = useUpdateDraftNote({
    onSuccess: () => {},
    onError: () => {
      toast.error('Something when wrong, please try again!')
    },
  })

  const { getPatientStatusDefinitionsIsLoading } = useStatusDefinitions(patient)

  const createPatientNoteFormIsLoading =
    getPatientStatusDefinitionsIsLoading ||
    getDraftNoteIsLoading ||
    createDraftNoteIsLoading ||
    updateDraftNoteIsLoading

  const getNoteTypes = () => {
    return mapToPatientV2NoteTypes(patient)
  }

  const handleChangeNoteType = async (event: SelectChangeEvent<NoteType>) => {
    const noteTypeValue = event.target.value as NoteType
    if (noteTypeValue !== '') {
      if (draftNote) {
        updateDraftNote(
          mapToUpdateDraftNoteArgs(patient, draftNote.id, {
            type: noteTypeValue,
          }),
          {
            onSuccess: () => {
              setSelectedNoteType(noteTypeValue)
            },
          }
        )
      } else {
        createDraftNote(
          mapToCreateDraftNoteArgs(patient, { type: noteTypeValue }),
          {
            onSuccess: () => {
              setSelectedNoteType(noteTypeValue)
            },
          }
        )
      }
    } else {
      if (draftNote) {
        deleteDraftNote(
          { draftNoteId: draftNote.id },
          {
            onSuccess: () => {
              setSelectedNoteType('')
            },
          }
        )
      }
    }
  }

  useEffect(() => {
    if (patient.patientId && patient.patientId !== currentNotesPatientId) {
      const notesStorage = loadNotesStateFromLocalStorage(patient.patientId)
      let newNotesState: Partial<NotesState> = {
        currentNotesPatientId: patient.patientId,
      }
      if (notesStorage) {
        newNotesState = notesStorage
      } else if (draftNote) {
        newNotesState = {
          currentNotesPatientId: patient.patientId,
          selectedNoteType: draftNote.type,
          noteText: draftNote.note,
        }
      }
      setNotesState(newNotesState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient.patientId])

  return (
    <Box
      sx={{
        paddingTop: '16px',
        paddingBottom: '32px',
      }}
    >
      <Box
        sx={{
          padding: '0 16px 16px 16px',
          display: 'flex',
          width: 'full',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant={'h6'}>Notes</Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <FormControl size={'small'} sx={{ width: 227 }}>
            <InputLabel id={'notes-type-form-label'}>Type of Note</InputLabel>
            <Select
              labelId={'notes-type-form-label'}
              id={'note-type-form'}
              label={'Type of note'}
              value={selectedNoteType}
              onChange={handleChangeNoteType}
            >
              <MenuItem value={''}>None</MenuItem>
              {getNoteTypes().map((noteType) => (
                <MenuItem key={noteType} value={noteType}>
                  {noteType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box>
        {createPatientNoteFormIsLoading ? (
          <FullSectionLoader />
        ) : selectedNoteType && draftNote ? (
          <CreatePatientNoteForm patient={patient} draftNote={draftNote} />
        ) : null}
      </Box>
      <Box>
        <PatientNotesList patientNotes={patientNotes} />
      </Box>
    </Box>
  )
}
