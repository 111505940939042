import { ProcessMSKFormServiceResponse } from 'src/features/msk/infrastructure'

export const processMSKFormServiceResponseMock: ProcessMSKFormServiceResponse =
  {
    bmi: 92.35,
    pathway: 'Ortho',
    pathwayId: '002a',
    recommendedAction: 'Ortho:Surgeon, APP if unavailable w/in 5-7 days',
    track: 'Surgeon, APP if unavailable w/in 5-7 days',
    questionPath: {
      botheredBodyPart: '002',
      answers: ['yes', 'yes', 'no', 'yes'],
    },
  }
