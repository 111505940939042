import {
  Radio,
  RadioGroup,
  FormControlLabel,
  RadioGroupProps,
} from '@mui/material'
import { styled } from '@mui/material/styles'

import {
  FormFieldContainer,
  FormFieldContainerProps,
} from 'src/features/shared/presentation'

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  '& .MuiFormControlLabel-asterisk': {
    display: 'none',
  },
}))

type YesNoRadioGroupProps = RadioGroupProps & {
  label: string
  helperText?: string
  error?: boolean
  dataTestIdPreffix?: string
  required?: boolean
  formFieldContainerProps?: Omit<
    FormFieldContainerProps,
    'label' | 'helperText' | 'error'
  >
}

export function YesNoRadioGroup({
  label,
  id,
  error,
  helperText,
  required,
  formFieldContainerProps = {},
  dataTestIdPreffix = '',
  ...props
}: YesNoRadioGroupProps) {
  const dataTestIdYes = dataTestIdPreffix
    ? `${dataTestIdPreffix}_yes`
    : undefined
  const dataTestIdNo = dataTestIdPreffix ? `${dataTestIdPreffix}_no` : undefined
  return (
    <FormFieldContainer
      id={id}
      label={label}
      error={error}
      helperText={helperText}
      required={required}
      {...formFieldContainerProps}
    >
      <RadioGroup row id={id} {...props}>
        <StyledFormControlLabel
          data-testid={dataTestIdYes}
          value={'yes'}
          label={'Yes'}
          control={<Radio required={required} />}
        />
        <StyledFormControlLabel
          value={'no'}
          control={<Radio required={required} />}
          label={'No'}
          data-testid={dataTestIdNo}
        />
      </RadioGroup>
    </FormFieldContainer>
  )
}
