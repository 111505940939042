import {
  SMarket,
  GetMarketsServiceResponse,
  GetMarketsServiceErrorResponse,
} from 'src/features/providers/infrastructure'
import {
  Market,
  GetMarketsError,
  GetMarketsReturns,
} from 'src/features/providers/domain'

type MapToMarket = (market: SMarket) => Market

export const mapToMarket: MapToMarket = (market) => {
  return {
    name: market.name,
    id: market.id,
  }
}

// -----------
// GET MARKETS /
// -----------

type MapToGetMarketsReturns = (
  response: GetMarketsServiceResponse
) => GetMarketsReturns

export const mapToGetMarketsReturns: MapToGetMarketsReturns = (response) => {
  return response.markets.map(({ id, name }) => ({ id, name }))
}

export type MapToGetMarketsError = (
  error: GetMarketsServiceErrorResponse
) => GetMarketsError

export const mapToGetMarketsError: MapToGetMarketsError = (error) => ({
  ...error,
})

type MapToMultiSelectOptionMarket = (market: Market) => {
  label: string
  value: string
  key: string
}

export const mapMarketToMultiSelectOption: MapToMultiSelectOptionMarket = (
  market
) => {
  return {
    label: market.name,
    value: market.id,
    key: market.id,
  }
}
