import axios from 'axios'
import { useCallback } from 'react'

import { useAuth } from 'src/features/shared/infrastructure'
import * as settings from 'src/config/settings'

/**
 * Custom hook that is used to provide an instance of the Axios HTTP client pre-configured
 * with the necessary headers including the authorization token and the Compass API key from settings.
 *
 * The hook itself takes no parameters, it relies on the `useAuth` hook to fetch authentication tokens when required.
 *
 * `useCallback` is used to memorize the `getCompassApiClient` function until `getTokens` changes, which helps to prevent unnecessary re-renders.
 *
 * @returns {Object} The object containing the `getCompassApiClient` function which returns a pre-configured Axios instance.
 *
 * @example
 * const { getCompassApiClient } = useCompassApiClient()
 * const compassClient = await getCompassApiClient();
 * await compassClient.get<GetPathwaysServiceResponse>(
 *  settings.COMPASS_API_BASE_URL + '/pathways/definitions'
 * )
 * // or
 * await compassClient.post<ReassignPathwayServiceResponse>(
 *   settings.COMPASS_API_BASE_URL + `/pathways/reassign`,
 *   payload
 * )
 */

export const useCompassApiClient = () => {
  const { getTokens } = useAuth()

  const getCompassApiClient = useCallback(async () => {
    const { idToken } = await getTokens()
    return axios.create({
      headers: {
        'x-api-key': settings.COMPASS_API_KEY,
        Authorization: `Bearer ${idToken}`,
      },
    })
  }, [getTokens])

  return {
    getCompassApiClient,
  }
}
