import { GetProviderGroupsServiceResponse } from 'src/features/providers/infrastructure'

export const getProviderGroupsServiceResponseMockExample: GetProviderGroupsServiceResponse =
  {
    providers: [
      {
        id: '03439e16-d330-4838-b9ce-1b14227960cf',
        createdAt: '2023-08-25T15:32:12.840Z',
        group: 'Resurgens - Test',
        createdBy: null,
        isThreadwellPartner: false,
      },
      {
        id: 'c7f89a3c-d742-4111-9cf2-11377a50c20f',
        createdAt: '2023-08-28T14:00:36.057Z',
        group: 'Resurgens - Test 2',
        createdBy: null,
        isThreadwellPartner: false,
      },
      {
        id: '65b29ed1-2bc2-43e1-a369-18630c83ce97',
        createdAt: '2023-08-31T01:15:31.158Z',
        group: 'Test Group',
        createdBy: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
        isThreadwellPartner: false,
      },
      {
        id: 'b7359c17-371f-403c-959f-8301f36ef375',
        createdAt: '2023-08-31T12:37:13.163Z',
        group: 'My group',
        createdBy: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
        isThreadwellPartner: false,
      },
    ],
  }
