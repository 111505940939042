/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC } from 'react'
import ReactQuill, { ReactQuillProps, UnprivilegedEditor } from 'react-quill'
import { DeltaStatic, Sources } from 'quill'

import { useNotesStore } from 'src/features/shared/infrastructure'
import { reactQuillDefaultModules } from 'src/config'
import { cleanReactQuillText } from 'src/features/shared/utils'

export type NoteFieldProps = Omit<
  ReactQuillProps,
  'value' | 'modules' | 'theme' | 'onChange'
> & {
  onChange?: (value: string) => void
}

export const NoteField: FC<NoteFieldProps> = (props) => {
  const { onChange, ...restProps } = props
  const { noteText, setNoteText } = useNotesStore()

  const handleChange = (
    value: string,
    delta: DeltaStatic,
    source: Sources,
    editor: UnprivilegedEditor
  ) => {
    // 'api' source is removing empty spaces at the end of the text automatically
    if (source === 'user') {
      const cleanValue = cleanReactQuillText(value)
      setNoteText(cleanValue)
      if (onChange) {
        onChange(cleanValue)
      }
    }
  }
  return (
    <ReactQuill
      theme="snow"
      modules={reactQuillDefaultModules}
      value={noteText}
      onChange={handleChange}
      {...restProps}
    />
  )
}
