import { useQuery } from '@tanstack/react-query'

import {
  CompassUser,
  GetMyCompassUserError,
  GetMyCompassUserReturns,
} from 'src/features/shared/domain'
import { useCompassUsersUseCases } from 'src/features/shared/service-locator/compass-users.service-locator'

export const GET_MY_COMPASS_USER_QUERY_KEY = 'getMyCompassUser'

type UseGetMyCompassUserQueryProps = {
  idToken?: string
}

type UseGetMyCompassUserQuery = (props: UseGetMyCompassUserQueryProps) => {
  myCompassUser: CompassUser | null
  getMyCompassUserIsLoading: boolean
  getMyCompassUserIsError: boolean
}

export const useGetMyCompassUserQuery: UseGetMyCompassUserQuery = ({
  idToken,
}) => {
  const navigatorsUseCases = useCompassUsersUseCases()

  const { data, isLoading, isError } = useQuery<
    GetMyCompassUserReturns,
    GetMyCompassUserError
  >({
    queryKey: [GET_MY_COMPASS_USER_QUERY_KEY],
    queryFn: () => {
      return navigatorsUseCases.getMyCompassUser(idToken as string)
    },
    enabled: !!idToken,
    staleTime: 1000 * 60 * 60, // 1 hour
  })
  return {
    myCompassUser: data || null,
    getMyCompassUserIsLoading: isLoading,
    getMyCompassUserIsError: isError,
  }
}
