import { FC } from 'react'
import { Box } from '@mui/material'

import { mapToQueuePatientsTableRow } from 'src/features/queues/adapters'
import { QueuePatient } from 'src/features/queues/domain'
import { BackdropLoader } from 'src/features/shared/presentation'
import {
  QueuePatientsTable,
  QueuePatientsTableRow,
  useGetQueuePatientsQuery,
} from 'src/features/queues/presentation'

export const QueuePatientsPage: FC = () => {
  const { queuePatients, totalCount, getQueuePatientsIsLoading } =
    useGetQueuePatientsQuery()

  const getQueuePatientsRows = (
    patients: QueuePatient[]
  ): QueuePatientsTableRow[] => {
    if (!patients) {
      return []
    }

    return patients.map((patient) => mapToQueuePatientsTableRow(patient))
  }

  return (
    <Box sx={{ pt: '24px' }}>
      <QueuePatientsTable
        patients={getQueuePatientsRows(queuePatients)}
        totalCount={totalCount}
      />
      <BackdropLoader open={getQueuePatientsIsLoading} />
    </Box>
  )
}
