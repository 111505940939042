import { rest } from 'msw'

import { GetNavigatorsServiceResponse } from 'src/features/shared/infrastructure'

import * as settings from 'src/config/settings'
import { getNavigatorsServiceResponseMock } from 'src/__mocks__/responses/get-navigators-service-response'

const url = `${settings.COMPASS_API_BASE_URL}/users`

export const getNavigatorsServiceResponseHandler =
  rest.get<GetNavigatorsServiceResponse>(url, async (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(getNavigatorsServiceResponseMock))
  })
