import {
  UseMutateAsyncFunction,
  UseMutateFunction,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import {
  CreatePatientOutreachNoteReturns,
  CreatePatientOutreachNoteArgs,
  CreatePatientOutreachNoteError,
} from 'src/features/notes/domain'
import { GET_PATIENT_QUERY_KEY_V2 } from 'src/features/patients/presentation'
import {
  GET_PATIENT_DRAFT_NOTE,
  GET_PATIENT_NOTES_QUERY_KEY,
} from 'src/features/notes/presentation'
import { useNotesUseCases } from 'src/features/notes/notes.service-locator'

type UseCreatePatientOutreachNoteProps = Omit<
  UseMutationOptions<
    CreatePatientOutreachNoteReturns,
    CreatePatientOutreachNoteError,
    CreatePatientOutreachNoteArgs,
    unknown
  >,
  'mutationFn'
>

type UseCreatePatientOutreachNote = (
  props?: UseCreatePatientOutreachNoteProps
) => {
  createPatientOutreachNote: UseMutateFunction<
    CreatePatientOutreachNoteReturns,
    CreatePatientOutreachNoteError,
    CreatePatientOutreachNoteArgs,
    unknown
  >
  createPatientOutreachNoteAsync: UseMutateAsyncFunction<
    CreatePatientOutreachNoteReturns,
    CreatePatientOutreachNoteError,
    CreatePatientOutreachNoteArgs,
    unknown
  >
  createPatientOutreachNoteIsLoading: boolean
  createPatientOutreachNoteIsError: boolean
  createPatientOutreachNoteStatus: string
}

export const useCreatePatientOutreachNote: UseCreatePatientOutreachNote = (
  props = {}
) => {
  const queryClient = useQueryClient()
  const notesUseCases = useNotesUseCases()

  const { onSuccess } = props

  const { mutate, mutateAsync, isLoading, isError, status } = useMutation<
    CreatePatientOutreachNoteReturns,
    CreatePatientOutreachNoteError,
    CreatePatientOutreachNoteArgs,
    unknown
  >({
    mutationFn: (args: CreatePatientOutreachNoteArgs) => {
      return notesUseCases.createPatientOutreachNote(args)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [GET_PATIENT_QUERY_KEY_V2, variables.patientId],
      })
      queryClient.invalidateQueries({
        queryKey: [GET_PATIENT_NOTES_QUERY_KEY, variables.patientId],
      })
      queryClient.invalidateQueries({
        queryKey: [GET_PATIENT_DRAFT_NOTE, variables.patientId],
      })
      onSuccess?.(data, variables, context)
    },
  })
  return {
    createPatientOutreachNote: mutate,
    createPatientOutreachNoteAsync: mutateAsync,
    createPatientOutreachNoteIsLoading: isLoading,
    createPatientOutreachNoteIsError: isError,
    createPatientOutreachNoteStatus: status,
  }
}
