import {
  UseMutateFunction,
  UseMutationOptions,
  useMutation,
} from '@tanstack/react-query'

import { useUserSidebarTasks } from 'src/features/tasks/presentation'
import {
  CreateTaskArgs,
  CreateTaskError,
  Task,
} from 'src/features/tasks/domain'
import { useTasksUseCases } from 'src/features/tasks/tasks.service-locator'

type useCreateTaskProps = Omit<
  UseMutationOptions<Task, CreateTaskError, CreateTaskArgs, unknown>,
  'mutationFn'
>

type UseCreateTask = (props?: useCreateTaskProps) => {
  createTask: UseMutateFunction<Task, CreateTaskError, CreateTaskArgs, unknown>
  createTaskIsLoading: boolean
  createTaskIsError: boolean
  createTaskStatus: string
}

export const useCreateTask: UseCreateTask = (props = {}) => {
  const tasksUseCases = useTasksUseCases()
  const { refreshUserSidebarTasks } = useUserSidebarTasks()

  const { onSuccess } = props

  const { mutate, isLoading, isError, status } = useMutation<
    Task,
    CreateTaskError,
    CreateTaskArgs,
    unknown
  >({
    mutationFn: (args: CreateTaskArgs) => {
      return tasksUseCases.createTask(args)
    },
    onSuccess: (data, variables, context) => {
      refreshUserSidebarTasks({ upcoming: true, today: true })
      onSuccess?.(data, variables, context)
    },
  })
  return {
    createTask: mutate,
    createTaskIsLoading: isLoading,
    createTaskIsError: isError,
    createTaskStatus: status,
  }
}
