import {
  UseMutateFunction,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

import {
  ProcessTraumaMSKFormArgs,
  ProcessTraumaMSKFormError,
  ProcessTraumaMSKFormReturns,
} from 'src/features/msk/domain'
import { GET_PATIENT_QUERY_KEY_V2 } from 'src/features/patients/presentation'
import {
  GET_PATIENT_INTAKE_FLOWS_QUERY_KEY,
  GET_PATIENT_LAST_INTAKE_FLOW_QUERY_KEY,
} from 'src/features/msk/presentation'
import { useMSKUseCases } from 'src/features/msk/msk.service-locator'
import { removeMSKFormFromLocalStorage } from 'src/features/shared/infrastructure'

type useProcessTraumaMSKFormProps = Omit<
  UseMutationOptions<
    ProcessTraumaMSKFormReturns,
    ProcessTraumaMSKFormError,
    ProcessTraumaMSKFormArgs,
    unknown
  >,
  'mutationFn'
>

type UseProcessTraumaMSKForm = (props?: useProcessTraumaMSKFormProps) => {
  processTraumaMSKForm: UseMutateFunction<
    ProcessTraumaMSKFormReturns,
    ProcessTraumaMSKFormError,
    ProcessTraumaMSKFormArgs,
    unknown
  >
  processTraumaMSKFormIsLoading: boolean
  processTraumaMSKFormIsError: boolean
  processTraumaMSKFormStatus: string
}

export const useProcessTraumaMSKForm: UseProcessTraumaMSKForm = (
  props = {}
) => {
  const { onSuccess } = props

  const queryClient = useQueryClient()
  const mskUseCases = useMSKUseCases()

  const { mutate, isLoading, isError, status } = useMutation<
    ProcessTraumaMSKFormReturns,
    ProcessTraumaMSKFormError,
    ProcessTraumaMSKFormArgs,
    unknown
  >({
    mutationFn: (args: ProcessTraumaMSKFormArgs) => {
      return mskUseCases.processTraumaMSKForm(args)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [GET_PATIENT_QUERY_KEY_V2, variables.patientId],
      })
      queryClient.invalidateQueries({
        queryKey: [GET_PATIENT_LAST_INTAKE_FLOW_QUERY_KEY, variables.patientId],
      })
      queryClient.invalidateQueries({
        queryKey: [GET_PATIENT_INTAKE_FLOWS_QUERY_KEY, variables.patientId],
      })
      removeMSKFormFromLocalStorage(variables.patientId)
      onSuccess?.(data, variables, context)
    },
  })
  return {
    processTraumaMSKForm: mutate,
    processTraumaMSKFormIsLoading: isLoading,
    processTraumaMSKFormIsError: isError,
    processTraumaMSKFormStatus: status,
  }
}
