import { differenceInMinutes, isValid as isValidDate } from 'date-fns'

import {
  CreatePatientArgs,
  CreatePatientError,
  CreatePatientManuallyArgs,
  CreatePatientManuallyError,
  CreatePatientManuallyReturns,
  CreatePatientReturns,
  SearchDuplicatePatientParams,
  SearchDuplicatePatientsError,
  SearchDuplicatePatientsReturns,
  FunctionHistoryItem,
  GetPatientStatusDefinitionsError,
  GetPatientStatusDefinitionsReturns,
  PainHistoryItem,
  PatientAddress,
  PatientContact,
  PatientView,
  SearchPatientParams,
  SearchPatientsError,
  SearchPatientsReturns,
  UpdatePatientArgs,
  UpdatePatientError,
  UpdatePatientPayload,
  UpdatePatientReturns,
  UpdatePatientStatusArgs,
  UpdatePatientStatusError,
  UpdatePatientStatusReturns,
  SearchDuplicatePatientResult,
  GetPatientGoalsReturns,
  GetPatientGoalsError,
  GetPatientPainAndFunctionV2Error,
  GetPatientPainAndFunctionV2Returns,
  PainAndFunctionCondition,
  PatientGoal,
  PatientPathwayV2,
  PatientV2,
  GetPatientV2Error,
  CreatePatientPainAndFunctionPayload,
  CreatePatientPainAndFunctionArgs,
  CreatePatientPainAndFunctionError,
  CreatePatientPainAndFunctionReturns,
} from 'src/features/patients/domain'
import { EligiblePatient } from 'src/features/eligibility/domain'
import {
  CreatePatientManuallyServiceErrorResponse,
  CreatePatientManuallyServicePayload,
  CreatePatientManuallyServiceResponse,
  CreatePatientServiceErrorResponse,
  CreatePatientServicePayload,
  CreatePatientServiceResponse,
  GetPatientStatusDefinitionsServiceErrorResponse,
  GetPatientStatusDefinitionsServiceResponse,
  SFunctionHistoryItem,
  SPainHistoryItem,
  SPatientAddress,
  SPatientContact,
  SPatientView,
  SearchPatientsServiceErrorResponse,
  SearchPatientsServiceParams,
  SearchPatientsServiceResponse,
  UpdatePatientServiceArgs,
  UpdatePatientServiceErrorResponse,
  UpdatePatientServicePayload,
  UpdatePatientServiceResponse,
  UpdatePatientStatusServiceArgs,
  UpdatePatientStatusServiceErrorResponse,
  UpdatePatientStatusServiceResponse,
  SSearchDuplicatePatientsServiceParams,
  SSearchDuplicatePatientsServiceErrorResponse,
  SSearchDuplicatePatientsServiceResponse,
  GetPatientGoalsServiceResponse,
  GetPatientGoalsServiceErrorResponse,
  GetPatientPainAndFunctionV2ServiceErrorResponse,
  GetPatientPainAndFunctionV2ServiceResponse,
  SPainAndFunctionCondition,
  SPatientGoal,
  SPatientPathwayV2,
  SPatientV2,
  GetPatientV2ServiceErrorResponse,
  CreatePatientPainAndFunctionServicePayload,
  CreatePatientPainAndFunctionServiceArgs,
  CreatePatientPainAndFunctionServiceErrorResponse,
  CreatePatientPainAndFunctionServiceResponse,
} from 'src/features/patients/infrastructure'
import {
  PatientGlanceEditFormFields,
  PatientHeaderData,
  PatientHeaderEditFormFields,
} from 'src/features/patients/presentation'
import {
  formatDate,
  cleanPhoneFieldValue,
  getPatientMaskedPhones,
  getPatientCurrentContacts,
  getFormLanguage,
  phoneRegex,
} from 'src/features/shared/utils'
import {
  CreatePatientManuallyFormFields,
  DuplicatePatientWarningResultListItem,
} from 'src/features/queues/presentation'
import { MAIN_LANGUAGES } from 'src/features/shared/constants'
import { GetPlansReturns, Payor } from 'src/features/providers/domain'
import { IntakeFlow } from 'src/features/msk/domain'
import { MultiSelectOption } from 'src/features/shared/presentation'
import { PathwayInfo } from 'src/features/pathways/presentation'
import {
  ConditionPainAndFunctionValue,
  PainAndFunctionFieldState,
} from 'src/features/notes/presentation'

type MapToPatientView = (sPatient: SPatientView) => PatientView

export const mapToPatientView: MapToPatientView = (sPatientView) => {
  const viewedBy = {
    name: sPatientView.name,
    id: sPatientView.viewedBy,
    email: sPatientView.email,
  }

  return {
    ...sPatientView,
    viewedBy,
  }
}

type GetPatientViewersHistory = (
  sPatient: SPatientV2
) => PatientV2['patientViewersHistory']

const getPatientViewersHistory: GetPatientViewersHistory = (sPatient) => {
  if (sPatient.patientViewersHistory) {
    const patientViewersHistory =
      sPatient.patientViewersHistory.map(mapToPatientView)

    // order by viewedAt descending
    return patientViewersHistory.sort((a, b) => {
      return new Date(b.viewedAt).getTime() - new Date(a.viewedAt).getTime()
    })
  }
  return []
}

type GetPatientRecentViewersHistory = (
  patientViewersHistory: PatientV2['patientViewersHistory']
) => PatientV2['patientRecentViewersHistory']

const getPatientRecentViewersHistory: GetPatientRecentViewersHistory = (
  patientViewersHistory
) => {
  if (patientViewersHistory) {
    // filter by viewedAt within the last 30 minutes
    return patientViewersHistory.filter((view) => {
      const thirtyMinutesAgo = new Date()
      thirtyMinutesAgo.setMinutes(thirtyMinutesAgo.getMinutes() - 30)
      return differenceInMinutes(new Date(view.viewedAt), thirtyMinutesAgo) < 30
    })
  }
  return []
}

type GetPatientIsBusy = (
  patientViewersHistory: PatientV2['patientViewersHistory']
) => PatientV2['isBusy']

const getPatientIsBusy: GetPatientIsBusy = (patientViewersHistory) => {
  if (patientViewersHistory) {
    const thirtyMinutesAgo = new Date()
    thirtyMinutesAgo.setMinutes(thirtyMinutesAgo.getMinutes() - 30)
    for (const view of patientViewersHistory) {
      if (differenceInMinutes(new Date(view.viewedAt), thirtyMinutesAgo) < 30) {
        return true
      }
    }
  }
  return false
}

type MapToPatientPathwayV2 = (sPathwayV2: SPatientPathwayV2) => PatientPathwayV2

export const mapToPatientPathwayV2: MapToPatientPathwayV2 = (sPathwayV2) => {
  return {
    id: sPathwayV2.id,
    done: sPathwayV2.done,
    communicationChannel: sPathwayV2.type,
    track: sPathwayV2.track,
    pathway: sPathwayV2.pathway,
    intakeId: sPathwayV2.intakeId,
    isPaused: sPathwayV2.isPaused,
    lockDate: sPathwayV2.lockDate,
    firstAppointmentDate: sPathwayV2.firstAppointmentDate,
    createdBy: sPathwayV2.createdBy,
    navigator: sPathwayV2.navigator,
    pathwayId: sPathwayV2.pathwayId,
    patientId: sPathwayV2.patientId,
    assignedTo: sPathwayV2.assignedTo,
    isCancelled: sPathwayV2.isCancelled,
    pathwayCode: sPathwayV2.pathwayCode,
    pathwayType: sPathwayV2.pathwayType,
    creationDate: sPathwayV2.creationDate,
    lastUpdatedAt: sPathwayV2.lastUpdatedAt,
    eventsGenerated: sPathwayV2.eventsGenerated,
  }
}

type MapToPatientContact = (sContact: SPatientContact) => PatientContact

export const mapToPatientContact: MapToPatientContact = (sContact) => {
  return {
    id: sContact.id,
    patientId: sContact.patientId,
    phone: sContact.phone,
    email: sContact.email,
    consentForSms: sContact.consenttosms ?? false,
    createdAt: sContact.creationdate
      ? new Date(sContact.creationdate)
      : undefined,
  }
}

type MapToPatientV2 = (sPatientV2: SPatientV2) => PatientV2

export const mapToPatientV2: MapToPatientV2 = (sPatientV2) => {
  const patientViewersHistory = getPatientViewersHistory(sPatientV2)

  const patientRecentViewersHistory = getPatientRecentViewersHistory(
    patientViewersHistory
  )
  const doNotCall = sPatientV2.doNotCall || false

  const dualCoverage = sPatientV2.dualCoverage || false

  const eligible = sPatientV2.eligible || false

  const contacts = sPatientV2.contacts
    ? sPatientV2.contacts
        .map(mapToPatientContact)
        .sort(
          (a, b) =>
            (a.createdAt?.getTime() ?? 0) - (b.createdAt?.getTime() ?? 0)
        )
    : []

  const notes = sPatientV2.notes || []

  const pathways = sPatientV2.pathways
    ? sPatientV2.pathways.map(mapToPatientPathwayV2)
    : []

  let referralSource = sPatientV2.referralSource || null

  if (referralSource === 'UM - Mail') {
    referralSource = 'Inbound - Phone'
  }
  if (referralSource === 'Outbound Marketing') {
    referralSource = 'Inbound - Phone'
  }
  if (referralSource === 'UM - Email Marketing') {
    referralSource = 'Analytic Target'
  }

  return {
    patientId: sPatientV2.patientId,
    firstName: sPatientV2.firstName,
    middleName: sPatientV2.middleName,
    lastName: sPatientV2.lastName,
    dob: formatDate(sPatientV2.dob, true),
    mbi: sPatientV2.mbi,
    mrn: sPatientV2.mrn,
    doNotCall,
    referralSource,
    isThreadwell: sPatientV2.isThreadwell,
    eligible,
    eligibilityStartDate: sPatientV2.eligibilityStartDate ?? null,
    eligibilityEndDate: sPatientV2.eligibilityEndDate ?? null,
    eligibilityLastUpdatedAt: sPatientV2.eligibilityLastUpdatedAt ?? null,
    dualCoverage,
    language: sPatientV2.language,
    notes,
    contacts,
    addressLine1: sPatientV2.addressLine1,
    addressLine2: sPatientV2.addressLine2,
    city: sPatientV2.city,
    state: sPatientV2.state,
    zip: sPatientV2.zip,
    addressId: sPatientV2.addressId,
    pathways,
    stageAndStatus: sPatientV2.stageAndStatus,
    medicalProfile: sPatientV2.medicalProfile,
    patientViewersHistory: patientViewersHistory,
    patientRecentViewersHistory: patientRecentViewersHistory,
    isBusy: getPatientIsBusy(patientViewersHistory),
    healthAndEquityQuestionsRoundTwoRequired:
      sPatientV2.healthAndEquityQuestionsRoundTwoRequired,
  }
}

// --------------
// GET PATIENT V2
// -------------

type MapToGetPatientV2Returns = (response: SPatientV2) => PatientV2

export const mapToGetPatientV2Returns: MapToGetPatientV2Returns = (
  sPatientV2
) => mapToPatientV2(sPatientV2)

type MapToGetPatientV2Error = (
  error: GetPatientV2ServiceErrorResponse
) => GetPatientV2Error

export const mapToGetPatientV2Error: MapToGetPatientV2Error = (error) => {
  return {
    message: error.message,
  }
}
// -----------------
// SEARCH PATIENTS
// ----------------

type MapToSearchPatientsServiceParams = (
  params: SearchPatientParams
) => SearchPatientsServiceParams

export const mapToSearchPatientsServiceParams: MapToSearchPatientsServiceParams =
  (params) => ({ type: params.type, q: params.query })

type MapToSearchPatientsReturns = (
  response: SearchPatientsServiceResponse
) => SearchPatientsReturns

export const mapToSearchPatientsReturns: MapToSearchPatientsReturns = (
  response
) => ({ ...response })

type MapToSearchPatientsError = (
  error: SearchPatientsServiceErrorResponse
) => SearchPatientsError

export const mapToSearchPatientsError: MapToSearchPatientsError = (error) => ({
  ...error,
})

// ---------------------------
// SEARCH DUPLICATE PATIENTS
// --------------------------

type MapToSearchDuplicatePatientsServiceParams = (
  params: SearchDuplicatePatientParams
) => SSearchDuplicatePatientsServiceParams

export const mapToSearchDuplicatePatientsServiceParams: MapToSearchDuplicatePatientsServiceParams =
  (params) => ({
    phone: params.phone,
    firstName: params.firstName,
    lastName: params.lastName,
    dob: params.dob,
  })

type MapToSearchDuplicatePatientsReturns = (
  response: SSearchDuplicatePatientsServiceResponse
) => SearchDuplicatePatientsReturns

export const mapToSearchDuplicatePatientsReturns: MapToSearchDuplicatePatientsReturns =
  (response) => [...response]

type MapToSearchDuplicatePatientsError = (
  error: SSearchDuplicatePatientsServiceErrorResponse
) => SearchDuplicatePatientsError

export const mapToSearchDuplicatePatientsError: MapToSearchDuplicatePatientsError =
  (error) => ({
    ...error,
  })

// ----------------
// UPDATE PATIENT
// ---------------

type MapToSPatientContact = (contact: PatientContact) => SPatientContact

export const mapToSPatientContact: MapToSPatientContact = (contact) => ({
  id: contact.id,
  email: contact.email,
  phone: contact.phone,
  consenttosms: contact.consentForSms,
  patientId: contact.patientId,
})

type MapToSPatientAddress = (address: PatientAddress) => SPatientAddress

export const mapToSPatientAddress: MapToSPatientAddress = ({
  id,
  ...address
}) => {
  return {
    ...address,
    addressId: id,
  }
}

type MapToUpdatePatientServicePayload = (
  payload: UpdatePatientPayload
) => UpdatePatientServicePayload

export const mapToUpdatePatientServicePayload: MapToUpdatePatientServicePayload =
  (payload) => {
    return {
      ...payload,
      address: payload.address
        ? mapToSPatientAddress(payload.address)
        : undefined,
      contacts: payload.contacts
        ? payload.contacts.map(mapToSPatientContact)
        : undefined,
    }
  }

type MapToUpdatePatientServiceArgs = (
  args: UpdatePatientArgs
) => UpdatePatientServiceArgs

export const mapToUpdatePatientServiceArgs: MapToUpdatePatientServiceArgs = (
  args
) => {
  return {
    patientId: args.patientId,
    payload: mapToUpdatePatientServicePayload(args.payload),
  }
}

type MapToUpdatePatientReturns = (
  response: UpdatePatientServiceResponse
) => UpdatePatientReturns

export const mapToUpdatePatientReturns: MapToUpdatePatientReturns = (
  response
) => ({ ...response })

type MapToUpdatePatientError = (
  response: UpdatePatientServiceErrorResponse
) => UpdatePatientError

export const mapToUpdatePatientError: MapToUpdatePatientError = (error) => ({
  ...error,
})

// -----------------------
// UPDATE PATIENT STATUS
// ----------------------

type MapToUpdatePatientStatusServiceArgs = (
  args: UpdatePatientStatusArgs
) => UpdatePatientStatusServiceArgs

export const mapToUpdatePatientStatusServiceArgs: MapToUpdatePatientStatusServiceArgs =
  (args) => {
    return { ...args }
  }

type MapToUpdatePatientStatusReturns = (
  response: UpdatePatientStatusServiceResponse
) => UpdatePatientStatusReturns

export const mapToUpdatePatientStatusReturns: MapToUpdatePatientStatusReturns =
  (response) => ({ ...response })

type MapToUpdatePatientStatusError = (
  response: UpdatePatientStatusServiceErrorResponse
) => UpdatePatientStatusError

export const mapToUpdatePatientStatusError: MapToUpdatePatientStatusError = (
  error
) => ({ ...error })

type MapToUpdatePatientPayload = (
  patient: PatientV2,
  formValues: PatientHeaderEditFormFields
) => UpdatePatientPayload

export const mapToUpdatePatientPayload: MapToUpdatePatientPayload = (
  patient,
  formValues
) => {
  const updatePatientPayload: UpdatePatientPayload = {}

  if (formValues.firstName)
    updatePatientPayload.firstName = formValues.firstName

  if (formValues.middleName)
    updatePatientPayload.middleName = formValues.middleName

  if (formValues.lastName) updatePatientPayload.lastName = formValues.lastName

  if (formValues.dob) updatePatientPayload.dob = formatDate(formValues.dob)

  const address: UpdatePatientPayload['address'] = {}

  if (patient.addressId) address.id = patient.addressId

  if (formValues.addressLine1) address.line1 = formValues.addressLine1

  if (formValues.addressLine2) address.line2 = formValues.addressLine2

  if (formValues.city) address.city = formValues.city

  if (formValues.state) address.state = formValues.state

  if (formValues.zip) address.zip = formValues.zip

  if (formValues.mrn) updatePatientPayload.mrn = formValues.mrn

  if (Object.keys(address).length > 0) {
    updatePatientPayload.address = address
  }

  const currentContacts = getPatientCurrentContacts(patient)

  const contacts: UpdatePatientPayload['contacts'] = []

  if (formValues.phone1) {
    const phone1 = {
      patientId: patient.patientId,
      phone: cleanPhoneFieldValue(formValues.phone1),
      id: currentContacts.phone1?.id,
      consentForSms: currentContacts.phone1?.consentForSms ?? false,
    }
    contacts.push(phone1)
  }

  if (formValues.phone2 || currentContacts.phone2) {
    const phone2 = {
      patientId: patient.patientId,
      phone: formValues.phone2 ? cleanPhoneFieldValue(formValues.phone2) : '',
      id: currentContacts.phone2?.id,
      consentForSms: currentContacts.phone2?.consentForSms ?? false,
    }

    contacts.push(phone2)
  }

  if (formValues.email || currentContacts.email) {
    const email = {
      patientId: patient.patientId,
      email: formValues.email,
      id: currentContacts.email?.id,
      consentForSms: currentContacts.email?.consentForSms ?? false,
    }

    contacts.push(email)
  }

  if (contacts) updatePatientPayload.contacts = contacts

  updatePatientPayload.language = getFormLanguage(
    formValues.language,
    formValues.otherLanguage
  )
  if (patient.medicalProfile) {
    updatePatientPayload.medicalProfile = { id: patient.medicalProfile.id }
    updatePatientPayload.medicalProfile.primaryCarePhysician =
      formValues.primaryCarePhysician || ''
    updatePatientPayload.medicalProfile.addressLine1 =
      formValues.pcpAddressLine1 || ''
    updatePatientPayload.medicalProfile.addressLine2 =
      formValues.pcpAddressLine2 || ''
    updatePatientPayload.medicalProfile.city = formValues.pcpCity || ''
    updatePatientPayload.medicalProfile.state = formValues.pcpState || ''
    updatePatientPayload.medicalProfile.zip = formValues.pcpZip || ''
    updatePatientPayload.medicalProfile.fax = formValues.pcpFax
      ? cleanPhoneFieldValue(formValues.pcpFax)
      : ''
    updatePatientPayload.medicalProfile.groupName =
      formValues.pcpGroupName || ''
    updatePatientPayload.medicalProfile.phone = formValues.pcpPhone
      ? cleanPhoneFieldValue(formValues.pcpPhone)
      : ''
    updatePatientPayload.medicalProfile.lineOfBusiness =
      formValues.lineOfBusiness || null
  }

  return updatePatientPayload
}

type MapToUpdatePatientGlancePayload = (
  patient: PatientV2,
  formValues: PatientGlanceEditFormFields,
  payors: Payor[]
) => UpdatePatientPayload

export const mapToUpdatePatientGlancePayload: MapToUpdatePatientGlancePayload =
  (patient, formValues, payors) => {
    const updatePatientPayload: UpdatePatientPayload = {}

    if (formValues.referralSource) {
      updatePatientPayload.referralSource = formValues.referralSource
    }

    if (patient.medicalProfile) {
      if (
        (formValues.linkedPayorId ||
          formValues.linkedPlanId ||
          formValues.marketId ||
          formValues.memberId ||
          formValues.contractedWith) &&
        !patient.medicalProfile.id
      ) {
        throw new Error('medical profile updates must contain an id')
      }

      updatePatientPayload.medicalProfile = { id: patient.medicalProfile.id }
      if (formValues.linkedPayorId) {
        updatePatientPayload.medicalProfile.linkedPayorId =
          formValues.linkedPayorId
        const payor = payors.find(({ id }) => formValues.linkedPayorId === id)
        if (payor) {
          updatePatientPayload.medicalProfile.payor = payor.name
        }
      } else {
        updatePatientPayload.medicalProfile.linkedPayorId = null
        updatePatientPayload.medicalProfile.lineOfBusiness = null
      }

      updatePatientPayload.medicalProfile.linkedPlanId = formValues.linkedPlanId
        ? formValues.linkedPlanId
        : null

      updatePatientPayload.medicalProfile.marketId = formValues.marketId
        ? formValues.marketId
        : null

      updatePatientPayload.medicalProfile.memberId = formValues.memberId
        ? formValues.memberId
        : null

      updatePatientPayload.medicalProfile.contractedWith =
        formValues.contractedWith ? formValues.contractedWith : null
    }

    return updatePatientPayload
  }

// ---------------
// CREATE PATIENT
// ---------------

type MapToCreatePatientReturns = (
  response: CreatePatientServiceResponse
) => CreatePatientReturns

export const mapToCreatePatientReturns: MapToCreatePatientReturns = (
  response
) => {
  return {
    ...response,
  }
}

type MapToCreatePatientError = (
  error: CreatePatientServiceErrorResponse
) => CreatePatientError

export const mapToCreatePatientError: MapToCreatePatientError = (error) => {
  return {
    message: error.message,
  }
}

type MapArgsToCreatePatientServicePayload = (
  args: CreatePatientArgs
) => CreatePatientServicePayload

export const mapArgsToCreatePatientServicePayload: MapArgsToCreatePatientServicePayload =
  (args) => {
    return {
      ...args,
    }
  }

type MapPatientToCreatePatientArgs = (
  patient: EligiblePatient
) => CreatePatientArgs

export const mapPatientToCreatePatientArgs: MapPatientToCreatePatientArgs = (
  patient
) => {
  const contacts: CreatePatientArgs['contacts'] = []

  if (patient?.primaryPhone) {
    contacts.push({
      phone: patient.primaryPhone,
    })
  }

  if (patient?.secondaryPhone) {
    contacts.push({
      phone: patient.secondaryPhone,
    })
  }

  if (patient?.email) {
    contacts.push({
      email: patient.email,
    })
  }

  const address: CreatePatientArgs['address'] = {}

  if (patient?.addressLine1) {
    address.line1 = patient.addressLine1
  }

  if (patient?.addressLine2) {
    address.line2 = patient.addressLine2
  }

  if (patient?.city) {
    address.city = patient.city
  }

  if (patient?.state) {
    address.state = patient.state
  }

  if (patient?.zip) {
    address.zip = patient.zip
  }

  const createPatientArgs: CreatePatientArgs = {
    firstName: patient?.firstName || '',
    lastName: patient?.lastName || '',
    mbi: patient?.mbi || '',
    doNotCall: patient?.doNotCall || false,
    dob: patient?.dob || '',
  }

  if (patient?.middleName) {
    createPatientArgs.middleName = patient.middleName
  }

  if (Object.keys(address).length) {
    createPatientArgs.address = address
  }

  if (contacts.length) {
    createPatientArgs.contacts = contacts
  }

  return createPatientArgs
}

// --------------------------
// CREATE PATIENT MANUALLY
// ------------------------

type MapToCreatePatientManuallyReturns = (
  response: CreatePatientManuallyServiceResponse
) => CreatePatientManuallyReturns

export const mapToCreatePatientManuallyReturns: MapToCreatePatientManuallyReturns =
  (response) => {
    return {
      ...response,
    }
  }

type MapToCreatePatientManuallyError = (
  error: CreatePatientManuallyServiceErrorResponse
) => CreatePatientManuallyError

export const mapToCreatePatientManuallyError: MapToCreatePatientManuallyError =
  (error) => {
    return {
      message: error.message,
    }
  }

type MapArgsToCreatePatientManuallyServicePayload = (
  args: CreatePatientManuallyArgs
) => CreatePatientManuallyServicePayload

export const mapArgsToCreatePatientManuallyServicePayload: MapArgsToCreatePatientManuallyServicePayload =
  (args) => {
    return {
      ...args,
    }
  }

type MapToCreatePatientManuallyArgs = (
  formValues: CreatePatientManuallyFormFields,
  payors: Payor[]
) => CreatePatientManuallyArgs

export const mapToCreatePatientManuallyArgs: MapToCreatePatientManuallyArgs = (
  formValues,
  payors
) => {
  const contacts: CreatePatientManuallyArgs['contacts'] = [
    {
      phone: formValues.phone1,
    },
  ]

  if (formValues.email) {
    contacts[0].email = formValues.email
  }

  const args: CreatePatientManuallyArgs = {
    firstName: formValues.firstName,
    lastName: formValues.lastName,
    dob: formatDate(formValues.dob),
    contacts,
    address: {
      line1: formValues.addressLine1,
      city: formValues.city,
      state: formValues.state,
      zip: formValues.zip,
    },
    referralSource: formValues.referralSource,
    dualCoverage: formValues.dnsp,
  }

  if (formValues.middleName) {
    args.middleName = formValues.middleName
  }

  if (formValues.phone2) {
    contacts.push({ phone: formValues.phone2 })
  }

  if (formValues.addressLine2 && args.address) {
    args.address.line2 = formValues.addressLine2
  }

  args.language = getFormLanguage(formValues.language, formValues.otherLanguage)

  const medicalProfile: CreatePatientManuallyArgs['medicalProfile'] = {}

  if (formValues.linkedPlanId) {
    medicalProfile.linkedPlanId = formValues.linkedPlanId
  }

  if (formValues.linkedPayorId) {
    medicalProfile.linkedPayorId = formValues.linkedPayorId
    const payor = payors.find(({ id }) => formValues.linkedPayorId === id)
    if (payor) {
      medicalProfile.payor = payor.name
    }
  }

  if (formValues.memberId) {
    medicalProfile.memberId = formValues.memberId
  }

  if (formValues.memberId) {
    medicalProfile.memberId = formValues.memberId
  }

  if (formValues.primaryCarePhysician) {
    medicalProfile.primaryCarePhysician = formValues.primaryCarePhysician
  }

  if (formValues.pcpGroupName) {
    medicalProfile.groupName = formValues.pcpGroupName
  }

  if (formValues.pcpPhone) {
    medicalProfile.phone = formValues.pcpPhone
  }

  if (formValues.pcpFax) {
    medicalProfile.fax = formValues.pcpFax
  }

  if (formValues.pcpAddressLine1) {
    medicalProfile.addressLine1 = formValues.pcpAddressLine1
  }

  if (formValues.pcpAddressLine2) {
    medicalProfile.addressLine2 = formValues.pcpAddressLine2
  }

  if (formValues.pcpCity) {
    medicalProfile.city = formValues.pcpCity
  }

  if (formValues.pcpState) {
    medicalProfile.state = formValues.pcpState
  }

  if (formValues.pcpZip) {
    medicalProfile.zip = formValues.pcpZip
  }

  if (formValues.payor) {
    medicalProfile.payor = formValues.payor
  }

  if (formValues.lineOfBusiness) {
    medicalProfile.lineOfBusiness = formValues.lineOfBusiness
  }

  if (Object.keys(medicalProfile).length) {
    args.medicalProfile = medicalProfile
  }

  return args
}

// --------------------------------
// GET PATIENT STATUS DEFINITIONS
// -------------------------------

type MapToGetPatientStatusDefinitionsReturns = (
  response: GetPatientStatusDefinitionsServiceResponse
) => GetPatientStatusDefinitionsReturns

export const mapToGetPatientStatusDefinitionsReturns: MapToGetPatientStatusDefinitionsReturns =
  (response) => {
    return response
  }

type MapToGetPatientStatusDefinitionsError = (
  error: GetPatientStatusDefinitionsServiceErrorResponse
) => GetPatientStatusDefinitionsError

export const mapToGetPatientStatusDefinitionsError: MapToGetPatientStatusDefinitionsError =
  (error) => {
    return {
      message: error.message,
    }
  }

type MapStatusToMultiSelectOption = (status: string) => MultiSelectOption

export const mapStatusToMultiSelectOption: MapStatusToMultiSelectOption = (
  status
) => {
  return {
    label: status,
    value: status,
    key: status,
  }
}

// ---------------------------------------
// GET PATIENT PAIN AND FUNCTION HISTORY V2
// ---------------------------------------

type MapToPainHistoryItem = (
  sPainHistoryItem: SPainHistoryItem
) => PainHistoryItem

export const mapToPainHistoryItem: MapToPainHistoryItem = (
  sPainHistoryItem
) => {
  const createdAt = new Date(sPainHistoryItem.createdAt)
  return {
    painLevel: sPainHistoryItem.pain,
    createdAt: createdAt,
    date: formatDate(createdAt),
  }
}

type MapToFunctionHistoryItem = (
  sFunctionHistoryItem: SFunctionHistoryItem
) => FunctionHistoryItem

export const mapToFunctionHistoryItem: MapToFunctionHistoryItem = (
  sFunctionHistoryItem
) => {
  const createdAt = new Date(sFunctionHistoryItem.createdAt)
  return {
    functionLevel: sFunctionHistoryItem.function,
    createdAt: createdAt,
    date: formatDate(createdAt),
  }
}

type MapToPainAndFunctionCondition = (
  sPainAndFunctionCondition: SPainAndFunctionCondition
) => PainAndFunctionCondition

export const mapToPainAndFunctionCondition: MapToPainAndFunctionCondition = (
  sPainAndFunctionCondition
) => {
  const painHistory = sPainAndFunctionCondition.pain.map(mapToPainHistoryItem)

  const functionHistory = sPainAndFunctionCondition.function.map(
    mapToFunctionHistoryItem
  )

  // sort by createdAt ascending
  painHistory.sort((a, b) => {
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  })
  functionHistory.sort((a, b) => {
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  })

  return {
    intakeId: sPainAndFunctionCondition.intakeId,
    botheredBodyPartDisplay: sPainAndFunctionCondition.botheredBodyPartDisplay,
    hasPainHistory: painHistory.length > 0,
    hasFunctionHistory: functionHistory.length > 0,
    painHistory,
    functionHistory,
  }
}

type MapToGetPatientPainAndFunctionV2Returns = (
  response: GetPatientPainAndFunctionV2ServiceResponse
) => GetPatientPainAndFunctionV2Returns

export const mapToGetPatientPainAndFunctionV2Returns: MapToGetPatientPainAndFunctionV2Returns =
  (response) => {
    return {
      conditions: response.conditions
        ? response.conditions.map(mapToPainAndFunctionCondition)
        : [],
    }
  }

type MapToGetPatientPainAndFunctionV2Error = (
  error: GetPatientPainAndFunctionV2ServiceErrorResponse
) => GetPatientPainAndFunctionV2Error

export const mapToGetPatientPainAndFunctionV2Error: MapToGetPatientPainAndFunctionV2Error =
  (error) => {
    return {
      message: error.message,
    }
  }

// --------------------------------
// CREATE PATIENT PAIN AND FUNCTION
// -------------------------------

type MapToCreatePatientPainAndFunctionServicePayload = (
  payload: CreatePatientPainAndFunctionPayload
) => CreatePatientPainAndFunctionServicePayload

export const mapToCreatePatientPainAndFunctionServicePayload: MapToCreatePatientPainAndFunctionServicePayload =
  (payload) => {
    return {
      ...payload,
    }
  }

type MapToCreatePatientPainAndFunctionServiceArgs = (
  args: CreatePatientPainAndFunctionArgs
) => CreatePatientPainAndFunctionServiceArgs

export const mapToCreatePatientPainAndFunctionServiceArgs: MapToCreatePatientPainAndFunctionServiceArgs =
  (args) => {
    return {
      patientId: args.patientId,
      payload: mapToCreatePatientPainAndFunctionServicePayload(args.payload),
    }
  }

type MapToCreatePatientPainAndFunctionReturns = (
  response: CreatePatientPainAndFunctionServiceResponse
) => CreatePatientPainAndFunctionReturns

export const mapToCreatePatientPainAndFunctionReturns: MapToCreatePatientPainAndFunctionReturns =
  (response) => ({ ...response })

type MapToCreatePatientPainAndFunctionError = (
  response: CreatePatientPainAndFunctionServiceErrorResponse
) => CreatePatientPainAndFunctionError

export const mapToCreatePatientPainAndFunctionError: MapToCreatePatientPainAndFunctionError =
  (error) => ({
    ...error,
  })

// --------------------------------
// GET PATIENT GOALS
// -------------------------------

type MapToPatientGoal = (sGoal: SPatientGoal) => PatientGoal

export const mapToPatientGoal: MapToPatientGoal = (sGoal) => {
  return {
    id: sGoal.id,
    name: sGoal.goal,
    createdAt: new Date(sGoal.createdAt),
  }
}

type MapToGetPatientGoalsReturns = (
  response: GetPatientGoalsServiceResponse
) => GetPatientGoalsReturns

export const mapToGetPatientGoalsReturns: MapToGetPatientGoalsReturns = (
  response
) => {
  return response.patientGoals.map(mapToPatientGoal)
}

type MapToGetPatientGoalsError = (
  error: GetPatientGoalsServiceErrorResponse
) => GetPatientGoalsError

export const mapToGetPatientGoalsError: MapToGetPatientGoalsError = (error) => {
  return {
    message: error.message,
  }
}

// ----------------------
// PRESENTATION MAPPERS
// --------------------

type MapToPatientName = (patient: PatientV2) => string

export const mapToPatientName: MapToPatientName = (patient) => {
  const nameArray: string[] = []
  const validNameAttrs: (keyof PatientV2)[] = [
    'firstName',
    'middleName',
    'lastName',
  ]
  validNameAttrs.forEach((key: keyof PatientV2) => {
    if (patient[key] && typeof patient[key] === 'string')
      nameArray.push(patient[key] as string)
  })

  return nameArray.join(' ')
}

type MapToPatientHeaderData = (
  patient: PatientV2,
  plans: GetPlansReturns,
  lastIntakeFlow?: IntakeFlow | null
) => PatientHeaderData

export const mapToPatientHeaderData: MapToPatientHeaderData = (
  patient,
  plans,
  lastIntakeFlow
) => {
  const { phone1, phone2 } = getPatientMaskedPhones(patient.contacts)

  const getStageAndStatus = () => {
    let result = ''
    if (patient.stageAndStatus?.stage) {
      result += patient.stageAndStatus.stage
    }
    if (patient.stageAndStatus?.status) {
      result += ` | ${patient.stageAndStatus.status}`
    }
    return result
  }

  const notEnglishSpeaker = patient.language
    ? patient.language !== 'English'
    : false

  const hasPCPInfo =
    !!patient.medicalProfile?.primaryCarePhysician ||
    !!patient.medicalProfile?.groupName ||
    !!patient.medicalProfile?.phone ||
    !!patient.medicalProfile?.fax

  const hasPlanInfo =
    !!patient.medicalProfile?.linkedPlanId || !!patient.medicalProfile?.planId

  const plan = plans.find(
    (plan) => plan.id === patient.medicalProfile?.linkedPlanId
  )
  const planName = plan ? plan.name : ''

  let isTraumaPatient = false
  if (lastIntakeFlow && lastIntakeFlow.intakes.length) {
    const lastIntake = lastIntakeFlow.intakes[0]
    isTraumaPatient = lastIntake.isTraumaIntake
  }

  const planType = patient.medicalProfile?.planType
    ? patient.medicalProfile.planType
    : null
  return {
    ...patient,
    hasPhone: !!phone1 || !!phone2,
    referralSource: patient.referralSource || undefined,
    isThreadwell: patient.isThreadwell,
    id: patient.patientId,
    name: mapToPatientName(patient),
    stageAndStatus: getStageAndStatus(),
    planType,
    phone1,
    phone2,
    notEnglishSpeaker,
    hasPCPInfo,
    hasPlanInfo,
    planName,
    isTraumaPatient,
  }
}

type MapToPatientUpdateStatus = (
  patient: PatientV2,
  status: string
) => UpdatePatientStatusArgs

export const mapToPatientUpdateStatus: MapToPatientUpdateStatus = (
  patient,
  status
) => {
  const updatePatientStatusArgs: UpdatePatientStatusArgs = {
    patientId: patient.patientId,
    payload: {
      status,
    },
  }

  return updatePatientStatusArgs
}

type MapToPatientHeaderEditFormFields = (
  patient: PatientV2,
  plans: GetPlansReturns
) => PatientHeaderEditFormFields

export const mapToPatientHeaderEditFormFields: MapToPatientHeaderEditFormFields =
  (patient, plans) => {
    const {
      phone1: currentPhone1Object,
      phone2: currentPhone2Object,
      email: currentEmailObject,
    } = getPatientCurrentContacts(patient)

    const phone1 = currentPhone1Object?.phone || ''
    const phone2 = currentPhone2Object?.phone || ''
    const email = currentEmailObject?.email || ''

    let language = ''
    let otherLanguage = ''
    if (patient.language && MAIN_LANGUAGES.includes(patient.language)) {
      language = patient.language
    } else if (patient.language && !MAIN_LANGUAGES.includes(patient.language)) {
      language = 'Other'
      otherLanguage = patient.language
    }

    const plan = plans.find(
      (plan) => plan.id === patient.medicalProfile?.linkedPlanId
    )

    const planName = plan?.name

    return {
      phone1,
      phone2,
      email,
      language,
      otherLanguage,
      firstName: patient.firstName || '',
      middleName: patient.middleName || '',
      lastName: patient.lastName || '',
      dob: patient.dob ? new Date(patient.dob) : new Date(),
      addressLine1: patient.addressLine1 || '',
      addressLine2: patient.addressLine2 || '',
      city: patient.city || '',
      state: patient.state || '',
      zip: patient.zip || '',
      mrn: patient.mrn || '',
      referralSource: patient.referralSource || '',
      primaryCarePhysician: patient.medicalProfile?.primaryCarePhysician || '',
      pcpPhone: patient.medicalProfile?.phone || '',
      pcpFax: patient.medicalProfile?.fax || '',
      pcpGroupName: patient.medicalProfile?.groupName || '',
      pcpAddressLine1: patient.medicalProfile?.addressLine1 || '',
      pcpAddressLine2: patient.medicalProfile?.addressLine2 || '',
      pcpCity: patient.medicalProfile?.city || '',
      pcpState: patient.medicalProfile?.state || '',
      pcpZip: patient.medicalProfile?.zip || '',
      linkedPlanId: patient.medicalProfile?.linkedPlanId || '',
      linkedPayorId: patient.medicalProfile?.linkedPayorId || '',
      lineOfBusiness: patient.medicalProfile?.lineOfBusiness || '',
      planName: planName || '',
    }
  }

type MapToPatientGlanceEditFormFields = (
  patient: PatientV2
) => PatientGlanceEditFormFields

export const mapToPatientGlanceEditFormFields: MapToPatientGlanceEditFormFields =
  (patient) => {
    let language = ''
    let otherLanguage = ''
    if (patient.language && MAIN_LANGUAGES.includes(patient.language)) {
      language = patient.language
    } else if (patient.language && !MAIN_LANGUAGES.includes(patient.language)) {
      language = 'Other'
      otherLanguage = patient.language
    }

    return {
      language,
      otherLanguage,
      referralSource: patient.referralSource || '',
      linkedPayorId:
        patient.medicalProfile && patient.medicalProfile.linkedPayorId
          ? patient.medicalProfile.linkedPayorId
          : undefined,
      linkedPlanId:
        patient.medicalProfile && patient.medicalProfile.linkedPlanId
          ? patient.medicalProfile.linkedPlanId
          : undefined,
      memberId: patient.medicalProfile?.memberId || '',
      marketId: patient.medicalProfile?.marketId || '',
      contractedWith: patient.medicalProfile?.contractedWith || '',
    }
  }

type MapToSearchDuplicatePatientParams = (
  fields: Pick<
    CreatePatientManuallyFormFields,
    'firstName' | 'lastName' | 'dob' | 'phone1' | 'phone2'
  >
) => SearchDuplicatePatientParams

export const mapToSearchDuplicatePatientParams: MapToSearchDuplicatePatientParams =
  (fields) => {
    const params: SearchDuplicatePatientParams = {
      firstName: fields.firstName,
      lastName: fields.lastName,
    }
    if (isValidDate(fields.dob)) {
      params.dob = formatDate(fields.dob)
    }
    if (phoneRegex.test(fields.phone1)) {
      params.phone = fields.phone1
    } else if (fields.phone2 && phoneRegex.test(fields.phone2)) {
      params.phone = fields.phone2
    }
    return params
  }

type MapToDuplicatePatientWarningResultListItem = (
  patient: SearchDuplicatePatientResult
) => DuplicatePatientWarningResultListItem

export const mapToDuplicatePatientWarningResultListItem: MapToDuplicatePatientWarningResultListItem =
  (patient) => ({
    label: `${patient.firstName} ${patient.lastName}, ${patient.dob}`,
    id: patient.patientId,
  })

export const mapPatientGoalToMultiSelectOption = (
  patientGoal: PatientGoal
): MultiSelectOption => {
  return {
    label: patientGoal.name,
    value: patientGoal.name,
    key: patientGoal.id,
  }
}

type MapPatientPathwayV2ToPathwayInfo = (
  pathway: PatientPathwayV2 | null
) => PathwayInfo

export const mapPatientPathwayV2ToPathwayInfo: MapPatientPathwayV2ToPathwayInfo =
  (pathway) => {
    if (!pathway) return {}
    return {
      lockDate: pathway.lockDate,
      firstAppointmentDate: pathway.firstAppointmentDate,
      pathwayId: pathway.pathwayId,
      assignedPathwayId: pathway.id,
      assignedUserId: pathway.assignedTo,
      pathwayType: pathway.pathwayType,
      navigator: pathway.navigator,
      pathway: pathway.pathway,
      track: pathway.track,
      communicationChannel: pathway.communicationChannel,
      pathwayCode: pathway.pathwayCode,
      creationDate: pathway.creationDate,
      isPaused: pathway.isPaused,
    }
  }

type MapToCreatePatientPainAndFunctionArgs = (
  patient: PatientV2,
  painAndFunctionFieldState: PainAndFunctionFieldState
) => CreatePatientPainAndFunctionArgs[]

export const mapToCreatePatientPainAndFunctionArgs: MapToCreatePatientPainAndFunctionArgs =
  (patient, painAndFunctionFieldState) => {
    const filteredValuesEntries: [
      string,
      ConditionPainAndFunctionValue | null
    ][] = Object.entries(painAndFunctionFieldState.active)
      .filter((value) => {
        const [, active] = value
        return active
      })
      .map((value) => {
        const [intakeId, active] = value
        return [
          intakeId,
          active ? painAndFunctionFieldState.values[intakeId] : null,
        ]
      })

    const painAndFunctionArgs: CreatePatientPainAndFunctionArgs[] = []

    filteredValuesEntries.forEach((valueEntry) => {
      const [intakeId, value] = valueEntry
      if (
        value &&
        value.painLevel !== undefined &&
        value.functionLevel !== undefined
      ) {
        const painAndFunctionArg: CreatePatientPainAndFunctionArgs = {
          patientId: patient.patientId,
          payload: {
            intakeId,
            pain: value.painLevel,
            function: value.functionLevel,
          },
        }
        painAndFunctionArgs.push(painAndFunctionArg)
      }
    })

    return painAndFunctionArgs
  }
