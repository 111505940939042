import {
  Modal,
  Box,
  Alert,
  AlertTitle,
  Button,
  AlertColor,
} from '@mui/material'
import { FC, ReactNode } from 'react'

import { WithChildren } from 'src/features/shared/types'

type ErrorAlertProps = {
  open: boolean
  onConfirm: () => void
  onIgnore: () => void
  onClose?: () => void
  severity?: AlertColor
  dataTestIdPreffix?: string
  title?: string
  confirmText?: ReactNode
  ignoreText?: ReactNode
  confirmDisabled?: boolean
  ignoreDisabled?: boolean
}

export const ErrorAlert: FC<WithChildren<ErrorAlertProps>> = ({
  open,
  onConfirm,
  onIgnore,
  children,
  title,
  confirmText,
  ignoreText,
  dataTestIdPreffix = 'error_alert_',
  severity = 'error',
  onClose,
  confirmDisabled = false,
  ignoreDisabled = false,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 700,
          bgcolor: 'background.paper',
          borderRadius: '4px',
          boxShadow: 24,
        }}
      >
        <Alert severity={severity}>
          <AlertTitle>{title}</AlertTitle>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '32px',
            }}
          >
            {children}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                columnGap: '16px',
              }}
            >
              <Button
                data-testid={`${dataTestIdPreffix}confirm_no`}
                variant={'outlined'}
                color={'secondary'}
                onClick={onIgnore}
                disabled={ignoreDisabled}
              >
                {ignoreText}
              </Button>
              <Button
                data-testid={`${dataTestIdPreffix}confirm_yes`}
                variant={'contained'}
                color={'secondary'}
                onClick={onConfirm}
                disabled={confirmDisabled}
              >
                {confirmText}
              </Button>
            </Box>
          </Box>
        </Alert>
      </Box>
    </Modal>
  )
}
