import * as yup from 'yup'
import { Box, Grid, Stack } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { DevTool } from '@hookform/devtools'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useEffect } from 'react'

import {
  FormLayoutWithCancelSave,
  ControlledNoteField,
  SDMField,
  SDMPatientUnderstandsField,
  ControlledSelectField,
  SPOKE_WITH_OPTIONS,
  YES_NO_OPTIONS,
  PatientGoalsSelect,
  StatusField,
  useStatusDefinitions,
} from 'src/features/shared/presentation'
import { getReferralSources } from 'src/features/shared/utils'
import {
  filterNoneValueOptions,
  mapToCreatePatientIntakeNoteArgs,
  mapToUpdateDraftNoteArgs,
} from 'src/features/notes/adapters'
import {
  useUpdatePatient,
  useUpdatePatientStatus,
} from 'src/features/patients/presentation'
import { mapToPatientUpdateStatus } from 'src/features/patients/adapters'
import {
  NoteFormProps,
  useCreatePatientIntakeNote,
  IntakeQuestionsFormValues,
  noteFieldLabelDefaultSx,
  NOTE_FIELD_LABEL,
} from 'src/features/notes/presentation'
import {
  useNotesStore,
  useTasksStore,
} from 'src/features/shared/infrastructure'
import { mapToControlledSelectItem } from 'src/features/shared/adapters'
import { useUpdateDraftNote } from 'src/features/notes/presentation'

export type CreatePatientIntakeNoteFormFields = IntakeQuestionsFormValues & {
  note: string
  sdm: boolean
  communication: string
  status?: string
  referralSource?: string
  spokeWith?: string
}

const isSdmTextRequired = (sdm?: boolean) => !!sdm

const isSdmPatientUnderstandsConditionRequired = (sdm?: boolean) => !!sdm

const isSdmClinicalBelievesPatientUnderstandsConditionRequired = (
  sdm: boolean
) => !!sdm

const isReasonForRefusalRequired = (status?: string) => status === 'Refused'

type IntakeNoteFormProps = NoteFormProps

export const IntakeNoteForm = ({
  patient,
  setShowLoader,
  draftNote,
}: IntakeNoteFormProps) => {
  const { updatePatientAsync } = useUpdatePatient()
  const { createPatientIntakeNoteAsync } = useCreatePatientIntakeNote()
  const { updatePatientStatusAsync } = useUpdatePatientStatus()
  const { getAllStatusDefinitions } = useStatusDefinitions(patient, {
    noteType: 'Intake',
  })
  const { setSelectedNoteType } = useNotesStore()
  const { updateDraftNoteAsync } = useUpdateDraftNote()
  const { setAddTaskFormModalOpen } = useTasksStore()

  const createIntakeNoteFormSchema: yup.Schema<CreatePatientIntakeNoteFormFields> =
    yup.object().shape({
      note: yup.string().required('Required'),
      status: yup.string().required('Required'),
      reasonForRefusal: yup.string().when('status', {
        is: isReasonForRefusalRequired,
        then: (schema) => schema.required('Required'),
      }),
      sdm: yup.boolean().required('Required'),
      referralSource: yup.string().required('Required'),
      spokeWith: yup.string().required('Required'),
      communication: yup.string().required('Required'),
      sdmPatientUnderstandsCondition: yup.string().when('sdm', {
        is: isSdmPatientUnderstandsConditionRequired,
        then: (schema) => schema.required('Required'),
      }),
      sdmClinicalBelievesPatientUnderstandsCondition: yup.string().when('sdm', {
        is: isSdmClinicalBelievesPatientUnderstandsConditionRequired,
        then: (schema) => schema.required('Required'),
      }),
      sdmText: yup.string().when('sdm', {
        is: isSdmTextRequired,
        then: (schema) => schema.required('Required'),
      }),
      outsideSource: yup.boolean(),
      patientGoals: yup.string(),
    })

  const formMethods = useForm<CreatePatientIntakeNoteFormFields>({
    resolver: yupResolver(createIntakeNoteFormSchema),
    defaultValues: {
      note: '',
      status: '',
      reasonForRefusal: '',
      referralSource: patient.referralSource || '',
      spokeWith: '',
      sdm: false,
      communication: 'outbound',
      sdmPatientUnderstandsCondition: '',
      sdmClinicalBelievesPatientUnderstandsCondition: '',
      sdmText: '',
      outsideSource: false,
      patientGoals: '',
    },
    mode: 'onBlur',
  })

  const referralSourceWatcher = formMethods.watch('referralSource')
  const sdmWatcher = formMethods.watch('sdm')
  const statusWatcher = formMethods.watch('status')

  const submitHandler: SubmitHandler<
    CreatePatientIntakeNoteFormFields
  > = async (data) => {
    setShowLoader?.(true)

    const createPatientIntakeNoteArgs = mapToCreatePatientIntakeNoteArgs(
      patient,
      data,
      draftNote.id
    )
    try {
      await createPatientIntakeNoteAsync(createPatientIntakeNoteArgs)
      toast.success('Patient Note successfully created!')

      if (
        patient.stageAndStatus &&
        patient.stageAndStatus.stage &&
        data.status
      ) {
        const createPatientUpdateStatusArgs = mapToPatientUpdateStatus(
          patient,
          data.status
        )
        try {
          await updatePatientStatusAsync(createPatientUpdateStatusArgs)
          toast.success('Patient Status successfully updated!')
        } catch (error) {
          toast.error('Patient Status update failed!')
        }
      }

      if (data.referralSource) {
        try {
          await updatePatientAsync({
            patientId: patient.patientId,
            payload: {
              referralSource: data.referralSource,
            },
          })
          toast.success('Patient referral source successfully updated!')
        } catch (error) {
          toast.error('Patient referral source update failed!')
        }
      }
    } catch (error) {
      toast.error('Failed creating patient note!')
    }

    setSelectedNoteType('')
    setShowLoader?.(false)

    if (data.status === 'Pending') {
      setAddTaskFormModalOpen(
        true,
        `Pending ${patient.firstName} ${patient.lastName} Check-In`,
        true
      )
    }
  }

  const saveDraftHandler: SubmitHandler<
    CreatePatientIntakeNoteFormFields
  > = async (data) => {
    setShowLoader?.(true)

    const updatePatientCustomNoteArgs = mapToUpdateDraftNoteArgs(
      patient,
      draftNote.id,
      {
        ...data,
        type: 'Intake',
      }
    )

    try {
      await updateDraftNoteAsync(updatePatientCustomNoteArgs)
      toast.success('Patient Note Draft successfully Saved!')
    } catch (error) {
      toast.error('Failed Saving Patient Note Draft!', { autoClose: false })
    }

    setShowLoader?.(false)
  }

  const handleSaveDraft = (data: CreatePatientIntakeNoteFormFields) => {
    saveDraftHandler(data)
  }

  useEffect(() => {
    const subscription = formMethods.watch((value, { name, type }) => {
      if (type === 'change') {
        if (
          name === 'sdm' &&
          (value['sdmPatientUnderstandsCondition'] ||
            value['sdmClinicalBelievesPatientUnderstandsCondition'])
        ) {
          formMethods.reset((formValues) => ({
            ...formValues,
            sdmPatientUnderstandsCondition: '',
            sdmClinicalBelievesPatientUnderstandsCondition: '',
          }))
        }
      }
    })

    return () => subscription.unsubscribe()
  }, [formMethods])

  return (
    <Box>
      <FormLayoutWithCancelSave
        onSubmit={submitHandler}
        onSaveDraftClick={handleSaveDraft}
        formMethods={formMethods}
        draftNote={draftNote}
      >
        <Grid container rowSpacing={2} pb={'32px'}>
          <Grid item sm={12} pb={'16px'}>
            <ControlledNoteField />
          </Grid>
          <Grid container item columnSpacing={2}>
            <Grid item sm={2}>
              <StatusField
                statusRequired
                reasonForRefusalRequired={isReasonForRefusalRequired(
                  statusWatcher
                )}
                statuses={getAllStatusDefinitions(referralSourceWatcher)}
              />
            </Grid>
            <Grid item sm={2}>
              <ControlledSelectField
                name={'spokeWith'}
                label={NOTE_FIELD_LABEL.spokeWith}
                labelComponent={'inputLabel'}
                items={SPOKE_WITH_OPTIONS.map(mapToControlledSelectItem)}
                formControlProps={{
                  size: 'small',
                }}
                formControlSx={{
                  width: '100%',
                }}
                required
              />
            </Grid>
            <Grid item sm={4.5}>
              <ControlledSelectField
                name={'referralSource'}
                label={NOTE_FIELD_LABEL.referralSource}
                labelComponent={'inputLabel'}
                items={getReferralSources(patient.referralSource).map(
                  mapToControlledSelectItem
                )}
                formControlProps={{
                  size: 'small',
                }}
                formControlSx={{
                  width: '100%',
                }}
                required
              />
            </Grid>
          </Grid>
          <Grid container item sm={10}>
            <Box pt={1} width="100%">
              <SDMField />
            </Box>
            {sdmWatcher === true ? (
              <Stack
                direction={'column'}
                rowGap={'16px'}
                ml={'80px'}
                py={'16px'}
              >
                <PatientGoalsSelect />

                <ControlledSelectField
                  name={'sdmPatientUnderstandsCondition'}
                  label={NOTE_FIELD_LABEL.sdmPatientUnderstandsCondition}
                  items={filterNoneValueOptions(YES_NO_OPTIONS).map(
                    mapToControlledSelectItem
                  )}
                  required={isSdmPatientUnderstandsConditionRequired(
                    sdmWatcher
                  )}
                  selectSx={{ width: '88px' }}
                  formLabelSx={noteFieldLabelDefaultSx}
                />
                <SDMPatientUnderstandsField
                  required={isSdmClinicalBelievesPatientUnderstandsConditionRequired(
                    sdmWatcher
                  )}
                />
              </Stack>
            ) : null}
          </Grid>
        </Grid>
        <DevTool control={formMethods.control} placement={'top-left'} />
      </FormLayoutWithCancelSave>
    </Box>
  )
}
