import { useParams } from 'react-router-dom'

type UsePatientIdProps = {
  patientId?: string
  throwGetPatientIdError?: boolean
}

export const usePatientId = ({
  patientId,
  throwGetPatientIdError,
}: UsePatientIdProps = {}) => {
  const { patientId: paramPatientId } = useParams()

  const getPatientId = () => {
    if (patientId) {
      return patientId
    }
    if (paramPatientId) {
      return paramPatientId
    }
    if (throwGetPatientIdError) {
      throw new Error(
        'Patient ID is required as a parameter or in the url as path param.'
      )
    }
    return null
  }

  return getPatientId()
}
