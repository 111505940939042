import { FC, useEffect } from 'react'
import { Box, Paper, Stack } from '@mui/material'
import { Image } from '@aws-amplify/ui-react'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'

import {
  SignInButton,
  MainBoxBaseLayout,
  MainStackBaseLayout,
  Navbar,
  BackdropLoader,
} from 'src/features/shared/presentation'
import { useAuth } from 'src/features/shared/infrastructure'

import tcLogo from 'src/assets/images/tc-horizontal-logo.svg'

export const LoginPage: FC = () => {
  const { authIsLoading, setAuthIsLoading } = useAuth()
  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search)
    const code = searchParams.get('code')
    if (code) {
      setAuthIsLoading(true)
    }
  }, [setAuthIsLoading])
  return (
    <MainBoxBaseLayout>
      <MainStackBaseLayout>
        <Navbar />
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            pt: '48px',
          }}
        >
          <Paper sx={{ maxWidth: '350px', pt: '50px', pb: '50px', px: '50px' }}>
            <Stack direction={'column'} gap={'32px'}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Image alt="TailorCare logo" src={tcLogo} width={280} />
              </Box>
              <SignInButton
                provider={CognitoHostedUIIdentityProvider.Cognito}
              />
            </Stack>
          </Paper>
        </Box>
        <BackdropLoader open={authIsLoading} />
      </MainStackBaseLayout>
    </MainBoxBaseLayout>
  )
}
