import { FC, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Stack,
  Typography,
  Dialog,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  DialogContent,
  styled,
} from '@mui/material'
import { toast } from 'react-toastify'
import {
  FormProvider,
  SubmitHandler,
  useForm,
  Controller,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { format } from 'date-fns'
import * as yup from 'yup'

import { useTasksStore } from 'src/features/shared/infrastructure'
import {
  BackdropLoader,
  ControlledDatePicker,
  ModalDialogTitle,
  useGetNavigatorsQuery,
} from 'src/features/shared/presentation'
import { useGetPatientQueryV2 } from 'src/features/patients/presentation'
import {
  useUpdateTask,
  useCreateTask,
  TaskComments,
  useUpdateTasks,
} from 'src/features/tasks/presentation'
import { CreateTaskArgs, Task } from 'src/features/tasks/domain'
import { mapToPatientName } from 'src/features/patients/adapters'
import { Navigator } from 'src/features/shared/domain'

const sortNavigatorsByTaskCountAsc = (navigators: Navigator[]) => {
  return [...navigators].sort(
    (a, b) => (a.activeTaskCount || 0) - (b.activeTaskCount || 0)
  )
}

const TaskFormModalDialogContent = styled(DialogContent)(() => ({
  width: '496px',
}))

type CreateTaskFormFields = {
  title: string
  description?: string
  dueDate: Date
  assignedUserId: string
  urgent: boolean
}

const createTaskFormSchema: yup.Schema<CreateTaskFormFields> = yup
  .object()
  .shape({
    dueDate: yup.date().typeError('Invalid Date').required('Required'),
    assignedUserId: yup.string().required('Required'),
    title: yup.string().required('Required'),
    urgent: yup.boolean().required('Required'),
    description: yup.string(),
  })

type AddTaskFormProps = {
  defaultTitle?: string
  defaultUrgent?: boolean
}

export const AddTaskForm: FC<AddTaskFormProps> = ({
  defaultTitle = '',
  defaultUrgent = false,
}) => {
  const { setAddTaskFormModalOpen, setShowLoader } = useTasksStore()
  const { createTask } = useCreateTask()
  const { patient } = useGetPatientQueryV2()
  const { navigators } = useGetNavigatorsQuery()

  const formMethods = useForm<CreateTaskFormFields>({
    resolver: yupResolver(createTaskFormSchema),
    defaultValues: {
      dueDate: new Date(),
      assignedUserId: '',
      urgent: defaultUrgent,
      description: '',
      title: defaultTitle,
    },
    mode: 'onBlur',
  })
  const submitHandler: SubmitHandler<CreateTaskFormFields> = (data) => {
    const args: CreateTaskArgs = {
      patientId: patient ? patient.patientId : '',
      title: data.title,
      dueDate: format(data.dueDate, 'MM/dd/yy'),
      assignedUserId: data.assignedUserId,
      urgent: data.urgent,
    }

    if (data.description) {
      args.description = data.description
    }

    setShowLoader(true)
    createTask(args, {
      onSuccess: () => {
        toast.success(`Task ${data.title} successfully created!`)
        setAddTaskFormModalOpen(false)
      },
      onError: () => {
        toast.error(`Task creation failed!`)
      },
      onSettled: () => {
        setShowLoader(false)
      },
    })
  }

  const handleCancelClick = () => {
    setAddTaskFormModalOpen(false)
  }

  if (!patient) return null

  return (
    <>
      <ModalDialogTitle>Add Task</ModalDialogTitle>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(submitHandler)}>
          <TaskFormModalDialogContent>
            <Box>
              <Box sx={{ marginBottom: '56px' }}>
                <Stack direction={'row'} gap={'32px'}>
                  <Typography color={'rgba(0, 0, 0, 0.6)'} component={'span'}>
                    Patient
                  </Typography>
                  <Typography component={'span'}>
                    {mapToPatientName(patient)}
                  </Typography>
                </Stack>
              </Box>
              <Stack direction={'column'} gap={'56px'}>
                <TextField
                  label={'Title'}
                  variant={'outlined'}
                  required
                  error={!!formMethods.formState.errors['title']}
                  helperText={
                    formMethods.formState.errors['title']
                      ? (formMethods.formState.errors['title']
                          ?.message as string)
                      : undefined
                  }
                  {...formMethods.register('title')}
                />
                <TextField
                  label={'Description'}
                  variant={'outlined'}
                  error={!!formMethods.formState.errors['description']}
                  helperText={
                    formMethods.formState.errors['description']
                      ? (formMethods.formState.errors['description']
                          ?.message as string)
                      : 'optional'
                  }
                  {...formMethods.register('description')}
                />
                <Stack direction={'row'} gap={'32px'}>
                  <ControlledDatePicker
                    name="dueDate"
                    datePickerProps={{
                      label: 'To Do Date',
                      slotProps: {
                        textField: {
                          size: 'medium',
                          sx: {
                            flex: { xs: '0 0 160px' },
                          },
                          required: true,
                        },
                      },
                    }}
                  />

                  <Controller
                    name="assignedUserId"
                    control={formMethods.control}
                    render={({ field, fieldState }) => (
                      <FormControl fullWidth>
                        <InputLabel error={fieldState.invalid} required>
                          TC Team Member
                        </InputLabel>
                        <Select
                          label={'TC Team Member'}
                          value={field.value}
                          onBlur={field.onBlur}
                          onChange={field.onChange}
                          error={fieldState.invalid}
                          required
                        >
                          {navigators.map((navigator) => (
                            <MenuItem key={navigator.id} value={navigator.id}>
                              {navigator.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {fieldState.error ? (
                          <FormHelperText error>
                            {fieldState.error ? fieldState.error.message : ''}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    )}
                  />
                </Stack>
                <Controller
                  name={'urgent'}
                  control={formMethods.control}
                  render={({ field, fieldState }) => (
                    <Box>
                      <FormControlLabel
                        componentsProps={{
                          typography: {
                            variant: 'body1',
                          },
                        }}
                        control={
                          <Checkbox
                            {...field}
                            color={'primary'}
                            checked={field.value}
                          />
                        }
                        label={'Urgent'}
                      />
                      <FormHelperText
                        variant={'outlined'}
                        error={fieldState.invalid}
                      >
                        {fieldState.error?.message || 'optional'}
                      </FormHelperText>
                    </Box>
                  )}
                />
              </Stack>
            </Box>
          </TaskFormModalDialogContent>
          <DialogActions>
            <Button color="secondary" onClick={handleCancelClick}>
              CANCEL
            </Button>
            <Button type="submit" color="secondary">
              SAVE
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </>
  )
}

type EditMultipleTasksFormFields = {
  dueDate?: Date
  assignedUserId?: string
  urgent?: boolean
  done?: boolean
  comment?: string
}

const editMultipleTasksFormSchema: yup.Schema<EditMultipleTasksFormFields> = yup
  .object()
  .shape({
    dueDate: yup.date(),
    assignedUserId: yup.string(),
    urgent: yup.boolean(),
    done: yup.boolean(),
    comment: yup.string(),
  })

type EditMultipleTasksFormProps = {
  tasks: Task[]
  closeModal: () => void
}

export const EditMultipleTasksForm: FC<EditMultipleTasksFormProps> = ({
  tasks,
  closeModal,
}) => {
  const { updateTasks } = useUpdateTasks()
  const {
    setShowLoader,
    setSelectedTask,
    setEditTaskFormModalOpen,
    userTasksCalendarAgendaSelectedTasks,
    setUserTasksCalendarAgendaSelectedTasks,
    userTasksCalendarDaySelectedTasks,
    setUserTasksCalendarDaySelectedTasks,
  } = useTasksStore()

  const getSelectedTasksDueDateIfAllSame = () => {
    const selectedTasks = userTasksCalendarAgendaSelectedTasks.length
      ? userTasksCalendarAgendaSelectedTasks
      : userTasksCalendarDaySelectedTasks.length
      ? userTasksCalendarDaySelectedTasks
      : []

    if (selectedTasks.length === 0) return

    if (
      selectedTasks.every(({ dueDate }) => dueDate == selectedTasks[0].dueDate)
    )
      return new Date(selectedTasks[0].dueDate)

    return
  }

  const [dueDate, setDueDate] = useState<Date | undefined>(
    getSelectedTasksDueDateIfAllSame()
  )
  const { navigators } = useGetNavigatorsQuery({
    role: ['Clinical Navigator', 'PT Manager'],
    withTasks: true,
    taskDueDate: dueDate,
  })

  const formMethods = useForm<EditMultipleTasksFormFields>({
    resolver: yupResolver(editMultipleTasksFormSchema),
    defaultValues: {
      dueDate: dueDate,
      assignedUserId: '',
      urgent: false,
      done: false,
      comment: '',
    },
  })

  const submitHandler: SubmitHandler<EditMultipleTasksFormFields> = (data) => {
    setShowLoader(true)
    updateTasks(
      {
        tasks: tasks.map((task) => task.id),
        dueDate: data.dueDate ? format(data.dueDate, 'MM/dd/yy') : undefined,
        assignedUserId: data.assignedUserId ? data.assignedUserId : undefined,
        urgent: data.urgent ? true : undefined,
        status: data.done ? 'complete' : undefined,
        comment: data.comment ? data.comment : undefined,
      },
      {
        onSuccess: () => {
          toast.success(`Tasks successfully updated!`)
          setSelectedTask(null)
          setEditTaskFormModalOpen(false)
          setUserTasksCalendarAgendaSelectedTasks([])
          setUserTasksCalendarDaySelectedTasks([])
        },
        onError: () => {
          toast.error(`Tasks update failed!`)
        },
        onSettled: () => {
          setShowLoader(false)
          closeModal()
        },
      }
    )
  }

  const handleCancelClick = () => {
    closeModal()
  }

  if (!tasks.length) return null

  return (
    <>
      <ModalDialogTitle>
        Update Selected Tasks ({tasks.length})
      </ModalDialogTitle>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(submitHandler)}>
          <TaskFormModalDialogContent>
            <Box>
              <Stack direction={'column'} gap={'56px'}>
                <TextField
                  label={'Comment'}
                  variant={'outlined'}
                  placeholder={'Left a message'}
                  error={!!formMethods.formState.errors['comment']}
                  helperText={
                    formMethods.formState.errors['comment']
                      ? (formMethods.formState.errors['comment']
                          ?.message as string)
                      : undefined
                  }
                  {...formMethods.register('comment')}
                />
                <Stack direction={'row'} gap={'32px'}>
                  <ControlledDatePicker
                    name="dueDate"
                    onChange={setDueDate}
                    datePickerProps={{
                      label: 'To Do Date',
                      slotProps: {
                        textField: {
                          size: 'medium',
                          sx: {
                            flex: { xs: '0 0 160px' },
                          },
                        },
                      },
                    }}
                  />

                  <Controller
                    name="assignedUserId"
                    control={formMethods.control}
                    render={({ field, fieldState }) => (
                      <FormControl fullWidth>
                        <InputLabel error={fieldState.invalid}>
                          TC Team Member
                        </InputLabel>
                        <Select
                          label={'TC Team Member'}
                          value={field.value}
                          onBlur={field.onBlur}
                          onChange={field.onChange}
                          error={fieldState.invalid}
                        >
                          {sortNavigatorsByTaskCountAsc(navigators).map(
                            (navigator) => (
                              <MenuItem key={navigator.id} value={navigator.id}>
                                {navigator.name}
                                {dueDate
                                  ? ` - ${navigator.activeTaskCount || 0}`
                                  : null}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {fieldState.error ? (
                          <FormHelperText error>
                            {fieldState.error ? fieldState.error.message : ''}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    )}
                  />
                </Stack>
                <Stack>
                  <Controller
                    name={'urgent'}
                    control={formMethods.control}
                    render={({ field, fieldState }) => (
                      <Box>
                        <FormControlLabel
                          componentsProps={{
                            typography: {
                              variant: 'body1',
                            },
                          }}
                          control={
                            <Checkbox
                              {...field}
                              color={'primary'}
                              checked={field.value}
                            />
                          }
                          label={'Urgent'}
                        />
                        <FormHelperText
                          variant={'outlined'}
                          error={fieldState.invalid}
                        >
                          {fieldState.error?.message}
                        </FormHelperText>
                      </Box>
                    )}
                  />
                  <Controller
                    name={'done'}
                    control={formMethods.control}
                    render={({ field, fieldState }) => (
                      <Box>
                        <FormControlLabel
                          componentsProps={{
                            typography: {
                              variant: 'body1',
                            },
                          }}
                          control={
                            <Checkbox
                              {...field}
                              color={'primary'}
                              checked={field.value}
                            />
                          }
                          label={'Mark as Done'}
                        />
                        <FormHelperText
                          variant={'outlined'}
                          error={fieldState.invalid}
                        >
                          {fieldState.error?.message}
                        </FormHelperText>
                      </Box>
                    )}
                  />
                </Stack>
              </Stack>
            </Box>
          </TaskFormModalDialogContent>
          <DialogActions>
            <Button color="secondary" onClick={handleCancelClick}>
              CANCEL
            </Button>
            <Button
              type="submit"
              color="secondary"
              disabled={!formMethods.formState.isDirty}
            >
              SAVE
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </>
  )
}

type EditTaskFormFields = {
  dueDate: Date
  assignedUserId: string
  urgent: boolean
  done: boolean
  comment?: string
}

const editTaskFormSchema: yup.Schema<EditTaskFormFields> = yup.object().shape({
  dueDate: yup.date().required('Required'),
  assignedUserId: yup.string().required('Required'),
  urgent: yup.boolean().required('Required'),
  done: yup.boolean().required('Required'),
  comment: yup.string(),
})

export const EditTaskForm: FC = () => {
  const { updateTask } = useUpdateTask()
  const { selectedTask, setEditTaskFormModalOpen, setShowLoader } =
    useTasksStore()

  const [dueDate, setDueDate] = useState<Date | undefined>(
    new Date(selectedTask?.dueDate || '')
  )
  const { navigators } = useGetNavigatorsQuery({
    role: ['Clinical Navigator', 'PT Manager'],
    withTasks: true,
    taskDueDate: dueDate,
  })

  const formMethods = useForm<EditTaskFormFields>({
    resolver: yupResolver(editTaskFormSchema),
    defaultValues: {
      dueDate: dueDate,
      assignedUserId: selectedTask?.assignedUserId || '',
      urgent: selectedTask?.urgent || false,
      done: selectedTask?.status === 'complete',
      comment: '',
    },
  })

  const submitHandler: SubmitHandler<EditTaskFormFields> = (data) => {
    setShowLoader(true)
    updateTask(
      {
        taskId: selectedTask?.id || '',
        payload: {
          dueDate: format(data.dueDate, 'MM/dd/yy'),
          assignedUserId: data.assignedUserId,
          urgent: data.urgent,
          status: data.done ? 'complete' : undefined,
          comment: data.comment,
        },
      },
      {
        onSuccess: () => {
          toast.success(`Task ${selectedTask?.title} successfully updated!`)
        },
        onError: () => {
          toast.error(`Task ${selectedTask?.title} update failed!`)
        },
        onSettled: () => {
          setShowLoader(false)
          setEditTaskFormModalOpen(false)
        },
      }
    )
  }

  const handleCancelClick = () => {
    setEditTaskFormModalOpen(false)
  }

  if (!selectedTask) return null

  return (
    <>
      <ModalDialogTitle>Edit Task</ModalDialogTitle>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(submitHandler)}>
          <TaskFormModalDialogContent>
            <Box>
              <Box sx={{ marginBottom: '56px' }}>
                <Stack direction={'row'} gap={'32px'}>
                  <Typography color={'rgba(0, 0, 0, 0.6)'} component={'span'}>
                    Patient
                  </Typography>
                  <Typography component={'span'}>
                    {selectedTask.patientName}
                  </Typography>
                </Stack>
              </Box>
              <Stack direction={'column'} gap={'16px'}>
                <TextField
                  value={selectedTask.title}
                  inputProps={{ readOnly: true }}
                  label={'Title'}
                  variant={'outlined'}
                  disabled
                />
                <TextField
                  value={selectedTask.description}
                  inputProps={{ readOnly: true }}
                  label={'Description'}
                  variant={'outlined'}
                  disabled
                />
                <TaskComments task={selectedTask} />
              </Stack>
              <Stack direction={'column'} gap={'56px'}>
                <TextField
                  label={'Comment'}
                  variant={'outlined'}
                  placeholder={'Left a message'}
                  error={!!formMethods.formState.errors['comment']}
                  helperText={
                    formMethods.formState.errors['comment']
                      ? (formMethods.formState.errors['comment']
                          ?.message as string)
                      : undefined
                  }
                  {...formMethods.register('comment')}
                />
                <Stack direction={'row'} gap={'32px'}>
                  <ControlledDatePicker
                    name="dueDate"
                    onChange={setDueDate}
                    datePickerProps={{
                      label: 'To Do Date',
                      slotProps: {
                        textField: {
                          size: 'medium',
                          required: true,
                          sx: {
                            flex: { xs: '0 0 160px' },
                          },
                        },
                      },
                    }}
                  />

                  <Controller
                    name="assignedUserId"
                    control={formMethods.control}
                    render={({ field, fieldState }) => (
                      <FormControl fullWidth>
                        <InputLabel error={fieldState.invalid} required>
                          TC Team Member
                        </InputLabel>
                        <Select
                          label={'TC Team Member'}
                          value={field.value}
                          onBlur={field.onBlur}
                          onChange={field.onChange}
                          error={fieldState.invalid}
                          required
                        >
                          {sortNavigatorsByTaskCountAsc(navigators).map(
                            (navigator) => (
                              <MenuItem key={navigator.id} value={navigator.id}>
                                {navigator.name}
                                {dueDate
                                  ? ` - ${navigator.activeTaskCount || 0}`
                                  : null}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {fieldState.error ? (
                          <FormHelperText error>
                            {fieldState.error ? fieldState.error.message : ''}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    )}
                  />
                </Stack>
                <Stack>
                  <Controller
                    name={'urgent'}
                    control={formMethods.control}
                    render={({ field, fieldState }) => (
                      <Box>
                        <FormControlLabel
                          componentsProps={{
                            typography: {
                              variant: 'body1',
                            },
                          }}
                          control={
                            <Checkbox
                              {...field}
                              color={'primary'}
                              checked={field.value}
                            />
                          }
                          label={'Urgent'}
                        />
                        <FormHelperText
                          variant={'outlined'}
                          error={fieldState.invalid}
                        >
                          {fieldState.error?.message}
                        </FormHelperText>
                      </Box>
                    )}
                  />
                  <Controller
                    name={'done'}
                    control={formMethods.control}
                    render={({ field, fieldState }) => (
                      <Box>
                        <FormControlLabel
                          componentsProps={{
                            typography: {
                              variant: 'body1',
                            },
                          }}
                          control={
                            <Checkbox
                              {...field}
                              color={'primary'}
                              checked={field.value}
                            />
                          }
                          label={
                            selectedTask?.status === 'complete'
                              ? 'Done'
                              : 'Mark as Done'
                          }
                        />
                        <FormHelperText
                          variant={'outlined'}
                          error={fieldState.invalid}
                        >
                          {fieldState.error?.message}
                        </FormHelperText>
                      </Box>
                    )}
                  />
                </Stack>
              </Stack>
            </Box>
          </TaskFormModalDialogContent>
          <DialogActions>
            <Button color="secondary" onClick={handleCancelClick}>
              CANCEL
            </Button>
            <Button type="submit" color="secondary">
              SAVE
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </>
  )
}

export const TaskAddFormModal: FC = () => {
  const {
    addTaskFormModalOpen: { open, defaultTitle, defaultUrgent },
    setAddTaskFormModalOpen,
    showLoader,
  } = useTasksStore()
  const handleModalFormClose = () => {
    setAddTaskFormModalOpen(false)
  }

  return (
    <Dialog onClose={handleModalFormClose} open={open} sx={{ zIndex: 2 }}>
      <AddTaskForm defaultTitle={defaultTitle} defaultUrgent={defaultUrgent} />
      <BackdropLoader open={showLoader} />
    </Dialog>
  )
}

export const TaskEditFormModal: FC = () => {
  const { editTaskFormModalOpen, setEditTaskFormModalOpen, showLoader } =
    useTasksStore()
  const handleModalFormClose = () => {
    setEditTaskFormModalOpen(false)
  }

  return (
    <Dialog
      onClose={handleModalFormClose}
      open={editTaskFormModalOpen}
      sx={{ zIndex: 2 }}
    >
      <EditTaskForm />
      <BackdropLoader open={showLoader} />
    </Dialog>
  )
}

export const AgendaMultipleTasksEditFormModal: FC = () => {
  const {
    userTasksCalendarAgendaSelectedTasks,
    editAgendaMultipleTasksFormModalOpen,
    setEditAgendaMultipleTasksFormModalOpen,
    showLoader,
  } = useTasksStore()
  const handleModalFormClose = () => {
    setEditAgendaMultipleTasksFormModalOpen(false)
  }

  return (
    <Dialog
      onClose={handleModalFormClose}
      open={editAgendaMultipleTasksFormModalOpen}
      sx={{ zIndex: 2 }}
    >
      <EditMultipleTasksForm
        tasks={userTasksCalendarAgendaSelectedTasks}
        closeModal={handleModalFormClose}
      />
      <BackdropLoader open={showLoader} />
    </Dialog>
  )
}

export const DayMultipleTasksEditFormModal: FC = () => {
  const {
    userTasksCalendarDaySelectedTasks,
    editDayMultipleTasksFormModalOpen,
    setEditDayMultipleTasksFormModalOpen,
    showLoader,
  } = useTasksStore()
  const handleModalFormClose = () => {
    setEditDayMultipleTasksFormModalOpen(false)
  }

  return (
    <Dialog
      onClose={handleModalFormClose}
      open={editDayMultipleTasksFormModalOpen}
      sx={{ zIndex: 2 }}
    >
      <EditMultipleTasksForm
        tasks={userTasksCalendarDaySelectedTasks}
        closeModal={handleModalFormClose}
      />
      <BackdropLoader open={showLoader} />
    </Dialog>
  )
}
