import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { View } from 'react-big-calendar'

import { Task } from 'src/features/tasks/domain'
import { RootState } from 'src/features/shared/infrastructure'
import {
  formatDate,
  getFirstAndLastDayOfMonth,
} from 'src/features/shared/utils'

type AddTaskModalOpenState = {
  open: boolean
  defaultTitle?: string
  defaultUrgent?: boolean
}

export type TasksState = {
  sidebarOpen: boolean
  taskViewModalOpen: boolean
  selectedSidebarTab: 'upcoming' | 'today' | 'done'
  addTaskFormModalOpen: AddTaskModalOpenState
  editTaskFormModalOpen: boolean
  editAgendaMultipleTasksFormModalOpen: boolean
  editDayMultipleTasksFormModalOpen: boolean
  selectedTask: Task | null
  showLoader: boolean
  openUserTasksCalendarModal: boolean
  userTasksCalendarDateRange: string[]
  patientTasksCalendarView: View
  userTasksCalendarView: View
  userTasksCalendarAgendaSelectedTasks: Task[]
  userTasksCalendarDaySelectedTasks: Task[]
}

const initialState: TasksState = {
  sidebarOpen: false,
  taskViewModalOpen: false,
  selectedSidebarTab: 'today',
  addTaskFormModalOpen: { open: false, defaultTitle: '', defaultUrgent: false },
  editTaskFormModalOpen: false,
  editAgendaMultipleTasksFormModalOpen: false,
  editDayMultipleTasksFormModalOpen: false,
  selectedTask: null,
  showLoader: false,
  openUserTasksCalendarModal: false,
  userTasksCalendarDateRange: getFirstAndLastDayOfMonth().map((date) =>
    formatDate(date)
  ),
  patientTasksCalendarView: 'month',
  userTasksCalendarView: 'month',
  userTasksCalendarAgendaSelectedTasks: [],
  userTasksCalendarDaySelectedTasks: [],
}

const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    setSidebarOpenAction(state, action: PayloadAction<boolean>) {
      state.sidebarOpen = action.payload
    },
    setSelectedSidebarTabAction(
      state,
      action: PayloadAction<TasksState['selectedSidebarTab']>
    ) {
      state.selectedSidebarTab = action.payload
    },
    setAddTaskFormModalOpenAction(
      state,
      action: PayloadAction<AddTaskModalOpenState>
    ) {
      state.addTaskFormModalOpen = action.payload
    },
    setEditTaskFormModalOpenAction(state, action: PayloadAction<boolean>) {
      state.editTaskFormModalOpen = action.payload
      if (action.payload === false) state.selectedTask = null
    },
    setEditAgendaMultipleTasksFormModalOpenAction(
      state,
      action: PayloadAction<boolean>
    ) {
      state.editAgendaMultipleTasksFormModalOpen = action.payload
    },
    setEditDayMultipleTasksFormModalOpenAction(
      state,
      action: PayloadAction<boolean>
    ) {
      state.editDayMultipleTasksFormModalOpen = action.payload
    },
    setSelectedTaskAction(state, action: PayloadAction<Task | null>) {
      state.selectedTask = action.payload
    },
    setShowTaskLoaderAction(state, action: PayloadAction<boolean>) {
      state.showLoader = action.payload
    },
    setTaskViewModalOpenAction(state, action: PayloadAction<boolean>) {
      state.taskViewModalOpen = action.payload
    },
    setOpenUserTasksCalendarModalAction(
      state,
      action: PayloadAction<TasksState['openUserTasksCalendarModal']>
    ) {
      state.openUserTasksCalendarModal = action.payload
    },
    setUserTasksCalendarDateRangeAction(
      state,
      action: PayloadAction<TasksState['userTasksCalendarDateRange']>
    ) {
      state.userTasksCalendarDateRange = action.payload
    },
    setPatientTasksCalendarViewAction(
      state,
      action: PayloadAction<TasksState['patientTasksCalendarView']>
    ) {
      state.patientTasksCalendarView = action.payload
    },
    setUserTasksCalendarViewAction(
      state,
      action: PayloadAction<TasksState['userTasksCalendarView']>
    ) {
      state.userTasksCalendarView = action.payload
    },
    setUserTasksCalendarAgendaSelectedTasksAction(
      state,
      action: PayloadAction<TasksState['userTasksCalendarAgendaSelectedTasks']>
    ) {
      state.userTasksCalendarAgendaSelectedTasks = action.payload
    },
    setUserTasksCalendarDaySelectedTasksAction(
      state,
      action: PayloadAction<TasksState['userTasksCalendarDaySelectedTasks']>
    ) {
      state.userTasksCalendarDaySelectedTasks = action.payload
    },
  },
})

export const {
  setAddTaskFormModalOpenAction,
  setEditAgendaMultipleTasksFormModalOpenAction,
  setEditDayMultipleTasksFormModalOpenAction,
  setEditTaskFormModalOpenAction,
  setOpenUserTasksCalendarModalAction,
  setPatientTasksCalendarViewAction,
  setSelectedSidebarTabAction,
  setSelectedTaskAction,
  setShowTaskLoaderAction,
  setSidebarOpenAction,
  setTaskViewModalOpenAction,
  setUserTasksCalendarAgendaSelectedTasksAction,
  setUserTasksCalendarDateRangeAction,
  setUserTasksCalendarDaySelectedTasksAction,
  setUserTasksCalendarViewAction,
} = tasksSlice.actions

export const tasksSidebarOpenSelector = (state: RootState) =>
  state.tasks.sidebarOpen

export const taskViewModalOpenSelector = (state: RootState) =>
  state.tasks.taskViewModalOpen

export const tasksSidebarSelectedTabSelector = (state: RootState) =>
  state.tasks.selectedSidebarTab

export const tasksSidebarOpenAddTaskFormModalSelector = (state: RootState) =>
  state.tasks.addTaskFormModalOpen

export const tasksSidebarOpenEditTaskFormModalSelector = (state: RootState) =>
  state.tasks.editTaskFormModalOpen

export const tasksSidebarOpenEditAgendaMultipleTasksFormModalSelector = (
  state: RootState
) => state.tasks.editAgendaMultipleTasksFormModalOpen

export const tasksSidebarOpenEditDayMultipleTasksFormModalSelector = (
  state: RootState
) => state.tasks.editDayMultipleTasksFormModalOpen

export const tasksSelectedTaskSelector = (state: RootState) =>
  state.tasks.selectedTask

export const tasksSidebarShowLoaderSelector = (state: RootState) =>
  state.tasks.showLoader

export const openUserTasksCalendarModalSelector = (state: RootState) =>
  state.tasks.openUserTasksCalendarModal

export const userTasksCalendarDateRangeSelector = (state: RootState) =>
  state.tasks.userTasksCalendarDateRange

export const patientTasksCalendarViewSelector = (state: RootState) =>
  state.tasks.patientTasksCalendarView

export const userTasksCalendarViewSelector = (state: RootState) =>
  state.tasks.userTasksCalendarView

export const userTasksCalendarAgendaSelectedTasksSelector = (
  state: RootState
) => state.tasks.userTasksCalendarAgendaSelectedTasks

export const userTasksCalendarDaySelectedTasksSelector = (state: RootState) =>
  state.tasks.userTasksCalendarDaySelectedTasks

export default tasksSlice.reducer
