import { GetProvidersServiceResponse } from 'src/features/providers/infrastructure'

export const getProvidersServiceResponseMockExampleResurgens: GetProvidersServiceResponse =
  {
    providers: [
      {
        id: 'Resurgens1',
        groupId: '03439e16-d330-4838-b9ce-1b14227960cf',
        firstName: 'Patricia',
        lastName: 'Resurgens',
        npi: '1699963231',
        createdAt: '2023-08-25T15:32:45.957Z',
        createdBy: null,
        group: 'Resurgens - Test',
        addresses: [],
      },
      {
        id: 'Resurgens2',
        groupId: '03439e16-d330-4838-b9ce-1b14227960cf',
        firstName: 'Cary',
        lastName: 'Resurgens',
        npi: '1699963231',
        createdAt: '2023-08-28T15:02:29.734Z',
        createdBy: null,
        group: 'Resurgens - Test',
        addresses: [],
      },
    ],
  }

export const getProvidersServiceResponseMockExampleBenchmark: GetProvidersServiceResponse =
  {
    providers: [
      {
        id: 'Benchmark1',
        groupId: '03439e16-d330-4838-b9ce-1b14227960cf',
        firstName: 'Patricia',
        lastName: 'Benchmark',
        npi: '1699963231',
        createdAt: '2023-08-25T15:32:45.957Z',
        createdBy: null,
        group: 'Resurgens - Test',
        addresses: [],
      },
      {
        id: 'Benchmark2',
        groupId: '03439e16-d330-4838-b9ce-1b14227960cf',
        firstName: 'Cary',
        lastName: 'Benchmark',
        npi: '1699963231',
        createdAt: '2023-08-28T15:02:29.734Z',
        createdBy: null,
        group: 'Resurgens - Test',
        addresses: [],
      },
    ],
  }
export const getProvidersServiceResponseMockExample: GetProvidersServiceResponse =
  {
    providers: [
      ...getProvidersServiceResponseMockExampleBenchmark.providers,
      ...getProvidersServiceResponseMockExampleResurgens.providers,
    ],
  }
