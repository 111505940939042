import { UseQueuesUseCases } from 'src/features/queues/domain'
import { useQueuesServiceAdapter } from 'src/features/queues/adapters'

type MakeUseQueuesUseCases = () => UseQueuesUseCases

const makeUseQueuesUseCases: MakeUseQueuesUseCases = () => () => {
  const eligibilityServiceAdapter = useQueuesServiceAdapter()

  return {
    getQueuePatients: eligibilityServiceAdapter.getQueuePatients,
    getQueues: eligibilityServiceAdapter.getQueues,
  }
}

export const useQueuesUseCases: UseQueuesUseCases = makeUseQueuesUseCases()
