import { useState } from 'react'

import { useSearchProvidersStore } from 'src/features/shared/infrastructure'
import {
  useCreateProviderQuery,
  useCreateProviderGroupQuery,
  useGetProvidersGroupsQuery,
  useGetProvidersQuery,
} from 'src/features/providers/presentation'
import { mapToTCReactSelectOption } from 'src/features/shared/adapters'
import { Provider, ProviderGroup } from 'src/features/providers/domain'
import { addBodyScroll, removeBodyScroll } from 'src/features/shared/utils'
import { toast } from 'react-toastify'

type UseProviderFieldsArgs = {
  onCreateProviderGroupSuccess?: (providerGroup: ProviderGroup) => void
  onCreateProviderSuccess?: (provider: Provider) => void
  currentProviderGroupId?: string
}

export const useProviderFields = ({
  currentProviderGroupId,
  onCreateProviderGroupSuccess,
  onCreateProviderSuccess,
}: UseProviderFieldsArgs = {}) => {
  const [openSearchProvidersModal, _setOpenSearchProvidersModal] =
    useState(false)

  const { setFirstName, setLastName, setProviderGroupId, setSearchResults } =
    useSearchProvidersStore()

  const { createProviderIsLoading } = useCreateProviderQuery()

  const { createProviderGroup, createProviderGroupIsLoading } =
    useCreateProviderGroupQuery()

  const { providerGroups, getProviderGroupsIsLoading } =
    useGetProvidersGroupsQuery()

  const { providers, getProvidersIsLoading } = useGetProvidersQuery({
    filters: { providerGroupId: currentProviderGroupId },
  })

  const setOpenSearchProvidersModal = (open: boolean) => {
    _setOpenSearchProvidersModal(open)
    if (open) {
      removeBodyScroll()
    } else {
      addBodyScroll()
    }
  }

  const getCurrentProviderOption = (providerId: string | undefined) => {
    if (!providerId) {
      return undefined
    }
    const provider = providers.find((provider) => provider.id === providerId)
    if (provider) {
      return mapToTCReactSelectOption(provider)
    }
    return undefined
  }

  const getProviderSelectKey = (providerId: string | undefined) => {
    if (!providerId) {
      return undefined
    }
    return JSON.stringify(getCurrentProviderOption(providerId))
  }

  const getCurrentProviderGroupOption = (
    providerGroupId: string | undefined
  ) => {
    if (!providerGroupId) {
      return undefined
    }
    const providerGroup = providerGroups.find(
      (providerGroup) => providerGroup.id === providerGroupId
    )
    if (providerGroup) {
      return mapToTCReactSelectOption(providerGroup)
    }
    return undefined
  }

  const getProviderGroupSelectKey = (providerGroupId: string | undefined) => {
    if (!providerGroupId) {
      return undefined
    }
    return JSON.stringify(getCurrentProviderGroupOption(providerGroupId))
  }

  const handleCreateProviderGroup = (name: string) => {
    const createProviderArgs = {
      payload: {
        name,
      },
    }
    createProviderGroup(createProviderArgs, {
      onSuccess: (providerGroup) => {
        toast.success(
          `Provider Group ${providerGroup.name} successfully created!`
        )
        if (onCreateProviderGroupSuccess) {
          onCreateProviderGroupSuccess(providerGroup)
        }
      },
    })
  }

  const initSearchProviderForm = (searchQuery: string) => {
    setProviderGroupId(currentProviderGroupId)
    const splitQuery = searchQuery.split(' ')
    if (splitQuery.length > 1) {
      setFirstName(splitQuery[0])
      setLastName(splitQuery[1])
    } else {
      setFirstName(undefined)
      setLastName(splitQuery[0])
    }
    setSearchResults(undefined)
    setOpenSearchProvidersModal(true)
  }

  const handleSearchProvidersModalClose = () => {
    setOpenSearchProvidersModal(false)
  }

  const handleSearchProvidersModalUnMount = () => {
    setOpenSearchProvidersModal(false)
  }

  const handleCreateProviderSuccess = (provider: Provider) => {
    const providerGroup = providerGroups.find(
      (providerGroup) => providerGroup.id === currentProviderGroupId
    )

    let message = `Referring Provider ${provider.name} successfully created`

    if (providerGroup) {
      message += ` and associated it to group ${providerGroup.name}!`
    } else {
      message += '!'
    }

    toast.success(message)

    if (onCreateProviderSuccess) {
      onCreateProviderSuccess(provider)
    }
  }

  return {
    handleCreateProviderGroup,
    createProviderGroupIsLoading,
    createProviderIsLoading,
    getCurrentProviderGroupOption,
    getCurrentProviderOption,
    getProviderGroupSelectKey,
    getProviderGroupsIsLoading,
    getProviderSelectKey,
    getProvidersIsLoading,
    handleCreateProviderSuccess,
    handleSearchProvidersModalClose,
    handleSearchProvidersModalUnMount,
    initSearchProviderForm,
    openSearchProvidersModal,
    providerGroups,
    providers,
    setOpenSearchProvidersModal,
  }
}
