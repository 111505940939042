import { rest } from 'msw'

import { CreateEnablingTherapyServiceResponse } from 'src/features/msk/infrastructure'
import { createEnablingTherapyServiceResponseMock } from 'src/__mocks__/responses/create-enabling-therapy-service-response'
import * as settings from 'src/config/settings'

const url = `${settings.COMPASS_API_BASE_URL}/msk/:intakeId/enabling-therapies`

export const createEnablingTherapyServiceResponseHandler =
  rest.post<CreateEnablingTherapyServiceResponse>(
    url,
    async (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json(createEnablingTherapyServiceResponseMock)
      )
    }
  )
