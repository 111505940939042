import {
  UseMutateFunction,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

import {
  CreatedPatient,
  CreatePatientManuallyArgs,
  CreatePatientManuallyError,
  CreatePatientManuallyReturns,
} from 'src/features/patients/domain'
import { GET_QUEUE_PATIENTS_QUERY_KEY } from 'src/features/queues/presentation'
import { GET_ELIGIBLE_PATIENTS_QUERY_KEY } from 'src/features/eligibility/presentation'
import { usePatientsUseCases } from 'src/features/patients/patients.service-locator'

type useCreatePatientManuallyProps = Omit<
  UseMutationOptions<
    CreatePatientManuallyReturns,
    CreatePatientManuallyError,
    CreatePatientManuallyArgs,
    unknown
  >,
  'mutationFn'
>

type UseCreatePatientManually = (props?: useCreatePatientManuallyProps) => {
  createPatientManually: UseMutateFunction<
    CreatePatientManuallyReturns,
    CreatePatientManuallyError,
    CreatePatientManuallyArgs,
    unknown
  >
  createPatientManuallyIsLoading: boolean
  createPatientManuallyIsError: boolean
  createPatientManuallyStatus: string
}

export const useCreatePatientManually: UseCreatePatientManually = (
  props = {}
) => {
  const queryClient = useQueryClient()
  const patientsUseCases = usePatientsUseCases()

  const { onSuccess } = props

  const { mutate, isLoading, isError, status } = useMutation<
    CreatedPatient,
    CreatePatientManuallyError,
    CreatePatientManuallyArgs,
    unknown
  >({
    mutationFn: (args: CreatePatientManuallyArgs) => {
      return patientsUseCases.createPatientManually(args)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [GET_QUEUE_PATIENTS_QUERY_KEY],
      })
      queryClient.invalidateQueries({
        queryKey: [GET_ELIGIBLE_PATIENTS_QUERY_KEY],
      })
      onSuccess?.(data, variables, context)
    },
  })
  return {
    createPatientManually: mutate,
    createPatientManuallyIsLoading: isLoading,
    createPatientManuallyIsError: isError,
    createPatientManuallyStatus: status,
  }
}
