import { rest } from 'msw'

import { GetQueuePatientsServiceResponse } from 'src/features/queues/infrastructure'

import * as settings from 'src/config/settings'
import { getQueuePatientsServiceResponseMock } from 'src/__mocks__/responses/get-queue-patients-service-response'

const url = `${settings.COMPASS_API_BASE_URL}/queues`

export const getQueuePatientsServiceResponseHandler =
  rest.get<GetQueuePatientsServiceResponse>(url, async (req, res, ctx) => {
    // const limit = req.url.searchParams.get('limit')
    // const page = req.url.searchParams.get('page')
    // const filterOptions = req.url.searchParams.get('filterOptions')

    return res(ctx.status(200), ctx.json(getQueuePatientsServiceResponseMock))
  })
