import { Box, Button } from '@mui/material'
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  UseFormReturn,
} from 'react-hook-form'
import { toast } from 'react-toastify'
import React from 'react'

import { WithChildren } from 'src/features/shared/types'
import { useNotesStore } from 'src/features/shared/infrastructure'
import { CommunicationField } from 'src/features/shared/presentation'
import {
  removePainAndFunctionFieldStateFromLocalStorage,
  useNoteSession,
} from 'src/features/notes/presentation'
import { useDeleteDraftNote } from 'src/features/notes/presentation'
import { DraftNote } from 'src/features/notes/domain'

type FormLayoutWithCancelSaveProps<T extends FieldValues> = {
  formMethods: UseFormReturn<T>
  renderCommunicationField?: boolean
  inboundOutboundType?: 'checkbox' | 'radio'
  onCancelClick?: () => void
  onSaveDraftClick?: SubmitHandler<T>
  onSubmit?: SubmitHandler<T>
  draftNote: DraftNote
}

export const FormLayoutWithCancelSave = <T extends FieldValues>({
  children,
  formMethods,
  onSubmit = () => {},
  onSaveDraftClick,
  onCancelClick,
  renderCommunicationField = true,
  inboundOutboundType = 'radio',
  draftNote,
}: WithChildren<FormLayoutWithCancelSaveProps<T>>) => {
  const { selectedNoteType, currentNotesPatientId } = useNotesStore()
  const { deleteDraftNote } = useDeleteDraftNote({
    patientId: currentNotesPatientId,
  })
  const noteSession = useNoteSession({
    patientId: currentNotesPatientId,
    noteType: selectedNoteType,
    formMethods,
    draftNote,
  })

  const resetForm = () => {
    formMethods.reset()
    noteSession.clear()
    removePainAndFunctionFieldStateFromLocalStorage(
      currentNotesPatientId,
      selectedNoteType
    )
  }

  const handleCancelClick = () => {
    if (onCancelClick) {
      onCancelClick()
    }
    if (draftNote) {
      deleteDraftNote({ draftNoteId: draftNote.id })
    }
    resetForm()
  }

  const handleSaveDraftClick = () => {
    const formData = formMethods.getValues()
    if (onSaveDraftClick) {
      onSaveDraftClick(formData)
    }
  }

  const handleInternalSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      await formMethods.handleSubmit((data, event) => {
        onSubmit(data, event)
        resetForm()
      })(e)
    } catch (error) {
      toast.error('Failed creating patient note!', { autoClose: false })
    }
  }

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleInternalSubmit}
        style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
      >
        {children}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            pt: '40px',
            pb: '16px',
          }}
        >
          {renderCommunicationField ? (
            <Box>
              <CommunicationField variant={inboundOutboundType} />
            </Box>
          ) : null}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              columnGap: '16px',
              mt: 'auto',
            }}
            marginLeft={renderCommunicationField ? 'none' : 'auto'}
          >
            <Button
              variant={'text'}
              color={'secondary'}
              onClick={handleCancelClick}
            >
              CANCEL
            </Button>
            {draftNote !== undefined ? (
              <Button
                variant={'contained'}
                color={'warning'}
                onClick={handleSaveDraftClick}
              >
                SAVE DRAFT
              </Button>
            ) : null}
            <Button type="submit" variant={'contained'} color={'secondary'}>
              SAVE
            </Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  )
}
