import { SxProps, Theme } from '@mui/material'

import { NoteDataField } from 'src/features/notes/domain'

type AdditionalQuestionLabels = Record<NoteDataField, string>

export const ADDITIONAL_QUESTION_LABELS: AdditionalQuestionLabels = {
  accessToMedicineToControlPain:
    'Do you feel you have access to the medication you need to control your pain?',
  additionalConditionFunction: 'Additional condition function',
  additionalConditionPain: 'Additional condition pain',
  additionalQuestionsOrConcerns:
    'What additional questions or concerns do you have about your condition?',
  agreeYouUnderstandCondition: 'Patient understands conditions?',
  agreeYouUnderstandTreatmentOptions: 'Patient understands treatment options?',
  appointmentDate: 'Date of visit?',
  appointmentRescheduled: 'Was the appointment rescheduled?',
  bodyRegionToNote: 'Body region to note: ',
  completedAppointment: 'Did you go to your most recent appointment?',
  completingPhysicalTherapyExercises:
    'Have you been doing your physical therapy exercises at home?',
  detailsOfMissedAppointment: 'Further details on why missed',
  dischargePosition: 'Discharge Disposition:',
  improvementFromInjection:
    'How much do you feel your injection has helped your pain/function?',
  injectionAtCurrentVisit:
    'Did you get an injection at this visit or a previous visit?',
  isPainControlled: 'Do you feel your pain is controlled?',
  medicalHistory: 'Medical History:',
  moreInformationRequested: 'Patient requested more information?',
  newSymptoms: 'Do you have any new symptoms?',
  outcomeNote: 'Outcome note:',
  outcomeOfVisit: 'What was the outcome of your visit?',
  outsideSource: 'Outside source',
  painDuringPTExercise: 'What is your pain level while performing exercises?',
  painLevelDecreased:
    'Has your pain level decreased since you started self-care?',
  participatingInPT: 'Are you participating in physical therapy?',
  patientGoals: 'Patient Goals:',
  physicalTherapyLocation: 'Physical therapy location:',
  preferredProvider: 'Resurgens preferred provider?',
  procedure: 'Procedure type:',
  providerAddress: 'Provider address',
  providerId: 'Provider id',
  providerGroup: 'Provider group:',
  providerGroupId: 'Provider group id:',
  providerGroupLocation: 'Provider group location',
  providerGroupText: 'Provider group other:',
  providerName: 'Provider Name:',
  questionsAboutSurgery:
    'Do you have any questions about what to expect before and after surgery?',
  reasonForMissingAppointment: 'What was the reason for missing?',
  reasonForNotCompletingPTExercises: 'If No, why not?',
  reasonForRefusal: 'Reason for refusal',
  referralDate: 'Date of Referral',
  referralType: 'Referral Type',
  referredFor: 'Referred for:',
  referringProvider: 'Referring provider:',
  referringProviderId: 'Referring provider id:',
  satisfiedWithInjectionResults:
    'Are you satisfied with the results of the injection?',
  scheduled: 'Appt. scheduled?',
  scheduledFollowUp:
    'Do you have a follow-up visit scheduled with your surgeon after your surgery?',
  sdmClinicalBelievesPatientUnderstandsCondition: `Do you feel like this patient understands condition and treatment options?`,
  sdmPatientUnderstandsCondition:
    'Was SDM completed and does the patient understand benefits and risks of pathway choice?',
  sdmText: 'SDM note:',
  surgeryDate: 'Surgical date?',
  surgerySite: 'Surgery Site:',
  typeOfInjection: 'What type of injection?',
  typeOfInjectionText: 'Other injection type',
}

type NoteRegularFieldLabel = 'spokeWith' | 'referralSource' | 'status'

type NoteFieldLabel = Record<NoteDataField | NoteRegularFieldLabel, string>

export const NOTE_FIELD_LABEL: NoteFieldLabel = {
  accessToMedicineToControlPain:
    'If not, do you feel you have access to the medication you need to control your pain?',
  additionalConditionFunction: 'Additional Condition Function',
  additionalConditionPain: 'Additional Condition Pain',
  additionalQuestionsOrConcerns:
    'What additional questions or concerns do you have about your condition?',
  agreeYouUnderstandCondition: 'Patient understands condition?',
  agreeYouUnderstandTreatmentOptions: 'Patient understands treatment options?',
  appointmentDate: 'Date of visit',
  appointmentRescheduled: 'Was the appointment rescheduled?',
  bodyRegionToNote: 'Body Region to Note',
  completedAppointment: 'Did you go to your most recent appointment?',
  completingPhysicalTherapyExercises:
    'Have you been doing your physical therapy exercises at home?',
  detailsOfMissedAppointment: 'Further details on why missed',
  dischargePosition: 'Discharge disposition',
  improvementFromInjection:
    'How much do you feel your injection has helped your pain/function?',
  injectionAtCurrentVisit:
    'Did you get an injection at this visit or a previous visit?',
  isPainControlled: 'Do you feel your pain is controlled?',
  medicalHistory: 'Medical History',
  moreInformationRequested:
    'Patient requested more information? (please select all that apply)',
  newSymptoms: 'Do you have any new symptoms? (related to problem area)',
  outcomeNote: 'Outcome Note',
  outcomeOfVisit: 'What was the outcome of this visit?',
  outsideSource: 'Outside Source',
  painDuringPTExercise: 'What is your pain level while performing exercises?',
  painLevelDecreased:
    'Has your pain level decreased since you started self-care?',
  participatingInPT: 'Are you participating in physical therapy?',
  patientGoals: 'Patient Goals',
  physicalTherapyLocation: 'Where?',
  preferredProvider: 'Preferred Provider',
  procedure: 'Procedure Type',
  providerAddress: 'Provider Address',
  providerGroup: 'Provider Group',
  providerGroupId: 'Provider Group',
  providerGroupLocation: 'Provider Group Location',
  providerGroupText: 'Provider Group - Other',
  providerId: 'Provider',
  providerName: 'Provider Name',
  questionsAboutSurgery:
    'Do you have any questions about what to expect before and after surgery?',
  reasonForMissingAppointment: 'What was the reason for missing?',
  reasonForNotCompletingPTExercises: 'If No, why not?',
  reasonForRefusal: 'Reason Refused',
  referralDate: 'Date of Referral',
  referralSource: 'Referral Source',
  referralType: 'Referral Type',
  referredFor: 'Referred For',
  referringProvider: 'Referring Provider',
  referringProviderId: 'Referring Provider',
  satisfiedWithInjectionResults:
    'Are you satisfied with the results of the injection?',
  scheduled: 'Scheduled',
  scheduledFollowUp:
    'Do you have a follow-up visit scheduled with your surgeon after your surgery?',
  sdmClinicalBelievesPatientUnderstandsCondition:
    'Do you feel like this patient understands condition and treatment options?',
  sdmPatientUnderstandsCondition:
    'Was SDM completed and does the patient understand benefits and risks of pathway choice?',
  sdmText: 'SDM Note',
  spokeWith: 'Spoke with',
  status: 'Status',
  surgeryDate: 'Surgical Date (if Applicable)',
  surgerySite: 'Site of Surgery',
  typeOfInjection: 'What type of injection?',
  typeOfInjectionText: 'Other injection type',
}

export const questionOrder: NoteDataField[] = [
  'sdmText',
  'patientGoals',
  'completedAppointment',
  'reasonForMissingAppointment',
  'detailsOfMissedAppointment',
  'appointmentRescheduled',
  'completingPhysicalTherapyExercises',
  'reasonForNotCompletingPTExercises',
  'painDuringPTExercise',
  'painLevelDecreased',
  'newSymptoms',
  'outcomeOfVisit',
  'outcomeNote',
  'isPainControlled',
  'typeOfInjection',
  'improvementFromInjection',
  'injectionAtCurrentVisit',
  'satisfiedWithInjectionResults',
  'accessToMedicineToControlPain',
  'procedure',
  'surgerySite',
  'dischargePosition',
  'participatingInPT',
  'physicalTherapyLocation',
  'scheduledFollowUp',
  'questionsAboutSurgery',
  'additionalQuestionsOrConcerns',
  'referringProvider',
  'referralDate',
  'referralType',
  'providerName',
  'providerGroup',
  'providerGroupText',
  'preferredProvider',
  'providerGroupLocation',
  'appointmentDate',
  'surgeryDate',
  'scheduled',
  'referredFor',
  'bodyRegionToNote',
  'medicalHistory',
  'sdmPatientUnderstandsCondition',
  'sdmClinicalBelievesPatientUnderstandsCondition',
  'agreeYouUnderstandCondition',
  'agreeYouUnderstandTreatmentOptions',
]

export const questionsWithDateVaules: NoteDataField[] = [
  'appointmentDate',
  'surgeryDate',
  'referralDate',
]

export const hiddenQuestions: NoteDataField[] = [
  'providerId',
  'referringProviderId',
  'providerGroupId',
]

export const noteFieldLabelDefaultSx: SxProps<Theme> = {
  fontWeight: 'bold',
  color: 'rgba(0, 0, 0, 0.6)',
  pb: '8px',
}
