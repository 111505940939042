import { rest } from 'msw'

import * as settings from 'src/config/settings'
import { GetPlansServiceResponse } from 'src/features/providers/infrastructure'
import { getPlansServiceResponseMock } from 'src/__mocks__/responses/get-plans-service-response'

const url = `${settings.PROVIDERS_API_BASE_URL}/plans`

export const getPlansServiceResponseHandler = rest.get<GetPlansServiceResponse>(
  url,
  async (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json(getPlansServiceResponseMock))
  }
)
