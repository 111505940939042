import { useQuery } from '@tanstack/react-query'

import { usePartnersUseCases } from 'src/features/providers/partners.service-locator'
import {
  GetPartnersError,
  GetPartnersReturns,
} from 'src/features/providers/domain'

export const GET_PARTNERS_QUERY_KEY = 'getPartners'

export const useGetPartnersQuery = () => {
  const partnersUseCases = usePartnersUseCases()

  const { data, isLoading, isError, isSuccess } = useQuery<
    GetPartnersReturns,
    GetPartnersError
  >([GET_PARTNERS_QUERY_KEY], () => {
    return partnersUseCases.getPartners()
  })

  return {
    partners: data || [],
    getPartnersIsLoading: isLoading,
    getPartnersIsError: isError,
    getPartnersIsSuccess: isSuccess,
  }
}
