import { rest } from 'msw'

import { CreateTaskServiceResponse } from 'src/features/tasks/infrastructure'

import * as settings from 'src/config/settings'
import { createTaskServiceResponseMock } from 'src/__mocks__/responses/create-task-service-response'

const url = `${settings.COMPASS_API_BASE_URL}/tasks`

export const createTaskServiceResponseHandler =
  rest.post<CreateTaskServiceResponse>(url, async (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(createTaskServiceResponseMock))
  })
