import { Box, Divider, Stack, Typography } from '@mui/material'
import { FieldPath, FieldValues } from 'react-hook-form'
import { FC } from 'react'

import { filterNoneValueOptions } from 'src/features/notes/adapters'
import {
  ControlledSelectField,
  ControlledTextField,
  YES_NO_OPTIONS,
} from 'src/features/shared/presentation'
import {
  NOTE_FIELD_LABEL,
  noteFieldLabelDefaultSx,
} from 'src/features/notes/presentation'
import { mapToControlledSelectItem } from 'src/features/shared/adapters'
import { NoteData } from 'src/features/notes/domain'

export type PreOpTouchpointQuestionsFormV2Values = Pick<
  NoteData,
  | 'agreeYouUnderstandCondition'
  | 'agreeYouUnderstandTreatmentOptions'
  | 'scheduledFollowUp'
  | 'additionalQuestionsOrConcerns'
  | 'questionsAboutSurgery'
  | 'sdmPatientUnderstandsCondition'
  | 'sdmClinicalBelievesPatientUnderstandsCondition'
  | 'procedure'
  | 'sdmText'
  | 'patientGoals'
  | 'reasonForRefusal'
>

type PreOpTouchpointAdditionalQuestionsFormV2Props = {
  scheduledFollowUpFieldName?: FieldPath<FieldValues>
  questionsAboutSurgeryFieldName?: FieldPath<FieldValues>
  additionalQuestionsOrConcernsFieldName?: FieldPath<FieldValues>
  scheduledFollowUpRequired?: boolean
  questionsAboutSurgeryRequired?: boolean
  additionalQuestionsOrConcernsRequired?: boolean
}

export const PreOpTouchpointAdditionalQuestionsFormV2: FC<
  PreOpTouchpointAdditionalQuestionsFormV2Props
> = ({
  scheduledFollowUpFieldName = 'scheduledFollowUp',
  questionsAboutSurgeryFieldName = 'questionsAboutSurgery',
  additionalQuestionsOrConcernsFieldName = 'additionalQuestionsOrConcerns',
  scheduledFollowUpRequired,
  questionsAboutSurgeryRequired,
  additionalQuestionsOrConcernsRequired,
}) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: '8px',
          width: 170,
          whiteSpace: 'nowrap',
          flexDirection: 'column',
        }}
      >
        <Divider />
        <Typography pb={'16px'} color={'grey'}>
          Assessment Questions
        </Typography>
      </Box>
      <Stack direction={'column'} rowGap={'8px'}>
        <ControlledSelectField
          name={scheduledFollowUpFieldName}
          label={NOTE_FIELD_LABEL.scheduledFollowUp}
          items={filterNoneValueOptions(YES_NO_OPTIONS).map(
            mapToControlledSelectItem
          )}
          required={scheduledFollowUpRequired}
          formLabelSx={noteFieldLabelDefaultSx}
          selectSx={{ width: '88px' }}
        />

        <ControlledTextField
          name={questionsAboutSurgeryFieldName}
          label={NOTE_FIELD_LABEL.questionsAboutSurgery}
          required={questionsAboutSurgeryRequired}
          formLabelSx={noteFieldLabelDefaultSx}
          textFieldSx={{ width: '100%' }}
        />

        <ControlledTextField
          name={additionalQuestionsOrConcernsFieldName}
          label={NOTE_FIELD_LABEL.additionalQuestionsOrConcerns}
          required={additionalQuestionsOrConcernsRequired}
          formLabelSx={noteFieldLabelDefaultSx}
          textFieldSx={{ width: '100%' }}
        />
      </Stack>
    </>
  )
}
