import {
  GetProvidersError,
  GetProvidersArgs,
  GetProvidersReturns,
  GetProvidersFilters,
  CreateProviderArgs,
  CreateProviderReturns,
  CreateProviderError,
  CreateProviderPayload,
  GetProviderGroupsReturns,
  GetProviderGroupsError,
  Provider,
  ProviderGroup,
  CreateProviderGroupArgs,
  CreateProviderGroupReturns,
  CreateProviderGroupError,
  CreateProviderGroupPayload,
  SearchNPPESParams,
  SearchNPPESArgs,
  SearchNPPESReturns,
  SearchNPPESError,
  Address,
  BasicInfo,
  Taxonomy,
} from 'src/features/providers/domain'

import {
  GetProvidersServiceErrorResponse,
  GetProvidersServiceArgs,
  GetProvidersServiceResponse,
  GetProvidersServiceFilters,
  CreateProviderServiceArgs,
  CreateProviderServiceResponse,
  CreateProviderServiceErrorResponse,
  CreateProviderServicePayload,
  GetProviderGroupsServiceResponse,
  GetProviderGroupsServiceErrorResponse,
  SProvider,
  SCreatedProvider,
  SProviderGroup,
  CreateProviderGroupServiceArgs,
  CreateProviderGroupServiceResponse,
  CreateProviderGroupServiceErrorResponse,
  CreateProviderGroupServicePayload,
  SearchNPPESServiceArgs,
  SearchNPPESServiceResponse,
  SearchNPPESServiceErrorResponse,
  SearchNPPESServiceParams,
} from 'src/features/providers/infrastructure'
import { NPPESResult } from 'src/features/providers/domain'
import { ProvidersTableRow } from '../presentation/forms/search-providers-form'

type MapToProvider = (provider: SProvider | SCreatedProvider) => Provider

export const mapToProvider: MapToProvider = (provider) => {
  let name = provider.firstName
  if (provider.lastName) {
    name += ` ${provider.lastName}`
  }
  return {
    id: provider.id,
    name,
    npi: provider.npi,
  }
}

// --------------
// GET PROVIDERS /
// -------------

type MapToGetProvidersServiceFilters = (
  filters: GetProvidersFilters
) => GetProvidersServiceFilters

export const mapToGetProvidersServiceFilters: MapToGetProvidersServiceFilters =
  (filters) => ({ groupId: filters.providerGroupId })

type MapToGetProvidersServiceArgs = (
  args?: GetProvidersArgs
) => GetProvidersServiceArgs | undefined

export const mapToGetProvidersServiceArgs: MapToGetProvidersServiceArgs = (
  args
) => {
  if (!args) return undefined

  return {
    filters: args.filters
      ? mapToGetProvidersServiceFilters(args.filters)
      : undefined,
  }
}

type MapToGetProvidersReturns = (
  response: GetProvidersServiceResponse
) => GetProvidersReturns

export const mapToGetProvidersReturns: MapToGetProvidersReturns = (
  response
) => {
  const { providers } = response
  return providers.map(mapToProvider)
}

export type MapToGetProvidersError = (
  error: GetProvidersServiceErrorResponse
) => GetProvidersError

export const mapToGetProvidersError: MapToGetProvidersError = (error) => ({
  ...error,
})

// --------------
// CREATE PROVIDER /
// -------------

type MapToCreateProviderServicePayload = (
  payload: CreateProviderPayload
) => CreateProviderServicePayload

export const mapToCreateProviderPayload: MapToCreateProviderServicePayload = (
  payload
) => ({
  name: payload.name,
  npi: payload.npi,
  groupId: payload.providerGroupId,
})

type MapToCreateProviderServiceArgs = (
  args: CreateProviderArgs
) => CreateProviderServiceArgs

export const mapToCreateProviderServiceArgs: MapToCreateProviderServiceArgs = (
  args
) => ({
  payload: mapToCreateProviderPayload(args.payload),
})

type MapToCreateProviderReturns = (
  response: CreateProviderServiceResponse
) => CreateProviderReturns

export const mapToCreateProviderReturns: MapToCreateProviderReturns = (
  response
) => {
  return mapToProvider(response)
}

type MapToCreateProviderError = (
  error: CreateProviderServiceErrorResponse
) => CreateProviderError

export const mapToCreateProviderError: MapToCreateProviderError = (error) => ({
  ...error,
})

// ---------------------
// GET PROVIDERS GROUP /
// ---------------------

type MapToProviderGroup = (provider: SProviderGroup) => ProviderGroup

export const mapToProviderGroup: MapToProviderGroup = (providerGroup) => {
  return {
    id: providerGroup.id,
    name: providerGroup.group,
    isThreadwellPartner: providerGroup.isThreadwellPartner,
  }
}

type MapToGetProviderGroupsReturns = (
  response: GetProviderGroupsServiceResponse
) => GetProviderGroupsReturns

export const mapToGetProviderGroupsReturns: MapToGetProviderGroupsReturns = (
  response
) => {
  const { providers } = response

  return providers.map(mapToProviderGroup)
}

export type MapToGetProviderGroupsError = (
  error: GetProviderGroupsServiceErrorResponse
) => GetProviderGroupsError

export const mapToGetProviderGroupsError: MapToGetProviderGroupsError = (
  error
) => ({ ...error })

// ---------------------
// CREATE PROVIDERS GROUP /
// ---------------------

type MapToCreateProviderGroupServicePayload = (
  payload: CreateProviderGroupPayload
) => CreateProviderGroupServicePayload

export const mapToCreateProviderGroupPayload: MapToCreateProviderGroupServicePayload =
  (payload) => ({
    group: payload.name,
  })

type MapToCreateProviderGroupServiceArgs = (
  args: CreateProviderGroupArgs
) => CreateProviderGroupServiceArgs

export const mapToCreateProviderGroupServiceArgs: MapToCreateProviderGroupServiceArgs =
  (args) => ({
    payload: mapToCreateProviderGroupPayload(args.payload),
  })

type MapToCreateProviderGroupReturns = (
  response: CreateProviderGroupServiceResponse
) => CreateProviderGroupReturns

export const mapToCreateProviderGroupReturns: MapToCreateProviderGroupReturns =
  (response) => {
    return mapToProviderGroup(response)
  }

type MapToCreateProviderGroupError = (
  error: CreateProviderGroupServiceErrorResponse
) => CreateProviderGroupError

export const mapToCreateProviderGroupError: MapToCreateProviderGroupError = (
  error
) => ({
  ...error,
})

// ---------------------
// Search NPPES        /
// ---------------------

type MapToSearchNPPESServiceParams = (
  params: SearchNPPESParams
) => SearchNPPESServiceParams

export const mapToSearchNPPESParams: MapToSearchNPPESServiceParams = ({
  limit,
  skip,
  npi,
  firstName,
  lastName,
  city,
  state,
  postalCode,
}) => ({
  limit,
  skip,
  number: npi,
  first_name: firstName,
  last_name: lastName,
  city,
  state,
  postal_code: postalCode,
})

type MapToSearchNPPESServiceArgs = (
  args: SearchNPPESArgs
) => SearchNPPESServiceArgs

export const mapToSearchNPPESServiceArgs: MapToSearchNPPESServiceArgs = (
  args
) => ({ params: mapToSearchNPPESParams(args.params) })

type MapToSearchNPPESReturns = (
  svcResponse: SearchNPPESServiceResponse
) => SearchNPPESReturns

export const mapToSearchNPPESReturns: MapToSearchNPPESReturns = (
  svcResponse
) => {
  const results: NPPESResult[] = svcResponse.results.map((result) => {
    const {
      created_epoch: createdEpoch,
      number,
      addresses: unmappedAddresses,
      basic: unmappedBasic,
      taxonomies: unmappedTaxonomies,
    } = result
    const addresses: Address[] = unmappedAddresses.map((unmappedAddress) => ({
      countryCode: unmappedAddress.country_code,
      countryName: unmappedAddress.country_name,
      addressPurpose: unmappedAddress.address_purpose,
      addressType: unmappedAddress.address_type,
      address1: unmappedAddress.address_1,
      city: unmappedAddress.city,
      state: unmappedAddress.state,
      postalCode: unmappedAddress.postal_code,
      telephoneNumber: unmappedAddress.telephone_number,
      faxNumber: unmappedAddress.fax_number,
    }))
    const basic: BasicInfo = {
      firstName: unmappedBasic.first_name,
      lastName: unmappedBasic.last_name,
      middleName: unmappedBasic.middle_name,
    }
    const taxonomies: Taxonomy[] = unmappedTaxonomies.map(
      ({ code, desc, primary }) => ({ code, desc, primary })
    )
    return { createdEpoch, number, addresses, basic, taxonomies }
  })
  return { results, resultCount: svcResponse.result_count }
}

type MapToSearchNPPESError = (
  error: SearchNPPESServiceErrorResponse
) => SearchNPPESError

export const mapToSearchNPPESError: MapToSearchNPPESError = (error) => ({
  ...error,
})

// ---------------------
// TableRow Mapper     /
// ---------------------

type MapToProvidersTableRow = (result: NPPESResult) => ProvidersTableRow

const mapAddress = (address: Address) => {
  let postalCode
  if (address.postalCode) {
    if (address.postalCode.length === 9) {
      postalCode = `
      ${address.postalCode.slice(0, 5)}
      -${address.postalCode.slice(5, 10)}`
    } else {
      postalCode = address.postalCode.slice(0, 5)
    }
  }

  return (
    `${address.address1}\n` +
    `${address.city}, ` +
    `${address.state} ${postalCode || ''}` // Ensure postalCode is a string
  )
}

export const mapToProvidersTableRow: MapToProvidersTableRow = (result) => {
  const npi = result.number
  const name = `${result.basic.firstName} ${result.basic.lastName}`
  const primaryPracticeAddress = result.addresses.find(
    (address: any) => address.addressPurpose === 'LOCATION'
  )! // assume there is always a primary address
  const secondaryPracticeAddress = result.addresses.find(
    (address: any) => address.addressPurpose === 'MAILING'
  )
  const primaryTaxonomy = result.taxonomies.find(
    (taxonomy: any) => taxonomy.primary
  )!
  const address = mapAddress(primaryPracticeAddress)
  const phone = primaryPracticeAddress.telephoneNumber
  const secondaryPhone = secondaryPracticeAddress
    ? secondaryPracticeAddress.telephoneNumber
    : undefined
  const fax = primaryPracticeAddress.faxNumber
  const secondaryFax = secondaryPracticeAddress
    ? secondaryPracticeAddress.faxNumber
    : undefined
  const taxonomy = primaryTaxonomy ? primaryTaxonomy.desc : ''
  const secondaryAddress =
    secondaryPracticeAddress && mapAddress(secondaryPracticeAddress) !== address
      ? mapAddress(secondaryPracticeAddress)
      : undefined
  return {
    npi,
    name,
    address,
    secondaryAddress,
    phone,
    secondaryPhone,
    fax,
    secondaryFax,
    taxonomy,
  }
}
