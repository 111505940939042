import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Theme,
} from '@mui/material'
import { FC } from 'react'
import { SxProps } from '@mui/system'

export const ModalDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.teal['200'],
  color: theme.palette.green['300'],
  marginBottom: '20px',
}))

export const ModalDialogTitleContainer = styled(Box)(({ theme }) => ({
  padding: '16px 24px',
  backgroundColor: theme.palette.teal['200'],
  color: theme.palette.green['300'],
  marginBottom: '20px',
  '& .MuiTypography-root': {
    fontWeight: 500,
    fontSize: '1.25rem',
    lineHeight: 1.6,
    letterSpacing: '0.0075em',
  },
  '& .MuiButtonBase-root.MuiIconButton-root': {
    color: theme.palette.common.white,
  },
}))

type WarningModalProps = {
  open: boolean
  onConfirm: () => void
  onCancel?: () => void
  message?: string
  title?: string
  confirmButtonText?: string
  cancelButtonText?: string
  dialogSx?: SxProps<Theme>
  confirmButtonVariant?: ButtonProps['variant']
  cancelButtonVariant?: ButtonProps['variant']
  confirmButtonPosition?: 'left' | 'right'
  textAlignment?: 'left' | 'center'
  actionsAlignment?: 'center' | 'right'
}

export const WarningModal: FC<WarningModalProps> = ({
  open,
  title,
  message,
  onConfirm,
  onCancel,
  dialogSx,
  confirmButtonVariant = 'text',
  confirmButtonPosition = 'left',
  confirmButtonText = 'Confirm',
  cancelButtonVariant = 'contained',
  cancelButtonText = 'Cancel',
  textAlignment = 'left',
  actionsAlignment = 'right',
}) => {
  return (
    <Dialog open={open} sx={{ ...dialogSx }}>
      {title ? (
        <DialogTitle
          sx={{
            textAlign: textAlignment,
          }}
        >
          {title}
        </DialogTitle>
      ) : null}
      {message ? (
        <DialogContent
          sx={{
            textAlign: textAlignment,
          }}
        >
          {message}
        </DialogContent>
      ) : null}
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent:
            actionsAlignment === 'center'
              ? 'center'
              : confirmButtonPosition === 'left'
              ? 'flex-end'
              : 'flex-start',
          flexDirection:
            confirmButtonPosition === 'left' ? 'row' : 'row-reverse',
          gap: '8px',
        }}
      >
        <Button
          onClick={onConfirm}
          color={'secondary'}
          variant={confirmButtonVariant}
        >
          {confirmButtonText}
        </Button>
        {onCancel ? (
          <Button
            onClick={onCancel}
            color={'secondary'}
            variant={cancelButtonVariant}
          >
            {cancelButtonText}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  )
}
