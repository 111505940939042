import * as yup from 'yup'
import { DevTool } from '@hookform/devtools'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid } from '@mui/material'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import twLogo from 'src/assets/images/threadwell-logo.svg'

import {
  FormLayoutWithCancelSave,
  ControlledNoteField,
  ReferredForField,
  BodyRegionField,
  ControlledTextField,
  REFERRAL_TYPES,
  ControlledSelectField,
  TCReactSelect,
  ControlledDatePicker,
  StatusField,
  useStatusDefinitions,
} from 'src/features/shared/presentation/'

import {
  NOTE_FIELD_LABEL,
  NoteFormProps,
  useCreatePatientReferralUploadNote,
} from 'src/features/notes/presentation'
import {
  mapToCreatePatientReferralUploadNoteArgs,
  mapToUpdateDraftNoteArgs,
} from 'src/features/notes/adapters'
import { useNotesStore } from 'src/features/shared/infrastructure'
import {
  mapToControlledSelectItem,
  mapToTCReactSelectOption,
} from 'src/features/shared/adapters'
import {
  useGetPayorsQuery,
  useProviderFields,
} from 'src/features/providers/presentation'
import { Provider, ProviderGroup } from 'src/features/providers/domain'
import { mapToPatientUpdateStatus } from 'src/features/patients/adapters'
import {
  useUpdatePatient,
  useUpdatePatientStatus,
} from 'src/features/patients/presentation'
import { SearchProvidersModal } from 'src/features/providers/presentation'
import { getReferralSources } from 'src/features/shared/utils'
import { useUpdateDraftNote } from 'src/features/notes/presentation'

export type ReferralUploadNoteFormFields = {
  note: string
  referralSource: string
  referredFor: string
  medicalHistory: string
  spokeWith?: string
  providerGroupId?: string
  providerId?: string
  referralDate?: string
  referralType?: string
  bodyRegionToNote?: string
  communication?: string
  status?: string
  reasonForRefusal?: string
}

type ReferralUploadNoteFormProps = NoteFormProps

const isReasonForRefusalRequired = (status?: string) => status === 'Refused'

export const ReferralUploadNoteForm = ({
  patient,
  setShowLoader,
  draftNote,
}: ReferralUploadNoteFormProps) => {
  const { updatePatientAsync } = useUpdatePatient()
  const { createPatientReferralUploadNoteAsync } =
    useCreatePatientReferralUploadNote()
  const { setSelectedNoteType } = useNotesStore()
  const { updatePatientStatusAsync } = useUpdatePatientStatus()
  const { getAllStatusDefinitions } = useStatusDefinitions(patient)
  const { updateDraftNoteAsync } = useUpdateDraftNote()

  const createReferralUploadNoteFormSchema: yup.Schema<ReferralUploadNoteFormFields> =
    yup.object().shape({
      note: yup.string().required('Required'),
      referralSource: yup.string().required('Required'),
      spokeWith: yup.string(),
      providerGroupId: yup.string().required('Required'),
      providerId: yup.string().required('Required'),
      referralDate: yup.string().typeError('Invalid Date').required('Required'),
      referralType: yup.string().required('Required'),
      referredFor: yup.string().required('Required'),
      medicalHistory: yup.string().required('Required'),
      bodyRegionToNote: yup.string(),
      status: yup.string(),
      reasonForRefusal: yup.string().when('status', {
        is: isReasonForRefusalRequired,
        then: (schema) => schema.required('Required'),
      }),
    })

  const formMethods = useForm<ReferralUploadNoteFormFields>({
    resolver: yupResolver(createReferralUploadNoteFormSchema),
    defaultValues: {
      note: '',
      referralSource: patient.referralSource || '',
      spokeWith: '',
      providerGroupId: '',
      providerId: '',
      referralDate: new Date().toISOString(),
      referralType: '',
      referredFor: '',
      medicalHistory: '',
      bodyRegionToNote: '',
      status: '',
      reasonForRefusal: '',
    },
    mode: 'onBlur',
  })

  const providerGroupWatcher = formMethods.watch('providerGroupId')

  const onCreateProviderGroupSuccess = (providerGroup: ProviderGroup) => {
    formMethods.setValue('providerGroupId', providerGroup.id)
  }

  const onCreateProviderSuccess = (provider: Provider) => {
    formMethods.setValue('providerId', provider.id)
  }

  const {
    handleCreateProviderGroup,
    createProviderGroupIsLoading,
    createProviderIsLoading,
    getCurrentProviderGroupOption,
    getCurrentProviderOption,
    getProviderGroupsIsLoading,
    getProviderSelectKey,
    getProvidersIsLoading,
    handleSearchProvidersModalClose,
    handleSearchProvidersModalUnMount,
    handleCreateProviderSuccess,
    initSearchProviderForm,
    openSearchProvidersModal,
    providerGroups,
    providers,
  } = useProviderFields({
    currentProviderGroupId: providerGroupWatcher,
    onCreateProviderGroupSuccess,
    onCreateProviderSuccess,
  })
  const { payors } = useGetPayorsQuery()

  const threadwellPartners = providerGroups
    .filter((v) => v.isThreadwellPartner)
    .map((v) => v.id)

  const statusWatcher = formMethods.watch('status')

  const isDigitalIntakeRequest = (data: ReferralUploadNoteFormFields) => {
    if (
      data.providerGroupId &&
      patient.medicalProfile &&
      patient.medicalProfile.linkedPayorId &&
      patient.medicalProfile.lineOfBusiness
    ) {
      const group = providerGroups.find(({ id }) => id === data.providerGroupId)
      const { linkedPayorId } = patient.medicalProfile
      const payor = payors.find(({ id }) => id === linkedPayorId)
      if (group && payor) {
        const { isThreadwellPartner } = group
        const isHumana = payor.name.toLowerCase() === 'humana'
        const isHumanaMA =
          isHumana &&
          patient.medicalProfile.lineOfBusiness.toLowerCase() === 'ma'

        if (
          isThreadwellPartner &&
          (!isHumanaMA || (isHumanaMA && !patient.eligible))
        ) {
          return true
        }
      } else {
        return false
      }
    } else {
      return false
    }
  }

  // TODO: This submission should be in a single request and the backend should use a transaction
  const submitHandler: SubmitHandler<ReferralUploadNoteFormFields> = async (
    data
  ) => {
    setShowLoader?.(true)

    const createPatientReferralUploadNoteArgs =
      mapToCreatePatientReferralUploadNoteArgs(patient, data, draftNote.id)

    try {
      await createPatientReferralUploadNoteAsync(
        createPatientReferralUploadNoteArgs
      )
      toast.success('Patient Note successfully created!')
      if (isDigitalIntakeRequest(data)) {
        toast.success('Patient routed to ThreadWell & SMS sent')
      }

      if (
        patient.stageAndStatus &&
        patient.stageAndStatus.stage &&
        data.status
      ) {
        const createPatientUpdateStatusArgs = mapToPatientUpdateStatus(
          patient,
          data.status
        )
        try {
          await updatePatientStatusAsync(createPatientUpdateStatusArgs)

          toast.success('Patient Status successfully updated!')
        } catch (error) {
          toast.error('Patient Status update failed!')
        }
      }

      if (data.referralSource) {
        try {
          await updatePatientAsync({
            patientId: patient.patientId,
            payload: {
              referralSource: data.referralSource,
            },
          })
          toast.success('Patient referral source successfully updated!')
        } catch (error) {
          toast.error('Patient referral source update failed!')
        }
      }
    } catch (error) {
      toast.error('Failed creating patient note!')
    }

    setSelectedNoteType('')
    setShowLoader?.(false)
  }

  const saveDraftHandler: SubmitHandler<ReferralUploadNoteFormFields> = async (
    data
  ) => {
    setShowLoader?.(true)

    const updatePatientCustomNoteArgs = mapToUpdateDraftNoteArgs(
      patient,
      draftNote.id,
      {
        ...data,
        type: 'Referral Upload',
      }
    )

    try {
      await updateDraftNoteAsync(updatePatientCustomNoteArgs)
      toast.success('Patient Note Draft successfully Saved!')
    } catch (error) {
      toast.error('Failed Saving Patient Note Draft!', { autoClose: false })
    }

    setShowLoader?.(false)
  }

  const handleSaveDraft = (data: ReferralUploadNoteFormFields) => {
    saveDraftHandler(data)
  }
  return (
    <Box>
      <FormLayoutWithCancelSave
        onSubmit={submitHandler}
        onSaveDraftClick={handleSaveDraft}
        formMethods={formMethods}
        renderCommunicationField={false}
        draftNote={draftNote}
      >
        <Grid container rowSpacing={2}>
          <Grid item container columnSpacing={2}>
            <Grid item sm={12} pb={'16px'}>
              <ControlledNoteField />
            </Grid>
          </Grid>
          <Grid item container columnSpacing={2}>
            <Grid item sm={4}>
              <StatusField
                statusRequired
                reasonForRefusalRequired={isReasonForRefusalRequired(
                  statusWatcher
                )}
                statuses={getAllStatusDefinitions()}
                formControlSx={{ width: '100%', display: 'flex' }}
              />
            </Grid>
          </Grid>
          <Grid item container columnSpacing={2}>
            <Grid item sm={4}>
              <ControlledSelectField
                name={'referralSource'}
                label={NOTE_FIELD_LABEL.referralSource}
                labelComponent={'inputLabel'}
                items={getReferralSources(patient.referralSource).map(
                  mapToControlledSelectItem
                )}
                formControlProps={{
                  size: 'small',
                }}
                formControlSx={{
                  width: '100%',
                }}
                required
              />
            </Grid>
            <Grid item sm={4}>
              <Controller
                name="providerGroupId"
                control={formMethods.control}
                render={({ field }) => (
                  <TCReactSelect
                    isCreatable
                    placeholder={NOTE_FIELD_LABEL.providerGroup}
                    options={providerGroups.map(mapToTCReactSelectOption)}
                    formatOptionLabel={(providerGroup) => (
                      <span>
                        {providerGroup.label}
                        {threadwellPartners.includes(providerGroup.value) ? (
                          <span>
                            &nbsp;
                            <img
                              src={twLogo}
                              alt="threadwell logo"
                              height={12}
                            />
                          </span>
                        ) : null}
                      </span>
                    )}
                    value={getCurrentProviderGroupOption(field.value)}
                    required={true}
                    isLoading={
                      getProviderGroupsIsLoading || createProviderGroupIsLoading
                    }
                    onBlur={field.onBlur}
                    onChange={(option) => {
                      const value = option ? option.value : ''
                      field.onChange(value)
                      formMethods.resetField('providerId')
                    }}
                    creatableProps={{
                      onCreateOption: handleCreateProviderGroup,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item sm={4}>
              <Controller
                name="providerId"
                control={formMethods.control}
                render={({ field }) => (
                  <TCReactSelect
                    key={getProviderSelectKey(field.value)}
                    isCreatable
                    placeholder={NOTE_FIELD_LABEL.referringProvider}
                    required
                    value={getCurrentProviderOption(field.value)}
                    options={providers.map(mapToTCReactSelectOption)}
                    isDisabled={!providerGroupWatcher || getProvidersIsLoading}
                    isLoading={getProvidersIsLoading || createProviderIsLoading}
                    onBlur={field.onBlur}
                    onChange={(option) => {
                      const value = !option ? '' : option.value
                      field.onChange(value)
                    }}
                    creatableProps={{
                      formatCreateLabel: (val) =>
                        `Search for provider "${val}"`,
                      onCreateOption: initSearchProviderForm,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item container columnSpacing={2}>
            <Grid item sm={4}>
              <ControlledSelectField
                name={'referralType'}
                label={NOTE_FIELD_LABEL.referralType}
                labelComponent={'inputLabel'}
                items={REFERRAL_TYPES.map(mapToControlledSelectItem)}
                formControlProps={{
                  size: 'small',
                }}
                formControlSx={{
                  width: '100%',
                }}
                required
              />
            </Grid>
            <Grid item sm={4}>
              <ReferredForField required />
            </Grid>
            <Grid item sm={4}>
              <ControlledDatePicker
                name={'referralDate'}
                datePickerProps={{
                  label: 'Date of referral',
                  slotProps: {
                    textField: {
                      required: true,
                    },
                  },
                  sx: {
                    display: 'flex',
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid item container columnSpacing={2}>
            <Grid item sm={5}>
              <BodyRegionField />
            </Grid>
          </Grid>
          <Grid item container columnSpacing={2}>
            <Grid item sm={12}>
              <ControlledTextField
                name="medicalHistory"
                required
                textFieldProps={{
                  label: NOTE_FIELD_LABEL.medicalHistory,
                }}
                formControlSx={{ width: '100%' }}
              />
            </Grid>
          </Grid>
        </Grid>
        <DevTool control={formMethods.control} placement={'top-left'} />
      </FormLayoutWithCancelSave>
      <SearchProvidersModal
        open={openSearchProvidersModal}
        onClose={handleSearchProvidersModalClose}
        onUnMount={handleSearchProvidersModalUnMount}
        searchProviderFormProps={{
          onCreateProviderSuccess: handleCreateProviderSuccess,
          onClose: handleSearchProvidersModalClose,
        }}
      />
    </Box>
  )
}
