import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { CompassUser } from 'src/features/shared/domain'
import { RootState } from 'src/features/shared/infrastructure'

export type RolesAndPermissionsState = {
  userRoles: CompassUser['roles']
  userType: CompassUser['type']
}

const initialState: RolesAndPermissionsState = {
  userRoles: [],
  userType: null,
}

const rolesAndPermissionsSlice = createSlice({
  name: 'rolesAndPermissions',
  initialState,
  reducers: {
    setUserRolesAction(
      state,
      action: PayloadAction<RolesAndPermissionsState['userRoles']>
    ) {
      state.userRoles = action.payload
    },
    setUserTypeAction(
      state,
      action: PayloadAction<RolesAndPermissionsState['userType']>
    ) {
      state.userType = action.payload
    },
  },
})

export const { setUserRolesAction, setUserTypeAction } =
  rolesAndPermissionsSlice.actions

export const userRolesSelector = (state: RootState) =>
  state.rolesAndPermissions.userRoles

export const userTypeSelector = (state: RootState) =>
  state.rolesAndPermissions.userType

export default rolesAndPermissionsSlice.reducer
