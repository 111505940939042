import {
  CreatePathwayArgs,
  CreatePathwayError,
  CreatePathwayReturns,
  GetPathwaysError,
  GetPathwaysReturns,
  Pathway,
  ReassignPathwayArgs,
  ReassignPathwayError,
  ReassignPathwayReturns,
  UpdatePathwayArgs,
  UpdatePathwayError,
  UpdatePathwayPayload,
  UpdatePathwayReturns,
} from 'src/features/pathways/domain'
import { PatientPathwayV2 } from 'src/features/patients/domain'
import {
  CreatePathwayServiceErrorResponse,
  CreatePathwayServicePayload,
  CreatePathwayServiceResponse,
  GetPathwaysServiceErrorResponse,
  GetPathwaysServiceResponse,
  ReassignPathwayServiceErrorResponse,
  ReassignPathwayServicePayload,
  ReassignPathwayServiceResponse,
  UpdatePathwayServiceArgs,
  UpdatePathwayServiceErrorResponse,
  UpdatePathwayServicePayload,
  UpdatePathwayServiceResponse,
} from 'src/features/pathways/infrastructure'
import {
  ConditionWithPathway,
  PatientPathwayFormFields,
} from 'src/features/pathways/presentation'
import { formatDate } from 'src/features/shared/utils'
import { SelectOption } from 'src/features/shared/types'
import { PatientIntakeFlowConditionV2 } from 'src/features/msk/presentation'

type MapToGetPathwaysReturns = (
  response: GetPathwaysServiceResponse
) => GetPathwaysReturns

export const mapToGetPathwaysReturns: MapToGetPathwaysReturns = (response) => {
  return {
    pathways: response.definitions,
    pathwayTypes: response.pathwayTypes,
  }
}

type MapToGetPathwaysError = (
  response: GetPathwaysServiceErrorResponse
) => GetPathwaysError

export const mapToGetPathwaysError: MapToGetPathwaysError = (response) => {
  return {
    message: response.message,
  }
}

type MapToCreatePathwayServicePayload = (
  args: CreatePathwayArgs
) => CreatePathwayServicePayload

export const mapToCreatePathwayServicePayload: MapToCreatePathwayServicePayload =
  (args) => {
    return {
      ...args,
    }
  }

type MapToCreatePathwayReturns = (
  response: CreatePathwayServiceResponse
) => CreatePathwayReturns

export const mapToCreatePathwayReturns: MapToCreatePathwayReturns = (
  response
) => {
  return {
    ...response,
  }
}

type MapToCreatePathwayError = (
  response: CreatePathwayServiceErrorResponse
) => CreatePathwayError

export const mapToCreatePathwayError: MapToCreatePathwayError = (response) => {
  return {
    ...response,
  }
}

type MapToReassignPathwayServicePayload = (
  args: ReassignPathwayArgs
) => ReassignPathwayServicePayload

export const mapToReassignPathwayServicePayload: MapToReassignPathwayServicePayload =
  (args) => {
    return {
      ...args,
    }
  }

type MapToReassignPathwayReturns = (
  response: ReassignPathwayServiceResponse
) => ReassignPathwayReturns

export const mapToReassignPathwayReturns: MapToReassignPathwayReturns = (
  response
) => {
  return {
    ...response,
  }
}

type MapToReassignPathwayError = (
  response: ReassignPathwayServiceErrorResponse
) => ReassignPathwayError

export const mapToReassignPathwayError: MapToReassignPathwayError = (
  response
) => {
  return {
    ...response,
  }
}

type MapToUpdatePathwayServicePayload = (
  payload: UpdatePathwayPayload
) => UpdatePathwayServicePayload

export const mapToUpdatePathwayServicePayload: MapToUpdatePathwayServicePayload =
  (payload) => {
    return {
      ...payload,
    }
  }

type MapToUpdatePathwayServiceArgs = (
  args: UpdatePathwayArgs
) => UpdatePathwayServiceArgs

export const mapToUpdatePathwayServiceArgs: MapToUpdatePathwayServiceArgs = (
  args
) => {
  return {
    pathwayId: args.pathwayId,
    payload: mapToUpdatePathwayServicePayload(args.payload),
  }
}

type MapToUpdatePathwayReturns = (
  response: UpdatePathwayServiceResponse
) => UpdatePathwayReturns

export const mapToUpdatePathwayReturns: MapToUpdatePathwayReturns = (
  response
) => {
  return {
    ...response,
  }
}

type MapToUpdatePathwayError = (
  response: UpdatePathwayServiceErrorResponse
) => UpdatePathwayError

export const mapToUpdatePathwayError: MapToUpdatePathwayError = (response) => {
  return {
    ...response,
  }
}

type MapToCreatePathwayArgs = (
  pathway: Pathway,
  patientId: string,
  formValues: PatientPathwayFormFields,
  intakeId: string
) => CreatePathwayArgs

export const mapToCreatePathwayArgs: MapToCreatePathwayArgs = (
  pathway,
  patientId,
  formValues,
  intakeId
) => {
  const createPathwayArgs: CreatePathwayArgs = {
    patientId,
    navigatorId: formValues.navigatorId,
    pathwaySequenceIdentifier: pathway.id,
    pathwaySequenceCode: pathway.code,
    pathway: pathway.pathway,
    pathwayType: formValues.pathwayType,
    intakeId: intakeId,
  }

  if (formValues.pathwayDeviation) {
    createPathwayArgs.pathwayDeviation = formValues.pathwayDeviation
    if (formValues.pathwayDeviation === 'other') {
      createPathwayArgs.pathwayDeviationOtherExplanation =
        formValues.pathwayDeviationOtherExplanation
    }

    if (
      formValues.pathwayDeviation ===
      'has barrier to pursuing recommended pathway'
    ) {
      createPathwayArgs.pathwayDeviationBarrierExplanation =
        formValues.pathwayDeviationBarrierExplanation
      if (formValues.pathwayDeviationBarrierExplanation === 'other') {
        createPathwayArgs.pathwayDeviationBarrierExplanationOtherExplanation =
          formValues.pathwayDeviationBarrierExplanationOtherExplanation
      }
    }
  }
  if (formValues.commsTrigger) {
    createPathwayArgs.lockDate = formatDate(formValues.commsTrigger)
  }
  if (formValues.firstAppointmentDate) {
    createPathwayArgs.firstAppointmentDate = formatDate(
      formValues.firstAppointmentDate
    )
  }
  return createPathwayArgs
}

type MapToReassignPathwayArgs = (
  pathway: Pathway,
  patientId: string,
  assignedPathwayId: string,
  formValues: PatientPathwayFormFields,
  intakeId: string
) => ReassignPathwayArgs

export const mapToReassignPathwayArgs: MapToReassignPathwayArgs = (
  pathway,
  patientId,
  assignedPathwayId,
  formValues,
  intakeId
) => {
  const reassignPathwayArgs: ReassignPathwayArgs = {
    patientId,
    previousAssignedPathwayId: assignedPathwayId,
    navigatorId: formValues.navigatorId,
    pathwaySequenceIdentifier: pathway.id,
    pathwaySequenceCode: pathway.code,
    pathway: pathway.pathway,
    pathwayType: formValues.pathwayType,
    intakeId: intakeId,
  }
  if (formValues.pathwayDeviation) {
    reassignPathwayArgs.pathwayDeviation = formValues.pathwayDeviation
    if (formValues.pathwayDeviation === 'other') {
      reassignPathwayArgs.pathwayDeviationOtherExplanation =
        formValues.pathwayDeviationOtherExplanation
    }

    if (
      formValues.pathwayDeviation ===
      'has barrier to pursuing recommended pathway'
    ) {
      reassignPathwayArgs.pathwayDeviationBarrierExplanation =
        formValues.pathwayDeviationBarrierExplanation
      if (formValues.pathwayDeviationBarrierExplanation === 'other') {
        reassignPathwayArgs.pathwayDeviationBarrierExplanationOtherExplanation =
          formValues.pathwayDeviationBarrierExplanationOtherExplanation
      }
    }
  }
  if (formValues.commsTrigger) {
    reassignPathwayArgs.lockDate = formatDate(formValues.commsTrigger)
  }
  if (formValues.firstAppointmentDate) {
    reassignPathwayArgs.firstAppointmentDate = formatDate(
      formValues.firstAppointmentDate
    )
  }
  return reassignPathwayArgs
}

type MapToUpdatePathwayPayload = (
  patientId: string,
  pathway: Pathway,
  formValues: PatientPathwayFormFields
) => UpdatePathwayPayload

export const mapToUpdatePathwayPayload: MapToUpdatePathwayPayload = (
  patientId: string,
  pathway,
  formValues
) => {
  const updatePathwayPayload: UpdatePathwayPayload = {
    patientId,
    navigatorId: formValues.navigatorId,
    pathway: pathway.pathway,
    pathwayType: formValues.pathwayType,
  }
  if (formValues.pathwayDeviation) {
    updatePathwayPayload.pathwayDeviation = formValues.pathwayDeviation
    if (formValues.pathwayDeviation === 'other') {
      updatePathwayPayload.pathwayDeviationOtherExplanation =
        formValues.pathwayDeviationOtherExplanation
    }

    if (
      formValues.pathwayDeviation ===
      'has barrier to pursuing recommended pathway'
    ) {
      updatePathwayPayload.pathwayDeviationBarrierExplanation =
        formValues.pathwayDeviationBarrierExplanation
      if (formValues.pathwayDeviationBarrierExplanation === 'other') {
        updatePathwayPayload.pathwayDeviationBarrierExplanationOtherExplanation =
          formValues.pathwayDeviationBarrierExplanationOtherExplanation
      }
    }
  }

  if (formValues.commsTrigger) {
    updatePathwayPayload.lockDate = formatDate(formValues.commsTrigger)
  }
  if (formValues.firstAppointmentDate) {
    updatePathwayPayload.firstAppointmentDate = formatDate(
      formValues.firstAppointmentDate
    )
  }
  return updatePathwayPayload
}

export const orthoTrackSplitter = (orthoTrack: string) => {
  const trackSplit = orthoTrack.split(' - ')
  if (trackSplit.length < 2) {
    throw new Error('Ortho Track is not in the correct format')
  }
  return {
    trackType: trackSplit[0],
    track: trackSplit[1],
  }
}

export const orthoTrackJoiner = (trackType: string, track: string) => {
  return `${trackType} - ${track}`
}

type MapToAllTracks = (pathways: Pathway[]) => SelectOption[]

export const mapToAllTracks: MapToAllTracks = (pathways) =>
  Array.from(
    new Set(
      pathways.map((pathwayItem) => {
        const label =
          pathwayItem.pathway === 'Ortho'
            ? orthoTrackSplitter(pathwayItem.track).track
            : pathwayItem.track

        return {
          key: pathwayItem.code,
          value: pathwayItem.code,
          label,
        }
      })
    )
  )

type MapToTracksByPathway = (
  pathways: Pathway[],
  filterBy: string
) => SelectOption[]

export const mapToTracksByPathway: MapToTracksByPathway = (
  pathways: Pathway[],
  filterBy
) =>
  Array.from(
    new Set(
      pathways
        ?.filter((pathwayItem) => pathwayItem.pathway === filterBy)
        .map((pathwayItem) => ({
          key: pathwayItem.code,
          value: pathwayItem.code,
          label: pathwayItem.track,
        }))
    )
  )

type MapToOrthoTrackTypes = (pathways: Pathway[]) => string[]

export const mapToOrthoTrackTypes: MapToOrthoTrackTypes = (pathways) => {
  return Array.from(
    new Set(
      mapToTracksByPathway(pathways, 'Ortho').map(
        (track) => orthoTrackSplitter(track.label).trackType
      )
    )
  )
}

type MapToOrthoTracks = (
  pathways: Pathway[],
  trackType: string
) => SelectOption[]

export const mapToOrthoTracks: MapToOrthoTracks = (pathways, trackType) =>
  Array.from(
    new Set(
      mapToTracksByPathway(pathways, 'Ortho')
        .filter(
          (track) => orthoTrackSplitter(track.label).trackType === trackType
        )
        .map((track) => ({
          key: track.key,
          value: track.value,
          label: orthoTrackSplitter(track.label).track,
        }))
    )
  )

type MapToConditionWithPathway = (
  conditions: PatientIntakeFlowConditionV2[],
  pathways: PatientPathwayV2[]
) => ConditionWithPathway[]

export const mapToConditionWithPathway: MapToConditionWithPathway = (
  conditions,
  pathways
) => {
  return conditions.map((condition) => {
    const result: ConditionWithPathway = {
      condition: condition,
      pathway: null,
    }

    const conditionPathway = pathways.find((pathway) => {
      return (
        condition.associatedPathway &&
        condition.associatedPathway.id === pathway.id
      )
    })
    if (conditionPathway) {
      result.pathway = conditionPathway
    }

    return result
  })
}
