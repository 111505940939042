import {
  UseMutateFunction,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { useProvidersUseCases } from 'src/features/providers/providers.service-locator'
import {
  CreateProviderGroupError,
  CreateProviderGroupArgs,
  CreateProviderGroupReturns,
} from 'src/features/providers/domain'
import { GET_PROVIDERS_GROUPS_QUERY_KEY } from 'src/features/providers/presentation'

type useCreateProviderGroupProps = Omit<
  UseMutationOptions<
    CreateProviderGroupReturns,
    CreateProviderGroupError,
    CreateProviderGroupArgs,
    unknown
  >,
  'mutationFn'
>

type UseCreateProviderGroup = (props?: useCreateProviderGroupProps) => {
  createProviderGroup: UseMutateFunction<
    CreateProviderGroupReturns,
    CreateProviderGroupError,
    CreateProviderGroupArgs,
    unknown
  >
  createProviderGroupIsLoading: boolean
  createProviderGroupIsError: boolean
  createProviderGroupStatus: string
}

export const useCreateProviderGroupQuery: UseCreateProviderGroup = (
  props = {}
) => {
  const providersUseCases = useProvidersUseCases()
  const queryClient = useQueryClient()

  const { onSuccess } = props

  const { mutate, isLoading, isError, status } = useMutation<
    CreateProviderGroupReturns,
    CreateProviderGroupError,
    CreateProviderGroupArgs,
    unknown
  >({
    mutationFn: (args: CreateProviderGroupArgs) => {
      return providersUseCases.createProviderGroup(args)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [GET_PROVIDERS_GROUPS_QUERY_KEY],
      })
      onSuccess?.(data, variables, context)
    },
  })

  return {
    createProviderGroup: mutate,
    createProviderGroupIsLoading: isLoading,
    createProviderGroupIsError: isError,
    createProviderGroupStatus: status,
  }
}
