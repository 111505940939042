import * as yup from 'yup'
import InputMask from 'react-input-mask'
import { DevTool } from '@hookform/devtools'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, TextField, Button, Divider } from '@mui/material'
import { FC, useState } from 'react'
import {
  useForm,
  SubmitHandler,
  FormProvider,
  Controller,
} from 'react-hook-form'
import { toast } from 'react-toastify'

import { mapToUpdatePatientPayload } from 'src/features/patients/adapters'
import { PatientV2 } from 'src/features/patients/domain'
import {
  BackdropLoader,
  ControlledDatePicker,
  ControlledSelectField,
  LanguageFieldsSection,
} from 'src/features/shared/presentation'
import { useUpdatePatient } from 'src/features/patients/presentation'
import {
  addressRegex,
  cityRegex,
  cleanPhoneFieldValue,
  getMaskedPhone,
  nameRegex,
  phoneRegex,
  stateRegex,
  zipRegex,
} from 'src/features/shared/utils'
import { LINE_OF_BUSINESS_OPTIONS } from 'src/features/shared/constants'
import { mapToControlledSelectItem } from 'src/features/shared/adapters'
import { useGetPayorsQuery } from 'src/features/providers/presentation'

export type PatientHeaderEditFormFields = {
  firstName?: string
  middleName?: string
  lastName?: string
  dob?: Date
  phone1?: string
  phone2?: string
  email?: string
  addressLine1?: string
  addressLine2?: string
  city?: string
  state?: string
  zip?: string
  mrn?: string
  language?: string
  otherLanguage?: string
  primaryCarePhysician?: string
  pcpPhone?: string
  pcpFax?: string
  pcpGroupName?: string
  pcpAddressLine1?: string
  pcpAddressLine2?: string
  pcpCity?: string
  pcpState?: string
  pcpZip?: string
  lineOfBusiness?: string
}
const isOtherLanguageRequired = (language?: string) => language === 'Other'

const patientHeaderEditFormSchema: yup.Schema<PatientHeaderEditFormFields> = yup
  .object()
  .shape({
    firstName: yup
      .string()
      .matches(nameRegex, 'Invalid format')
      .required('Required'),
    middleName: yup.string().matches(nameRegex, 'Invalid format'),
    lastName: yup
      .string()
      .matches(nameRegex, 'Invalid format')
      .required('Required'),
    dob: yup.date().typeError('Invalid Date').required('Required'),
    phone1: yup
      .string()
      .required('Required')
      .test('phone1', 'Invalid format', (value) => {
        if (value) {
          return phoneRegex.test(value)
        }
        return true
      }),
    phone2: yup.string().test('phone2', 'Invalid format', (value) => {
      if (value) {
        return phoneRegex.test(value)
      }
      return true
    }),
    email: yup.string().email(),
    addressLine1: yup.string().matches(addressRegex, 'Invalid format'),
    addressLine2: yup.string().matches(addressRegex, 'Invalid format'),
    city: yup.string().matches(cityRegex, 'Invalid format'),
    state: yup.string().matches(stateRegex, 'Invalid format'),
    zip: yup.string().matches(zipRegex, 'Invalid format'),
    mrn: yup.string(),
    language: yup.string(),
    otherLanguage: yup.string().when('language', {
      is: isOtherLanguageRequired,
      then: (schema) => schema.required('Required'),
    }),
    primaryCarePhysician: yup.string().matches(nameRegex, 'Invalid format'),
    pcpPhone: yup.string().test('pcpPhone', 'Invalid format', (value) => {
      if (value) {
        return phoneRegex.test(value)
      }
      return true
    }),
    pcpFax: yup.string().test('pcpFax', 'Invalid format', (value) => {
      if (value) {
        return phoneRegex.test(value)
      }
      return true
    }),
    pcpGroupName: yup.string(),
    pcpAddressLine1: yup.string().matches(addressRegex, 'Invalid format'),
    pcpAddressLine2: yup.string().matches(addressRegex, 'Invalid format'),
    pcpCity: yup.string().matches(cityRegex, 'Invalid format'),
    pcpState: yup.string().matches(stateRegex, 'Invalid format'),
    pcpZip: yup.string().matches(zipRegex, {
      message: 'Invalid format',
      excludeEmptyString: true,
    }),
    lineOfBusiness: yup.string().when('payor', {
      is: 'Humana',
      then: (schema) => schema.required('Required'),
    }),
  })

type PatientPageHeaderEditFormProps = {
  patient: PatientV2
  onCancelClick: () => void
  initialValues: PatientHeaderEditFormFields
  onSaveSuccess?: () => void
}

export const PatientPageHeaderEditForm: FC<PatientPageHeaderEditFormProps> = ({
  patient,
  initialValues,
  onCancelClick,
  onSaveSuccess,
}) => {
  const [showLoader, setShowLoader] = useState(false)
  const { updatePatient } = useUpdatePatient()
  const formMethods = useForm<PatientHeaderEditFormFields>({
    resolver: yupResolver(patientHeaderEditFormSchema),
    defaultValues: initialValues,
    mode: 'onBlur',
  })
  const languageWatcher = formMethods.watch('language')
  const { getPayorsIsLoading, getPayorName } = useGetPayorsQuery()

  const submitHandler: SubmitHandler<PatientHeaderEditFormFields> = (data) => {
    setShowLoader(true)
    updatePatient(
      {
        patientId: patient.patientId,
        payload: mapToUpdatePatientPayload(patient, data),
      },
      {
        onSuccess: () => {
          toast.success('Patient succesfully updated!')
          onSaveSuccess?.()
        },
        onError: () => {
          toast.error('Patient update failed!')
        },
        onSettled: () => {
          setShowLoader(false)
        },
      }
    )
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(submitHandler)}>
        <Box
          sx={{
            padding: '48px 48px 24px 24px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '48px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: 3,
            }}
          >
            <Divider textAlign="left" sx={{ color: 'gray' }}>
              Patient Info
            </Divider>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: '24px',
                '& > *': {
                  flexBasis: '100%',
                },
              }}
            >
              <TextField
                label={'First Name'}
                variant={'outlined'}
                size={'small'}
                error={!!formMethods.formState.errors['firstName']}
                helperText={
                  formMethods.formState.errors['firstName']
                    ? (formMethods.formState.errors['firstName']
                        ?.message as string)
                    : undefined
                }
                {...formMethods.register('firstName')}
                required
              />
              <TextField
                label={'Middle Name'}
                variant={'outlined'}
                size={'small'}
                error={!!formMethods.formState.errors['middleName']}
                helperText={
                  formMethods.formState.errors['middleName']
                    ? (formMethods.formState.errors['middleName']
                        ?.message as string)
                    : undefined
                }
                {...formMethods.register('middleName')}
              />
              <TextField
                label={'Last Name'}
                variant={'outlined'}
                size={'small'}
                error={!!formMethods.formState.errors['lastName']}
                helperText={
                  formMethods.formState.errors['lastName']
                    ? (formMethods.formState.errors['lastName']
                        ?.message as string)
                    : undefined
                }
                {...formMethods.register('lastName')}
                required
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: '24px',
                '& > *': {
                  flexBasis: '100%',
                },
              }}
            >
              <ControlledDatePicker
                name="dob"
                datePickerProps={{
                  label: 'DOB',
                  slotProps: {
                    textField: {
                      required: true,
                    },
                  },
                }}
              />
              <Controller
                name={'phone1'}
                control={formMethods.control}
                render={({ field, fieldState }) => (
                  <Box>
                    <InputMask
                      mask="(999) 999-9999"
                      value={getMaskedPhone(field.value)}
                      onBlur={field.onBlur}
                      onChange={(e) => {
                        const value = cleanPhoneFieldValue(e.target.value)
                        field.onChange(value)
                      }}
                    >
                      <TextField
                        error={fieldState.invalid}
                        helperText={
                          fieldState.error ? fieldState.error.message : ''
                        }
                        label={'Phone 1'}
                        size={'small'}
                        required
                      />
                    </InputMask>
                  </Box>
                )}
              />
              <Controller
                name={'phone2'}
                control={formMethods.control}
                render={({ field, fieldState }) => (
                  <Box>
                    <InputMask
                      mask="(999) 999-9999"
                      value={getMaskedPhone(field.value)}
                      onBlur={field.onBlur}
                      onChange={(e) => {
                        const value = cleanPhoneFieldValue(e.target.value)
                        field.onChange(value)
                      }}
                    >
                      <TextField
                        error={fieldState.invalid}
                        helperText={
                          fieldState.error ? fieldState.error.message : ''
                        }
                        label={'Phone 2'}
                        size={'small'}
                      />
                    </InputMask>
                  </Box>
                )}
              />
              <TextField
                label={'Email'}
                variant={'outlined'}
                size={'small'}
                error={!!formMethods.formState.errors['email']}
                helperText={
                  formMethods.formState.errors['email']
                    ? (formMethods.formState.errors['email']?.message as string)
                    : undefined
                }
                fullWidth
                {...formMethods.register('email')}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: '24px',
              }}
            ></Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '12px',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
                <TextField
                  label={'Address 1'}
                  variant={'outlined'}
                  size={'small'}
                  fullWidth
                  error={!!formMethods.formState.errors['addressLine1']}
                  helperText={
                    formMethods.formState.errors['addressLine1']
                      ? (formMethods.formState.errors['addressLine1']
                          ?.message as string)
                      : undefined
                  }
                  {...formMethods.register('addressLine1')}
                />
                <TextField
                  label={'City'}
                  variant={'outlined'}
                  size={'small'}
                  sx={{
                    flexBasis: '298px',
                  }}
                  error={!!formMethods.formState.errors['city']}
                  helperText={
                    formMethods.formState.errors['city']
                      ? (formMethods.formState.errors['city']
                          ?.message as string)
                      : undefined
                  }
                  {...formMethods.register('city')}
                />
                <TextField
                  label={'State'}
                  variant={'outlined'}
                  size={'small'}
                  sx={{
                    flexBasis: '110px',
                  }}
                  error={!!formMethods.formState.errors['state']}
                  helperText={
                    formMethods.formState.errors['state']
                      ? (formMethods.formState.errors['state']
                          ?.message as string)
                      : undefined
                  }
                  {...formMethods.register('state')}
                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
                <TextField
                  label={'Address 2'}
                  variant={'outlined'}
                  size={'small'}
                  sx={{
                    maxWidth: '454px',
                    width: '100%',
                  }}
                  error={!!formMethods.formState.errors['addressLine2']}
                  helperText={
                    formMethods.formState.errors['addressLine2']
                      ? (formMethods.formState.errors['addressLine2']
                          ?.message as string)
                      : undefined
                  }
                  {...formMethods.register('addressLine2')}
                />
                <TextField
                  label={'Zip'}
                  variant={'outlined'}
                  size={'small'}
                  sx={{
                    flexBasis: '151px',
                  }}
                  error={!!formMethods.formState.errors['zip']}
                  helperText={
                    formMethods.formState.errors['zip']
                      ? (formMethods.formState.errors['zip']?.message as string)
                      : undefined
                  }
                  {...formMethods.register('zip')}
                />
              </Box>
            </Box>

            <LanguageFieldsSection
              otherLanguageIsRequired={isOtherLanguageRequired(languageWatcher)}
            />

            <Divider textAlign="left" sx={{ color: 'gray' }}>
              PCP Info
            </Divider>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: '32px',
                width: '70%',
                '& > *': {
                  flexBasis: '100%',
                },
              }}
            >
              <TextField
                label={'PCP'}
                variant={'outlined'}
                size={'small'}
                error={!!formMethods.formState.errors['primaryCarePhysician']}
                helperText={
                  formMethods.formState.errors['primaryCarePhysician']
                    ? (formMethods.formState.errors['primaryCarePhysician']
                        ?.message as string)
                    : undefined
                }
                {...formMethods.register('primaryCarePhysician')}
              />
              <TextField
                label={'PCP Group Name'}
                variant={'outlined'}
                size={'small'}
                error={!!formMethods.formState.errors['pcpGroupName']}
                helperText={
                  formMethods.formState.errors['pcpGroupName']
                    ? (formMethods.formState.errors['pcpGroupName']
                        ?.message as string)
                    : undefined
                }
                {...formMethods.register('pcpGroupName')}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: '32px',
                width: '70%',
                '& > *': {
                  flexBasis: '100%',
                },
              }}
            >
              <Controller
                name={'pcpPhone'}
                control={formMethods.control}
                render={({ field, fieldState }) => (
                  <InputMask
                    mask="(999) 999-9999"
                    value={getMaskedPhone(field.value)}
                    onBlur={field.onBlur}
                    onChange={(e) => {
                      const value = cleanPhoneFieldValue(e.target.value)
                      field.onChange(value)
                    }}
                  >
                    <TextField
                      error={fieldState.invalid}
                      helperText={
                        fieldState.error ? fieldState.error.message : ''
                      }
                      label={'PCP Phone'}
                      size={'small'}
                    />
                  </InputMask>
                )}
              />
              <Controller
                name={'pcpFax'}
                control={formMethods.control}
                render={({ field, fieldState }) => (
                  <InputMask
                    mask="(999) 999-9999"
                    value={getMaskedPhone(field.value)}
                    onBlur={field.onBlur}
                    onChange={(e) => {
                      const value = cleanPhoneFieldValue(e.target.value)
                      field.onChange(value)
                    }}
                  >
                    <TextField
                      error={fieldState.invalid}
                      helperText={
                        fieldState.error ? fieldState.error.message : ''
                      }
                      label={'PCP Fax'}
                      size={'small'}
                    />
                  </InputMask>
                )}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '12px',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
                <TextField
                  label={'PCP Address 1'}
                  variant={'outlined'}
                  size={'small'}
                  fullWidth
                  error={!!formMethods.formState.errors['pcpAddressLine1']}
                  helperText={
                    formMethods.formState.errors['pcpAddressLine1']
                      ? (formMethods.formState.errors['pcpAddressLine1']
                          ?.message as string)
                      : undefined
                  }
                  {...formMethods.register('pcpAddressLine1')}
                />
                <TextField
                  label={'PCP City'}
                  variant={'outlined'}
                  size={'small'}
                  sx={{
                    flexBasis: '298px',
                  }}
                  error={!!formMethods.formState.errors['pcpCity']}
                  helperText={
                    formMethods.formState.errors['pcpCity']
                      ? (formMethods.formState.errors['pcpCity']
                          ?.message as string)
                      : undefined
                  }
                  {...formMethods.register('pcpCity')}
                />
                <TextField
                  label={'PCP State'}
                  variant={'outlined'}
                  size={'small'}
                  sx={{
                    flexBasis: '110px',
                  }}
                  error={!!formMethods.formState.errors['pcpState']}
                  helperText={
                    formMethods.formState.errors['pcpState']
                      ? (formMethods.formState.errors['pcpState']
                          ?.message as string)
                      : undefined
                  }
                  {...formMethods.register('pcpState')}
                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
                <TextField
                  label={'PCP Address 2'}
                  variant={'outlined'}
                  size={'small'}
                  sx={{
                    maxWidth: '454px',
                    width: '100%',
                  }}
                  error={!!formMethods.formState.errors['pcpAddressLine2']}
                  helperText={
                    formMethods.formState.errors['pcpAddressLine2']
                      ? (formMethods.formState.errors['pcpAddressLine2']
                          ?.message as string)
                      : undefined
                  }
                  {...formMethods.register('pcpAddressLine2')}
                />
                <TextField
                  label={'PCP Zip'}
                  variant={'outlined'}
                  size={'small'}
                  sx={{
                    flexBasis: '151px',
                  }}
                  error={!!formMethods.formState.errors['pcpZip']}
                  helperText={
                    formMethods.formState.errors['pcpZip']
                      ? (formMethods.formState.errors['pcpZip']
                          ?.message as string)
                      : undefined
                  }
                  {...formMethods.register('pcpZip')}
                />
              </Box>
            </Box>
            <Divider textAlign="left" sx={{ color: 'gray' }}>
              MRN
            </Divider>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '16px',
                width: '60%',
              }}
            >
              <TextField
                label={'mrn'}
                variant={'outlined'}
                size={'small'}
                sx={{
                  maxWidth: '454px',
                  width: '100%',
                }}
                {...formMethods.register('mrn')}
              />
            </Box>
            <Box
              data-testid="edit_patient_header_select_lob_btn"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '16px',
                width: '60%',
              }}
            >
              <ControlledSelectField
                name={'lineOfBusiness'}
                label={'Line of Business'}
                labelComponent={'inputLabel'}
                items={LINE_OF_BUSINESS_OPTIONS.map(mapToControlledSelectItem)}
                formControlProps={{
                  size: 'small',
                }}
                formControlSx={{
                  width: '50%',
                  flex: { xs: '0 1 170px' },
                }}
                required={
                  !getPayorsIsLoading &&
                  patient.medicalProfile !== undefined &&
                  patient.medicalProfile !== null &&
                  patient.medicalProfile.linkedPayorId !== undefined &&
                  patient.medicalProfile.linkedPayorId !== null &&
                  getPayorName(patient.medicalProfile.linkedPayorId, true) ===
                    'Humana'
                }
              />
            </Box>
          </Box>
          <Box
            sx={{
              pt: '24px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              columnGap: '16px',
            }}
          >
            <Button
              variant={'outlined'}
              color={'secondary'}
              onClick={onCancelClick}
              data-testid="edit_patient_header_cancel_btn"
            >
              CANCEL
            </Button>
            <Button
              type="submit"
              variant={'contained'}
              color={'secondary'}
              data-testid="edit_patient_header_save_btn"
            >
              SAVE
            </Button>
          </Box>
        </Box>
      </form>
      <BackdropLoader open={showLoader} />
      <DevTool control={formMethods.control} placement={'bottom-left'} />
    </FormProvider>
  )
}
