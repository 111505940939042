import { rest } from 'msw'

import { GetEligiblePatientsServiceResponse } from 'src/features/eligibility/infrastructure'

import * as settings from 'src/config/settings'
import { getEligiblePatientsServiceResponseMock } from 'src/__mocks__/responses/get-elegible-patients-service-response'

const url = `${settings.COMPASS_API_BASE_URL}/eligible-users`

export const getEligiblePatientsServiceResponseHandler =
  rest.get<GetEligiblePatientsServiceResponse>(url, async (req, res, ctx) => {
    // const limit = req.url.searchParams.get('limit')
    // const page = req.url.searchParams.get('page')
    // const filterOptions = req.url.searchParams.get('filterOptions')

    return res(
      ctx.status(200),
      ctx.json(getEligiblePatientsServiceResponseMock)
    )
  })
