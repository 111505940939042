import { Box, Button, IconButton } from '@mui/material'
import { FC } from 'react'

import AddIcon from '@mui/icons-material/Add'
import FilterListIcon from '@mui/icons-material/FilterList'

import {
  QueuePatientsSearchInput,
  useGetQueuePatientsQuery,
  useGetQueuesQuery,
  useQueuePatientsQueryParams,
} from 'src/features/queues/presentation'
import { useQueuePatientsStore } from 'src/features/shared/infrastructure'
import { RefreshButton, useCurrentPage } from 'src/features/shared/presentation'

export const QueuePatientsActionsHeader: FC = () => {
  const { refetchQueues, getQueuesIsRefetching, getQueuesIsLoading } =
    useGetQueuesQuery()
  const {
    refetchQueuePatients,
    getQueuePatientsIsRefetching,
    getQueuePatientsIsLoading,
  } = useGetQueuePatientsQuery()
  const { getCurrentPage } = useCurrentPage()
  const { setOpenCreatePatientModal } = useQueuePatientsStore()
  const { setOpenQueuePatientsFilterModal, clearQueuePatientsSelectedFilters } =
    useQueuePatientsStore()
  const { hasAtLeastOneFilter, deleteAllQueuePatientsQueryParams } =
    useQueuePatientsQueryParams()

  const isRefreshing = () => {
    if (getCurrentPage() === '/queues') {
      return getQueuesIsRefetching || getQueuesIsLoading
    }

    if (getCurrentPage() === '/queues/patients') {
      return getQueuePatientsIsRefetching || getQueuePatientsIsLoading
    }

    return false
  }

  const handleAddButtonClick = () => {
    setOpenCreatePatientModal(true)
  }

  const handleFilterButtonClick = () => {
    setOpenQueuePatientsFilterModal(true)
  }

  const handleClearAllButtonClick = () => {
    deleteAllQueuePatientsQueryParams('filters')
    clearQueuePatientsSelectedFilters()
  }

  const handleRefreshClick = () => {
    if (getCurrentPage() === '/queues') {
      refetchQueues()
    }

    if (getCurrentPage() === '/queues/patients') {
      refetchQueuePatients()
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          columnGap: '16px',
        }}
      >
        <QueuePatientsSearchInput />
        <IconButton
          data-testid={'queue_patients_add_button'}
          onClick={handleAddButtonClick}
        >
          <AddIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          columnGap: '16px',
        }}
      >
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <RefreshButton
              data-testid={'queue_patients_refresh_button'}
              onClick={handleRefreshClick}
              isRefreshing={isRefreshing()}
            />
            <IconButton
              data-testid={'queue_patients_filter_button'}
              onClick={handleFilterButtonClick}
            >
              <FilterListIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {hasAtLeastOneFilter() ? (
              <Button
                onClick={handleClearAllButtonClick}
                sx={{
                  color: 'rgba(0, 0, 0, 0.38)',
                  fontSize: '10px',
                  fontWeight: '700',
                }}
              >
                CLEAR FILTERS
              </Button>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
