import { useCallback } from 'react'

import {
  CreatePatientService,
  CreatePatientServiceResponse,
  GetPatientPainAndFunctionV2Service,
  GetPatientPainAndFunctionV2ServiceResponse,
  GetPatientStatusDefinitionsService,
  UpdatePatientService,
  UpdatePatientServiceResponse,
  UpdatePatientStatusService,
  UpdatePatientStatusServiceResponse,
  CreatePatientManuallyService,
  CreatePatientManuallyServiceResponse,
  GetPatientGoalsService,
  GetPatientV2Service,
  GetPatientV2ServiceResponse,
  CreatePatientPainAndFunctionService,
  CreatePatientPainAndFunctionServiceResponse,
} from 'src/features/patients/infrastructure'
import { useCompassApiClient } from 'src/features/shared/infrastructure'
import * as settings from 'src/config/settings'

export const usePatientsService = () => {
  const { getCompassApiClient } = useCompassApiClient()

  const getPatientV2: GetPatientV2Service = useCallback(
    async (patientId) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.get<GetPatientV2ServiceResponse>(
        settings.COMPASS_API_BASE_URL + `/patients/${patientId}?version=2`
      )
    },
    [getCompassApiClient]
  )

  const getPatientPainAndFunctionV2: GetPatientPainAndFunctionV2Service =
    useCallback(
      async (patientId) => {
        const compassClient = await getCompassApiClient()
        return await compassClient.get<GetPatientPainAndFunctionV2ServiceResponse>(
          settings.COMPASS_API_BASE_URL +
            `/patients/${patientId}/painAndFunction`,
          {
            params: {
              version: 2,
            },
          }
        )
      },
      [getCompassApiClient]
    )

  const createPatientPainAndFunction: CreatePatientPainAndFunctionService =
    useCallback(
      async ({ patientId, payload }) => {
        const compassClient = await getCompassApiClient()
        return await compassClient.post<CreatePatientPainAndFunctionServiceResponse>(
          settings.COMPASS_API_BASE_URL +
            `/patients/${patientId}/painAndFunction`,
          payload
        )
      },
      [getCompassApiClient]
    )

  const updatePatient: UpdatePatientService = useCallback(
    async ({ patientId, payload }) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.put<UpdatePatientServiceResponse>(
        settings.COMPASS_API_BASE_URL + `/patients/${patientId}`,
        payload
      )
    },
    [getCompassApiClient]
  )

  const updatePatientStatus: UpdatePatientStatusService = useCallback(
    async ({ patientId, payload }) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.post<UpdatePatientStatusServiceResponse>(
        settings.COMPASS_API_BASE_URL + `/patients/${patientId}/status`,
        payload
      )
    },
    [getCompassApiClient]
  )

  const createPatient: CreatePatientService = useCallback(
    async (payload) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.post<CreatePatientServiceResponse>(
        settings.COMPASS_API_BASE_URL + `/patients`,
        payload
      )
    },
    [getCompassApiClient]
  )

  const createPatientManually: CreatePatientManuallyService = useCallback(
    async (payload) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.post<CreatePatientManuallyServiceResponse>(
        settings.COMPASS_API_BASE_URL + `/patients?manualEntry=true`,
        payload
      )
    },
    [getCompassApiClient]
  )

  const getPatientStatusDefinitions: GetPatientStatusDefinitionsService =
    useCallback(async () => {
      const compassClient = await getCompassApiClient()
      return await compassClient.get(
        settings.COMPASS_API_BASE_URL + `/patients/status/definitions`
      )
    }, [getCompassApiClient])

  const getPatientGoals: GetPatientGoalsService = useCallback(async () => {
    const compassClient = await getCompassApiClient()
    return await compassClient.get(
      settings.COMPASS_API_BASE_URL + `/patients/goals/definitions`
    )
  }, [getCompassApiClient])

  return {
    createPatient,
    createPatientManually,
    getPatientV2,
    getPatientPainAndFunctionV2,
    createPatientPainAndFunction,
    updatePatient,
    updatePatientStatus,
    getPatientStatusDefinitions,
    getPatientGoals,
  }
}
