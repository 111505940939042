import { useCallback, useMemo } from 'react'
import { groupObjectBy } from 'src/features/shared/utils'

import {
  useGetOverdueTasksByUserIdInfiniteQuery,
  useGetTodayTasksByUserIdInfiniteQuery,
  useGetUrgentTasksByUserIdInfiniteQuery,
  useGetDoneTasksByUserIdInfiniteQuery,
  useGetUpcomingTasksByUserIdInfiniteQuery,
  useGetOverdueTasksByUserIdQuery,
  useGetTodayTasksByUserIdQuery,
} from 'src/features/tasks/presentation'

type RefreshTasksProps = {
  upcoming?: boolean
  today?: boolean
  urgent?: boolean
  overdue?: boolean
  done?: boolean
}

export const useUserSidebarTasks = () => {
  const {
    infiniteDoneTasksData,
    infiniteDoneTasksIsLoading,
    infiniteDoneTasksIsFetchingNextPage,
    fetchNextPageInfiniteDoneTasks,
    hasNextPageInfiniteDoneTasks,
    infiniteDoneTasksIsFetching,
    refetchInfiniteDoneTasks,
  } = useGetDoneTasksByUserIdInfiniteQuery()
  const {
    infiniteTodayTasksData,
    infiniteTodayTasksIsLoading,
    infiniteTodayTasksIsFetchingNextPage,
    fetchNextPageInfiniteTodayTasks,
    hasNextPageInfiniteTodayTasks,
    infiniteTodayTasksIsFetching,
    refetchInfiniteTodayTasks,
  } = useGetTodayTasksByUserIdInfiniteQuery()
  const {
    infiniteUrgentTasksData,
    infiniteUrgentTasksIsLoading,
    infiniteUrgentTasksIsFetchingNextPage,
    fetchNextPageInfiniteUrgentTasks,
    hasNextPageInfiniteUrgentTasks,
    infiniteUrgentTasksIsFetching,
    refetchInfiniteUrgentTasks,
  } = useGetUrgentTasksByUserIdInfiniteQuery()
  const {
    infiniteOverdueTasksData,
    infiniteOverdueTasksIsLoading,
    infiniteOverdueTasksIsFetchingNextPage,
    fetchNextPageInfiniteOverdueTasks,
    hasNextPageInfiniteOverdueTasks,
    infiniteOverdueTasksIsFetching,
    refetchInfiniteOverdueTasks,
  } = useGetOverdueTasksByUserIdInfiniteQuery()
  const {
    infiniteUpcomingTasksData,
    infiniteUpcomingTasksIsLoading,
    infiniteUpcomingTasksIsFetchingNextPage,
    fetchNextPageInfiniteUpcomingTasks,
    hasNextPageInfiniteUpcomingTasks,
    infiniteUpcomingTasksIsFetching,
    refetchInfiniteUpcomingTasks,
  } = useGetUpcomingTasksByUserIdInfiniteQuery()

  const {
    userOverdueTasks,
    refetchUserOverdueTasks,
    userOverdueTasksIsLoading,
    userOverdueTasksIsFetching,
  } = useGetOverdueTasksByUserIdQuery({
    params: { paginated: false },
  })

  const {
    userTodayTasks,
    refetchUserTodayTasks,
    userTodayTasksIsLoading,
    userTodayTasksIsFetching,
  } = useGetTodayTasksByUserIdQuery({
    params: { paginated: false },
  })

  const overdueTasksGroupedByPatient = groupObjectBy(
    userOverdueTasks,
    ({ patientId }) => patientId
  )
  const extractLastName = (fullName: string) => {
    const nameParts = fullName.trim().split(' ')
    return nameParts.length > 1 ? nameParts[nameParts.length - 1] : nameParts[0]
  }

  const overdueTasksGroupedByPatientSortedByPatientNamePatientsId =
    useMemo(() => {
      return Object.values(overdueTasksGroupedByPatient)
        .sort((a, b) => {
          const aLastName = extractLastName(a[0].patientName)
          const bLastName = extractLastName(b[0].patientName)

          if (aLastName < bLastName) {
            return -1
          } else if (aLastName > bLastName) {
            return 1
          }
          return 0
        })
        .map((tasks) => tasks[0].patientId)
    }, [overdueTasksGroupedByPatient])

  const todaysTasksGroupedByPatient = groupObjectBy(
    userTodayTasks,
    ({ patientId }) => patientId
  )

  const todaysTasksGroupedByPatientSortedByPatientNamePatientsId =
    useMemo(() => {
      return Object.values(todaysTasksGroupedByPatient)
        .sort((a, b) => {
          const aLastName = extractLastName(a[0].patientName)
          const bLastName = extractLastName(b[0].patientName)

          if (aLastName < bLastName) {
            return -1
          } else if (aLastName > bLastName) {
            return 1
          }
          return 0
        })
        .map((tasks) => tasks[0].patientId)
    }, [todaysTasksGroupedByPatient])

  const refreshUserSidebarTasks = useCallback(
    (
      { upcoming, today, urgent, overdue, done }: RefreshTasksProps = {
        upcoming: true,
        today: true,
        urgent: true,
        overdue: true,
        done: true,
      }
    ) => {
      if (today) {
        refetchUserTodayTasks()
      }
      if (urgent) {
        refetchInfiniteUrgentTasks()
      }
      if (overdue) {
        refetchInfiniteOverdueTasks()
        refetchUserOverdueTasks()
      }
      if (done) {
        refetchInfiniteDoneTasks()
      }
      if (upcoming) {
        refetchInfiniteUpcomingTasks()
      }
    },
    [
      refetchUserTodayTasks,
      refetchInfiniteDoneTasks,
      refetchInfiniteOverdueTasks,
      refetchUserOverdueTasks,
      refetchInfiniteTodayTasks,
      refetchInfiniteUpcomingTasks,
      refetchInfiniteUrgentTasks,
    ]
  )

  const isRefreshingUserTasks = useMemo(() => {
    return (
      userTodayTasksIsLoading ||
      userTodayTasksIsFetching ||
      infiniteDoneTasksIsLoading ||
      infiniteDoneTasksIsFetching ||
      infiniteTodayTasksIsLoading ||
      infiniteTodayTasksIsFetching ||
      infiniteUrgentTasksIsLoading ||
      infiniteUrgentTasksIsFetching ||
      infiniteOverdueTasksIsLoading ||
      infiniteOverdueTasksIsFetching ||
      infiniteUpcomingTasksIsLoading ||
      infiniteUpcomingTasksIsFetching ||
      userOverdueTasksIsFetching
    )
  }, [
    userTodayTasksIsLoading,
    userTodayTasksIsFetching,
    infiniteDoneTasksIsLoading,
    infiniteDoneTasksIsFetching,
    infiniteTodayTasksIsLoading,
    infiniteTodayTasksIsFetching,
    infiniteUrgentTasksIsLoading,
    infiniteUrgentTasksIsFetching,
    infiniteOverdueTasksIsLoading,
    infiniteOverdueTasksIsFetching,
    infiniteUpcomingTasksIsLoading,
    infiniteUpcomingTasksIsFetching,
    userOverdueTasksIsFetching,
  ])

  return {
    fetchNextPageInfiniteDoneTasks,
    fetchNextPageInfiniteOverdueTasks,
    fetchNextPageInfiniteTodayTasks,
    fetchNextPageInfiniteUpcomingTasks,
    fetchNextPageInfiniteUrgentTasks,
    hasNextPageInfiniteDoneTasks,
    hasNextPageInfiniteOverdueTasks,
    hasNextPageInfiniteTodayTasks,
    hasNextPageInfiniteUpcomingTasks,
    hasNextPageInfiniteUrgentTasks,
    infiniteDoneTasksData,
    infiniteDoneTasksIsFetching,
    infiniteDoneTasksIsFetchingNextPage,
    infiniteDoneTasksIsLoading,
    infiniteOverdueTasksData,
    infiniteOverdueTasksIsFetching,
    infiniteOverdueTasksIsFetchingNextPage,
    infiniteOverdueTasksIsLoading,
    infiniteTodayTasksData,
    infiniteTodayTasksIsFetching,
    infiniteTodayTasksIsFetchingNextPage,
    infiniteTodayTasksIsLoading,
    infiniteUpcomingTasksData,
    infiniteUpcomingTasksIsFetching,
    infiniteUpcomingTasksIsFetchingNextPage,
    infiniteUpcomingTasksIsLoading,
    infiniteUrgentTasksData,
    infiniteUrgentTasksIsFetching,
    infiniteUrgentTasksIsFetchingNextPage,
    infiniteUrgentTasksIsLoading,
    isRefreshingUserTasks,
    refetchInfiniteDoneTasks,
    refetchInfiniteOverdueTasks,
    refetchInfiniteTodayTasks,
    refetchInfiniteUpcomingTasks,
    refetchInfiniteUrgentTasks,
    refetchUserOverdueTasks,
    refreshUserSidebarTasks,
    userOverdueTasks,
    userOverdueTasksIsFetching,
    userOverdueTasksIsLoading,
    overdueTasksGroupedByPatient,
    overdueTasksGroupedByPatientSortedByPatientNamePatientsId,
    todaysTasksGroupedByPatient,
    todaysTasksGroupedByPatientSortedByPatientNamePatientsId,
    userTodayTasksIsLoading,
  }
}
