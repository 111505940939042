import { Stack, Button, Icon } from '@mui/material'
import { FC } from 'react'

type MSKFormPaginationProps = {
  onNext?: () => void
  onPrevious?: () => void
  onEdit?: () => void
  showEdit?: boolean
  showNext?: boolean
  showPrevious?: boolean
  editText?: string
  nextText?: string
  previousText?: string
  showPreviousStartIcon?: boolean
  showNextEndIcon?: boolean
  disabledNext?: boolean
  disabledPrevious?: boolean
  disabledEdit?: boolean
}

export const MSKFormPagination: FC<MSKFormPaginationProps> = ({
  onNext,
  onPrevious,
  onEdit,
  showEdit = false,
  showNext = true,
  showPrevious = true,
  editText = 'EDIT',
  nextText = 'NEXT',
  previousText = 'BACK',
  showPreviousStartIcon = true,
  showNextEndIcon = true,
  disabledNext = false,
  disabledPrevious = false,
  disabledEdit = false,
}) => {
  return (
    <Stack direction={'row'} justifyContent={'end'}>
      <Stack direction={'row'} columnGap={'16px'}>
        {showEdit ? (
          <Button
            data-testid={'msk_form_pagination_edit'}
            variant={'outlined'}
            color={'secondary'}
            startIcon={<Icon>edit</Icon>}
            onClick={onEdit}
            disabled={disabledEdit}
            sx={{
              '& .MuiIcon-root': {
                color: disabledEdit ? 'gray' : 'secondary.main',
              },
            }}
          >
            {editText}
          </Button>
        ) : null}
        {showPrevious ? (
          <Button
            data-testid={'msk_form_pagination_back'}
            variant={'outlined'}
            color={'secondary'}
            startIcon={showPreviousStartIcon ? <Icon>arrow_back</Icon> : null}
            onClick={onPrevious}
            disabled={disabledPrevious}
            sx={{
              '& .MuiIcon-root': {
                color: disabledPrevious ? 'gray' : 'secondary.main',
              },
            }}
          >
            {previousText}
          </Button>
        ) : null}
        {showNext ? (
          <Button
            data-testid={'msk_form_pagination_next'}
            variant={'contained'}
            color={'secondary'}
            type={'submit'}
            endIcon={showNextEndIcon ? <Icon>arrow_forward</Icon> : null}
            onClick={onNext}
            disabled={disabledNext}
          >
            {nextText}
          </Button>
        ) : null}
      </Stack>
    </Stack>
  )
}
