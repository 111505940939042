import { FC } from 'react'
import * as yup from 'yup'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  styled,
  Typography,
} from '@mui/material'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

import {
  BackdropLoader,
  TagChip,
  TCCopyToClipboard,
  useTooltipsState,
} from 'src/features/shared/presentation'
import { useCreatePatient } from 'src/features/patients/presentation'
import { useEligibilityPatientStore } from 'src/features/shared/infrastructure'
import { CreatePatientArgs } from 'src/features/patients/domain'
import { mapPatientToCreatePatientArgs } from 'src/features/patients/adapters'
import { REFERRAL_SOURCES } from 'src/features/shared/constants'
import { mapToControlledSelectItem } from 'src/features/shared/adapters'

const EligiblePatientModalFormAttrKey = styled(Typography)(() => ({
  color: 'rgba(0, 0, 0, 0.38)',
  fontSize: '14px',
  letterSpacing: '0.17px',
}))

type EligiblePatientsFormFields = {
  referralSource: string
}

const eligibilityPatientFormSchema: yup.Schema<EligiblePatientsFormFields> = yup
  .object()
  .shape({
    referralSource: yup.string().required('Required'),
  })

export const EligiblePatientFormModal: FC = () => {
  const {
    tooltipState,
    getTooltipCopyClickHandler,
    getTooltipCopiedTooltipCloseHandler,
  } = useTooltipsState(['phone', 'phone2', 'mbi'])

  const {
    selectedEligiblePatient,
    selectedEligiblePatientRow,
    openEligiblePatientFormModal,
    setOpenEligiblePatientFormModal,
  } = useEligibilityPatientStore()

  const { createPatient, createPatientIsLoading } = useCreatePatient()
  const navigate = useNavigate()

  const formMethods = useForm<EligiblePatientsFormFields>({
    resolver: yupResolver(eligibilityPatientFormSchema),
    defaultValues: {
      referralSource: '',
    },
  })

  const handleModalFormClose = () => {
    setOpenEligiblePatientFormModal(false)
  }

  const handleCancelClick = () => {
    setOpenEligiblePatientFormModal(false)
    formMethods.reset()
  }

  const submitHandler = (data: EligiblePatientsFormFields) => {
    if (!selectedEligiblePatient) {
      // TODO: handle error
      return
    }

    const createPatientArgs: CreatePatientArgs = {
      ...mapPatientToCreatePatientArgs(selectedEligiblePatient),
      ...data,
    }

    createPatient(createPatientArgs, {
      onSuccess: (data) => {
        toast.success(`Patient succesfully created!`)
        setOpenEligiblePatientFormModal(false)
        navigate(`/patients/${data.patientId}`)
      },
      onError: (error) => {
        toast.error(`Patient creation failed! ${error.message}`)
      },
    })
  }

  return (
    <Dialog
      open={openEligiblePatientFormModal}
      onClose={handleModalFormClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '600px',
        },
      }}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(submitHandler)}>
          <DialogContent>
            <Box
              sx={{
                padding: '24px 16px',
                display: 'flex',
                flexDirection: 'column',
                rowGap: '16px',
                position: 'relative',
              }}
            >
              <Stack direction={'row'} gap={'24px'} alignItems={'center'}>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: '24px',
                  }}
                >
                  {selectedEligiblePatientRow?.name}
                </Typography>
                {selectedEligiblePatient?.mbi ? (
                  <Stack direction={'row'} gap={'4px'} alignItems={'center'}>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: '16px',
                        color: 'rgba(0, 0, 0, 0.6)',
                      }}
                    >
                      {selectedEligiblePatient.mbi}
                    </Typography>
                    <TCCopyToClipboard
                      value={selectedEligiblePatient.mbi}
                      tooltipOpen={tooltipState['mbi']}
                      onCopy={getTooltipCopyClickHandler('mbi')}
                      onTooltipClose={getTooltipCopiedTooltipCloseHandler(
                        'mbi'
                      )}
                    />
                  </Stack>
                ) : null}
              </Stack>
              <Stack direction={'row'} gap={'32px'}>
                {selectedEligiblePatient?.dob ? (
                  <Stack direction={'row'} gap={'8px'}>
                    <EligiblePatientModalFormAttrKey>
                      DOB
                    </EligiblePatientModalFormAttrKey>
                    <Typography>{selectedEligiblePatient.dob}</Typography>
                  </Stack>
                ) : null}
                {selectedEligiblePatient?.primaryPhone ? (
                  <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
                    <EligiblePatientModalFormAttrKey>
                      Phone 1
                    </EligiblePatientModalFormAttrKey>
                    <Stack direction={'row'} gap={'4px'}>
                      <Typography>
                        {selectedEligiblePatient.primaryPhone}
                      </Typography>
                      <TCCopyToClipboard
                        value={selectedEligiblePatient.primaryPhone}
                        tooltipOpen={tooltipState['phone']}
                        onCopy={getTooltipCopyClickHandler('phone')}
                        onTooltipClose={getTooltipCopiedTooltipCloseHandler(
                          'phone'
                        )}
                      />
                    </Stack>
                  </Stack>
                ) : null}
                {selectedEligiblePatient?.secondaryPhone ? (
                  <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
                    <EligiblePatientModalFormAttrKey>
                      Phone 2
                    </EligiblePatientModalFormAttrKey>
                    <Stack direction={'row'} gap={'4px'}>
                      <Typography>
                        {selectedEligiblePatient?.secondaryPhone}
                      </Typography>
                      <TCCopyToClipboard
                        value={selectedEligiblePatient?.secondaryPhone}
                        tooltipOpen={tooltipState['phone2']}
                        onCopy={getTooltipCopyClickHandler('phone2')}
                        onTooltipClose={getTooltipCopiedTooltipCloseHandler(
                          'phone2'
                        )}
                      />
                    </Stack>
                  </Stack>
                ) : null}
                {selectedEligiblePatient?.doNotCall ? (
                  <TagChip label="DNC" />
                ) : null}
              </Stack>
            </Box>

            <Controller
              name="referralSource"
              control={formMethods.control}
              render={({ field, fieldState }) => (
                <FormControl fullWidth>
                  <InputLabel
                    id={'eligibility-patient-form-referral-source-label'}
                  >
                    Referral Source
                  </InputLabel>
                  <Select
                    labelId={'eligibility-patient-form-referral-source-label'}
                    id={'eligibility-patient-form-referral-source'}
                    label={'Referral Source'}
                    value={field.value}
                    onChange={(event) => {
                      field.onChange(event.target.value)
                    }}
                  >
                    {REFERRAL_SOURCES.map(mapToControlledSelectItem).map(
                      (referralSource) => (
                        <MenuItem
                          key={referralSource.id}
                          value={referralSource.value}
                        >
                          {referralSource.label}
                        </MenuItem>
                      )
                    )}
                  </Select>
                  {fieldState.error ? (
                    <FormHelperText error={!!fieldState.error}>
                      {fieldState.error ? fieldState.error.message : ''}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={handleCancelClick}>
              CANCEL
            </Button>
            <Button type="submit" color="secondary" variant="contained">
              SAVE
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
      <BackdropLoader open={createPatientIsLoading} />
    </Dialog>
  )
}
