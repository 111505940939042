import { QueryClientConfig } from '@tanstack/react-query'

import * as settings from 'src/config/settings'

export const reactQueryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * settings.GLOBAL_REACT_QUERY_STALE_TIME_MINUTES,
      useErrorBoundary: true,
    },
  },
}
