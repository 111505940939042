export const NODE_ENV = process.env.NODE_ENV

export const REACT_APP_ENV = process.env.REACT_APP_ENV

// UTILS

export const HELP_URL = process.env.REACT_APP_HELP_URL

export const IDLE_TIMEOUT_MINUTES = Number(
  process.env.REACT_APP_IDLE_TIMEOUT_MINUTES
)

export const PHQ9_FORM_URL = process.env.REACT_APP_PHQ9_FORM_URL

export const MENDA_ASSESSMENT_URL = process.env.REACT_APP_MENDA_FORM_URL

export const NYMBL_ASSESSMENT_URL = process.env.REACT_APP_NYMBL_FORM_URL

// UI

// REACT QUERY

export const GLOBAL_REACT_QUERY_STALE_TIME_MINUTES = Number(
  process.env.REACT_APP_GLOBAL_REACT_QUERY_STALE_TIME_MINUTES
)

// COMPASS API

export const COMPASS_API_BASE_URL = process.env.REACT_APP_COMPASS_API_BASE_URL

export const COMPASS_API_KEY = process.env.REACT_APP_COMPASS_API_KEY

export const COMPASS_SEARCH_API_BASE_URL =
  process.env.REACT_APP_COMPASS_SEARCH_API_BASE_URL

export const COMPASS_SEARCH_API_KEY =
  process.env.REACT_APP_COMPASS_SEARCH_API_KEY

// PROVIDERS API

export const PROVIDERS_API_KEY = process.env.REACT_APP_PROVIDERS_API_KEY

export const PROVIDERS_API_BASE_URL =
  process.env.REACT_APP_PROVIDERS_API_BASE_URL

// Sentry

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN

// TASKS

export const DEFAULT_TASKS_PER_LOAD = process.env
  .REACT_APP_DEFAULT_TASKS_PER_LOAD
  ? Number(process.env.REACT_APP_DEFAULT_TASKS_PER_LOAD)
  : 10

export const TODAY_TASKS_PER_LOAD = process.env.REACT_APP_TODAY_TASKS_PER_LOAD
  ? Number(process.env.REACT_APP_TODAY_TASKS_PER_LOAD)
  : DEFAULT_TASKS_PER_LOAD

export const URGENT_TASKS_PER_LOAD = process.env.REACT_APP_URGENT_TASKS_PER_LOAD
  ? Number(process.env.REACT_APP_URGENT_TASKS_PER_LOAD)
  : DEFAULT_TASKS_PER_LOAD

export const OVERDUE_TASKS_PER_LOAD = process.env
  .REACT_APP_OVERDUE_TASKS_PER_LOAD
  ? Number(process.env.REACT_APP_OVERDUE_TASKS_PER_LOAD)
  : DEFAULT_TASKS_PER_LOAD

export const DONE_TASKS_PER_LOAD = process.env.REACT_APP_DONE_TASKS_PER_LOAD
  ? Number(process.env.REACT_APP_DONE_TASKS_PER_LOAD)
  : DEFAULT_TASKS_PER_LOAD

export const UPCOMING_TASKS_PER_LOAD = process.env
  .REACT_APP_UPCOMING_TASKS_PER_LOAD
  ? Number(process.env.REACT_APP_UPCOMING_TASKS_PER_LOAD)
  : DEFAULT_TASKS_PER_LOAD

// OKTA

export const OKTA_HOSTED_SIGNIN =
  process.env.REACT_APP_OKTA_HOSTED_SIGNIN === 'true'

// AWS

export const AWS_PROJECT_REGION = process.env.REACT_APP_AWS_REGION

export const AWS_COGNITO_REGION = process.env.REACT_APP_AWS_REGION

export const AWS_COGNITO_IDENTITY_POOL_ID =
  process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID

export const AWS_USER_POOLS_ID = process.env.REACT_APP_AWS_USER_POOLS_ID

export const AWS_USER_POOLS_WEB_CLIENT_ID =
  process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID

export const AWS_OAUTH_DOMAIN = process.env.REACT_APP_AWS_OAUTH_DOMAIN

export const AWS_OAUTH_SCOPE = process.env.REACT_APP_AWS_OAUTH_SCOPE?.split(' ')

export const AWS_OAUTH_REDIRECT_SIGN_IN =
  process.env.REACT_APP_AWS_OAUTH_REDIRECT_SIGN_IN

export const AWS_OAUTH_REDIRECT_SIGN_OUT =
  process.env.REACT_APP_AWS_OAUTH_REDIRECT_SIGN_OUT

// MUIX

export const MUIX_LICENSE_KEY = process.env.REACT_APP_MUIX_LICENSE_KEY || ''

// MOCKS

export const LOGIN_DISABLED = process.env.REACT_APP_LOGIN_DISABLED === 'true'

export const MOCK_BACKEND = process.env.REACT_APP_MOCK_BACKEND == 'true'

export const MOCK_GET_QUEUES_SERVICE =
  process.env.REACT_APP_MOCK_GET_QUEUES_SERVICE === 'true'

export const MOCK_GET_NAVIGATORS_SERVICE =
  process.env.REACT_APP_MOCK_GET_NAVIGATORS_SERVICE === 'true'

export const MOCK_GET_MY_COMPASS_USER_SERVICE =
  process.env.REACT_APP_MOCK_GET_MY_COMPASS_USER_SERVICE === 'true'

export const MOCK_GET_TASKS_BY_USER_ID_SERVICE =
  process.env.REACT_APP_MOCK_GET_TASKS_BY_USER_ID_SERVICE === 'true'

export const MOCK_LOAD_MSK_FORM_SERVICE =
  process.env.REACT_APP_MOCK_LOAD_MSK_FORM_SERVICE === 'true'

export const MOCK_GET_PATHWAYS_SERVICE_RESPONSE =
  process.env.REACT_APP_MOCK_GET_PATHWAYS_SERVICE_RESPONSE === 'true'

export const GET_PATIENTS_STATUS_DEFINITIONS_SERVICE_RESPONSE =
  process.env
    .REACT_APP_MOCK_GET_PATIENTS_STATUS_DEFINITIONS_SERVICE_RESPONSE === 'true'

export const MOCK_GET_PATIENT_SERVICE_RESPONSE =
  process.env.REACT_APP_MOCK_GET_PATIENT_SERVICE_RESPONSE === 'true'

export const MOCK_GET_PATIENT_PAIN_AND_FUNCTION_SERVICE_RESPONSE =
  process.env.REACT_APP_MOCK_GET_PATIENT_PAIN_AND_FUNCTION_SERVICE_RESPONSE ===
  'true'

export const MOCK_GET_PATIENT_NOTES_SERVICE_RESPONSE =
  process.env.REACT_APP_MOCK_GET_PATIENT_NOTES_SERVICE_RESPONSE === 'true'

export const MOCK_GET_ELEGIBLE_PATIENTS_SERVICE_RESPONSE =
  process.env.REACT_APP_MOCK_GET_ELEGIBLE_PATIENTS_SERVICE_RESPONSE === 'true'

export const MOCK_SEARCH_PATIENTS_SERVICE_RESPONSE =
  process.env.REACT_APP_MOCK_SEARCH_PATIENTS_SERVICE_RESPONSE === 'true'

export const MOCK_SEARCH_DUPLICATE_PATIENTS_SERVICE_RESPONSE =
  process.env.REACT_APP_MOCK_SEARCH_DUPLICATE_PATIENTS_SERVICE_RESPONSE ===
  'true'

export const MOCK_GET_QUEUE_PATIENTS_SERVICE_RESPONSE =
  process.env.REACT_APP_MOCK_GET_QUEUE_PATIENTS_SERVICE_RESPONSE === 'true'

export const MOCK_GET_PATIENT_INTAKE_FLOWS_SERVICE_RESPONSE =
  process.env.REACT_APP_MOCK_GET_PATIENT_INTAKE_FLOWS_SERVICE_RESPONSE ===
  'true'

export const MOCK_CREATE_PATIENT_SERVICE_RESPONSE =
  process.env.REACT_APP_MOCK_CREATE_PATIENT_SERVICE_RESPONSE === 'true'

export const MOCK_CREATE_PATIENT_NOTE_SERVICE_RESPONSE =
  process.env.REACT_APP_MOCK_CREATE_PATIENT_NOTE_SERVICE_RESPONSE === 'true'

export const MOCK_UPDATE_PATIENT_STATUS_SERVICE_RESPONSE =
  process.env.REACT_APP_MOCK_UPDATE_PATIENT_STATUS_SERVICE_RESPONSE === 'true'

export const MOCK_PROCESS_MSK_FORM_SERVICE_RESPONSE =
  process.env.REACT_APP_MOCK_PROCESS_MSK_FORM_SERVICE_RESPONSE === 'true'

export const MOCK_CREATE_PATHWAY_SERVICE_RESPONSE =
  process.env.REACT_APP_MOCK_CREATE_PATHWAY_SERVICE_RESPONSE === 'true'

export const MOCK_REASSIGN_PATHWAY_SERVICE_RESPONSE =
  process.env.REACT_APP_MOCK_REASSIGN_PATHWAY_SERVICE_RESPONSE === 'true'

export const MOCK_CREATE_TASK_SERVICE_RESPONSE =
  process.env.REACT_APP_MOCK_CREATE_TASK_SERVICE_RESPONSE === 'true'

export const MOCK_CREATE_ENABLING_THERAPY_SERVICE_RESPONSE =
  process.env.REACT_APP_MOCK_CREATE_ENABLING_THERAPY_SERVICE_RESPONSE === 'true'

export const MOCK_GET_PROVIDERS_SERVICE_RESPONSE =
  process.env.REACT_APP_MOCK_GET_PROVIDERS_SERVICE_RESPONSE === 'true'

export const MOCK_CREATE_PROVIDER_SERVICE_RESPONSE =
  process.env.REACT_APP_MOCK_CREATE_PROVIDER_SERVICE_RESPONSE === 'true'

export const MOCK_GET_PROVIDER_GROUPS_SERVICE_RESPONSE =
  process.env.REACT_APP_MOCK_GET_PROVIDER_GROUPS_SERVICE_RESPONSE === 'true'

export const MOCK_CREATE_PROVIDER_GROUP_SERVICE_RESPONSE =
  process.env.REACT_APP_MOCK_CREATE_PROVIDER_GROUP_SERVICE_RESPONSE === 'true'

export const MOCK_CREATE_REFERRING_PROVIDER_SERVICE_RESPONSE =
  process.env.REACT_APP_MOCK_CREATE_REFERRING_PROVIDER_SERVICE_RESPONSE ===
  'true'

export const MOCK_GET_REFERRING_PROVIDERS_SERVICE_RESPONSE =
  process.env.REACT_APP_MOCK_GET_REFERRING_PROVIDERS_SERVICE_RESPONSE === 'true'

export const MOCK_GET_PLANS_SERVICE_RESPONSE =
  process.env.REACT_APP_MOCK_GET_PLANS_SERVICE_RESPONSE === 'true'

export const MOCK_CREATE_PLAN_SERVICE_RESPONSE =
  process.env.REACT_APP_MOCK_CREATE_PLAN_SERVICE_RESPONSE === 'true'

export const MOCK_GET_TASKS_BY_PATIENT_ID_SERVICE_RESPONSE =
  process.env.REACT_APP_MOCK_GET_TASKS_BY_PATIENT_ID_SERVICE_RESPONSE === 'true'
