import {
  UseMutateAsyncFunction,
  UseMutateFunction,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import {
  CreateDraftNoteReturns,
  CreateDraftNoteArgs,
  CreateDraftNoteError,
} from 'src/features/notes/domain'
import { useNotesUseCases } from 'src/features/notes/notes.service-locator'
import { GET_PATIENT_DRAFT_NOTE } from './use-get-draft-note'

type UseCreateDraftNoteProps = Omit<
  UseMutationOptions<
    CreateDraftNoteReturns,
    CreateDraftNoteError,
    CreateDraftNoteArgs,
    unknown
  >,
  'mutationFn'
>

type UseCreateDraftNote = (props?: UseCreateDraftNoteProps) => {
  createDraftNote: UseMutateFunction<
    CreateDraftNoteReturns,
    CreateDraftNoteError,
    CreateDraftNoteArgs,
    unknown
  >
  createDraftNoteAsync: UseMutateAsyncFunction<
    CreateDraftNoteReturns,
    CreateDraftNoteError,
    CreateDraftNoteArgs,
    unknown
  >
  createDraftNoteIsLoading: boolean
  createDraftNoteIsError: boolean
  createDraftNoteStatus: string
}

export const useCreateDraftNote: UseCreateDraftNote = (props = {}) => {
  const notesUseCases = useNotesUseCases()
  const queryClient = useQueryClient()

  const { onSuccess, onError } = props

  const { mutate, mutateAsync, isLoading, isError, status } = useMutation<
    CreateDraftNoteReturns,
    CreateDraftNoteError,
    CreateDraftNoteArgs,
    unknown
  >({
    mutationFn: (args: CreateDraftNoteArgs) => {
      return notesUseCases.createDraftNote(args)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [GET_PATIENT_DRAFT_NOTE, variables.patientId],
      })
      onSuccess?.(data, variables, context)
    },
    onError: (error, variables, context) => {
      onError?.(error, variables, context)
    },
  })
  return {
    createDraftNote: mutate,
    createDraftNoteAsync: mutateAsync,
    createDraftNoteIsLoading: isLoading,
    createDraftNoteIsError: isError,
    createDraftNoteStatus: status,
  }
}
