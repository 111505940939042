import axios from 'axios'

import { useMSKFormService } from 'src/features/msk/infrastructure'
import { MSKUseCases } from 'src/features/msk/domain'
import {
  mapToLoadMSKFormError,
  mapToLoadMSKFormReturns,
  mapToProcessMSKFormError,
  mapToProcessMSKFormReturns,
  mapToProcessMSKFormServicePayload,
  mapToProcessTraumaMSKFormError,
  mapToProcessTraumaMSKFormReturns,
  mapToProcessTraumaMSKFormServicePayload,
} from 'src/features/msk/adapters'
import { useAxiosErrorHandling } from 'src/features/shared/infrastructure'

export const useMSKFormServiceAdapter = () => {
  const mskFormService = useMSKFormService()
  const { handleAxiosError } = useAxiosErrorHandling()

  const loadMSKForm: MSKUseCases['loadMSKForm'] = async () => {
    try {
      const response = await mskFormService.loadMSKForm()

      return mapToLoadMSKFormReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToLoadMSKFormError)
      }
      throw error
    }
  }

  const processMSKForm: MSKUseCases['processMSKForm'] = async (args) => {
    try {
      const payload = mapToProcessMSKFormServicePayload(args)
      const response = await mskFormService.processMSKForm(payload)

      return mapToProcessMSKFormReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToProcessMSKFormError)
      }
      throw error
    }
  }

  const processTraumaMSKForm: MSKUseCases['processTraumaMSKForm'] = async (
    args
  ) => {
    try {
      const payload = mapToProcessTraumaMSKFormServicePayload(args)
      const response = await mskFormService.processTraumaMSKForm(payload)

      return mapToProcessTraumaMSKFormReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToProcessTraumaMSKFormError)
      }
      throw error
    }
  }

  return {
    loadMSKForm,
    processMSKForm,
    processTraumaMSKForm,
  }
}
