import axios from 'axios'

import { ProvidersUseCases } from 'src/features/providers/domain'
import { useProvidersService } from 'src/features/providers/infrastructure'
import {
  mapToGetProvidersServiceArgs,
  mapToGetProvidersReturns,
  mapToGetProvidersError,
  mapToCreateProviderServiceArgs,
  mapToCreateProviderReturns,
  mapToCreateProviderError,
  mapToGetProviderGroupsError,
  mapToGetProviderGroupsReturns,
  mapToCreateProviderGroupServiceArgs,
  mapToCreateProviderGroupReturns,
  mapToCreateProviderGroupError,
  mapToSearchNPPESServiceArgs,
  mapToSearchNPPESReturns,
  mapToSearchNPPESError,
} from 'src/features/providers/adapters'
import { useAxiosErrorHandling } from 'src/features/shared/infrastructure'

export const useProvidersServiceAdapter = () => {
  const providersService = useProvidersService()
  const { handleAxiosError } = useAxiosErrorHandling()

  const getProviders: ProvidersUseCases['getProviders'] = async (args) => {
    try {
      const serviceArgs = mapToGetProvidersServiceArgs(args)

      const response = await providersService.getProviders(serviceArgs)

      return mapToGetProvidersReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToGetProvidersError)
      }
      throw error
    }
  }

  const createProvider: ProvidersUseCases['createProvider'] = async (args) => {
    try {
      const serviceArgs = mapToCreateProviderServiceArgs(args)

      const response = await providersService.createProvider(serviceArgs)

      return mapToCreateProviderReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToCreateProviderError)
      }
      throw error
    }
  }

  const getProviderGroups: ProvidersUseCases['getProviderGroups'] =
    async () => {
      try {
        const response = await providersService.getProviderGroups()

        return mapToGetProviderGroupsReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToGetProviderGroupsError)
        }
        throw error
      }
    }

  const createProviderGroup: ProvidersUseCases['createProviderGroup'] = async (
    args
  ) => {
    try {
      const serviceArgs = mapToCreateProviderGroupServiceArgs(args)

      const response = await providersService.createProviderGroup(serviceArgs)

      return mapToCreateProviderGroupReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToCreateProviderGroupError)
      }
      throw error
    }
  }

  const searchNPPES: ProvidersUseCases['searchNPPES'] = async (args) => {
    try {
      const serviceArgs = mapToSearchNPPESServiceArgs(args)
      const response = await providersService.searchNPPES(serviceArgs)
      return mapToSearchNPPESReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToSearchNPPESError)
      }
      throw error
    }
  }

  return {
    getProviders,
    createProvider,
    getProviderGroups,
    createProviderGroup,
    searchNPPES,
  }
}
