import { IconButton } from '@mui/material'

import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import * as settings from 'src/config/settings'

import { FC } from 'react'

export const HelpButton: FC = () => {
  const handleHelpClick = () => {
    window.open(settings.HELP_URL, '_blank')
  }

  return (
    <IconButton onClick={handleHelpClick}>
      <HelpOutlineIcon />
    </IconButton>
  )
}
