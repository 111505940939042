import { usePayorsServiceAdapter } from 'src/features/providers/adapters'
import { UsePayorsUseCases } from 'src/features/providers/domain'

type MakeUsePayorsUseCases = () => UsePayorsUseCases

const makeUsePayorsUseCases: MakeUsePayorsUseCases = () => () => {
  const payorsServiceAdapter = usePayorsServiceAdapter()

  return {
    getPayors: payorsServiceAdapter.getPayors,
    createPayor: payorsServiceAdapter.createPayor,
  }
}

export const usePayorsUseCases: UsePayorsUseCases = makeUsePayorsUseCases()
