import { FC } from 'react'
import { IconButton } from '@mui/material'

import RefreshIcon from '@mui/icons-material/Refresh'

import { getRotateIconSx } from 'src/features/shared/utils'
import { Testable } from 'src/features/shared/types'

type RefreshButtonProps = Testable & {
  onClick: () => void
  isRefreshing: boolean
}

export const RefreshButton: FC<RefreshButtonProps> = ({
  onClick,
  isRefreshing,
  ...props
}) => {
  return (
    <IconButton
      data-testid={props['data-testid']}
      onClick={onClick}
      disabled={isRefreshing}
    >
      <RefreshIcon
        sx={{
          ...getRotateIconSx(isRefreshing),
        }}
      />
    </IconButton>
  )
}
