import { styled } from '@mui/material/styles'
import TableRow from '@mui/material/TableRow'

export const TCBodyTableRow = styled(TableRow)(({ theme }) => ({
  transition: 'background-color 0.2s ease-in-out',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.grey['50'],
  },
}))

export const TCBodyTableBusyRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.grey['100'],
  cursor: 'pointer',
}))

export const TCNotEligibleRow = styled(TableRow)(({ theme }) => ({
  transition: 'background-color 0.2s ease-in-out',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.grey['50'],
  },
  '& .MuiTableCell-root': {
    color: 'rgba(0, 0, 0, 0.38)',
  },
}))

export const TCBusyNotEligibleRow = TCBodyTableBusyRow
