import { Dialog, Tabs, Tab, IconButton, Box } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { FC, SyntheticEvent, useState } from 'react'
import { useForm } from 'react-hook-form'

import CloseIcon from '@mui/icons-material/Close'

import {
  PartnersEligibilityFilesTab,
  useEligiblePatientsQueryParams,
} from 'src/features/eligibility/presentation'
import {
  QueuePatientsState,
  useEligibilityPatientStore,
  useQueuePatientsStore,
} from 'src/features/shared/infrastructure'
import {
  ModalDialogTitle,
  WarningModal,
} from 'src/features/shared/presentation'
import {
  CreatePatientManuallyFormWarningModal,
  createPatientFormDefaultValues,
  createPatientFormSchema,
  CreatePatientManuallyForm,
  CreatePatientManuallyFormFields,
} from 'src/features/queues/presentation'

export const CreatePatientModal: FC = () => {
  const {
    openCreatePatientModal,
    setOpenCreatePatientModal,
    createPatientModalSelectedTab,
    setCreatePatientModalSelectedTab,
  } = useQueuePatientsStore()
  const { clearSelectedEligiblePatient } = useEligibilityPatientStore()
  const { deleteAllEligiblePatientsQueryParams } =
    useEligiblePatientsQueryParams()
  const [
    showEligibilityCancelCreatePatientManuallyWarning,
    setShowEligibilityCancelCreatePatientManuallyWarning,
  ] = useState(false)
  const [
    showCloseModalCreatePatientManuallyWarning,
    setShowCloseModalCreatePatientManuallyWarning,
  ] = useState(false)
  const [
    showChangeToCreatePatientManuallyWarning,
    setShowChangeToCreatePatientManuallyWarning,
  ] = useState(false)
  const [patientLookedConfirmed, setPatientLookedConfirmed] = useState(false)

  const createPatientManuallyFormMethods =
    useForm<CreatePatientManuallyFormFields>({
      resolver: yupResolver(createPatientFormSchema),
      defaultValues: createPatientFormDefaultValues,
      mode: 'onBlur',
    })

  const handleChangeToCreatePatientManuallyWarningConfirm = () => {
    setShowChangeToCreatePatientManuallyWarning(false)
    setPatientLookedConfirmed(true)
    setCreatePatientModalSelectedTab('manual')
  }

  const handleChangeToCreatePatientManuallyWarningCancel = () => {
    setShowChangeToCreatePatientManuallyWarning(false)
  }

  const handleEligibilityCancelCreatePatientManuallyWarningConfirm = () => {
    setShowEligibilityCancelCreatePatientManuallyWarning(false)
    setOpenCreatePatientModal(false)
    deleteAllEligiblePatientsQueryParams()
    clearSelectedEligiblePatient()
  }

  const handleEligibilityCancelCreatePatientManuallyWarningCancel = () => {
    setShowEligibilityCancelCreatePatientManuallyWarning(false)
  }

  const handleCloseModalCreatePatientManuallyWarningConfirm = () => {
    setShowCloseModalCreatePatientManuallyWarning(false)
    setOpenCreatePatientModal(false)
  }

  const handleCloseModalCreatePatientManuallyWarningCancel = () => {
    setShowCloseModalCreatePatientManuallyWarning(false)
    setCreatePatientModalSelectedTab('manual')
  }

  const handleModalClose = () => {
    if (createPatientManuallyFormMethods.formState.isDirty) {
      setShowCloseModalCreatePatientManuallyWarning(true)
      return
    }
    setPatientLookedConfirmed(false)
    setOpenCreatePatientModal(false)
  }

  const handleEligibilityFilesCancelClick = () => {
    if (createPatientManuallyFormMethods.formState.isDirty) {
      setShowCloseModalCreatePatientManuallyWarning(true)
      return
    }
    setOpenCreatePatientModal(false)
    deleteAllEligiblePatientsQueryParams()
    clearSelectedEligiblePatient()
  }

  const handleTabChange = (
    event: SyntheticEvent,
    newSelectedTab: QueuePatientsState['createPatientModalSelectedTab']
  ) => {
    if (newSelectedTab === 'manual' && !patientLookedConfirmed) {
      setShowChangeToCreatePatientManuallyWarning(true)
      return
    }
    setCreatePatientModalSelectedTab(newSelectedTab)
  }

  return (
    <Dialog
      onClose={handleModalClose}
      open={openCreatePatientModal}
      sx={{
        zIndex: 2,
        '& .MuiPaper-root.MuiDialog-paper': {
          width: { xs: '1022px' },
          maxWidth: { xs: '1022px' },
          height: { xs: '705px' },
        },
      }}
    >
      <ModalDialogTitle>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          Bring Patient into Compass
          <IconButton onClick={handleModalClose} sx={{ color: 'white' }}>
            <CloseIcon />
          </IconButton>
        </Box>
      </ModalDialogTitle>
      <Tabs
        value={createPatientModalSelectedTab}
        onChange={handleTabChange}
        sx={{
          px: '32px',
        }}
      >
        <Tab label="PARTNER ELIGIBILITY FILES" value={'eligibility'} />
        <Tab label="MANUAL ENTRY" value={'manual'} />
      </Tabs>
      {createPatientModalSelectedTab === 'eligibility' ? (
        <PartnersEligibilityFilesTab
          onCancelClick={handleEligibilityFilesCancelClick}
        />
      ) : null}
      {createPatientModalSelectedTab === 'manual' ? (
        <CreatePatientManuallyForm
          formMethods={createPatientManuallyFormMethods}
        />
      ) : null}
      <CreatePatientManuallyFormWarningModal
        open={showEligibilityCancelCreatePatientManuallyWarning}
        onConfirm={handleEligibilityCancelCreatePatientManuallyWarningConfirm}
        onCancel={handleEligibilityCancelCreatePatientManuallyWarningCancel}
      />
      <CreatePatientManuallyFormWarningModal
        open={showCloseModalCreatePatientManuallyWarning}
        onConfirm={handleCloseModalCreatePatientManuallyWarningConfirm}
        onCancel={handleCloseModalCreatePatientManuallyWarningCancel}
        message={
          'You have a manual entry in progress, closing this modal may result in unsaved entry changes being lost.'
        }
      />
      <WarningModal
        message={
          'Please ensure you have looked for this Patient within Partner Eligibility Files before creating a new record within Manual Entry.'
        }
        open={showChangeToCreatePatientManuallyWarning}
        onConfirm={handleChangeToCreatePatientManuallyWarningConfirm}
        onCancel={handleChangeToCreatePatientManuallyWarningCancel}
        confirmButtonText={'I already looked'}
        cancelButtonText={'Let me try again'}
        confirmButtonPosition={'right'}
        confirmButtonVariant={'contained'}
        cancelButtonVariant={'text'}
      />
    </Dialog>
  )
}
