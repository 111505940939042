import { Box, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { BackdropLoader } from 'src/features/shared/presentation'
import {
  useGetQueuesQuery,
  LaunchpadItem,
  useQueuePatientsQueryParams,
} from 'src/features/queues/presentation'
import {
  useAuth,
  useQueuePatientsStore,
} from 'src/features/shared/infrastructure'
import { Stage } from 'src/features/patients/domain'

export const QueuesPage = () => {
  const navigate = useNavigate()
  const { myCompassUser } = useAuth()
  const { queues, getQueuesIsLoading } = useGetQueuesQuery()
  const { clearQueuePatientsSelectedFilters } = useQueuePatientsStore()
  const { deleteAllQueuePatientsQueryParams } = useQueuePatientsQueryParams()
  const {
    setQueuePatientsSelectedAssignedToFilter,
    setQueuePatientsSelectedStageFilter,
  } = useQueuePatientsStore()

  const assignedToMeItemClickHandler = () => {
    const assignedToFilters: string[] = myCompassUser ? [myCompassUser.id] : []
    const stageFilters: Stage[] = ['Engaged']
    setQueuePatientsSelectedAssignedToFilter(assignedToFilters.join(','))
    setQueuePatientsSelectedStageFilter(stageFilters.join(','))
    const searchParamsFilters = new URLSearchParams({
      assignedTo: assignedToFilters.join(','),
      stage: stageFilters.join(','),
    })
    navigate(`/queues/patients?${searchParamsFilters}`)
  }

  useEffect(() => {
    deleteAllQueuePatientsQueryParams('filters')
    clearQueuePatientsSelectedFilters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const referralRequestsQueue = queues
    ? queues.launchpadQueues.find((queue) => queue.name === 'Referral Requests')
    : null
  return (
    <>
      {queues ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '32px',
            paddingTop: '32px',
          }}
        >
          {queues.myDataQueue ? (
            <Box
              sx={{
                width: '15%',
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  p: '16px 16px 16px 16px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  flex: '0 1 265px',
                  alignItems: 'center',
                  cursor: 'pointer',
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                  backgroundColor: '#FFFFFF',
                  '&:hover': {
                    backgroundColor: '#F5F7FA',
                  },
                }}
                data-testid="my_panel_launchpad_box"
                onClick={assignedToMeItemClickHandler}
              >
                <Box>
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      fontWeight: '600',
                      letterSpacing: '1.5px',
                      color: '#6A7682',
                    }}
                  >
                    {'MY PANEL'}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '24px',
                      fontWeight: '600',
                      letterSpacing: '1.5px',
                      color: '#00454A',
                    }}
                  >
                    {queues.myDataQueue.count}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ) : null}
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              columnGap: '56px',
              rowGap: '32px',
            }}
          >
            {queues.launchpadQueues
              .filter((queue) => queue.name !== 'Referral Requests')
              .map((queue) => (
                <LaunchpadItem key={queue.name} queue={queue} />
              ))}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              columnGap: '56px',
              rowGap: '32px',
            }}
          >
            {referralRequestsQueue ? (
              <LaunchpadItem
                key={referralRequestsQueue.name}
                queue={referralRequestsQueue}
              />
            ) : null}
          </Box>
        </Box>
      ) : null}
      <BackdropLoader open={getQueuesIsLoading} />
    </>
  )
}
