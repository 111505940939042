import { GetPathwaysServiceResponse } from 'src/features/pathways/infrastructure/'

export const getPathwaysServiceResponseMock: GetPathwaysServiceResponse = {
  definitions: [
    {
      id: '1415fb60-058d-42ff-bb0b-c87fbf484f74',
      code: 'SC00P',
      description: 'self care track 00 - phone 1',
      createdAt: '2023-01-19T14:37:19.697Z',
      lastUpdatedAt: '2023-01-19T14:37:19.697Z',
      pathway: 'Self-Care',
      track: 'Self-Care',
      type: 'phone',
    },
    {
      id: '19983631-218e-449c-b60d-4a19cacc9cf6',
      code: 'PT02P',
      description: 'physical therapy track 02 - phone 1',
      createdAt: '2023-01-19T14:37:19.697Z',
      lastUpdatedAt: '2023-01-19T14:37:19.697Z',
      pathway: 'PT',
      track: 'In-Person Appt. date unknown',
      type: 'phone',
    },
    {
      id: '32e9b9f1-a317-496a-a78d-3fa1e3d7a8da',
      code: 'PT01P',
      description: 'physical therapy track 01 - phone 1',
      createdAt: '2023-01-19T14:37:19.697Z',
      lastUpdatedAt: '2023-01-19T14:37:19.697Z',
      pathway: 'PT',
      track: 'In-Person Appt. date known',
      type: 'phone',
    },
    {
      id: '0515474d-5a6b-4434-b769-1e04f33e0a88',
      code: 'OR05P',
      description: 'ortho track 05 - phone repeat every 6 weeks post day 45',
      createdAt: '2023-01-19T14:37:19.697Z',
      lastUpdatedAt: '2023-01-19T14:37:19.697Z',
      pathway: 'Ortho',
      track: 'Resurgens - Injections',
      type: 'phone',
    },
    {
      id: '51b6514d-3ab7-426c-a1ab-15b918883413',
      code: 'OR04P',
      description: 'ortho track 04 - phone 1',
      createdAt: '2023-01-19T14:37:19.697Z',
      lastUpdatedAt: '2023-01-19T14:37:19.697Z',
      pathway: 'Ortho',
      track: 'Resurgens - Non-Surgical',
      type: 'phone',
    },
    {
      id: '51ddf969-d588-4980-9458-cc949fd7485b',
      code: 'OR10P',
      description: 'ortho track 10 - phone 1',
      createdAt: '2023-01-19T14:37:19.697Z',
      lastUpdatedAt: '2023-01-19T14:37:19.697Z',
      pathway: 'Ortho',
      track: 'Resurgens - Post-Op Only',
      type: 'phone',
    },
    {
      id: '90c33df4-2d9b-43bb-90af-4ae604839915',
      code: 'OR07P',
      description: 'ortho track 07 - phone repeat every 6 weeks post day 45',
      createdAt: '2023-01-19T14:37:19.697Z',
      lastUpdatedAt: '2023-01-19T14:37:19.697Z',
      pathway: 'Ortho',
      track: 'Non-Resurgens - Injections',
      type: 'phone',
    },
    {
      id: 'af9ca2c6-eb52-4831-a2f1-9951afd46cf9',
      code: 'OR06P',
      description: 'ortho track 06 - phone repeat every 6 weeks post day 45',
      createdAt: '2023-01-19T14:37:19.697Z',
      lastUpdatedAt: '2023-01-19T14:37:19.697Z',
      pathway: 'Ortho',
      track: 'Non-Resurgens - Non-Surgical',
      type: 'phone',
    },
    {
      id: '260dff25-4a95-4f76-9148-137f24e553b2',
      code: 'OR11P',
      description: 'ortho track 11 - phone 1',
      createdAt: '2023-01-19T14:37:19.697Z',
      lastUpdatedAt: '2023-01-19T14:37:19.697Z',
      pathway: 'Ortho',
      track: 'Non-Resurgens - Post-Op Only',
      type: 'phone',
    },
    {
      id: '46d3f27e-23fa-4d69-b7ba-f430eab74f4f',
      code: 'OR08P',
      description: 'ortho track 08 - phone 1',
      createdAt: '2023-06-07T18:47:45.826Z',
      lastUpdatedAt: '2023-06-07T18:47:45.826Z',
      pathway: 'Ortho',
      track: 'Resurgens - Surgical',
      type: 'phone',
    },
    {
      id: '04543bc8-4071-46c0-95c2-6dce35c7c994',
      code: 'OR09P',
      description: 'ortho track 09 - phone 1',
      createdAt: '2023-06-07T18:47:45.826Z',
      lastUpdatedAt: '2023-06-07T18:47:45.826Z',
      pathway: 'Ortho',
      track: 'Non-Resurgens - Surgical',
      type: 'phone',
    },
  ],
  pathwayTypes: {
    ortho: ['Surgeon', 'Physiatry', 'APP (PA or NP)'],
    pt: ['Traditional', 'McKenzie', 'CHT', 'Balance'],
    'self-care': ['Self-Care'],
  },
}
