import { Box, Divider, Stack, Typography } from '@mui/material'
import { FieldPath, FieldValues, useWatch } from 'react-hook-form'
import { FC } from 'react'

import { filterNoneValueOptions } from 'src/features/notes/adapters'
import {
  ControlledSelectField,
  ControlledTextField,
  VISIT_OUTCOMES,
  YES_NO_OPTIONS,
  INJECTION_TYPES,
  MISSED_REASONS,
} from 'src/features/shared/presentation'
import {
  NOTE_FIELD_LABEL,
  noteFieldLabelDefaultSx,
} from 'src/features/notes/presentation'
import { mapToControlledSelectItem } from 'src/features/shared/adapters'
import { NoteData } from 'src/features/notes/domain'

export type OrthoTouchpointQuestionsFormV2Values = Pick<
  NoteData,
  | 'agreeYouUnderstandCondition'
  | 'agreeYouUnderstandTreatmentOptions'
  | 'completedAppointment'
  | 'reasonForMissingAppointment'
  | 'detailsOfMissedAppointment'
  | 'appointmentRescheduled'
  | 'outcomeOfVisit'
  | 'outcomeNote'
  | 'typeOfInjection'
  | 'additionalQuestionsOrConcerns'
  | 'sdmText'
  | 'sdmPatientUnderstandsCondition'
  | 'sdmClinicalBelievesPatientUnderstandsCondition'
  | 'injectionAtCurrentVisit'
  | 'satisfiedWithInjectionResults'
  | 'patientGoals'
  | 'reasonForRefusal'
>

type OrthoTouchpointAdditionalQuestionsFormV2Props = {
  completedAppointmentFieldName?: FieldPath<FieldValues>
  outcomeOfVisitFieldName?: FieldPath<FieldValues>
  additionalQuestionsOrConcernsFieldName?: FieldPath<FieldValues>
  typeOfInjectionFieldName?: FieldPath<FieldValues>
  reasonForMissingAppointmentFieldName?: FieldPath<FieldValues>
  detailsOfMissedAppointmentFieldName?: FieldPath<FieldValues>
  appointmentRescheduledFieldName?: FieldPath<FieldValues>
  injectionAtCurrentVisitFieldName?: FieldPath<FieldValues>
  satisfiedWithInjectionResultsFieldName?: FieldPath<FieldValues>
  completedAppointmentRequired?: boolean
  outcomeOfVisitRequired?: boolean
  typeOfInjectionRequired?: boolean
  additionalQuestionsOrConcernsRequired?: boolean
  reasonForMissingAppointmentRequired?: boolean
  detailsOfMissedAppointmentRequired?: boolean
  appointmentRescheduledRequired?: boolean
  satisfiedWithInjectionResultsRequired?: boolean
}

export const OrthoTouchpointAdditionalQuestionsFormV2: FC<
  OrthoTouchpointAdditionalQuestionsFormV2Props
> = ({
  completedAppointmentRequired,
  outcomeOfVisitRequired,
  additionalQuestionsOrConcernsRequired,
  typeOfInjectionRequired,
  reasonForMissingAppointmentRequired,
  detailsOfMissedAppointmentRequired,
  appointmentRescheduledRequired,
  completedAppointmentFieldName = 'completedAppointment',
  outcomeOfVisitFieldName = 'outcomeOfVisit',
  additionalQuestionsOrConcernsFieldName = 'additionalQuestionsOrConcerns',
  typeOfInjectionFieldName = 'typeOfInjection',
  reasonForMissingAppointmentFieldName = 'reasonForMissingAppointment',
  detailsOfMissedAppointmentFieldName = 'detailsOfMissedAppointment',
  appointmentRescheduledFieldName = 'appointmentRescheduled',
  injectionAtCurrentVisitFieldName = 'injectionAtCurrentVisit',
  satisfiedWithInjectionResultsFieldName = 'satisfiedWithInjectionResults',
}) => {
  const injectionAtCurrentVisitWatcher = useWatch().injectionAtCurrentVisit

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: '8px',
          width: 170,
          whiteSpace: 'nowrap',
          flexDirection: 'column',
        }}
      >
        <Divider />
        <Typography pb={'16px'} color={'grey'}>
          Assessment Questions
        </Typography>
      </Box>
      <Stack direction={'column'} rowGap={'8px'}>
        <ControlledSelectField
          name={completedAppointmentFieldName}
          label={NOTE_FIELD_LABEL.completedAppointment}
          items={filterNoneValueOptions(YES_NO_OPTIONS).map(
            mapToControlledSelectItem
          )}
          selectSx={{ width: '88px' }}
          formLabelSx={noteFieldLabelDefaultSx}
          required={completedAppointmentRequired}
        />

        {reasonForMissingAppointmentRequired ? (
          <ControlledSelectField
            name={reasonForMissingAppointmentFieldName}
            label={NOTE_FIELD_LABEL.reasonForMissingAppointment}
            labelComponent={'inputLabel'}
            items={filterNoneValueOptions(MISSED_REASONS)
              .filter((reason) => reason !== 'Co-pay')
              .map(mapToControlledSelectItem)}
            required={reasonForMissingAppointmentRequired}
            formControlProps={{
              size: 'small',
            }}
            formControlSx={{
              width: '340px',
            }}
          />
        ) : null}
        {detailsOfMissedAppointmentRequired ? (
          <ControlledTextField
            name={detailsOfMissedAppointmentFieldName}
            required={detailsOfMissedAppointmentRequired}
            textFieldProps={{
              label: NOTE_FIELD_LABEL.detailsOfMissedAppointment,
            }}
            formControlSx={{ width: '668px' }}
          />
        ) : null}
        {appointmentRescheduledRequired ? (
          <ControlledSelectField
            name={appointmentRescheduledFieldName}
            label={NOTE_FIELD_LABEL.appointmentRescheduled}
            labelComponent={'inputLabel'}
            items={filterNoneValueOptions(YES_NO_OPTIONS).map(
              mapToControlledSelectItem
            )}
            required={appointmentRescheduledRequired}
            formControlProps={{
              size: 'small',
            }}
            formControlSx={{
              width: '340px',
            }}
          />
        ) : null}

        <ControlledSelectField
          name={outcomeOfVisitFieldName}
          label={NOTE_FIELD_LABEL.outcomeOfVisit}
          items={filterNoneValueOptions(VISIT_OUTCOMES).map(
            mapToControlledSelectItem
          )}
          selectSx={{ width: '323px' }}
          placeholderItem="None"
          formLabelSx={noteFieldLabelDefaultSx}
          required={outcomeOfVisitRequired}
        />

        <ControlledSelectField
          name={injectionAtCurrentVisitFieldName}
          label={NOTE_FIELD_LABEL.injectionAtCurrentVisit}
          items={filterNoneValueOptions(YES_NO_OPTIONS).map(
            mapToControlledSelectItem
          )}
          selectSx={{ width: '88px' }}
          formLabelSx={noteFieldLabelDefaultSx}
        />
        {injectionAtCurrentVisitWatcher == 'Yes' ? (
          <ControlledSelectField
            name={satisfiedWithInjectionResultsFieldName}
            label={NOTE_FIELD_LABEL.satisfiedWithInjectionResults}
            items={filterNoneValueOptions(YES_NO_OPTIONS).map(
              mapToControlledSelectItem
            )}
            selectSx={{ width: '88px' }}
            formLabelSx={noteFieldLabelDefaultSx}
          />
        ) : null}

        {typeOfInjectionRequired ? (
          <ControlledSelectField
            name={typeOfInjectionFieldName}
            label={NOTE_FIELD_LABEL.typeOfInjection}
            labelComponent={'inputLabel'}
            items={INJECTION_TYPES.map(mapToControlledSelectItem)}
            formControlProps={{ size: 'small' }}
            formControlSx={{ width: '417px' }}
            required={typeOfInjectionRequired}
          />
        ) : null}

        <ControlledTextField
          name={additionalQuestionsOrConcernsFieldName}
          label={NOTE_FIELD_LABEL.additionalQuestionsOrConcerns}
          required={additionalQuestionsOrConcernsRequired}
          formLabelSx={noteFieldLabelDefaultSx}
          textFieldSx={{ width: '100%' }}
        />
      </Stack>
    </>
  )
}
