import { useQuery } from '@tanstack/react-query'

import { useTasksUseCases } from 'src/features/tasks/tasks.service-locator'
import { GetTasksParams } from 'src/features/tasks/domain'

export const GET_ALL_TASKS_BY_USER_ID_QUERY_KEY = 'getAllTasksByUserId'

type UseGetAllTasksByUserIdArgs = {
  params?: GetTasksParams
}

export const useGetAllTasksByUserIdQuery = ({
  params,
}: UseGetAllTasksByUserIdArgs = {}) => {
  const tasksUseCases = useTasksUseCases()

  const { data, isLoading, isError, refetch, isFetching } = useQuery({
    queryKey: [GET_ALL_TASKS_BY_USER_ID_QUERY_KEY, params],
    queryFn: () => {
      return tasksUseCases.getAllTasksByUserId({
        params,
      })
    },
  })
  return {
    userAllTasksData: data,
    userAllTasks: data?.tasks ? data.tasks : [],
    refetchUserAllTasks: refetch,
    userAllTasksIsLoading: isLoading,
    userAllTasksIsError: isError,
    userAllTasksIsFetching: isFetching,
  }
}
