import {
  UseMutateAsyncFunction,
  UseMutateFunction,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import {
  UpdateCareTeamArgs,
  UpdateCareTeamError,
  UpdateCareTeamReturns,
} from 'src/features/care-team/domain'
import { useCareTeamUseCases } from 'src/features/care-team/care-team.service-locator'
import { GET_PATIENT_INTAKE_FLOWS_QUERY_KEY } from 'src/features/msk/presentation'
import { usePatientId } from 'src/features/shared/presentation'

type UseUpdateCareTeamProps = Omit<
  UseMutationOptions<
    UpdateCareTeamReturns,
    UpdateCareTeamError,
    UpdateCareTeamArgs,
    unknown
  >,
  'mutationFn'
> & {
  patientId?: string
  throwGetPatientIdError?: boolean
}

type UseUpdateCareTeam = (props?: UseUpdateCareTeamProps) => {
  updateCareTeam: UseMutateFunction<
    UpdateCareTeamReturns,
    UpdateCareTeamError,
    UpdateCareTeamArgs,
    unknown
  >
  updateCareTeamAsync: UseMutateAsyncFunction<
    UpdateCareTeamReturns,
    UpdateCareTeamError,
    UpdateCareTeamArgs,
    unknown
  >
  updateCareTeamIsLoading: boolean
  updateCareTeamStatus: string
}

export const useUpdateCareTeam: UseUpdateCareTeam = (props = {}) => {
  const queryClient = useQueryClient()
  const patientsUseCases = useCareTeamUseCases()

  const { onSuccess, patientId, throwGetPatientIdError = false } = props

  // TODO: find a better way to get the patient id
  const _patientId = usePatientId({ patientId, throwGetPatientIdError })

  const { mutate, isLoading, status, mutateAsync } = useMutation<
    UpdateCareTeamReturns,
    UpdateCareTeamError,
    UpdateCareTeamArgs,
    unknown
  >({
    mutationFn: (args: UpdateCareTeamArgs) => {
      return patientsUseCases.updateCareTeam(args)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [GET_PATIENT_INTAKE_FLOWS_QUERY_KEY, _patientId],
      })
      onSuccess?.(data, variables, context)
    },
  })
  return {
    updateCareTeam: mutate,
    updateCareTeamAsync: mutateAsync,
    updateCareTeamIsLoading: isLoading,
    updateCareTeamStatus: status,
  }
}
