import { Box, Divider, Typography } from '@mui/material'
import { FieldPath, FieldValues } from 'react-hook-form'
import { FC } from 'react'

import {
  AGREE_LEVEL_OPTIONS,
  ControlledSelectField,
} from 'src/features/shared/presentation'
import { noteFieldLabelDefaultSx } from 'src/features/notes/presentation'
import { mapToControlledSelectItem } from 'src/features/shared/adapters'

export const HEALTH_EQUITY_LABELS = {
  agreeYouUnderstandCondition: 'How well do you understand your condition?',
  agreeYouUnderstandTreatmentOptions:
    'How well do you understand your treatment options for your condition?',
}

export type HealthEquityQuestionsFormProps = {
  agreeYouUnderstandConditionFieldName?: FieldPath<FieldValues>
  agreeYouUnderstandTreatmentOptionsFieldName?: FieldPath<FieldValues>
  agreeYouUnderstandConditionRequired?: boolean
  agreeYouUnderstandTreatmentOptionsRequired?: boolean
  showTitle?: boolean
}

export const HealthEquityQuestionsForm: FC<HealthEquityQuestionsFormProps> = ({
  agreeYouUnderstandConditionRequired,
  agreeYouUnderstandTreatmentOptionsRequired,
  showTitle = true,
  agreeYouUnderstandConditionFieldName = 'agreeYouUnderstandCondition',
  agreeYouUnderstandTreatmentOptionsFieldName = 'agreeYouUnderstandTreatmentOptions',
}) => {
  return (
    <>
      {!showTitle ? (
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            width: '285px',
            whiteSpace: 'nowrap',
            flexDirection: 'column',
          }}
        >
          <Divider />
          <Typography pb={'16px'} color={'grey'}>
            Health Equity & Engagement Questions
          </Typography>
        </Box>
      ) : null}

      <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
        <ControlledSelectField
          name={agreeYouUnderstandConditionFieldName}
          label={HEALTH_EQUITY_LABELS.agreeYouUnderstandCondition}
          items={AGREE_LEVEL_OPTIONS.map(mapToControlledSelectItem)}
          selectSx={{ width: '268px' }}
          formLabelSx={noteFieldLabelDefaultSx}
          required={agreeYouUnderstandConditionRequired}
        />
        <ControlledSelectField
          name={agreeYouUnderstandTreatmentOptionsFieldName}
          label={HEALTH_EQUITY_LABELS.agreeYouUnderstandTreatmentOptions}
          items={AGREE_LEVEL_OPTIONS.map(mapToControlledSelectItem)}
          selectSx={{ width: '268px' }}
          formLabelSx={noteFieldLabelDefaultSx}
          required={agreeYouUnderstandTreatmentOptionsRequired}
        />
      </Box>
    </>
  )
}
