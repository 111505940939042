import {
  SelectChangeEvent,
  Box,
  Checkbox,
  Typography,
  Select,
  MenuItem,
  FormHelperText,
  Divider,
} from '@mui/material'
import { FC, ChangeEvent } from 'react'

import { Intake } from 'src/features/msk/domain'
import { mapToControlledSelectItem } from 'src/features/shared/adapters'
import {
  FormFieldContainer,
  LEVEL_SCORES,
} from 'src/features/shared/presentation'
import { getOutlineInputNotAllowedCursorStyle } from 'src/features/shared/utils'
import { PainAndFunctionFieldMethods } from 'src/features/notes/presentation'

const LABELS = {
  functionLevel: 'Function',
  painLevel: 'Pain',
}

type PainAndFunctionFieldProps = {
  intakes: Intake[]
  fieldMethods: PainAndFunctionFieldMethods
}

export const PainAndFunctionField: FC<PainAndFunctionFieldProps> = ({
  intakes,
  fieldMethods,
}) => {
  const { state, setActive, validate, removeError, setValues, reset } =
    fieldMethods

  const getFieldBlurHandler = (intakeId: string) => () => {
    validate(intakeId)
  }

  const getPainChangeHandler =
    (intakeId: string) => (event: SelectChangeEvent<string>) => {
      const painLevel = Number(event.target.value)
      setValues({ intakeId, painLevel })
      removeError(intakeId)
    }

  const getFunctionChangeHandler =
    (intakeId: string) => (event: SelectChangeEvent<string>) => {
      const functionLevel = Number(event.target.value)
      setValues({ intakeId, functionLevel })
      removeError(intakeId)
    }

  const getActiveChangeHandler =
    (intakeId: string) =>
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setActive(intakeId, checked)
      if (!checked) {
        reset()
        removeError(intakeId)
      } else {
        validate(intakeId, true)
      }
    }

  const getCheckboxLabelColor = (intakeId: string) => {
    if (state.active[intakeId]) {
      return '#014F55'
    }
    return 'rgba(0, 0, 0, 0.38)'
  }

  const isRequired = (intakeId: string) => state.active[intakeId]

  return (
    <Box>
      <Divider />
      <Box
        sx={{
          py: '16px',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '32px',
        }}
      >
        {intakes.map((intake) => (
          <Box
            key={`${intake.id}_pain_and_function_field`}
            sx={{
              display: 'flex',
              columnGap: '32px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                columnGap: '8px',
              }}
            >
              <Checkbox
                color={'primary'}
                checked={state.active[intake.id]}
                onChange={getActiveChangeHandler(intake.id)}
              />
              <Box>
                <Typography
                  fontSize={'12px'}
                  fontWeight={500}
                  color={getCheckboxLabelColor(intake.id)}
                >
                  CONDITION:
                </Typography>
                <Typography
                  fontSize={'18px'}
                  fontWeight={500}
                  color={getCheckboxLabelColor(intake.id)}
                >
                  {intake.botheredBodyPart}
                </Typography>
                <Typography
                  fontSize={'12px'}
                  fontWeight={500}
                  color={getCheckboxLabelColor(intake.id)}
                >
                  {intake.associatedPathway
                    ? `${intake.associatedPathway.pathway} Pathway`
                    : 'No Pathway Assigned'}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box sx={{ display: 'flex', columnGap: '8px' }}>
                <FormFieldContainer
                  labelComponent={'inputLabel'}
                  label={LABELS.painLevel}
                  error={!!state.errors[intake.id]}
                  formControlProps={{
                    size: 'small',
                  }}
                  formControlSx={{
                    ...getOutlineInputNotAllowedCursorStyle(
                      !state.active[intake.id]
                    ),
                    width: '116px',
                  }}
                >
                  <Select
                    size="small"
                    label={LABELS.painLevel}
                    value={state.values[intake.id]?.painLevel?.toString() ?? ''}
                    onBlur={getFieldBlurHandler(intake.id)}
                    onChange={getPainChangeHandler(intake.id)}
                    required={isRequired(intake.id)}
                    error={!!state.errors[intake.id]}
                    disabled={!state.active[intake.id]}
                    displayEmpty
                  >
                    {LEVEL_SCORES.map(mapToControlledSelectItem).map((item) => (
                      <MenuItem key={item.id} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormFieldContainer>
                <FormFieldContainer
                  labelComponent={'inputLabel'}
                  label={LABELS.functionLevel}
                  error={!!state.errors[intake.id]}
                  formControlProps={{
                    size: 'small',
                  }}
                  formControlSx={{
                    ...getOutlineInputNotAllowedCursorStyle(
                      !state.active[intake.id]
                    ),
                    width: '116px',
                  }}
                >
                  <Select
                    size="small"
                    label={LABELS.functionLevel}
                    value={
                      state.values[intake.id]?.functionLevel?.toString() ?? ''
                    }
                    onBlur={getFieldBlurHandler(intake.id)}
                    onChange={getFunctionChangeHandler(intake.id)}
                    required={isRequired(intake.id)}
                    error={!!state.errors[intake.id]}
                    disabled={!state.active[intake.id]}
                    displayEmpty
                  >
                    {LEVEL_SCORES.map(mapToControlledSelectItem).map((item) => (
                      <MenuItem key={item.id} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormFieldContainer>
              </Box>
              {state.errors[intake.id] ? (
                <FormHelperText error={!!state.errors[intake.id]}>
                  {state.errors[intake.id]}
                </FormHelperText>
              ) : null}
            </Box>
          </Box>
        ))}
      </Box>
      <Divider />
    </Box>
  )
}
