import { CreateProviderGroupServiceResponse } from 'src/features/providers/infrastructure'

export const createProviderGroupServiceResponseMock: CreateProviderGroupServiceResponse =
  {
    id: 'CreatedProviderGroup',
    createdAt: '2023-08-31T00:30:28.415Z',
    createdBy: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
    group: 'Mock group',
    isThreadwellPartner: false,
  }
