import { useCallback } from 'react'
import {
  CreateTaskService,
  CreateTaskServiceResponse,
  GetDoneTasksByUserIdService,
  GetDoneTasksByUserIdServiceResponse,
  GetOverdueTasksByUserIdService,
  GetOverdueTasksByUserIdServiceResponse,
  GetAllTasksByPatientIdService,
  GetAllTasksByPatientIdServiceResponse,
  GetTodayTasksByUserIdService,
  GetTodayTasksByUserIdServiceResponse,
  GetUpcomingTasksByUserIdService,
  GetUpcomingTasksByUserIdServiceResponse,
  GetUrgentTasksByUserIdService,
  GetUrgentTasksByUserIdServiceResponse,
  UpdateTaskService,
  UpdateTasksService,
  UpdateTaskServiceResponse,
  UpdateTasksServiceResponse,
  GetUrgentTasksByPatientIdService,
  GetUrgentTasksByPatientIdServiceResponse,
  GetOverdueTasksByPatientIdService,
  GetOverdueTasksByPatientIdServiceResponse,
  GetTodayTasksByPatientIdService,
  GetTodayTasksByPatientIdServiceResponse,
  GetDoneTasksByPatientIdService,
  GetDoneTasksByPatientIdServiceResponse,
  GetUpcomingTasksByPatientIdService,
  GetUpcomingTasksByPatientIdServiceResponse,
  GetAllTasksByUserIdService,
  GetAllTasksByUserIdServiceResponse,
  GetTasksServiceParams,
} from 'src/features/tasks/infrastructure'
import { useCompassApiClient } from 'src/features/shared/infrastructure'
import * as settings from 'src/config/settings'

export const useTasksService = () => {
  const { getCompassApiClient } = useCompassApiClient()

  const getAllTasksByUserId: GetAllTasksByUserIdService = useCallback(
    async (args) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.get<GetAllTasksByUserIdServiceResponse>(
        settings.COMPASS_API_BASE_URL + '/tasks',
        {
          params: args?.params,
        }
      )
    },
    [getCompassApiClient]
  )

  const getTodayTasksByUserId: GetTodayTasksByUserIdService = useCallback(
    async ({ params } = {}) => {
      const compassClient = await getCompassApiClient()
      let requestParams: GetTasksServiceParams = {
        timeFrame: 'today',
      }
      if (params) {
        requestParams = {
          ...requestParams,
          ...params,
        }
      }
      return await compassClient.get<GetTodayTasksByUserIdServiceResponse>(
        settings.COMPASS_API_BASE_URL + '/tasks',
        {
          params: requestParams,
        }
      )
    },
    [getCompassApiClient]
  )

  const getUrgentTasksByUserId: GetUrgentTasksByUserIdService = useCallback(
    async ({ params } = {}) => {
      const compassClient = await getCompassApiClient()
      let requestParams: GetTasksServiceParams = {
        timeFrame: 'urgent',
      }
      if (params) {
        requestParams = {
          ...requestParams,
          ...params,
        }
      }
      return await compassClient.get<GetUrgentTasksByUserIdServiceResponse>(
        settings.COMPASS_API_BASE_URL + '/tasks',
        {
          params: requestParams,
        }
      )
    },
    [getCompassApiClient]
  )

  const getOverdueTasksByUserId: GetOverdueTasksByUserIdService = useCallback(
    async ({ params } = {}) => {
      const compassClient = await getCompassApiClient()
      let requestParams: GetTasksServiceParams = {
        timeFrame: 'overdue',
      }
      if (params) {
        requestParams = {
          ...requestParams,
          ...params,
        }
      }
      return await compassClient.get<GetOverdueTasksByUserIdServiceResponse>(
        settings.COMPASS_API_BASE_URL + '/tasks',
        {
          params: requestParams,
        }
      )
    },
    [getCompassApiClient]
  )

  const getDoneTasksByUserId: GetDoneTasksByUserIdService = useCallback(
    async ({ params } = {}) => {
      const compassClient = await getCompassApiClient()
      let requestParams: GetTasksServiceParams = {
        timeFrame: 'done',
      }
      if (params) {
        requestParams = {
          ...requestParams,
          ...params,
        }
      }
      return await compassClient.get<GetDoneTasksByUserIdServiceResponse>(
        settings.COMPASS_API_BASE_URL + '/tasks',
        {
          params: requestParams,
        }
      )
    },
    [getCompassApiClient]
  )

  const getUpcomingTasksByUserId: GetUpcomingTasksByUserIdService = useCallback(
    async ({ params } = {}) => {
      const compassClient = await getCompassApiClient()
      let requestParams: GetTasksServiceParams = {
        timeFrame: 'upcoming',
      }
      if (params) {
        requestParams = {
          ...requestParams,
          ...params,
        }
      }
      return await compassClient.get<GetUpcomingTasksByUserIdServiceResponse>(
        settings.COMPASS_API_BASE_URL + '/tasks',
        {
          params: requestParams,
        }
      )
    },
    [getCompassApiClient]
  )

  const updateTask: UpdateTaskService = useCallback(
    async ({ taskId, payload }) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.put<UpdateTaskServiceResponse>(
        settings.COMPASS_API_BASE_URL + `/tasks/${taskId}`,
        payload
      )
    },
    [getCompassApiClient]
  )

  const updateTasks: UpdateTasksService = useCallback(
    async (payload) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.post<UpdateTasksServiceResponse>(
        settings.COMPASS_API_BASE_URL + '/tasks/update',
        payload
      )
    },
    [getCompassApiClient]
  )

  const createTask: CreateTaskService = useCallback(
    async (payload) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.post<CreateTaskServiceResponse>(
        settings.COMPASS_API_BASE_URL + `/tasks`,
        payload
      )
    },
    [getCompassApiClient]
  )

  const getAllTasksByPatientId: GetAllTasksByPatientIdService = useCallback(
    async ({ patientId, params }) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.get<GetAllTasksByPatientIdServiceResponse>(
        settings.COMPASS_API_BASE_URL + '/tasks/' + patientId + '/patientId',
        {
          params: params,
        }
      )
    },
    [getCompassApiClient]
  )

  const getUrgentTasksByPatientId: GetUrgentTasksByPatientIdService =
    useCallback(
      async ({ patientId, params }) => {
        const compassClient = await getCompassApiClient()
        let requestParams: GetTasksServiceParams = {
          timeFrame: 'urgent',
        }
        if (params) {
          requestParams = {
            ...requestParams,
            ...params,
          }
        }
        return await compassClient.get<GetUrgentTasksByPatientIdServiceResponse>(
          settings.COMPASS_API_BASE_URL + '/tasks/' + patientId + '/patientId',
          {
            params: requestParams,
          }
        )
      },
      [getCompassApiClient]
    )

  const getOverdueTasksByPatientId: GetOverdueTasksByPatientIdService =
    useCallback(
      async ({ patientId, params }) => {
        const compassClient = await getCompassApiClient()
        let requestParams: GetTasksServiceParams = {
          timeFrame: 'overdue',
        }
        if (params) {
          requestParams = {
            ...requestParams,
            ...params,
          }
        }
        return await compassClient.get<GetOverdueTasksByPatientIdServiceResponse>(
          settings.COMPASS_API_BASE_URL + '/tasks/' + patientId + '/patientId',
          {
            params: requestParams,
          }
        )
      },
      [getCompassApiClient]
    )

  const getDoneTasksByPatientId: GetDoneTasksByPatientIdService = useCallback(
    async ({ patientId, params }) => {
      const compassClient = await getCompassApiClient()
      let requestParams = {
        timeFrame: 'done',
      }
      if (params) {
        requestParams = {
          ...requestParams,
          ...params,
        }
      }
      return await compassClient.get<GetDoneTasksByPatientIdServiceResponse>(
        settings.COMPASS_API_BASE_URL + '/tasks/' + patientId + '/patientId',
        {
          params: requestParams,
        }
      )
    },
    [getCompassApiClient]
  )

  const getTodayTasksByPatientId: GetTodayTasksByPatientIdService = useCallback(
    async ({ patientId, params }) => {
      const compassClient = await getCompassApiClient()
      let requestParams = {
        timeFrame: 'today',
      }
      if (params) {
        requestParams = {
          ...requestParams,
          ...params,
        }
      }
      return await compassClient.get<GetTodayTasksByPatientIdServiceResponse>(
        settings.COMPASS_API_BASE_URL + '/tasks/' + patientId + '/patientId',
        {
          params: requestParams,
        }
      )
    },
    [getCompassApiClient]
  )

  const getUpcomingTasksByPatientId: GetUpcomingTasksByPatientIdService =
    useCallback(
      async ({ patientId, params }) => {
        const compassClient = await getCompassApiClient()
        let requestParams: GetTasksServiceParams = {
          timeFrame: 'upcoming',
        }
        if (params) {
          requestParams = {
            ...requestParams,
            ...params,
          }
        }
        return await compassClient.get<GetUpcomingTasksByPatientIdServiceResponse>(
          settings.COMPASS_API_BASE_URL + '/tasks/' + patientId + '/patientId',
          {
            params: requestParams,
          }
        )
      },
      [getCompassApiClient]
    )

  return {
    createTask,
    getAllTasksByUserId,
    getDoneTasksByUserId,
    getOverdueTasksByUserId,
    getAllTasksByPatientId,
    getTodayTasksByUserId,
    getUpcomingTasksByUserId,
    getUrgentTasksByUserId,
    getUrgentTasksByPatientId,
    getOverdueTasksByPatientId,
    getTodayTasksByPatientId,
    getDoneTasksByPatientId,
    getUpcomingTasksByPatientId,
    updateTask,
    updateTasks,
  }
}
