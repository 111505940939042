import {
  FC,
  useState,
  ChangeEventHandler,
  MouseEventHandler,
  KeyboardEventHandler,
  useRef,
} from 'react'
import {
  Alert,
  Box,
  Divider,
  Fade,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'

import {
  FullSectionLoader,
  useDebounce,
  useOnClickOutside,
} from 'src/features/shared/presentation'
import { useSearchPatientsQuery } from 'src/features/patients/presentation'
import { useEligiblePatientsQueryParams } from 'src/features/eligibility/presentation'
import { SearchPatientResult } from 'src/features/patients/domain'

const SEARCH_QUERY_DEBOUNCE_TIME = 2000
const MIN_QUERY_LENGTH = 3

export const EligiblePatientsSearchInput: FC = () => {
  const searchInputContainerRef = useRef<HTMLInputElement>(null)

  const { getQ, setQ, setMbi, deleteEligiblePatientsQueryParams } =
    useEligiblePatientsQueryParams()

  const [
    eligiblePatientsSearchInputValue,
    setEligiblePatientsSearchInputValue,
  ] = useState(getQ() || '')

  const [showSuggestions, setShowSuggestions] = useState(false)
  const [suggestionsQuery, setSuggestionsQuery] = useState('')
  const debouncedSearchQuery = useDebounce<string>(
    suggestionsQuery,
    SEARCH_QUERY_DEBOUNCE_TIME
  )
  const {
    searchPatientsResults,
    searchPatientsIsLoading,
    searchPatientsIsError,
  } = useSearchPatientsQuery({
    query: debouncedSearchQuery,
    type: 'eligibility',
  })

  const showSuggestionsDivider = (index: number) =>
    searchPatientsResults.length > 1 &&
    index !== searchPatientsResults.length - 1

  const resetSuggestionsState = () => {
    setShowSuggestions(false)
    setSuggestionsQuery('')
  }

  const resetAllState = () => {
    setEligiblePatientsSearchInputValue('')
    resetSuggestionsState()
  }

  const handleInputChange: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (event) => {
    const value = event.target.value

    if (value.length >= MIN_QUERY_LENGTH) {
      setSuggestionsQuery(value)
    } else {
      setSuggestionsQuery('')
    }

    setEligiblePatientsSearchInputValue(value)
  }

  const handleInputFocus = () => {
    setShowSuggestions(true)
  }

  const getSuggestionClickHandler =
    (suggestion: SearchPatientResult): MouseEventHandler<HTMLLIElement> =>
    () => {
      setMbi(suggestion.mbi)
      setShowSuggestions(false)
    }

  const handleSearch = () => {
    setQ(eligiblePatientsSearchInputValue)
    setShowSuggestions(false)
  }

  const handleSearchIconClick: MouseEventHandler<SVGSVGElement> = () => {
    handleSearch()
  }

  const handleSearchInputKeyDown: KeyboardEventHandler<HTMLDivElement> = (
    event
  ) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  const handleSearchInputClearIconClick: MouseEventHandler<
    HTMLButtonElement
  > = () => {
    resetAllState()
    deleteEligiblePatientsQueryParams(['q', 'mbi'])
  }

  useOnClickOutside(searchInputContainerRef, () => {
    setShowSuggestions(false)
  })

  return (
    <Box>
      <Box ref={searchInputContainerRef} sx={{ width: '320px' }}>
        <TextField
          variant="outlined"
          type={'text'}
          placeholder="Search a patient by name or DOB"
          sx={{
            backgroundColor: 'common.white',
            width: '100%',
          }}
          inputProps={{
            sx: {
              padding: '12px',
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon
                  sx={{ cursor: 'pointer' }}
                  onClick={handleSearchIconClick}
                />
              </InputAdornment>
            ),
            endAdornment: eligiblePatientsSearchInputValue ? (
              <IconButton onClick={handleSearchInputClearIconClick}>
                <CancelRoundedIcon />
              </IconButton>
            ) : null,
          }}
          value={eligiblePatientsSearchInputValue}
          onChange={handleInputChange}
          onKeyDown={handleSearchInputKeyDown}
          onFocus={handleInputFocus}
        />
        <Fade in={showSuggestions}>
          <Paper
            sx={{
              position: 'absolute',
              width: '320px',
              maxHeight: '160px',
              overflowY: 'scroll',
              zIndex: 3,
            }}
          >
            {suggestionsQuery.length >= MIN_QUERY_LENGTH &&
            searchPatientsIsLoading ? (
              <FullSectionLoader />
            ) : debouncedSearchQuery && searchPatientsIsError ? (
              <Alert
                severity="error"
                sx={{
                  width: '100%',
                  height: '100px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Something went wrong. Please try again.
              </Alert>
            ) : searchPatientsResults.length ? (
              <List
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  '& .MuiListItem-root': {
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'grey.100',
                    },
                  },
                }}
              >
                {searchPatientsResults.map((suggestion, index) => (
                  <Box key={index}>
                    <ListItem
                      alignItems="flex-start"
                      onClick={getSuggestionClickHandler(suggestion)}
                    >
                      <ListItemText
                        primary={`${suggestion.firstName} ${suggestion.lastName}`}
                        secondary={
                          <>
                            {suggestion.dob ? (
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {suggestion.dob}
                              </Typography>
                            ) : null}
                            {suggestion.mbi ? ` — ${suggestion.mbi}` : null}
                          </>
                        }
                      />
                    </ListItem>
                    {showSuggestionsDivider(index) ? <Divider /> : null}
                  </Box>
                ))}
              </List>
            ) : null}
          </Paper>
        </Fade>
      </Box>
    </Box>
  )
}
