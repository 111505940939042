import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import {
  SearchPatientParams,
  SearchPatientsError,
  SearchPatientsReturns,
} from 'src/features/patients/domain'
import { usePatientsUseCases } from 'src/features/patients/patients.service-locator'

export const SEARCH_PATIENTS_QUERY_KEY = 'SearchPatientsQueryKey'

type UseSearchPatientsQueryProps = UseQueryOptions<
  SearchPatientsReturns,
  unknown,
  SearchPatientsReturns
> & {
  query: string
  type?: SearchPatientParams['type']
}

type UseSearchPatientsQuery = (props: UseSearchPatientsQueryProps) => {
  searchPatientsResults: SearchPatientsReturns['hits']
  searchPatientsIsError: boolean
  searchPatientsIsLoading: boolean
}

export const useSearchPatientsQuery: UseSearchPatientsQuery = ({
  query,
  type = 'patient',
}) => {
  const patientsUseCases = usePatientsUseCases()

  const { data, isLoading, isError } = useQuery<
    SearchPatientsReturns,
    SearchPatientsError
  >(
    [SEARCH_PATIENTS_QUERY_KEY, query],
    () => patientsUseCases.searchPatients({ type, query }),
    {
      retry: false,
    }
  )

  return {
    searchPatientsResults: data ? data.hits.slice(0, 5) : [],
    searchPatientsIsError: isError,
    searchPatientsIsLoading: isLoading,
  }
}
