import {
  addDays,
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
} from 'date-fns'

export const getDateWithouthTimeZone = (date: Date) => {
  const dateWithoutTimeZone = new Date(
    date.getTime() + date.getTimezoneOffset() * 60 * 1000
  )
  return dateWithoutTimeZone
}

export const getFirstAndLastDayOfMonth = (date?: Date) => {
  const currentDate = date || new Date()
  const firstDay = startOfMonth(currentDate)
  const lastDay = endOfMonth(currentDate)

  return [firstDay, lastDay]
}

export const getFirstAndLastDayOfWeek = (date?: Date) => {
  const currentDate = date || new Date()
  const firstDay = startOfWeek(currentDate)
  const lastDay = endOfWeek(currentDate)

  return [firstDay, lastDay]
}

export const getFirstAndLastDayOfAgenda = (date?: Date) => {
  const currentDate = date || new Date()
  const firstDay = currentDate
  const lastDay = addDays(currentDate, 30) // Default for Agenda view, probably could be a const somewhere

  return [firstDay, lastDay]
}

export function formatDate(date: Date, removeTimezone?: boolean): string
export function formatDate(date: string, removeTimezone?: boolean): string
export function formatDate(
  date?: string | null,
  removeTimezone?: boolean
): undefined | string | null
export function formatDate(date: any, removeTimezone = false): any {
  if (date) {
    let dateToFormat = date

    if (removeTimezone && typeof dateToFormat === 'string') {
      if (dateToFormat.includes('-') && !dateToFormat.includes('T')) {
        dateToFormat += 'T00:00:00.000'
      } else if (dateToFormat.includes('T')) {
        dateToFormat = dateToFormat.split('T')[0]
        dateToFormat += 'T00:00:00.000'
      }
    } else if (removeTimezone) {
      dateToFormat = getDateWithouthTimeZone(dateToFormat)
    }

    if (typeof dateToFormat === 'string') {
      return format(new Date(dateToFormat), 'MM/dd/yyyy')
    }

    return format(dateToFormat, 'MM/dd/yyyy')
  }
  return date
}
