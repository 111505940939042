import { SxProps, Theme, Button, ButtonProps } from '@mui/material'
import { FC } from 'react'

type ClickableTextProps = Omit<ButtonProps, 'sx'> & {
  sx?: SxProps<Theme>
}

export const ClickableText: FC<ClickableTextProps> = ({
  children,
  sx = {},
  ...props
}) => {
  return (
    <Button
      variant="text"
      color="secondary"
      size="medium"
      sx={{
        textTransform: 'none',
        textDecoration: 'underline',
        '&.Mui-disabled': {
          cursor: 'not-allowed',
        },
        '&:hover': {
          textDecoration: 'underline',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  )
}
