import { useQuery } from '@tanstack/react-query'

import {
  GetTasksByPatientIdParams,
  GetUpcomingTasksByPatientIdError,
  GetUpcomingTasksByPatientIdReturns,
} from 'src/features/tasks/domain'
import { useTasksUseCases } from 'src/features/tasks/tasks.service-locator'
import { usePatientId } from 'src/features/shared/presentation'

export const GET_UPCOMING_TASKS_BY_PATIENT_ID_QUERY_KEY =
  'getUpcomingTasksByPatientId'

type UseGetUpcomingTasksByPatientIdProps = {
  patientId?: string
  throwGetPatientIdError?: boolean
  params?: GetTasksByPatientIdParams
}

export const useGetUpcomingTasksByPatientIdQuery = ({
  patientId,
  params,
  throwGetPatientIdError = true,
}: UseGetUpcomingTasksByPatientIdProps = {}) => {
  const taskUseCase = useTasksUseCases()

  // TODO: find a better way to get the patient id
  const _patientId = usePatientId({ patientId, throwGetPatientIdError })

  const { data, refetch, isLoading, isError, isFetching, isRefetching } =
    useQuery<
      GetUpcomingTasksByPatientIdReturns,
      GetUpcomingTasksByPatientIdError
    >([GET_UPCOMING_TASKS_BY_PATIENT_ID_QUERY_KEY, _patientId], () => {
      return _patientId
        ? taskUseCase.getUpcomingTasksByPatientId({
            patientId: _patientId,
            params,
          })
        : Promise.reject(
            new Error(
              'Patient ID is required as a parameter or in the url as path param.'
            )
          )
    })

  return {
    patientUpcomingTasksData: data,
    patientUpcomingTasks: data?.tasks ? data.tasks : [],
    refetchPatientUpcomingTasks: refetch,
    patientUpcomingTasksIsLoading: isLoading,
    patientUpcomingTasksIsError: isError,
    patientUpcomingTasksIsFetching: isFetching,
    patientUpcomingTasksIsRefetching: isRefetching,
  }
}
