import { useQuery } from '@tanstack/react-query'

import { useProvidersUseCases } from 'src/features/providers/providers.service-locator'
import {
  GetProvidersError,
  GetProvidersArgs,
  GetProvidersReturns,
} from 'src/features/providers/domain'

export const GET_PROVIDERS_QUERY_KEY = 'getProviders'

export const useGetProvidersQuery = (args: GetProvidersArgs) => {
  const providersUseCases = useProvidersUseCases()

  const { data, isLoading, isError } = useQuery<
    GetProvidersReturns,
    GetProvidersError
  >([GET_PROVIDERS_QUERY_KEY, args], () => {
    return providersUseCases.getProviders(args)
  })
  return {
    providers: data || [],
    getProvidersIsLoading: isLoading,
    getProvidersIsError: isError,
  }
}
