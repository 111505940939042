import {
  ControlledSelectItem,
  MultiSelectOption,
  TCReactSelectOption,
} from 'src/features/shared/presentation'
import { Plan, Provider } from 'src/features/providers/domain'

export function mapToMultiSelectOption(
  data: ControlledSelectItem
): MultiSelectOption
export function mapToMultiSelectOption(data: string): MultiSelectOption
export function mapToMultiSelectOption(data: any): MultiSelectOption {
  if (typeof data === 'string') {
    return {
      label: data,
      value: data,
      key: data,
    }
  }
  return {
    label: data.label,
    value: data.value,
    key: data.id,
  }
}

type MapToControlledSelectItem = (constant: string) => ControlledSelectItem

export const mapToControlledSelectItem: MapToControlledSelectItem = (
  constant
) => {
  return {
    id: constant,
    label: constant,
    value: constant,
  }
}

const isProviderType = (from: any) => {
  if (typeof from === 'object') {
    return (
      Object.keys(from).includes('name') && Object.keys(from).includes('id')
    )
  }

  return false
}

const isPlanType = (from: any) => {
  if (typeof from === 'object') {
    return (
      Object.keys(from).includes('name') && Object.keys(from).includes('id')
    )
  }

  return false
}

export function mapToTCReactSelectOption(from: string): TCReactSelectOption
export function mapToTCReactSelectOption(from: Provider): TCReactSelectOption
export function mapToTCReactSelectOption(plan: Plan): TCReactSelectOption
export function mapToTCReactSelectOption(from: any): TCReactSelectOption {
  if (typeof from === 'string') return { label: from, value: from }

  if (isPlanType(from)) return { label: from.name, value: from.id }

  if (isProviderType(from)) return { label: from.name, value: from.id }

  throw new Error('Invalid type')
}
