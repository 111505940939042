import { Reducer, useCallback, useReducer } from 'react'

import {
  readLocalStorage,
  saveInLocalStorage,
  removeFromLocalStorage,
} from 'src/features/shared/utils'
import { Intake } from 'src/features/msk/domain'
import { mapToNoteTypeStorageKey } from 'src/features/notes/adapters'
import { DraftNotePainAndFunction, NoteType } from 'src/features/notes/domain'

const PAIN_AND_FUNCTION_FIELD_LOCAL_STORAGE_KEY_PREFIX =
  'pain-and-function-field'

export type ConditionPainAndFunctionValue = {
  painLevel?: number
  functionLevel?: number
}

export type PainAndFunctionFieldState = {
  active: Record<string, boolean>
  errors: Record<string, string>
  values: Record<string, ConditionPainAndFunctionValue | null>
}

type ResetAction = {
  type: 'RESET'
  payload: {
    initialState: PainAndFunctionFieldState
    patientId: string
    noteType: NoteType
  }
}

type SetActiveAction = {
  type: 'SET_ACTIVE'
  payload: {
    intakeId: string
    active: boolean
    patientId: string
    noteType: NoteType
  }
}

type ValidateAction = {
  type: 'VALIDATE'
  payload: {
    intakeId: string
    isActive?: boolean
    patientId: string
    noteType: NoteType
  }
}

type RemoveErrorAction = {
  type: 'REMOVE_ERROR'
  payload: {
    intakeId: string
    patientId: string
    noteType: NoteType
  }
}

type SetValuesAction = {
  type: 'SET_VALUES'
  payload: {
    intakeId: string
    painLevel?: number
    functionLevel?: number
    patientId: string
    noteType: NoteType
  }
}

type ConditionPainAndFunctionAction =
  | ResetAction
  | SetActiveAction
  | ValidateAction
  | RemoveErrorAction
  | SetValuesAction

const getLocalStorageKey = (patientId: string, noteType: NoteType) => {
  const noteKeyPreffix = mapToNoteTypeStorageKey(noteType)
  return `${PAIN_AND_FUNCTION_FIELD_LOCAL_STORAGE_KEY_PREFIX}-${noteKeyPreffix}-${patientId}`
}

export const loadPainAndFunctionFieldStateFromLocalStorage = (
  patientId: string,
  noteType: NoteType
) => {
  const key = getLocalStorageKey(patientId, noteType)
  return readLocalStorage<PainAndFunctionFieldState>(key)
}

export const savePainAndFunctionFieldStateInLocalStorage = (
  patientId: string,
  state: PainAndFunctionFieldState,
  noteType: NoteType
) => {
  const key = getLocalStorageKey(patientId, noteType)
  saveInLocalStorage(key, state)
}

export const removePainAndFunctionFieldStateFromLocalStorage = (
  patientId: string,
  noteType: NoteType
) => {
  const key = getLocalStorageKey(patientId, noteType)
  removeFromLocalStorage(key)
}

const reducer: Reducer<
  PainAndFunctionFieldState,
  ConditionPainAndFunctionAction
> = (state, action) => {
  switch (action.type) {
    case 'RESET':
      savePainAndFunctionFieldStateInLocalStorage(
        action.payload.patientId,
        action.payload.initialState,
        action.payload.noteType
      )
      return action.payload.initialState
    case 'SET_ACTIVE': {
      const newState = {
        ...state,
        active: {
          ...state.active,
          [action.payload.intakeId]: action.payload.active,
        },
      }
      savePainAndFunctionFieldStateInLocalStorage(
        action.payload.patientId,
        newState,
        action.payload.noteType
      )
      return newState
    }
    case 'VALIDATE': {
      const isActive =
        action.payload.isActive ?? state.active[action.payload.intakeId]
      if (
        isActive &&
        (state.values[action.payload.intakeId]?.painLevel === undefined ||
          state.values[action.payload.intakeId]?.functionLevel === undefined)
      ) {
        const newState = {
          ...state,
          errors: {
            ...state.errors,
            [action.payload.intakeId]: 'Please fill out both fields',
          },
        }
        savePainAndFunctionFieldStateInLocalStorage(
          action.payload.patientId,
          newState,
          action.payload.noteType
        )
        return newState
      }
      return state
    }
    case 'REMOVE_ERROR': {
      const newErrors = { ...state.errors }
      delete newErrors[action.payload.intakeId]
      const newState = {
        ...state,
        errors: newErrors,
      }
      savePainAndFunctionFieldStateInLocalStorage(
        action.payload.patientId,
        newState,
        action.payload.noteType
      )
      return newState
    }
    case 'SET_VALUES': {
      const newValue = {
        ...state.values[action.payload.intakeId],
      }

      if (typeof action.payload.painLevel === 'number') {
        newValue.painLevel = action.payload.painLevel
      }

      if (typeof action.payload.functionLevel === 'number') {
        newValue.functionLevel = action.payload.functionLevel
      }
      const newState = {
        ...state,
        values: {
          ...state.values,
          [action.payload.intakeId]: newValue,
        },
      }
      savePainAndFunctionFieldStateInLocalStorage(
        action.payload.patientId,
        newState,
        action.payload.noteType
      )
      return newState
    }
    default:
      return state
  }
}

type setValuesPayload = Omit<
  SetValuesAction['payload'],
  'patientId' | 'noteType'
>

export type PainAndFunctionFieldMethods = {
  state: PainAndFunctionFieldState
  setActive: (intakeId: string, active: boolean) => void
  validate: (intakeId: string, isActive?: boolean) => void
  removeError: (intakeId: string) => void
  setValues: (payload: setValuesPayload) => void
  reset: () => void
  hasErrors: boolean
  hasAtLeastOneActive: boolean
  getPainValues: () => number[]
}

type UsePainAndFunctionFieldArgs = {
  intakes: Intake[]
  patientId: string
  noteType: NoteType
  draftNotePainAndFunction?: DraftNotePainAndFunction[]
}
type UsePainAndFunctionField = (
  args: UsePainAndFunctionFieldArgs
) => PainAndFunctionFieldMethods

export const usePainAndFunctionField: UsePainAndFunctionField = ({
  intakes,
  patientId,
  noteType,
  draftNotePainAndFunction,
}) => {
  const initialState: PainAndFunctionFieldState = {
    active: intakes
      .map((intake) => intake.id)
      .reduce((acc: PainAndFunctionFieldState['active'], intakeId) => {
        acc[intakeId] = false
        return acc
      }, {}),
    errors: {},
    values: intakes
      .map((intake) => intake.id)
      .reduce((acc: PainAndFunctionFieldState['values'], intakeId) => {
        acc[intakeId] = null
        return acc
      }, {}),
  }

  const getInitialState = (): PainAndFunctionFieldState => {
    const stateFromLocalStorage = loadPainAndFunctionFieldStateFromLocalStorage(
      patientId,
      noteType
    )
    if (stateFromLocalStorage) {
      return stateFromLocalStorage
    }
    let _initialState = initialState

    if (draftNotePainAndFunction) {
      draftNotePainAndFunction.forEach((painAndFunction) => {
        if (
          painAndFunction.intakeId &&
          (painAndFunction.pain || painAndFunction.function)
        ) {
          _initialState = {
            active: {
              [painAndFunction.intakeId]: true,
            },
            errors: {},
            values: {
              [painAndFunction.intakeId]: {
                painLevel: painAndFunction.pain,
                functionLevel: painAndFunction.function,
              },
            },
          }
        }
      })
    }

    savePainAndFunctionFieldStateInLocalStorage(
      patientId,
      _initialState,
      noteType
    )
    return _initialState
  }

  const [state, dispatch] = useReducer(reducer, getInitialState())

  const setActive = (intakeId: string, active: boolean) => {
    dispatch({
      type: 'SET_ACTIVE',
      payload: { intakeId, active, patientId, noteType },
    })
  }

  const validate = (intakeId: string, isActive?: boolean) => {
    dispatch({
      type: 'VALIDATE',
      payload: { intakeId, isActive, patientId, noteType },
    })
  }

  const removeError = (intakeId: string) => {
    dispatch({
      type: 'REMOVE_ERROR',
      payload: { intakeId, patientId, noteType },
    })
  }

  const setValues = ({
    intakeId,
    painLevel,
    functionLevel,
  }: setValuesPayload) => {
    dispatch({
      type: 'SET_VALUES',
      payload: { intakeId, painLevel, functionLevel, patientId, noteType },
    })
  }

  const reset = () => {
    dispatch({
      type: 'RESET',
      payload: {
        initialState,
        patientId,
        noteType,
      },
    })
  }

  const getPainValues = useCallback(() => {
    const nonNullValues = Object.values(state.values).filter(
      (value) => value !== null
    ) as ConditionPainAndFunctionValue[]

    const nonNullPainValues = nonNullValues.filter(
      (value) => value.painLevel
    ) as Required<ConditionPainAndFunctionValue>[]

    return nonNullPainValues.map((value) => value.painLevel)
  }, [state.values])

  const hasErrors = Object.keys(state.errors).length > 0

  const hasAtLeastOneActive = Object.values(state.active).some(
    (active) => active
  )

  return {
    state,
    setActive,
    validate,
    removeError,
    setValues,
    hasErrors,
    hasAtLeastOneActive,
    getPainValues,
    reset,
  }
}
