import {
  GetMyCompassUserError,
  GetMyCompassUserReturns,
  GetNavigatorsError,
  GetNavigatorsParams,
  GetNavigatorsReturns,
  Navigator,
} from 'src/features/shared/domain'
import {
  GetMyCompassUserServiceErrorResponse,
  GetMyCompassUserServiceResponse,
  GetNavigatorsServiceErrorResponse,
  GetNavigatorsServiceParams,
  GetNavigatorsServiceResponse,
} from 'src/features/shared/infrastructure'
import { MultiSelectOption } from 'src/features/shared/presentation'

type MapToGetNavigatorsServiceParams = (
  params?: GetNavigatorsParams
) => GetNavigatorsServiceParams | undefined

export const mapToGetNavigatorsServiceParams: MapToGetNavigatorsServiceParams =
  (params) => {
    if (!params) return undefined

    return {
      ...params,
      withTasks: undefined, // Don't need to send this to the API
    }
  }

type MapToGetNavigatorsReturns = (
  response: GetNavigatorsServiceResponse
) => GetNavigatorsReturns

export const mapToGetNavigatorsReturns: MapToGetNavigatorsReturns = (
  response
) => {
  const navigators = response.navigators.map((navigator) => {
    const { totalAssignedPathways, name, type, ...rest } = navigator
    const firstName = name.split(' ')[0]

    return {
      ...rest,
      name,
      firstName,
      type,
      totalAssignedPathways,
    }
  })
  return { navigators }
}

type MapToGetNavigatorsError = (
  error: GetNavigatorsServiceErrorResponse
) => GetNavigatorsError

export const mapToGetNavigatorsError: MapToGetNavigatorsError = (error) => {
  return {
    message: error.message,
  }
}

type MapToGetMyCompassUserReturns = (
  response: GetMyCompassUserServiceResponse
) => GetMyCompassUserReturns

export const mapToGetMyCompassUserReturns: MapToGetMyCompassUserReturns = (
  response
) => {
  const firstName = response.name.split(' ')[0]
  return {
    ...response,
    firstName,
  }
}

type MapToGetMyCompassUserError = (
  error: GetMyCompassUserServiceErrorResponse
) => GetMyCompassUserError

export const mapToGetMyCompassUserError: MapToGetMyCompassUserError = (
  error
) => {
  return {
    message: error.message,
  }
}

type MapToMultiSelectOption = (navigator: Navigator) => MultiSelectOption

export const mapNavigatorToMultiSelectOption: MapToMultiSelectOption = (
  navigator
) => {
  return {
    value: navigator.id,
    key: navigator.id,
    label: navigator.name,
  }
}
