import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from 'src/features/shared/infrastructure'

export type BaseLayoutUIState = {
  showNavbar: boolean
}

const initialState: BaseLayoutUIState = {
  showNavbar: true,
}

const baseLayoutUISlice = createSlice({
  name: 'baseLayoutUI',
  initialState,
  reducers: {
    setShowNavbarAction(state, action: PayloadAction<boolean>) {
      state.showNavbar = action.payload
    },
  },
})

export const { setShowNavbarAction } = baseLayoutUISlice.actions

export const baseLayoutUIShowNavbarSelector = (state: RootState) =>
  state.baseLayoutUI.showNavbar

export default baseLayoutUISlice.reducer
