import { useQuery } from '@tanstack/react-query'

import {
  GetDraftNoteReturns,
  GetDraftNoteParams,
  GetDraftNoteError,
} from 'src/features/notes/domain'
import { useNotesUseCases } from 'src/features/notes/notes.service-locator'

export const GET_PATIENT_DRAFT_NOTE = 'getDraftNote'

type UseGetDraftNoteProps = GetDraftNoteParams

type UseGetDraftNote = (props: UseGetDraftNoteProps) => {
  draftNote: GetDraftNoteReturns
  getDraftNoteIsLoading: boolean
  getDraftNoteIsError: boolean
  getDraftNoteError: GetDraftNoteError | null
}

export const useGetDraftNote: UseGetDraftNote = (params) => {
  const notesUseCases = useNotesUseCases()

  const { data, isLoading, isError, error } = useQuery<
    GetDraftNoteReturns,
    GetDraftNoteError
  >({
    queryKey: [GET_PATIENT_DRAFT_NOTE, params.patientId],
    queryFn: () => notesUseCases.getDraftNote(params),
    enabled: !!params.patientId,
  })

  return {
    draftNote: data ?? null,
    getDraftNoteIsLoading: isLoading,
    getDraftNoteIsError: isError,
    getDraftNoteError: error,
  }
}
