import {
  UseMutateFunction,
  UseMutationOptions,
  useMutation,
} from '@tanstack/react-query'

import {
  UpdateTasksArgs,
  UpdateTasksError,
  UpdateTasksReturns,
} from 'src/features/tasks/domain'
import {
  useUserSidebarTasks,
  useUserCalendarTasks,
} from 'src/features/tasks/presentation'
import { useTasksUseCases } from 'src/features/tasks/tasks.service-locator'

type useUpdateTasksProps = Omit<
  UseMutationOptions<
    UpdateTasksReturns,
    UpdateTasksError,
    UpdateTasksArgs,
    unknown
  >,
  'mutationFn'
>

type UseUpdateTasks = (props?: useUpdateTasksProps) => {
  updateTasks: UseMutateFunction<
    UpdateTasksReturns,
    UpdateTasksError,
    UpdateTasksArgs,
    unknown
  >
  updateTasksIsLoading: boolean
  updateTasksStatus: string
}

export const useUpdateTasks: UseUpdateTasks = (props = {}) => {
  const tasksUseCases = useTasksUseCases()
  const { refreshUserSidebarTasks } = useUserSidebarTasks()
  const { refreshUserCalendarTasks } = useUserCalendarTasks()

  const { onSuccess } = props

  const { mutate, isLoading, status } = useMutation<
    UpdateTasksReturns,
    UpdateTasksError,
    UpdateTasksArgs,
    unknown
  >({
    mutationFn: (args: UpdateTasksArgs) => {
      return tasksUseCases.updateTasks(args)
    },
    onSuccess: (data, variables, context) => {
      refreshUserSidebarTasks()
      refreshUserCalendarTasks()
      onSuccess?.(data, variables, context)
    },
  })
  return {
    updateTasks: mutate,
    updateTasksIsLoading: isLoading,
    updateTasksStatus: status,
  }
}
