import { rest } from 'msw'

import { ProcessMSKFormServiceResponse } from 'src/features/msk/infrastructure'

import * as settings from 'src/config/settings'
import { processMSKFormServiceResponseMock } from 'src/__mocks__/responses/process-msk-form-service-response'

const url = `${settings.COMPASS_API_BASE_URL}/msk/process`

export const processMSKFormServiceResponseHandler =
  rest.post<ProcessMSKFormServiceResponse>(url, async (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(processMSKFormServiceResponseMock))
  })
