import { useCallback, useMemo } from 'react'

import {
  useGetAllTasksByPatientIdQuery,
  useGetDoneTasksByPatientIdQuery,
  useGetTodayTasksByPatientIdQuery,
  useGetUpcomingTasksByPatientIdQuery,
  useGetUrgentTasksByPatientIdQuery,
  useGetOverdueTasksByPatientIdQuery,
} from 'src/features/tasks/presentation'

type RefreshTasksProps = {
  all?: boolean
  upcoming?: boolean
  today?: boolean
  urgent?: boolean
  overdue?: boolean
  done?: boolean
}

type UseRefreshPatientTasks = () => {
  refreshPatientTasks: (props?: RefreshTasksProps) => void
  isRefreshingPatientTasks: boolean
}

export const useRefreshPatientTasks: UseRefreshPatientTasks = () => {
  const {
    patientAllTasksIsLoading,
    patientAllTasksIsFetching,
    refetchPatientAllTasks,
  } = useGetAllTasksByPatientIdQuery({
    params: { paginated: false },
  })
  const {
    patientDoneTasksIsLoading,
    patientDoneTasksIsFetching,
    refetchPatientDoneTasks,
  } = useGetDoneTasksByPatientIdQuery({
    params: { paginated: false, withUrgent: true },
  })
  const {
    patientTodayTasksIsFetching,
    patientTodayTasksIsLoading,
    refetchPatientTodayTasks,
  } = useGetTodayTasksByPatientIdQuery({
    params: { paginated: false, withUrgent: true },
  })
  const {
    patientUrgentTasksIsFetching,
    patientUrgentTasksIsLoading,
    refetchPatientUrgentTasks,
  } = useGetUrgentTasksByPatientIdQuery({
    params: { paginated: false },
  })
  const {
    patientUpcomingTasksIsFetching,
    patientUpcomingTasksIsLoading,
    refetchPatientUpcomingTasks,
  } = useGetUpcomingTasksByPatientIdQuery({
    params: { paginated: false, withUrgent: true },
  })
  const {
    patientOverdueTasksIsFetching,
    patientOverdueTasksIsLoading,
    refetchPatientOverdueTasks,
  } = useGetOverdueTasksByPatientIdQuery({
    params: { paginated: false, withUrgent: true },
  })

  const refreshPatientTasks = useCallback(
    (
      { all, upcoming, today, urgent, overdue, done }: RefreshTasksProps = {
        all: true,
        upcoming: true,
        today: true,
        urgent: true,
        overdue: true,
        done: true,
      }
    ) => {
      if (all) {
        refetchPatientAllTasks()
      }
      if (today) {
        refetchPatientTodayTasks()
      }
      if (urgent) {
        refetchPatientUrgentTasks()
      }
      if (overdue) {
        refetchPatientOverdueTasks()
      }
      if (done) {
        refetchPatientDoneTasks()
      }
      if (upcoming) {
        refetchPatientUpcomingTasks()
      }
    },
    [
      refetchPatientAllTasks,
      refetchPatientDoneTasks,
      refetchPatientOverdueTasks,
      refetchPatientTodayTasks,
      refetchPatientUpcomingTasks,
      refetchPatientUrgentTasks,
    ]
  )

  const isRefreshingPatientTasks = useMemo(() => {
    return (
      patientAllTasksIsLoading ||
      patientAllTasksIsFetching ||
      patientDoneTasksIsLoading ||
      patientDoneTasksIsFetching ||
      patientTodayTasksIsLoading ||
      patientTodayTasksIsFetching ||
      patientUrgentTasksIsLoading ||
      patientUrgentTasksIsFetching ||
      patientOverdueTasksIsLoading ||
      patientOverdueTasksIsFetching ||
      patientUpcomingTasksIsLoading ||
      patientUpcomingTasksIsFetching
    )
  }, [
    patientAllTasksIsLoading,
    patientAllTasksIsFetching,
    patientDoneTasksIsFetching,
    patientDoneTasksIsLoading,
    patientTodayTasksIsFetching,
    patientTodayTasksIsLoading,
    patientUrgentTasksIsFetching,
    patientUrgentTasksIsLoading,
    patientOverdueTasksIsFetching,
    patientOverdueTasksIsLoading,
    patientUpcomingTasksIsFetching,
    patientUpcomingTasksIsLoading,
  ])

  return {
    refreshPatientTasks,
    isRefreshingPatientTasks,
  }
}
