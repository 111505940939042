import { useQuery } from '@tanstack/react-query'

import { usePathwaysUseCases } from 'src/features/pathways/pathways.service-locator'
import {
  GetPathwaysError,
  GetPathwaysReturns,
} from 'src/features/pathways/domain'

export const GET_PATHWAYS_QUERY_KEY = 'getPathways'

export const useGetPathwaysQuery = () => {
  const pathwaysUseCases = usePathwaysUseCases()

  const { data, isLoading, isError } = useQuery<
    GetPathwaysReturns,
    GetPathwaysError
  >([GET_PATHWAYS_QUERY_KEY], () => {
    return pathwaysUseCases.getPathways()
  })
  return {
    pathways: data ? data.pathways : [],
    pathwayTypes: data
      ? data.pathwayTypes
      : { ortho: [], pt: [], 'self-care': [] },
    getPathwaysIsLoading: isLoading,
    getPathwaysIsError: isError,
  }
}
