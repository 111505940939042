import { Box, Divider, Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { FieldPath, FieldValues } from 'react-hook-form'

import { filterNoneValueOptions } from 'src/features/notes/adapters'
import {
  ControlledDatePicker,
  ControlledSelectField,
  ControlledTextField,
  INJECTION_TYPES,
  MISSED_REASONS,
  VISIT_OUTCOMES,
  YES_NO_OPTIONS,
} from 'src/features/shared/presentation'
import {
  NOTE_FIELD_LABEL,
  noteFieldLabelDefaultSx,
} from 'src/features/notes/presentation'
import { mapToControlledSelectItem } from 'src/features/shared/adapters'
import { NoteData } from 'src/features/notes/domain'

export type OrthoChartCheckQuestionsFormValues = Pick<
  NoteData,
  | 'completedAppointment'
  | 'reasonForMissingAppointment'
  | 'detailsOfMissedAppointment'
  | 'appointmentRescheduled'
  | 'outcomeOfVisit'
  | 'typeOfInjection'
  | 'outcomeNote'
  | 'typeOfInjectionText'
> & {
  appointmentDate?: string | null
  surgeryDate?: string | null
}

type OrthoChartCheckAdditionalQuestionsFormProps = {
  completedAppointmentFieldName?: FieldPath<FieldValues>
  outcomeOfVisitFieldName?: FieldPath<FieldValues>
  appointmentDateFieldName?: FieldPath<FieldValues>
  surgeryDateFieldName?: FieldPath<FieldValues>
  typeOfInjectionFieldName?: FieldPath<FieldValues>
  typeOfInjectionTextFieldName?: FieldPath<FieldValues>
  reasonForMissingAppointmentFieldName?: FieldPath<FieldValues>
  detailsOfMissedAppointmentFieldName?: FieldPath<FieldValues>
  appointmentRescheduledFieldName?: FieldPath<FieldValues>
  completedAppointmentRequired?: boolean
  outcomeOfVisitRequired?: boolean
  appointmentDateRequired?: boolean
  surgeryDateRequired?: boolean
  typeOfInjectionRequired?: boolean
  typeOfInjectionTextRequired?: boolean
  reasonForMissingAppointmentRequired?: boolean
  detailsOfMissedAppointmentRequired?: boolean
  appointmentRescheduledRequired?: boolean
}

export const OrthoChartCheckAdditionalQuestionsForm: FC<
  OrthoChartCheckAdditionalQuestionsFormProps
> = ({
  completedAppointmentRequired,
  outcomeOfVisitRequired,
  appointmentDateRequired,
  surgeryDateRequired,
  typeOfInjectionRequired,
  typeOfInjectionTextRequired,
  reasonForMissingAppointmentRequired,
  detailsOfMissedAppointmentRequired,
  appointmentRescheduledRequired,
  completedAppointmentFieldName = 'completedAppointment',
  outcomeOfVisitFieldName = 'outcomeOfVisit',
  appointmentDateFieldName = 'appointmentDate',
  surgeryDateFieldName = 'surgeryDate',
  typeOfInjectionFieldName = 'typeOfInjection',
  typeOfInjectionTextFieldName = 'typeOfInjectionText',
  reasonForMissingAppointmentFieldName = 'reasonForMissingAppointment',
  detailsOfMissedAppointmentFieldName = 'detailsOfMissedAppointment',
  appointmentRescheduledFieldName = 'appointmentRescheduled',
}) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: '8px',
          width: '170px',
          whiteSpace: 'nowrap',
          flexDirection: 'column',
        }}
      >
        <Divider />
        <Typography pb={'16px'} color={'grey'}>
          Chart Check Questions
        </Typography>
      </Box>
      <Stack direction={'column'} rowGap={'16px'}>
        <ControlledSelectField
          name={completedAppointmentFieldName}
          label={NOTE_FIELD_LABEL.completedAppointment}
          items={filterNoneValueOptions(YES_NO_OPTIONS).map(
            mapToControlledSelectItem
          )}
          selectSx={{ width: '88px' }}
          formLabelSx={noteFieldLabelDefaultSx}
          required={completedAppointmentRequired}
        />
        {reasonForMissingAppointmentRequired ? (
          <ControlledSelectField
            name={reasonForMissingAppointmentFieldName}
            label={NOTE_FIELD_LABEL.reasonForMissingAppointment}
            labelComponent={'inputLabel'}
            items={MISSED_REASONS.map(mapToControlledSelectItem)}
            required={reasonForMissingAppointmentRequired}
            formControlProps={{
              size: 'small',
            }}
            formControlSx={{
              width: '340px',
            }}
          />
        ) : null}
        {detailsOfMissedAppointmentRequired ? (
          <ControlledTextField
            name={detailsOfMissedAppointmentFieldName}
            required={detailsOfMissedAppointmentRequired}
            textFieldProps={{
              label: NOTE_FIELD_LABEL.detailsOfMissedAppointment,
            }}
            formControlSx={{ width: '668px' }}
          />
        ) : null}
        {appointmentRescheduledRequired ? (
          <ControlledSelectField
            name={appointmentRescheduledFieldName}
            label={NOTE_FIELD_LABEL.appointmentRescheduled}
            labelComponent={'inputLabel'}
            items={filterNoneValueOptions(YES_NO_OPTIONS).map(
              mapToControlledSelectItem
            )}
            required={appointmentRescheduledRequired}
            formControlProps={{
              size: 'small',
            }}
            formControlSx={{
              width: '340px',
            }}
          />
        ) : null}
        <ControlledSelectField
          name={outcomeOfVisitFieldName}
          label={NOTE_FIELD_LABEL.outcomeOfVisit}
          items={VISIT_OUTCOMES.map(mapToControlledSelectItem)}
          selectSx={{ width: '323px' }}
          formLabelSx={noteFieldLabelDefaultSx}
          required={outcomeOfVisitRequired}
        />
        {typeOfInjectionRequired ? (
          <>
            <ControlledSelectField
              name={typeOfInjectionFieldName}
              label={NOTE_FIELD_LABEL.typeOfInjection}
              labelComponent={'inputLabel'}
              items={INJECTION_TYPES.map(mapToControlledSelectItem)}
              formControlProps={{ size: 'small' }}
              formControlSx={{ width: '417px' }}
              required={typeOfInjectionRequired}
            />
            {typeOfInjectionTextRequired ? (
              <ControlledTextField
                name={typeOfInjectionTextFieldName}
                textFieldProps={{
                  label: NOTE_FIELD_LABEL.typeOfInjectionText,
                }}
                textFieldSx={{ width: '100%' }}
                required={typeOfInjectionTextRequired}
              />
            ) : null}
          </>
        ) : null}

        <Stack direction={'row'} py={'16px'} columnGap={'16px'}>
          <ControlledDatePicker
            name={appointmentDateFieldName}
            datePickerProps={{
              label: NOTE_FIELD_LABEL.appointmentDate,
              slotProps: {
                textField: {
                  required: appointmentDateRequired,
                  sx: {
                    flex: { xs: '0 1 265px' },
                  },
                },
              },
            }}
          />
          <ControlledDatePicker
            name={surgeryDateFieldName}
            datePickerProps={{
              label: NOTE_FIELD_LABEL.surgeryDate,
              slotProps: {
                textField: {
                  required: surgeryDateRequired,
                  sx: {
                    flex: { xs: '0 1 265px' },
                  },
                },
              },
            }}
          />
        </Stack>
      </Stack>
    </>
  )
}
