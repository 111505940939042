import { useQuery } from '@tanstack/react-query'

import {
  GetTasksByPatientIdParams,
  GetUrgentTasksByPatientIdReturns,
} from 'src/features/tasks/domain'
import { useTasksUseCases } from 'src/features/tasks/tasks.service-locator'
import { GetUrgentTasksByPatientIdServiceErrorResponse } from 'src/features/tasks/infrastructure'
import { usePatientId } from 'src/features/shared/presentation'

export const GET_URGENT_TASKS_BY_PATIENT_ID_QUERY_KEY =
  'getUrgentTasksByPatientId'

type UseGetUrgentTasksByPatientIdProps = {
  patientId?: string
  throwGetPatientIdError?: boolean
  params?: GetTasksByPatientIdParams
}

export const useGetUrgentTasksByPatientIdQuery = ({
  patientId,
  params,
  throwGetPatientIdError = true,
}: UseGetUrgentTasksByPatientIdProps = {}) => {
  const taskUseCase = useTasksUseCases()

  // TODO: find a better way to get the patient id
  const _patientId = usePatientId({ patientId, throwGetPatientIdError })

  const { data, refetch, isLoading, isError, isFetching, isRefetching } =
    useQuery<
      GetUrgentTasksByPatientIdReturns,
      GetUrgentTasksByPatientIdServiceErrorResponse
    >([GET_URGENT_TASKS_BY_PATIENT_ID_QUERY_KEY, _patientId], () => {
      return _patientId
        ? taskUseCase.getUrgentTasksByPatientId({
            patientId: _patientId,
            params,
          })
        : Promise.reject(
            new Error(
              'Patient ID is required as a parameter or in the url as path param.'
            )
          )
    })

  return {
    patientUrgentTasksData: data,
    patientUrgentTasks: data?.tasks ? data.tasks : [],
    refetchPatientUrgentTasks: refetch,
    patientUrgentTasksIsLoading: isLoading,
    patientUrgentTasksIsError: isError,
    patientUrgentTasksIsFetching: isFetching,
    patientUrgentTasksIsRefetching: isRefetching,
  }
}
