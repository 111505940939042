import { useLocation } from 'react-router-dom'

import { useQueryParams } from 'src/features/shared/presentation'

export type EligiblePatientsQueryParams = {
  limit: string
  page: string
  q: string
  mbi: string
}

export type EligiblePatientsQueryParam = keyof EligiblePatientsQueryParams

const DEFAULT_ROWS_PER_PAGE = '25'
const DEFAULT_PAGE = '1'
const QUERY_PARAM_PREFIX = 'eligibility-'

const getPreffixedQueryParam = (key: EligiblePatientsQueryParam) =>
  `${QUERY_PARAM_PREFIX}${key}`

export const useEligiblePatientsQueryParams = () => {
  const location = useLocation()
  const {
    getQueryParam: originalGetQueryParam,
    setQueryParam,
    updateQueryParams,
    setSearchParams,
  } = useQueryParams()

  const getQueryParam = (key: EligiblePatientsQueryParam) => {
    const preffixedParam = getPreffixedQueryParam(key)
    return originalGetQueryParam(preffixedParam)
  }

  const getInitialURLSearchParams = () => {
    return new URLSearchParams(location.search)
  }

  const getInitialURLSearchParamValue = (param: EligiblePatientsQueryParam) => {
    const preffixedParam = getPreffixedQueryParam(param)
    return getInitialURLSearchParams().get(preffixedParam)
  }

  const getLimit = () => {
    const queryParamValue =
      getQueryParam('limit') || getInitialURLSearchParamValue('limit')
    return queryParamValue || DEFAULT_ROWS_PER_PAGE
  }

  const getPage = () => {
    const queryParamValue =
      getQueryParam('page') || getInitialURLSearchParamValue('page')
    return queryParamValue || DEFAULT_PAGE
  }

  const getQ = () => getQueryParam('q') || getInitialURLSearchParamValue('q')

  const getMbi = () =>
    getQueryParam('mbi') || getInitialURLSearchParamValue('mbi')

  const setLimit = (limit: string) =>
    setQueryParam(getPreffixedQueryParam('limit'), limit)

  const setPage = (page: string) =>
    setQueryParam(getPreffixedQueryParam('page'), page)

  const setQ = (q: string) => {
    setSearchParams((prevParams) => {
      prevParams.set(getPreffixedQueryParam('q'), q)
      prevParams.delete(getPreffixedQueryParam('mbi'))
      prevParams.delete(getPreffixedQueryParam('page'))
      prevParams.delete(getPreffixedQueryParam('limit'))
      return prevParams
    })
  }

  const setMbi = (mbi?: string) => {
    setSearchParams((prevParams) => {
      prevParams.set(getPreffixedQueryParam('mbi'), mbi || '')
      prevParams.delete(getPreffixedQueryParam('q'))
      prevParams.delete(getPreffixedQueryParam('page'))
      prevParams.delete(getPreffixedQueryParam('limit'))
      return prevParams
    })
  }

  const deleteEligiblePatientsQueryParams = (
    params: EligiblePatientsQueryParam[]
  ) => {
    setSearchParams((prevParams) => {
      params.forEach((param) =>
        prevParams.delete(getPreffixedQueryParam(param))
      )
      return prevParams
    })
  }

  const deleteAllEligiblePatientsQueryParams = () => {
    setSearchParams((prevParams) => {
      prevParams.delete(getPreffixedQueryParam('q'))
      prevParams.delete(getPreffixedQueryParam('mbi'))
      prevParams.delete(getPreffixedQueryParam('page'))
      prevParams.delete(getPreffixedQueryParam('limit'))
      return prevParams
    })
  }

  const updateEligiblePatientsQueryParams = (
    params: Partial<EligiblePatientsQueryParams>
  ) => {
    const preffixedParams = Object.entries(params).reduce(
      (acc, [key, value]) => {
        acc[getPreffixedQueryParam(key as EligiblePatientsQueryParam)] = value
        return acc
      },
      {} as Record<string, string>
    )

    updateQueryParams(preffixedParams)
  }

  return {
    getLimit,
    setLimit,
    getPage,
    setPage,
    getQ,
    setQ,
    getMbi,
    setMbi,
    updateEligiblePatientsQueryParams,
    deleteEligiblePatientsQueryParams,
    deleteAllEligiblePatientsQueryParams,
  }
}
