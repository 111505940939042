import { useCallback } from 'react'

import {
  CreateEnablingTherapyService,
  CreateEnablingTherapyServiceResponse,
  GetPatientIntakeFlowsService,
  GetPatientIntakeFlowsServiceResponse,
  UpdateIntakeService,
  UpdateIntakeServiceResponse,
} from 'src/features/msk/infrastructure'
import { useCompassApiClient } from 'src/features/shared/infrastructure'
import * as settings from 'src/config/settings'

export const usePatientIntakeFlowsService = () => {
  const { getCompassApiClient } = useCompassApiClient()

  const getPatientIntakeFlows: GetPatientIntakeFlowsService = useCallback(
    async (patientId, params) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.get<GetPatientIntakeFlowsServiceResponse>(
        settings.COMPASS_API_BASE_URL + `/msk/${patientId}/intake-flows`,
        {
          params: params,
        }
      )
    },
    [getCompassApiClient]
  )

  const updateIntake: UpdateIntakeService = useCallback(
    async ({ intakeId, payload }) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.put<UpdateIntakeServiceResponse>(
        settings.COMPASS_API_BASE_URL + `/msk/${intakeId}`,
        payload
      )
    },
    [getCompassApiClient]
  )

  const createEnablingTherapy: CreateEnablingTherapyService = useCallback(
    async ({ intakeId, payload }) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.post<CreateEnablingTherapyServiceResponse>(
        settings.COMPASS_API_BASE_URL + `/msk/${intakeId}/enabling-therapies`,
        payload
      )
    },
    [getCompassApiClient]
  )

  return {
    getPatientIntakeFlows,
    updateIntake,
    createEnablingTherapy,
  }
}
