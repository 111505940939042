import { InfiniteData } from '@tanstack/react-query'
import { format } from 'date-fns'

import { formatDate } from 'src/features/shared/utils'
import {
  CreateTaskArgs,
  CreateTaskError,
  CreateTaskReturns,
  DoneTask,
  GetOverdueTasksByUserIdError,
  GetOverdueTasksByUserIdReturns,
  GetTasksByUserIdArgs,
  GetAllTasksByPatientIdError,
  GetAllTasksByPatientIdReturns,
  GetTasksParams,
  GetTodayTasksByUserIdError,
  GetTodayTasksByUserIdReturns,
  GetUpcomingTasksByUserIdError,
  GetUpcomingTasksByUserIdReturns,
  GetUrgentTasksByUserIdError,
  GetUrgentTasksByUserIdReturns,
  Task,
  TaskComment,
  UpdateTaskArgs,
  UpdateTaskError,
  UpdateTaskReturns,
  UpdateTasksArgs,
  UpdateTasksError,
  UpdateTasksReturns,
  GetUpcomingTasksByPatientIdReturns,
  GetTodayTasksByPatientIdReturns,
  GetDoneTasksByPatientIdReturns,
  GetUrgentTasksByPatientIdReturns,
  GetTasksByPatientIdArgs,
  GetOverdueTasksByPatientIdReturns,
  GetDoneTasksByUserIdReturns,
  GetDoneTasksByUserIdError,
  GetAllTasksByUserIdError,
  GetAllTasksByUserIdReturns,
} from 'src/features/tasks/domain'
import {
  CreateTaskServiceErrorResponse,
  CreateTaskServicePayload,
  CreateTaskServiceResponse,
  GetTasksServiceArgs,
  GetTasksServiceParams,
  GetTodayTasksByUserIdServiceErrorResponse,
  GetTodayTasksByUserIdServiceResponse,
  GetUrgentTasksByUserIdServiceErrorResponse,
  GetUrgentTasksByUserIdServiceResponse,
  GetOverdueTasksByUserIdServiceErrorResponse,
  GetOverdueTasksByUserIdServiceResponse,
  GetUpcomingTasksByUserIdServiceErrorResponse,
  GetUpcomingTasksByUserIdServiceResponse,
  STaskComment,
  UpdateTaskServiceArgs,
  UpdateTaskServiceErrorResponse,
  UpdateTaskServiceResponse,
  UpdateTasksServicePayload,
  UpdateTasksServiceErrorResponse,
  UpdateTasksServiceResponse,
  SDoneTask,
  GetAllTasksByPatientIdServiceResponse,
  STask,
  GetAllTasksByPatientIdServiceErrorResponse,
  GetUpcomingTasksByPatientIdServiceResponse,
  GetOverdueTasksByPatientIdServiceResponse,
  GetTodayTasksByPatientIdServiceResponse,
  GetDoneTasksByPatientIdServiceResponse,
  GetUpcomingTasksByPatientIdServiceErrorResponse,
  GetUrgentTasksByPatientIdServiceResponse,
  GetUrgentTasksByPatientIdServiceErrorResponse,
  GetTasksByPatientIdServiceArgs,
  GetDoneTasksByPatientIdServiceErrorResponse,
  GetTodayTasksByPatientIdServiceErrorResponse,
  GetOverdueTasksByPatientIdServiceErrorResponse,
  GetDoneTasksByUserIdServiceResponse,
  GetDoneTasksByUserIdServiceErrorResponse,
  GetAllTasksByUserIdServiceResponse,
  GetAllTasksByUserIdServiceErrorResponse,
} from 'src/features/tasks/infrastructure'
import {
  DoneTaskGroupedByDate,
  PatientTouchpointsPathwayInfo,
  TaskGroupedByDate,
} from 'src/features/tasks/presentation'
import { TaskCalendarEvent } from 'src/features/shared/presentation'
import { IntakeAssociatedPathway, Intake } from 'src/features/msk/domain'

// -------------------------
// GET TASKS BY USER ID
// ------------------------

type MapToGetTasksServiceParams = (
  params?: GetTasksParams
) => GetTasksServiceParams | undefined

export const mapToGetTasksServiceParams: MapToGetTasksServiceParams = (
  params
) => {
  if (!params) return undefined
  return {
    lastTaskId: params.lastTaskId,
    lastTaskDate: params.lastTaskDate,
    limit: params.limit,
    withUrgent: params.withUrgent,
    all: params.paginated !== undefined ? !params.paginated : false,
    fromDate: params.fromDate,
    toDate: params.toDate,
  }
}

type MapToGetTasksServiceArgs = (
  args?: GetTasksByUserIdArgs
) => GetTasksServiceArgs | undefined

export const mapToGetTasksServiceArgs: MapToGetTasksServiceArgs = (args) => {
  if (!args) return undefined

  if (args.params) {
    return {
      ...args,
      params: mapToGetTasksServiceParams(args.params),
    }
  }
}

type MapToTaskComment = (sTaskComment: STaskComment) => TaskComment

export const mapToTaskComment: MapToTaskComment = (sTaskComment) => {
  const createdAt = format(
    new Date(sTaskComment.createdAt),
    "MM/dd/yy 'at' p"
  ).toLowerCase()

  const header = `${sTaskComment.name} commented ${createdAt}`
  return {
    ...sTaskComment,
    id: sTaskComment.eventCommentId,
    body: sTaskComment.comment,
    createdBy: sTaskComment.name,
    header,
  }
}

type MapToTaskComments = (sTaskComments: STask['comments']) => Task['comments']

const mapToTaskComments: MapToTaskComments = (sTaskComments) => {
  const comments: Task['comments'] = sTaskComments
    ? sTaskComments.map(mapToTaskComment)
    : []
  return comments
}

type MapToTask = (sTask: STask) => Task

export const mapToTask: MapToTask = (sTask) => {
  return {
    assignedPathwayId: sTask.assignedPathwayId,
    assignedTCMemberName: sTask.assignedTCMember,
    assignedUserId: sTask.assignedUserId,
    comments: mapToTaskComments(sTask.comments),
    completedAt: sTask.completedAt ? formatDate(sTask.completedAt, true) : null,
    description: sTask.description,
    id: sTask.eventId,
    patientId: sTask.patientId,
    patientName: sTask.name,
    status: sTask.status,
    title: sTask.title,
    urgent: sTask.urgent,
    dueDate: formatDate(sTask.dueDate, true),
  }
}

type MapToDoneTask = (sDoneTask: SDoneTask) => DoneTask

export const mapToDoneTask: MapToDoneTask = (sDoneTask) => {
  return {
    ...sDoneTask,
    assignedTCMemberName: sDoneTask.assignedTCMember,
    comments: mapToTaskComments(sDoneTask.comments),
    id: sDoneTask.eventId,
    patientName: sDoneTask.name,
    dueDate: formatDate(sDoneTask.dueDate, true),
    completedAt: formatDate(sDoneTask.completedAt, true),
  }
}

type MapToGetAllTasksByUserIdReturns = (
  response: GetAllTasksByUserIdServiceResponse
) => GetAllTasksByUserIdReturns

export const mapToGetAllTasksByUserIdReturns: MapToGetAllTasksByUserIdReturns =
  (response) => {
    return {
      tasks: response.tasks.map(mapToTask),
      lastTaskId: response.lastTaskId,
      lastTaskDate: response.lastTaskDate,
    }
  }

type MapToGetAllTasksByUserIdError = (
  response: GetAllTasksByUserIdServiceErrorResponse
) => GetAllTasksByUserIdError

export const mapToGetAllTasksByUserIdError: MapToGetAllTasksByUserIdError = (
  response
) => {
  return {
    message: response.message,
  }
}

type MapToGetTodayTasksByUserIdReturns = (
  response: GetTodayTasksByUserIdServiceResponse
) => GetTodayTasksByUserIdReturns

export const mapToGetTodayTasksByUserIdReturns: MapToGetTodayTasksByUserIdReturns =
  (response) => {
    const todayDate = formatDate(new Date())
    return {
      tasks: response.today.map(mapToTask),
      lastTaskId: response.lastTaskId,
      lastTaskDate: response.lastTaskDate,
      todayDate,
    }
  }

type MapToGetTodayTasksByUserIdError = (
  response: GetTodayTasksByUserIdServiceErrorResponse
) => GetTodayTasksByUserIdError

export const mapToGetTodayTasksByUserIdError: MapToGetTodayTasksByUserIdError =
  (response) => {
    return {
      message: response.message,
    }
  }

type MapToGetUrgentTasksByUserIdReturns = (
  response: GetUrgentTasksByUserIdServiceResponse
) => GetUrgentTasksByUserIdReturns

export const mapToGetUrgentTasksByUserIdReturns: MapToGetUrgentTasksByUserIdReturns =
  (response) => {
    return {
      tasks: response.urgent.map(mapToTask),
      lastTaskId: response.lastTaskId,
      lastTaskDate: response.lastTaskDate,
    }
  }

type MapToGetUrgentTasksByUserIdError = (
  response: GetUrgentTasksByUserIdServiceErrorResponse
) => GetUrgentTasksByUserIdError

export const mapToGetUrgentTasksByUserIdError: MapToGetUrgentTasksByUserIdError =
  (response) => {
    return {
      message: response.message,
    }
  }

type MapToGetOverdueTasksByUserIdReturns = (
  response: GetOverdueTasksByUserIdServiceResponse
) => GetOverdueTasksByUserIdReturns

export const mapToGetOverdueTasksByUserIdReturns: MapToGetOverdueTasksByUserIdReturns =
  (response) => {
    return {
      tasks: response.overdue.map(mapToTask),
      lastTaskId: response.lastTaskId,
      lastTaskDate: response.lastTaskDate,
    }
  }

type MapToGetOverdueTasksByUserIdError = (
  response: GetOverdueTasksByUserIdServiceErrorResponse
) => GetOverdueTasksByUserIdError

export const mapToGetOverdueTasksByUserIdError: MapToGetOverdueTasksByUserIdError =
  (response) => {
    return {
      message: response.message,
    }
  }

type MapToGetDoneTasksByUserIdReturns = (
  response: GetDoneTasksByUserIdServiceResponse
) => GetDoneTasksByUserIdReturns

export const mapToGetDoneTasksByUserIdReturns: MapToGetDoneTasksByUserIdReturns =
  (response) => {
    return {
      tasks: response.done.map(mapToDoneTask),
      lastTaskId: response.lastTaskId,
      lastTaskDate: response.lastTaskDate,
    }
  }

type MapToGetDoneTaskGroupedByDate = (
  data: InfiniteData<GetDoneTasksByUserIdReturns>
) => DoneTaskGroupedByDate

export const mapToGetDoneTaskGroupedByDate: MapToGetDoneTaskGroupedByDate = (
  data
) => {
  // get all done tasks in one array
  const DoneTasks = data.pages.reduce(
    (acc: GetDoneTasksByUserIdReturns['tasks'], page) => {
      return [...acc, ...page.tasks]
    },
    []
  )

  // group tasks by completed date
  const groupedTasks: DoneTaskGroupedByDate = {}
  DoneTasks.forEach((task) => {
    if (!groupedTasks[task.completedAt]) {
      groupedTasks[task.completedAt] = []
    }
    groupedTasks[task.completedAt].push(task)
  })

  // order grouped tasks by completed date
  return Object.keys(groupedTasks)
    .sort((a, b) => {
      return new Date(b).getTime() - new Date(a).getTime()
    })
    .reduce((acc: DoneTaskGroupedByDate, key) => {
      acc[key] = groupedTasks[key]
      return acc
    }, {})
}

type MapToGetDoneTasksByUserIdError = (
  response: GetDoneTasksByUserIdServiceErrorResponse
) => GetDoneTasksByUserIdError

export const mapToGetDoneTasksByUserIdError: MapToGetDoneTasksByUserIdError = (
  response
) => {
  return {
    message: response.message,
  }
}

type MapToGetUpcomingTasksByUserIdReturns = (
  response: GetUpcomingTasksByUserIdServiceResponse
) => GetUpcomingTasksByUserIdReturns

export const mapToGetUpcomingTasksByUserIdReturns: MapToGetUpcomingTasksByUserIdReturns =
  (response) => {
    return {
      tasks: response.upcoming.map(mapToTask),
      lastTaskId: response.lastTaskId,
      lastTaskDate: response.lastTaskDate,
    }
  }

type MapToGetUpcomingTaskGroupedByDate = (
  data: InfiniteData<GetUpcomingTasksByUserIdReturns>
) => TaskGroupedByDate

export const mapToGetUpcomingTaskGroupedByDate: MapToGetUpcomingTaskGroupedByDate =
  (data) => {
    // get all done tasks in one array
    const upcomingTasks = data.pages.reduce(
      (acc: GetUpcomingTasksByUserIdReturns['tasks'], page) => {
        return [...acc, ...page.tasks]
      },
      []
    )

    // group tasks by completed date
    const groupedTasks: TaskGroupedByDate = {}
    upcomingTasks.forEach((task) => {
      if (!groupedTasks[task.dueDate]) {
        groupedTasks[task.dueDate] = []
      }
      groupedTasks[task.dueDate].push(task)
    })

    // order tasks by due date
    return Object.keys(groupedTasks)
      .sort((a, b) => {
        return new Date(a).getTime() - new Date(b).getTime()
      })
      .reduce((acc: TaskGroupedByDate, key) => {
        acc[key] = groupedTasks[key]
        return acc
      }, {})
  }

type MapToGetUpcomingTasksByUserIdError = (
  response: GetUpcomingTasksByUserIdServiceErrorResponse
) => GetUpcomingTasksByUserIdError

export const mapToGetUpcomingTasksByUserIdError: MapToGetUpcomingTasksByUserIdError =
  (response) => {
    return {
      message: response.message,
    }
  }

// -------------------------
// UPDATE TASK
// ------------------------

type MapToUpdateTaskError = (
  error: UpdateTaskServiceErrorResponse
) => UpdateTaskError

export const mapToUpdateTaskError: MapToUpdateTaskError = (error) => {
  return {
    message: error.message,
  }
}
type MapToUpdateTaskReturns = (
  response: UpdateTaskServiceResponse
) => UpdateTaskReturns

export const mapToUpdateTaskReturns: MapToUpdateTaskReturns = (response) =>
  mapToTask(response)

type MapToUpdateTaskServiceArgs = (
  args: UpdateTaskArgs
) => UpdateTaskServiceArgs

export const mapToUpdateTaskServiceArgs: MapToUpdateTaskServiceArgs = (
  args
) => {
  const payload = args.payload

  if (payload && !payload?.comment) {
    delete payload.comment
  }

  return {
    taskId: args.taskId,
    payload: args.payload,
  }
}

// -------------------------
// UPDATE TASKS
// ------------------------

type MapToUpdateTasksError = (
  error: UpdateTasksServiceErrorResponse
) => UpdateTasksError

export const mapToUpdateTasksError: MapToUpdateTasksError = (error) => error
type MapToUpdateTasksReturns = (
  response: UpdateTasksServiceResponse
) => UpdateTasksReturns

export const mapToUpdateTasksReturns: MapToUpdateTasksReturns = (response) =>
  response
type MapToUpdateTasksServiceArgs = (
  args: UpdateTasksArgs
) => UpdateTasksServicePayload

export const mapToUpdateTasksServiceArgs: MapToUpdateTasksServiceArgs = (
  args
) => args

// -------------------------
// CREATE TASK
// ------------------------

type MapToCreateTaskServicePayload = (
  args: CreateTaskArgs
) => CreateTaskServicePayload

export const mapToCreateTaskServicePayload: MapToCreateTaskServicePayload = (
  args
) => {
  return {
    assignedUserId: args.assignedUserId,
    patientId: args.patientId,
    title: args.title,
    description: args.description,
    dueDate: args.dueDate,
    urgent: args.urgent,
  }
}

type MapToCreateTaskReturns = (
  response: CreateTaskServiceResponse
) => CreateTaskReturns

export const mapToCreateTaskReturns: MapToCreateTaskReturns = (response) =>
  mapToTask(response)

type MapToCreateTaskError = (
  error: CreateTaskServiceErrorResponse
) => CreateTaskError

export const mapToCreateTaskError: MapToCreateTaskError = (error) => {
  return {
    message: error.message,
  }
}

// -------------------------
// GET TASKS BY PATIENT ID
// ------------------------

type MapToGetTasksByPatientIdServiceArgs = (
  args: GetTasksByPatientIdArgs
) => GetTasksByPatientIdServiceArgs

export const mapToGetTasksByPatientIdServiceArgs: MapToGetTasksByPatientIdServiceArgs =
  (args) => {
    return {
      ...args,
      params: mapToGetTasksServiceParams(args.params),
    }
  }

export type MapToGetAllTasksByPatientId = (
  response: GetAllTasksByPatientIdServiceResponse
) => GetAllTasksByPatientIdReturns

export const mapToGetAllTasksByPatientId: MapToGetAllTasksByPatientId = (
  response
) => {
  return {
    tasks: response.tasks.map(mapToTask),
    lastTaskId: response.lastTaskId,
    lastTaskDate: response.lastTaskDate,
  }
}

type MapToGetAllTasksByPatientIdError = (
  error: GetAllTasksByPatientIdServiceErrorResponse
) => GetAllTasksByPatientIdError

export const mapToGetTaskByPatientIdError: MapToGetAllTasksByPatientIdError = (
  error
) => {
  return {
    message: error.message,
  }
}

type MapToGetUpcomingTasksByPatientIdReturns = (
  response: GetUpcomingTasksByPatientIdServiceResponse
) => GetUpcomingTasksByPatientIdReturns

export const mapToGetUpcomingTasksByPatientIdReturns: MapToGetUpcomingTasksByPatientIdReturns =
  (response) => {
    return {
      tasks: response.upcoming.map(mapToTask),
      lastTaskId: response.lastTaskId,
      lastTaskDate: response.lastTaskDate,
    }
  }

type MapToGetUpcomingTasksByPatientIdError = (
  response: GetUpcomingTasksByPatientIdServiceErrorResponse
) => GetUpcomingTasksByPatientIdServiceErrorResponse

export const mapToGetUpcomingTasksByPatientIdError: MapToGetUpcomingTasksByPatientIdError =
  (response) => {
    return {
      message: response.message,
    }
  }

type MapToGetUrgentTasksByPatientIdReturns = (
  response: GetUrgentTasksByPatientIdServiceResponse
) => GetUrgentTasksByPatientIdReturns

export const mapToGetUrgentTasksByPatientIdReturns: MapToGetUrgentTasksByPatientIdReturns =
  (response) => {
    return {
      tasks: response.urgent.map(mapToTask),
      lastTaskId: response.lastTaskId,
      lastTaskDate: response.lastTaskDate,
    }
  }

type MapToGetUrgentTasksByPatientIdError = (
  response: GetUrgentTasksByPatientIdServiceErrorResponse
) => GetUrgentTasksByPatientIdServiceErrorResponse

export const mapToGetUrgentTasksByPatientIdError: MapToGetUrgentTasksByPatientIdError =
  (response) => {
    return {
      message: response.message,
    }
  }

type MapToGetDoneTasksByPatientIdReturns = (
  response: GetDoneTasksByPatientIdServiceResponse
) => GetDoneTasksByPatientIdReturns

export const mapToGetDoneTasksByPatientIdReturns: MapToGetDoneTasksByPatientIdReturns =
  (response) => {
    return {
      tasks: response.done.map(mapToTask),
      lastTaskId: response.lastTaskId,
      lastTaskDate: response.lastTaskDate,
    }
  }

type MapToGetDoneTasksByPatientIdError = (
  response: GetDoneTasksByPatientIdServiceErrorResponse
) => GetDoneTasksByPatientIdServiceErrorResponse

export const mapToGetDoneTasksByPatientIdError: MapToGetDoneTasksByPatientIdError =
  (response) => {
    return {
      message: response.message,
    }
  }

type MapToGetTodayTasksByPatientIdReturns = (
  response: GetTodayTasksByPatientIdServiceResponse
) => GetTodayTasksByPatientIdReturns

export const mapToGetTodayTasksByPatientIdReturns: MapToGetTodayTasksByPatientIdReturns =
  (response) => {
    return {
      tasks: response.today.map(mapToTask),
      lastTaskId: response.lastTaskId,
      lastTaskDate: response.lastTaskDate,
    }
  }

type MapToGetTodayTasksByPatientIdError = (
  response: GetTodayTasksByPatientIdServiceErrorResponse
) => GetTodayTasksByPatientIdServiceErrorResponse

export const mapToGetTodayTasksByPatientIdError: MapToGetTodayTasksByPatientIdError =
  (response) => {
    return {
      message: response.message,
    }
  }

type MapToGetOverdueTasksByPatientIdReturns = (
  response: GetOverdueTasksByPatientIdServiceResponse
) => GetOverdueTasksByPatientIdReturns

export const mapToGetOverdueTasksByPatientIdReturns: MapToGetOverdueTasksByPatientIdReturns =
  (response) => {
    return {
      tasks: response.overdue.map(mapToTask),
      lastTaskId: response.lastTaskId,
      lastTaskDate: response.lastTaskDate,
    }
  }

type MapToGetOverdueTasksByPatientIdError = (
  response: GetOverdueTasksByPatientIdServiceErrorResponse
) => GetOverdueTasksByPatientIdServiceErrorResponse

export const mapToGetOverdueTasksByPatientIdError: MapToGetOverdueTasksByPatientIdError =
  (response) => {
    return {
      message: response.message,
    }
  }

export type MapToTaskCalendarEvent = (task: Task) => TaskCalendarEvent

export const mapToTaskCalendarEvent: MapToTaskCalendarEvent = (task) => {
  const start = new Date(task.dueDate)
  const end = new Date(task.dueDate)
  start.setHours(0)
  end.setHours(23)
  return {
    taskTitle: task.title,
    allDay: true,
    start,
    end,
    task,
  }
}

type GroupTasksByPathway = (
  tasks: Task[],
  intakesPathwaysIds: IntakeAssociatedPathway['id'][]
) => { [key: IntakeAssociatedPathway['id']]: Task[] }

export const groupTasksByPathway: GroupTasksByPathway = (
  tasks,
  intakesPathwaysIds
) => {
  return tasks
    .filter(
      (task) =>
        task.assignedPathwayId &&
        intakesPathwaysIds.includes(task.assignedPathwayId)
    )
    .reduce((acc, task) => {
      if (task.assignedPathwayId) {
        if (acc[task.assignedPathwayId]) {
          acc[task.assignedPathwayId].push(task)
        } else {
          acc[task.assignedPathwayId] = [task]
        }
      }
      return acc
    }, {} as { [key: string]: Task[] })
}

type MapToPatientTouchpointsPathwayInfo = (
  intakes: Intake[]
) => PatientTouchpointsPathwayInfo

export const mapToPatientTouchpointsPathwayInfo: MapToPatientTouchpointsPathwayInfo =
  (intakes) => {
    const result: PatientTouchpointsPathwayInfo = {}

    intakes.forEach((intake) => {
      if (intake.associatedPathway) {
        result[intake.associatedPathway.id] = intake.botheredBodyPart
      }
    })

    return result
  }
