import { rest } from 'msw'

import { GetMyCompassUserServiceResponse } from 'src/features/shared/infrastructure'

import * as settings from 'src/config/settings'
import { getMyCompassUserServiceResponseMock } from 'src/__mocks__/responses/get-my-compass-user-service-response'

const delay = process.env.NODE_ENV === 'test' ? 0 : 2000

const url = `${settings.COMPASS_API_BASE_URL}/users/me`

export const getMyCompassUserServiceResponseHandler =
  rest.get<GetMyCompassUserServiceResponse>(url, async (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(delay),
      ctx.json(getMyCompassUserServiceResponseMock)
    )
  })
