import { useQuery } from '@tanstack/react-query'

import { useTasksUseCases } from 'src/features/tasks/tasks.service-locator'
import { GetTasksParams } from 'src/features/tasks/domain'

export const GET_OVERDUE_TASKS_BY_USER_ID_QUERY_KEY = 'getOverdueTasksByUserId'

type UseGetOverdueTasksByUserIdArgs = {
  params?: GetTasksParams
}

export const useGetOverdueTasksByUserIdQuery = ({
  params,
}: UseGetOverdueTasksByUserIdArgs = {}) => {
  const tasksUseCases = useTasksUseCases()

  const { data, isLoading, isError, refetch, isFetching } = useQuery({
    queryKey: [GET_OVERDUE_TASKS_BY_USER_ID_QUERY_KEY, params],
    queryFn: () => {
      return tasksUseCases.getOverdueTasksByUserId({
        params,
      })
    },
  })
  return {
    userOverdueTasksData: data,
    userOverdueTasks: data?.tasks ? data.tasks : [],
    refetchUserOverdueTasks: refetch,
    userOverdueTasksIsLoading: isLoading,
    userOverdueTasksIsError: isError,
    userOverdueTasksIsFetching: isFetching,
  }
}
