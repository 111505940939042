import axios from 'axios'

import { PathwaysUseCases } from 'src/features/pathways/domain'
import { usePathwaysService } from 'src/features/pathways/infrastructure'
import {
  mapToGetPathwaysReturns,
  mapToCreatePathwayServicePayload,
  mapToCreatePathwayError,
  mapToCreatePathwayReturns,
  mapToReassignPathwayServicePayload,
  mapToReassignPathwayReturns,
  mapToReassignPathwayError,
  mapToUpdatePathwayServiceArgs,
  mapToUpdatePathwayReturns,
  mapToUpdatePathwayError,
  mapToGetPathwaysError,
} from 'src/features/pathways/adapters'
import { useAxiosErrorHandling } from 'src/features/shared/infrastructure'

export const usePathwaysServiceAdapter = () => {
  const pathwaysService = usePathwaysService()
  const { handleAxiosError } = useAxiosErrorHandling()

  const getPathways: PathwaysUseCases['getPathways'] = async () => {
    try {
      const response = await pathwaysService.getPathways()

      return mapToGetPathwaysReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToGetPathwaysError)
      }
      throw error
    }
  }

  const createPathway: PathwaysUseCases['createPathway'] = async (args) => {
    try {
      const serviceArgs = mapToCreatePathwayServicePayload(args)

      const response = await pathwaysService.createPathway(serviceArgs)

      return mapToCreatePathwayReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToCreatePathwayError)
      }
      throw error
    }
  }

  const reassignPathway: PathwaysUseCases['reassignPathway'] = async (args) => {
    try {
      const serviceArgs = mapToReassignPathwayServicePayload(args)

      const response = await pathwaysService.reassignPathway(serviceArgs)

      return mapToReassignPathwayReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToReassignPathwayError)
      }
      throw error
    }
  }

  const updatePathway: PathwaysUseCases['updatePathway'] = async (args) => {
    try {
      const serviceArgs = mapToUpdatePathwayServiceArgs(args)

      const response = await pathwaysService.updatePathway(serviceArgs)

      return mapToUpdatePathwayReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToUpdatePathwayError)
      }
      throw error
    }
  }

  return {
    getPathways,
    createPathway,
    reassignPathway,
    updatePathway,
  }
}
