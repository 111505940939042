import { Dispatch, SetStateAction, createContext } from 'react'
import { AmplifyUser } from '@aws-amplify/ui'

import { CompassUser } from 'src/features/shared/domain'

export type AuthStore = {
  amplifyUser: AmplifyUser | null
  myCompassUser: CompassUser | null
  isAuthenticated: boolean
  authIsLoading: boolean
  setAuthIsLoading: Dispatch<SetStateAction<boolean>>
  authError?: string
  logout: () => Promise<void>
  getTokens: () => Promise<{
    idToken: string
    accessToken: string
  }>
}

const AuthContext = createContext<AuthStore>({
  amplifyUser: null,
  myCompassUser: null,
  isAuthenticated: false,
  authIsLoading: false,
  setAuthIsLoading: () => {},
  authError: undefined,
  logout: () => Promise.resolve(),
  getTokens: () => Promise.resolve({ idToken: '', accessToken: '' }),
})

AuthContext.displayName = 'AuthContext'

export { AuthContext }
