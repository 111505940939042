import {
  UseMutateFunction,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import { usePatientId } from 'src/features/shared/presentation'
import {
  Task,
  UpdateTaskArgs,
  UpdateTaskError,
} from 'src/features/tasks/domain'
import {
  useUserSidebarTasks,
  GET_ALL_TASKS_BY_PATIENT_ID_QUERY_KEY,
  GET_DONE_TASKS_BY_PATIENT_ID_QUERY_KEY,
  GET_URGENT_TASKS_BY_PATIENT_ID_QUERY_KEY,
  GET_UPCOMING_TASKS_BY_PATIENT_ID_QUERY_KEY,
  GET_OVERDUE_TASKS_BY_PATIENT_ID_QUERY_KEY,
  GET_TODAY_TASKS_BY_PATIENT_ID_QUERY_KEY,
} from 'src/features/tasks/presentation'
import { useTasksUseCases } from 'src/features/tasks/tasks.service-locator'

type useUpdateTaskProps = Omit<
  UseMutationOptions<Task, UpdateTaskError, UpdateTaskArgs, unknown>,
  'mutationFn'
>

type UseUpdateTask = (props?: useUpdateTaskProps) => {
  updateTask: UseMutateFunction<Task, UpdateTaskError, UpdateTaskArgs, unknown>
  updateTaskIsLoading: boolean
  updateTaskStatus: string
}

export const useUpdateTask: UseUpdateTask = (props = {}) => {
  const tasksUseCases = useTasksUseCases()
  const { refreshUserSidebarTasks } = useUserSidebarTasks()

  const { onSuccess } = props

  const _patientId = usePatientId({ throwGetPatientIdError: false })

  const queryClient = useQueryClient()

  const { mutate, isLoading, status } = useMutation<
    Task,
    UpdateTaskError,
    UpdateTaskArgs,
    unknown
  >({
    mutationFn: (args: UpdateTaskArgs) => {
      return tasksUseCases.updateTask(args)
    },
    onSuccess: (data, variables, context) => {
      refreshUserSidebarTasks()
      if (_patientId) {
        queryClient.invalidateQueries([
          GET_ALL_TASKS_BY_PATIENT_ID_QUERY_KEY,
          _patientId,
        ])
        queryClient.invalidateQueries([
          GET_DONE_TASKS_BY_PATIENT_ID_QUERY_KEY,
          _patientId,
        ])
        queryClient.invalidateQueries([
          GET_URGENT_TASKS_BY_PATIENT_ID_QUERY_KEY,
          _patientId,
        ])
        queryClient.invalidateQueries([
          GET_UPCOMING_TASKS_BY_PATIENT_ID_QUERY_KEY,
          _patientId,
        ])
        queryClient.invalidateQueries([
          GET_OVERDUE_TASKS_BY_PATIENT_ID_QUERY_KEY,
          _patientId,
        ])
        queryClient.invalidateQueries([
          GET_TODAY_TASKS_BY_PATIENT_ID_QUERY_KEY,
          _patientId,
        ])
      }
      onSuccess?.(data, variables, context)
    },
  })
  return {
    updateTask: mutate,
    updateTaskIsLoading: isLoading,
    updateTaskStatus: status,
  }
}
