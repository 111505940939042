import { Box, Stack } from '@mui/material'
import { FC } from 'react'

import { ChildrenInProps } from 'src/features/shared/types'

import 'react-toastify/dist/ReactToastify.min.css'

export const MainBoxBaseLayout: FC<ChildrenInProps> = ({ children }) => {
  return (
    <Box id={'main-box-base-layout'} sx={{ backgroundColor: '#F5F5F5' }}>
      {children}
    </Box>
  )
}

export const MainStackBaseLayout: FC<ChildrenInProps> = ({ children }) => {
  return (
    <Stack id={'main-stack-base-layout'} minHeight={'100vh'}>
      {children}
    </Stack>
  )
}
