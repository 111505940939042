import { useQuery } from '@tanstack/react-query'

import { useProvidersUseCases } from 'src/features/providers/providers.service-locator'
import {
  GetProviderGroupsError,
  GetProviderGroupsReturns,
} from 'src/features/providers/domain'

export const GET_PROVIDERS_GROUPS_QUERY_KEY = 'getProviderGroups'

/**
 * `useGetProvidersGroupsQuery` is a custom React hook that uses react-query's `useQuery` hook to fetch provider groups.
 * It first attempts to retrieve the providers use cases from the service locator and then sends a request to fetch the provider groups.
 *
 * @returns {Object} This hook returns an object with the following properties:
 *  - `providerGroups`: the fetched data or an empty array if no data.
 *  - `getProviderGroupsIsLoading`: a boolean indicating whether the data is still loading
 *  - `getProviderGroupsIsError`: a boolean indicating if there was an error fetching the data
 *  - `getProviderGroupsIsSuccess`: a boolean indicating if the data was fetched successfully
 *
 * @example
 * export const MyComponent = () => {
 *    const {
 *      providerGroups,
 *      getProviderGroupsIsLoading,
 *      getProviderGroupsIsError,
 *      getProviderGroupsIsSuccess,
 *    } = useGetProvidersGroupsQuery();
 *
 *    if (getProviderGroupsIsLoading) {
 *      return <div>Loading provider groups...</div>
 *    }
 *
 *    if (getProviderGroupsIsError) {
 *      return <div>Error occurred while fetching provider groups.</div>
 *    }
 *
 *    return providerGroups.map(group => (
 *      <div key={group.id}>
 *        <h2>{group.name}</h2>
 *        <p>{group.description}</p>
 *      </div>
 *    ));
 * }
 */

export const useGetProvidersGroupsQuery = () => {
  const providersUseCases = useProvidersUseCases()

  const { data, isLoading, isError, isSuccess } = useQuery<
    GetProviderGroupsReturns,
    GetProviderGroupsError
  >([GET_PROVIDERS_GROUPS_QUERY_KEY], () => {
    return providersUseCases.getProviderGroups()
  })

  return {
    providerGroups: data || [],
    getProviderGroupsIsLoading: isLoading,
    getProviderGroupsIsError: isError,
    getProviderGroupsIsSuccess: isSuccess,
  }
}
