import { rest } from 'msw'

import { GetQueuesServiceResponse } from 'src/features/queues/infrastructure/services/queues.service'

import * as settings from 'src/config/settings'
import { getQueuesServiceResponseMock } from 'src/__mocks__/responses/get-queues-service-response'

const url = `${settings.COMPASS_API_BASE_URL}/queues/load`

export const getQueuesServiceHandler = rest.get<GetQueuesServiceResponse>(
  url,
  async (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(getQueuesServiceResponseMock))
  }
)
