import axios from 'axios'

import { usePlansService } from 'src/features/providers/infrastructure'

import { useAxiosErrorHandling } from 'src/features/shared/infrastructure'
import { PlansUseCases } from 'src/features/providers/domain'
import {
  mapToCreatePlanError,
  mapToCreatePlanReturns,
  mapToCreatePlanServiceArgs,
  mapToGetPlansError,
  mapToGetPlansReturns,
  mapToGetPlansServiceArgs,
} from 'src/features/providers/adapters'

export const usePlansServiceAdapter = () => {
  const plansService = usePlansService()
  const { handleAxiosError } = useAxiosErrorHandling()

  const getPlans: PlansUseCases['getPlans'] = async (args) => {
    try {
      const serviceArgs = mapToGetPlansServiceArgs(args)

      const response = await plansService.getPlans(serviceArgs)

      return mapToGetPlansReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToGetPlansError)
      }
      throw error
    }
  }

  const createPlan: PlansUseCases['createPlan'] = async (args) => {
    try {
      const serviceArgs = mapToCreatePlanServiceArgs(args)

      const response = await plansService.createPlan(serviceArgs)

      return mapToCreatePlanReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToCreatePlanError)
      }
      throw error
    }
  }

  return {
    getPlans,
    createPlan,
  }
}
