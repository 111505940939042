import { Box, Typography } from '@mui/material'
import { FC } from 'react'

import { Task } from 'src/features/tasks/domain'

type TaskCommentsProps = {
  task: Task
}

export const TaskComments: FC<TaskCommentsProps> = ({ task }) => {
  const noEmptyComments = task.comments?.filter((comment) => comment.body)
  const thereAreComments = !!noEmptyComments?.length
  return (
    <Box
      sx={{
        maxHeight: '96px',
        overflowY: thereAreComments ? 'scroll' : 'hidden',
        scrollSnapMarginRight: '16px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '16px',
        padding: '8px 0px 8px 8px',
      }}
    >
      {thereAreComments ? (
        noEmptyComments?.map((comment) => (
          <Box key={comment.id}>
            <Typography variant="caption" color="rgba(0, 0, 0, 0.38)">
              {comment.header}
            </Typography>
            <Typography>{comment.body}</Typography>
          </Box>
        ))
      ) : (
        <Typography>No comments</Typography>
      )}
    </Box>
  )
}
