export const TRAUMA_QUESTIONS = {
  didYouHaveARecentInjury: {
    id: '047q',
    question: 'Did you have a recent injury?',
  },
  didYouSeeAPhysician: {
    id: '062q',
    question: 'Did you see a physician who diagnosed you with a fracture?',
  },
}
