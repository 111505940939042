import { GetQueuesServiceResponse } from 'src/features/queues/infrastructure/services/queues.service'

export const getQueuesServiceResponseMock: GetQueuesServiceResponse = {
  queues: [
    {
      count: 42,
      name: 'PCP Referrals',
      position: 0,
    },
    {
      count: 7,
      name: 'Ortho Referrals',
      position: 1,
    },
    {
      count: 0,
      name: 'Assigned To Me',
      position: 2,
    },
  ],
}
