import { Box, Typography } from '@mui/material'
import { FC } from 'react'

import { NoteField } from 'src/features/shared/presentation'

export const MSKNoteField: FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '32px',
        width: {
          xs: '50%',
        },
      }}
    >
      <Typography variant={'h6'}>Notes</Typography>
      <Box
        sx={{
          px: '32px',
        }}
      >
        <NoteField />
      </Box>
    </Box>
  )
}
