import {
  UseMutateAsyncFunction,
  UseMutateFunction,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { useProvidersUseCases } from 'src/features/providers/providers.service-locator'
import {
  SearchNPPESError,
  SearchNPPESArgs,
  SearchNPPESReturns,
  NPPESResult,
} from 'src/features/providers/domain'

const SEARCH_LIMIT = 200
export const SEARCH_NPPES_QUERY_KEY = 'searchNPPES'

type useSearchNPPESProps = Omit<
  UseMutationOptions<
    SearchNPPESReturns,
    SearchNPPESError,
    SearchNPPESArgs,
    unknown
  >,
  'mutationFn'
>

type UseSearchNPPES = (props?: useSearchNPPESProps) => {
  searchNPPESAsync: UseMutateAsyncFunction<
    SearchNPPESReturns,
    SearchNPPESError,
    SearchNPPESArgs,
    unknown
  >
  searchNPPES: UseMutateFunction<
    SearchNPPESReturns,
    SearchNPPESError,
    SearchNPPESArgs,
    unknown
  >
  searchAllNPPES: (
    params: SearchNPPESArgs['params'],
    setSearchResults: (
      r: NPPESResult[]
    ) => { payload: NPPESResult[] | undefined; type: string } | undefined,
    setIsLoading: (r: boolean) => { payload: boolean; type: string } | undefined
  ) => Promise<void>
  searchNPPESIsLoading: boolean
  searchNPPESIsError: boolean
  searchNPPESStatus: string
}

export const useSearchNPPESQuery: UseSearchNPPES = (props = {}) => {
  const providersUseCases = useProvidersUseCases()
  const queryClient = useQueryClient()

  const { onSuccess } = props

  const { mutate, isLoading, isError, status, mutateAsync } = useMutation<
    SearchNPPESReturns,
    SearchNPPESError,
    SearchNPPESArgs,
    unknown
  >({
    mutationFn: (args: SearchNPPESArgs) => {
      return providersUseCases.searchNPPES(args)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [SEARCH_NPPES_QUERY_KEY],
      })
      onSuccess?.(data, variables, context)
    },
  })

  const searchAllNPPES = async (
    params: SearchNPPESArgs['params'],
    setSearchResults: (
      r: NPPESResult[]
    ) => { payload: NPPESResult[] | undefined; type: string } | undefined,
    setIsLoading: (r: boolean) => { payload: boolean; type: string } | undefined
  ) => {
    const currentParams = {
      skip: 0,
      limit: SEARCH_LIMIT,
      ...params,
    }
    let response = await mutateAsync({ params: currentParams })
    let lastResultsFirstNPI =
      response.results.length > 0 ? response.results[0].number : undefined
    let resultsAcc = response.results
    setSearchResults(resultsAcc)
    setIsLoading(false)
    let repeatEncountered = false
    while (response.resultCount == SEARCH_LIMIT && !repeatEncountered) {
      currentParams.skip = currentParams.skip + SEARCH_LIMIT
      response = await mutateAsync({ params: currentParams })
      const currentResultsFirstNpi =
        response.results.length > 0 ? response.results[0].number : undefined
      if (
        currentResultsFirstNpi &&
        lastResultsFirstNPI &&
        currentResultsFirstNpi === lastResultsFirstNPI
      ) {
        repeatEncountered = true
      } else {
        resultsAcc = resultsAcc.concat(response.results)
        setSearchResults(resultsAcc)
      }
      lastResultsFirstNPI = currentResultsFirstNpi
    }
  }

  return {
    searchNPPESAsync: mutateAsync,
    searchNPPES: mutate,
    searchAllNPPES,
    searchNPPESIsLoading: isLoading,
    searchNPPESIsError: isError,
    searchNPPESStatus: status,
  }
}
