import { Amplify } from 'aws-amplify'
import { Quill } from 'react-quill'
import { LicenseInfo } from '@mui/x-license-pro'
import * as Sentry from '@sentry/react'

import { worker } from 'src/__mocks__/backend-mock-service-worker'
import {
  saveNotesStateInLocalStorage,
  store,
} from './features/shared/infrastructure'
import { saveMSKFormInLocalStorage } from './features/shared/infrastructure'
import { ReactQuillExternalLink, amplifyConfig, sentryConfig } from './config'
import * as settings from './config/settings'

export default () => {
  // Mock Service Worker
  if (settings.MOCK_BACKEND) {
    worker.start()
  }

  // Sentry
  if (settings.SENTRY_DSN && settings.REACT_APP_ENV === 'prod') {
    Sentry.init(sentryConfig)
  }

  // Amplify
  Amplify.configure(amplifyConfig)

  // Redux
  store.subscribe(() => {
    if (store.getState().mskForm.mskFormPatientId) {
      saveMSKFormInLocalStorage(store.getState().mskForm)
    }
    if (store.getState().notes.currentNotesPatientId) {
      saveNotesStateInLocalStorage(store.getState().notes)
    }
  })

  // React Quill
  Quill.register(ReactQuillExternalLink)

  // MUI
  LicenseInfo.setLicenseKey(settings.MUIX_LICENSE_KEY)
}
