import { rest } from 'msw'

import { LoadMSKFormServiceResponse } from 'src/features/msk/infrastructure/services/'

import * as settings from 'src/config/settings'
import { loadMSKFormServiceResponseMock } from 'src/__mocks__/responses/load-msk-form-service-response'

const url = `${settings.COMPASS_API_BASE_URL}/msk/load`

export const loadMSKFormServiceHandler = rest.get<LoadMSKFormServiceResponse>(
  url,
  async (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(loadMSKFormServiceResponseMock))
  }
)
