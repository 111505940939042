import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import CancelIcon from '@mui/icons-material/Cancel'
import { SxProps, Theme } from '@mui/material/styles'

type PatientEligibility = {
  eligible: boolean
  eligibilityLastUpdatedAt?: string | null
  eligibilityEndDate?: string | null
}

type EligibilityIconProps = {
  patientEligibility: PatientEligibility
  showEligible?: boolean
  sx?: SxProps<Theme>
}

const getMonthFromDateString = (dateString: string) => {
  if (!dateString) {
    return 'unavailable'
  }

  const date = new Date(dateString)
  if (isNaN(date.getTime())) {
    return 'unavailable'
  }

  date.setMonth(date.getMonth() - 1)
  const month = date.toLocaleString('default', { month: 'long' })
  return month
}

export const EligibilityIcon: React.FC<EligibilityIconProps> = ({
  patientEligibility,
  showEligible = true,
  sx,
}) => {
  let tooltipMessage = `Ineligible as of ${
    patientEligibility.eligibilityEndDate || 'unavailable'
  }`
  let Icon: typeof ErrorRoundedIcon | typeof CancelIcon = CancelIcon
  let iconColor = 'red'

  const lastUpdatedMonth = getMonthFromDateString(
    patientEligibility.eligibilityLastUpdatedAt ?? ''
  )

  if (showEligible) {
    if (patientEligibility.eligible) {
      tooltipMessage = `Last known eligible as of ${lastUpdatedMonth}`
      Icon = ErrorRoundedIcon
    }

    if (patientEligibility.eligible && lastUpdatedMonth === 'unavailable') {
      iconColor = 'grey'
    } else if (patientEligibility.eligible) {
      iconColor = '#319645'
    }
  }

  return (
    <Tooltip title={tooltipMessage}>
      <Icon sx={{ ...sx, color: iconColor }} />
    </Tooltip>
  )
}
