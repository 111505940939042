import Select, { GroupBase, Props, CSSObjectWithLabel } from 'react-select'
import CreatableSelect, { CreatableProps } from 'react-select/creatable'
import AsyncCreatableSelect, {
  AsyncCreatableProps,
} from 'react-select/async-creatable'

import { tcColors } from 'src/features/shared/presentation'
import { Typography } from '@mui/material'

export type TCReactSelectOption = {
  label: string
  value: string
}

const defaultMenuStyles: CSSObjectWithLabel = {
  zIndex: 3,
}
const defaultControlStyles: CSSObjectWithLabel = {}
const defaultPlaceholderStyles: CSSObjectWithLabel = {}

interface TCReactSelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> extends Props<Option, IsMulti, Group> {
  error?: boolean
  isCreatable?: boolean
  isAsyncCreatable?: boolean
  creatableProps?: CreatableProps<Option, IsMulti, Group>
  asyncCreatableProps?: AsyncCreatableProps<Option, IsMulti, Group>
}

export function TCReactSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  styles,
  isCreatable,
  isAsyncCreatable,
  asyncCreatableProps = {},
  creatableProps = {},
  ...restProps
}: TCReactSelectProps<Option, IsMulti, Group>) {
  const otherProps = isCreatable ? creatableProps : asyncCreatableProps
  const stylesProps = styles || {}
  const { menu, control, placeholder, ...restStylesProps } = stylesProps

  let Component = Select

  if (isCreatable) {
    Component = CreatableSelect
  }
  if (isAsyncCreatable) {
    Component = AsyncCreatableSelect
  }

  return (
    <Component
      menuShouldScrollIntoView={false}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: tcColors.teal[50],
          primary50: tcColors.teal[100],
          primary75: tcColors.teal[200],
          primary: tcColors.teal[300],
        },
      })}
      {...restProps}
      {...otherProps}
      placeholder={
        otherProps.placeholder || restProps.placeholder ? (
          <Typography>
            {otherProps.placeholder || restProps.placeholder}{' '}
            {restProps.required ? (
              <Typography component={'span'} color={'#FF0000'}>
                *
              </Typography>
            ) : null}
          </Typography>
        ) : null
      }
      styles={{
        ...styles,
        control: (provided, controlProps) => {
          const newControlProps = control
            ? { ...defaultControlStyles, ...control(provided, controlProps) }
            : defaultControlStyles
          return {
            ...provided,
            ...newControlProps,
            border: restProps.error ? '1px solid #d32f2f' : provided.border,
          }
        },
        placeholder: (provided, placeholderProps) => {
          const newPlaceholderProps = placeholder
            ? {
                ...defaultPlaceholderStyles,
                ...placeholder(provided, placeholderProps),
              }
            : defaultPlaceholderStyles
          return {
            ...provided,
            ...newPlaceholderProps,
            color: restProps.error ? '#d32f2f' : provided.color,
          }
        },
        menu: (provided, menuProps) => {
          const newMenuProps = menu
            ? { ...defaultMenuStyles, ...menu(provided, menuProps) }
            : defaultMenuStyles
          return {
            ...provided,
            ...newMenuProps,
          }
        },
        ...restStylesProps,
      }}
    />
  )
}
