import { TableCell, tableCellClasses, TableCellProps } from '@mui/material'
import { FC } from 'react'

import { ChildrenInProps, WithChildren } from 'src/features/shared/types'

export type TCTableCellProps = Omit<TableCellProps, 'variant'> & {
  tableCellVariant?: TableCellProps['variant']
  variant?: 'normal' | 'warning'
}

export const TCTableCell: FC<WithChildren<TCTableCellProps>> = ({
  sx,
  tableCellVariant,
  children,
  variant = 'normal',
  ...restProps
}) => {
  const _sx = {
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'navy.200',
      color: 'common.white',
      fontSize: 14,
      fontWeight: 500,
      width: 'auto',
    },
    [`&.${tableCellClasses.body}`]: {
      color: variant === 'warning' ? 'warning.main' : '',
      fontSize: 16,
      fontWeight: 400,
      width: 'auto',
    },
    ...sx,
  }
  return (
    <TableCell variant={tableCellVariant} sx={_sx} {...restProps}>
      {children}
    </TableCell>
  )
}

export const TCTableCellHead: FC<ChildrenInProps> = ({ children }) => {
  return (
    <TCTableCell
      tableCellVariant={'head'}
      size={'medium'}
      sx={{
        whiteSpace: {
          lg: 'nowrap',
        },
      }}
    >
      {children}
    </TCTableCell>
  )
}
