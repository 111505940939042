import {
  MSKFormState,
  MSKFormStateIntakeAssessment,
  NotFoundIntakeAssessmentError,
} from 'src/features/shared/infrastructure'

export const intakeAssessmentIsComplete = (
  intakeAssessment: MSKFormStateIntakeAssessment
) => {
  return !!intakeAssessment.recommendedAction
}

export const getHeightInInches = (feet: number, inches: number) =>
  (feet * 12 + inches).toString()

export const getCurrentIntakeAssessment = (
  intakeAssessments: MSKFormStateIntakeAssessment[],
  currentCondition: MSKFormState['currentCondition']
): MSKFormStateIntakeAssessment => {
  if (!currentCondition) {
    throw new NotFoundIntakeAssessmentError(
      'currentCondition is has not been set'
    )
  }

  const currentIntakeAssessment = intakeAssessments.find(
    (intakeAssessment) =>
      intakeAssessment.botheredBodyPart.id === currentCondition.id
  )

  if (!currentIntakeAssessment) {
    throw new NotFoundIntakeAssessmentError()
  }

  return currentIntakeAssessment
}
