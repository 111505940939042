import {
  SelectChangeEvent,
  Box,
  Select,
  MenuItem,
  TextField,
  Divider,
  FormHelperText,
  FormControl,
  Alert,
  AlertTitle,
  Button,
  Typography,
  Stack,
} from '@mui/material'
import { FC } from 'react'

import { LoadMSKFormReturns } from 'src/features/msk/domain'
import {
  useMSKFormStore,
  MSKFormStateAdditionalQuestionsFieldOptions,
  BhAction2Options,
  PhQ9ActionOptions,
} from 'src/features/shared/infrastructure'
import {
  FormFieldContainer,
  TCCopyToClipboard,
  useTooltipsState,
} from 'src/features/shared/presentation'
import { mskFormLabelSx } from 'src/features/msk/presentation'
import { PatientV2 } from 'src/features/patients/domain'
import * as settings from 'src/config/settings'

import {
  QUESTIONS_LABELS,
  getSelectDefaultSx,
  MSK_FORM_ADDITIONAL_QUESTIONS_FIELD_OPTIONS,
  getTextFieldDefaultSx,
  isPhQ9ActionRequired,
} from './helpers'
import { useGetMarketsQuery } from 'src/features/providers/presentation/hooks/use-get-markets'
import { useGetPayorsQuery } from 'src/features/providers/presentation/hooks/use-get-payors'

type MSKFormStepThreePage1Props = {
  patient: PatientV2
  loadedMSKForm: LoadMSKFormReturns
}

export const MSKFormStepThreePage1: FC<MSKFormStepThreePage1Props> = ({
  patient,
  loadedMSKForm,
}) => {
  const {
    tooltipState,
    getTooltipCopyClickHandler,
    getTooltipCopiedTooltipCloseHandler,
  } = useTooltipsState(['tcId'])

  const { additionalQuestions, setAdditionalQuestions, errors, deleteError } =
    useMSKFormStore({
      loadedMSKForm,
    })
  const { markets } = useGetMarketsQuery()
  const { getPayorName } = useGetPayorsQuery()

  const getMarketName = () => {
    const market = markets.find(
      (market) => market.id === patient.medicalProfile?.marketId
    )
    return market ? market.name : undefined
  }

  const getPhScore = () => {
    if (additionalQuestions.phScore === null) {
      return ''
    }
    return additionalQuestions.phScore
  }

  const getGad2Score = () => {
    if (additionalQuestions.gad2Score === null) {
      return ''
    }
    return additionalQuestions.gad2Score
  }

  const handlePhQ1Change = (event: SelectChangeEvent) => {
    const value = event.target
      .value as MSKFormStateAdditionalQuestionsFieldOptions['v1']
    setAdditionalQuestions({
      phQ1: value,
    })

    deleteError(['phQ9Action', 'bhAction2'])
  }

  const handlePhQ2Change = (event: SelectChangeEvent) => {
    const value = event.target
      .value as MSKFormStateAdditionalQuestionsFieldOptions['v1']
    setAdditionalQuestions({
      phQ2: value,
    })

    deleteError(['phQ9Action', 'bhAction2'])
  }

  const handleGad2Q1Change = (event: SelectChangeEvent) => {
    const value = event.target
      .value as MSKFormStateAdditionalQuestionsFieldOptions['v1']
    setAdditionalQuestions({
      gad2Q1: value,
    })

    deleteError(['bhAction2'])
  }

  const handleGad2Q2Change = (event: SelectChangeEvent) => {
    const value = event.target
      .value as MSKFormStateAdditionalQuestionsFieldOptions['v1']
    setAdditionalQuestions({
      gad2Q2: value,
    })

    deleteError(['bhAction2'])
  }

  const handlePhQ9Change = (event: SelectChangeEvent) => {
    const value = event.target.value as PhQ9ActionOptions
    setAdditionalQuestions({
      phQ9Action: value,
    })

    deleteError(['phQ9Action'])
  }

  const handleBhAction2Change = (event: SelectChangeEvent) => {
    const value = event.target.value as BhAction2Options
    setAdditionalQuestions({
      bhAction2: value,
    })

    if (value !== 'Select an option') {
      deleteError('bhAction2')
    }
  }

  const handleOpenPhQ9FormClick = () => {
    window.open(settings.PHQ9_FORM_URL, '_blank')
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '32px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
          }}
        >
          <FormFieldContainer
            id={'phQ1'}
            label={QUESTIONS_LABELS.phQ1}
            error={!!errors.phQ1}
            helperText={errors.phQ1 ? errors.phQ1 : ''}
            formLabelSx={mskFormLabelSx}
          >
            <Select
              id={'phQ1'}
              size="small"
              value={additionalQuestions.phQ1}
              onChange={handlePhQ1Change}
              error={!!errors.phQ1}
              sx={{
                ...getSelectDefaultSx(),
              }}
            >
              {MSK_FORM_ADDITIONAL_QUESTIONS_FIELD_OPTIONS.v1.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.display}
                </MenuItem>
              ))}
            </Select>
          </FormFieldContainer>

          <FormFieldContainer
            id={'phQ2'}
            label={QUESTIONS_LABELS.phQ2}
            error={!!errors.phQ2}
            helperText={errors.phQ2 ? errors.phQ2 : ''}
            formLabelSx={mskFormLabelSx}
          >
            <Select
              id={'phQ2'}
              size="small"
              value={additionalQuestions.phQ2}
              onChange={handlePhQ2Change}
              error={!!errors.phQ2}
              sx={{
                ...getSelectDefaultSx(),
              }}
            >
              {MSK_FORM_ADDITIONAL_QUESTIONS_FIELD_OPTIONS.v1.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.display}
                </MenuItem>
              ))}
            </Select>
          </FormFieldContainer>
        </Box>

        <Box
          sx={{
            display: 'flex',
            columnGap: '16px',
            justifyContent: 'flex-end',
          }}
        >
          <TextField
            label={'PHQ2 Score'}
            value={getPhScore()}
            size={'small'}
            disabled={true}
            sx={{
              width: '124px',
            }}
          />
          <TextField
            label={'PHQ2 Assessment'}
            value={additionalQuestions.phAssessment}
            size={'small'}
            disabled={true}
            sx={{
              width: '140px',
            }}
          />
        </Box>
      </Box>

      <Divider />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
          }}
        >
          <FormFieldContainer
            id={'gad2Q1'}
            label={QUESTIONS_LABELS.gad2Q1}
            error={!!errors.gad2Q1}
            helperText={errors.gad2Q1 ? errors.gad2Q1 : ''}
            formLabelSx={mskFormLabelSx}
          >
            <Select
              id={'gad2Q1'}
              size="small"
              value={additionalQuestions.gad2Q1}
              onChange={handleGad2Q1Change}
              error={!!errors.gad2Q1}
              sx={{
                ...getSelectDefaultSx(),
              }}
            >
              {MSK_FORM_ADDITIONAL_QUESTIONS_FIELD_OPTIONS.v1.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.display}
                </MenuItem>
              ))}
            </Select>
          </FormFieldContainer>

          <FormFieldContainer
            id={'gad2Q2'}
            label={QUESTIONS_LABELS.gad2Q2}
            error={!!errors.gad2Q2}
            helperText={errors.gad2Q2 ? errors.gad2Q2 : ''}
            formLabelSx={mskFormLabelSx}
          >
            <Select
              id={'gad2Q2'}
              size="small"
              value={additionalQuestions.gad2Q2}
              onChange={handleGad2Q2Change}
              error={!!errors.gad2Q2}
              sx={{
                ...getSelectDefaultSx(),
              }}
            >
              {MSK_FORM_ADDITIONAL_QUESTIONS_FIELD_OPTIONS.v1.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.display}
                </MenuItem>
              ))}
            </Select>
          </FormFieldContainer>
        </Box>
        <Box
          sx={{
            display: 'flex',
            columnGap: '16px',
            justifyContent: 'flex-end',
          }}
        >
          <TextField
            label={'GAD2 Score'}
            value={getGad2Score()}
            size={'small'}
            disabled={true}
            sx={{
              width: '124px',
            }}
          />
          <TextField
            label={'GAD2 Assessment'}
            value={additionalQuestions.gad2Assessment}
            size={'small'}
            disabled={true}
            sx={{
              width: '140px',
            }}
          />
        </Box>
      </Box>

      <Divider />

      <Box
        sx={{
          display: 'flex',
          columnGap: '16px',
        }}
      >
        <FormFieldContainer
          id={'bhAction2'}
          label={QUESTIONS_LABELS.bhAction2}
          error={!!errors.bhAction2}
          formLabelSx={mskFormLabelSx}
        >
          <Box
            sx={{
              display: 'flex',
              columnGap: '16px',
            }}
          >
            <TextField
              value={additionalQuestions.bhAction1}
              size={'small'}
              disabled={true}
              sx={{
                ...getTextFieldDefaultSx(),
              }}
            />
            {additionalQuestions.bhAction1 === 'Y' ? (
              <FormControl>
                <Select
                  id={'bhAction2'}
                  size="small"
                  value={additionalQuestions.bhAction2}
                  onChange={handleBhAction2Change}
                  error={!!errors.bhAction2}
                  sx={{
                    ...getSelectDefaultSx(),
                  }}
                >
                  {MSK_FORM_ADDITIONAL_QUESTIONS_FIELD_OPTIONS.bhAction.map(
                    (option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.display}
                      </MenuItem>
                    )
                  )}
                </Select>

                {errors.bhAction2 ? (
                  <FormHelperText error={!!errors.bhAction2}>
                    {errors.bhAction2}
                  </FormHelperText>
                ) : null}
              </FormControl>
            ) : null}
          </Box>
        </FormFieldContainer>
      </Box>

      {isPhQ9ActionRequired(
        additionalQuestions.phScore,
        patient.medicalProfile?.linkedPayorId
          ? getPayorName(patient.medicalProfile.linkedPayorId)
          : undefined,
        getMarketName()
      ) ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
          }}
        >
          <Divider />
          <Alert severity={'warning'}>
            <AlertTitle>
              PHQ2 is positive - If patient is currently receiving care, please
              select 'Patient already under care' in the dropdown below. If not,
              we must offer this patient the PHQ9 assessment.
            </AlertTitle>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '32px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '24px',
                }}
              >
                <Typography>
                  Clicking continue will open a new tab with the PHQ9
                  assessment.
                </Typography>
                <Typography variant={'caption'} fontStyle={'italic'}>
                  Suggested script: "We ask the next couple questions with
                  everyone, to support Mental Wellness..."
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  columnGap: '16px',
                }}
              >
                <Stack direction={'row'} gap={'4px'} alignItems={'center'}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '12px',
                      letterSpacing: '0.15px',
                      textDecoration: 'underline',
                      color: 'magenta.300',
                    }}
                  >
                    TC ID
                  </Typography>
                  <TCCopyToClipboard
                    value={patient.patientId}
                    tooltipOpen={tooltipState['tcId']}
                    onCopy={getTooltipCopyClickHandler('tcId')}
                    onTooltipClose={getTooltipCopiedTooltipCloseHandler('tcId')}
                  />
                </Stack>
                <Button
                  variant={'contained'}
                  color={'secondary'}
                  onClick={handleOpenPhQ9FormClick}
                >
                  Continue to PHq 9 assessment
                </Button>
              </Box>
            </Box>
          </Alert>
          <FormFieldContainer
            id={'phQ9Action'}
            label={QUESTIONS_LABELS.phQ9Action}
            error={!!errors.phQ9Action}
            helperText={errors.phQ9Action ? errors.phQ9Action : ''}
            formLabelSx={mskFormLabelSx}
          >
            <Select
              id={'phQ9Action'}
              size="small"
              value={additionalQuestions.phQ9Action}
              onChange={handlePhQ9Change}
              error={!!errors.phQ9Action}
              sx={{
                ...getSelectDefaultSx(),
              }}
            >
              {MSK_FORM_ADDITIONAL_QUESTIONS_FIELD_OPTIONS.phQ9Action.map(
                (option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.display}
                  </MenuItem>
                )
              )}
            </Select>
          </FormFieldContainer>
          <Divider />
        </Box>
      ) : null}
    </Box>
  )
}
