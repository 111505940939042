import { Stack, Typography } from '@mui/material'
import { FieldPath, FieldValues } from 'react-hook-form'
import { FC } from 'react'

import { filterNoneValueOptions } from 'src/features/notes/adapters'
import {
  ControlledSelectField,
  ControlledTextField,
  YES_NO_OPTIONS,
} from 'src/features/shared/presentation'
import {
  NOTE_FIELD_LABEL,
  noteFieldLabelDefaultSx,
} from 'src/features/notes/presentation'
import { mapToControlledSelectItem } from 'src/features/shared/adapters'
import { NoteData } from 'src/features/notes/domain'

export type SelfCareTouchpointAdditionalQuestionsFormV2Values = Pick<
  NoteData,
  | 'agreeYouUnderstandCondition'
  | 'agreeYouUnderstandTreatmentOptions'
  | 'painLevelDecreased'
  | 'newSymptoms'
  | 'additionalQuestionsOrConcerns'
  | 'sdmText'
  | 'sdmPatientUnderstandsCondition'
  | 'sdmClinicalBelievesPatientUnderstandsCondition'
  | 'patientGoals'
  | 'reasonForRefusal'
>

type SelfCareTouchpointAdditionalQuestionsFormV2Props = {
  painLevelDecreasedFieldName?: FieldPath<FieldValues>
  newSymptomsFieldName?: FieldPath<FieldValues>
  additionalQuestionsOrConcernsFieldName?: FieldPath<FieldValues>
  painLevelDecreasedRequired?: boolean
  newSymptomsRequired?: boolean
  additionalQuestionsOrConcernsRequired?: boolean
}

export const SelfCareTouchpointAdditionalQuestionsFormV2: FC<
  SelfCareTouchpointAdditionalQuestionsFormV2Props
> = ({
  painLevelDecreasedFieldName = 'painLevelDecreased',
  additionalQuestionsOrConcernsFieldName = 'additionalQuestionsOrConcerns',
  newSymptomsFieldName = 'newSymptoms',
  painLevelDecreasedRequired,
  newSymptomsRequired,
  additionalQuestionsOrConcernsRequired,
}) => {
  return (
    <>
      <Typography pb={'16px'}>Additional Questions</Typography>
      <Stack direction={'column'} rowGap={'8px'}>
        <ControlledSelectField
          name={painLevelDecreasedFieldName}
          label={NOTE_FIELD_LABEL.painLevelDecreased}
          items={filterNoneValueOptions(YES_NO_OPTIONS).map(
            mapToControlledSelectItem
          )}
          required={painLevelDecreasedRequired}
          selectSx={{ width: '88px' }}
          formLabelSx={noteFieldLabelDefaultSx}
        />
        <ControlledTextField
          name={newSymptomsFieldName}
          label={NOTE_FIELD_LABEL.newSymptoms}
          required={newSymptomsRequired}
          formLabelSx={noteFieldLabelDefaultSx}
          textFieldSx={{ width: '100%' }}
        />
        <ControlledTextField
          name={additionalQuestionsOrConcernsFieldName}
          label={NOTE_FIELD_LABEL.additionalQuestionsOrConcerns}
          required={additionalQuestionsOrConcernsRequired}
          formLabelSx={noteFieldLabelDefaultSx}
          textFieldSx={{ width: '100%' }}
        />
      </Stack>
    </>
  )
}
