import { CreatePathwayServiceResponse } from 'src/features/pathways/infrastructure'

export const createPathwayServiceResponseMock: CreatePathwayServiceResponse = {
  id: '3df9470b-80ae-4fdc-b00e-6ee5f23a4576',
  patientId: '3c2343eb-bc60-45bf-9d48-41d33c1437f7',
  assignedTo: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
  eventsGenerated: false,
  pathwayId: '1415fb60-058d-42ff-bb0b-c87fbf484f74',
  lockDate: '06/20/2023',
  creationDate: '06/21/2023',
  lastUpdatedAt: '06/21/2023',
  isCancelled: false,
  isPaused: false,
  createdBy: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
  pathwayType: 'Self-Care',
  done: false,
  intakeId: null,
  firstAppointmentDate: null,
  message: 'locked pathway for patient 3c2343eb-bc60-45bf-9d48-41d33c1437f7',
}
