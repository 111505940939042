import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { QueuePatientsTableRow } from 'src/features/queues/presentation'
import { RootState } from 'src/features/shared/infrastructure'

export type QueuePatientsStateSelectedFilters = {
  referralSource: string
  stage: string
  linkedPlanId: string
  status: string
  market: string
  lastToContact: string
  assignedTo: string
  dateOfLastAttemptStart: string
  dateOfLastAttemptEnd: string
  attemptsQtyFrom: string
  attemptsQtyTo: string
  referralCoordinationFlag: string
}

export type QueuePatientsState = {
  selectedQueuePatientRow: QueuePatientsTableRow | null
  openQueuePatientBusyModal: boolean
  queuePatientsSearchInputValue: string
  openQueuePatientsFilterModal: boolean
  queuePatientsSelectedFilters: QueuePatientsStateSelectedFilters
  openCreatePatientModal: boolean
  createPatientModalSelectedTab: 'eligibility' | 'manual'
}

const initialState: QueuePatientsState = {
  selectedQueuePatientRow: null,
  openQueuePatientBusyModal: false,
  queuePatientsSearchInputValue: '',
  openQueuePatientsFilterModal: false,
  queuePatientsSelectedFilters: {
    referralSource: '',
    stage: '',
    linkedPlanId: '',
    lastToContact: '',
    assignedTo: '',
    status: '',
    market: '',
    attemptsQtyFrom: '',
    attemptsQtyTo: '',
    dateOfLastAttemptStart: '',
    dateOfLastAttemptEnd: '',
    referralCoordinationFlag: '',
  },
  openCreatePatientModal: false,
  createPatientModalSelectedTab: 'eligibility',
}

export type SelectedQueuePatientRowActionPayload =
  QueuePatientsState['selectedQueuePatientRow']

export type OpenQueuePatientBusyModalActionPayload =
  QueuePatientsState['openQueuePatientBusyModal']

export type QueuePatientsSearchInputValueActionPayload =
  QueuePatientsState['queuePatientsSearchInputValue']

export type OpenQueuePatientsFilterModalActionPayload =
  QueuePatientsState['openQueuePatientsFilterModal']

export type QueuePatientsSelectedFiltersActionPayload = {
  filter: keyof QueuePatientsStateSelectedFilters
  value: string
}[]

const queuePatientsSlice = createSlice({
  name: 'queuePatients',
  initialState,
  reducers: {
    setSelectedQueuePatientRowAction(
      state,
      action: PayloadAction<SelectedQueuePatientRowActionPayload>
    ) {
      state.selectedQueuePatientRow = action.payload
    },
    setOpenQueuePatientBusyModalAction(
      state,
      action: PayloadAction<OpenQueuePatientBusyModalActionPayload>
    ) {
      state.openQueuePatientBusyModal = action.payload
    },
    setQueuePatientsSearchInputValueAction(
      state,
      action: PayloadAction<QueuePatientsSearchInputValueActionPayload>
    ) {
      state.queuePatientsSearchInputValue = action.payload
    },
    setOpenQueuePatientsFilterModalAction(
      state,
      action: PayloadAction<OpenQueuePatientsFilterModalActionPayload>
    ) {
      state.openQueuePatientsFilterModal = action.payload
    },
    setQueuePatientsSelectedFiltersAction(
      state,
      action: PayloadAction<QueuePatientsSelectedFiltersActionPayload>
    ) {
      action.payload.forEach((filterData) => {
        const { filter, value } = filterData
        state.queuePatientsSelectedFilters[filter] = value
      })
    },
    setOpenCreatePatientModalAction(
      state,
      action: PayloadAction<QueuePatientsState['openCreatePatientModal']>
    ) {
      state.openCreatePatientModal = action.payload
    },
    setCreatePatientModalSelectedTabAction(
      state,
      action: PayloadAction<QueuePatientsState['createPatientModalSelectedTab']>
    ) {
      state.createPatientModalSelectedTab = action.payload
    },
  },
})

export const {
  setSelectedQueuePatientRowAction,
  setOpenQueuePatientBusyModalAction,
  setQueuePatientsSearchInputValueAction,
  setOpenQueuePatientsFilterModalAction,
  setQueuePatientsSelectedFiltersAction,
  setCreatePatientModalSelectedTabAction,
  setOpenCreatePatientModalAction,
} = queuePatientsSlice.actions

export const selectedQueuePatientRowSelector = (state: RootState) =>
  state.queuePatients.selectedQueuePatientRow

export const openQueuePatientBusyModalSelector = (state: RootState) =>
  state.queuePatients.openQueuePatientBusyModal

export const queuePatientsSearchInputValueSelector = (state: RootState) =>
  state.queuePatients.queuePatientsSearchInputValue

export const openQueuePatientsFilterModalSelector = (state: RootState) =>
  state.queuePatients.openQueuePatientsFilterModal

export const queuePatientsSelectedFiltersSelector = (state: RootState) =>
  state.queuePatients.queuePatientsSelectedFilters

export const openCreatePatientModalSelector = (state: RootState) =>
  state.queuePatients.openCreatePatientModal

export const createPatientModalSelectedTabSelector = (state: RootState) =>
  state.queuePatients.createPatientModalSelectedTab

export default queuePatientsSlice.reducer
