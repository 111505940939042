import { useMarketsServiceAdapter } from 'src/features/providers/adapters'
import { UseMarketsUseCases } from 'src/features/providers/domain'

type MakeUseMarketsUseCases = () => UseMarketsUseCases

const makeUseMarketsUseCases: MakeUseMarketsUseCases = () => () => {
  const marketsServiceAdapter = useMarketsServiceAdapter()

  return {
    getMarkets: marketsServiceAdapter.getMarkets,
  }
}

export const useMarketsUseCases: UseMarketsUseCases = makeUseMarketsUseCases()
