import {
  Typography,
  Box,
  Stack,
  IconButton,
  SxProps,
  Theme,
  TypographyProps,
} from '@mui/material'
import { FC, useState } from 'react'

import EditIcon from '@mui/icons-material/Edit'
import AddRoundedIcon from '@mui/icons-material/AddRounded'

import {
  EligibilityIcon,
  TCCopyToClipboard,
  useTooltipsState,
} from 'src/features/shared/presentation'
import { PatientMedicalProfile } from 'src/features/patients/domain'
import { MRNField } from 'src/features/patients/presentation'
import { WithChildren } from 'src/features/shared/types'

export type PatientHeaderData = {
  id: string
  name: string
  notEnglishSpeaker: boolean
  hasPhone: boolean
  hasPCPInfo: boolean
  hasPlanInfo: boolean
  isTraumaPatient: boolean
  language?: string | null
  mbi?: string | null
  dob?: string | null
  mrn?: string
  phone1?: string
  phone2?: string
  doNotCall?: boolean
  dualCoverage?: boolean
  planType: string | null
  referralSource?: string
  isThreadwell: boolean
  stageAndStatus?: string | null
  medicalProfile?: PatientMedicalProfile | null
  planName?: string
  eligible: boolean
  eligibilityStartDate?: string | null
  eligibilityEndDate?: string | null
  eligibilityLastUpdatedAt?: string | null
}

type PatientHeaderAttrKeyProps = Omit<TypographyProps, 'variant'> & {
  typographyVariant?: TypographyProps['variant']
  variant?: 'normal' | 'warning'
}

const PatientHeaderAttrKey: FC<WithChildren<PatientHeaderAttrKeyProps>> = ({
  sx,
  children,
  typographyVariant,
  variant = 'normal',
  ...restProps
}) => {
  const _sx: SxProps<Theme> = {
    color: variant === 'warning' ? 'warning.main' : 'rgba(0, 0, 0, 0.38)',
    fontSize: '14px',
    letterSpacing: '0.17px',
    ...sx,
  }
  return (
    <Typography variant={typographyVariant} sx={_sx} {...restProps}>
      {children}
    </Typography>
  )
}

type PatientPhoneProps = Omit<TypographyProps, 'variant'> & {
  typographyVariant?: TypographyProps['variant']
  variant?: 'normal' | 'warning'
}

const PatientPhone: FC<WithChildren<PatientPhoneProps>> = ({
  sx,
  children,
  typographyVariant,
  variant = 'normal',
  ...restProps
}) => {
  const _sx: SxProps<Theme> = {
    color: variant === 'warning' ? 'warning.main' : 'normal.main',
    ...sx,
  }
  return (
    <Typography variant={typographyVariant} sx={_sx} {...restProps}>
      {children}
    </Typography>
  )
}

const getPatientPhoneVariant = (
  doNotCall: PatientHeaderData['doNotCall']
): PatientPhoneProps['variant'] => (doNotCall ? 'warning' : 'normal')

const getPatientHeaderAttrKeyVariant = (
  doNotCall: PatientHeaderData['doNotCall']
): PatientHeaderAttrKeyProps['variant'] => (doNotCall ? 'warning' : 'normal')

type PatientPageHeaderViewProps = {
  patient: PatientHeaderData
  onEditClick?: () => void
  disableEdit?: boolean
}

export const PatientPageHeaderView: FC<PatientPageHeaderViewProps> = ({
  patient,
  onEditClick,
  disableEdit = false,
}) => {
  const patientPhoneVariant = getPatientPhoneVariant(patient.doNotCall)
  const patientHeaderAttrKeyVariant = getPatientHeaderAttrKeyVariant(
    patient.doNotCall
  )

  const {
    tooltipState,
    getTooltipCopyClickHandler,
    getTooltipCopiedTooltipCloseHandler,
  } = useTooltipsState([
    'tcId',
    'phone',
    'phone2',
    'mrn',
    'mbi',
    'pcp',
    'memberId',
    'pcpPhone',
  ])
  const handleEditTaskClick = () => (onEditClick ? onEditClick() : undefined)
  const [mrnEditing, setMrnEditing] = useState(false)

  return (
    <Box
      sx={{
        padding: '24px 16px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '5px',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', columnGap: '30px', alignItems: 'center' }}>
          <EligibilityIcon
            patientEligibility={{
              eligible: patient.eligible,
              eligibilityLastUpdatedAt: patient.eligibilityLastUpdatedAt,
              eligibilityEndDate: patient.eligibilityEndDate,
            }}
            sx={{ margin: '0px -10px 0px 0px' }}
          />
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '24px',
            }}
          >
            {patient.name}
          </Typography>

          <Stack direction={'row'} gap={'4px'} alignItems={'center'}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '12px',
                letterSpacing: '0.15px',
                textDecoration: 'underline',
                color: 'magenta.300',
              }}
            >
              TC ID
            </Typography>
            <TCCopyToClipboard
              value={patient.id}
              tooltipOpen={tooltipState['tcId']}
              onCopy={getTooltipCopyClickHandler('tcId')}
              onTooltipClose={getTooltipCopiedTooltipCloseHandler('tcId')}
            />
          </Stack>

          {patient.dob ? (
            <Stack>
              <PatientHeaderAttrKey>DOB</PatientHeaderAttrKey>
              <Typography>{patient.dob}</Typography>
            </Stack>
          ) : null}

          {patient.stageAndStatus ? (
            <Stack>
              <PatientHeaderAttrKey>Stage | Status</PatientHeaderAttrKey>
              <Typography>{patient.stageAndStatus}</Typography>
            </Stack>
          ) : null}

          {/* MRN */}
          <Stack>
            <PatientHeaderAttrKey>MRN</PatientHeaderAttrKey>
            {mrnEditing ? (
              <MRNField
                patientId={patient.id}
                defaultValue={patient.mrn || ''}
                onCancel={() => setMrnEditing(false)}
                onSuccess={() => setMrnEditing(false)}
              />
            ) : patient.mrn ? (
              <Stack direction={'row'} gap={'4px'}>
                <Typography onDoubleClick={() => setMrnEditing(true)}>
                  {patient.mrn}
                </Typography>
                <TCCopyToClipboard
                  value={patient.mrn}
                  tooltipOpen={tooltipState['mrn']}
                  onCopy={getTooltipCopyClickHandler('mrn')}
                  onTooltipClose={getTooltipCopiedTooltipCloseHandler('mrn')}
                />
              </Stack>
            ) : (
              <IconButton
                style={{ padding: '2px' }}
                onClick={() => setMrnEditing(true)}
              >
                <AddRoundedIcon fontSize="small" />
              </IconButton>
            )}
          </Stack>
        </Box>
        {!disableEdit ? (
          <Box sx={{ display: 'flex' }}>
            <IconButton
              onClick={handleEditTaskClick}
              data-testid="edit_patient_header_btn"
            >
              <EditIcon />
            </IconButton>
          </Box>
        ) : null}
      </Box>

      <Box sx={{ display: 'flex', columnGap: '30px', alignItems: 'center' }}>
        <Stack direction={'row'} columnGap={'56px'} rowGap={'32px'}>
          <Stack paddingTop={'12px'}>
            <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
              Patient Information
            </Typography>
            {patient.hasPhone ? (
              <Stack>
                {patient.phone1 ? (
                  <Stack paddingTop={'12px'}>
                    <PatientHeaderAttrKey variant={patientHeaderAttrKeyVariant}>
                      Phone 1
                    </PatientHeaderAttrKey>
                    <Stack direction={'row'} gap={'8px'}>
                      <PatientPhone variant={patientPhoneVariant}>
                        {patient.phone1}
                      </PatientPhone>
                      <TCCopyToClipboard
                        value={patient.phone1}
                        tooltipOpen={tooltipState['phone']}
                        onCopy={getTooltipCopyClickHandler('phone')}
                        onTooltipClose={getTooltipCopiedTooltipCloseHandler(
                          'phone'
                        )}
                      />
                    </Stack>
                  </Stack>
                ) : null}
                {patient.phone2 ? (
                  <Stack paddingTop={'12px'}>
                    <PatientHeaderAttrKey variant={patientHeaderAttrKeyVariant}>
                      Phone 2
                    </PatientHeaderAttrKey>
                    <Stack direction={'row'} gap={'8px'}>
                      <PatientPhone variant={patientPhoneVariant}>
                        {patient.phone2}
                      </PatientPhone>
                      <TCCopyToClipboard
                        value={patient.phone2}
                        tooltipOpen={tooltipState['phone']}
                        onCopy={getTooltipCopyClickHandler('phone')}
                        onTooltipClose={getTooltipCopiedTooltipCloseHandler(
                          'phone'
                        )}
                      />
                    </Stack>
                  </Stack>
                ) : null}
              </Stack>
            ) : (
              <Stack paddingTop={'12px'}>
                <Typography sx={{ color: 'rgba(0, 0, 0, 0.38)' }}>
                  No additional information.
                </Typography>
              </Stack>
            )}
          </Stack>

          {patient.medicalProfile && patient.hasPCPInfo ? (
            <Stack paddingTop={'12px'}>
              <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                PCP Information
              </Typography>
              <Stack>
                {patient.medicalProfile.primaryCarePhysician ? (
                  <Stack paddingTop={'12px'}>
                    <PatientHeaderAttrKey>PCP</PatientHeaderAttrKey>
                    <Stack direction={'row'} gap={'4px'}>
                      <Typography>
                        {patient.medicalProfile.primaryCarePhysician}
                      </Typography>
                      <TCCopyToClipboard
                        value={patient.medicalProfile.primaryCarePhysician}
                        tooltipOpen={tooltipState['pcp']}
                        onCopy={getTooltipCopyClickHandler('pcp')}
                        onTooltipClose={getTooltipCopiedTooltipCloseHandler(
                          'pcp'
                        )}
                      />
                    </Stack>
                  </Stack>
                ) : null}
                {patient.medicalProfile.groupName ? (
                  <Stack paddingTop={'12px'}>
                    <PatientHeaderAttrKey>PCP Group Name</PatientHeaderAttrKey>
                    <Stack direction={'row'} gap={'4px'}>
                      <Typography>
                        {patient.medicalProfile.groupName}
                      </Typography>
                      <TCCopyToClipboard
                        value={patient.medicalProfile.groupName}
                        tooltipOpen={tooltipState['groupName']}
                        onCopy={getTooltipCopyClickHandler('groupName')}
                        onTooltipClose={getTooltipCopiedTooltipCloseHandler(
                          'groupName'
                        )}
                      />
                    </Stack>
                  </Stack>
                ) : null}
              </Stack>
              <Stack direction={'row'} gap={'32px'}>
                {patient.medicalProfile.phone ? (
                  <Stack
                    paddingTop={'12px'}
                    direction={'row'}
                    gap={'8px'}
                    alignItems={'center'}
                  >
                    <PatientHeaderAttrKey>PCP Phone</PatientHeaderAttrKey>
                    <Stack direction={'row'} gap={'4px'}>
                      <Typography>{patient.medicalProfile.phone}</Typography>
                      <TCCopyToClipboard
                        value={patient.medicalProfile.phone}
                        tooltipOpen={tooltipState['pcpPhone']}
                        onCopy={getTooltipCopyClickHandler('pcpPhone')}
                        onTooltipClose={getTooltipCopiedTooltipCloseHandler(
                          'pcpPhone'
                        )}
                      />
                    </Stack>
                  </Stack>
                ) : null}
                {patient.medicalProfile.fax ? (
                  <Stack
                    paddingTop={'12px'}
                    direction={'row'}
                    gap={'8px'}
                    alignItems={'center'}
                  >
                    <PatientHeaderAttrKey>PCP Fax</PatientHeaderAttrKey>
                    <Stack direction={'row'} gap={'4px'}>
                      <Typography>{patient.medicalProfile.fax}</Typography>
                      <TCCopyToClipboard
                        value={patient.medicalProfile.fax}
                        tooltipOpen={tooltipState['fax']}
                        onCopy={getTooltipCopyClickHandler('fax')}
                        onTooltipClose={getTooltipCopiedTooltipCloseHandler(
                          'fax'
                        )}
                      />
                    </Stack>
                  </Stack>
                ) : null}
              </Stack>
            </Stack>
          ) : null}
        </Stack>
      </Box>
    </Box>
  )
}
