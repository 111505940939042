import { useCompassApiClient } from 'src/features/shared/infrastructure'
import {
  CreateCareTeamService,
  UpdateCareTeamService,
} from 'src/features/care-team/infrastructure'
import * as settings from 'src/config/settings'

export const useCareTeamService = () => {
  const { getCompassApiClient } = useCompassApiClient()

  const createCareTeam: CreateCareTeamService = async (payload) => {
    const compassClient = await getCompassApiClient()
    return await compassClient.post(
      settings.COMPASS_API_BASE_URL + '/care-team',
      payload
    )
  }

  const updateCareTeam: UpdateCareTeamService = async ({ id, payload }) => {
    const compassClient = await getCompassApiClient()
    return await compassClient.put(
      settings.COMPASS_API_BASE_URL + '/care-team/' + id,
      payload
    )
  }

  return {
    createCareTeam,
    updateCareTeam,
  }
}
