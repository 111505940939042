import { useCallback } from 'react'

import { useCompassApiClient } from 'src/features/shared/infrastructure'
import {
  GetEligiblePatientsService,
  GetEligiblePatientsServiceResponse,
} from 'src/features/eligibility/infrastructure'
import * as settings from 'src/config/settings'

export const useEligibilityService = () => {
  const { getCompassApiClient } = useCompassApiClient()

  const getEligiblePatients: GetEligiblePatientsService = useCallback(
    async (params, filters) => {
      const compassClient = await getCompassApiClient()
      let filterOptions
      if (
        filters &&
        !Object.values(filters).every((filter) => filter === undefined)
      ) {
        filterOptions = JSON.stringify(filters)
      }

      const allParams = {
        ...params,
        filterOptions,
      }
      return await compassClient.get<GetEligiblePatientsServiceResponse>(
        settings.COMPASS_API_BASE_URL + '/eligible-users',
        {
          params: allParams,
        }
      )
    },
    [getCompassApiClient]
  )

  return {
    getEligiblePatients,
  }
}
