import axios from 'axios'

import { QueuesUseCases } from 'src/features/queues/domain'
import { useQueuesService } from 'src/features/queues/infrastructure'
import {
  mapToGetQueuePatientsError,
  mapToGetQueuePatientsReturns,
  mapToGetQueuePatientsServiceArgs,
  mapToGetQueuesError,
  mapToGetQueuesReturns,
} from 'src/features/queues/adapters'
import { useAxiosErrorHandling } from 'src/features/shared/infrastructure'

export const useQueuesServiceAdapter = () => {
  const queuesService = useQueuesService()
  const { handleAxiosError } = useAxiosErrorHandling()

  const getQueues: QueuesUseCases['getQueues'] = async () => {
    try {
      const response = await queuesService.getQueues()
      return mapToGetQueuesReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToGetQueuesError)
      }
      throw error
    }
  }

  const getQueuePatients: QueuesUseCases['getQueuePatients'] = async (args) => {
    try {
      const serviceArgs = mapToGetQueuePatientsServiceArgs(args)
      const response = await queuesService.getQueuePatients(serviceArgs)
      return mapToGetQueuePatientsReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToGetQueuePatientsError)
      }
      throw error
    }
  }

  return {
    getQueues,
    getQueuePatients,
  }
}
