import { UseCompassUsersUseCases } from 'src/features/shared/domain'
import { useCompassUsersServiceAdapter } from 'src/features/shared/adapters'

type MakeUseCompassUsersUseCases = () => UseCompassUsersUseCases

const makeUseCompassUsersUseCases: MakeUseCompassUsersUseCases = () => () => {
  const compassUsersServiceAdapter = useCompassUsersServiceAdapter()

  return {
    getNavigators: compassUsersServiceAdapter.getNavigators,
    getMyCompassUser: compassUsersServiceAdapter.getMyCompassUser,
  }
}

export const useCompassUsersUseCases: UseCompassUsersUseCases =
  makeUseCompassUsersUseCases()
