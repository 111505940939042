import { useContext } from 'react'

import { AuthContext, AuthStore } from 'src/features/shared/infrastructure'

type UseAuth = () => AuthStore

export const useAuth: UseAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthContextProvider')
  }
  return context
}
