import { Stack, SxProps, Theme } from '@mui/material'
import { FieldPath, FieldValues } from 'react-hook-form'
import { FC } from 'react'

import {
  ControlledSelectField,
  REASON_FOR_REFUSAL,
} from 'src/features/shared/presentation'
import { NOTE_FIELD_LABEL } from 'src/features/notes/presentation'
import { mapToControlledSelectItem } from 'src/features/shared/adapters'

type StatusFieldProps = {
  statusFieldName?: FieldPath<FieldValues>
  reasonForRefusalFieldName?: FieldPath<FieldValues>
  statusRequired?: boolean
  reasonForRefusalRequired?: boolean
  formControlSx?: SxProps<Theme>
  statuses: string[]
}

export const StatusField: FC<StatusFieldProps> = ({
  statusRequired,
  reasonForRefusalRequired,
  statuses,
  statusFieldName = 'status',
  reasonForRefusalFieldName = 'reasonForRefusal',
  formControlSx,
}) => {
  return (
    <Stack direction={'row'} gap={'16px'}>
      <ControlledSelectField
        name={statusFieldName}
        label={NOTE_FIELD_LABEL.status}
        labelComponent={'inputLabel'}
        items={statuses.map(mapToControlledSelectItem)}
        required={statusRequired}
        formControlProps={{
          size: 'small',
        }}
        formControlSx={formControlSx ? formControlSx : { width: '200px' }}
      />

      {reasonForRefusalRequired ? (
        <ControlledSelectField
          name={reasonForRefusalFieldName}
          required={reasonForRefusalRequired}
          label={NOTE_FIELD_LABEL.reasonForRefusal}
          items={REASON_FOR_REFUSAL.map(mapToControlledSelectItem)}
          labelComponent="inputLabel"
          formControlProps={{
            size: 'small',
          }}
          formControlSx={formControlSx ? formControlSx : { width: '100%' }}
        />
      ) : null}
    </Stack>
  )
}
