import { rest } from 'msw'

import { GetPathwaysServiceResponse } from 'src/features/pathways/infrastructure/'

import * as settings from 'src/config/settings'
import { getPathwaysServiceResponseMock } from 'src/__mocks__/responses/get-pathways-service-response'

const url = `${settings.COMPASS_API_BASE_URL}/pathways/definitions`

export const getPathwaysServiceResponseHandler =
  rest.get<GetPathwaysServiceResponse>(url, async (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(getPathwaysServiceResponseMock))
  })
