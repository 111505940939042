import { rest } from 'msw'

import { CreatePatientServiceResponse } from 'src/features/patients/infrastructure'

import * as settings from 'src/config/settings'
import { createPatientServiceResponseMock } from 'src/__mocks__/responses/create-patient-service-response'

const url = `${settings.COMPASS_API_BASE_URL}/patients`

export const createPatientServiceResponseHandler =
  rest.post<CreatePatientServiceResponse>(url, async (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(createPatientServiceResponseMock))
  })
