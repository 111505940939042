import { rest } from 'msw'

import { GetPatientIntakeFlowsServiceResponse } from 'src/features/msk/infrastructure'

import * as settings from 'src/config/settings'
import { getPatientIntakeFlowsServiceResponseMock } from 'src/__mocks__/responses/get-patient-intake-flows-service-response'

const url = `${settings.COMPASS_API_BASE_URL}/msk/:patientId/intake-flows`

export const getPatientIntakeFlowsServiceResponseHandler =
  rest.get<GetPatientIntakeFlowsServiceResponse>(url, async (req, res, ctx) => {
    const { patientId } = req.params
    // const limit = req.url.searchParams.get('limit')
    // const orderBy = req.url.searchParams.get('orderBy')
    // const orderDirection = req.url.searchParams.get('orderDirection')

    return res(
      ctx.status(200),
      ctx.json({
        ...getPatientIntakeFlowsServiceResponseMock,
        patientId: patientId,
      })
    )
  })
