import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material'
import { formatDate } from 'src/features/shared/utils'

import { yupResolver } from '@hookform/resolvers/yup'
import { DevTool } from '@hookform/devtools'
import * as yup from 'yup'
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form'
import { FC, useState } from 'react'
import { toast } from 'react-toastify'

import EditIcon from '@mui/icons-material/Edit'

import {
  BackdropLoader,
  ControlledSelectField,
  ControlledTextField,
  LIMITATION,
  REASONS_FOR_NOT_USING_GARNER_TYPES,
  REFERRAL_REQUESTS_STATUS_TYPES,
  SCHEDULED_TYPES,
  SCHEDULED_WITH_GARNER_PROVIDER_TYPES,
  TCReactSelect,
  THERAPY,
  YesNoRadioGroup,
} from 'src/features/shared/presentation'
import {
  SearchProvidersModal,
  useProviderFields,
} from 'src/features/providers/presentation'
import { PatientIntakeFlowConditionV2 } from 'src/features/msk/presentation'
import { NOTE_FIELD_LABEL } from 'src/features/notes/presentation'
import {
  mapToControlledSelectItem,
  mapToTCReactSelectOption,
} from 'src/features/shared/adapters'
import { ProviderGroup, Provider } from 'src/features/providers/domain'
import { PatientV2 } from 'src/features/patients/domain'
import { useCreateCareTeam } from 'src/features/care-team/presentation/hooks/use-create-care-team'
import { useUpdateCareTeam } from 'src/features/care-team/presentation/hooks/use-update-care-team'
import { getOutlineInputNotAllowedCursorStyle } from 'src/features/shared/utils'
import { mapToCreateCareTeamArgs } from 'src/features/care-team/adapters'

const LABELS = {
  referringProviderGroup: 'Referring Provider Group',
  providerGroup: 'Provider Group',
  providerName: 'Provider Name',
  providerGroupLocation: 'Provider Group Location',
  garnerUsed: 'Did you use Garner?',
  didNotUseGarnerExplanation: 'If no, why not?',
  usedGarnerExplanation: 'If yes, did you schedule an appointment?',
  garnerUsageExplanationOther: 'Other Reason',
  scheduled: 'Scheduled',
  referralCoordination: 'Referral Coordination',
  refRequestStatus: 'Referral Request Status',
  requiredTherapy: 'Required Therapy',
  requiredOtherTherapy: 'Other Therapy',
  referringProviderId: 'Required Referring Provider',
  limitations: 'Limitations',
  limitationsOther: 'Other Limitations',
  referralUploadLink: 'Referral Upload Link',
  quickNote: 'Quick Note',
}

const getRadioGroupInputStyle = () => {
  return {
    '& .MuiFormControlLabel-root': {
      marginRight: '0px',
      marginLeft: '10px',
    },
    '& .MuiTypography-root': {
      color: 'GrayText',
    },
  }
}

const getCheckBoxInputStyle = () => {
  return {
    '& .MuiTypography-root': {
      fontSize: 14,
    },
  }
}

/** Schemas and fields **/
export type PatientCareTeamFormFields = {
  scheduled?: string
  providerId?: string
  providerGroupId?: string
  referringProviderGroupId?: string
  locationProvider?: string
  garnerRecommendationFlag?: string
  garnerUsed?: string
  garnerUsageExplanation?: string
  garnerUsageExplanationOther?: string
  referralCoordinationFlag?: boolean
  requiredTherapy?: string
  requiredOtherTherapy?: string
  referringProviderId?: string
  limitations?: string[]
  limitationsOther?: string
  refRequestStatus?: string
  referralUploadLink?: string
  quickNote?: string
  lastModifiedReqStatusBy?: string
  referralLastModified?: string
}

const isProviderGroupRequired = (scheduled?: string) =>
  !!scheduled &&
  ['Yes', 'Pending Partner Confirmation', 'Warm Transferred'].includes(
    scheduled
  )

const isGarnerUsageExplanationOtherRequired = (
  garnerUsageExplanationWatcher?: string
) => {
  return (
    garnerUsageExplanationWatcher === 'Some other reason' ||
    garnerUsageExplanationWatcher === 'No, some other reason'
  )
}

const isReferringProviderGroupRequired = (referralCoordinationFlag?: boolean) =>
  referralCoordinationFlag

const isRequiredTherapyRequired = (referralCoordinationFlag?: boolean) =>
  referralCoordinationFlag

const isReferringProviderIdRequired = (referralCoordinationFlag?: boolean) =>
  referralCoordinationFlag

const isLimitationsRequired = (referralCoordinationFlag?: boolean) =>
  referralCoordinationFlag

const isRefRequestStatusRequired = (referralCoordinationFlag?: boolean) =>
  referralCoordinationFlag

const isRequiredOtherTherapyRequired = (requiredTherapy?: string) =>
  requiredTherapy === 'Other'

const isLimitationsOtherRequired = (limitations?: string[]) =>
  limitations?.includes('Other')

/** Prop Type **/
export type CareTeamInfo = {
  patient: PatientV2
  condition?: PatientIntakeFlowConditionV2
}

/** Care Team Component **/
export const CareTeamForm: FC<CareTeamInfo> = ({ patient, condition }) => {
  const [editMode, setEditMode] = useState(false)

  const { createCareTeam, createCareTeamIsLoading } = useCreateCareTeam()
  const { updateCareTeam, updateCareTeamIsLoading } = useUpdateCareTeam()

  const getDefaultValues = (): PatientCareTeamFormFields => {
    if (condition && condition.careTeam) {
      return {
        scheduled: condition.careTeam.scheduled ?? '',
        providerId: condition.careTeam.providerId ?? '',
        providerGroupId: condition.careTeam.providerGroupId ?? '',
        referringProviderGroupId:
          condition.careTeam.referringProviderGroupId ?? '',
        locationProvider: condition.careTeam.locationProvider ?? '',
        garnerRecommendationFlag: condition.careTeam.garnerRecommendationFlag
          ? 'yes'
          : 'no',
        garnerUsed: condition.careTeam.garnerUsed ? 'yes' : 'no',
        garnerUsageExplanation: condition.careTeam.garnerUsageExplanation ?? '',
        garnerUsageExplanationOther:
          condition.careTeam.garnerUsageExplanationOther ?? '',
        referralCoordinationFlag: condition.careTeam.referralCoordinationFlag,
        requiredTherapy: condition.careTeam.requiredTherapy ?? '',
        requiredOtherTherapy: condition.careTeam.requiredOtherTherapy ?? '',
        referringProviderId: condition.careTeam.referringProviderId ?? '',
        limitations: condition.careTeam.limitations ?? [],
        limitationsOther: condition.careTeam.limitationsOther ?? '',
        refRequestStatus: condition.careTeam.refRequestStatus || '',
        referralUploadLink: condition.careTeam.referralUploadLink ?? '',
        quickNote: condition.careTeam.quickNote || '',
        lastModifiedReqStatusBy:
          condition.careTeam.lastModifiedReqStatusBy || '',
        referralLastModified: condition.careTeam.referralLastModified || '',
      }
    }
    return {
      scheduled: '',
      providerId: '',
      providerGroupId: '',
      referringProviderGroupId: '',
      locationProvider: '',
      garnerRecommendationFlag: 'no',
      garnerUsed: 'no',
      garnerUsageExplanation: '',
      garnerUsageExplanationOther: '',
      referralCoordinationFlag: false,
      requiredTherapy: '',
      requiredOtherTherapy: '',
      referringProviderId: '',
      limitations: [],
      limitationsOther: '',
      refRequestStatus: '',
      referralUploadLink: '',
      quickNote: '',
      lastModifiedReqStatusBy: '',
      referralLastModified: '',
    }
  }

  const patientCareTeamFormSchema: yup.Schema<PatientCareTeamFormFields> = yup
    .object()
    .shape({
      scheduled: yup.string().required('Required'),
      providerId: yup.string(),
      providerGroupId: yup.string().when('scheduled', {
        is: isProviderGroupRequired,
        then: (schema) => schema.required('Required'),
      }),
      locationProvider: yup.string(),
      garnerRecommendationFlag: yup.string(),
      garnerUsed: yup.string().oneOf(['yes', 'no']).required('Required'),
      garnerUsageExplanation: yup.string().required('Required'),
      garnerUsageExplanationOther: yup.string().when('garnerUsageExplanation', {
        is: isGarnerUsageExplanationOtherRequired,
        then: (schema) => schema.required('Required'),
      }),
      referralCoordinationFlag: yup.boolean(),
      requiredTherapy: yup.string().when('referralCoordinationFlag', {
        is: isRequiredTherapyRequired,
        then: (schema) => schema.required('Required'),
      }),
      requiredOtherTherapy: yup.string().when('requiredTherapy', {
        is: isRequiredOtherTherapyRequired,
        then: (schema) => schema.required('Required'),
      }),
      referringProviderGroupId: yup.string().when('referralCoordinationFlag', {
        is: isReferringProviderGroupRequired,
        then: (schema) => schema.required('Required'),
      }),
      referringProviderId: yup.string().when('referralCoordinationFlag', {
        is: isReferringProviderIdRequired,
        then: (schema) => schema.required('Required'),
      }),
      limitations: yup
        .array()
        .of(yup.string().required())
        .when('referralCoordinationFlag', {
          is: isLimitationsRequired,
          then: (schema) => schema.required('Required'),
        }),
      limitationsOther: yup.string().when('limitations', {
        is: isLimitationsOtherRequired,
        then: (schema) => schema.required('Required'),
      }),
      refRequestStatus: yup.string().when('referralCoordinationFlag', {
        is: isRefRequestStatusRequired,
        then: (schema) => schema.required('Required'),
      }),
      referralUploadLink: yup.string().url(),
    })

  const formMethods = useForm<PatientCareTeamFormFields>({
    resolver: yupResolver(patientCareTeamFormSchema),
    mode: 'onBlur',
    defaultValues: getDefaultValues(),
  })

  const providerGroupWatcher = formMethods.watch('providerGroupId')
  const referringProviderGroupWatcher = formMethods.watch(
    'referringProviderGroupId'
  )
  const scheduledWatcher = formMethods.watch('scheduled')
  const garnerUsedWatcher = formMethods.watch('garnerUsed')
  const garnerUsageExplanationWatcher = formMethods.watch(
    'garnerUsageExplanation'
  )
  const referralCoordinationFlagWatcher = formMethods.watch(
    'referralCoordinationFlag'
  )
  const requiredTherapyWatcher = formMethods.watch('requiredTherapy')
  const limitationsWatcher = formMethods.watch('limitations')

  const onCreateProviderGroupSuccess = (providerGroup: ProviderGroup) => {
    formMethods.setValue('providerGroupId', providerGroup.id)
  }

  const onCreateProviderSuccess = (provider: Provider) => {
    formMethods.setValue('providerId', provider.id)
  }

  const onCreateReferringProviderSuccess = (provider: Provider) => {
    formMethods.setValue('referringProviderId', provider.id)
  }

  const {
    handleCreateProviderGroup,
    createProviderGroupIsLoading,
    createProviderIsLoading,
    getCurrentProviderGroupOption,
    getCurrentProviderOption,
    getProviderGroupsIsLoading,
    getProviderSelectKey,
    getProvidersIsLoading,
    handleSearchProvidersModalClose,
    handleSearchProvidersModalUnMount,
    handleCreateProviderSuccess,
    initSearchProviderForm,
    openSearchProvidersModal,
    providerGroups,
    providers,
  } = useProviderFields({
    currentProviderGroupId: providerGroupWatcher,
    onCreateProviderGroupSuccess,
    onCreateProviderSuccess,
  })

  const {
    handleCreateProviderGroup: handleCreateReferringProviderGroup,
    createProviderGroupIsLoading: createReferringProviderGroupIsLoading,
    createProviderIsLoading: createReferringProviderIsLoading,
    getCurrentProviderGroupOption: getCurrentReferringProviderGroupOption,
    getProviderGroupsIsLoading: getReferringProviderGroupsIsLoading,
    getCurrentProviderOption: getCurrentReferringProviderOption,
    getProviderSelectKey: getReferringProviderSelectKey,
    getProvidersIsLoading: getReferringProvidersIsLoading,
    handleSearchProvidersModalClose: handleSearchReferringProvidersModalClose,
    handleSearchProvidersModalUnMount:
      handleSearchReferringProvidersModalUnMount,
    handleCreateProviderSuccess: handleCreateReferringProviderSuccess,
    initSearchProviderForm: initSearchReferringProviderForm,
    openSearchProvidersModal: openSearchReferringProvidersModal,
    providerGroups: referringProviderGroups,
    providers: referringProviders,
  } = useProviderFields({
    onCreateProviderSuccess: onCreateReferringProviderSuccess,
    currentProviderGroupId: referringProviderGroupWatcher,
  })
  const scheduledFieldDisabled = !editMode
  const providerIdFieldDisabled =
    !editMode || !providerGroupWatcher || getProvidersIsLoading
  const referringProviderIdFieldDisabled =
    !editMode ||
    !referringProviderGroupWatcher ||
    getReferringProvidersIsLoading
  const providerGroupIdFieldDisabled = !editMode
  const referringProviderGroupIdFieldDisabled = !editMode
  const locationProviderFieldDisabled = !editMode
  const garnerUsedFieldDisabled = !editMode
  const garnerUsageExplanationFieldDisabled = !editMode
  const garnerUsageExplanationOtherFieldDisabled =
    !editMode ||
    (garnerUsageExplanationWatcher !== 'No, some other reason' &&
      garnerUsageExplanationWatcher !== 'Some other reason')
  const referralCoordinationFlagFieldDisabled = !editMode
  const requiredTherapyFieldDisabled = !editMode
  const limitationsFieldDisabled = !editMode
  const refRequestStatusFieldDisabled = !editMode
  const referralUploadLinkFieldDisabled = !editMode
  const requiredOtherTherapyFieldDisabled = !editMode
  const limitationsOtherFieldDisabled = !editMode

  const handleEditClick = () => {
    setEditMode(true)
  }

  const handleCancelClick = () => {
    setEditMode(false)
    formMethods.reset(getDefaultValues())
  }

  const submitHandler: SubmitHandler<PatientCareTeamFormFields> = (data) => {
    if (condition && condition.careTeam) {
      const garnerUsed = data.garnerUsed === 'yes'
      const garnerRecommendationFlag =
        garnerUsed && data.garnerUsageExplanation === 'Yes'
      updateCareTeam(
        {
          id: condition.careTeam.id,
          payload: {
            ...data,
            active: true,
            garnerRecommendationFlag,
            garnerUsed,
            linkedAssignedPathwayId: condition.associatedPathway?.id,
          },
        },
        {
          onSuccess: () => {
            toast.success('Care Team updated successfully!')
            setEditMode(false)
          },
          onError: () => {
            toast.error('Care Team update failed!')
          },
        }
      )
    } else if (condition) {
      const createCareTeamArgs = mapToCreateCareTeamArgs(
        patient,
        condition,
        data
      )
      createCareTeam(createCareTeamArgs, {
        onSuccess: () => {
          toast.success('Care Team created successfully!')
          setEditMode(false)
        },
        onError: () => {
          toast.error('Care Team creation failed!')
        },
      })
    }
  }

  return (
    <FormProvider {...formMethods}>
      <Box
        sx={{
          p: '24px',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '24px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            fontWeight={500}
            fontSize={20}
            color={'rgba(0, 0, 0, 0.87)'}
          >
            Care Team Info
          </Typography>
          {!editMode && condition && condition.associatedPathway ? (
            <IconButton onClick={handleEditClick}>
              <EditIcon />
            </IconButton>
          ) : null}
        </Box>

        {condition ? (
          <Grid container item sm={12} columnSpacing={2}>
            {/* Condition */}
            <Grid item sm={4}>
              <Box sx={{ display: 'flex', columnGap: '16px' }}>
                <Typography fontSize={19} color={'GrayText'}>
                  Condition:
                </Typography>
                <Typography fontSize={19} color={'GrayText'}>
                  {condition.bodyRegion}
                </Typography>
              </Box>
            </Grid>
            {/* Pathway */}
            <Grid item sm={4}>
              <Box sx={{ display: 'flex', columnGap: '16px' }}>
                <Typography fontSize={19} color={'GrayText'}>
                  Pathway:
                </Typography>
                <Typography fontSize={19} color={'GrayText'}>
                  {condition.associatedPathway
                    ? condition.associatedPathway.pathway
                    : 'No Pathway'}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        ) : null}

        {!condition ? (
          'No condition selected'
        ) : !condition.associatedPathway ? (
          'Assign a pathway to this condition to add a care team'
        ) : (
          <form onSubmit={formMethods.handleSubmit(submitHandler)}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '24px',
              }}
            >
              {/* === Dropdowns === */}
              <Grid container item sm={12} columnSpacing={2}>
                <Grid item sm={4}>
                  <ControlledSelectField
                    name={'scheduled'}
                    label={NOTE_FIELD_LABEL.scheduled}
                    labelComponent={'inputLabel'}
                    items={SCHEDULED_TYPES.map(mapToControlledSelectItem)}
                    formControlProps={{
                      size: 'small',
                      disabled: scheduledFieldDisabled,
                    }}
                    formControlSx={{
                      width: '100%',
                      ...getOutlineInputNotAllowedCursorStyle(
                        scheduledFieldDisabled
                      ),
                    }}
                    required
                  />
                </Grid>
                <Grid item sm={4}>
                  {/* Provider Group */}
                  <Controller
                    name="providerGroupId"
                    control={formMethods.control}
                    render={({ field }) => (
                      <TCReactSelect
                        isClearable
                        isCreatable
                        isDisabled={providerGroupIdFieldDisabled}
                        placeholder={NOTE_FIELD_LABEL.providerGroup}
                        options={providerGroups.map(mapToTCReactSelectOption)}
                        value={getCurrentProviderGroupOption(field.value)}
                        required={isProviderGroupRequired(scheduledWatcher)}
                        isLoading={
                          getProviderGroupsIsLoading ||
                          createProviderGroupIsLoading
                        }
                        onBlur={field.onBlur}
                        onChange={(option) => {
                          const value = option ? option.value : ''
                          formMethods.setValue('providerId', '')
                          field.onChange(value)
                        }}
                        creatableProps={{
                          onCreateOption: handleCreateProviderGroup,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={4}>
                  <Controller
                    name="providerId"
                    control={formMethods.control}
                    render={({ field }) => (
                      <TCReactSelect
                        key={getProviderSelectKey(field.value)}
                        isCreatable
                        isClearable
                        placeholder={NOTE_FIELD_LABEL.providerName}
                        value={getCurrentProviderOption(field.value)}
                        options={providers.map(mapToTCReactSelectOption)}
                        isDisabled={providerIdFieldDisabled}
                        isLoading={
                          getProvidersIsLoading || createProviderIsLoading
                        }
                        onBlur={field.onBlur}
                        onChange={(option) => {
                          const value = !option ? '' : option.value
                          field.onChange(value)
                        }}
                        creatableProps={{
                          formatCreateLabel: (val) =>
                            `Search for provider "${val}"`,
                          onCreateOption: initSearchProviderForm,
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              {/* Second Row */}
              <Grid container item sm={12} columnSpacing={2}>
                <Grid item sm={4}>
                  <ControlledTextField
                    name="locationProvider"
                    label={LABELS.providerGroupLocation}
                    labelComponent={'inputLabel'}
                    formControlSx={{
                      ...getOutlineInputNotAllowedCursorStyle(
                        locationProviderFieldDisabled
                      ),
                    }}
                    textFieldProps={{
                      disabled: locationProviderFieldDisabled,
                    }}
                  />
                </Grid>
              </Grid>

              {/* Third Row */}
              <Grid container item sm={12} columnSpacing={2}>
                <Grid item sm={4}>
                  <Controller
                    name={'garnerUsed'}
                    control={formMethods.control}
                    render={({ field }) => (
                      <YesNoRadioGroup
                        required
                        label={LABELS.garnerUsed}
                        onChange={(option) => {
                          const value = option ? option.target.value : ''
                          formMethods.resetField('garnerUsageExplanation', {
                            defaultValue: '',
                          })
                          formMethods.resetField(
                            'garnerUsageExplanationOther',
                            {
                              defaultValue: '',
                            }
                          )
                          field.onChange(value)
                        }}
                        value={field.value}
                        formFieldContainerProps={{
                          formControlProps: {
                            disabled: garnerUsedFieldDisabled,
                          },
                          formControlSx: {
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            fontSize: '50px',
                          },
                        }}
                        sx={getRadioGroupInputStyle()}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={4}>
                  {garnerUsedWatcher === 'yes' ? (
                    <ControlledSelectField
                      extraOnChange={() => {
                        formMethods.resetField('garnerUsageExplanationOther', {
                          defaultValue: '',
                        })
                      }}
                      required={true}
                      name={'garnerUsageExplanation'}
                      label={LABELS.usedGarnerExplanation}
                      labelComponent={'inputLabel'}
                      items={SCHEDULED_WITH_GARNER_PROVIDER_TYPES.map(
                        mapToControlledSelectItem
                      )}
                      formControlProps={{
                        size: 'small',
                        disabled: garnerUsageExplanationFieldDisabled,
                      }}
                      formControlSx={{
                        width: '100%',
                        ...getOutlineInputNotAllowedCursorStyle(
                          garnerUsageExplanationFieldDisabled
                        ),
                      }}
                      selectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                        },
                      }}
                    />
                  ) : (
                    <ControlledSelectField
                      extraOnChange={() => {
                        formMethods.resetField('garnerUsageExplanationOther', {
                          defaultValue: '',
                        })
                      }}
                      required={true}
                      name={'garnerUsageExplanation'}
                      label={LABELS.didNotUseGarnerExplanation}
                      labelComponent={'inputLabel'}
                      items={REASONS_FOR_NOT_USING_GARNER_TYPES.map(
                        mapToControlledSelectItem
                      )}
                      formControlProps={{
                        size: 'small',
                        disabled: garnerUsageExplanationFieldDisabled,
                      }}
                      formControlSx={{
                        width: '100%',
                        ...getOutlineInputNotAllowedCursorStyle(
                          garnerUsageExplanationFieldDisabled
                        ),
                      }}
                      selectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                        },
                      }}
                    />
                  )}
                </Grid>
                <Grid item sm={4}>
                  <ControlledTextField
                    name={'garnerUsageExplanationOther'}
                    label={LABELS.garnerUsageExplanationOther}
                    labelComponent={'inputLabel'}
                    formControlSx={{
                      ...getOutlineInputNotAllowedCursorStyle(
                        garnerUsageExplanationOtherFieldDisabled
                      ),
                    }}
                    textFieldProps={{
                      disabled: garnerUsageExplanationOtherFieldDisabled,
                      placeholder: 'Type Other Reason',
                    }}
                  />
                </Grid>
              </Grid>

              {/* Fourth Row */}
              <Grid container item sm={12} columnSpacing={2}>
                <Grid item sm={4}>
                  <Controller
                    name="referralCoordinationFlag"
                    control={formMethods.control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Switch
                            disabled={referralCoordinationFlagFieldDisabled}
                            onChange={field.onChange}
                            checked={field.value}
                            color="primary"
                          />
                        }
                        sx={getCheckBoxInputStyle()}
                        label={LABELS.referralCoordination}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              {referralCoordinationFlagWatcher ? (
                <>
                  <Grid container item sm={12} columnSpacing={2}>
                    <Grid item sm={4}>
                      <ControlledSelectField
                        name="refRequestStatus"
                        label={LABELS.refRequestStatus}
                        labelComponent={'inputLabel'}
                        items={REFERRAL_REQUESTS_STATUS_TYPES.map(
                          mapToControlledSelectItem
                        )}
                        required
                        formControlProps={{
                          size: 'small',
                          disabled: refRequestStatusFieldDisabled,
                        }}
                        formControlSx={{
                          width: '100%',
                          ...getOutlineInputNotAllowedCursorStyle(
                            refRequestStatusFieldDisabled
                          ),
                        }}
                      />
                      {condition.careTeam?.lastReferralStatusUpdater && (
                        <Typography
                          fontSize={14}
                          color={'rgba(0, 0, 0, 0.4)'}
                          sx={{
                            px: '24px',
                          }}
                        >
                          last set by{' '}
                          {condition.careTeam?.lastReferralStatusUpdater} at{' '}
                          {formatDate(
                            condition.careTeam?.referralLastModified,
                            true
                          )}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item sm={4}>
                      <Controller
                        name="referringProviderGroupId"
                        control={formMethods.control}
                        render={({ field }) => (
                          <TCReactSelect
                            isClearable
                            isCreatable
                            isDisabled={referringProviderGroupIdFieldDisabled}
                            placeholder={LABELS.referringProviderGroup}
                            options={referringProviderGroups.map(
                              mapToTCReactSelectOption
                            )}
                            value={getCurrentReferringProviderGroupOption(
                              field.value
                            )}
                            required={isReferringProviderGroupRequired(
                              referralCoordinationFlagWatcher
                            )}
                            isLoading={
                              getReferringProviderGroupsIsLoading ||
                              createReferringProviderGroupIsLoading
                            }
                            onBlur={field.onBlur}
                            onChange={(option) => {
                              const value = option ? option.value : ''
                              formMethods.setValue('referringProviderId', '')
                              field.onChange(value)
                            }}
                            creatableProps={{
                              onCreateOption:
                                handleCreateReferringProviderGroup,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item sm={4}>
                      <Controller
                        name="referringProviderId"
                        control={formMethods.control}
                        render={({ field }) => (
                          <TCReactSelect
                            key={getReferringProviderSelectKey(field.value)}
                            isCreatable
                            placeholder={NOTE_FIELD_LABEL.referringProvider}
                            required={isReferringProviderIdRequired(
                              referralCoordinationFlagWatcher
                            )}
                            value={getCurrentReferringProviderOption(
                              field.value
                            )}
                            options={referringProviders.map(
                              mapToTCReactSelectOption
                            )}
                            isDisabled={referringProviderIdFieldDisabled}
                            isLoading={
                              getReferringProvidersIsLoading ||
                              createReferringProviderIsLoading
                            }
                            onBlur={field.onBlur}
                            onChange={(option) => {
                              const value = !option ? '' : option.value
                              field.onChange(value)
                            }}
                            creatableProps={{
                              formatCreateLabel: (val) =>
                                `Search for provider "${val}"`,
                              onCreateOption: initSearchReferringProviderForm,
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid container item sm={12} columnSpacing={2}>
                    <Grid item sm={4}>
                      <ControlledSelectField
                        name="requiredTherapy"
                        label={LABELS.requiredTherapy}
                        labelComponent={'inputLabel'}
                        items={THERAPY.map(mapToControlledSelectItem)}
                        formControlProps={{
                          size: 'small',
                          disabled: requiredTherapyFieldDisabled,
                        }}
                        formControlSx={{
                          width: '100%',
                          ...getOutlineInputNotAllowedCursorStyle(
                            requiredTherapyFieldDisabled
                          ),
                        }}
                        required
                      />
                    </Grid>
                    {isRequiredOtherTherapyRequired(requiredTherapyWatcher) ? (
                      <Grid item sm={4} key={requiredTherapyWatcher}>
                        <ControlledTextField
                          name="requiredOtherTherapy"
                          label={LABELS.requiredOtherTherapy}
                          labelComponent={'inputLabel'}
                          required={isRequiredOtherTherapyRequired(
                            requiredTherapyWatcher
                          )}
                          formControlSx={{
                            ...getOutlineInputNotAllowedCursorStyle(
                              requiredOtherTherapyFieldDisabled
                            ),
                          }}
                          textFieldProps={{
                            disabled: requiredOtherTherapyFieldDisabled,
                          }}
                        />
                      </Grid>
                    ) : null}
                  </Grid>

                  <Grid container item sm={12} columnSpacing={2}>
                    <Grid item sm={4}>
                      <ControlledSelectField
                        name="limitations"
                        label={LABELS.limitations}
                        labelComponent={'inputLabel'}
                        items={LIMITATION.map(mapToControlledSelectItem)}
                        formControlProps={{
                          size: 'small',
                          disabled: limitationsFieldDisabled,
                        }}
                        formControlSx={{
                          width: '100%',
                          ...getOutlineInputNotAllowedCursorStyle(
                            limitationsFieldDisabled
                          ),
                        }}
                        multiple
                      />
                    </Grid>
                    {isLimitationsOtherRequired(limitationsWatcher) ? (
                      <Grid item sm={4} key={limitationsWatcher?.join(',')}>
                        <ControlledTextField
                          name="limitationsOther"
                          label={LABELS.limitationsOther}
                          labelComponent={'inputLabel'}
                          required={isLimitationsOtherRequired(
                            limitationsWatcher
                          )}
                          formControlSx={{
                            ...getOutlineInputNotAllowedCursorStyle(
                              limitationsOtherFieldDisabled
                            ),
                          }}
                          textFieldProps={{
                            disabled: limitationsOtherFieldDisabled,
                          }}
                        />
                      </Grid>
                    ) : null}
                  </Grid>

                  <Grid container item sm={12} columnSpacing={2}>
                    <Grid item sm={4}>
                      <ControlledTextField
                        name="referralUploadLink"
                        label={LABELS.referralUploadLink}
                        labelComponent={'inputLabel'}
                        formControlSx={{
                          ...getOutlineInputNotAllowedCursorStyle(
                            referralUploadLinkFieldDisabled
                          ),
                        }}
                        textFieldProps={{
                          disabled: referralUploadLinkFieldDisabled,
                        }}
                      />
                    </Grid>
                    <Grid item sm={4}>
                      <ControlledTextField
                        name="quickNote"
                        label={LABELS.quickNote}
                        labelComponent={'inputLabel'}
                        formControlSx={{
                          ...getOutlineInputNotAllowedCursorStyle(
                            referralUploadLinkFieldDisabled
                          ),
                        }}
                        textFieldProps={{
                          disabled: referralUploadLinkFieldDisabled,
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : null}

              {/* Last row, buttons */}
              {editMode ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    columnGap: '16px',
                    flex: '1 1 auto',
                  }}
                >
                  <Button
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={handleCancelClick}
                  >
                    CANCEL
                  </Button>
                  <Tooltip title="" arrow>
                    <Box>
                      <Button
                        type="submit"
                        color="secondary"
                        variant="contained"
                      >
                        SAVE
                      </Button>
                    </Box>
                  </Tooltip>
                </Box>
              ) : null}
            </Box>
            <DevTool control={formMethods.control} placement={'bottom-left'} />
          </form>
        )}
      </Box>

      <SearchProvidersModal
        key={'search-providers-modal'}
        open={openSearchProvidersModal}
        onClose={handleSearchProvidersModalClose}
        onUnMount={handleSearchProvidersModalUnMount}
        searchProviderFormProps={{
          onCreateProviderSuccess: handleCreateProviderSuccess,
          onClose: handleSearchProvidersModalClose,
        }}
      />

      <SearchProvidersModal
        key={'search-referring-providers-modal'}
        open={openSearchReferringProvidersModal}
        onClose={handleSearchReferringProvidersModalClose}
        onUnMount={handleSearchReferringProvidersModalUnMount}
        searchProviderFormProps={{
          onCreateProviderSuccess: handleCreateReferringProviderSuccess,
          onClose: handleSearchReferringProvidersModalClose,
        }}
      />
      <BackdropLoader
        open={createCareTeamIsLoading || updateCareTeamIsLoading}
      />
    </FormProvider>
  )
}
