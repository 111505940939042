import {
  Box,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Stack,
  FormHelperText,
} from '@mui/material'
import { FC, ChangeEvent, Key } from 'react'

export type CheckboxGroupOption = {
  key: Key
  value: string
  label: string
}

type CheckboxGroupProps = {
  label: string
  name: string
  options: CheckboxGroupOption[]
  selectedOptions: string[]
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void
  error?: boolean
  helperText?: string
  maxRows?: number
  dataTestIdPreffix?: string
}

export const CheckboxGroup: FC<CheckboxGroupProps> = ({
  label,
  name,
  options,
  selectedOptions,
  handleChange,
  error,
  helperText,
  maxRows = 6,
  dataTestIdPreffix = '',
}) => {
  const getColumns = () => {
    const columnsQty =
      options.length > maxRows ? Math.ceil(options.length / maxRows) : 1

    const columns = []

    for (let i = 0; i < columnsQty; i++) {
      columns.push(options.slice(i * maxRows, (i + 1) * maxRows))
    }

    return columns
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <FormControl component="fieldset" variant="standard" error={error}>
        <FormLabel
          component="legend"
          sx={{
            color: `${error ? 'error.main' : 'common.black'}`,
            fontWeight: '500',
          }}
        >
          {label}
        </FormLabel>
        <Box sx={{ m: '16px 0 0 16px' }}>
          <Stack display={'flex'} direction={'row'}>
            {getColumns().map((choicesColumn, index) => (
              <FormGroup key={index}>
                {choicesColumn.map((choice) => (
                  <FormControlLabel
                    key={choice.key}
                    control={
                      <Checkbox
                        checked={selectedOptions.includes(choice.value)}
                        onChange={handleChange}
                        name={name}
                        value={choice.value}
                        sx={{ paddingTop: '0' }}
                        data-testid={`${dataTestIdPreffix}_${choice.key}`}
                      />
                    }
                    label={choice.label}
                    sx={{
                      minWidth: '190px',
                      alignItems: 'flex-start',
                    }}
                  />
                ))}
              </FormGroup>
            ))}
          </Stack>
        </Box>
        {helperText ? (
          <FormHelperText variant={'outlined'} error={error}>
            {helperText}
          </FormHelperText>
        ) : null}
      </FormControl>
    </Box>
  )
}
