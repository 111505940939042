import {
  readLocalStorage,
  saveInLocalStorage,
  removeFromLocalStorage,
} from 'src/features/shared/utils'
import { NotesState } from 'src/features/shared/infrastructure'

const NOTES_LOCAL_STORAGE_KEY_PREFIX = 'notes-slice'

export const loadNotesStateFromLocalStorage = (patientId: string) => {
  const key = `${NOTES_LOCAL_STORAGE_KEY_PREFIX}-${patientId}`
  return readLocalStorage<NotesState>(key)
}

export const saveNotesStateInLocalStorage = (state: NotesState) => {
  const key = `${NOTES_LOCAL_STORAGE_KEY_PREFIX}-${state.currentNotesPatientId}`
  saveInLocalStorage(key, state)
}

export const removeNotesStateFromLocalStorage = (
  patientId: NotesState['currentNotesPatientId']
) => {
  const key = `${NOTES_LOCAL_STORAGE_KEY_PREFIX}-${patientId}`
  removeFromLocalStorage(key)
}
