import { rest } from 'msw'

import { CreatePathwayServiceResponse } from 'src/features/pathways/infrastructure'

import * as settings from 'src/config/settings'
import { createPathwayServiceResponseMock } from 'src/__mocks__/responses/create-pathway-service-response'

const url = `${settings.COMPASS_API_BASE_URL}/pathways/assign`

export const createPathwayServiceResponseHandler =
  rest.post<CreatePathwayServiceResponse>(url, async (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(createPathwayServiceResponseMock))
  })
