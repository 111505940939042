import { rest } from 'msw'

import * as settings from 'src/config/settings'
import {
  getProvidersServiceResponseMockExample,
  getProvidersServiceResponseMockExampleBenchmark,
  getProvidersServiceResponseMockExampleResurgens,
} from 'src/__mocks__/responses/get-providers-service-response'
import { GetProvidersServiceResponse } from 'src/features/providers/infrastructure'

const url = `${settings.PROVIDERS_API_BASE_URL}/providers`

export const getProvidersServiceResponseHandler =
  rest.get<GetProvidersServiceResponse>(url, async (req, res, ctx) => {
    const filterOptionsEncoded = req.url.searchParams.get('filterOptions')

    if (filterOptionsEncoded) {
      const filterOptionsString = decodeURIComponent(filterOptionsEncoded)
      const filterOptions = JSON.parse(filterOptionsString)

      if (filterOptions.groupId) {
        if (filterOptions.groupId === '03439e16-d330-4838-b9ce-1b14227960cf') {
          return res(
            ctx.status(200),
            ctx.json(getProvidersServiceResponseMockExampleResurgens)
          )
        }

        if (filterOptions.groupId === '65b29ed1-2bc2-43e1-a369-18630c83ce97') {
          return res(
            ctx.status(200),
            ctx.json(getProvidersServiceResponseMockExampleBenchmark)
          )
        }
      }
    }
    return res(
      ctx.status(200),
      ctx.json(getProvidersServiceResponseMockExample)
    )
  })
