import { PatientV2 } from 'src/features/patients/domain'
import { getPatientHasStage } from 'src/features/shared/utils'
import { useCallback } from 'react'
import { useGetPatientStatusDefinitionsQuery } from 'src/features/patients/presentation'
import { NoteType } from 'src/features/notes/domain'

type Options = {
  noteType?: NoteType
}

export const useStatusDefinitions = (patient: PatientV2, options?: Options) => {
  const { statusDefinitions, getPatientStatusDefinitionsIsLoading } =
    useGetPatientStatusDefinitionsQuery()

  const getStatusInitialValue = useCallback(() => {
    const patientHasStage = getPatientHasStage(patient)

    if (patientHasStage) {
      return patient.stageAndStatus?.status || ''
    }

    return ''
  }, [patient])

  const getAllStatusDefinitions = useCallback(
    (referralSource?: string) => {
      const statusInitialValue = getStatusInitialValue()

      if (!statusInitialValue || !statusDefinitions) {
        return []
      }

      let filteredStatusDefinitions = [...statusDefinitions[statusInitialValue]]

      if (
        (referralSource || patient.referralSource) !==
        'PCP - Ineligible Referral'
      ) {
        filteredStatusDefinitions = filteredStatusDefinitions.filter(
          (status) => status !== 'Pathway Rec. Only'
        )
      }

      if (!statusDefinitions[statusInitialValue].includes(statusInitialValue)) {
        filteredStatusDefinitions = [
          statusInitialValue,
          ...filteredStatusDefinitions,
        ]
      }

      if (options?.noteType === 'Intake') {
        filteredStatusDefinitions.push('Opt Out')
      }

      return Array.from(new Set(filteredStatusDefinitions))
    },
    [
      getStatusInitialValue,
      options?.noteType,
      patient.referralSource,
      statusDefinitions,
    ]
  )

  return {
    getStatusInitialValue,
    getAllStatusDefinitions,
    getPatientStatusDefinitionsIsLoading,
  }
}
