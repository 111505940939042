import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from 'src/features/shared/infrastructure'
import { EligiblePatient } from 'src/features/eligibility/domain'
import { EligiblePatientsTableRow } from 'src/features/eligibility/presentation'

export type EligibilityState = {
  selectedEligiblePatientRow: EligiblePatientsTableRow | null
  selectedEligiblePatient: EligiblePatient | null
  openEligiblePatientFormModal: boolean
  createPatientFormModalSelectedTab: 'eligibility' | 'manual'
}

const initialState: EligibilityState = {
  selectedEligiblePatientRow: null,
  selectedEligiblePatient: null,
  openEligiblePatientFormModal: false,
  createPatientFormModalSelectedTab: 'eligibility',
}

const eligibilitySlice = createSlice({
  name: 'eligibility',
  initialState,
  reducers: {
    setSelectedEligiblePatientAction(
      state,
      action: PayloadAction<EligibilityState['selectedEligiblePatient']>
    ) {
      state.selectedEligiblePatient = action.payload
    },
    setSelectedEligiblePatientRowAction(
      state,
      action: PayloadAction<EligibilityState['selectedEligiblePatientRow']>
    ) {
      state.selectedEligiblePatientRow = action.payload
    },
    setOpenEligiblePatientFormModalAction(
      state,
      action: PayloadAction<EligibilityState['openEligiblePatientFormModal']>
    ) {
      state.openEligiblePatientFormModal = action.payload
    },
    clearSelectedEligiblePatientAction(state) {
      state.selectedEligiblePatient = null
      state.selectedEligiblePatientRow = null
    },
  },
})

export const {
  setSelectedEligiblePatientRowAction,
  setSelectedEligiblePatientAction,
  setOpenEligiblePatientFormModalAction,
  clearSelectedEligiblePatientAction,
} = eligibilitySlice.actions

export const selectedEligiblePatientSelector = (state: RootState) =>
  state.eligibility.selectedEligiblePatient

export const selectedEligiblePatientRowSelector = (state: RootState) =>
  state.eligibility.selectedEligiblePatientRow

export const openEligiblePatientFormModalSelector = (state: RootState) =>
  state.eligibility.openEligiblePatientFormModal

export default eligibilitySlice.reducer
