import { rest } from 'msw'

import { GetPatientStatusDefinitionsServiceResponse } from 'src/features/patients/infrastructure'

import * as settings from 'src/config/settings'
import { getPatientsStatusDefinitionsServiceResponseMock } from 'src/__mocks__/responses/get-patients-status-definitions-service-response'

const url = `${settings.COMPASS_API_BASE_URL}/patients/status/definitions`

export const getPatientsStatusDefinitionsServiceResponseHanlder =
  rest.get<GetPatientStatusDefinitionsServiceResponse>(
    url,
    async (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json(getPatientsStatusDefinitionsServiceResponseMock)
      )
    }
  )
