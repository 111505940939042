import { FC, ReactNode } from 'react'

type TabPanelProps = {
  index: string | number
  value: string | number
  children?: ReactNode
  idPrefix?: string
}

export const CustomTabPanel: FC<TabPanelProps> = (props) => {
  const { children, value, index, idPrefix = 'simple-', ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${idPrefix}-tabpanel-${index}`}
      aria-labelledby={`${idPrefix}-tab-${index}`}
      {...other}
    >
      {value === index ? children : null}
    </div>
  )
}
