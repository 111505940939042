export const getRotateIconSx = (rotate = true) => ({
  animation: rotate ? 'spin 1s linear infinite' : '',
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(-360deg)',
    },
    '100%': {
      transform: 'rotate(0deg)',
    },
  },
})

export const getOutlineInputNotAllowedCursorStyle = (isDisabled: boolean) => ({
  '& .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled': {
    cursor: isDisabled ? 'not-allowed' : '',
  },
  '& .Mui-disabled.MuiInputBase-input.MuiOutlinedInput-input': {
    cursor: isDisabled ? 'not-allowed' : '',
  },
})
