import { useQuery } from '@tanstack/react-query'

import { useTasksUseCases } from 'src/features/tasks/tasks.service-locator'
import { GetTasksParams } from 'src/features/tasks/domain'

export const GET_UPCOMING_TASKS_BY_USER_ID_QUERY_KEY =
  'getUpcomingTasksByUserId'

type UseGetUpcomingTasksByUserIdQueryArgs = {
  params?: GetTasksParams
}

export const useGetUpcomingTasksByUserIdQuery = ({
  params,
}: UseGetUpcomingTasksByUserIdQueryArgs = {}) => {
  const tasksUseCases = useTasksUseCases()

  const { data, isLoading, isError, refetch, isFetching } = useQuery({
    queryKey: [GET_UPCOMING_TASKS_BY_USER_ID_QUERY_KEY, params],
    queryFn: () => {
      return tasksUseCases.getUpcomingTasksByUserId({
        params,
      })
    },
  })
  return {
    userUpcomingTasksData: data,
    userUpcomingTasks: data?.tasks ? data.tasks : [],
    refetchUserUpcomingTasks: refetch,
    userUpcomingTasksIsLoading: isLoading,
    userUpcomingTasksIsError: isError,
    userAllUpcomingTasksIsFetching: isFetching,
  }
}
