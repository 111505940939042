import { SelectChangeEvent } from '@mui/material'
import { FC } from 'react'
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form'
import { noteFieldLabelDefaultSx } from 'src/features/notes/presentation'
import { mapPatientGoalToMultiSelectOption } from 'src/features/patients/adapters'
import { useGetPatientGoalsQuery } from 'src/features/patients/presentation'
import {
  MultiSelect,
  MultiSelectOption,
} from 'src/features/shared/presentation'

type PatientGoalsSelectProps = {
  id?: string
  fieldName?: FieldPath<FieldValues>
}

export const PatientGoalsSelect: FC<PatientGoalsSelectProps> = ({
  id = 'patient_goals_select',
  fieldName = 'patientGoals',
}) => {
  const { patientGoals } = useGetPatientGoalsQuery()
  const { setValue, control } = useFormContext()

  const handleChange = ({
    target,
  }: SelectChangeEvent<MultiSelectOption['value'][]>) => {
    const { value } = target
    let newValue = ''
    if (typeof value === 'string' && value) {
      newValue = value
    } else if (Array.isArray(value) && value.length > 0) {
      newValue = value.join(',')
    }
    setValue(fieldName, newValue)
  }

  const getSelectedOptions = (value: string) => {
    if (!value) {
      return []
    }
    return value.split(',')
  }

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => (
        <MultiSelect
          displayOptionType="checkbox"
          displayValueType="chip"
          labelComponent="formLabel"
          label={'Patient Goals'}
          id={id}
          options={patientGoals.map(mapPatientGoalToMultiSelectOption)}
          selectedOptions={getSelectedOptions(field.value)}
          onChange={handleChange}
          selectProps={{
            size: 'small',
          }}
          formLabelSx={noteFieldLabelDefaultSx}
        />
      )}
    />
  )
}
