import {
  UseMutateFunction,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import {
  UpdatePathwayReturns,
  UpdatePathwayArgs,
  UpdatePathwayError,
} from 'src/features/pathways/domain'
import { usePathwaysUseCases } from 'src/features/pathways/pathways.service-locator'
import { GET_PATIENT_QUERY_KEY_V2 } from 'src/features/patients/presentation'
import { GET_PATIENT_INTAKE_FLOWS_QUERY_KEY } from 'src/features/msk/presentation'

type UseUpdatePathwayProps = Omit<
  UseMutationOptions<
    UpdatePathwayReturns,
    UpdatePathwayError,
    UpdatePathwayArgs,
    unknown
  >,
  'mutationFn'
>
type UseUpdatePathway = (props?: UseUpdatePathwayProps) => {
  updatePathway: UseMutateFunction<
    UpdatePathwayReturns,
    UpdatePathwayError,
    UpdatePathwayArgs,
    unknown
  >
  updatePathwayIsLoading: boolean
  updatePathwayStatus: string
}

export const useUpdatePathway: UseUpdatePathway = (props = {}) => {
  const queryClient = useQueryClient()
  const pathwaysUseCases = usePathwaysUseCases()

  const { onSuccess } = props

  const { mutate, isLoading, isError, status } = useMutation<
    UpdatePathwayReturns,
    UpdatePathwayError,
    UpdatePathwayArgs,
    unknown
  >({
    mutationFn: (args: UpdatePathwayArgs) => {
      return pathwaysUseCases.updatePathway(args)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [GET_PATIENT_QUERY_KEY_V2, variables.payload.patientId],
      })
      queryClient.invalidateQueries({
        queryKey: [
          GET_PATIENT_INTAKE_FLOWS_QUERY_KEY,
          variables.payload.patientId,
        ],
      })
      onSuccess?.(data, variables, context)
    },
  })
  return {
    updatePathway: mutate,
    updatePathwayIsLoading: isLoading,
    updatePathwayIsError: isError,
    updatePathwayStatus: status,
  }
}
