import { Box, Dialog, DialogContent, IconButton } from '@mui/material'
import { FC, useEffect } from 'react'

import CloseIcon from '@mui/icons-material/Close'

import { ModalDialogTitle } from 'src/features/shared/presentation'
import { useSearchProvidersStore } from 'src/features/shared/infrastructure'
import {
  SearchProvidersForm,
  SearchProvidersFormProps,
} from 'src/features/providers/presentation'

type SearchProviderModalProps = {
  open: boolean
  searchProviderFormProps?: SearchProvidersFormProps
  onClose?: () => void
  onUnMount?: () => void
}

export const SearchProvidersModal: FC<SearchProviderModalProps> = ({
  open,
  onClose,
  onUnMount,
  searchProviderFormProps = {},
}) => {
  const { setSearchResults, setSelectedProvider } = useSearchProvidersStore()

  const handleClose = () => {
    setSearchResults([])
    setSelectedProvider(undefined)
    if (onClose) {
      onClose()
    }
  }

  useEffect(() => {
    return () => {
      if (onUnMount) {
        onUnMount()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        zIndex: 2,
        height: 'auto',
        '& .MuiPaper-root.MuiDialog-paper': {
          width: { xs: '1022px' },
          maxWidth: { xs: '1022px' },
        },
      }}
    >
      <ModalDialogTitle>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          Create Provider in Compass
          <IconButton onClick={handleClose} sx={{ color: 'white' }}>
            <CloseIcon />
          </IconButton>
        </Box>
      </ModalDialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '20px',
          px: '32px',
        }}
      >
        <SearchProvidersForm {...searchProviderFormProps} />
      </DialogContent>
    </Dialog>
  )
}
