import {
  Select,
  MenuItem,
  SxProps,
  Theme,
  SelectProps,
  FormControlProps,
  SelectChangeEvent,
} from '@mui/material'
import { FC } from 'react'
import {
  Controller,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form'

import { mapToMultiSelectOption } from 'src/features/shared/adapters'
import {
  FormFieldContainer,
  FormFieldContainerProps,
  MultiSelect,
} from 'src/features/shared/presentation'

export type ControlledSelectItem = { id: string; label: string; value: string }

export type ControlledSelectFieldProps = FormFieldContainerProps & {
  name: FieldPath<FieldValues>
  items?: ControlledSelectItem[]
  placeholderItem?: string
  selectProps?: SelectProps
  selectSx?: SxProps<Theme>
  multiple?: boolean
  valueMapper?: (field: ControllerRenderProps<FieldValues, string>) => string
  fieldOnChange?: (event: SelectChangeEvent<any>) => void
  extraOnChange?: (event: SelectChangeEvent<any>) => void
}

export const ControlledSelectField: FC<ControlledSelectFieldProps> = ({
  name,
  placeholderItem,
  required,
  labelComponent,
  label,
  helperText,
  selectProps = {},
  selectSx = {},
  multiple = false,
  items = [],
  formControlProps = {},
  valueMapper = undefined,
  fieldOnChange = undefined,
  extraOnChange = undefined,
  ...formFieldProps
}) => {
  const formMethods = useFormContext()
  const defaultFormControlProps: FormControlProps =
    labelComponent === 'inputLabel' ? { size: 'small' } : {}
  return (
    <Controller
      name={name}
      control={formMethods.control}
      render={({ field, fieldState }) =>
        multiple ? (
          <MultiSelect
            displayOptionType="checkbox"
            id={name + '-multi-select'}
            label={label}
            options={items.map(mapToMultiSelectOption)}
            selectedOptions={field.value}
            onChange={(event) => {
              if (fieldOnChange) {
                fieldOnChange(event)
              } else {
                field.onChange(event)
              }
              if (extraOnChange) {
                extraOnChange(event)
              }
            }}
            onBlur={field.onBlur}
            formControlProps={{
              ...defaultFormControlProps,
              ...formControlProps,
            }}
            {...formFieldProps}
          />
        ) : (
          <FormFieldContainer
            labelComponent={labelComponent}
            label={label}
            helperText={
              fieldState.error ? fieldState.error.message : helperText
            }
            error={fieldState.invalid}
            required={required}
            formControlProps={{
              ...defaultFormControlProps,
              ...formControlProps,
            }}
            {...formFieldProps}
          >
            <Select
              size="small"
              label={
                labelComponent === 'inputLabel' && label ? label : undefined
              }
              value={valueMapper ? valueMapper(field) : field.value}
              onBlur={field.onBlur}
              onChange={(event) => {
                if (fieldOnChange) {
                  fieldOnChange(event)
                } else {
                  field.onChange(event)
                }
                if (extraOnChange) {
                  extraOnChange(event)
                }
              }}
              required={required}
              error={fieldState.invalid}
              {...selectProps}
              sx={selectSx}
              displayEmpty
            >
              {placeholderItem ? (
                <MenuItem value={''}>{placeholderItem}</MenuItem>
              ) : null}
              {items.map((item) => (
                <MenuItem key={item.id} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormFieldContainer>
        )
      }
    />
  )
}
