import { format } from 'date-fns'

import {
  CreateDraftNoteArgs,
  CreateDraftNoteError,
  CreateDraftNoteReturns,
  CreatePatientCustomNoteArgs,
  CreatePatientCustomNoteError,
  CreatePatientCustomNoteReturns,
  CreatePatientIntakeNoteArgs,
  CreatePatientIntakeNoteError,
  CreatePatientIntakeNoteReturns,
  CreatePatientOrthoChartCheckNoteArgs,
  CreatePatientOrthoChartCheckNoteError,
  CreatePatientOrthoChartCheckNoteReturns,
  CreatePatientOrthoTouchpointNoteArgs,
  CreatePatientOrthoTouchpointNoteError,
  CreatePatientOrthoTouchpointNoteReturns,
  CreatePatientOutreachNoteArgs,
  CreatePatientOutreachNoteError,
  CreatePatientOutreachNoteReturns,
  CreatePatientPTTouchpointNoteArgs,
  CreatePatientPTTouchpointNoteError,
  CreatePatientPTTouchpointNoteReturns,
  CreatePatientPostOpTouchpointNoteArgs,
  CreatePatientPostOpTouchpointNoteError,
  CreatePatientPostOpTouchpointNoteReturns,
  CreatePatientPreOpTouchpointNoteArgs,
  CreatePatientPreOpTouchpointNoteError,
  CreatePatientPreOpTouchpointNoteReturns,
  CreatePatientReferralUploadNoteArgs,
  CreatePatientReferralUploadNoteError,
  CreatePatientReferralUploadNoteReturns,
  CreatePatientSelfCareTouchpointNoteArgs,
  CreatePatientSelfCareTouchpointNoteError,
  CreatePatientSelfCareTouchpointNoteReturns,
  DeleteDraftNoteArgs,
  DeleteDraftNoteError,
  DeleteDraftNoteReturns,
  DraftNote,
  DraftNotePainAndFunction,
  GetDraftNoteError,
  GetDraftNoteReturns,
  GetPatientNotesError,
  GetPatientNotesReturns,
  Note,
  NoteData,
  NoteType,
  UpdateDraftNoteArgs,
  UpdateDraftNoteError,
  UpdateDraftNoteReturns,
} from 'src/features/notes/domain'
import {
  CreateDraftNoteServiceErrorResponse,
  CreateDraftNoteServicePayload,
  CreateDraftNoteServiceResponse,
  CreatePatientCustomNoteServiceErrorResponse,
  CreatePatientCustomNoteServicePayload,
  CreatePatientCustomNoteServiceResponse,
  CreatePatientIntakeNoteServiceErrorResponse,
  CreatePatientIntakeNoteServicePayload,
  CreatePatientIntakeNoteServiceResponse,
  CreatePatientOrthoChartCheckNoteServiceErrorResponse,
  CreatePatientOrthoChartCheckNoteServicePayload,
  CreatePatientOrthoChartCheckNoteServiceResponse,
  CreatePatientOrthoTouchpointNoteServiceErrorResponse,
  CreatePatientOrthoTouchpointNoteServicePayload,
  CreatePatientOrthoTouchpointNoteServiceResponse,
  CreatePatientOutreachNoteServiceErrorResponse,
  CreatePatientOutreachNoteServicePayload,
  CreatePatientOutreachNoteServiceResponse,
  CreatePatientPTTouchpointNoteServiceErrorResponse,
  CreatePatientPTTouchpointNoteServicePayload,
  CreatePatientPTTouchpointNoteServiceResponse,
  CreatePatientPostOpTouchpointNoteServiceErrorResponse,
  CreatePatientPostOpTouchpointNoteServicePayload,
  CreatePatientPostOpTouchpointNoteServiceResponse,
  CreatePatientPreOpTouchpointNoteServiceErrorResponse,
  CreatePatientPreOpTouchpointNoteServicePayload,
  CreatePatientPreOpTouchpointNoteServiceResponse,
  CreatePatientReferralUploadNoteServiceErrorResponse,
  CreatePatientReferralUploadNoteServicePayload,
  CreatePatientReferralUploadNoteServiceResponse,
  CreatePatientSelfCareTouchpointNoteServiceErrorResponse,
  CreatePatientSelfCareTouchpointNoteServicePayload,
  CreatePatientSelfCareTouchpointNoteServiceResponse,
  DeleteDraftNoteServiceErrorResponse,
  DeleteDraftNoteServiceResponse,
  GetDraftNoteParams,
  GetDraftNoteServiceErrorResponse,
  GetDraftNoteServiceResponse,
  GetPatientNotesServiceErrorResponse,
  GetPatientNotesServiceResponse,
  SDraftNote,
  SNote,
  SNoteData,
  UpdateDraftNoteServiceErrorResponse,
  UpdateDraftNoteServicePayload,
  UpdateDraftNoteServiceResponse,
} from 'src/features/notes/infrastructure'
import { PatientV2, ReferralSource } from 'src/features/patients/domain'
import {
  CreatePatientCustomNoteFormV2Fields,
  CreatePatientIntakeNoteFormFields,
  OrthoChartCheckNoteFormFields,
  OrthoTouchpointNoteFormV2Fields,
  OutreachNoteFormFields,
  PTTouchpointNoteFormV2Fields,
  PostOpTouchpointNoteFormV2Fields,
  PreOpTouchpointNoteFormV2Fields,
  ReferralUploadNoteFormFields,
  SelfCareTouchpointNoteFormV2Fields,
} from 'src/features/notes/presentation/components/forms'
import { isPatientV2Engaged } from 'src/features/shared/utils'
import { PainAndFunctionFieldState } from 'src/features/notes/presentation'
import { SReferralSource } from 'src/features/patients/infrastructure'

const mapToReferralSource = (sReferralSource?: SReferralSource | null) => {
  if (!sReferralSource) {
    return null
  }

  let referralSource: ReferralSource

  if (sReferralSource === 'UM - Mail') {
    referralSource = 'Inbound - Phone'
  } else if (sReferralSource === 'Outbound Marketing') {
    referralSource = 'Inbound - Phone'
  } else if (sReferralSource === 'UM - Email Marketing') {
    referralSource = 'Analytic Target'
  } else {
    referralSource = sReferralSource
  }

  return referralSource
}

// -------------------
// GET PATIENT NOTES /
// -----------------

type MapToNote = (note: SNote) => Note

export const mapToNote: MapToNote = (note) => {
  const createdAt = format(
    new Date(note.createdAt),
    "MM/dd/yy 'at' p"
  ).toLowerCase()

  const header = `Added by ${note.authorName} on ${createdAt}`

  const referralSourceChangedTo = mapToReferralSource(
    note.referralSourceChangedTo
  )

  return {
    ...note,
    data: note.data,
    referralSourceChangedTo,
    createdAt,
    header,
  }
}

type MapToGetPatientNotesReturns = (
  response: GetPatientNotesServiceResponse
) => GetPatientNotesReturns

export const mapToGetPatientNotesReturns: MapToGetPatientNotesReturns = (
  response
) => {
  const sortedNotes = response.notes.sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  })

  return {
    notes: sortedNotes.map(mapToNote),
  }
}

type MapToGetPatientNotesError = (
  response: GetPatientNotesServiceErrorResponse
) => GetPatientNotesError

export const mapToGetPatientNotesError: MapToGetPatientNotesError = (
  response
) => {
  return {
    message: response.message,
  }
}

// ---------------------------
// CREATE PATIENT CUSTOM NOTE
// --------------------------

type MapToCreatePatientCustomNoteV2Args = (
  patient: PatientV2,
  formValues: CreatePatientCustomNoteFormV2Fields,
  draftNoteId?: string
) => CreatePatientCustomNoteArgs

export const mapToCreatePatientCustomNoteV2Args: MapToCreatePatientCustomNoteV2Args =
  (patient, formValues, draftNoteId) => {
    const args: CreatePatientCustomNoteArgs = {
      type: 'Custom',
      note: formValues.note,
      patientId: patient.patientId,
      data: {},
    }

    if (
      formValues.referralSource &&
      formValues.referralSource !== patient.referralSource
    ) {
      args['referralSourceChangedTo'] = formValues.referralSource
    }

    if (formValues.communication) {
      args['communication'] = formValues.communication
    }

    if (formValues.reasonForRefusal) {
      args.data['reasonForRefusal'] = formValues.reasonForRefusal
    }

    if (draftNoteId) {
      args.draftNoteId = draftNoteId
    }

    return args
  }

type MapToCreatePatientCustomNoteServicePayload = (
  args: CreatePatientCustomNoteArgs
) => CreatePatientCustomNoteServicePayload

export const mapToCreatePatientCustomNoteServicePayload: MapToCreatePatientCustomNoteServicePayload =
  (args) => {
    return {
      ...args,
    }
  }

type MapToCreatePatientCustomNoteReturns = (
  response: CreatePatientCustomNoteServiceResponse
) => CreatePatientCustomNoteReturns

export const mapToCreatePatientCustomNoteReturns: MapToCreatePatientCustomNoteReturns =
  (response) => {
    return {
      ...response,
    }
  }

type MapToCreatePatientCustomNoteError = (
  response: CreatePatientCustomNoteServiceErrorResponse
) => CreatePatientCustomNoteError

export const mapToCreatePatientCustomNoteError: MapToCreatePatientCustomNoteError =
  (response) => {
    return {
      ...response,
    }
  }

// ---------------------------------------
// CREATE PATIENT INTAKE ASSESSMENT NOTE
// -------------------------------------

type MapToCreatePatientIntakeNoteArgs = (
  patient: PatientV2,
  formValues: CreatePatientIntakeNoteFormFields,
  draftNoteId?: string
) => CreatePatientIntakeNoteArgs

export const mapToCreatePatientIntakeNoteArgs: MapToCreatePatientIntakeNoteArgs =
  (patient, formValues, draftNoteId) => {
    const args: CreatePatientIntakeNoteArgs = {
      type: 'Intake',
      note: formValues.note,
      sdm: formValues.sdm,
      patientId: patient.patientId,
      communication: formValues.communication,
      data: {},
    }

    if (
      formValues.referralSource &&
      formValues.referralSource !== patient.referralSource
    ) {
      args['referralSourceChangedTo'] = formValues.referralSource
    }

    if (formValues.outsideSource) {
      args.data['outsideSource'] = formValues.outsideSource
    }

    if (formValues.spokeWith) {
      args['spokeWith'] = formValues.spokeWith
    }

    if (formValues.scheduled) {
      args.data['scheduled'] = formValues.scheduled
    }

    if (formValues.providerId) {
      args.data['providerId'] = formValues.providerId
    }

    if (formValues.providerGroupId) {
      args.data['providerGroupId'] = formValues.providerGroupId
    }

    if (formValues.providerGroupLocation) {
      args.data['providerGroupLocation'] = formValues.providerGroupLocation
    }

    if (formValues.preferredProvider) {
      args.data['preferredProvider'] = formValues.preferredProvider
    }

    if (formValues.sdmText) {
      args.data['sdmText'] = formValues.sdmText
    }

    if (formValues.sdmText) {
      args.data['sdmText'] = formValues.sdmText
    }

    if (formValues.sdmPatientUnderstandsCondition) {
      args.data['sdmPatientUnderstandsCondition'] =
        formValues.sdmPatientUnderstandsCondition
    }
    if (formValues.sdmClinicalBelievesPatientUnderstandsCondition) {
      args.data['sdmClinicalBelievesPatientUnderstandsCondition'] =
        formValues.sdmClinicalBelievesPatientUnderstandsCondition
    }

    if (formValues.patientGoals) {
      args.data['patientGoals'] = formValues.patientGoals
    }

    if (formValues.reasonForRefusal) {
      args.data['reasonForRefusal'] = formValues.reasonForRefusal
    }
    if (draftNoteId) {
      args.draftNoteId = draftNoteId
    }

    return args
  }

type MapToCreatePatientIntakeNoteServicePayload = (
  args: CreatePatientIntakeNoteArgs
) => CreatePatientIntakeNoteServicePayload

export const mapToCreatePatientIntakeNoteServicePayload: MapToCreatePatientIntakeNoteServicePayload =
  (args) => {
    return {
      ...args,
    }
  }

type MapToCreatePatientIntakeNoteReturns = (
  response: CreatePatientIntakeNoteServiceResponse
) => CreatePatientIntakeNoteReturns

export const mapToCreatePatientIntakeNoteReturns: MapToCreatePatientIntakeNoteReturns =
  (response) => {
    return {
      ...response,
    }
  }

type MapToCreatePatientIntakeNoteError = (
  response: CreatePatientIntakeNoteServiceErrorResponse
) => CreatePatientIntakeNoteError

export const mapToCreatePatientIntakeNoteError: MapToCreatePatientIntakeNoteError =
  (response) => {
    return {
      ...response,
    }
  }

// ---------------------------------------
// CREATE PATIENT ORTHO CHART CHECK ASSESSMENT NOTE
// -------------------------------------

type MapToCreatePatientOrthoChartCheckNoteArgs = (
  patient: PatientV2,
  formValues: OrthoChartCheckNoteFormFields,
  draftNoteId?: string
) => CreatePatientOrthoChartCheckNoteArgs

export const mapToCreatePatientOrthoChartCheckNoteArgs: MapToCreatePatientOrthoChartCheckNoteArgs =
  (patient, formValues, draftNoteId) => {
    const args: CreatePatientOrthoChartCheckNoteArgs = {
      type: 'Ortho Chart Check',
      note: formValues.note,
      patientId: patient.patientId,
      data: {},
    }

    // Additional Questions

    if (formValues.completedAppointment) {
      args.data['completedAppointment'] = formValues.completedAppointment
    }

    if (formValues.reasonForMissingAppointment) {
      args.data['reasonForMissingAppointment'] =
        formValues.reasonForMissingAppointment
    }
    if (formValues.detailsOfMissedAppointment) {
      args.data['detailsOfMissedAppointment'] =
        formValues.detailsOfMissedAppointment
    }
    if (formValues.appointmentRescheduled) {
      args.data['appointmentRescheduled'] = formValues.appointmentRescheduled
    }

    if (formValues.outcomeNote) {
      args.data['outcomeNote'] = formValues.outcomeNote
    }
    if (formValues.outcomeOfVisit) {
      args.data['outcomeOfVisit'] = formValues.outcomeOfVisit
    }
    if (formValues.typeOfInjection) {
      args.data['typeOfInjection'] = formValues.typeOfInjection
    }

    if (formValues.typeOfInjectionText) {
      args.data['typeOfInjectionText'] = formValues.typeOfInjectionText
    }

    if (formValues.appointmentDate) {
      args.data['appointmentDate'] = formValues.appointmentDate
    }

    if (formValues.surgeryDate) {
      args.data['surgeryDate'] = formValues.surgeryDate
    }
    if (draftNoteId) {
      args.draftNoteId = draftNoteId
    }

    return args
  }

type MapToCreatePatientOrthoChartCheckNoteServicePayload = (
  args: CreatePatientOrthoChartCheckNoteArgs
) => CreatePatientOrthoChartCheckNoteServicePayload

export const mapToCreatePatientOrthoChartCheckNoteServicePayload: MapToCreatePatientOrthoChartCheckNoteServicePayload =
  (args) => {
    return {
      ...args,
    }
  }

type MapToCreatePatientOrthoChartCheckNoteReturns = (
  response: CreatePatientOrthoChartCheckNoteServiceResponse
) => CreatePatientOrthoChartCheckNoteReturns

export const mapToCreatePatientOrthoChartCheckNoteReturns: MapToCreatePatientOrthoChartCheckNoteReturns =
  (response) => {
    return {
      ...response,
    }
  }

type MapToCreatePatientOrthoChartCheckNoteError = (
  response: CreatePatientOrthoChartCheckNoteServiceErrorResponse
) => CreatePatientOrthoChartCheckNoteError

export const mapToCreatePatientOrthoChartCheckNoteError: MapToCreatePatientOrthoChartCheckNoteError =
  (response) => {
    return {
      ...response,
    }
  }

// ---------------------------------------
// CREATE PATIENT ORTHO TOUCHPOINT ASSESSMENT NOTE
// -------------------------------------

type MapToCreatePatientOrthoTouchpointNoteV2Args = (
  patient: PatientV2,
  formValues: OrthoTouchpointNoteFormV2Fields,
  draftNoteId?: string
) => CreatePatientOrthoTouchpointNoteArgs

export const mapToCreatePatientOrthoTouchpointNoteV2Args: MapToCreatePatientOrthoTouchpointNoteV2Args =
  (patient, formValues, draftNoteId) => {
    const args: CreatePatientOrthoTouchpointNoteArgs = {
      type: 'Ortho Touchpoint',
      note: formValues.note,
      patientId: patient.patientId,
      sdm: formValues.sdm,
      communication: formValues.communication,
      data: {},
    }

    if (formValues.spokeWith) {
      args['spokeWith'] = formValues.spokeWith
    }

    // Additional Questions

    if (formValues.completedAppointment) {
      args.data['completedAppointment'] = formValues.completedAppointment
    }

    if (formValues.reasonForMissingAppointment) {
      args.data['reasonForMissingAppointment'] =
        formValues.reasonForMissingAppointment
    }
    if (formValues.detailsOfMissedAppointment) {
      args.data['detailsOfMissedAppointment'] =
        formValues.detailsOfMissedAppointment
    }
    if (formValues.appointmentRescheduled) {
      args.data['appointmentRescheduled'] = formValues.appointmentRescheduled
    }

    if (formValues.typeOfInjection) {
      args.data['typeOfInjection'] = formValues.typeOfInjection
    }

    if (formValues.outcomeNote) {
      args.data['outcomeNote'] = formValues.outcomeNote
    }
    if (formValues.outcomeOfVisit) {
      args.data['outcomeOfVisit'] = formValues.outcomeOfVisit
    }

    if (formValues.sdmText) {
      args.data['sdmText'] = formValues.sdmText
    }
    if (formValues.patientGoals) {
      args.data['patientGoals'] = formValues.patientGoals
    }

    if (
      formValues.injectionAtCurrentVisit &&
      formValues.satisfiedWithInjectionResults
    ) {
      args.data['injectionAtCurrentVisit'] = formValues.injectionAtCurrentVisit
      args.data['satisfiedWithInjectionResults'] =
        formValues.satisfiedWithInjectionResults
    }

    if (formValues.sdmPatientUnderstandsCondition) {
      args.data['sdmPatientUnderstandsCondition'] =
        formValues.sdmPatientUnderstandsCondition
    }

    if (formValues.sdmClinicalBelievesPatientUnderstandsCondition) {
      args.data['sdmClinicalBelievesPatientUnderstandsCondition'] =
        formValues.sdmClinicalBelievesPatientUnderstandsCondition
    }

    if (formValues.agreeYouUnderstandCondition) {
      args.data['agreeYouUnderstandCondition'] =
        formValues.agreeYouUnderstandCondition
    }

    if (formValues.agreeYouUnderstandTreatmentOptions) {
      args.data['agreeYouUnderstandTreatmentOptions'] =
        formValues.agreeYouUnderstandTreatmentOptions
    }
    if (draftNoteId) {
      args.draftNoteId = draftNoteId
    }

    return args
  }

type MapToCreatePatientOrthoTouchpointNoteServicePayload = (
  args: CreatePatientOrthoTouchpointNoteArgs
) => CreatePatientOrthoTouchpointNoteServicePayload

export const mapToCreatePatientOrthoTouchpointNoteServicePayload: MapToCreatePatientOrthoTouchpointNoteServicePayload =
  (args) => {
    const { ...restArgs } = args
    return {
      ...restArgs,
    }
  }

type MapToCreatePatientOrthoTouchpointNoteReturns = (
  response: CreatePatientOrthoTouchpointNoteServiceResponse
) => CreatePatientOrthoTouchpointNoteReturns

export const mapToCreatePatientOrthoTouchpointNoteReturns: MapToCreatePatientOrthoTouchpointNoteReturns =
  (response) => {
    return {
      ...response,
    }
  }

type MapToCreatePatientOrthoTouchpointNoteError = (
  response: CreatePatientOrthoTouchpointNoteServiceErrorResponse
) => CreatePatientOrthoTouchpointNoteError

export const mapToCreatePatientOrthoTouchpointNoteError: MapToCreatePatientOrthoTouchpointNoteError =
  (response) => {
    return {
      ...response,
    }
  }

// ---------------------------------------
// CREATE PATIENT OUTREACH ASSESSMENT NOTE
// -------------------------------------

type MapToCreatePatientOutreachNoteArgs = (
  patient: PatientV2,
  formValues: OutreachNoteFormFields,
  draftNoteId?: string
) => CreatePatientOutreachNoteArgs

export const mapToCreatePatientOutreachNoteArgs: MapToCreatePatientOutreachNoteArgs =
  (patient, formValues, draftNoteId) => {
    const args: CreatePatientOutreachNoteArgs = {
      type: 'Outreach',
      note: formValues.note,
      patientId: patient.patientId,
      communication: formValues.communication,
      data: {},
    }

    if (
      formValues.referralSource &&
      formValues.referralSource !== patient.referralSource
    ) {
      args['referralSourceChangedTo'] = formValues.referralSource
    }

    if (formValues.spokeWith) {
      args['spokeWith'] = formValues.spokeWith
    }
    if (formValues.moreInformationRequested?.length) {
      args.data['moreInformationRequested'] =
        formValues.moreInformationRequested
    }
    if (formValues.reasonForRefusal) {
      args.data['reasonForRefusal'] = formValues.reasonForRefusal
    }
    if (draftNoteId) {
      args.draftNoteId = draftNoteId
    }

    return args
  }

type MapToCreatePatientOutreachNoteServicePayload = (
  args: CreatePatientOutreachNoteArgs
) => CreatePatientOutreachNoteServicePayload

export const mapToCreatePatientOutreachNoteServicePayload: MapToCreatePatientOutreachNoteServicePayload =
  (args) => {
    return {
      ...args,
    }
  }

type MapToCreatePatientOutreachNoteReturns = (
  response: CreatePatientOutreachNoteServiceResponse
) => CreatePatientOutreachNoteReturns

export const mapToCreatePatientOutreachNoteReturns: MapToCreatePatientOutreachNoteReturns =
  (response) => {
    return {
      ...response,
    }
  }

type MapToCreatePatientOutreachNoteError = (
  response: CreatePatientOutreachNoteServiceErrorResponse
) => CreatePatientOutreachNoteError

export const mapToCreatePatientOutreachNoteError: MapToCreatePatientOutreachNoteError =
  (response) => {
    return {
      ...response,
    }
  }

// ---------------------------------------
// CREATE PATIENT SELFCARE TOUCHPOINT ASSESSMENT NOTE
// -------------------------------------

type MapToCreatePatientSelfCareTouchpointNoteV2Args = (
  patient: PatientV2,
  formValues: SelfCareTouchpointNoteFormV2Fields,
  draftNoteId?: string
) => CreatePatientSelfCareTouchpointNoteArgs

export const mapToCreatePatientSelfCareTouchpointNoteV2Args: MapToCreatePatientSelfCareTouchpointNoteV2Args =
  (patient, formValues, draftNoteId) => {
    const args: CreatePatientSelfCareTouchpointNoteArgs = {
      type: 'Self-Care Touchpoint',
      note: formValues.note,
      patientId: patient.patientId,
      sdm: formValues.sdm,
      communication: formValues.communication,
      data: {},
    }

    if (formValues.spokeWith) {
      args['spokeWith'] = formValues.spokeWith
    }

    // Additional Questions

    if (formValues.painLevelDecreased) {
      args.data['painLevelDecreased'] = formValues.painLevelDecreased
    }

    if (formValues.newSymptoms) {
      args.data['newSymptoms'] = formValues.newSymptoms
    }
    if (formValues.additionalQuestionsOrConcerns) {
      args.data['additionalQuestionsOrConcerns'] =
        formValues.additionalQuestionsOrConcerns
    }
    if (formValues.sdmText) {
      args.data['sdmText'] = formValues.sdmText
    }
    if (formValues.patientGoals) {
      args.data['patientGoals'] = formValues.patientGoals
    }

    if (formValues.sdmPatientUnderstandsCondition) {
      args.data['sdmPatientUnderstandsCondition'] =
        formValues.sdmPatientUnderstandsCondition
    }
    if (formValues.sdmClinicalBelievesPatientUnderstandsCondition) {
      args.data['sdmClinicalBelievesPatientUnderstandsCondition'] =
        formValues.sdmClinicalBelievesPatientUnderstandsCondition
    }

    if (formValues.agreeYouUnderstandCondition) {
      args.data['agreeYouUnderstandCondition'] =
        formValues.agreeYouUnderstandCondition
    }

    if (formValues.agreeYouUnderstandTreatmentOptions) {
      args.data['agreeYouUnderstandTreatmentOptions'] =
        formValues.agreeYouUnderstandTreatmentOptions
    }

    if (draftNoteId) {
      args.draftNoteId = draftNoteId
    }

    return args
  }

type MapToCreatePatientSelfCareTouchpointNoteServicePayload = (
  args: CreatePatientSelfCareTouchpointNoteArgs
) => CreatePatientSelfCareTouchpointNoteServicePayload

export const mapToCreatePatientSelfCareTouchpointNoteServicePayload: MapToCreatePatientSelfCareTouchpointNoteServicePayload =
  (args) => {
    return {
      ...args,
    }
  }

type MapToCreatePatientSelfCareTouchpointNoteReturns = (
  response: CreatePatientSelfCareTouchpointNoteServiceResponse
) => CreatePatientSelfCareTouchpointNoteReturns

export const mapToCreatePatientSelfCareTouchpointNoteReturns: MapToCreatePatientSelfCareTouchpointNoteReturns =
  (response) => {
    return {
      ...response,
    }
  }

type MapToCreatePatientSelfCareTouchpointNoteError = (
  response: CreatePatientSelfCareTouchpointNoteServiceErrorResponse
) => CreatePatientSelfCareTouchpointNoteError

export const mapToCreatePatientSelfCareTouchpointNoteError: MapToCreatePatientSelfCareTouchpointNoteError =
  (response) => {
    return {
      ...response,
    }
  }

// ---------------------------------------
// CREATE PATIENT REFERRAL UPLOAD ASSESSMENT NOTE
// -------------------------------------

type MapToCreatePatientReferralUploadNoteArgs = (
  patient: PatientV2,
  formValues: ReferralUploadNoteFormFields,
  draftNoteId?: string
) => CreatePatientReferralUploadNoteArgs

export const mapToCreatePatientReferralUploadNoteArgs: MapToCreatePatientReferralUploadNoteArgs =
  (patient, formValues, draftNoteId) => {
    const args: CreatePatientReferralUploadNoteArgs = {
      type: 'Referral Upload',
      note: formValues.note,
      patientId: patient.patientId,
      data: {},
    }

    if (
      formValues.referralSource &&
      formValues.referralSource !== patient.referralSource
    ) {
      args['referralSourceChangedTo'] = formValues.referralSource
    }
    if (formValues.spokeWith) {
      args['spokeWith'] = formValues.spokeWith
    }
    if (formValues.providerId) {
      args.data['providerId'] = formValues.providerId
    }
    if (formValues.providerGroupId) {
      args.data['providerGroupId'] = formValues.providerGroupId
    }
    if (formValues.referralDate) {
      args.data['referralDate'] = formValues.referralDate
    }
    if (formValues.referralType) {
      args.data['referralType'] = formValues.referralType
    }
    if (formValues.referredFor) {
      args.data['referredFor'] = formValues.referredFor
    }
    if (formValues.bodyRegionToNote) {
      args.data['bodyRegionToNote'] = formValues.bodyRegionToNote
    }
    if (formValues.medicalHistory) {
      args.data['medicalHistory'] = formValues.medicalHistory
    }
    if (draftNoteId) {
      args.draftNoteId = draftNoteId
    }

    return args
  }

type MapToCreatePatientReferralUploadNoteServicePayload = (
  args: CreatePatientReferralUploadNoteArgs
) => CreatePatientReferralUploadNoteServicePayload

export const mapToCreatePatientReferralUploadNoteServicePayload: MapToCreatePatientReferralUploadNoteServicePayload =
  (args) => {
    return {
      ...args,
    }
  }

type MapToCreatePatientReferralUploadNoteReturns = (
  response: CreatePatientReferralUploadNoteServiceResponse
) => CreatePatientReferralUploadNoteReturns

export const mapToCreatePatientReferralUploadNoteReturns: MapToCreatePatientReferralUploadNoteReturns =
  (response) => {
    return {
      ...response,
    }
  }

type MapToCreatePatientReferralUploadNoteError = (
  response: CreatePatientReferralUploadNoteServiceErrorResponse
) => CreatePatientReferralUploadNoteError

export const mapToCreatePatientReferralUploadNoteError: MapToCreatePatientReferralUploadNoteError =
  (response) => {
    return {
      ...response,
    }
  }

// ---------------------------------------
// CREATE PATIENT PT TOUCHPOINT ASSESSMENT NOTE
// -------------------------------------

type MapToCreatePatientPTTouchpointNoteV2Args = (
  patient: PatientV2,
  formValues: PTTouchpointNoteFormV2Fields,
  draftNoteId?: string
) => CreatePatientPTTouchpointNoteArgs

export const mapToCreatePatientPTTouchpointNoteV2Args: MapToCreatePatientPTTouchpointNoteV2Args =
  (patient, formValues, draftNoteId) => {
    const args: CreatePatientPTTouchpointNoteArgs = {
      type: 'PT Touchpoint',
      note: formValues.note,
      patientId: patient.patientId,
      sdm: formValues.sdm,
      communication: formValues.communication,
      data: {},
    }

    if (formValues.spokeWith) {
      args['spokeWith'] = formValues.spokeWith
    }

    // Additional Questions

    if (formValues.completedAppointment) {
      args.data['completedAppointment'] = formValues.completedAppointment
    }
    if (formValues.reasonForMissingAppointment) {
      args.data['reasonForMissingAppointment'] =
        formValues.reasonForMissingAppointment
    }
    if (formValues.detailsOfMissedAppointment) {
      args.data['detailsOfMissedAppointment'] =
        formValues.detailsOfMissedAppointment
    }
    if (formValues.appointmentRescheduled) {
      args.data['appointmentRescheduled'] = formValues.appointmentRescheduled
    }
    if (formValues.completingPhysicalTherapyExercises) {
      args.data['completingPhysicalTherapyExercises'] =
        formValues.completingPhysicalTherapyExercises
    }
    if (formValues.reasonForNotCompletingPTExercises) {
      args.data['reasonForNotCompletingPTExercises'] =
        formValues.reasonForNotCompletingPTExercises
    }
    if (formValues.painDuringPTExercise) {
      args.data['painDuringPTExercise'] = formValues.painDuringPTExercise
    }
    if (formValues.additionalQuestionsOrConcerns) {
      args.data['additionalQuestionsOrConcerns'] =
        formValues.additionalQuestionsOrConcerns
    }
    if (formValues.sdmText) {
      args.data['sdmText'] = formValues.sdmText
    }
    if (formValues.patientGoals) {
      args.data['patientGoals'] = formValues.patientGoals
    }

    if (formValues.sdmPatientUnderstandsCondition) {
      args.data['sdmPatientUnderstandsCondition'] =
        formValues.sdmPatientUnderstandsCondition
    }
    if (formValues.sdmClinicalBelievesPatientUnderstandsCondition) {
      args.data['sdmClinicalBelievesPatientUnderstandsCondition'] =
        formValues.sdmClinicalBelievesPatientUnderstandsCondition
    }

    if (formValues.agreeYouUnderstandCondition) {
      args.data['agreeYouUnderstandCondition'] =
        formValues.agreeYouUnderstandCondition
    }

    if (formValues.agreeYouUnderstandTreatmentOptions) {
      args.data['agreeYouUnderstandTreatmentOptions'] =
        formValues.agreeYouUnderstandTreatmentOptions
    }
    if (draftNoteId) {
      args.draftNoteId = draftNoteId
    }

    return args
  }

type MapToCreatePatientPTTouchpointNoteServicePayload = (
  args: CreatePatientPTTouchpointNoteArgs
) => CreatePatientPTTouchpointNoteServicePayload

export const mapToCreatePatientPTTouchpointNoteServicePayload: MapToCreatePatientPTTouchpointNoteServicePayload =
  (args) => {
    return {
      ...args,
    }
  }

type MapToCreatePatientPTTouchpointNoteReturns = (
  response: CreatePatientPTTouchpointNoteServiceResponse
) => CreatePatientPTTouchpointNoteReturns

export const mapToCreatePatientPTTouchpointNoteReturns: MapToCreatePatientPTTouchpointNoteReturns =
  (response) => {
    return {
      ...response,
    }
  }

type MapToCreatePatientPTTouchpointNoteError = (
  response: CreatePatientPTTouchpointNoteServiceErrorResponse
) => CreatePatientPTTouchpointNoteError

export const mapToCreatePatientPTTouchpointNoteError: MapToCreatePatientPTTouchpointNoteError =
  (response) => {
    return {
      ...response,
    }
  }

// ---------------------------------------
// CREATE PATIENT POST OP TOUCHPOINT ASSESSMENT NOTE
// -------------------------------------

type MapToCreatePatientPostOpTouchpointNoteV2Args = (
  patient: PatientV2,
  formValues: PostOpTouchpointNoteFormV2Fields,
  draftNoteId?: string
) => CreatePatientPostOpTouchpointNoteArgs

export const mapToCreatePatientPostOpTouchpointNoteV2Args: MapToCreatePatientPostOpTouchpointNoteV2Args =
  (patient, formValues, draftNoteId) => {
    const args: CreatePatientPostOpTouchpointNoteArgs = {
      type: 'Post-Op Touchpoint',
      note: formValues.note,
      patientId: patient.patientId,
      sdm: formValues.sdm,
      communication: formValues.communication,
      data: {},
    }

    if (formValues.spokeWith) {
      args['spokeWith'] = formValues.spokeWith
    }

    // Additional Questions

    if (formValues.isPainControlled) {
      args.data['isPainControlled'] = formValues.isPainControlled
    }

    if (formValues.accessToMedicineToControlPain) {
      args.data['accessToMedicineToControlPain'] =
        formValues.accessToMedicineToControlPain
    }

    if (formValues.participatingInPT) {
      args.data['participatingInPT'] = formValues.participatingInPT
    }

    if (formValues.physicalTherapyLocation) {
      args.data['physicalTherapyLocation'] = formValues.physicalTherapyLocation
    }
    if (formValues.additionalQuestionsOrConcerns) {
      args.data['additionalQuestionsOrConcerns'] =
        formValues.additionalQuestionsOrConcerns
    }
    if (formValues.sdmText) {
      args.data['sdmText'] = formValues.sdmText
    }
    if (formValues.patientGoals) {
      args.data['patientGoals'] = formValues.patientGoals
    }
    if (formValues.procedure) {
      args.data['procedure'] = formValues.procedure
    }
    if (formValues.surgerySite) {
      args.data['surgerySite'] = formValues.surgerySite
    }
    if (formValues.dischargePosition) {
      args.data['dischargePosition'] = formValues.dischargePosition
    }
    if (formValues.sdmPatientUnderstandsCondition) {
      args.data['sdmPatientUnderstandsCondition'] =
        formValues.sdmPatientUnderstandsCondition
    }
    if (formValues.sdmClinicalBelievesPatientUnderstandsCondition) {
      args.data['sdmClinicalBelievesPatientUnderstandsCondition'] =
        formValues.sdmClinicalBelievesPatientUnderstandsCondition
    }

    if (formValues.agreeYouUnderstandCondition) {
      args.data['agreeYouUnderstandCondition'] =
        formValues.agreeYouUnderstandCondition
    }

    if (formValues.agreeYouUnderstandTreatmentOptions) {
      args.data['agreeYouUnderstandTreatmentOptions'] =
        formValues.agreeYouUnderstandTreatmentOptions
    }
    if (draftNoteId) {
      args.draftNoteId = draftNoteId
    }

    return args
  }

type MapToCreatePatientPostOpTouchpointNoteServicePayload = (
  args: CreatePatientPostOpTouchpointNoteArgs
) => CreatePatientPostOpTouchpointNoteServicePayload

export const mapToCreatePatientPostOpTouchpointNoteServicePayload: MapToCreatePatientPostOpTouchpointNoteServicePayload =
  (args) => {
    return {
      ...args,
    }
  }

type MapToCreatePatientPostOpTouchpointNoteReturns = (
  response: CreatePatientPostOpTouchpointNoteServiceResponse
) => CreatePatientPostOpTouchpointNoteReturns

export const mapToCreatePatientPostOpTouchpointNoteReturns: MapToCreatePatientPostOpTouchpointNoteReturns =
  (response) => {
    return {
      ...response,
    }
  }

type MapToCreatePatientPostOpTouchpointNoteError = (
  response: CreatePatientPostOpTouchpointNoteServiceErrorResponse
) => CreatePatientPostOpTouchpointNoteError

export const mapToCreatePatientPostOpTouchpointNoteError: MapToCreatePatientPostOpTouchpointNoteError =
  (response) => {
    return {
      ...response,
    }
  }

// ---------------------------------------
// CREATE PATIENT PRE OP TOUCHPOINT ASSESSMENT NOTE
// -------------------------------------

type MapToCreatePatientPreOpTouchpointNoteV2Args = (
  patient: PatientV2,
  formValues: PreOpTouchpointNoteFormV2Fields,
  draftNoteId?: string
) => CreatePatientPreOpTouchpointNoteArgs

export const mapToCreatePatientPreOpTouchpointNoteV2Args: MapToCreatePatientPreOpTouchpointNoteV2Args =
  (patient, formValues, draftNoteId) => {
    const args: CreatePatientPreOpTouchpointNoteArgs = {
      type: 'Pre-Op Touchpoint',
      note: formValues.note,
      patientId: patient.patientId,
      sdm: formValues.sdm,
      communication: formValues.communication,
      data: {},
    }

    if (formValues.spokeWith) {
      args['spokeWith'] = formValues.spokeWith
    }

    // Additional questions
    if (formValues.scheduledFollowUp) {
      args.data['scheduledFollowUp'] = formValues.scheduledFollowUp
    }
    if (formValues.additionalQuestionsOrConcerns) {
      args.data['additionalQuestionsOrConcerns'] =
        formValues.additionalQuestionsOrConcerns
    }
    if (formValues.questionsAboutSurgery) {
      args.data['questionsAboutSurgery'] = formValues.questionsAboutSurgery
    }
    if (formValues.procedure) {
      args.data['procedure'] = formValues.procedure
    }
    if (formValues.sdmText) {
      args.data['sdmText'] = formValues.sdmText
    }
    if (formValues.patientGoals) {
      args.data['patientGoals'] = formValues.patientGoals
    }

    if (formValues.sdmPatientUnderstandsCondition) {
      args.data['sdmPatientUnderstandsCondition'] =
        formValues.sdmPatientUnderstandsCondition
    }
    if (formValues.sdmClinicalBelievesPatientUnderstandsCondition) {
      args.data['sdmClinicalBelievesPatientUnderstandsCondition'] =
        formValues.sdmClinicalBelievesPatientUnderstandsCondition
    }

    if (formValues.agreeYouUnderstandCondition) {
      args.data['agreeYouUnderstandCondition'] =
        formValues.agreeYouUnderstandCondition
    }

    if (formValues.agreeYouUnderstandTreatmentOptions) {
      args.data['agreeYouUnderstandTreatmentOptions'] =
        formValues.agreeYouUnderstandTreatmentOptions
    }
    if (draftNoteId) {
      args.draftNoteId = draftNoteId
    }

    return args
  }

type MapToCreatePatientPreOpTouchpointNoteServicePayload = (
  args: CreatePatientPreOpTouchpointNoteArgs
) => CreatePatientPreOpTouchpointNoteServicePayload

export const mapToCreatePatientPreOpTouchpointNoteServicePayload: MapToCreatePatientPreOpTouchpointNoteServicePayload =
  (args) => {
    return {
      ...args,
    }
  }

type MapToCreatePatientPreOpTouchpointNoteReturns = (
  response: CreatePatientPreOpTouchpointNoteServiceResponse
) => CreatePatientPreOpTouchpointNoteReturns

export const mapToCreatePatientPreOpTouchpointNoteReturns: MapToCreatePatientPreOpTouchpointNoteReturns =
  (response) => {
    return {
      ...response,
    }
  }

type MapToCreatePatientPreOpTouchpointNoteError = (
  response: CreatePatientPreOpTouchpointNoteServiceErrorResponse
) => CreatePatientPreOpTouchpointNoteError

export const mapToCreatePatientPreOpTouchpointNoteError: MapToCreatePatientPreOpTouchpointNoteError =
  (response) => {
    return {
      ...response,
    }
  }

// ---------------------------------------
// CREATE DRAFT NOTE
// -------------------------------------

const mapToSDraftNoteData = (noteData?: NoteData) => {
  if (!noteData) {
    return undefined
  }
  if (Object.keys(noteData).length === 0) {
    return undefined
  }

  const { typeOfInjection, typeOfInjectionText, ...restNoteData } = noteData

  const sNoteData: SNoteData = restNoteData

  if (typeOfInjection) {
    sNoteData['typeOfInjection'] = typeOfInjection
    if (typeOfInjection === 'Other' && typeOfInjectionText) {
      sNoteData['typeOfInjectionText'] = typeOfInjectionText
    }
  }

  return sNoteData
}

const mapPainFunctionToArray = (
  painFunctionMap?: PainAndFunctionFieldState['values']
): DraftNotePainAndFunction[] | undefined => {
  if (!painFunctionMap) return undefined
  return Object.entries(painFunctionMap).map(([intakeId, data]) => ({
    intakeId,
    pain: data?.painLevel,
    function: data?.functionLevel,
  }))
}

type DraftNoteValues = {
  note?: string
  sdm?: boolean
  communication?: string
  spokeWith?: string
  moreInformationRequested?: string[]
  status?: string
  sdmText?: string
  procedure?: string
  type: NoteType
  referralSource?: string
  painAndFunction?: PainAndFunctionFieldState['values']
} & NoteData

const mapToDrafNotePayload = (patient: PatientV2, values: DraftNoteValues) => {
  const {
    type,
    note,
    status,
    sdm,
    communication,
    painAndFunction,
    spokeWith,
    referralSource,
    ...data
  } = values

  const referralSourceChangedTo = referralSource as ReferralSource | undefined
  const shouldAddReferralSourceChangedTo =
    referralSourceChangedTo &&
    referralSourceChangedTo !== patient.referralSource
  const payload = {
    type: type ?? '',
    note: note ?? undefined,
    patientId: patient.patientId,
    sdm: sdm,
    status: status,
    communication: communication,
    data: data ? mapToSDraftNoteData(data) : {},
    spokeWith: spokeWith,
    painAndFunction: mapPainFunctionToArray(painAndFunction),
    referralSourceChangedTo: shouldAddReferralSourceChangedTo
      ? referralSourceChangedTo
      : undefined,
  }

  return payload
}

type MapToCreateDraftNoteArgs = (
  patient: PatientV2,
  formValues: DraftNoteValues
) => CreateDraftNoteArgs

export const mapToCreateDraftNoteArgs: MapToCreateDraftNoteArgs = (
  patient,
  formValues
) => {
  return mapToDrafNotePayload(patient, formValues)
}

type MapToCreateDraftNoteServicePayload = (
  args: CreateDraftNoteArgs
) => CreateDraftNoteServicePayload

export const mapToCreateDraftNoteServicePayload: MapToCreateDraftNoteServicePayload =
  (args) => {
    return args
  }

type MapToDraftNote = (sDraftNote: SDraftNote) => DraftNote

const mapToDraftNote: MapToDraftNote = (sDraftNote) => {
  return {
    ...sDraftNote,
    communication: sDraftNote.communication ?? undefined,
    note: sDraftNote.note ?? undefined,
    spokeWith: sDraftNote.spokeWith ?? undefined,
    data: sDraftNote.data ?? {},
    referralSourceChangedTo:
      mapToReferralSource(sDraftNote.referralSourceChangedTo) ?? undefined,
    status: sDraftNote.status ?? undefined,
    painAndFunction: sDraftNote.painAndFunction ?? [],
  }
}

type MapToCreateDraftNoteReturns = (
  response: CreateDraftNoteServiceResponse
) => CreateDraftNoteReturns

export const mapToCreateDraftNoteReturns: MapToCreateDraftNoteReturns = (
  response
) => {
  const drafNote = mapToDraftNote(response)
  return {
    id: drafNote.id,
    type: drafNote.type,
    patientId: drafNote.patientId,
  }
}

type MapToCreateDraftNoteError = (
  response: CreateDraftNoteServiceErrorResponse
) => CreateDraftNoteError

export const mapToCreateDraftNoteError: MapToCreateDraftNoteError = (
  response
) => {
  return {
    ...response,
  }
}

// ---------------------------------------
// DELETE DRAFT NOTE
// -------------------------------------
type MapToDeleteDraftNoteArgs = (draftNoteId: string) => DeleteDraftNoteArgs

export const mapToDeleteDraftNoteArgs: MapToDeleteDraftNoteArgs = (
  draftNoteId
) => {
  return {
    draftNoteId,
  }
}

type MapToDeleteDraftNoteServicePayload = (
  args: DeleteDraftNoteArgs
) => DeleteDraftNoteArgs

export const mapToDeleteDraftNoteServicePayload: MapToDeleteDraftNoteServicePayload =
  (args) => {
    return {
      ...args,
    }
  }

type MapToDeleteDraftNoteReturns = (
  response: DeleteDraftNoteServiceResponse
) => DeleteDraftNoteReturns

export const mapToDeleteDraftNoteReturns: MapToDeleteDraftNoteReturns = (
  response
) => {
  return {
    message: response,
  }
}

type MapToDeleteDraftNoteError = (
  response: DeleteDraftNoteServiceErrorResponse
) => DeleteDraftNoteError

export const mapToDeleteDraftNoteError: MapToDeleteDraftNoteError = (
  response
) => {
  return {
    ...response,
  }
}

// ---------------------------------------
// GET DRAFT NOTE
// -------------------------------------
type MapToGetDraftNoteServicePayload = (
  params: GetDraftNoteParams
) => GetDraftNoteParams

export const mapToGetDraftNoteServicePayload: MapToGetDraftNoteServicePayload =
  (params) => {
    return {
      ...params,
    }
  }

type MapToGetDraftNoteReturns = (
  response: GetDraftNoteServiceResponse
) => GetDraftNoteReturns

export const mapToGetDraftNoteReturns: MapToGetDraftNoteReturns = (
  response
) => {
  return mapToDraftNote(response)
}

type MapToGetDraftNoteError = (
  response: GetDraftNoteServiceErrorResponse
) => GetDraftNoteError

export const mapToGetDraftNoteError: MapToGetDraftNoteError = (response) => {
  return {
    ...response,
  }
}

// ---------------------------------------
// UPDATE DRAFT NOTE
// -------------------------------------

type MapToUpdateDraftNoteArgs = (
  patient: PatientV2,
  draftNoteId: string,
  formValues: DraftNoteValues
) => UpdateDraftNoteArgs

export const mapToUpdateDraftNoteArgs: MapToUpdateDraftNoteArgs = (
  patient,
  draftNoteId,
  formValues
) => {
  const payload = mapToDrafNotePayload(patient, formValues)

  return {
    draftNoteId,
    payload,
  }
}

type MapToUpdateDraftNoteServicePayload = (
  payload: UpdateDraftNoteArgs['payload']
) => UpdateDraftNoteServicePayload

export const mapToUpdateDraftNoteServicePayload: MapToUpdateDraftNoteServicePayload =
  (payload) => {
    return {
      ...payload,
      data: mapToSDraftNoteData(payload.data),
      referralSourceChangedTo: payload.referralSourceChangedTo || undefined,
      spokeWith: payload.spokeWith || undefined,
      note: payload.note || undefined,
      communication: payload.communication || undefined,
      status: payload.status || undefined,
    }
  }

type MapToUpdateDraftNoteReturns = (
  response: UpdateDraftNoteServiceResponse
) => UpdateDraftNoteReturns

export const mapToUpdateDraftNoteReturns: MapToUpdateDraftNoteReturns = (
  response
) => {
  return mapToDraftNote(response[0])
}

type MapToUpdateDraftNoteError = (
  response: UpdateDraftNoteServiceErrorResponse
) => UpdateDraftNoteError

export const mapToUpdateDraftNoteError: MapToUpdateDraftNoteError = (
  response
) => {
  return {
    ...response,
  }
}

// ---------------------
// PRESENTATION MAPPERS /
// ---------------------

export const filterNoneValueOptions = (values: string[]) =>
  values.filter((val) => !['None', 'N/A', 'Unknown'].includes(val))

type MapToPatientV2NoteTypes = (patient: PatientV2) => NoteType[]

export const mapToPatientV2NoteTypes: MapToPatientV2NoteTypes = (patient) => {
  const patientPathwaysCodes = patient.pathways.map(
    (pathway) => pathway.pathwayCode
  )

  const patientPathwaysTracks = patient.pathways.map((pathway) => pathway.track)

  const isSurgicalTrack = patientPathwaysCodes.some((code) =>
    ['OR08P', 'OR09P'].includes(code)
  )

  const isPostOpTrack = patientPathwaysTracks.some((track) =>
    track.toLowerCase().includes('post-op only')
  )

  const validNoteTypes: Record<NoteType, boolean> = {
    '': false,
    Outreach: !isPatientV2Engaged(patient),
    Intake: true,
    'Referral Upload': true,
    'Ortho Chart Check': isPatientV2Engaged(patient),
    'Ortho Touchpoint': isPatientV2Engaged(patient),
    'Self-Care Touchpoint': isPatientV2Engaged(patient),
    'PT Touchpoint': isPatientV2Engaged(patient),
    'Pre-Op Touchpoint':
      isPatientV2Engaged(patient, 'ortho') && isSurgicalTrack,
    'Post-Op Touchpoint':
      isPatientV2Engaged(patient, 'ortho') &&
      (isSurgicalTrack || isPostOpTrack),
    Custom: true,
  }

  return Object.entries(validNoteTypes)
    .filter(([, value]) => value)
    .map(([key]) => key as NoteType)
}

type MapToNoteTypeStorageKey = (noteType: NoteType) => string

export const mapToNoteTypeStorageKey: MapToNoteTypeStorageKey = (noteType) =>
  noteType.toLocaleLowerCase().replace(' ', '-')
