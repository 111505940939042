import { FC, useEffect } from 'react'
import {
  Box,
  Typography,
  styled,
  Chip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from '@mui/material'
import DOMPurify from 'dompurify'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { Note, NoteDataField } from 'src/features/notes/domain'
import { formatDate } from 'src/features/shared/utils/date.utils'
import {
  ADDITIONAL_QUESTION_LABELS,
  hiddenQuestions,
  questionOrder,
  questionsWithDateVaules,
} from 'src/features/notes/presentation'
import { reactQuillLinkExternalClickEventListener } from 'src/config'

const NoteChip = styled(Chip)(() => ({
  backgroundColor: 'white',
  border: '1px solid rgba(0, 0, 0, 0.38)',
}))

type QuestionsAndAnswersData = {
  question: string
  value: any
}

// TODO: find a better way to order the questions
const getQuestionsAndValues = (data: Note['data'] | null) => {
  const orderedData: QuestionsAndAnswersData[] = []
  const dataKeys = Object.keys(data || {}) as NoteDataField[]
  if (dataKeys.length) {
    const dataEntries = Object.entries(data || {}) as [NoteDataField, string][]
    const filteredDataEntries = dataEntries.filter(
      ([key]) => !hiddenQuestions.includes(key)
    )
    const quentionsAndAnswers = filteredDataEntries.map(([key, value]) => {
      const question = ADDITIONAL_QUESTION_LABELS[key]

      return { question, value }
    })

    const questionWithoutSpecificOrder = dataKeys.filter(
      (key) => !questionOrder.includes(key)
    )

    const orderedQuestions = [...questionOrder, ...questionWithoutSpecificOrder]

    orderedQuestions.forEach((questionKey) => {
      const dataQuestion = quentionsAndAnswers.find(
        (item) => item.question === ADDITIONAL_QUESTION_LABELS[questionKey]
      )
      if (
        dataQuestion &&
        dataQuestion.value !== null &&
        dataQuestion.value !== 'N/A'
      ) {
        orderedData.push({
          question: dataQuestion.question,
          value: questionsWithDateVaules.includes(questionKey)
            ? formatDate(dataQuestion.value)
            : dataQuestion.value,
        })
      }
    })
  }

  return orderedData
}

type NoteHeaderProps = {
  note: Note
}

const NoteHeader: FC<NoteHeaderProps> = ({ note }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        pt: '8px',
        '& .ql-syntax': { textWrap: 'wrap' },
      }}
    >
      <Typography variant="caption" color={'rgba(0, 0, 0, 0.38)'}>
        {note.type} {note.header}
      </Typography>
      <Box sx={{ ml: 1 }}>
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(note.note),
          }}
        />
      </Box>
    </Box>
  )
}

type NoteChipsProps = {
  note: Note
}

const NoteChips: FC<NoteChipsProps> = ({ note }) => {
  if (note.spokeWith || note.sdm)
    return (
      <Box sx={{ display: 'flex', columnGap: '4px', ml: 2, mb: 2 }}>
        {note.spokeWith ? (
          <NoteChip label={`Spoke with: ${note.spokeWith}`} />
        ) : null}
        {note.sdm ? <NoteChip label={'SDM Occurred'} /> : null}
      </Box>
    )

  return null
}

type QuestionsAndAnswersProps = {
  data: QuestionsAndAnswersData[]
}

// TODO: Check if the used of dangerouslySetInnerHTML is necessary
const QuestionsAndAnswers: FC<QuestionsAndAnswersProps> = ({ data }) => {
  return (
    <>
      {data.map(({ question, value }, index) => {
        const formattedValue = Array.isArray(value) ? value.join(', ') : value

        return (
          <Box key={index} sx={{ maxWidth: '90%' }}>
            <Box sx={{ marginBottom: 2 }}>
              <Typography variant="body1">{question}</Typography>
              <div
                style={{ fontWeight: 'bold' }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(String(formattedValue), {
                    ALLOWED_TAGS: [],
                  }),
                }}
              />
            </Box>
          </Box>
        )
      })}
    </>
  )
}

type PatientNotesListProps = {
  patientNotes: Note[]
}

// TODO: refactor this component to extract the inner components
export const PatientNotesList: FC<PatientNotesListProps> = ({
  patientNotes,
}) => {
  useEffect(() => {
    // KNOWN ISSUE: patch to open note links in new browser tab
    document.addEventListener('click', reactQuillLinkExternalClickEventListener)
    return () => {
      document.removeEventListener(
        'click',
        reactQuillLinkExternalClickEventListener
      )
    }
  }, [])
  return (
    <Box sx={{ maxHeight: '800px', overflowY: 'scroll' }}>
      {patientNotes.length ? (
        <>
          <Divider />

          {patientNotes.map((note, index) => {
            const questionAndValue = getQuestionsAndValues(note.data)
            return questionAndValue ? (
              <Box key={note.id}>
                <Accordion elevation={0} disableGutters square>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="note-content"
                    id="note-header"
                    sx={{ '.MuiAccordionSummary-content': { m: 0 } }}
                  >
                    <NoteHeader note={note} />
                  </AccordionSummary>
                  <AccordionDetails sx={{ ml: 2 }}>
                    <QuestionsAndAnswers data={questionAndValue} />
                    {note.referralSourceChangedTo ? (
                      <Box sx={{ marginBottom: 2 }}>
                        <Typography variant="body1">
                          Referral source changed to:
                        </Typography>
                        <Typography variant="body1" fontWeight={'bold'}>
                          {note.referralSourceChangedTo}
                        </Typography>
                      </Box>
                    ) : null}
                  </AccordionDetails>
                </Accordion>
                <NoteChips note={note} />
                {patientNotes.length - 1 !== index ? <Divider /> : null}
              </Box>
            ) : (
              <Box key={note.id}>
                <Box sx={{ px: 2 }}>
                  <NoteHeader note={note} />
                  <Box sx={{ ml: -2 }}>
                    <NoteChips note={note} />
                  </Box>
                </Box>
                {patientNotes.length - 1 !== index ? <Divider /> : null}
              </Box>
            )
          })}
          {patientNotes.length ? <Divider /> : null}
        </>
      ) : null}
    </Box>
  )
}
