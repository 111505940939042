import * as yup from 'yup'
import { DevTool } from '@hookform/devtools'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  IconButton,
  Box,
  Select,
  MenuItem,
  Typography,
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  SelectChangeEvent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Divider,
} from '@mui/material'
import {
  FC,
  useState,
  useCallback,
  useMemo,
  MouseEventHandler,
  useEffect,
  ChangeEvent,
  Dispatch,
  SetStateAction,
  ReactNode,
  useRef,
} from 'react'
import {
  useForm,
  SubmitHandler,
  FormProvider,
  Controller,
  UseFormReturn,
} from 'react-hook-form'
import { toast } from 'react-toastify'

import LockIcon from '@mui/icons-material/Lock'
import CloseIcon from '@mui/icons-material/Close'

import {
  getOutlineInputNotAllowedCursorStyle,
  isPathwayDateFieldRequired,
} from 'src/features/shared/utils'
import {
  useAuth,
  usePatientPageStore,
} from 'src/features/shared/infrastructure'
import {
  mapToCreatePathwayArgs,
  mapToReassignPathwayArgs,
  mapToAllTracks,
  mapToUpdatePathwayPayload,
  mapToTracksByPathway,
  mapToOrthoTrackTypes,
  mapToOrthoTracks,
  orthoTrackSplitter,
} from 'src/features/pathways/adapters'
import {
  useCreatePathway,
  useGetPathwaysQuery,
  useReassignPathway,
  useUpdatePathway,
} from 'src/features/pathways/presentation'
import { useUserSidebarTasks } from 'src/features/tasks/presentation'
import { PatientPathwayV2, PatientV2 } from 'src/features/patients/domain'
import {
  useRolesAndPermissions,
  BackdropLoader,
  TCDatePicker,
  useGetNavigatorsQuery,
  FormFieldContainer,
  ControlledSelectField,
  ModalDialogTitle,
  useDebounce,
  ControlledTextField,
} from 'src/features/shared/presentation'
import { PatientIntakeFlowConditionV2 } from 'src/features/msk/presentation'
import { UpdatePathwayArgs } from 'src/features/pathways/domain'
import { ChildrenInProps, SelectOption } from 'src/features/shared/types'
import { mapPatientPathwayV2ToPathwayInfo } from 'src/features/patients/adapters'
import { RECOMMENDED_ACTION_PATHWAYS_TYPES } from 'src/features/shared/constants'

const WAIT_TIME_FOR_TASKS_TO_BE_CREATED = 60000
const WAIT_TIME_FOR_CLOSE_PATHWAY_TOAST = 5000

const LABELS = {
  pathway: 'Pathway',
  trackType: 'Group',
  track: 'Track',
  pathwayType: 'Pathway Type',
  navigator: 'Assign Navigator',
}

const DefaultPathwayFormSelectContainer: FC<ChildrenInProps> = ({
  children,
}) => {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flex: '0 1 210px',
      }}
    >
      {children}
    </Box>
  )
}

const PathwayLockIcon = () => {
  return (
    <IconButton
      disableRipple={true}
      sx={{
        position: 'absolute',
        left: '0',
        cursor: 'not-allowed',
      }}
    >
      <LockIcon />
    </IconButton>
  )
}

type PathwayDeviationWarningSubmitModalProps = {
  open: boolean
  title: ReactNode
  onClose: () => void
  onSubmitClick: () => void
  showLoader: boolean
  submitButtonText: string
  formMethods: UseFormReturn<PatientPathwayFormFields, any, undefined>
  isReassign: boolean
}

const PathwayDeviationWarningSubmitModal: FC<
  PathwayDeviationWarningSubmitModalProps
> = ({
  open,
  onClose,
  title,
  submitButtonText,
  onSubmitClick,
  showLoader,
  formMethods,
  isReassign,
}) => {
  const pathwayDeviationWatcher = formMethods.watch('pathwayDeviation')
  const pathwayDeviationBarrierExplanationWatcher = formMethods.watch(
    'pathwayDeviationBarrierExplanation'
  )
  return (
    <Dialog open={open}>
      <ModalDialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          m: 0,
        }}
      >
        <CloseIcon
          aria-label="close"
          onClick={onClose}
          sx={{
            cursor: 'pointer',
            color: 'white',
          }}
        />
      </ModalDialogTitle>
      {title ? (
        <DialogTitle
          sx={{
            textAlign: 'left',
            py: 0,
          }}
        >
          {title}
        </DialogTitle>
      ) : null}

      <DialogContent
        sx={{
          textAlign: 'center',
        }}
      >
        <ControlledSelectField
          name={'pathwayDeviation'}
          placeholderItem={'Select an option'}
          items={
            isReassign
              ? [
                  {
                    id: '1',
                    value: 'desire for imaging',
                    label: 'Patient has desire for imaging',
                  },
                  {
                    id: '2',
                    value: 'tried the recommended pathway recently',
                    label: 'Patient tried the recommended pathway recently',
                  },
                  {
                    id: '3',
                    value: 'preconditioned by another provider',
                    label: 'Patient was preconditioned by another provider',
                  },
                  {
                    id: '4',
                    value: 'does not align with patient preference',
                    label:
                      'Recommended pathway does not align with patient preference',
                  },
                  {
                    id: '5',
                    value:
                      'provider of recommended type is not available at preferred location',
                    label:
                      'Provider of recommended type is not available at preferred location',
                  },
                  {
                    id: '6',
                    value: 'has barrier to pursuing recommended pathway',
                    label:
                      'Patient has barrier to pursuing recommended pathway',
                  },
                  { id: '7', value: 'other', label: 'Other' },
                ]
              : [
                  {
                    id: '1',
                    value: 'desire for imaging',
                    label: 'Patient has desire for imaging',
                  },
                  {
                    id: '2',
                    value: 'preconditioned by another provider',
                    label: 'Patient was preconditioned by another provider',
                  },
                  {
                    id: '3',
                    value: 'does not align with patient preference',
                    label:
                      'Recommended pathway does not align with patient preference',
                  },
                  {
                    id: '4',
                    value:
                      'provider of recommended type is not available at preferred location',
                    label:
                      'Provider of recommended type is not available at preferred location',
                  },
                  {
                    id: '5',
                    value: 'has barrier to pursuing recommended pathway',
                    label:
                      'Patient has barrier to pursuing recommended pathway',
                  },
                  { id: '6', value: 'other', label: 'Other' },
                ]
          }
        ></ControlledSelectField>
        {pathwayDeviationWatcher ===
        'has barrier to pursuing recommended pathway' ? (
          <ControlledSelectField
            name={'pathwayDeviationBarrierExplanation'}
            placeholderItem={'Select barrier'}
            items={[
              { id: '1', value: 'co-pays', label: 'Co-pays' },
              { id: '1', value: 'transportation', label: 'Transportation' },
              {
                id: '1',
                value: 'unable to get a referral',
                label: 'Unable to get a referral',
              },
              { id: '4', value: 'other', label: 'Other' },
            ]}
            required={true}
            selectSx={{ marginTop: '8px' }}
          ></ControlledSelectField>
        ) : null}
        {pathwayDeviationBarrierExplanationWatcher === 'other' ? (
          <ControlledTextField
            name="pathwayDeviationBarrierExplanationOtherExplanation"
            textFieldProps={{ placeholder: 'Please explain' }}
            required={true}
            textFieldSx={{ paddingTop: '8px' }}
          ></ControlledTextField>
        ) : null}
        {pathwayDeviationWatcher === 'other' ? (
          <ControlledTextField
            name="pathwayDeviationOtherExplanation"
            textFieldProps={{ placeholder: 'Please explain' }}
            required={true}
            textFieldSx={{ paddingTop: '8px' }}
          ></ControlledTextField>
        ) : null}
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row-reverse',
          gap: '8px',
        }}
      >
        <Button type="button" onClick={onSubmitClick} variant={'contained'}>
          {submitButtonText}
        </Button>
      </DialogActions>
      <BackdropLoader open={showLoader} />
    </Dialog>
  )
}

const isPathwayDeviationRequired = (
  recommendedActions: string[],
  pathwayType: string
) => recommendedActions.length && !recommendedActions.includes(pathwayType)

export type PatientPathwayFormFields = {
  pathway: string
  trackType?: string
  track: string
  pathwayType: string
  navigatorId: string
  commsTrigger?: Date | undefined | null
  firstAppointmentDate?: Date | undefined | null
  pathwayDeviation?:
    | ''
    | 'desire for imaging'
    | 'tried the recommended pathway recently'
    | 'preconditioned by another provider'
    | 'does not align with patient preference'
    | 'provider of recommended type is not available at preferred location'
    | 'has barrier to pursuing recommended pathway'
    | 'other'
  pathwayDeviationBarrierExplanation?:
    | ''
    | 'co-pays'
    | 'transportation'
    | 'unable to get a referral'
    | 'other'
  pathwayDeviationBarrierExplanationOtherExplanation?: string
  pathwayDeviationOtherExplanation?: string
}

const patientPathwayFormSchema: yup.Schema<PatientPathwayFormFields> = yup
  .object()
  .shape(
    {
      pathway: yup.string().required('Required'),
      trackType: yup.string().when('pathway', {
        is: (pathway: string) => pathway === 'ortho',
        then: (schema) => schema.required('Required'),
      }),
      track: yup.string().required('Required'),
      pathwayType: yup.string().required('Required'),
      navigatorId: yup.string().required('Required'),
      commsTrigger: yup.date().when('track', {
        is: (track: string) => isPathwayDateFieldRequired(track),
        then: (schema) => schema.required('Required'),
      }),
      pathwayDeviation: yup
        .string()
        .oneOf([
          '',
          'desire for imaging',
          'tried the recommended pathway recently',
          'preconditioned by another provider',
          'does not align with patient preference',
          'provider of recommended type is not available at preferred location',
          'has barrier to pursuing recommended pathway',
          'other',
        ])
        .when(
          [
            '$recommendedActions',
            'pathwayType',
            '$assignedPathwayId',
            '$isReassign',
          ],
          {
            is: isPathwayDeviationRequired,
            then: (schema) => schema.required('Required'),
          }
        ),
      pathwayDeviationBarrierExplanation: yup
        .string()
        .oneOf([
          '',
          'co-pays',
          'transportation',
          'unable to get a referral',
          'other',
        ])
        .when('pathwayDeviation', {
          is: (pathwayDeviation: string) =>
            pathwayDeviation === 'has barrier to pursuing recommended pathway',
          then: (schema) => schema.required('Required'),
        }),
      pathwayDeviationBarrierExplanationOtherExplanation: yup
        .string()
        .when('pathwayDeviationBarrierExplanation', {
          is: (pathwayDeviationBarrierExplanation: string) =>
            pathwayDeviationBarrierExplanation === 'other',
          then: (schema) => schema.required('Required'),
        }),
      pathwayDeviationOtherExplanation: yup.string().when('pathwayDeviation', {
        is: (pathwayDeviation: string) => pathwayDeviation === 'other',
        then: (schema) => schema.required('Required'),
      }),
    },
    [['track', 'commsTrigger']]
  )

export type PathwayInfo = {
  lockDate?: string | null
  firstAppointmentDate?: string | null
  creationDate?: string | null
  pathwayId?: string | null
  assignedPathwayId?: string | null
  assignedUserId?: string | null
  pathwayType?: string | null
  navigator?: string | null
  pathway?: string | null
  track?: string | null
  communicationChannel?: string | null
  pathwayCode?: string | null
  isPaused?: boolean
}

type PatientPathwayFormProps = {
  patient: PatientV2
  pathwayTrackRecommendation: string
  pathwayInfo: PathwayInfo
  condition: PatientIntakeFlowConditionV2
}

export const PatientPathwayForm: FC<PatientPathwayFormProps> = ({
  patient,
  pathwayTrackRecommendation,
  pathwayInfo,
  condition,
}) => {
  const { navigators } = useGetNavigatorsQuery()
  const { pathways, pathwayTypes } = useGetPathwaysQuery()
  const { hasPermissions } = useRolesAndPermissions()
  const [showPathwaySubmitWarning, setShowPathwaySubmitWarning] =
    useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [isReassign, setIsReassign] = useState(false)
  const [availableTracks, setAvailableTracks] = useState<SelectOption[]>(
    mapToAllTracks(pathways)
  )
  const [availableTypes, setAvailableTypes] = useState<string[]>(
    Object.values(pathwayTypes).flat()
  )

  const pathwayIsLocked = useMemo(
    () => !!pathwayInfo.assignedPathwayId,
    [pathwayInfo.assignedPathwayId]
  )

  const [isLocked, setIsLocked] = useState(pathwayIsLocked)

  const { myCompassUser } = useAuth()
  const { createPathway } = useCreatePathway()
  const { reassignPathway } = useReassignPathway()
  const { updatePathway } = useUpdatePathway()
  const { refreshUserSidebarTasks } = useUserSidebarTasks()

  const availableTracksTypes = useMemo(
    () => mapToOrthoTrackTypes(pathways),
    [pathways]
  )

  const getRecommendedActions = () => {
    if (
      condition &&
      condition.carePathwayId &&
      condition.carePathwayId in RECOMMENDED_ACTION_PATHWAYS_TYPES
    ) {
      return RECOMMENDED_ACTION_PATHWAYS_TYPES[condition.carePathwayId]
    }

    return []
  }

  const getWarningModalTitle = () => {
    const recommendedActions = getRecommendedActions()
    if (recommendedActions.length) {
      const recommendedActionsOrJoin = recommendedActions.join(' or ')
      return (
        <>
          <p>
            Recommended Care Pathway for the condition{' '}
            {recommendedActions.length > 1 ? 'are ' : 'is '}
            {recommendedActionsOrJoin}.
          </p>
          <p>Please select why you are scheduling with a different pathway.</p>
        </>
      )
    }
    return 'If not scheduling with recommended action, please select why.'
  }

  const getCurrentPathway = () => {
    return pathways.find((pathway) => pathway.code === pathwayInfo.pathwayCode)
  }

  const getInitialPathway = () => {
    return getCurrentPathway()?.pathway || ''
  }

  const getInitialTrackType = () => {
    const currentPathway = getCurrentPathway()
    if (currentPathway && currentPathway.pathway === 'Ortho') {
      return orthoTrackSplitter(currentPathway.track).trackType
    }
    return ''
  }

  const getInitialTrack = () => {
    return getCurrentPathway()?.code || ''
  }

  const getInitialType = () => {
    return pathwayInfo.pathwayType || ''
  }

  const getInitialLockDate = useCallback(() => {
    if (pathwayInfo.lockDate) {
      return new Date(pathwayInfo.lockDate)
    }
    return new Date()
  }, [pathwayInfo.lockDate])

  const getInitialFirstAppointmentDate = useCallback(() => {
    if (pathwayInfo.firstAppointmentDate) {
      return new Date(pathwayInfo.firstAppointmentDate)
    }
    return null
  }, [pathwayInfo])

  const getInitialNavigatorId = () => {
    const currentNavigator = navigators?.find(
      (navigator) => navigator.id === myCompassUser?.id
    )

    return pathwayInfo.assignedUserId || currentNavigator?.id || ''
  }

  const getPathwayOptions = () => {
    return Array.from(
      new Set(
        pathways
          ?.filter((pathway) => pathway.track && pathway.type)
          .map((pathway) => pathway.pathway)
      )
    )
  }

  const formMethods = useForm<PatientPathwayFormFields>({
    resolver: yupResolver(patientPathwayFormSchema),
    mode: 'onBlur',
    context: {
      recommendedActions: getRecommendedActions(),
      assignedPathwayId: pathwayInfo.assignedPathwayId,
      isReassign,
    },
    defaultValues: {
      pathway: getInitialPathway(),
      trackType: getInitialTrackType(),
      track: getInitialTrack(),
      pathwayType: getInitialType(),
      navigatorId: getInitialNavigatorId(),
      commsTrigger: getInitialLockDate(),
      firstAppointmentDate: getInitialFirstAppointmentDate(),
      pathwayDeviation: '',
      pathwayDeviationBarrierExplanation: '',
      pathwayDeviationBarrierExplanationOtherExplanation: '',
      pathwayDeviationOtherExplanation: '',
    },
  })

  const pathwayWatcher = formMethods.watch('pathway')
  const pathwayTypeWatcher = formMethods.watch('pathwayType')
  const trackTypeWatcher = formMethods.watch('trackType')
  const trackWatcher = formMethods.watch('track')

  const orthoPathwayIsSelected = useMemo(
    () => pathwayWatcher === 'Ortho',
    [pathwayWatcher]
  )
  const isPTTrackSelected = useMemo(
    () => ['PT01P', 'PT02P'].includes(trackWatcher),
    [trackWatcher]
  )

  const trackTypeIsNotRequired = !!pathwayWatcher && !orthoPathwayIsSelected

  const trackTypeIsInvalid = orthoPathwayIsSelected && !trackTypeWatcher

  const fieldHasErrorMessage = (field: keyof PatientPathwayFormFields) => {
    return (
      !!formMethods.formState.errors[field] &&
      !!formMethods.formState.errors[field]?.message
    )
  }

  const isLockButtonDisabled = () => {
    if (!hasPermissions('pathway:edit') || pathwayInfo.isPaused) {
      return true
    }
    return false
  }

  const inputsAreDisabled = isLockButtonDisabled()

  const pathwayInputIsDisabled = inputsAreDisabled || isLocked

  const trackTypeInputIsDisabled =
    inputsAreDisabled || isLocked || !pathwayWatcher || trackTypeIsNotRequired

  const trackInputIsDisabled =
    inputsAreDisabled || isLocked || !pathwayWatcher || trackTypeIsInvalid

  const pathwayTypeInputIsDisabled =
    inputsAreDisabled || isLocked || !pathwayWatcher

  const navigatorInputIsDisabled = inputsAreDisabled || isLocked

  const commsTriggerInputIsDisabled = inputsAreDisabled || isLocked

  const showCancelButton = pathwayIsLocked && !isLocked

  const submitButtonText =
    pathwayIsLocked && isReassign
      ? 'REASSIGN'
      : pathwayIsLocked
      ? 'SAVE'
      : 'LOCK'

  const getAvailableTracks = useCallback(
    (pathway: string) => mapToTracksByPathway(pathways, pathway),
    [pathways]
  )

  /**
   * Returns all available tracks except "In-Person Appt. date unknown"
   */
  const getAvailableTracksWithoutPT02P = useCallback(
    (pathway: string) =>
      getAvailableTracks(pathway).filter((track) => track.key !== 'PT02P'),
    [getAvailableTracks]
  )

  const getOrthoAvailableTracks = useCallback(
    (trackType: string) => {
      const orthoTracks = mapToOrthoTracks(pathways, trackType)
      if (pathwayInfo.track?.includes('Injections')) {
        return orthoTracks
      }
      // Filter out tracks containing "Injections"
      return orthoTracks.filter((trackItem) => {
        return !trackItem.label.includes('Injections')
      })
    },
    [pathways, pathwayInfo.track]
  )

  const getAvailableTypes = useCallback(
    (pathway: string) => {
      const pathwayType = pathway.toLowerCase() as keyof typeof pathwayTypes
      return pathwayTypes[pathwayType]
    },
    [pathwayTypes]
  )

  const getSelectedPathwayByFormValues = (values: PatientPathwayFormFields) => {
    return pathways?.find((pathwayItem) => pathwayItem.code === values.track)
  }

  const getTrackHelperText = () => {
    if (fieldHasErrorMessage('track')) {
      return formMethods.formState.errors['track']?.message
    }
    if (!pathwayWatcher) {
      return 'Select a pathway first'
    }
    if (trackTypeIsInvalid) {
      return 'Select a track type first'
    }
    return ''
  }

  const getTrackTypeHelperText = () => {
    if (fieldHasErrorMessage('trackType')) {
      return formMethods.formState.errors['trackType']?.message
    }
    if (!pathwayWatcher) {
      return 'Select a pathway first'
    }
    if (trackTypeIsNotRequired) {
      return `Track type is not required for selected pathway ${pathwayWatcher}`
    }
    return ''
  }

  const getPathwayTypeHelperText = () => {
    if (
      formMethods.formState.errors['pathwayType'] &&
      formMethods.formState.errors['pathwayType'].message
    ) {
      return formMethods.formState.errors['pathwayType'].message
    }
    if (!pathwayWatcher) {
      return 'Select a pathway first'
    }
    return ''
  }

  const getInputLockedStyles = () => {
    return {
      paddingLeft: isLocked ? '32px' : '14px',
    }
  }

  const getFormControlStyles = () => {
    return {
      width: '100%',
    }
  }

  const getSelectInputStyles = () => {
    return {
      width: '100%',
      background: 'white',
      '& .MuiSelect-select': {
        ...getInputLockedStyles(),
      },
    }
  }

  const getLockButtonDisabledTitle = () => {
    if (!hasPermissions('pathway:edit')) {
      return 'Only clinical users can make this change'
    }
    if (pathwayInfo.isPaused) {
      return 'Please update Condition to Active if trying to re-activate the Pathway.'
    }
    return ''
  }

  const getApptDateLabel = () => {
    switch (pathwayWatcher) {
      case 'PT':
        if (trackWatcher === 'PT01P') {
          return `Date of first ${pathwayWatcher} appt.`
        }
        return 'Pathway Lock'

      case 'Ortho':
        if (['OR04P', 'OR05P'].includes(trackWatcher)) {
          return `Date of first ${pathwayWatcher} appt.`
        }
        if (['OR06P', 'OR07P'].includes(trackWatcher)) {
          return 'Pathway lock'
        }
        return 'Surgical Date'

      case 'Self-Care':
        return 'Pathway lock'
    }
  }

  const resetForm = () => {
    const pathway = getCurrentPathway()?.pathway
    if (pathway) {
      setAvailableTypes(getAvailableTypes(pathway))
      if (pathway === 'Ortho') {
        setAvailableTracks(getOrthoAvailableTracks(getInitialTrackType()))
      } else {
        setAvailableTracks(getAvailableTracks(pathway))
      }
    }
    formMethods.reset({
      pathway: pathway || '',
      track: getInitialTrack(),
      trackType: getInitialTrackType(),
      pathwayType: getInitialType(),
      navigatorId: getInitialNavigatorId(),
      commsTrigger: getInitialLockDate(),
      pathwayDeviation: '',
    })
  }

  const handleCancelClick = () => {
    resetForm()
    setIsReassign(false)
    setIsLocked(true)
  }

  const handleUnlockClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault()
    const pathway = getCurrentPathway()?.pathway
    if (pathway) {
      setAvailableTypes(getAvailableTypes(pathway))
      if (pathway === 'Ortho') {
        setAvailableTracks(getOrthoAvailableTracks(getInitialTrackType()))
      } else {
        if (getCurrentPathway()?.code === 'PT02P') {
          formMethods.setValue('track', '')
        }
        setAvailableTracks(getAvailableTracksWithoutPT02P(pathway))
      }
    } else {
      setAvailableTracks([])
      setAvailableTypes([])
    }
    setIsLocked(false)
  }

  const handleOnClosePathwayWarning = () => {
    setShowPathwaySubmitWarning(false)
  }

  const submitHandler: SubmitHandler<PatientPathwayFormFields> = (values) => {
    setShowLoader(true)

    const selectedPathway = getSelectedPathwayByFormValues(values)

    if (!selectedPathway) {
      toast.error('Unable to find selected pathway')
      formMethods.reset()
      setShowLoader(false)
      return
    }
    if (pathwayInfo.assignedPathwayId && isReassign) {
      // If the patient has locked pathway and the user is reassigning the pathway

      const reassignPathwayArgs = mapToReassignPathwayArgs(
        selectedPathway,
        patient.patientId,
        pathwayInfo.assignedPathwayId,
        values,
        condition.id
      )

      reassignPathway(reassignPathwayArgs, {
        onSuccess: () => {
          toast.success(
            'Pathway reassigned successfully! Upcoming Tasks should appear for the assigned navigator shortly.',
            {
              autoClose: WAIT_TIME_FOR_CLOSE_PATHWAY_TOAST,
            }
          )
          setIsLocked(true)
          setShowPathwaySubmitWarning(false)
          setIsReassign(false)
        },
        onError: () => {
          toast.error('Unable to reassign pathway')
        },
        onSettled: () => {
          setShowLoader(false)
        },
      })
    } else if (pathwayInfo.assignedPathwayId) {
      // If the patient has locked pathway and the user is updating the pathway

      const updatePathwayArgs: UpdatePathwayArgs = {
        pathwayId: pathwayInfo.assignedPathwayId,
        payload: mapToUpdatePathwayPayload(
          patient.patientId,
          selectedPathway,
          values
        ),
      }

      updatePathway(updatePathwayArgs, {
        onSuccess: () => {
          toast.success(
            'Pathway updated successfully! Upcoming Tasks should appear for the assigned navigator shortly.',
            {
              autoClose: WAIT_TIME_FOR_CLOSE_PATHWAY_TOAST,
            }
          )
          setIsLocked(true)
          setShowPathwaySubmitWarning(false)
          setIsReassign(false)
        },
        onError: () => {
          toast.error('Unable to update pathway')
        },
        onSettled: () => {
          setShowLoader(false)
        },
      })
    } else {
      // If the patient does not have a locked pathway

      const createPathwayArgs = mapToCreatePathwayArgs(
        selectedPathway,
        patient.patientId,
        values,
        condition.id
      )

      createPathway(createPathwayArgs, {
        onSuccess: () => {
          toast.success(
            'Pathway assigned successfully! Upcoming Tasks should appear for the assigned navigator shortly.',
            {
              autoClose: WAIT_TIME_FOR_CLOSE_PATHWAY_TOAST,
            }
          )
          formMethods.reset()
          setShowPathwaySubmitWarning(false)
          setIsReassign(false)
        },
        onError: () => {
          toast.error('Unable to assign pathway')
        },
        onSettled: () => {
          setShowLoader(false)
        },
      })
    }

    setTimeout(() => {
      refreshUserSidebarTasks()
    }, WAIT_TIME_FOR_TASKS_TO_BE_CREATED)
  }

  const pathwaysMatch = (chosenPathway: string) => {
    const pathwayTypes = getRecommendedActions()
    const [, pathwayType] = chosenPathway.split(':')
    return pathwayTypes.includes(pathwayType.trim())
  }

  const getSubmitClickHandler = (checkPathwayDeviation: boolean) => () => {
    if (
      checkPathwayDeviation &&
      !pathwaysMatch(`${pathwayWatcher}: ${pathwayTypeWatcher}`) &&
      pathwayTrackRecommendation != 'N/A'
    ) {
      setShowPathwaySubmitWarning(true)
    } else {
      formMethods.handleSubmit(submitHandler)()
    }
  }

  useEffect(() => {
    const subscription = formMethods.watch((value, { name, type }) => {
      if (type === 'change') {
        switch (name) {
          case 'pathway':
            if (value['pathway']) {
              formMethods.setValue('trackType', '')
              formMethods.setValue('track', '')
              formMethods.setValue('pathwayType', '')

              if (value['pathway'] !== 'Ortho') {
                formMethods.resetField('firstAppointmentDate')
                setAvailableTracks(
                  getAvailableTracksWithoutPT02P(value['pathway'])
                )
              }
              setAvailableTypes(getAvailableTypes(value['pathway']))

              if (pathwayIsLocked && value['pathway'] !== pathwayInfo.pathway) {
                setIsReassign(true)
                formMethods.setValue('commsTrigger', null)
              }
            }
            break
          case 'trackType':
            if (value['trackType'] && value['pathway'] === 'Ortho') {
              formMethods.setValue('track', '')
              setAvailableTracks(getOrthoAvailableTracks(value['trackType']))
            }
            break
          case 'track':
            if (value['track']) {
              if (pathwayIsLocked && value['track'] !== pathwayInfo.track) {
                setIsReassign(true)
                formMethods.setValue('commsTrigger', new Date())
              } else {
                formMethods.setValue('commsTrigger', getInitialLockDate())
              }
            }
            break
          case 'pathwayType':
            if (
              pathwayIsLocked &&
              value['pathwayType'] !== pathwayInfo.pathwayType
            ) {
              setIsReassign(true)
              formMethods.setValue('commsTrigger', new Date())
            } else {
              formMethods.setValue('commsTrigger', getInitialLockDate())
            }
            break
          default:
            break
        }
      }
    })

    return () => subscription.unsubscribe()
  }, [
    formMethods,
    getInitialLockDate,
    getAvailableTracks,
    getAvailableTypes,
    getOrthoAvailableTracks,
    pathwayInfo.pathway,
    pathwayInfo.track,
    pathwayIsLocked,
    getAvailableTracksWithoutPT02P,
  ])

  const [firstAppointmentDate, setFirstAppointmentDate] = useState(
    getInitialFirstAppointmentDate()
  )

  const debouncedFirstAppointmentDate = useDebounce<Date | null>(
    firstAppointmentDate,
    1000
  )

  const firstUpdate = useRef(true)

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    if (isLocked) {
      const values = formMethods.getValues()
      const selectedPathway = getSelectedPathwayByFormValues(
        formMethods.getValues()
      )!
      const updatePathwayArgs: UpdatePathwayArgs = {
        pathwayId: pathwayInfo.assignedPathwayId!,
        payload: mapToUpdatePathwayPayload(
          patient.patientId,
          selectedPathway,
          values
        ),
      }
      updatePathway(updatePathwayArgs, {
        onSuccess: () => {
          toast.success(
            'First appointment date set successfully! Generated tasks remain the same.',
            {
              autoClose: WAIT_TIME_FOR_CLOSE_PATHWAY_TOAST,
            }
          )
          setShowPathwaySubmitWarning(false)
        },
        onError: () => {
          toast.error('Unable to update pathway')
        },
        onSettled: () => {
          setShowLoader(false)
        },
      })
    }
  }, [debouncedFirstAppointmentDate])

  return (
    <FormProvider {...formMethods}>
      <form>
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '24px' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              columnGap: '16px',
            }}
          >
            <Controller
              name="pathway"
              control={formMethods.control}
              render={({ field, fieldState }) => (
                <DefaultPathwayFormSelectContainer>
                  <FormFieldContainer
                    formHelperTextAbsolute
                    label={LABELS.pathway}
                    labelComponent={'inputLabel'}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    inputLabelProps={{ required: true }}
                    formControlProps={{
                      size: 'small',
                      disabled: pathwayInputIsDisabled,
                    }}
                    formControlSx={{
                      ...getFormControlStyles(),
                      ...getOutlineInputNotAllowedCursorStyle(
                        pathwayInputIsDisabled
                      ),
                    }}
                  >
                    <Select
                      required
                      label={LABELS.pathway}
                      value={field.value}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      sx={{
                        ...getSelectInputStyles(),
                      }}
                    >
                      {getPathwayOptions().map((pathway) => (
                        <MenuItem key={pathway} value={pathway}>
                          {pathway}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormFieldContainer>
                  {isLocked ? <PathwayLockIcon /> : null}
                </DefaultPathwayFormSelectContainer>
              )}
            />
            {orthoPathwayIsSelected ? (
              <Controller
                name="trackType"
                control={formMethods.control}
                render={({ field, fieldState }) => (
                  <DefaultPathwayFormSelectContainer>
                    <FormFieldContainer
                      formHelperTextAbsolute
                      label={LABELS.trackType}
                      labelComponent={'inputLabel'}
                      error={fieldState.invalid}
                      helperText={getTrackTypeHelperText()}
                      inputLabelProps={{ required: true }}
                      formControlProps={{
                        size: 'small',
                        disabled: trackTypeInputIsDisabled,
                      }}
                      formControlSx={{
                        ...getFormControlStyles(),
                        ...getOutlineInputNotAllowedCursorStyle(
                          trackTypeInputIsDisabled
                        ),
                      }}
                    >
                      <Select
                        required
                        label={LABELS.trackType}
                        value={field.value}
                        onBlur={field.onBlur}
                        onChange={field.onChange}
                        sx={{
                          ...getSelectInputStyles(),
                        }}
                      >
                        {availableTracksTypes.map((trackType) => (
                          <MenuItem key={trackType} value={trackType}>
                            {trackType}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormFieldContainer>
                    {isLocked ? <PathwayLockIcon /> : null}
                  </DefaultPathwayFormSelectContainer>
                )}
              />
            ) : null}
            <Controller
              name="track"
              control={formMethods.control}
              render={({ field, fieldState }) => (
                <DefaultPathwayFormSelectContainer>
                  <FormFieldContainer
                    formHelperTextAbsolute
                    label={LABELS.track}
                    labelComponent={'inputLabel'}
                    error={fieldState.invalid}
                    helperText={getTrackHelperText()}
                    inputLabelProps={{ required: true }}
                    formControlProps={{
                      size: 'small',
                      disabled: trackInputIsDisabled,
                    }}
                    formControlSx={{
                      ...getFormControlStyles(),
                      ...getOutlineInputNotAllowedCursorStyle(
                        trackInputIsDisabled
                      ),
                    }}
                  >
                    <Select
                      required
                      label={LABELS.track}
                      value={field.value}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      sx={{
                        ...getSelectInputStyles(),
                      }}
                    >
                      {availableTracks.map((track) => (
                        <MenuItem key={track.key} value={track.value}>
                          {track.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormFieldContainer>
                  {isLocked ? <PathwayLockIcon /> : null}
                </DefaultPathwayFormSelectContainer>
              )}
            />
            <Controller
              name="pathwayType"
              control={formMethods.control}
              render={({ field, fieldState }) => (
                <DefaultPathwayFormSelectContainer>
                  <FormFieldContainer
                    formHelperTextAbsolute
                    label={LABELS.pathwayType}
                    labelComponent={'inputLabel'}
                    error={fieldState.invalid}
                    helperText={getPathwayTypeHelperText()}
                    inputLabelProps={{ required: true }}
                    formControlProps={{
                      size: 'small',
                      disabled: pathwayTypeInputIsDisabled,
                    }}
                    formControlSx={{
                      ...getFormControlStyles(),
                      ...getOutlineInputNotAllowedCursorStyle(
                        pathwayTypeInputIsDisabled
                      ),
                    }}
                  >
                    <Select
                      required
                      label={LABELS.pathwayType}
                      value={field.value}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      sx={{
                        ...getSelectInputStyles(),
                      }}
                    >
                      {availableTypes.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormFieldContainer>
                  {isLocked ? <PathwayLockIcon /> : null}
                </DefaultPathwayFormSelectContainer>
              )}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                columnGap: '16px',
                flex: '1 1 auto',
              }}
            >
              <Controller
                name="navigatorId"
                control={formMethods.control}
                render={({ field, fieldState }) => (
                  <DefaultPathwayFormSelectContainer>
                    <FormFieldContainer
                      formHelperTextAbsolute
                      label={LABELS.navigator}
                      labelComponent={'inputLabel'}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                      inputLabelProps={{ required: true }}
                      formControlProps={{
                        size: 'small',
                        disabled: navigatorInputIsDisabled,
                      }}
                      formControlSx={{
                        ...getFormControlStyles(),
                        ...getOutlineInputNotAllowedCursorStyle(
                          navigatorInputIsDisabled
                        ),
                      }}
                    >
                      <Select
                        required
                        label={LABELS.navigator}
                        value={field.value}
                        onBlur={field.onBlur}
                        onChange={field.onChange}
                        sx={{
                          ...getSelectInputStyles(),
                          '& .totalPathwayCount': {
                            display: 'none',
                          },
                        }}
                      >
                        {navigators.map((navigator) => (
                          <MenuItem
                            key={navigator.id}
                            value={navigator.id}
                            sx={{
                              borderBottom: '1px solid rgba(0, 0, 0, 0.18) ',
                              '&:last-child': { borderBottom: 'none' },
                            }}
                          >
                            <Box>
                              <Typography>{navigator.name} </Typography>
                              <Typography
                                variant={'caption'}
                                color={'gray'}
                                className={'totalPathwayCount'}
                              >
                                Assigned Pathways:{' '}
                                {navigator.totalAssignedPathways}
                              </Typography>
                            </Box>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormFieldContainer>
                    {isLocked ? <PathwayLockIcon /> : null}
                  </DefaultPathwayFormSelectContainer>
                )}
              />
              {isPathwayDateFieldRequired(trackWatcher) ? (
                <Box>
                  <Controller
                    name={
                      isPTTrackSelected
                        ? 'firstAppointmentDate'
                        : 'commsTrigger'
                    }
                    control={formMethods.control}
                    render={({ field, fieldState }) => (
                      <DefaultPathwayFormSelectContainer>
                        <TCDatePicker
                          label={getApptDateLabel()}
                          value={field.value ? field.value : null}
                          disabled={
                            commsTriggerInputIsDisabled && !isPTTrackSelected
                          }
                          onChange={(newValue) => {
                            field.onChange(newValue)
                            if (isPTTrackSelected) {
                              setFirstAppointmentDate(newValue!)
                            }
                          }}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              disabled:
                                commsTriggerInputIsDisabled &&
                                !isPTTrackSelected,
                              size: 'small',
                              error: !!fieldState.error,
                              helperText: fieldState.error
                                ? 'Invalid date'
                                : '',
                              sx: {
                                '& .MuiInputBase-root': {
                                  backgroundColor: 'white',
                                },
                                '& .MuiInputBase-root.Mui-disabled': {
                                  '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(0, 0, 0, 0.23)',
                                  },
                                },
                                '& .MuiInputBase-input': {
                                  paddingLeft:
                                    isLocked && !isPTTrackSelected
                                      ? '32px'
                                      : '14px',
                                },
                                '& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled':
                                  {
                                    cursor: 'not-allowed',
                                  },
                              },
                            },
                          }}
                        />

                        {isLocked && !isPTTrackSelected ? (
                          <PathwayLockIcon />
                        ) : null}
                      </DefaultPathwayFormSelectContainer>
                    )}
                  />
                  {isLocked && isPTTrackSelected ? (
                    <Typography color={'#D32F2F'}>
                      Don't unlock to change.
                    </Typography>
                  ) : null}
                </Box>
              ) : null}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                columnGap: '8px',
                flexBasis: '210px',
              }}
            >
              {showCancelButton ? (
                <Button
                  variant={'outlined'}
                  color={'secondary'}
                  onClick={handleCancelClick}
                >
                  CANCEL
                </Button>
              ) : null}
              <Tooltip title={getLockButtonDisabledTitle()} arrow>
                <Box>
                  {isLocked ? (
                    <Button
                      type="button"
                      color="secondary"
                      variant="outlined"
                      onClick={handleUnlockClick}
                      disabled={isLockButtonDisabled()}
                    >
                      UNLOCK
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      onClick={getSubmitClickHandler(true)}
                      color="secondary"
                      variant="contained"
                      disabled={isLockButtonDisabled()}
                    >
                      {submitButtonText}
                    </Button>
                  )}
                </Box>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </form>

      <PathwayDeviationWarningSubmitModal
        open={showPathwaySubmitWarning}
        onClose={handleOnClosePathwayWarning}
        title={getWarningModalTitle()}
        submitButtonText={submitButtonText}
        onSubmitClick={getSubmitClickHandler(false)}
        showLoader={showLoader}
        formMethods={formMethods}
        isReassign={isReassign}
      />

      <BackdropLoader open={showLoader} />
      <DevTool control={formMethods.control} />
    </FormProvider>
  )
}

export type ConditionWithPathway = {
  condition: PatientIntakeFlowConditionV2
  pathway: PatientPathwayV2 | null
}

type PatientMultiplePathwayFormProps = {
  patient: PatientV2
  inactiveConditionsWithPathways: ConditionWithPathway[]
  activeConditionsWithPathways: ConditionWithPathway[]
}

export const PatientMultiplePathwayForm: FC<
  PatientMultiplePathwayFormProps
> = ({
  patient,
  activeConditionsWithPathways,
  inactiveConditionsWithPathways,
}) => {
  const getInitialConditionStatus = () => {
    if (activeConditionsWithPathways.length) {
      return 'active'
    }
    if (inactiveConditionsWithPathways.length) {
      return 'inactive'
    }
    return 'active'
  }

  const [selectedConditionStatus, setSelectedConditionStatus] =
    useState<string>(getInitialConditionStatus())

  const [
    selectedActiveConditionWithPathway,
    setSelectedActiveConditionWithPathway,
  ] = useState<ConditionWithPathway | null>(null)

  const [
    selectedInactiveConditionWithPathway,
    setSelectedInactiveConditionWithPathway,
  ] = useState<ConditionWithPathway | null>(null)

  const {
    setPainFunctionGraphsSelectedCondition,
    setCareTeamSelectedCondition,
  } = usePatientPageStore()

  const setOtherSectionsSelectedCondition = (
    conditionStatus?: string | null,
    selectedCondition?: ConditionWithPathway | null
  ) => {
    const _conditionStatus = conditionStatus ?? selectedConditionStatus
    let _selectedCondition = selectedCondition

    if (_conditionStatus === 'active') {
      if (!_selectedCondition) {
        if (
          !selectedActiveConditionWithPathway &&
          activeConditionsWithPathways.length
        ) {
          _selectedCondition = activeConditionsWithPathways[0]
        } else {
          _selectedCondition = selectedActiveConditionWithPathway
        }
      }
    } else {
      if (!_selectedCondition) {
        if (
          !selectedInactiveConditionWithPathway &&
          inactiveConditionsWithPathways.length
        ) {
          _selectedCondition = inactiveConditionsWithPathways[0]
        } else {
          _selectedCondition = selectedInactiveConditionWithPathway
        }
      }
    }

    if (_selectedCondition) {
      setPainFunctionGraphsSelectedCondition(_selectedCondition.condition.id)
      setCareTeamSelectedCondition(_selectedCondition.condition.id)
    } else {
      setCareTeamSelectedCondition(undefined)
    }
  }

  const pathwayRecommendation = useMemo(() => {
    let recommendedCarePathway = ''
    let recommendedType = ''
    if (selectedConditionStatus === 'active') {
      recommendedCarePathway =
        selectedActiveConditionWithPathway?.condition.recommendedCarePathway ??
        ''
      recommendedType =
        selectedActiveConditionWithPathway?.condition.recommendedType ?? ''
    } else {
      recommendedCarePathway =
        selectedInactiveConditionWithPathway?.condition
          .recommendedCarePathway ?? ''
      recommendedType =
        selectedInactiveConditionWithPathway?.condition.recommendedType ?? ''
    }

    let pathwayRecommendation = ''

    const hasReccomendedCarePathway =
      recommendedCarePathway && recommendedCarePathway !== '-'

    const hasReccomendedCareType = recommendedType && recommendedType !== '-'

    if (hasReccomendedCarePathway) {
      pathwayRecommendation = recommendedCarePathway
    }

    if (hasReccomendedCarePathway && hasReccomendedCareType) {
      pathwayRecommendation = `${pathwayRecommendation}: ${recommendedType}`
    }

    if (!hasReccomendedCarePathway && hasReccomendedCareType) {
      pathwayRecommendation = recommendedType
    }

    return pathwayRecommendation
  }, [
    selectedActiveConditionWithPathway?.condition.recommendedCarePathway,
    selectedActiveConditionWithPathway?.condition.recommendedType,
    selectedConditionStatus,
    selectedInactiveConditionWithPathway?.condition.recommendedCarePathway,
    selectedInactiveConditionWithPathway?.condition.recommendedType,
  ])

  const getSelectedConditionChangeHandler =
    (
      currentConditions: ConditionWithPathway[],
      setter: Dispatch<SetStateAction<ConditionWithPathway | null>>
    ) =>
    (event: SelectChangeEvent<string>) => {
      const conditionId = event.target.value
      const conditionWithPathway = currentConditions.find(
        (condition) => condition.condition.id === conditionId
      )
      if (conditionWithPathway) {
        setter(conditionWithPathway)
        setOtherSectionsSelectedCondition(null, conditionWithPathway)
      }
    }

  const handleSelectedConditionGroup = (
    event: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setSelectedConditionStatus(value)
    setOtherSectionsSelectedCondition(value)
  }

  useEffect(() => {
    setSelectedActiveConditionWithPathway((prevActiveCondition) => {
      const firstActiveConditionOrNull = activeConditionsWithPathways.length
        ? activeConditionsWithPathways[0]
        : null
      if (prevActiveCondition) {
        const conditionIsNoLongerActive = !activeConditionsWithPathways.find(
          (conditionWithPathway) =>
            conditionWithPathway.condition.id ===
            prevActiveCondition.condition.id
        )
        if (conditionIsNoLongerActive) {
          return firstActiveConditionOrNull
        } else {
          const newActiveConditionWithPathway =
            activeConditionsWithPathways.find(
              (conditionWithPathway) =>
                conditionWithPathway.condition.id ===
                prevActiveCondition.condition.id
            )
          return newActiveConditionWithPathway ?? prevActiveCondition
        }
      }
      return firstActiveConditionOrNull
    })

    setSelectedInactiveConditionWithPathway((prevInactiveCondition) => {
      const firstInactiveConditionOrNull = inactiveConditionsWithPathways.length
        ? inactiveConditionsWithPathways[0]
        : null
      if (prevInactiveCondition) {
        const conditionIsNoLongerInactive =
          !inactiveConditionsWithPathways.find(
            (conditionWithPathway) =>
              conditionWithPathway.condition.id ===
              prevInactiveCondition.condition.id
          )
        if (conditionIsNoLongerInactive) {
          return firstInactiveConditionOrNull
        } else {
          const newInactiveConditionWithPathway =
            inactiveConditionsWithPathways.find(
              (conditionWithPathway) =>
                conditionWithPathway.condition.id ===
                prevInactiveCondition.condition.id
            )
          return newInactiveConditionWithPathway ?? prevInactiveCondition
        }
      }
      return firstInactiveConditionOrNull
    })

    if (!activeConditionsWithPathways.length) {
      setSelectedConditionStatus('inactive')
    }
  }, [activeConditionsWithPathways, inactiveConditionsWithPathways])

  useEffect(() => {
    setOtherSectionsSelectedCondition(getInitialConditionStatus())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box
      sx={{
        p: '24px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '24px',
      }}
    >
      <Box>
        <Typography
          fontWeight={500}
          fontSize={20}
          color={'rgba(0, 0, 0, 0.87)'}
        >
          Pathway Management
        </Typography>
      </Box>
      {!inactiveConditionsWithPathways.length &&
      !activeConditionsWithPathways.length ? (
        <>
          <Divider />
          <Typography
            fontWeight={500}
            fontSize={18}
            textAlign={'center'}
            color={'rgba(0, 0, 0, 0.6)'}
            sx={{
              px: '24px',
            }}
          >
            No conditions
          </Typography>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                columnGap: '16px',
              }}
            >
              <FormFieldContainer
                formHelperTextAbsolute
                label={'Condition Status'}
                labelComponent={'formLabel'}
                formControlSx={{
                  width: '210px',
                }}
              >
                <RadioGroup
                  row
                  value={selectedConditionStatus}
                  onChange={handleSelectedConditionGroup}
                >
                  <FormControlLabel
                    value={'inactive'}
                    control={<Radio />}
                    label={'Inactive'}
                  />
                  <FormControlLabel
                    value={'active'}
                    control={<Radio />}
                    label={'Active'}
                  />
                </RadioGroup>
              </FormFieldContainer>
              {selectedConditionStatus === 'active' &&
              selectedActiveConditionWithPathway ? (
                <FormFieldContainer
                  formHelperTextAbsolute
                  label={'Condition'}
                  labelComponent={'formLabel'}
                  formControlSx={{
                    width: '210px',
                  }}
                >
                  <Select
                    size={'small'}
                    value={selectedActiveConditionWithPathway.condition.id}
                    onChange={getSelectedConditionChangeHandler(
                      activeConditionsWithPathways,
                      setSelectedActiveConditionWithPathway
                    )}
                  >
                    {activeConditionsWithPathways.map(({ condition }) => (
                      <MenuItem key={condition.id} value={condition.id}>
                        {condition.bodyRegion}
                      </MenuItem>
                    ))}
                  </Select>
                </FormFieldContainer>
              ) : selectedConditionStatus === 'inactive' &&
                selectedInactiveConditionWithPathway ? (
                <FormFieldContainer
                  formHelperTextAbsolute
                  label={'Condition'}
                  labelComponent={'formLabel'}
                  formControlSx={{
                    width: '210px',
                  }}
                >
                  <Select
                    size={'small'}
                    value={selectedInactiveConditionWithPathway.condition.id}
                    onChange={getSelectedConditionChangeHandler(
                      inactiveConditionsWithPathways,
                      setSelectedInactiveConditionWithPathway
                    )}
                  >
                    {inactiveConditionsWithPathways.map(({ condition }) => (
                      <MenuItem key={condition.id} value={condition.id}>
                        {condition.bodyRegion}
                      </MenuItem>
                    ))}
                  </Select>
                </FormFieldContainer>
              ) : null}
            </Box>
            {pathwayRecommendation ? (
              <Box
                sx={{
                  pr: pathwayRecommendation.length < 20 ? '64px' : '0px',
                }}
              >
                <Box>
                  <Typography color={'#9E9E9E'}>
                    Pathway Recommendation:
                  </Typography>
                  <Typography>{pathwayRecommendation}</Typography>
                </Box>
              </Box>
            ) : null}
          </Box>
          <Divider />
          <Box>
            {selectedConditionStatus === 'active' ? (
              selectedActiveConditionWithPathway ? (
                <PatientPathwayForm
                  key={selectedActiveConditionWithPathway.condition.id}
                  patient={patient}
                  pathwayTrackRecommendation={pathwayRecommendation}
                  pathwayInfo={mapPatientPathwayV2ToPathwayInfo(
                    selectedActiveConditionWithPathway?.pathway || null
                  )}
                  condition={selectedActiveConditionWithPathway.condition}
                />
              ) : !activeConditionsWithPathways.length ? (
                <Typography
                  fontWeight={500}
                  fontSize={18}
                  textAlign={'center'}
                  color={'rgba(0, 0, 0, 0.6)'}
                  sx={{
                    px: '24px',
                  }}
                >
                  No active conditions
                </Typography>
              ) : null
            ) : selectedInactiveConditionWithPathway ? (
              <PatientPathwayForm
                key={selectedInactiveConditionWithPathway.condition.id}
                patient={patient}
                pathwayTrackRecommendation={pathwayRecommendation}
                pathwayInfo={mapPatientPathwayV2ToPathwayInfo(
                  selectedInactiveConditionWithPathway?.pathway || null
                )}
                condition={selectedInactiveConditionWithPathway.condition}
              />
            ) : !inactiveConditionsWithPathways.length ? (
              <Typography
                fontWeight={500}
                fontSize={18}
                textAlign={'center'}
                color={'rgba(0, 0, 0, 0.6)'}
                sx={{
                  px: '24px',
                }}
              >
                No inactive conditions
              </Typography>
            ) : null}
          </Box>
        </>
      )}
    </Box>
  )
}
