import { useQuery } from '@tanstack/react-query'

import { useTasksUseCases } from 'src/features/tasks/tasks.service-locator'
import { GetTasksParams } from 'src/features/tasks/domain'

export const GET_TODAY_TASKS_BY_USER_ID_QUERY_KEY = 'getTodayTasksByUserId'

type UseGetTodayTasksByUserIdArgs = {
  params?: GetTasksParams
}

export const useGetTodayTasksByUserIdQuery = ({
  params,
}: UseGetTodayTasksByUserIdArgs = {}) => {
  const tasksUseCases = useTasksUseCases()

  const { data, isLoading, isError, refetch, isFetching } = useQuery({
    queryKey: [GET_TODAY_TASKS_BY_USER_ID_QUERY_KEY, params],
    queryFn: () => {
      return tasksUseCases.getTodayTasksByUserId({
        params,
      })
    },
  })
  return {
    userTodayTasksData: data,
    userTodayTasks: data?.tasks ? data.tasks : [],
    refetchUserTodayTasks: refetch,
    userTodayTasksIsLoading: isLoading,
    userTodayTasksIsError: isError,
    userTodayTasksIsFetching: isFetching,
  }
}
