import { rest } from 'msw'

import * as settings from 'src/config/settings'
import { createProviderServiceResponseMock } from 'src/__mocks__/responses/create-provider-service-response'
import { CreateProviderServiceResponse } from 'src/features/providers/infrastructure'

const url = `${settings.PROVIDERS_API_BASE_URL}/providers`

export const createProviderServiceResponseHandler =
  rest.post<CreateProviderServiceResponse>(url, async (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(createProviderServiceResponseMock))
  })
