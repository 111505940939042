import { Box, Tab } from '@mui/material'
import { FC, SyntheticEvent } from 'react'

import { PainAndFunctionCondition } from 'src/features/patients/domain'
import { CustomTabPanel, TCTabs } from 'src/features/shared/presentation'
import { getA11yTabsProps } from 'src/features/shared/utils'
import { PatientPainFunctionGraphsSection } from 'src/features/patients/presentation'
import { usePatientPageStore } from 'src/features/shared/infrastructure'

type PatientConditionPainFunctionGraphsSectionProps = {
  conditions: PainAndFunctionCondition[]
}

export const PatientConditionPainFunctionGraphsSection: FC<
  PatientConditionPainFunctionGraphsSectionProps
> = ({ conditions }) => {
  const {
    painFunctionGraphsSelectedCondition,
    setPainFunctionGraphsSelectedCondition,
  } = usePatientPageStore()

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setPainFunctionGraphsSelectedCondition(newValue)
  }

  const defaultDisplayedPainAndFunction =
    painFunctionGraphsSelectedCondition ?? conditions[0].intakeId

  return (
    <Box>
      {conditions.length ? (
        <>
          <TCTabs
            value={defaultDisplayedPainAndFunction}
            onChange={handleChange}
            variant="scrollable"
          >
            {conditions.map((condition) => (
              <Tab
                key={condition.intakeId}
                label={condition.botheredBodyPartDisplay}
                value={condition.intakeId}
                {...getA11yTabsProps(condition.intakeId)}
                sx={{
                  width: conditions.length > 1 ? '50%' : '100%',
                  maxWidth: conditions.length > 1 ? '50%' : '100%',
                  borderBottom: '2px solid rgba(0, 0, 0, 0.12)',
                }}
              />
            ))}
          </TCTabs>
          {conditions.map((condition) => (
            <CustomTabPanel
              value={defaultDisplayedPainAndFunction}
              index={condition.intakeId}
              key={condition.intakeId}
            >
              <PatientPainFunctionGraphsSection
                patientPainAndFunction={{
                  painHistory: condition.painHistory,
                  functionHistory: condition.functionHistory,
                }}
              />
            </CustomTabPanel>
          ))}{' '}
        </>
      ) : null}
    </Box>
  )
}
