import { FC } from 'react'
import { Box, CircularProgress } from '@mui/material'

export const FullSectionLoader: FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100px',
          color: 'magenta.300',
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    </Box>
  )
}
