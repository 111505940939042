import { useQuery } from '@tanstack/react-query'

import { usePlansUseCases } from 'src/features/providers/plans.service-locator'
import {
  GetPlansArgs,
  GetPlansError,
  GetPlansReturns,
} from 'src/features/providers/domain'

export const GET_PlANS_QUERY_KEY = 'getPlans'

export const useGetPlansQuery = (args?: GetPlansArgs) => {
  const plansUseCases = usePlansUseCases()

  const { data, isLoading, isError, isSuccess } = useQuery<
    GetPlansReturns,
    GetPlansError
  >([GET_PlANS_QUERY_KEY, args], () => {
    return plansUseCases.getPlans(args)
  })

  return {
    plans: data || [],
    getPlansIsLoading: isLoading,
    getPlansIsError: isError,
    getPlansIsSuccess: isSuccess,
  }
}
