import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  FormGroup,
} from '@mui/material'
import { ChangeEvent, FC } from 'react'
import {
  FieldValues,
  FieldPath,
  useFormContext,
  useController,
} from 'react-hook-form'

type CommunicationFieldProps = {
  variant?: 'radio' | 'checkbox'
  name?: FieldPath<FieldValues>
  initValue?: string
}

export const CommunicationField: FC<CommunicationFieldProps> = ({
  name = 'communication',
  variant = 'checkbox',
}) => {
  const { control } = useFormContext()
  const { field } = useController({ name, control })

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (field.value === e.target.value) {
      field.onChange('')
      return
    }
    field.onChange(e.target.value)
  }
  return (
    <>
      {variant === 'checkbox' ? (
        <FormControl>
          <FormGroup row>
            <FormControlLabel
              value={'inbound'}
              disabled={field.value == 'outbound'}
              checked={field.value == 'inbound'}
              control={
                <Checkbox value={'inbound'} onChange={handleCheckboxChange} />
              }
              label="Inbound"
            />
            <FormControlLabel
              value={'outbound'}
              disabled={field.value == 'inbound'}
              checked={field.value == 'outbound'}
              control={
                <Checkbox value={'outbound'} onChange={handleCheckboxChange} />
              }
              label="Outbound"
            />
          </FormGroup>
        </FormControl>
      ) : (
        <FormControl>
          <RadioGroup row value={field.value} onChange={field.onChange}>
            <FormControlLabel
              value={'inbound'}
              control={<Radio />}
              label={'Inbound'}
            />
            <FormControlLabel
              value={'outbound'}
              control={<Radio />}
              label={'Outbound'}
            />
          </RadioGroup>
        </FormControl>
      )}
    </>
  )
}
