import { useCallback } from 'react'

import {
  GetMarketsService,
  GetMarketsServiceResponse,
} from 'src/features/providers/infrastructure'

import { useProvidersApiClient } from 'src/features/shared/infrastructure'
import * as settings from 'src/config/settings'

export const useMarketsService = () => {
  const { getProvidersApiClient } = useProvidersApiClient()

  const getMarkets: GetMarketsService = useCallback(async () => {
    const providersClient = await getProvidersApiClient()

    return await providersClient.get<GetMarketsServiceResponse>(
      settings.PROVIDERS_API_BASE_URL + '/markets'
    )
  }, [getProvidersApiClient])

  return {
    getMarkets,
  }
}
