import {
  UseMutateFunction,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import {
  CreatePlanArgs,
  CreatePlanError,
  CreatePlanReturns,
} from 'src/features/providers/domain'
import { usePlansUseCases } from 'src/features/providers/plans.service-locator'
import { GET_PlANS_QUERY_KEY } from 'src/features/providers/presentation'

type useCreatePlanProps = Omit<
  UseMutationOptions<
    CreatePlanReturns,
    CreatePlanError,
    CreatePlanArgs,
    unknown
  >,
  'mutationFn'
>

type UseCreatePlan = (props?: useCreatePlanProps) => {
  createPlan: UseMutateFunction<
    CreatePlanReturns,
    CreatePlanError,
    CreatePlanArgs,
    unknown
  >
  createPlanIsLoading: boolean
  createPlanIsError: boolean
  createPlanStatus: string
}

export const useCreatePlanQuery: UseCreatePlan = (props = {}) => {
  const plansUseCases = usePlansUseCases()
  const queryClient = useQueryClient()

  const { onSuccess } = props

  const { mutate, isLoading, isError, status } = useMutation<
    CreatePlanReturns,
    CreatePlanError,
    CreatePlanArgs,
    unknown
  >({
    mutationFn: (args: CreatePlanArgs) => {
      return plansUseCases.createPlan(args)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [GET_PlANS_QUERY_KEY],
      })
      onSuccess?.(data, variables, context)
    },
  })

  return {
    createPlan: mutate,
    createPlanIsLoading: isLoading,
    createPlanIsError: isError,
    createPlanStatus: status,
  }
}
