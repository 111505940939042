import { FC, MouseEventHandler, ChangeEvent, MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@mui/material'

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

import { useQueuePatientsStore } from 'src/features/shared/infrastructure'
import {
  QueuePatientsQueryParams,
  useQueuePatientViewersHistory,
  useQueuePatientsQueryParams,
} from 'src/features/queues/presentation'
import { QueuePatientView } from 'src/features/queues/domain'
import {
  EligibilityIcon,
  TCBodyTableBusyRow,
  TCBodyTableRow,
  TCBusyNotEligibleRow,
  TCNotEligibleRow,
  TCTableCell,
  TCTableCellHead,
  TCTableCellProps,
  TablePaginationActions,
  TagChip,
} from 'src/features/shared/presentation'
import { ChildrenInProps } from 'src/features/shared/types'
import { DNC_TOOLTIP_TEXT } from 'src/features/shared/constants'

const getTCTableCellVariant = (
  doNotCall?: QueuePatientsTableRow['doNotCall']
): TCTableCellProps['variant'] => (doNotCall ? 'warning' : 'normal')

export type QueuePatientsTableRow = {
  id: string
  name: string
  market: string
  dob: string
  status: string
  lastToOutreach: string
  attempts: number
  dateOfLastAttempt: string
  doNotCall: boolean
  hasLockedPathway: boolean
  isBusy?: boolean
  patientViewersHistory?: QueuePatientView[] | null
  eligible: boolean
  eligibilityStartDate?: string | null
  eligibilityEndDate?: string | null
  eligibilityLastUpdatedAt?: string | null
}

const BusyPatientModal: FC = () => {
  const { getLastViewersNames } = useQueuePatientViewersHistory()
  const {
    selectedQueuePatientRow,
    setSelectedQueuePatientRow,
    openQueuePatientBusyModal,
    setOpenQueuePatientBusyModal,
  } = useQueuePatientsStore()
  const navigate = useNavigate()

  const handleCancelClick = () => {
    setOpenQueuePatientBusyModal(false)
    setSelectedQueuePatientRow(null)
  }

  const handleYesClick = () => {
    setOpenQueuePatientBusyModal(false)
    navigate(`/patients/${selectedQueuePatientRow?.id}`)
  }

  return (
    <Dialog open={openQueuePatientBusyModal}>
      <DialogTitle>Patient Record is Busy</DialogTitle>
      <DialogContent>
        {getLastViewersNames(selectedQueuePatientRow).join(', ')}{' '}
        {`${
          getLastViewersNames(selectedQueuePatientRow).length > 1 ? 'are' : 'is'
        }`}{' '}
        currently working with that patient, are you sure you want to open this
        record?
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleCancelClick}
        >
          CANCEL
        </Button>
        <Button color="secondary" onClick={handleYesClick}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const QueuePatientTableRowTagContainer: FC<ChildrenInProps> = ({
  children,
}) => {
  return <Box sx={{ display: 'flex', gap: '4px' }}>{children}</Box>
}

type QueuePatientTableRowComponentProps = {
  row: QueuePatientsTableRow
  onClick: MouseEventHandler<HTMLTableRowElement>
}

const QueuePatientTableRowComponent: FC<QueuePatientTableRowComponentProps> = ({
  row,
  onClick,
}) => {
  const tableCellVariant = getTCTableCellVariant(row.doNotCall)
  return (
    <TCBodyTableRow onClick={onClick}>
      <TCTableCell variant={tableCellVariant} align="left">
        {row.name}
      </TCTableCell>
      <TCTableCell variant={tableCellVariant} align="left">
        {row.market}
      </TCTableCell>
      <TCTableCell variant={tableCellVariant} align="left">
        {row.dob}
      </TCTableCell>
      <TCTableCell variant={tableCellVariant} align="left">
        {row.status}
      </TCTableCell>
      <TCTableCell variant={tableCellVariant} align="left">
        {row.lastToOutreach}
      </TCTableCell>
      <TCTableCell variant={tableCellVariant} align="left">
        <QueuePatientTableRowTagContainer>
          {row.doNotCall ? (
            <Tooltip title={DNC_TOOLTIP_TEXT}>
              <TagChip color="warning" label="DNC" />
            </Tooltip>
          ) : null}
          {row.hasLockedPathway ? <TagChip label="LOCKED" /> : null}
        </QueuePatientTableRowTagContainer>
      </TCTableCell>
      <TCTableCell variant={tableCellVariant} align="left">
        {row.attempts}
      </TCTableCell>
      <TCTableCell variant={tableCellVariant} align="left">
        {row.dateOfLastAttempt}
      </TCTableCell>
    </TCBodyTableRow>
  )
}

type BusyQueuePatientTableRowComponentProps = QueuePatientTableRowComponentProps

const BusyQueuePatientTableRowComponent: FC<
  BusyQueuePatientTableRowComponentProps
> = ({ row, onClick }) => {
  const tableCellVariant = getTCTableCellVariant(row.doNotCall)
  return (
    <TCBodyTableBusyRow onClick={onClick}>
      <TCTableCell variant={tableCellVariant} align="left">
        {row.name}
      </TCTableCell>
      <TCTableCell variant={tableCellVariant} align="left">
        {row.market}
      </TCTableCell>
      <TCTableCell variant={tableCellVariant} align="left">
        {row.dob}
      </TCTableCell>
      <TCTableCell variant={tableCellVariant} align="left">
        {row.status}
      </TCTableCell>
      <TCTableCell variant={tableCellVariant} align="left">
        {row.lastToOutreach}
      </TCTableCell>
      <TCTableCell variant={tableCellVariant} align="left">
        <QueuePatientTableRowTagContainer>
          {row.doNotCall ? (
            <Tooltip title={DNC_TOOLTIP_TEXT}>
              <TagChip color="warning" label="DNC" />
            </Tooltip>
          ) : null}
          {row.hasLockedPathway ? <TagChip label="LOCKED" /> : null}
        </QueuePatientTableRowTagContainer>
      </TCTableCell>
      <TCTableCell variant={tableCellVariant} align="left">
        {row.attempts}
      </TCTableCell>
      <TCTableCell variant={tableCellVariant} align="left">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box>{row.dateOfLastAttempt}</Box>

          <VisibilityOffIcon fontSize="medium" color="disabled" />
        </Box>
      </TCTableCell>
    </TCBodyTableBusyRow>
  )
}

type NotEligibleQueuePatientTableRowComponentProps =
  QueuePatientTableRowComponentProps

const NotEligibleQueuePatientTableRowComponent: FC<
  NotEligibleQueuePatientTableRowComponentProps
> = ({ row, onClick }) => {
  const tableCellVariant = getTCTableCellVariant(row.doNotCall)
  return (
    <TCNotEligibleRow onClick={onClick}>
      <TCTableCell align="left">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box>
            <EligibilityIcon
              patientEligibility={{
                eligible: row.eligible,
                eligibilityLastUpdatedAt: row.eligibilityLastUpdatedAt,
                eligibilityEndDate: row.eligibilityEndDate,
              }}
              showEligible={false}
              sx={{ margin: '0px 10px -6px 0px' }}
            />
          </Box>
          <Box>{row.name}</Box>
        </Box>
      </TCTableCell>
      <TCTableCell variant={tableCellVariant} align="left">
        {row.market}
      </TCTableCell>
      <TCTableCell variant={tableCellVariant} align="left">
        {row.dob}
      </TCTableCell>
      <TCTableCell variant={tableCellVariant} align="left">
        {row.status}
      </TCTableCell>
      <TCTableCell variant={tableCellVariant} align="left">
        {row.lastToOutreach}
      </TCTableCell>
      <TCTableCell variant={tableCellVariant} align="left">
        <QueuePatientTableRowTagContainer>
          {row.doNotCall ? (
            <Tooltip title={DNC_TOOLTIP_TEXT}>
              <TagChip color="warning" label="DNC" />
            </Tooltip>
          ) : null}
          {row.hasLockedPathway ? <TagChip label="LOCKED" /> : null}
        </QueuePatientTableRowTagContainer>
      </TCTableCell>
      <TCTableCell variant={tableCellVariant} align="left">
        {row.attempts}
      </TCTableCell>
      <TCTableCell variant={tableCellVariant} align="left">
        {row.dateOfLastAttempt}
      </TCTableCell>
    </TCNotEligibleRow>
  )
}

type BusyNotEligibleQueuePatientTableRowComponentProps =
  QueuePatientTableRowComponentProps

const BusyNotEligibleQueuePatientTableRowComponent: FC<
  BusyNotEligibleQueuePatientTableRowComponentProps
> = ({ row, onClick }) => {
  const tableCellVariant = getTCTableCellVariant(row.doNotCall)
  return (
    <TCBusyNotEligibleRow onClick={onClick}>
      <TCTableCell align="left">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box>
            <EligibilityIcon
              patientEligibility={{
                eligible: row.eligible,
                eligibilityLastUpdatedAt: row.eligibilityLastUpdatedAt,
                eligibilityEndDate: row.eligibilityEndDate,
              }}
              showEligible={false}
              sx={{ margin: '0px 10px -6px 0px' }}
            />
          </Box>
          <Box>{row.name}</Box>
        </Box>
      </TCTableCell>
      <TCTableCell variant={tableCellVariant} align="left">
        {row.market}
      </TCTableCell>
      <TCTableCell variant={tableCellVariant} align="left">
        {row.dob}
      </TCTableCell>
      <TCTableCell variant={tableCellVariant} align="left">
        {row.status}
      </TCTableCell>
      <TCTableCell variant={tableCellVariant} align="left">
        {row.lastToOutreach}
      </TCTableCell>
      <TCTableCell variant={tableCellVariant} align="left">
        <QueuePatientTableRowTagContainer>
          {row.doNotCall ? (
            <Tooltip title={DNC_TOOLTIP_TEXT}>
              <TagChip color="warning" label="DNC" />
            </Tooltip>
          ) : null}
          {row.hasLockedPathway ? <TagChip label="LOCKED" /> : null}
        </QueuePatientTableRowTagContainer>
      </TCTableCell>
      <TCTableCell variant={tableCellVariant} align="left">
        {row.attempts}
      </TCTableCell>
      <TCTableCell variant={tableCellVariant} align="left">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box>{row.dateOfLastAttempt}</Box>

          <VisibilityOffIcon fontSize="medium" color="disabled" />
        </Box>
      </TCTableCell>
    </TCBusyNotEligibleRow>
  )
}

type QueuePatientsTableProps = {
  patients: QueuePatientsTableRow[]
  totalCount: number
}

export const QueuePatientsTable: FC<QueuePatientsTableProps> = ({
  patients,
  totalCount,
}) => {
  const { isBusy } = useQueuePatientViewersHistory()
  const { setSelectedQueuePatientRow, setOpenQueuePatientBusyModal } =
    useQueuePatientsStore()
  const navigate = useNavigate()
  const { getLimit, getPage, updateQueuePatientsQueryParams } =
    useQueuePatientsQueryParams()

  const isBusyAndIneligible = (patient: QueuePatientsTableRow) =>
    isBusy(patient) && !patient.eligible

  const getRowClickHandler = (patient: QueuePatientsTableRow) => () => {
    setSelectedQueuePatientRow(patient)
    navigate(`/patients/${patient.id}`)
  }

  const getBusyRowClickHandler = (patient: QueuePatientsTableRow) => () => {
    setSelectedQueuePatientRow(patient)
    setOpenQueuePatientBusyModal(true)
  }

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    const newParams: Partial<QueuePatientsQueryParams> = {
      limit: `${getLimit()}`,
      page: `${newPage + 1}`,
    }
    updateQueuePatientsQueryParams(newParams)
  }

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newParams: Partial<QueuePatientsQueryParams> = {
      limit: event.target.value,
      page: '1',
    }
    updateQueuePatientsQueryParams(newParams)
  }

  return (
    <>
      <TableContainer component={Paper} sx={{ maxWidth: '100%' }}>
        <Table sx={{ maxWidth: '100%' }}>
          <TableHead>
            <TableRow>
              {[
                'Name',
                'Market',
                'DOB',
                'Status',
                'Last to Outreach',
                'Tags',
                'Attempts',
                'Date of Last Attempt',
              ].map((header) => (
                <TCTableCellHead key={header}>{header}</TCTableCellHead>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {patients.length === 0 ? (
              <TableRow>
                <TCTableCell colSpan={8} align="center">
                  No patients found
                </TCTableCell>
              </TableRow>
            ) : (
              patients.map((row, index) => {
                if (isBusyAndIneligible(row)) {
                  return (
                    <BusyNotEligibleQueuePatientTableRowComponent
                      key={`${row.id}-${index}`}
                      row={row}
                      onClick={getBusyRowClickHandler(row)}
                    />
                  )
                }

                if (isBusy(row)) {
                  return (
                    <BusyQueuePatientTableRowComponent
                      key={`${row.id}-${index}`}
                      row={row}
                      onClick={getBusyRowClickHandler(row)}
                    />
                  )
                }

                if (!row.eligible) {
                  return (
                    <NotEligibleQueuePatientTableRowComponent
                      key={`${row.id}-${index}`}
                      row={row}
                      onClick={getRowClickHandler(row)}
                    />
                  )
                }

                return (
                  <QueuePatientTableRowComponent
                    key={`${row.id}-${index}`}
                    row={row}
                    onClick={getRowClickHandler(row)}
                  />
                )
              })
            )}
          </TableBody>
          <TableFooter>
            <TableRow sx={{ width: '100% ' }}>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                colSpan={7}
                count={totalCount || 0}
                rowsPerPage={Number(getLimit())}
                page={
                  !totalCount || totalCount <= 0 ? 0 : Number(getPage()) - 1
                }
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                sx={{ width: '100%' }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <BusyPatientModal />
    </>
  )
}
