import { useQuery } from '@tanstack/react-query'

import { useMarketsUseCases } from 'src/features/providers/markets.service-locator'
import {
  GetMarketsError,
  GetMarketsReturns,
} from 'src/features/providers/domain'

export const GET_MARKETS_QUERY_KEY = 'getMarkets'

export const useGetMarketsQuery = () => {
  const marketsUseCases = useMarketsUseCases()

  const { data, isLoading, isError, isSuccess } = useQuery<
    GetMarketsReturns,
    GetMarketsError
  >([GET_MARKETS_QUERY_KEY], () => {
    return marketsUseCases.getMarkets()
  })

  return {
    markets: data || [],
    getMarketsIsLoading: isLoading,
    getMarketsIsError: isError,
    getMarketsIsSuccess: isSuccess,
  }
}
