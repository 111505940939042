import axios from 'axios'

import { TasksUseCases } from 'src/features/tasks/domain'
import { useTasksService } from 'src/features/tasks/infrastructure'
import {
  mapToCreateTaskError,
  mapToCreateTaskReturns,
  mapToCreateTaskServicePayload,
  mapToGetAllTasksByPatientId,
  mapToGetAllTasksByUserIdError,
  mapToGetAllTasksByUserIdReturns,
  mapToGetDoneTasksByPatientIdError,
  mapToGetDoneTasksByPatientIdReturns,
  mapToGetDoneTasksByUserIdError,
  mapToGetDoneTasksByUserIdReturns,
  mapToGetOverdueTasksByPatientIdError,
  mapToGetOverdueTasksByPatientIdReturns,
  mapToGetOverdueTasksByUserIdError,
  mapToGetOverdueTasksByUserIdReturns,
  mapToGetTaskByPatientIdError,
  mapToGetTasksByPatientIdServiceArgs,
  mapToGetTasksServiceArgs,
  mapToGetTodayTasksByPatientIdError,
  mapToGetTodayTasksByPatientIdReturns,
  mapToGetTodayTasksByUserIdError,
  mapToGetTodayTasksByUserIdReturns,
  mapToGetUpcomingTasksByPatientIdError,
  mapToGetUpcomingTasksByPatientIdReturns,
  mapToGetUpcomingTasksByUserIdError,
  mapToGetUpcomingTasksByUserIdReturns,
  mapToGetUrgentTasksByPatientIdError,
  mapToGetUrgentTasksByPatientIdReturns,
  mapToGetUrgentTasksByUserIdError,
  mapToGetUrgentTasksByUserIdReturns,
  mapToUpdateTaskError,
  mapToUpdateTaskReturns,
  mapToUpdateTaskServiceArgs,
  mapToUpdateTasksError,
  mapToUpdateTasksReturns,
  mapToUpdateTasksServiceArgs,
} from 'src/features/tasks/adapters'
import { useAxiosErrorHandling } from 'src/features/shared/infrastructure'

export const useTasksServiceAdapter = () => {
  const tasksService = useTasksService()
  const { handleAxiosError } = useAxiosErrorHandling()

  const getAllTasksByUserId: TasksUseCases['getAllTasksByUserId'] = async (
    args
  ) => {
    try {
      const serviceArgs = mapToGetTasksServiceArgs(args)
      const response = await tasksService.getAllTasksByUserId(serviceArgs)
      return mapToGetAllTasksByUserIdReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToGetAllTasksByUserIdError)
      }
      throw error
    }
  }

  const getTodayTasksByUserId: TasksUseCases['getTodayTasksByUserId'] = async (
    args
  ) => {
    try {
      const serviceArgs = mapToGetTasksServiceArgs(args)
      const response = await tasksService.getTodayTasksByUserId(serviceArgs)
      return mapToGetTodayTasksByUserIdReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToGetTodayTasksByUserIdError)
      }
      throw error
    }
  }

  const getUrgentTasksByUserId: TasksUseCases['getUrgentTasksByUserId'] =
    async (args) => {
      try {
        const serviceArgs = mapToGetTasksServiceArgs(args)
        const response = await tasksService.getUrgentTasksByUserId(serviceArgs)
        return mapToGetUrgentTasksByUserIdReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToGetUrgentTasksByUserIdError)
        }
        throw error
      }
    }

  const getOverdueTasksByUserId: TasksUseCases['getOverdueTasksByUserId'] =
    async (args) => {
      try {
        const serviceArgs = mapToGetTasksServiceArgs(args)
        const response = await tasksService.getOverdueTasksByUserId(serviceArgs)
        return mapToGetOverdueTasksByUserIdReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToGetOverdueTasksByUserIdError)
        }
        throw error
      }
    }
  const getDoneTasksByUserId: TasksUseCases['getDoneTasksByUserId'] = async (
    args
  ) => {
    try {
      const serviceArgs = mapToGetTasksServiceArgs(args)
      const response = await tasksService.getDoneTasksByUserId(serviceArgs)
      return mapToGetDoneTasksByUserIdReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToGetDoneTasksByUserIdError)
      }
      throw error
    }
  }

  const getUpcomingTasksByUserId: TasksUseCases['getUpcomingTasksByUserId'] =
    async (args) => {
      try {
        const serviceArgs = mapToGetTasksServiceArgs(args)
        const response = await tasksService.getUpcomingTasksByUserId(
          serviceArgs
        )
        return mapToGetUpcomingTasksByUserIdReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToGetUpcomingTasksByUserIdError)
        }
        throw error
      }
    }

  const updateTask: TasksUseCases['updateTask'] = async (args) => {
    try {
      const serviceArgs = mapToUpdateTaskServiceArgs(args)
      const response = await tasksService.updateTask(serviceArgs)
      return mapToUpdateTaskReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToUpdateTaskError)
      }
      throw error
    }
  }

  const updateTasks: TasksUseCases['updateTasks'] = async (args) => {
    try {
      const servicePayload = mapToUpdateTasksServiceArgs(args)
      const response = await tasksService.updateTasks(servicePayload)
      return mapToUpdateTasksReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToUpdateTasksError)
      }
      throw error
    }
  }

  const createTask: TasksUseCases['createTask'] = async (args) => {
    try {
      const payload = mapToCreateTaskServicePayload(args)
      const response = await tasksService.createTask(payload)
      return mapToCreateTaskReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToCreateTaskError)
      }
      throw error
    }
  }

  const getAllTasksByPatientId: TasksUseCases['getAllTasksByPatientId'] =
    async (args) => {
      try {
        const serviceArgs = mapToGetTasksByPatientIdServiceArgs(args)
        const response = await tasksService.getAllTasksByPatientId(serviceArgs)
        return mapToGetAllTasksByPatientId(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToGetTaskByPatientIdError)
        }
        throw error
      }
    }

  const getUpcomingTasksByPatientId: TasksUseCases['getUpcomingTasksByPatientId'] =
    async (args) => {
      try {
        const serviceArgs = mapToGetTasksByPatientIdServiceArgs(args)
        const response = await tasksService.getUpcomingTasksByPatientId(
          serviceArgs
        )
        return mapToGetUpcomingTasksByPatientIdReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToGetUpcomingTasksByPatientIdError)
        }
        throw error
      }
    }

  const getUrgentTasksByPatientId: TasksUseCases['getUrgentTasksByPatientId'] =
    async (args) => {
      try {
        const serviceArgs = mapToGetTasksByPatientIdServiceArgs(args)
        const response = await tasksService.getUrgentTasksByPatientId(
          serviceArgs
        )
        return mapToGetUrgentTasksByPatientIdReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToGetUrgentTasksByPatientIdError)
        }
        throw error
      }
    }

  const getDoneTasksByPatientId: TasksUseCases['getDoneTasksByPatientId'] =
    async (args) => {
      try {
        const serviceArgs = mapToGetTasksByPatientIdServiceArgs(args)
        const response = await tasksService.getDoneTasksByPatientId(serviceArgs)
        return mapToGetDoneTasksByPatientIdReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToGetDoneTasksByPatientIdError)
        }
        throw error
      }
    }

  const getOverdueTasksByPatientId: TasksUseCases['getOverdueTasksByPatientId'] =
    async (args) => {
      try {
        const serviceArgs = mapToGetTasksByPatientIdServiceArgs(args)
        const response = await tasksService.getOverdueTasksByPatientId(
          serviceArgs
        )
        return mapToGetOverdueTasksByPatientIdReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToGetOverdueTasksByPatientIdError)
        }
        throw error
      }
    }

  const getTodayTasksByPatientId: TasksUseCases['getTodayTasksByPatientId'] =
    async (args) => {
      try {
        const serviceArgs = mapToGetTasksByPatientIdServiceArgs(args)
        const response = await tasksService.getTodayTasksByPatientId(
          serviceArgs
        )
        return mapToGetTodayTasksByPatientIdReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToGetTodayTasksByPatientIdError)
        }
        throw error
      }
    }

  return {
    getAllTasksByUserId,
    getTodayTasksByUserId,
    getUrgentTasksByUserId,
    getOverdueTasksByUserId,
    getDoneTasksByUserId,
    getUpcomingTasksByUserId,
    getUpcomingTasksByPatientId,
    getAllTasksByPatientId,
    getUrgentTasksByPatientId,
    getDoneTasksByPatientId,
    getOverdueTasksByPatientId,
    getTodayTasksByPatientId,
    updateTask,
    updateTasks,
    createTask,
  }
}
