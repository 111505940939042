import {
  SSearchDuplicatePatientsServiceResponse,
  SSearchDuplicatePatientsServiceResult,
} from '../../features/patients/infrastructure'

const patientResultMock: SSearchDuplicatePatientsServiceResult = {
  patientId: '38fa40d3-0746-40c7-964c-97204353b014',
  firstName: 'Kinny',
  middleName: null,
  lastName: 'Jacklings',
  dob: '10/30/2041',
  medicareAdvantageId: '6QT6DJ7VV42',
  count: '1',
}
export const searchDuplicatePatientsServiceResponseMock: SSearchDuplicatePatientsServiceResponse =
  [patientResultMock]

export const searchDuplicatePatientsServiceResponseMockFactory = (qty = 1) => {
  const response: SSearchDuplicatePatientsServiceResponse = []

  for (let i = 0; i < qty; i++) {
    response.push({
      patientId: `id${i}`,
      firstName: `Jhon${i}`,
      middleName: `Smith${i}`,
      lastName: `Doe${i}`,
      dob: `10/30/2041`,
      medicareAdvantageId: `6QT6DJ7VV4${i}`,
      count: `${i}`,
    })
  }

  return response
}
