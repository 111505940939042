import { rest } from 'msw'

import * as settings from 'src/config/settings'
import {
  getDoneTasksByPatientIdResponseMock,
  getOverdueTasksByPatientIdResponseMock,
  getTasksByPatientIdResponseMock,
  getTodayTasksByPatientIdResponseMock,
  getUpcomingTasksByPatientIdResponseMock,
  getUrgentTasksByPatientIdResponseMock,
} from 'src/__mocks__/responses/get-tasks-by-patient-id-service-response'
import {
  GetAllTasksByPatientIdServiceResponse,
  GetUpcomingTasksByPatientIdServiceResponse,
} from 'src/features/tasks/infrastructure'

const url = `${settings.COMPASS_API_BASE_URL}/tasks/:patientId/patientId`

export const getTasksByPatientIdServiceResponseHandler = rest.get<
  | GetAllTasksByPatientIdServiceResponse
  | GetUpcomingTasksByPatientIdServiceResponse
>(url, async (req, res, ctx) => {
  const timeFrame = req.url.searchParams.get('timeFrame')

  if (timeFrame === 'upcoming') {
    return res(
      ctx.status(200),
      ctx.json(getUpcomingTasksByPatientIdResponseMock)
    )
  }
  if (timeFrame === 'urgent') {
    return res(ctx.status(200), ctx.json(getUrgentTasksByPatientIdResponseMock))
  }
  if (timeFrame === 'overdue') {
    return res(
      ctx.status(200),
      ctx.json(getOverdueTasksByPatientIdResponseMock)
    )
  }
  if (timeFrame === 'today') {
    return res(ctx.status(200), ctx.json(getTodayTasksByPatientIdResponseMock))
  }
  if (timeFrame === 'done') {
    return res(ctx.status(200), ctx.json(getDoneTasksByPatientIdResponseMock))
  }
  return res(ctx.status(200), ctx.json(getTasksByPatientIdResponseMock))
})
