import { useInfiniteQuery } from '@tanstack/react-query'

import { useTasksUseCases } from 'src/features/tasks/tasks.service-locator'
import * as settings from 'src/config/settings'

export const GET_TODAY_TASKS_BY_USER_ID_INFINITE_QUERY_KEY =
  'getTodayTasksByUserIdInfinite'

export const useGetTodayTasksByUserIdInfiniteQuery = () => {
  const tasksUseCases = useTasksUseCases()

  const {
    data,
    refetch,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isError,
    isFetching,
    isFetchingNextPage,
    isRefetching,
  } = useInfiniteQuery({
    queryKey: [GET_TODAY_TASKS_BY_USER_ID_INFINITE_QUERY_KEY],
    queryFn: ({ pageParam = undefined }) => {
      return tasksUseCases.getTodayTasksByUserId({
        params: {
          limit: settings.TODAY_TASKS_PER_LOAD,
          lastTaskId: pageParam?.lastTaskId,
          lastTaskDate: pageParam?.lastTaskDate,
        },
      })
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage.lastTaskId || !lastPage.lastTaskDate) return undefined
      return {
        lastTaskId: lastPage.lastTaskId,
        lastTaskDate: lastPage.lastTaskDate,
      }
    },
  })
  return {
    infiniteTodayTasksData: data,
    refetchInfiniteTodayTasks: refetch,
    fetchNextPageInfiniteTodayTasks: fetchNextPage,
    hasNextPageInfiniteTodayTasks: hasNextPage,
    infiniteTodayTasksIsLoading: isLoading,
    infiniteTodayTasksIsError: isError,
    infiniteTodayTasksIsFetching: isFetching,
    infiniteTodayTasksIsFetchingNextPage: isFetchingNextPage,
    infiniteTodayTasksIsRefetching: isRefetching,
  }
}

export const GET_URGENT_TASKS_BY_USER_ID_INFINITE_QUERY_KEY =
  'getUrgentTasksByUserIdInfinite'

export const useGetUrgentTasksByUserIdInfiniteQuery = () => {
  const tasksUseCases = useTasksUseCases()

  const {
    data,
    refetch,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isError,
    isFetching,
    isFetchingNextPage,
    isRefetching,
  } = useInfiniteQuery({
    queryKey: [GET_URGENT_TASKS_BY_USER_ID_INFINITE_QUERY_KEY],
    queryFn: ({ pageParam = undefined }) => {
      return tasksUseCases.getUrgentTasksByUserId({
        params: {
          limit: settings.URGENT_TASKS_PER_LOAD,
          lastTaskId: pageParam?.lastTaskId,
          lastTaskDate: pageParam?.lastTaskDate,
        },
      })
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage.lastTaskId || !lastPage.lastTaskDate) return undefined
      return {
        lastTaskId: lastPage.lastTaskId,
        lastTaskDate: lastPage.lastTaskDate,
      }
    },
  })
  return {
    infiniteUrgentTasksData: data,
    refetchInfiniteUrgentTasks: refetch,
    fetchNextPageInfiniteUrgentTasks: fetchNextPage,
    hasNextPageInfiniteUrgentTasks: hasNextPage,
    infiniteUrgentTasksIsLoading: isLoading,
    infiniteUrgentTasksIsError: isError,
    infiniteUrgentTasksIsFetching: isFetching,
    infiniteUrgentTasksIsFetchingNextPage: isFetchingNextPage,
    infiniteUrgentTasksIsRefetching: isRefetching,
  }
}

export const GET_OVERDUE_TASKS_BY_USER_ID_INFINITE_QUERY_KEY =
  'getOverdueTasksByUserIdInfinite'

export const useGetOverdueTasksByUserIdInfiniteQuery = () => {
  const tasksUseCases = useTasksUseCases()

  const {
    data,
    refetch,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isError,
    isFetching,
    isFetchingNextPage,
    isRefetching,
  } = useInfiniteQuery({
    queryKey: [GET_OVERDUE_TASKS_BY_USER_ID_INFINITE_QUERY_KEY],
    queryFn: ({ pageParam = undefined }) => {
      return tasksUseCases.getOverdueTasksByUserId({
        params: {
          limit: settings.OVERDUE_TASKS_PER_LOAD,
          lastTaskId: pageParam?.lastTaskId,
          lastTaskDate: pageParam?.lastTaskDate,
        },
      })
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage.lastTaskId || !lastPage.lastTaskDate) return undefined
      return {
        lastTaskId: lastPage.lastTaskId,
        lastTaskDate: lastPage.lastTaskDate,
      }
    },
  })
  return {
    infiniteOverdueTasksData: data,
    refetchInfiniteOverdueTasks: refetch,
    fetchNextPageInfiniteOverdueTasks: fetchNextPage,
    hasNextPageInfiniteOverdueTasks: hasNextPage,
    infiniteOverdueTasksIsLoading: isLoading,
    infiniteOverdueTasksIsError: isError,
    infiniteOverdueTasksIsFetching: isFetching,
    infiniteOverdueTasksIsFetchingNextPage: isFetchingNextPage,
    infiniteOverdueTasksIsRefetching: isRefetching,
  }
}
